import React, { useRef, useState, useEffect } from 'react';
import GlobalDialog from '../../utils/globalDialog';
import { validateFormFields } from '../../utils/functions';
import { ImCross } from 'react-icons/im';
import S3FileUpload from 'react-s3';
import s3 from '../../aws-s3';
import FileUpload from '../FileUpload';
import '@pathofdev/react-tag-input/build/index.css';
import Loader from '../Loader';
import { useProductState, ProductService } from '../../Service/ProductServices';
import Loader2 from '../Loader2';
import CurrencyInput from 'react-currency-input';
import CustomButton from '../../utils/customButton';

function UpdateProduct(props) {
  const { open, onClose, id, product } = props;
  const productState = useProductState();
  const retailPrice = useRef('');
  const [productImage, setProductImage] = useState(null);
  const discountedPrice = useRef('');
  const leadTime = useRef('');
  const image = useRef('');
  const name = useRef('');
  const link = useRef('');
  const description = useRef('');
  const [errors, setErrors] = useState('');
  const fieldErrors = {
    name: 'Please Enter Name Carefully',
    image: 'Please Select Image',
    retailPrice: 'Please Enter  Retail Price',
    discountedPrice: 'Please Enter Discounted Price',
    description: 'Please Enter Description Price',
    leadTime: 'Please Enter Lead Time',
    link: 'Please Enter Link'
  };
  const [resData, setResData] = useState({
    name: '',
    brand_name: '',
    uid: '',
    description: '',
    thumbnail: '',
    price1: '',
    price2: '',
    lead_time: '',
    product_link: ''
  });
  const data = {
    name: '',
    uid: '',
    description: '',
    thumbnail: '',
    price1: '',
    price2: '',
    lead_time: '',
    product_link: ''
  };
  useEffect(() => {
    if (productState.fetched.value) {
      setResData({
        name: product.name,
        brand_name: product.brand_name,
        uid: product.brand_id,
        description: product.description,
        price1: product.price1 / 100,
        price2: product.price2 / 100,
        lead_time: product.lead_time,
        thumbnail: product.thumbnail,
        product_link: product.link
      });
    }
  }, [productState.fetched.value, id]);
  const onChange = (e) => {
    setResData({ ...resData, [e.target.name]: e.target.value });
  };

  const handlePrice1 = (event, maskedvalue, floatvalue) => {
    setResData({
      ...resData,
      price1: maskedvalue
    });
  };
  const handlePrice2 = (event, maskedvalue, floatvalue) => {
    setResData({
      ...resData,
      price2: maskedvalue
    });
  };
  const convertCurrencyHandle = (currency) => {
    let str;
    if (isNaN(currency)) {
      str = currency.substring(1);
      return parseFloat(str.replace(/,/g, '')) * 100;
    }
    return currency * 100;
  };

  //on Form Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrors({});
    const formValues = {
      name: name.current.value,
      description: description.current.value,
      leadTime: leadTime.current.value,
      image: image.current ? image.current.value : product.thumbnail
    };

    const validationErrors = validateFormFields(formValues, fieldErrors);
    if (Object.keys(validationErrors).length) {
      return setErrors(validationErrors);
    }

    productState.merge({ loading: true });

    let url;
    if (image.current) {
      url = await S3FileUpload.uploadFile(image.current.files[0], s3).then((data) => data.location);
    }

    let retailPrice = convertCurrencyHandle(resData.price1);
    let discountedPrice = convertCurrencyHandle(resData.price2);

    const temp = data;
    temp.name = name.current.value;
    temp.description = description.current.value;
    temp.retailPrice = retailPrice;
    temp.discountedPrice = discountedPrice;
    temp.leadTime = leadTime.current.value;
    temp.thumbnail = resData.thumbnail ? resData.thumbnail : url;
    temp.shippingAreas = 'usa';
    temp.fixedShipping = true;
    temp.uid = resData.uid;
    temp.link = link.current.value;
    temp.brandName = resData.brand_name;
    if (id) ProductService.UpdateProduct(id, temp);
    onClose();
  };

  return (
    <GlobalDialog open={open} onClose={onClose}>
      <h1 className="font-bold  text-2xl mt-2 ml-4 text-gray-600">Update Product</h1>
      {!productState.fetched.value || !product ? (
        <div className="mb-4">
          <Loader2 />
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="p-4">
          <div className="m-2">
            <label htmlFor="profileName" className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              name="name"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter Name"
              value={resData.name}
              onChange={onChange}
              ref={name}
              required
            />
            <div className="text-red px-3 font-medium">{errors.name || ''}</div>
          </div>
          <div className="m-2">
            <label htmlFor="Description" className="block text-sm font-medium text-gray-700 hidden">
              Description
            </label>
            <input
              name="description"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter Description"
              value={resData.description}
              ref={description}
              onChange={onChange}
              type='hidden'
            />
            <div className="text-red px-3 font-medium">{errors.description || ''}</div>
          </div>
          <div className="m-2">
            <label htmlFor="ProductLink" className="text-black block md:text-lg mb-2">
              Product Link
            </label>
            <input
              type="url"
              name="product_link"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter Product Link"
              value={resData.product_link}
              onChange={onChange}
              required
              ref={link}
            />
            <div className="text-red px-3 font-medium">{errors.productLink || ''}</div>
          </div>
          <div className="m-2">
            <label htmlFor="RetailPrice" className="text-black block md:text-lg mb-2">
              Retail Price
            </label>
            <CurrencyInput
              name="price1"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter Retail Price"
              prefix="$"
              value={resData.price1}
              onChangeEvent={handlePrice1}
              required
              ref={retailPrice}
            />
            <div className="text-red px-3 font-medium">{errors.retailPrice || ''}</div>
          </div>
          <div className="m-2">
            <div className="text-red px-3 font-medium">{errors.retailPrice || ''}</div>
          </div>
          <label className="text-black block md:text-lg m-2">Discounted Price</label>
          <label className="block m-2">
            <CurrencyInput
              name="price2"
              placeholder="Discounted Price"
              prefix="$"
              value={resData.price2}
              onChangeEvent={handlePrice2}
              required
              ref={discountedPrice}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </label>
          <label className="text-black block md:text-lg m-2">
            Lead Time (Shipping & fulfillment)
          </label>
          <label className="block m-2">
            <input
              type="number"
              name="lead_time"
              placeholder="Enter Lead Time"
              value={resData.lead_time}
              onChange={onChange}
              required
              ref={leadTime}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </label>

          <div className="m-2">
            {!resData.thumbnail && (
              <div className="m-2">
                <label className="text-black block md:text-lg my-2 font-bold">Product Image</label>
                <FileUpload
                  title=""
                  image={productImage}
                  imageRef={image}
                  error={errors.image}
                  onChange={(event) => {
                    if (event.target.files && event.target.files[0]) {
                      let reader = new FileReader();
                      reader.onload = (e) => {
                        setProductImage(e.target.result);
                      };
                      reader.readAsDataURL(event.target.files[0]);
                    }
                  }}></FileUpload>
              </div>
            )}
            {resData.thumbnail && (
              <div className="w-32 relative mt-4">
                <img src={resData.thumbnail} alt="" className=" object-cover " />
                <div
                  className="absolute -top-2 -right-2 cursor-pointer"
                  onClick={() => setResData({ ...resData, thumbnail: '' })}>
                  <ImCross className="text-red" />
                </div>
              </div>
            )}
          </div>

          <div className="pt-3 mb-2 flex justify-center">
            <CustomButton
              type="submit"
              className="items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              {productState.loading.value && <Loader />}
              Update Product
            </CustomButton>
          </div>
        </form>
      )}
    </GlobalDialog>
  );
}

export default UpdateProduct;
