import React, { useState, useEffect, useContext } from "react";
import { useTabState } from "../../../utils/TabState";
import { useAuth } from "../../../Context/AuthContext";
import { CampaignService } from "../../../Service/CampaignServices";
import { CampaignState } from "../../../utils/campaignState";
import Loader from "../../Loader";
import { BsSearch } from "react-icons/bs";
import toast from "react-hot-toast";
import {
  InfluencerService,
  useInfluencerState,
} from "../../../Service/InfluencerService";
import { Mixpanel } from "../../../mixpanel";
import { UsersService, useUserState } from "../../../Service/userServices";
import Multiselect from "multiselect-react-dropdown";
import { Checkbox } from "@material-ui/core";
import { BrandContext } from "../../../routes/PrivateRoute/BrandDashboardRoute";

function CampaignOutReach({ message, onMessageChange }) {
  const tabState = useTabState();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const userState = useUserState();
  const influencerState = useInfluencerState();
  const campaignState = CampaignState();
  const [numberOfVideos, setNumberOfVideos] = useState({});
  const { brandId } = useContext(BrandContext);

  const [influencerSize, setInfluencerSize] = useState({
    nano: false,
    micro: false,
    macro: false,
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setInfluencerSize((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
    console.log("Checkbox clicked:", name, checked); // Add this line for debugging
  };

  const taskDetails = campaignState.tasks.value.map((task) => {
    return {
      name: task.name,
      platform: task.platform,
      regions: task.regions.map((region) => region.value).join(", "),
    };
  });

  console.log(taskDetails);
  const calculateRange = (influencerSize) => {
    if (influencerSize.nano && influencerSize.micro && influencerSize.macro) {
      return [100, 1000000];
    } else if (influencerSize.micro && influencerSize.macro) {
      return [10000, 1000000];
    } else if (influencerSize.nano && influencerSize.micro) {
      return [100, 50000];
    } else if (influencerSize.nano && influencerSize.macro) {
      return [100, 1000000];
    } else if (influencerSize.nano) {
      return [100, 10000];
    } else if (influencerSize.micro) {
      return [10000, 50000];
    } else if (influencerSize.macro) {
      return [100000, 1000000];
    } else {
      return "No range selected";
    }
  };

  const followerCountRange = calculateRange(influencerSize);
  console.log("campaign_id", campaignState)

  const handleContinue = async (e) => {
    e.preventDefault();

    const data = {
      follower_count: followerCountRange,
      platforms: ["tiktok", "instagram"],
      states: taskDetails.reduce((states, task) => {
        const regions = task.regions.split(", "); // Split the regions into an array
        return [...states, ...regions]; // Add the regions from each task to the states array
      }, []),
      brand_id: brandId,
      campaign_id: campaignState.campaign_id.value,
      campaign_name: campaignState.campaign_name.value,
      email_body: message,
      message: message,
    };

    InfluencerService.FindCreatorsForCampaign(data);
    setTimeout(() => {
      toast.success("Email has been sent to creators");
      tabState.merge({ currentCampModalTab: 4, data: message });
    }, 2000);
  };

  if (!campaignState.campaign) {
    campaignState.campaign = {};
  }

  const task = JSON.stringify(campaignState.tasks.value);
  const tempTask = JSON.parse(task);

  useEffect(() => {
    if (tempTask.length) {
      const data = {};
      tempTask.forEach((el) => {
        data[el.name] = el.number_of_videos;
      });
      setNumberOfVideos(data);
    }
  }, []);

  function validateCheckboxes(event) {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    const checked = Array.prototype.slice
      .call(checkboxes)
      .some((checkbox) => checkbox.checked);
    if (!checked) {
      event.preventDefault(); // Prevent form submission
      alert("At least one influencer size needs to be selected to proceed."); // Show error message
    }
  }

  return (
    <div className="p-4 max-w-full h-full md:max-w-2xl mx-auto text-justify">
      <form onSubmit={handleContinue}>
        <h1 className="text-base leading-6 text-center font-semibold text-gray-900">
          Almost there! Write prospective creators a message telling them why
          they should work with your brand for this campaign. What makes your
          brand unique?
        </h1>
        <textarea
          className="w-full py-36 mt-3 h-5/6 p-4 bg-gray-300 rounded-lg relative"
          required
          value={message}
          placeholder="Write your note here"
          onChange={(e) => onMessageChange(e.target.value)}
          style={{ textAlign: "left", paddingLeft: "1rem", paddingTop: "1rem" }}
        ></textarea>

        <h1 className="text-center my-2 font-bold mb-2">Search Criteria</h1>
        <div className="mt-2 mr-5">
          <label className="justify-center text-center flex mx-auto">
            What size influencers do you aim to target?
          </label>
          <div className="flex flex-row justify-center text-base gap-5">
            <label className="flex items-center mt-4">
              <input
                type="checkbox"
                name="nano"
                checked={influencerSize.nano}
                onChange={handleCheckboxChange}
              />
              <span className="ml-2">Nano (100 - 10k)</span>
            </label>
            <label className="flex items-center mt-4">
              <input
                type="checkbox"
                name="micro"
                checked={influencerSize.micro}
                onChange={handleCheckboxChange}
              />
              <span className="ml-2">Micro (10k - 50k)</span>
            </label>
            <label className="flex items-center mt-4">
              <input
                type="checkbox"
                name="macro"
                checked={influencerSize.macro}
                onChange={handleCheckboxChange}
              />
              <span className="ml-2">Macro (100k - 1M)</span>
            </label>
          </div>
        </div>
        <div className="flex justify-center mt-4">
          <button
            type="submit"
            className="bg-red text-white font-bold text-xl py-3 px-12 rounded-lg outline-none focus:outline-none"
            onClick={validateCheckboxes}
          >
            Email Creators
          </button>
        </div>
      </form>
    </div>
  );
}

export default CampaignOutReach;
