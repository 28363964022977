import React, { useState } from 'react';
import TaskDeleteModal from './TaskDeleteModal';
import TaskDetailsModal from './TaskDetailsModal';
import UpdateTask from './UpdateTask';
import SendVideoRevision from './SendVideoRevision';
import ApprovalModalTask from '../../Creator_Dashboard/ApprovalModalTask';
import DraftModal from './DraftModal';
function TaskCard(props) {
  const [delTask, setDelTask] = useState(false);
  const [taskDetails, setTaskDetails] = useState(false);
  const [videoRevision, setVideoRevision] = useState(false);
  const [deleteTask, setDeleteTask] = useState(false);
  const [proposalTask, setproposalTask] = useState(false);
  const [draft, setDraft] = useState(false);
  const [update, setUpdate] = useState(false);
  const detailsHander = () => {
    setTaskDetails(!taskDetails);
  };

  const revisionHandle = () => {
    setVideoRevision(!videoRevision);
  };
  const proposalHandle = () => {
    setproposalTask(true);
  };
  const deleteHandle = () => {
    setDeleteTask(true);
  };
  const updateHandle = () => {
    let updt = update;
    setUpdate(!updt);
  };
  
  const draftHandle = () => {
    setDraft(!draft);
  }

  return (
    <>
      <div
                    onClick={() => {
                      if (props.task.status === 'approved' &&  !props.task.drafts) {
                        detailsHander();
                      } else if (props.task.status === 'revisions' || (props.task.status === 'approved' && Array.isArray(props.task.drafts) && props.task.drafts.some(draft => draft.status === 'revisions'))) {
                        revisionHandle();
                      } else if (props.task.status === 'pending') {
                        proposalHandle();
                      } else if (props.task.status === 'completed' || (props.task.product_showcase && props.task.drafts && Object.values(props.task.drafts).filter(draft => draft.status === 'approved').length >= props.task.product_showcase)) {
                        // do nothing
                      } else if (props.task.status === 'drafted' || (props.task.status === 'approved' && props.task.drafts)) {
                        draftHandle();
                      } else {
                        detailsHander(); // Default action
                      }
                    }
        }
      >
        <div className="bg-lightGray items-center rounded-xl p-1 flex flex-col cursor-pointer">
        <img
            src={props.task.brand_photo}
            style={{ height: '200px' }}
            className="rounded-lg w-full h-full bg-white object-contain"
            alt="img"
          />
          <div className='text-center w-full overflow-hidden truncate line-camp-2'>
            {props.task && props.task.name || props.task.note1} 
          </div>
          {props.task.id}
          <div className="text-lg my-2 mb-1 text-red font-bold text-center">
            {' '}
            ${(props.task.price / 100).toFixed(2)} + free product
          </div>
          <div className="text-base mb-1 font-bold text-center">
          {props.task.product_showcase !== undefined && props.task.product_showcase !== 0 && (props.task.product_showcase === 1 ? props.task.product_showcase + ' Video' : props.task.product_showcase + ' Videos')}
          {props.task.product_showcase !== 0 && props.task && props.task.add_ons && props.task.add_ons.length > 0 && ', '}
          {props.task && props.task.add_ons && props.task.add_ons.length > 0 && (
            <>
              {props.task.add_ons.map((el, i) => (
                <span key={i}>
                  {el && el.name}
                  {i !== props.task.add_ons.length - 1 && ', '}
                </span>
              ))}
            </>
          )}
            {props.task.recipe_videos !== undefined && props.task.recipe_videos !== 0 && (props.task.recipe_videos + ' Recipe Video')}
          </div>
          <div className="text-base mb-1 font-bold"></div>
        </div>
      </div>
      <div className="items-center justify-center text-center">
    

      {props.task && props.task.status === 'approved' && props.task && props.task.drafts && (
          <>
            <button
              className="bg-red  text-white px-2 mr-3 rounded-lg outline-none text-xs"
              >
              Draft Submitted
            </button>
          </>
        )}

          {props.task && props.task.status === 'pending' && (
          <>
            <button
              className="bg-red  text-white px-2 mr-3 rounded-lg outline-none text-xs"
              >
              Pending Approval
            </button>
          </>
        )}
       
    
        {/* {props.task.status === 'draft_approved' && props.task.creator_id === currentUser.uid && (
          <>
            <button
              className="bg-red  text-white px-2 mr-3 rounded-lg outline-none text-xs"
              // onClick={() => detailsHander()}
              >
              Draft Approved
            </button>
          </>
        )} */}

        {/* {props.task.status === 'approved' && props.task.creator_id !== currentUser.uid && (
          <>
            <button
              className="bg-gray-800 text-white px-2 mr-3 rounded-lg outline-none text-xs"
              >
              Approved
            </button>
          </>
        )} */}
        {(props.task.status === 'completed' || (props.task.product_showcase && props.task.drafts && Object.values(props.task.drafts).filter(draft => draft.status === 'approved').length >= props.task.product_showcase)) && (
          <>
            <span
              className="bg-red text-white px-2 mr-3 rounded-lg outline-none text-xs"
              // onClick={() => detailsHander()}
            >
              Completed
            </span>
          </>
        )}
      </div>
      {delTask ? (
        <TaskDeleteModal open={delTask} onClose={() => setDelTask(false)} id={props.id} />
      ) : null}

      {update ? <UpdateTask open={update} onClose={updateHandle} id={props.id} /> : null}

      {proposalTask ? (
        <ApprovalModalTask
          open={proposalTask}
          task={props.task}
          onClose={() => setproposalTask(false)}
          id={props.id}
        />
      ) : null}

      {update ? <UpdateTask open={update} onClose={updateHandle} id={props.id} /> : null}

      {taskDetails ? (
        <TaskDetailsModal
          open={taskDetails}
          close={() => setTaskDetails(false)}
          task={props.task}
          id={props.id}
        />
      ) : null}
      {videoRevision ? (
        <SendVideoRevision
          open={videoRevision}
          close={() => setVideoRevision(false)}
          task={props.task}
          id={props.id}
        />
      ) : null}
      {deleteTask ? (
        <TaskDeleteModal
          open={deleteTask}
          task={props.task}
          close={() => setDeleteTask(false)}
          id={props.id}
        />
      ) : null}

      {draft ? (
        <DraftModal
          open={draft}
          close={() => setDraft(false)}
          task={props.task}
          id={props.id}
        />
      ) : null}
    </>
  );
}

export default TaskCard;