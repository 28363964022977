import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { baseUrl } from '../../../../baseUrl';
import GlobalDialog from '../../../../utils/globalDialog';
import { useParams } from 'react-router-dom';
import { CampaignService, useCampaignState } from '../../../../Service/CampaignServices';
function PostAnalyticsChart(props) {
  const [analyticsData, setAnalyticsData] = useState([]);
  const campaignState = useCampaignState();
  const {id} = useParams();
  useEffect(() => {
    if (id) {
      CampaignService.FetchSingleCampaign(id);
    }
  }, [id]);
  const res = JSON.stringify(campaignState.campaign.value);
  const campaign = JSON.parse(res);
  console.log("THIS IS THE POST", props.post);
    const performanceData = Object.keys(props.post.performance || {}).reduce((result, key) => {
      // Skip if the key is not an object (like the last "comments", "likes", "shares", "updated", "views")
      if (typeof props.post.performance[key] !== 'object') return result;
    
      const performanceEntry = props.post.performance[key];
      const entryDate = new Date(performanceEntry.updated || performanceEntry.date).getTime();
    
      // Iterate over the properties like "comments", "likes", etc.
      ['comments', 'likes', 'shares', 'views', 'clicks'].forEach(property => {
        // Find or create the series for this property
        let series = result.find(s => s.name === property);
        if (!series) {
          series = { name: property, data: [] };
          result.push(series);
        }
        // Add the data point to the series
        series.data.push({
          x: entryDate,
          y: performanceEntry[property] || 0,
        });
      });
    
      return result;
    }, []);
    
    
    
    const chartOptions = {
      type: 'area',
      series: performanceData,
      xaxis: {
        type: 'datetime',
        tickPlacement: 'on'
      },
      yaxis: {
        title: {
          text: 'Analytics'
        },
        labels: {
          formatter: function (val) {
            if (val !== undefined) {
              return val.toLocaleString(); // Format the number with commas
            }
            return val;
          }
        }
      },
      stroke: {
        curve: 'smooth'
      },  
      markers: {
        size: 6,
        hover: {
          sizeOffset: 3
        }
      }
    };
  
  return (
    <GlobalDialog open={props.open} onClose={props.close} className="w-full h-fit md:h-96 xl:h-96">
    <div className='ml-2'>
      <h1 className='flex items-center justify-center mb-4'>Post Analytics</h1>
      <ReactApexChart
        type="area"
        options={chartOptions}
        series={chartOptions.series}
        width="95%"
        className="mb-10"
        height="260"
      />
    </div>
  </GlobalDialog>
  );
};

export default PostAnalyticsChart;