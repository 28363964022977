import React from 'react';
import GlobalDialog from '../../utils/globalDialog';
import { BsExclamationCircle } from 'react-icons/bs';
import { TaskService } from '../../Service/TaskServices';
import { useAuth } from '../../Context/AuthContext';

function DeleteModal({ open, close, id, task }) {
  const { currentUser } = useAuth();
  const data = {
    creator_uid: task.creator_id,
    brand_uid: task.brand_id ? task.brand_id : currentUser.uid,
    task_id: id[0]
  };
  const handleDeleteRec = () => {
    TaskService.DeleteTask(id[0], data);
    close();
  };
  return (
    <GlobalDialog open={open} onClose={close}>
      <div className=" bg-white rounded-lg dark:bg-gray-700">
        <div className="p-6 pt-0 text-center">
          <BsExclamationCircle className="mx-auto mb-4 w-32 h-14 text-gray-400 dark:text-gray-200" />
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            Are you sure you want to delete this task?
          </h3>
          <button
            type="button"
            className="text-white bg-red hover:bg-red-800  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
            onClick={() => handleDeleteRec()}>
            Yes, I'm sure
          </button>
          <button
            type="button"
            className="text-gray-500 bg-white hover:bg-gray-100  rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600"
            onClick={() => close()}>
            No
          </button>
        </div>
      </div>
    </GlobalDialog>
  );
}

export default DeleteModal;
