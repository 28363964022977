import React, { useState } from 'react';
import { useAuth } from '../Context/AuthContext';
import OtpInput from 'react-otp-input';
import CustomButton from '../utils/customButton';
function OTPCode(props) {
  const { VerifyCode } = useAuth();
  const [otpCode, setOtpCOde] = useState('');
  const verifyOtp = (e) => {
    e.preventDefault();
    VerifyCode(otpCode);
    props.close();
  };
  const handleChange = (otp) => setOtpCOde(otp);
  return (
    <form className=" bg-blue-500">
      <div className="container mx-auto">
        <div className="max-w-sm mx-auto md:max-w-lg">
          <div className="w-full">
            <div className="bg-white py-3 rounded text-center">
              <div className="flex flex-col mt-2">
                <span className="font-bold text-xl">Enter 6-Digit Code sent to</span>
                <span className="font-bold">+{props.phoneNumber}</span>
              </div>
              <div className="flex flex-row justify-center text-center px-2 mt-2">
                <OtpInput
                  value={otpCode}
                  onChange={handleChange}
                  numInputs={6}
                  isInputNum
                  separator={<span>-</span>}
                  inputStyle="m-2 bg-gray-200 h-10 !w-8 text-center form-control rounded"
                />
              </div>
              <div className="flex justify-center text-center mt-5">
                <p className="flex items-center text-blue-700 hover:text-blue-900 cursor-pointer">
                  <span className="">Resend Code</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomButton
        className="text-2xl font-bold my-3 w-full mx-auto rounded text-white py-2 px-14 block"
        type="button"
        onClick={verifyOtp}>
        Verify Code
      </CustomButton>
    </form>
  );
}

export default OTPCode;
