import React, { useContext, useEffect, useState } from 'react';
import { auth } from '../firebase';
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
  signOut
} from 'firebase/auth';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentBrand, setCurrentBrand] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  /*function sendCode() {
    return (
      //window.recaptchaVerifier = 
      new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: (response) => {}
      },
      auth
    ));
  }*/

  function signupPhone(phoneNumber, appVerifier) {
    let Phone = phoneNumber;
    //let appVerifier = window.recaptchaVerifier;
    return signInWithPhoneNumber(auth, Phone, appVerifier)
      .then((confirmationResult) => {
        //window.confirmationResult = confirmationResult;
        return confirmationResult;
        // ...
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...
      });
  }

    function sendCode(phoneNumber) {
        const recaptchaVerifier = new RecaptchaVerifier(
            'recaptcha-container',
            {
                size: 'invisible',
                callback: (response) => {
                    // reCAPTCHA solved, you can proceed with signInWithPhoneNumber
                    //signupPhone(phoneNumber, response);
                },
                'expired-callback': () => {
                    // Handle expired reCAPTCHA
                  console.log("EXPIRED CAPTCHA")
                }
            },
            auth
        );

       // window.recaptchaVerifier = recaptchaVerifier;
    
        return signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
            .then((confirmationResult) => {
                // SMS sent. Return confirmationResult to verify the code later
                return confirmationResult;
            })
            .catch((error) => {
                // Handle Errors here.
                console.error("Error during signInWithPhoneNumber:", error);
                throw error;
            });
    }
    


  function VerifyCode(confirmationResult, code) {
    //let confirmationResult = window.confirmationResult;
    console.log("Context - confirmationResult: ", confirmationResult);
    // Check if 'someFunction' exists and is a function
    if (typeof confirmationResult.confirm === "function") {
        console.log("Context - confirm() exists and is a function.");
    } else {
        console.log("Context - confirm() does not exist or is not a function.");
    }
    return confirmationResult
      .confirm(code)
      .then((result) => {
        // User signed in successfully.
        console.log("User signed in sucessfully");
        console.log("Context - result: ", result);
        return result.user;
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        console.error("Error during confirm verification code:", error);
                throw error;
      });
  }

  function signin(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return signOut(auth);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  function updateEmailBrand(email) {
    return currentBrand.updateEmail(email);
  }

  function updatePasswordBrand(password) {
    return currentBrand.updatePassword(password);
  }
  
  useEffect(() => {
    const unsubscribeUser = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });
  
    const unsubscribeBrand = onAuthStateChanged(auth, (brand) => {
      setCurrentBrand(brand);
      setLoading(false);
    });
  
    // Return a cleanup function to unsubscribe from both listeners
    return () => {
      unsubscribeUser();
      unsubscribeBrand();
    };
  }, []);
  
  const value = {
    currentUser,
    currentBrand,
    signin,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updateEmailBrand,
    updatePasswordBrand,
    updatePassword,
    sendCode,
    //signupPhone,
    VerifyCode
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}

export default AuthContext;
