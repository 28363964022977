import React, { useState, useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import Modal from '../../../utils/brandModal';
import { usePaymentInputs } from 'react-payment-inputs';
import 'react-select-search/style.css';
import SubscriptionStripeContainer from '../SubscriptionsStripeContainer';
import SubscribeTab from '../../../common/steps/Onboarding/BrandbTab';

const styles = {
  fontFamily: 'sans-serif',
  textAlign: 'center',
  color: 'red',
  width: '80%'
};

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#000',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#fce883' },
      '::placeholder': { color: '#87bbfd' }
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee'
    }
  }
};

const BASIC_PLAN_PRICE_TEST = 'price_1MFNBXJZT64pbPqUorcdrTwS';
const PREMIUM_PLAN_PRICE_TEST = 'price_1MFNCKJZT64pbPqUD6WQnn6Q';
const GROWTH_PLAN_PRICE_TEST = 'price_1MFND1JZT64pbPqUaYwGdszA';

const BASIC_PLAN_PRICE = 'price_1MH97SJZT64pbPqUZeYQgdPb';
const PREMIUM_PLAN_PRICE = 'price_1MH97nJZT64pbPqU3IWYdLDK';
const GROWTH_PLAN_PRICE = 'price_1MH97tJZT64pbPqUy6ZOttJJ';

function Subscribe(props) {
  const [currentTab, setCurrentTab] = useState(1);
  const [subscribeData, setSubscribeData] = useState({
    type: '',
    quantity: '',
    price: ''
  });


  const handleBasicMoPlan = () => {
    setSubscribeData({
      ...subscribeData,
      type: 'basic',
      quantity: 2,
      price: BASIC_PLAN_PRICE_TEST,
      price_string: '199.00'
    });
    setCurrentTab(2);
  };
  const handlePremiumMoPlan = () => {
    setSubscribeData({
      ...subscribeData,
      type: 'premium',
      quantity: 5,
      price: PREMIUM_PLAN_PRICE_TEST,
      price_string: '625.00'
    });
    setCurrentTab(2);
  };
  const handleGrowthMoPlan = () => {
    setSubscribeData({
      ...subscribeData,
      type: 'growth',
      quantity: 13,
      price: GROWTH_PLAN_PRICE_TEST,
      price_string: '1500.00'
    });
    setCurrentTab(2);
  };


  return (
    <div>
      <Modal
        open={props.open}
        // open={false}
        onClose={props.close}
        center
        // classNames={{ modal: 'customModal' }}
        style={styles}
        closeOnOverlayClick={false}>
        <div>
          <SubscribeTab currentTab={currentTab} />
          <hr />
          <ul id="tabs">
            {currentTab === 1 ? (
            <>
              <h1 className="text-center text-4xl font-bold">How It Works</h1>
              <p className="text-center mb-8">Select the plan that fits your needs</p>
              <div className="grid lg:grid-cols-3 grid-cols-1 overflow-y-scroll h-41 md:grid-cols-2 gap-2 mx-2">
                <div className="h-28 mt-10 bg-lightGray xs:w-4px md:mx-8 xs:mx-auto rounded-xl">
                  <h1 className="text-xl m-8 font-bold mb-3 font-black xs:xl md:text-4xl text-center">
                    BASIC
                  </h1>
                  <h1 className="text-xl m-8 font-bold mb-3 font-black xs:xl md:text-4xl text-center">
                    $199/mo <span className="line-through text-sm text-darkGray">$299/mo</span>
                  </h1>

                  <ul className="ml-12" style={{ listStyle: 'disc' }}>
                    <li className="py-2">2 credits</li>
                    <li className="py-2">
                      Recipe Video (2 credits)
                    </li>
                    <li className="py-2">
                      Product Demo (1 credit)
                    </li>
                    <li className="py-2">$150 for each add'l credit</li>
                  </ul>

                  <button
                    className="mt-8 mx-auto bg-Red rounded text-white py-2 px-14 block"
                    type="button"
                    onClick={handleBasicMoPlan}>
                    Select Plan
                  </button>
                </div>
                <div className="h-32 bg-red xs:w-4px md:mx-8 xs:mx-auto rounded-xl">
                  <h1 className="text-xl m-8 font-bold mb-3 text-white xs:xl md:text-4xl text-center">
                    PREMIUM
                  </h1>
                  <h1 className="text-xl m-8 font-bold mb-3 text-white xs:xl md:text-4xl text-center">
                    $625/mo
                  </h1>

                  <ul className="ml-12 text-white" style={{ listStyle: 'disc' }}>
                    <li className="py-2">5 credits</li>
                    <li className="py-2">
                      Recipe Video (2 credits)
                    </li>
                    <li className="py-2">
                      Product Demo (1 credit)
                    </li>
                    <li className="py-2 text-white">$149.50 for each add'l credit</li>
                  </ul>
                  <button
                    className="mt-15 mx-auto bg-white rounded text-red py-2 px-14 block"
                    type="button"
                    onClick={handlePremiumMoPlan}>
                    Select Plan
                  </button>
                </div>
                <div className="h-28 mt-10 bg-black xs:w-4px md:mx-8 xs:mx-auto rounded-xl">
                  <h1 className="text-xl m-8 font-bold mb-3 text-white xs:xl md:text-4xl text-center">
                    GROWTH
                  </h1>
                  <h1 className="text-xl m-8 font-bold mb-3 text-white xs:xl md:text-4xl text-center">
                    $1499/mo
                  </h1>

                  <ul className="ml-12 text-white" style={{ listStyle: 'disc' }}>
                    <li className="py-2">13 credits</li>
                    <li className="py-2">
                        Recipe Video (2 credits)
                    </li>
                    <li className="py-2">
                        Product Demo (1 credit)
                    </li>
                    <li className="py-2 text-white">$99.50 for each add'l credit</li>
                  </ul>
                  <button
                    className="mt-8 mb-2 object-bottom mx-auto bg-Red rounded text-white py-2 px-14 block"
                    type="button"
                    onClick={handleGrowthMoPlan}>
                    Select Plan
                  </button>
                </div>
              </div>
              <div className=' flex justify-center mt-4'>
                <a target="_blank" href="https://vimeo.com/showcase/9947942/video/772604429">
                  Recipe Video Example
                </a> | <a target="_blank" href="https://vimeo.com/showcase/9947942/video/766550222">
                  Product Demo Example
                </a> 
              </div>
            </>
            ) : null}
            {currentTab === 2 ? (
              <>
                <h1 className="text-xl m-8 font-bold mb-3 xs:xl md:text-4xl text-center">
                  {subscribeData.type}:$
                  {subscribeData.price_string}/month
                </h1>
                <p className="text-center mb-1">This plan gets you {subscribeData.quantity} credits per month.</p>
                <p className="text-center mb-4">Note: Recipe Videos (2 credits), Product Demo (1 credits)</p>
                <SubscriptionStripeContainer
                  plan={subscribeData.type}
                  price={subscribeData.price}
                  qty={subscribeData.quantity}
                  handleSubscribe={() => setCurrentTab(3)}
                />
                {/*<div className="md:w-1/8 h-auto p-5 pl-12 mx-auto rounded-lg mx-auto  mt-12 bg-white">
                  
                  <div className="flex">
                    <div className="w-2/3 mx-auto">
                      <div className="md:w-full">
                        <label className="text-xs text-Lgray">Your Card Number</label>
                        <br />
                        <div className="flex">
                          <label className="w-fit bg-gray-100 p-3 rounded-l-lg">
                            <svg {...getCardImageProps({ images })} />
                          </label>
                          <input
                            className="w-3px bg-gray-100 p-3 rounded-r-lg"
                            {...getCardNumberProps({
                              onChange: (e) =>
                                setPaymetData({ ...paymentData, cardNumber: e.target.value })
                            })}
                            type={cardType}
                            value={paymentData.cardNumber}
                          />

                          <span
                            className="cursor-pointer mt-3 text-darkGray -ml-8"
                            onClick={showCardHide}>
                            {cardType === 'password' ? hide : show}
                          </span>
                        </div>
                      </div>
                      <div className="md:w-full mt-5">
                        <label className="text-xs text-Lgray">Cord Holder Name</label>
                        <br />
                        <input
                          className="w-full bg-gray-100 p-3 rounded-lg"
                          value={paymentData.holderName}
                          onChange={(e) =>
                            setPaymetData({ ...paymentData, holderName: e.target.value })
                          }
                          required
                        />
                      </div>
                      <div className="md:w-full">
                        <div className="flex mt-5">
                          <div className="mr-5  ">
                            <label className="text-xs text-Lgray">Expire Date</label>
                            <br />

                            <input
                              className="bg-gray-100 w-full p-3 rounded-lg"
                              {...getExpiryDateProps()}
                            />
                          </div>
                          <div className="">
                            <label className="text-xs text-Lgray">CVC</label>
                            <br />
                            <div className="w-full">
                              <input
                                className="bg-gray-100 w-full p-3 rounded-lg"
                                type={cvcType}
                                {...getCVCProps()}
                              />
                              <span
                                className="cursor-pointer mt-3 text-darkGray -ml-8"
                                onClick={showHide}>
                                {cvcType === 'password' ? hide : show}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="md:w-full xs:hidden">
                      <img
                        src={creditCard}
                        className="md:ml-9 xs:ml-3 md:w-5/6 xs:w-1/2"
                        alt="credit card"
                      />
                    </div>
                        </div>
                  <div className="flex md:w-2/3 my-3 text-center w-fit mx-auto">
                    <div className="mr-3">
                      <Switch
                        onChange={handleChangeSwitch}
                        checked={checked}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onColor="#648DE5"
                        offColor="#999999"
                      />
                    </div>
                    <div className="text-xs mt-1">
                      I accept the
                      <a className="text-Blue" href="/#">
                        Terms of the User Agreement
                      </a>
                    </div>
                  </div>

                  <div className="flex w-full mx-auto">
                    <div className="bg-red w-2/3 mx-auto py-2 font-bold rounded-lg text-white mt-2 items-center justify-center text-center cursor-pointer">
                      <button className="" onClick={() => handlePurchasing()}>
                        Purchase
                      </button>
                    </div>
                  </div>
                </div>*/}
              </>
            ) : null}
            
          </ul>
        </div>
      </Modal>
    </div>
  );
}

export default Subscribe;
