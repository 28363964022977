import React, { useState, useEffect } from 'react';
import ApprovalModalTask from './ApprovalModalTask';
import TaskDetailsModal from './TaskDetailsModal';
import EditTaskModal from './EditTaskModal';
import moment from 'moment';
import { Link } from 'react-router-dom';
import CustomButton from '../../utils/customButton';

function TaskCard(props) {
  const [taskDetails, setTaskDetails] = useState(false);
  const [proposalTask, setProposalTask] = useState(false);
  const [editTask, setEditTask] = useState(false);
  var task_time = props.task.date_issued;
  var currentdate = new Date();

  var taskdate = task_time
  ? task_time
      .substring(0, 10)
      .split('-')
      .map(function (item) {
        return parseInt(item, 10);
      })
  : null;
  
  var current_date =
    currentdate.getFullYear() + ',' + (currentdate.getMonth() + 1) + ',' + currentdate.getDate();

  var taskdate_b = current_date.split(',').map(function (item) {
    return parseInt(item, 10);
  });

  var a = moment(taskdate);

  var b = moment(taskdate_b);

  let timeLeft = b.diff(a, 'days');
  //console.log(timeLeft);

  const detailsHander = () => {
    setTaskDetails(!taskDetails);
  };

  const editTaskHandler = () => {
    setEditTask(!editTask);
  };

  const proposalHandle = () => {
    setProposalTask(true);
  };

  return (
    <>
     <Link
        to={`/brand/dashboard/task/${props.id}`}
        className="card-link"
      >
        <div className="bg-lightGray items-center rounded-xl 2xl:p-1 md:p-1 flex flex-col cursor-pointer">
          <div className='relative w-full h-full'> 
        <img
            src={props.task.brand_photo}
            className="rounded-lg w-full 2xl:h-15 md:h-6rem bg-white object-contain"
            alt="img"
          /> 
          {props.task && props.task.trial === true &&
              <div className="absolute text-white px-2 bg-red rounded-md top-2 left-2">
                <div className='text-0.7'>Trial Brief</div>
              </div>
          }
          {props.task && props.task.add_ons && props.task.add_ons.some((addOn) => 
                          addOn.name === "Whitelist") && 
                            <div className="absolute text-white px-2 bg-gray-900 rounded-md top-2 left-2">
                            <div className='text-0.7'>Whitelisting</div>
                          </div>
                          
              }
              
          </div>
         
          {/*<div className="text-base my-2 mb-1 text-Blue font-extrabold text-center">
          {props.task.task_credits && props.task.task_credits === 1 ? props.task.task_credits + ' credit' : props.task.task_credits + ' credits'}
          </div>*/}
          <div className='text-center mt-0.5 2xl:text-2xl md:text-0.7 w-full overflow-hidden truncate'>
            {props.task && props.task.name || props.task && props.task.note3}
          </div>
          <div className="2xl:text-base md:text-0.7 mb-1 font-bold text-center">
          {props.task.product_showcase !== undefined && props.task.product_showcase !== 0 && (props.task.product_showcase === 1 ? props.task.product_showcase + ' Video' : props.task.product_showcase + ' Videos')}
            {props.task.recipe_videos !== 0 &&  props.task.product_showcase !== 0 }
            {props.task && props.task.variations && 
             <>
             , Versions
             </>
             }
            {props.task && props.task.add_ons && props.task.add_ons.map((el, i) => (
            <span key={i}>,{i !== 0 } {el.name}</span>
          ))}
          </div>
        </div>
      </Link>
      <div className="items-center justify-center text-center">
        {((props.task.status === 'available' || props.task.status === "approved") && props.task.accepted_proposals)  && !props.task.drafts ? (
          <button
            className="bg-gray-400 text-white px-2 mr-3 rounded-lg outline-none text-xs"
          >
            Awaiting Drafts
          </button>
        ) : (props.task.status === 'approved' || props.task.status === 'available') && props.task.drafts ? (
          <button className="bg-red text-white px-2 mr-3 rounded-lg outline-none text-xs pointer-events-none">
            Draft(s) Submitted
          </button>
        ) : props.task.status === 'available' ? (
          <button className="bg-Blue text-white px-2 mr-3 rounded-lg outline-none text-xs pointer-events-none">
            View Proposals
          </button>
        )
         : props.task.status === 'completed' && (
          <button className="bg-gray-800 text-white px-2 mr-3 rounded-lg outline-none text-xs pointer-events-none">
            Completed
          </button>
        )}
      </div>
      {proposalTask ? (
        <ApprovalModalTask
          open={proposalTask}
          task={props.task}
          onClose={() => setProposalTask(false)}
          id={props.id}
        />
      ) : null}
      {taskDetails ? (
        <TaskDetailsModal
          open={taskDetails}
          close={() => setTaskDetails(false)}
          task={props.task}
          id={props.id}
        />
      ) : null}
      {editTask ? (
        <EditTaskModal
          open={editTask}
          close={() => setEditTask(false)}
          task={props.task}
          id={props.id}
        />
      ) : null}
    </>
  );
}

export default TaskCard;
