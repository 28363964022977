import React, { useState } from 'react';
import DeleteModal from './DeleteProductModal';
import UpdateProduct from './UpdateProduct';
import CustomButton from '../../utils/customButton';

function ProductCard(props) {
  const [delRec, setDelRec] = useState(false);
  const [update, setUpdate] = useState(false);
  const deleteHander = () => {
    setDelRec(true);
  };
  let price1 = (props.product.price1 / 100)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  let price2 = (props.product.price2 / 100)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return (
    <div
      className=" inline-block cursor-pointer overflow-hidden md:my-2 pt-1 md:px-1 mx-5 rounded-t-lg px-9"
      style={{ width: '85%' }}>
      <div className="border-2 bg-gray-200 rounded-t-lg ">
        <img
          src={props.product.thumbnail}
          className="rounded-2xl object-cover 2xl:h-15rem md:h-8rem md:w-full"
          alt={props.product.name}
        />
      </div>
      <div className="bg-gray-200 rounded-b-lg p-3">
        <h3 className="text-bold text-2xl">{props.product.name}</h3>
        <div className="flex ">
          <p className="text-Blue mr-6 font-bold text-xl">${price2}</p>
          <p className="text-gray-400 text-xl line-through ">${price1}</p>
        </div>
      </div>
      <div className="grid grid-cols-2 ml-4 mr-4 mt-2 mb-2">
        <CustomButton
          className=" text-white mr-2 rounded-lg outline-none"
          onClick={() => setUpdate(!update)}>
          Update
        </CustomButton>
        <CustomButton
          className=" text-white rounded-lg outline-none"
          onClick={() => deleteHander()}>
          Delete
        </CustomButton>
      </div>
      {delRec ? <DeleteModal open={delRec} onClose={() => setDelRec(false)} id={props.id} /> : null}
      {update ? (
        <UpdateProduct
          open={update}
          product={props.product}
          onClose={() => setUpdate(false)}
          id={props.id}
        />
      ) : null}
    </div>
  );
}

export default ProductCard;
