import React, { useState, useEffect, useContext } from 'react';
import GlobalDialog from '../../../../utils/globalDialog';
import { RequestService } from '../../../../Service/RequestServices';
import { useAuth } from '../../../../Context/AuthContext';
import { CreditPackService, useCreditPackState } from '../../../../Service/CreditPackServices';
import { TaskService } from '../../../../Service/TaskServices';
import { CampaignService } from '../../../../Service/CampaignServices';
import { toast, Toaster } from 'react-hot-toast';
import { useBrandState, BrandService } from '../../../../Service/BrandServices';
import { SubscriptionService, useSubscriptionState } from '../../../../Service/SubscriptionServices';
import { CampaignEmailService } from '../../../../Service/CampaignEmailService';
import { Mixpanel } from '../../../../mixpanel';
import { BrandContext } from '../../../../routes/PrivateRoute/BrandDashboardRoute';
import CustomButton from '../../../../utils/customButton';

function SendProductModal({ fetchData,open, proposal, creator_id, task_id, brand_id, task, id, name, onClose}) {
    const { currentUser } = useAuth();
    console.log("task: ", task);
    console.log("proposal: ", proposal);
    console.log("camp_id: ", id);
    console.log("task_id: ", task_id);
    console.log("creator_id: ", creator_id);
    console.log("brand_id: ", brand_id);
    console.log("product_id ", task.product_id);
    const proposalData = proposal[task.proposal_id];
    console.log("PROPOSAL DATA: ", proposalData);
    console.log(" city", proposalData.creator_address.city) 
    console.log('state', proposalData.creator_address.state);
    console.log("product id", task.product_id);
    const creditPackState = useCreditPackState();
    const [tab, setTab] = useState(0);
    const { brandId } = useContext(BrandContext);
    const [markAsShippedData, setMarkAsShippedData] = useState({
        trackingNumber: ''
    });
    const [promoCodeData, setPromoCodeData] = useState({
        promoCode: '',
        promoCodeLink: ''
    });
    const [instoreData, setInStoreData] = useState({
        retailer: '',
        store_link: '',
        credits: 0,
        product_price: 0.00
    });
    const [error, setError] = useState({
        trackingNumber: ''
    });

    const tabHandle = (tb) => {
        setTab(tb)
    };

    const brandState = useBrandState();
    const subsState = useSubscriptionState();
    
    useEffect(() => {
        if (currentUser && brandId !== null) {
          BrandService.FetchSingleBrand(currentUser && brandId);
          SubscriptionService.FetchSubscriptionForUser(currentUser && brandId);
          CreditPackService.FetchSubscriptionForUser(currentUser && brandId);
        }
        
      }, [brandId, creditPackState.updateNeed.value]);
      const brand = brandState.brand.value;
      //console.log("brand info:", brand);
      const subs = subsState.subscription.value;
      let availUsage = 0
      if (subs) {
        availUsage = subs.paidUsage - subs.currentUsage;
      }

    const handleShip = async (e) => {
        e.preventDefault();
        let ship_details = {
            creator_id: creator_id,
            campaign_id: id,
            task_id: task_id,
            brand_id: brand_id, 
            tracking_number: markAsShippedData.trackingNumber,
        } 
        console.log("ship details: ", ship_details);
        const emailData = {
            brand_id: brand_id,
            creator_id: creator_id,
            task_id: task_id,
            task_name: task.name,
            tracking_number: markAsShippedData.trackingNumber
          }
        CampaignEmailService.SendInfluencerFulfillmentEmail(emailData);
          await CampaignService.ShipProduct(task_id, ship_details);
        let mixpanel_fulfillment = {
            'creator_id':creator_id,
            'brand_name': task.brand_name,
            'campign_id': id,
            'campaign_name': name,
            'id': task_id,
            'name': task.name,
            'fulfillment_type': "Ship",
            'city':proposalData.creator_address && proposalData.creator_address.city,
            'state': proposalData.creator_address && proposalData.creator_address.state,
            'product_id': task.product_id,
            'product_name': task.product_name,
            'product_price': instoreData.product_price,
        }
        //Mixpanel.productFulfilled(mixpanel_fulfillment);
        toast.success('Product shipped', {duration: 1000});
        creditPackState.merge({ loading: true });
         fetchData();
         onClose();
       
    };

    const handleStoreReimbursment = async (e) => {
        e.preventDefault();
        creditPackState.merge({ loading: true });
        let reimbursement_details = {
            creator_id: creator_id,
            campaign_id: id,
            task_id: task_id,
            credits: Number(instoreData.credits),
            brand_id: brand_id,
            store_link: instoreData.store_link,
            product_price: Number(instoreData.product_price),
            retailer: instoreData.retailer,
        } 
        console.log("reimbursement_details: ", reimbursement_details);
        const emailData = {
            brand_id: brandId,
            creator_id: creator_id,
            task_id: task_id,
            task_name: task.name,
            product_price: instoreData.product_price,
            retailer: instoreData.retailer,
            store_link: instoreData.store_link,
          }
        
        let mixpanel_fulfillment = {
            'creator_id':creator_id,
            'brand_name': task.brand_name,
            'campign_id': id,
            'campaign_name': name,
            'id': task_id,
            'name': task.name,
            'fulfillment_type': "In Store",
            'city':proposalData.creator_address && proposalData.creator_address.city,
            'state': proposalData.creator_address && proposalData.creator_address.state,
            'product_id': task.product_id,
            'product_name': task.product_name,
            'product_price': instoreData.product_price,
        }
        await CampaignService.SendProductReimbursement(task_id, reimbursement_details);
        creditPackState.merge({ loading: true });
        CampaignEmailService.InStoreFulfillmentEmail(emailData);
      //  Mixpanel.productFulfilled(mixpanel_fulfillment);
      toast.success('Product shipped', {duration: 1000});
      TaskService.FetchSingleTask(task_id);
      onClose();
      fetchData();
       
    };

    const handlePromo = async (e) => {
        e.preventDefault();
        let promo_details = {
            creator_id: creator_id,
            campaign_id: id,
            task_id: task_id,
            brand_id: brand_id,
            promo_code: promoCodeData.promoCode,
            product_link: promoCodeData.promoCodeLink,
        } 
        console.log("promo details: ", promo_details);
        let mixpanel_fulfillment = {
            'creator_id':creator_id,
            'brand_name': task.brand_name,
            'campign_id': id,
            'campaign_name': name,
            'id': task_id,
            'name': task.name,
            'fulfillment_type': "Promo Code",
            'city':proposalData.creator_address && proposalData.creator_address.city,
            'state': proposalData.creator_address && proposalData.creator_address.state,
            'product_id': task.product_id,
            'product_name': task.product_name,
            'product_price': instoreData.product_price,
        }
        const emailData = {
            brand_id: brandId,
            creator_id: creator_id,
            task_id: task_id,
            task_name: task.name,
            promo_code: promoCodeData.promoCode,
            product_link: promoCodeData.promoCodeLink,
          }
        
       //Mixpanel.productFulfilled(mixpanel_fulfillment);
        await CampaignService.SendPromo(task_id, promo_details);
        creditPackState.merge({ loading: true });
        CampaignEmailService.PromoFulfillmentEmail(emailData);
        toast.success("Promo code added", {duration: 1000});
        onClose();
        fetchData();
    };

    console.log(tab);

    return (
        <GlobalDialog open={open} onClose={onClose}>
            <Toaster/>
            <div className=" bg-white rounded-lg dark:bg-gray-700">
                <div className="p-6 pt-0 text-center">
                    <div className="flex mb-5">
                        <div className={`w-auto my-2 font-bold cursor-pointer mr-3 ${tab === 0 ? 'text-Blue border-b-4 border-Blue' : ''}`} onClick={() => tabHandle(0)}>
                            Ship product
                        </div>
                        <div className={`w-auto  font-bold cursor-pointer mr-3  ${tab === 1 ? 'text-Blue border-b-4 border-Blue' : ''} my-2`} onClick={() => tabHandle(1)}>
                            | Promo Code
                        </div>
                        <div className={`w-auto  font-bold cursor-pointer mr-3  ${tab === 2 ? 'text-Blue border-b-4 border-Blue' : ''} my-2`} onClick={() => tabHandle(2)}>
                            | In-Store
                        </div>
                    </div>

                    <>
                        {tab === 0 && (
                            <div>

                                <h3 className="mb-5font-normal text-darkBlack font-semibold text-4xl">Product Seeding</h3>

                                <div>
                                    {proposal.creator_address &&
                                        <p className="mt-5">
                                            <span className="block font-extrabold">
                                                {proposal.creator_address.fullname && proposal.creator_address.fullname}
                                            </span>
                                            <span className="block font-extrabold">
                                                {proposal.creator_address.address1 && proposal.creator_address.address1}, {proposal.creator_address.address2 && proposal.creator_address.address2}
                                            </span>
                                            <span className="block font-extrabold">
                                                {proposal.creator_address.city && proposal.creator_address.city}, {proposal.creator_address.state && proposal.creator_address.state} {proposal.creator_address.zipcode && proposal.creator_address.zipcode}
                                            </span>
                                            <br></br>
                                        </p>
                                    }
                                </div>
                                
                                <label
                                    htmlFor="productName"
                                    className="text-black text-left  block md:text-lg font-semibold mb-2 ">
                                    Tracking number
                                </label>
                                <input
                                    name="trackingNumber"
                                    className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                                    placeholder="Enter Tracking Number"
                                    value={markAsShippedData.trackingNumber}
                                    onChange={(e) =>
                                        setMarkAsShippedData({
                                            ...markAsShippedData,
                                            trackingNumber: e.target.value
                                        })
                                    }
                                />
                                <div className="bg-Blue rounded-md w-full px-4 text-lg font-bold py-3 text-white mt-4 w-1/4 items-center justify-center text-center cursor-pointer">
                                        <button
                                            type="submit"
                                            className="bg-Blue rounded-md w-full px-4 text-lg font-bold"
                                            onClick={handleShip}>
                                            Send Tracking Number
                                        </button>
                                    </div>

                            </div>
                        )
                        }
                        {

                            tab === 1 && (
                                <div>
                                    <label
                                        htmlFor="productName"
                                        className="text-black text-left  block md:text-lg font-semibold mb-2 ">
                                        Promo Code
                                    </label>
                                    <input
                                        name="promocode"
                                        className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                                        placeholder="Enter Promo Code"
                                        value={promoCodeData.promoCode}
                                        onChange={(e) =>
                                            setPromoCodeData({
                                                ...promoCodeData,
                                                promoCode: e.target.value
                                            })
                                        }
                                    />
                                    <label
                                        htmlFor="productName"
                                        className="text-black mt-3 text-left block md:text-lg mb-2 font-bold">
                                        Link to product page for this promo code (ex:
                                        https://shop.itsskinny.com/angel-hair/)
                                    </label>
                                    <input
                                        name="ProductLink"
                                        className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                                        placeholder="Enter Product link"
                                        value={promoCodeData.promoCodeLink}
                                        onChange={(e) =>
                                            setPromoCodeData({
                                                ...promoCodeData,
                                                promoCodeLink: e.target.value
                                            })
                                        }
                                    />
                                    <div className="bg-Blue w-full px-4 text-lg font-bold py-3 rounded-md text-white mt-4 w-1/4 items-center justify-center text-center cursor-pointer">
                                        <button
                                            type="submit"
                                            className="bg-Blue w-full px-4 text-lg font-bold"
                                            onClick={handlePromo}>
                                            Send Promo Code
                                        </button>
                                    </div>
                                </div>
                            )}
                            {

                            tab === 2 && (
                                <div>
                                    <label
                                        htmlFor="productName"
                                        className="text-black text-left  block md:text-lg font-semibold mb-2 ">
                                        In-Store
                                    </label>
                                    <div className=''>
                                        <p className='mb-1 flex-wrap'>Please specify the maximum budget you can allocate for a creator to buy your product in-store.</p>
                                    </div>
                                    <div>
                                        <div>
                                            <label
                                                htmlFor="productPrice"
                                                className="text-black mt-3 text-left block md:text-lg mb-2 font-bold">
                                                Product Price (Reimbursement Amount):
                                            </label>
                                            <div className="mr-2 mb-2 outline-none bg-gray-100 p-3 flex items-center rounded-lg w-full">
                                                <span className='mr-2 font-bold text-slate-700'>$</span>
                                                <input
                                                    name="product_price"
                                                    className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                                                    placeholder={instoreData.product_price ? instoreData.product_price : '0.00'}
                                                    type="number"
                                                    onKeyDown={(e) => {
                                                        // Allow backspace key
                                                        if (e.key === 'Backspace') {
                                                          return;
                                                        }
                                                        // Validate input to allow only whole numbers
                                                        if (!/^\d*$/.test(e.key)) {
                                                          e.preventDefault();
                                                        }
                                                      }}
                                                    required
                                                    value={instoreData.product_price}
                                                    step='0.01'
                                                    onChange={(e) => {
                                                        const inputPrice = parseFloat(e.target.value);
                                                        const formattedPrice = inputPrice % 1 === 0 ? inputPrice.toFixed(0) : e.target.value;
                                                        setInStoreData({
                                                        ...instoreData,
                                                        product_price: formattedPrice,
                                                        credits: formattedPrice
                                                        });
                                                    }}
                                                    />
                                            </div>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="retailer"
                                                className="text-black mt-3 text-left block md:text-lg mb-2 font-bold">
                                                Retailer:
                                            </label>
                                            <input
                                                name="retailer"
                                                className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                                                placeholder={task.retailer ? task.retailer : 'Enter retailer name.'}
                                                value={instoreData.retailer}
                                                onChange={(e) =>
                                                    setInStoreData({
                                                        ...instoreData,
                                                        retailer: e.target.value
                                                    })
                                                }
                                            />
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="storeLocator"
                                                className="text-black mt-3 text-left block md:text-lg mb-2 font-bold">
                                                Store Locator or Store List (must be a link):
                                            </label>
                                            <input
                                                name="storeLocator"
                                                className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                                                placeholder={task.store_list ? task.store_list : "Enter link to store list or store locator."}
                                                value={instoreData.store_link}
                                                onChange={(e) =>
                                                    setInStoreData({
                                                        ...instoreData,
                                                        store_link: e.target.value
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                    {instoreData.credits > 0 && (
                                   <h1 className='font-bold mt-2' >
                                   <span className={`${instoreData.credits <= availUsage ? 'text-black' : 'text-red'}`}>{instoreData.credits} Credits</span> ({availUsage} Credits Available)</h1>
                                   )}
                                                
                                     {instoreData.credits <= availUsage ? (     
                                     <div className="bg-Blue rounded-md w-full px-4 text-lg font-bold py-3 text-white mt-4 w-1/4 items-center justify-center text-center cursor-pointer">
                                  
                                     
                                        <CustomButton
                                            type="submit"
                                            className="bg-Blue rounded-md w-full px-4 text-lg font-bold"
                                            onClick={handleStoreReimbursment}>
                                            Send Product Reimbursement
                                        </CustomButton>
                                           </div> )
                                        : (

                                    <div className="bg-gray-400 w-full px-4 text-lg font-bold py-3 rounded-md text-white mt-4 w-1/4 items-center justify-center text-center">
                                        <span
                                        className="bg-gray-400 w-full px-4 text-lg font-bold">
                                        Insufficient credits
                                        </span>
                                    </div>
                                        )
                                        }
                             </div>
                            )}
                    </>

                    <br />
                    {error && error.trackingNumber && (
                        <span className="text-sm text-Red">{error.trackingNumber}</span>
                    )}

                </div>
            </div>
        </GlobalDialog>
    );
}

export default SendProductModal;