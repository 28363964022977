import React, { useState } from 'react';
import progressArrow from '../../Assets/progressArrow.png';
import progressCurrent from '../../Assets/progressCurrent.png';
import progressDone from '../../Assets/progressDone.png';
import creditCard from '../../Assets/visaCard.png';

function Purchasing({ handlePurchasing, handleBack }) {
  const [paymentData, setPaymetData] = useState({
    cardNumber: '',
    holderName: '',
    additionalInfo: '',
    expireDate: '',
    cvc: ''
  });
  return (
    <div className="md:w-2/5 h-auto p-5 pl-12  rounded-lg mx-auto  mt-12 bg-white">
      <h1 className="text-red font-semibold md:text-5xl xs:text-sm mb-5">
        Payment & Shipping Details
      </h1>
      <div className="flex xs:my-2 md:my-12">
        <div className="">
          <img className="mr-3 md:w-9 md:h-9 xs:w-5 xs:h-5" src={progressDone} alt="progress" />
        </div>
        <div className="text-Green md:text-4xl xs:text-xs  font-semibold">Shipping Details</div>
        <div className="">
          <img
            className="mt-1 md:mx-9 h-2 xs:mx-2 md:mt-3 md:w-24 xs:w-5"
            src={progressArrow}
            alt="progrss arrow"
          />
        </div>
        <div className="">
          <img
            className="mr-3 md:w-9 md:h-9 xs:w-5 xs:h-5"
            src={progressCurrent}
            alt="progress current"
          />
        </div>
        <div className="text-Blue md:mr-5 md:text-4xl xs:mr-2 xs:text-xs font-semibold">
          Payment
        </div>
      </div>
      <div className="flex">
        <div className="w-2/3">
          <div className="md:w-full">
            <label className="text-xs text-Lgray">Your Card Number</label>
            <br />
            <div className="flex">
              <input
                className="w-full bg-gray-100 p-3 rounded-lg"
                placeholder="Enter Your Card Number"
                value={paymentData.address}
                onChange={(e) => setPaymetData({ ...paymentData, address: e.target.value })}
                required
              />
            </div>
          </div>
          <div className="md:w-full mt-5">
            <label className="text-xs text-Lgray">Cord Holder Name</label>
            <br />
            <input
              className="w-full bg-gray-100 p-3 rounded-lg"
              value={paymentData.address}
              onChange={(e) => setPaymetData({ ...paymentData, address: e.target.value })}
              required
            />
          </div>
          <div className="md:w-full">
            <div className="flex mt-5">
              <div className="mr-5">
                <label className="text-xs text-Lgray">Expire Date</label>
                <br />
                <input
                  className="w-full font-semibold bg-gray-100 p-3 rounded-lg"
                  value="07/26"
                  placeholder="Your Postal Code"
                />
              </div>
              <div>
                <label className="text-xs text-Lgray">CVC</label>
                <br />
                <input className="w-full bg-gray-100 p-3 rounded-lg" placeholder="" value="***" />
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-full xs:hidden">
          <img src={creditCard} className="md:ml-9 xs:ml-3 md:w-5/6 xs:w-1/2" alt="credit card" />
        </div>
      </div>
      <div className="flex w-full my-3">
        <div>I accept the Terms of the User Agreement</div>
      </div>

      <div className="flex w-full my-3">
        <div className="border-2 border-gray-100  w-20  py-3 mr-2 font-bold rounded-xl text-white mt-4 items-center justify-center text-center cursor-pointer">
          <button className="w-full text-black font-semibold" onClick={handleBack}>
            Back
          </button>
        </div>
        <div className="bg-red w-32  py-3 font-bold rounded-lg text-white mt-4 items-center justify-center text-center cursor-pointer">
          <button className={'w-full'} onClick={handlePurchasing}>
            Purchase
          </button>
        </div>
      </div>
    </div>
  );
}

export default Purchasing;
