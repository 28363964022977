import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import Collapsible from 'react-collapsible';
import { BsChevronCompactDown, BsChevronCompactUp } from 'react-icons/bs';
import moment from 'moment';
import TaskDeleteModal from './TaskDeleteModal';
import EditTaskModal from './EditTaskModal';

const bg = {
  overlay: {
    background: 'rgba(128, 128, 128, 0.099)'
  }
};
const TaskDetails = (props) => {
  const [deleteTask, setDeleteTask] = useState(false);
  const [editTask,setEditTask] = useState(false);
  let products = [];
  const timezone = moment.tz.guess();
  var task_deadline_time = moment(props.task.deadline).tz(timezone).format('MMM Do YYYY, h:mm a z');
  if (props.task) {
    products = props.task.products
      ? Object.entries(props.task.products).map((e) => ({
          [e[0]]: e[1]
        }))
      : [];
  }
  return (
    <div>
     
        <Modal
          open={props.open}
          onClose={props.close}
          center
          styles={bg}
          classNames={{ modal: 'customModal' }}
          closeOnOverlayClick={true}>
          <>
            <h1 className="font-bold mb-3 font-black xs:text-lg md:text-4xl">Brief Details</h1>
            <Collapsible
              trigger={[
                'Details',
                <div className="px-3 absolute right-0">
                  <BsChevronCompactDown />
                </div>
              ]}
              triggerWhenOpen={[
                'Details',
                <div className="px-3 absolute right-0">
                  <BsChevronCompactUp />
                </div>
              ]}
              contentOuterClassName="mb-6"
              triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
              triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"></Collapsible>
            <div className="m-3 bg-white rounded-lg text-3xl dark:bg-gray-700">
              <div className="flex mb-5">
                <div className="flex mr-5 font-bold">Status:</div>
                <div className="text-green">
                  <div className="text-green">
                    {props.task && (
                      <div>
                        {props.task.status === 'available' && <span>Waiting for Creator</span>}
                        {props.task.status === 'pending' && <span>Waiting for Creator</span>}
                        {props.task.status === 'accepted' && <span>Creator Accepted</span>}
                        {props.task.status === 'approved' && <span>Creator Approved</span>}
                        {props.task.status === 'completed' && <span>Task Completed</span>}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/*<div className="flex mb-5">
                <div className="flex mr-5 font-bold">Deadline:</div>
                <div>{props.task && task_deadline_time}</div>
              </div>*/}
               {props.task && props.task.brand_name && (   
           <div className="flex mb-5">
                <div className="flex mr-5 font-bold">Brand:</div>
                <div>{props.task && props.task.brand_name}</div>
              </div>)}
              {props.task && props.task.accepted_proposals && Object.values(props.task.accepted_proposals).map((proposal, index) => (
                  <div key={index} className="flex mb-5">
                    <div className="flex mr-4 font-bold">Creator {index + 1}:</div>
                    <div>{proposal.creator_name}</div>
                  </div>
                ))}

                {props.task && props.task.note1 && (
                  <div className="flex mb-5">
                  <div className="flex mr-5 font-bold">Product:</div>
                  {props.task.note1}
                  </div>
                )}            

{products.length > 0 && (
  <div className="flex flex-col mb-5">
    <div className="flex mr-5 font-bold">Products Selected by Creator:</div>
    {products
      .slice(0)
      .reverse()
      .map((item, i) => {
        const prod = item[Object.keys(item)];
        return (
          <div className=''>
            <>
              <li>
                <div className="flex">
                  <div>
                    <strong className="mr-2">{prod.brand_name}:</strong>
                    {prod.name}
                  </div>
                </div>
                {prod.seeding_type ? (
                  <>
                    {prod.seeding_type === 'code' ? (
                      <>
                        <div className="mr-2">Code:</div>
                        <div className="mr-2">{prod.sample_product_code}</div>
                        <a
                          className="text-red font-bold"
                          href={`${prod.product_link}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          Place Order
                        </a>
                      </>
                    ) : (
                      <>
                        <div className="mr-2">Shipment Tracking:</div>
                        <a
                          className="text-red font-bold"
                          href={`https://parcelsapp.com/en/tracking/${prod.tracking_number}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          Track Order
                        </a>
                      </>
                    )}
                  </>
                ) : (
                  <div className="italic">Pending Approval</div>
                )}
              </li>
            </>
          </div>
        );
      })}
    {/* <div>{props.task.products}</div> */}
  </div>
)}
            </div>
            <div className="items-center gap-48 my-5 justify-center text-center">
            <button
              className="bg-red text-white px-2 mx-2 py-2 rounded-lg outline-none text-base"
              onClick={() => setDeleteTask(true)}
            >
              Delete Task
            </button>
            <button
              className="bg-red text-white px-2 py-2 mx-2 rounded-lg outline-none text-base"
              onClick={() => setEditTask(true)}
            >
              Edit Task
            </button>
          </div>
          </>
        </Modal>
        {deleteTask ? (
        <TaskDeleteModal
          open={deleteTask}
          task={props.task}
          close={() => setDeleteTask(false)}
          id={props.id}
        />
        ) : null}
        {editTask ? (
        <EditTaskModal
          open={editTask}
          task={props.task}
          close={() => setEditTask(false)}
          id={props.id}
        />    
        ): null}
    </div>
  );
};
export default TaskDetails;
