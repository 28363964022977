import React, { useState, useEffect } from 'react';
import { useOrderState, OrderService } from '../Service/OrderServices';
import ApproveOrderModal from './Brand_Dashboard/ApproveRequestModal';
import Toggle from './Toggle';

const Switch = (props) => {
  const [markConfirm, setMarkConfirm] = useState(false);
  const orderState = useOrderState();
  useEffect(() => {
    if (orderState.updateNeed.value) {
      OrderService.FetchOrders();
    }
  }, [orderState.updateNeed.value]);
  const res = orderState.orders.value;

  const ord = res
    ? Object.entries(res).map((e) => ({
        [e[0]]: e[1]
      }))
    : [];

  ord.map((item, i) => {
    const res = item[Object.keys(item)];
    if (res.status) {
      if (res.status.shipped) {
      }
    }
    return null;
  });
  const handleChange = (e) => {
    setMarkConfirm(true);
  };
  return (
    <div>
      <ApproveOrderModal
        open={markConfirm}
        onClose={() => setMarkConfirm(false)}
        id={props.order_id}
      />
      <form>
        <Toggle
          checked={props.checked}
          text=""
          onChange={handleChange}
          offstyle="btn-danger"
          onstyle="btn-success"
        />
      </form>
    </div>
  );
};
export default Switch;
