import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import NewProductModal from '../Brand_Dashboard/NewProductModal';
import PaymentAndShipping from './PaymentAndShiping';
import Checkout from './Checkout';
import Purchasing from './Purchasing';
import EndPurchasing from './EndPurchasing';
import Navbar from '../../Components/Navbar';

function Payment() {
  const [OpenProfileModal, setOpenProfileModal] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const history = useHistory();
  const handleContinue = (shippingData) => {
    setCurrentTab(1);
  };
  const handlePurchasing = () => {
    setCurrentTab(2);
  };
  const handleBack = () => {
    const currentT = currentTab;
    setCurrentTab(currentT - 1);
  };
  const handleBackProduct = () => {
    history.push('./products');
  };
  return (
    <>
      <Navbar />
      <div className="bg-white  rounded-lg w-full h-100 bg-gray-200">
        {currentTab === 0 && (
          <div className="md:flex xs:block">
            <div className="md:w-2/3 xs:w-full rounded-lg md:m-3">
              <PaymentAndShipping handleBack={handleBackProduct} handleContinue={handleContinue} />
            </div>
            <Checkout />
          </div>
        )}
        {currentTab === 1 && (
          <div className="md:flex xs:block">
            <div className="w-full rounded-lg mx-auto m-3">
              <Purchasing handleBack={handleBack} handlePurchasing={handlePurchasing} />
            </div>
          </div>
        )}
        {currentTab === 2 && (
          <div className="md:flex xs:block">
            <div className="w-full rounded-lg mx-auto md:m-3">
              <EndPurchasing handleBack={handleBack} handlePurchasing={handlePurchasing} />
            </div>
          </div>
        )}

        {OpenProfileModal ? (
          <NewProductModal
            closeAction={() => {
              setOpenProfileModal(false);
            }}
          />
        ) : null}
      </div>
    </>
  );
}

export default Payment;
