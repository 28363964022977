import React, { useContext, useState } from 'react'
import { useCampaignState, CampaignService } from '../../../../Service/CampaignServices';
import { toast, Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../Context/AuthContext';
import { CampaignEmailService } from '../../../../Service/CampaignEmailService';
import { BrandContext } from '../../../../routes/PrivateRoute/BrandDashboardRoute';
import CustomButton from '../../../../utils/customButton';
import Loader from '../../../Loader';
import { useCreditPackState, CreditPackService } from '../../../../Service/CreditPackServices';

function ApproveInfluencerModal(props) {
  const creditPackState = useCreditPackState();
  const { id } = useParams();
  const {currentUser} = useAuth();
  const { brandId } = useContext(BrandContext);
  const [isApproving, setIsApproving] = useState(false);
  console.log("props.task: ", props.task && props.task.traffic_link);
  console.log("props.influence rate", props.influencerRate);
  console.log("props: ", props);
  const approvedProp = async (proposal, proposal_id) => {
    let new_proposal = {
      proposal_id: proposal_id,
      proposal: proposal,
      campaign_id: id,
      brand_id: brandId,
      // task_name: props.task.name,
      // task_brief: props.task.talking_points,
      influencer_rate: Number(props.influencerRate),
      traffic_link: (props.task && props.task.traffic_link) ? props.task.traffic_link : null
    };
  
    
    const emailData = {
      brand_id: brandId,
      creator_id: proposal.creator_id,
      task_id: props.task.id,
      proposal: proposal,
      campaign_id: id,
      task_name: props.task.name,
      task_brief: props.task.talking_points
    }
  
    try {
      if (proposal ) {
        setIsApproving(true);
        creditPackState.merge({updateNeed: true});
        console.log("task id: " + proposal.task_id);
        const result = await CampaignService.ApproveProposal(proposal.task_id, new_proposal);
        await CampaignEmailService.SendApprovalEmail(emailData);
        props.fetchData(); // Reloading the proposals
        CreditPackService.FetchSubscriptionForUser(brandId);
        creditPackState.merge({updateNeed: false});
        props.close()
        toast.success(result.data.message);
        setIsApproving(false);
      }
    } catch (error) {
      setIsApproving(false)
      toast.error(error.response.data.message);
    }
  };
  return (
    <div className='bg-white rounded-md px-10 break-words text-xs my-5 h-auto md:h-fit w-1/3 md:w-1/3 flex flex-col items-center justify-center'>
      <p className='2xl:text-3xl text-base text-center mt-10'>Approve Proposal</p>
      <h1 className='flex mx-auto items-center text-base mt-10 justify-center'>
      Approving {props.proposal.creator_address.fullname}'s proposal will cost you {

parseInt(Math.floor(
                         
  (parseFloat(
    (
      props.proposal.creator_socials && 
      props.proposal.creator_socials.instagram &&
      props.proposal.creator_socials.instagram.suggested_rate !== undefined ?
      parseFloat(props.proposal.creator_socials.instagram.suggested_rate) :
      0
    ) + (
      props.proposal.creator_socials &&
      props.proposal.creator_socials.tiktok &&
      props.proposal.creator_socials.tiktok.performance &&
      props.proposal.creator_socials.tiktok.performance.suggestedRate !== undefined ?
      parseFloat(props.proposal.creator_socials.tiktok.performance.suggestedRate) :
      0
    )
  ) / 2500 * 3300) + 20000

) / 100).toLocaleString()


      } credits, would you like to continue?
      </h1>
      <div className='flex my-5 items-center justify-center gap-x-20'>
        <CustomButton onClick={() => approvedProp(props.proposal, props.proposal_id)} className=' font-bold text-white py-1 px-3 md:py-3 md:px-10'>{isApproving  && <Loader />} Activate Influencer
        </CustomButton>
        <button  onClick={() => props.close()} className='border-2 font-bold hover:bg-gray-100 text-gray-500 rounded-md py-1 px-3 md:py-3 md:px-10'>No
        </button>

      </div>
    </div>
  )
}

export default ApproveInfluencerModal