import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';

//State
const state = createState({
  account: [],
  brands: [],
  retrieving: false,
  fetched: false,
  inserted: false,
  message: '',
  loading: true,
  updateNeed: true
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'ACCOUNT_CREATED':
        return s.merge({
          account: action.data,
          updateNeed: false,
          loading: false,
        })
      case 'INVITED_ACCOUNT_CREATED':
        return s.merge({
          account: action.data,
          updateNeed: false,
          loading: false,
        })
      case 'ACCOUNT_BRANDS_LOADED':
        return s.merge({
          brands: action.data,
          updateNeed: true,
          loading: false,
          retrieving: true
        });
      case 'INVITED_BRAND_ACCOUNT_CREATED':
        return s.merge({
          account: action.data,
          updateNeed: false,
          loading: false,
          fetched: true
        });
      case 'ACCOUNT_LOADED':
        return s.merge({
          account: action.data,
          updateNeed: true,
          loading: false,
          fetched: true
        });

      default:
        return null;
    }
  }, action.type);
});

export const accessAccountState = () => state;

export const useAccountState = () => useState(state);
//Service
export const AccountService = {
  FetchBrandsForUser: async (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/accounts/getBrands/${id}`)
      .then((res) => {
        console.log("accounts brands: ", res.data.result);
        dispatch(AccountAction.loadAccountBrands(res.data.result));
      })
      .catch((err) => {});
  },
  FetchAccountInfo: async (id) => {
    const dispatch = useDispatch();

    axios
      .get(`${baseUrl}api/accounts/${id}`)
      .then((res) => {
        console.log("From AccountServices - account info: ", res.data.result);
        dispatch(AccountAction.loadAccount(res.data.result));
      })
      .catch((err) => {});
  },

  CreateAccount: (data) => {
    const dispatch = useDispatch();
    axios 
    .post(`${baseUrl}api/accounts/createAccount`, data)
    .then((res) => {
      dispatch(AccountAction.createAccount(res.data.result));
    })
  },

  CreateInvitedAccount: (data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/accounts/createInviteAccount`, data)
      .then((res) => {
        dispatch(AccountAction.createAccount(res.data.result));
      })
      .catch((err) => {});
  },

  CreateInvitedBrandAccount: (data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/accounts/createBrandAccount`, data)
      .then((res) => {
        dispatch(AccountAction.createInvitedAccount(res.data.result));
      })
      .catch((err) => {});
  }
};

//Action
export const AccountAction = {
  loadAccountBrands: (data) => {
    return {
      type: 'ACCOUNT_BRANDS_LOADED',
      data: data
    };
  },
  loadAccount: (data) => {
    return {
      type: 'ACCOUNT_LOADED',
      data: data
    };
  },
  createAccount: (data) => {
    return {
      type: 'ACCOUNT_CREATED',
      data: data
    }
  },
  createInvitedAccount: (data) =>{
    return {
      type: 'INVITED_ACCOUNT_CREATED',
      data: data
    }
  }
};
