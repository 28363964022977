import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Layout from '../../Layout/Dashboard/Creator';
import { useAuth } from '../../Context/AuthContext';
import { AuthService, userAuthstate } from '../../Service/authenticationService';
function CreatorRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        return currentUser ? (
          <Layout user={currentUser}>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to="/login"></Redirect>
        );
      }}
    />
  );
}

export default CreatorRoute;
