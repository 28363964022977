import React, {useState, useEffect} from 'react'
import ReviewModal from './ReviewModal';
import ReviewInfoModal from './ReviewInfoModal';
import ReactStars from 'react-rating-stars-component';

function ReviewCard(props) {
  console.log("ReviewCard", props.review);
  const [review, setReview] = useState(false);
  return (
    <div className="flex flex-col sm:flex-row items-center shadow-md border-2 xl:text-base md:text-0.7 mx-3 bg-white hover:bg-gray-100 p-4 cursor-pointer rounded-lg mb-4" onClick={()=> setReview(true)}>
    <img src={props.review.creator_avatar} alt="Profile" className="w-16 h-16 rounded-full mr-4 object-cover" />
    <div className="flex justify-between w-full">
      <div>
        <h4 className="font-bold">{props.review.creator_name}</h4>
        <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">{props.review.task_name}</p>
        {props.review.campaign_name ? <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">{props.review.campaign_name}</p> : null}
      </div>
      <div className="flex flex-col justify-end">
        {props.review.completed === true ? 
          <ReactStars 
            count={5}
            value={props.review.overall_rating}
            size={40}
            isHalf={true}
            edit={false}
            activeColor="#FFD700"
          /> 
          : null}
      </div>
    </div>

    {review ? 
    (props.review.completed ? 
    <ReviewInfoModal open={review} onClose={()=> setReview(false)} review={props.review} />  
      : 
      <ReviewModal open={review} onClose={()=> setReview(false)} review={props.review} /> 
    ) 
    : null}
  </div>
  )
}

export default ReviewCard