import React, { useEffect, useState, useContext } from 'react';
import TaskCard from './TaskCard';
import { useAuth } from '../../Context/AuthContext';
import { baseUrl } from '../../baseUrl';
import InfluencerTasks from './InfluencerTasks';
import { TaskService, useTaskState } from '../../Service/TaskServices';
import { BrandService, useBrandState } from '../../Service/BrandServices';
import MyTaskCard from './MyTaskCard';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';
import { useUserState , UsersService} from '../../Service/userServices';
import { useHistory } from 'react-router-dom';
import { Mixpanel } from '../../mixpanel';
import { useAccountState, AccountService } from '../../Service/AccountServices';

function Tasks(props) {
  const taskState = useTaskState();
  const userState = useUserState();
  const { currentUser, currentBrand } = useAuth();
  const history = useHistory();
  const brandState = useBrandState();
  const accountState = useAccountState();

  let account = accountState.account.value;

  useEffect(() => {
    const fetchAccountInfo = async () => {
      if (currentUser.uid !== null) {
        await AccountService.FetchAccountInfo(currentUser.uid);
      }
    };
  
    fetchAccountInfo();
  
    return () => {
      // Cleanup, if needed
    };
  }, [currentUser.uid]);
  
  useEffect(() => {
    if (accountState.account.value && accountState.account.value.brands) {
      console.log("IS BRAND");
      history.push("/brand/dashboard");
    }
  }, [accountState.account.value]);
  {/*useEffect(() => {
    const connectToTikTok = async () => {
      const url = new URL(window.location.href);
      const urlParams = new URLSearchParams(url.search);
      const code = urlParams.get('code'); // Retrieve the code parameter from the URL
  
      console.log("Code: " + code);
    
      const apiUrl = `${baseUrl}api/influencers/tiktok/onboarding/${currentUser.uid}?code=${code}`;
  
      try {
        const response = await fetch(apiUrl, { method: 'GET' });
        const data = await response.json();
  
        // Handle the response data
        console.log(data);
      } catch (error) {
        // Handle the error
        console.error(error);
      }
    };
  
    // Check if the code parameter is present in the URL
    const url = new URL(window.location.href);
    const urlParams = new URLSearchParams(url.search);
    const code = urlParams.get('code');
    if (code) {
      // Run the onboarding process
      connectToTikTok();
    }
  }, []);*/}

  let user;
  if (userState.user.value) {
    user = userState.user.value;
  }

  useEffect(() => {
    TaskService.FetchTasks();
  }, [taskState.updateNeed.value]);

  const res = taskState.tasks.value;
  const resUser = taskState.userTasks.value;
  const tasks = res ? Object.entries(res).map((e) => ({ [e[0]]: e[1] })) : [];
  const task_user = resUser ? Object.entries(resUser).map((e) => ({ [e[0]]: e[1] })) : [];


  
  return (
    <div className="bg-white md:m-4 rounded-lg w-full lg:w-full xs:h-fit overflow-y-scroll"
    style={{ height: 'calc(100vh - 5rem)' }}>
    <div className="bg-white md:m-4 rounded-lg w-full lg:w-full xs:h-fit overflow-y-scroll">
        <div >
          <h3 className="font-semibold p-4 md:mb-1 bg-white w-full h-10 2xl:text-2xl md:text-base xs:text-sm">UGC Briefs</h3>
          <h3 className=' text-gray-500 text-base ml-4 mb-2 '>For these briefs you will only be asked to generate the video assets, there will be no need to post.</h3>
        </div>
        <div className=" m-4 overflow-y-scroll md:h-16rem " style={{maxHeight: '400px'}}>
          <div className="grid lg:grid-cols-5 grid-cols-1 md:grid-cols-2 gap-2 mx-2">
            {tasks.length > 0 &&
              tasks
                .slice(0)
                .reverse()
                .map((item, i) => {
                  const res1 = item[Object.keys(item)];
                  const id = Object.keys(item);

                  // Check if the task meets the filter conditions
                  if (
                    res1.status &&
                    res1.status !== 'approved' &&
                    res1.status !== 'accepted' &&
                    res1.status !== 'revisions' &&
                    res1.status !== 'drafted' &&
                    res1.status !== 'completed' &&
                    (!res1.proposals || !Object.values(res1.proposals).some(proposal => proposal.creator_id === currentUser.uid)) &&
                    (!res1.accepted_proposals || Object.entries(res1.accepted_proposals).length < res1.product_showcase)
                  ){
                    return (
                      <div key={i}>
                        <TaskCard task={res1} id={id} />
                      </div>
                    );
                  } else {
                    // Task doesn't meet the filter conditions, don't render it
                    return null;
                  }
                })}
          </div>
        </div>
      </div>
      <div className='border-b border-2 mb-2 border-gray-300'/>
        <InfluencerTasks/>
    </div>
  );

}

export default Tasks;
