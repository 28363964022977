import React, { useState, useEffect, useContext } from 'react';
import { BiLogOut } from 'react-icons/bi';
import { FiBox, FiCreditCard, FiHome, FiShoppingBag, FiYoutube, FiZap, FiSearch, FiUsers } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import { useAuth } from '../../../src/Context/AuthContext';
import {HiOutlinePencilSquare} from 'react-icons/hi2';
import { SubscriptionService, useSubscriptionState } from '../../Service/SubscriptionServices';
import { BrandService, useBrandState } from '../../Service/BrandServices';
import { VscOrganization } from "react-icons/vsc";
import { OrgContext } from '../../routes/PrivateRoute/OrganizationDashboard';
import Loader from '../Loader2';
import { useAccountState, AccountService } from '../../Service/AccountServices';
import { useOrganizationState, OrganizationService } from '../../Service/OrganizationService';
import CustomButton from '../../utils/customButton';
function Sidebar() {
  const [pathname, setPathname] = useState('dashboard');
  const path = window.location.pathname;
  const { logout } = useAuth();
  const { currentBrand } = useAuth();
  const brandState = useBrandState();
  const subsState = useSubscriptionState();
  const {currentUser} = useAuth();
  const {id} = useParams();
  const accountState = useAccountState();
  const {orgId, setOrgId} = useContext(OrgContext);
  console.log("id", id)
  const organizationState = useOrganizationState();
  useEffect(() => {
    console.log(currentUser.uid);
    if (currentUser && currentUser.uid) {
      AccountService.FetchAccountInfo(currentUser.uid);
    }
  }, [currentUser]);
  useEffect(() => {
      OrganizationService.GetOrganization(id);
  }, [id]);
  
  let organization = organizationState.organization.value;
  console.log("organization", organization)
  console.log("current user:-", currentUser.uid)
  let credits = 0;
  console.log("credits:-", credits)
  const handleLogOut = () => {
    localStorage.removeItem('orgId');
    setOrgId('');
    logout();
  };
  useEffect(() => {
    switch (path) {
      case `/organization/dashboard/brands/${id}`:
        setPathname('dashboard');
        break;
      case `/organization/dashboard/tasks/${id}`:
        setPathname('tasks');
        break;
      case `/organization/dashboard/campaigns/${id}`:
        setPathname('campaigns');
        break;
      case `/organization/dashboard/users/${id}`:
        setPathname('users');
        break;
      case `/organization/dashboard/billing/${id}`:
        setPathname('billing');
        break;
      case `/organization/dashboard/drafts/${id}`:
        setPathname('drafts');
        break;
    }
  }, [path]);
  //console.log("pathname:-", pathname)
  //console.log("account:-", accountState.account.value)
  let account  = accountState.account.value
  credits = organization && organization.billing;
  let creditsValue = credits ? credits.paidUsage - credits.currentUsage : 0;
  //console.log("role", account && account.organizations && account.organizations[id][currentUser.uid].role );
  let role = account && account.organizations && account.organizations[id] && account.organizations[id].users[currentUser.uid] && account.organizations[id].users[currentUser.uid].role ;
  console.log("credits:-", credits)
  console.log("role:-", role)
  return (
    <div className="flex flex-col w-full md:w-64 m-4 sticky 2xl:text-base text-xs text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800 rounded-md shadow dark-mode:bg-gray-800 flex-shrink-0">
      <nav className="flex-grow md:block px-4 pb-4 md:pb-0 md:overflow-y-auto">
      <div className=' border-b-2 border-gray-300'> 
        <div
          className={
            pathname === 'cash-out'
              ? 'block px-4 py-2 mt-2 text-sm font-semibold  rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 focus:text-white focus:bg-gray-900 focus:text-white focus:shadow-outline'
          } 
          >
            <>
            {creditsValue ? (
              <h3 className='text-center md:text-center text-3xl 2xl:text-4xl font-bold'>
                {creditsValue.toLocaleString()} <span className='text-gray-500 text-sm ml-0.5'>credits</span>
              </h3>
            ) : (
              <h3 className='text-center md:text-center text-3xl 2xl:text-4xl font-bold'>
                0 <span className='text-gray-500 text-sm ml-0.5'>credits</span>
              </h3>
            )}  </>      
          <div className='my-4 text-center items-center justify-center   h-6'>
            <Link className='py-2 w-full px-10 text-3xl tracking-tightest font-extrabold rounded-lg bg-gray-200 hover:bg-gray-400 hover:text-white'
              to = {`/organization/dashboard/billing/${id}`}>Add Credits</Link>
          </div>
        </div>
      </div>
        {/*<Link
          className={
            pathname === 'dashboard'
              ? 'block px-4 py-2 mt-2 text-sm font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/brand/dashboard">
          <FiHome className="mr-2 mb-1.5 inline w-8 h-6" />
          Dashboard
        </Link>*/}
        {/*<Link
          className={
            pathname === 'tasks'
              ? 'block px-4 py-2 mt-2 text-sm font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to={"/brand/dashboard/tasks"}>
          <FiZap className="mr-2 mb-1.5 inline w-8 h-6" />
          Crafted Studio
        </Link>
        <Link
          className={
            pathname === 'campaigns'
              ? 'block px-4 py-2 mt-2 text-sm font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/brand/dashboard/campaigns">
          <FiUsers className="mr-2 mb-1.5 inline w-8 h-6" />
          Crafted Amplify
        </Link>
        <Link
          className={
            pathname === 'requests'
              ? 'block px-4 py-2 mt-2 text-sm font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/brand/dashboard/requests">
          <FiBox className="mr-2 mb-1.5 inline w-8 h-6" />
          Send Samples
        </Link>*/}
        <Link
          className={
            pathname === 'users'
              ? 'block px-4 py-2 mt-2 text-sm font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to={`/organization/dashboard/users/${id}`}>
          <VscOrganization className="mr-1 mb-1.5 inline w-8 h-6" />
          Manage Team
        </Link>
        <Link
          className={
            pathname === 'brands'
              ? 'block px-4 py-2 mt-2 text-sm font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to={`/organization/dashboard/brands/${id}`}>
          <FiYoutube className="mr-2 mb-1.5 inline w-8 h-6" />
          Manage Brands 
        </Link>
        {/*<Link
          className={
            pathname === 'drafts'
              ? 'block px-4 py-2 mt-2 text-sm font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/brand/dashboard/drafts">
          <HiOutlinePencilSquare className="mr-2 mb-1.5 font-bold inline w-8 h-6" />
          Manage Drafts
        </Link>*/}
        {role === 'admin' && (
        <Link
          className={
            pathname === 'billing'
              ? 'block px-4 py-2 mt-2 text-sm font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to={`/organization/dashboard/billing/${id}`}>
          <FiCreditCard className="mr-2 mb-1.5 inline w-8 h-6" />
          Billing
        </Link> )}
        {/*<Link
          className={
            pathname === 'creator'
              ? 'block px-4 py-2 mt-2 text-sm font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/brand/creatorlookup">
          <FiSearch className="mr-2 mb-1.5 inline w-8 h-6" />
          Creator Search
        </Link>*/}
        
      </nav>

      <CustomButton className="bg-red text-white font-bold py-2 px-1 text-lg m-2 rounded-md" onClick={handleLogOut}>
        <BiLogOut className="mr-2 mb-1.5 inline w-8 h-6" />
        Logout
      </CustomButton>
    </div>
  );
}

export default Sidebar;
