import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';

//State
const state = createState({
  briefs: [],
  brief: [],
  retrieving: false,
  fetched: false,
  loading: true,
  updateNeed: true
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'BRIEFS_LOADED':
        return s.merge({
          briefs: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true
        });
      case 'BRAND_BRIEFS_LOADED':
        return s.merge({
          briefs: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true
        });
      case 'CREATE_BRIEF_TEMPLATE':
        return s.merge({
          brief: action.data,
          loading: false,
          updateNeed: true
        });
      case 'BRIEF_LOADED':
        return s.merge({
          brief: action.data,
          loading: false,
          updateNeed: true
        });
      case 'BRIEF_UPDATE':
        return s.merge({
          brief: action.data,
          updateNeed: true,
          loading: false
        });
      case 'BRIEF_UPDATE_TASK':
        return s.merge({
          brief: action.data,
          updateNeed: true,
          loading: false
        });
        case 'BRIEF_DELETE':
        return s.merge({
          brief: action.data,
          updateNeed: true,
          loading: false
        });
      default:
        return null;
    }
  }, action.type);
});

export const accessGenerativeBriefState = () => state;

export const useGenerativeBriefState = () => useState(state);

//Service
export const GenerativeBriefService = {

  getBrandGenBriefs: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/generativeBrief/templates/${id}`)
      .then((res) => {
       dispatch(GenerativeBriefAction.loadBrandBriefTemplates(res.data));
      })
      .catch((err) => {
        // handle the error
      });
  },
  createBriefTemplate: (data) => {
    const dispatch = useDispatch();
    return axios
      .post(`${baseUrl}api/generativeBrief/createAmplifyBrief`, data)
      .then((res) => {
        dispatch(GenerativeBriefAction.createBriefTemplate(res.data));
        // Retrieve the brief_id and briefData from the response
        const briefId = res.data.brief_id;
        const briefData = res.data.data;
        // Return the briefId and the briefData
        return { briefId, briefData };
      })
      .catch((err) => {
        // handle the error
      });
  },
  getAllGenBriefs: () => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/generativeBriefs`)
      .then((res) => {
        dispatch(GenerativeBriefAction.loadbriefs(res.data));
      })
      .catch((err) => {
        // handle the error
      });
  },
  getBriefTemplate: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/generativeBrief/${id}`)
      .then((res) => {
        dispatch(GenerativeBriefAction.loadSingleReview(res.data));
      })
      .catch((err) => {
        // handle the error
      });
  },

  updateBriefTemplate: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/generativeBrief/updateBrief/${id}`, data)
      .then((res) => {
        dispatch(GenerativeBriefAction.updateBriefTemplate(res.data));
      })
      .catch((err) => {
        // handle the error
      });
  },

  updateStudioTaskBrief: (data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/generativeBrief/updateTaskBrief`, data)
      .then((res) => {
        dispatch(GenerativeBriefAction.updateBriefTemplate(res.data));
      })
      .catch((err) => {
        // handle the error
      });
  },

  deleteBriefTemplate: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/generativeBrief/deleteBrief/${id}`, data)
      .then((res) => {
        dispatch(GenerativeBriefAction.deleteBriefTemplate(res.data));
      })
      .catch((err) => {
        // handle the error
      });
  }
}
//Action
export const GenerativeBriefAction = {
  loadBriefTemplates: (data) => {
    return {
      type: 'BRIEFS_LOADED',
      data: data
    };
  },
  loadBrandBriefTemplates: (data) => {
    return {
      type: 'BRAND_BRIEFS_LOADED',
      data: data
    };
  },
  createBriefTemplate: (data) => {
    return {
      type: 'CREATE_BRIEF_TEMPLATE',
      data: data
    };
  },
  loadBriefTemplate: (data) => {
    return {
      type: 'BRIEF_LOADED',
      data: data
    };
  },
  updateBriefTemplate: (data) => {
    return {
      type: 'BRIEF_UPDATE',
      data: data
    };
  },

  updateStudioTaskBrief: (data) => {
    return {
      type: 'BRIEF_UPDATE',
      data: data
    };
  },
  deleteBriefTemplate: (data) => {
    return {
      type: 'BRIEF_DELETE',
      data: data
    };
  }

};