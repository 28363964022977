import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';

//State
const state = createState({
  creditPack: [],
  videosData:{},
  retrieving: false,
  fetched: false,
  loading: true,
  credit:0,
  updateNeed: true
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'CREDIT_PACK_ADDED':
        return s.merge({
          creditPack: action.data,
          loading: false,
          updateNeed: true
        });
      case 'STRIPE_CUSTOMER_CREATED':
        return s.merge({
          creditPack: action.data,
          loading: false,
          updateNeed: true
        });
      case 'SUBSCRIPTION_INCREMENTED':
        return s.merge({
          updateNeed: true,
          videosData:action.data,
          loading: false
        });
      case 'SUBSCRIPTION_DECREMENTED':
        return s.merge({
          updateNeed: true,
          videosData: action.data,
          loading: false
        });
      case 'SUBSCRIPTION_LOADED':
        return s.merge({
          creditPack: action.data,
          updateNeed: false,
          loading: false,
          fetched: true
        });

      default:
        return null;
    }
  }, action.type);
});

export const accessCreditPackState = () => state;

export const useCreditPackState = () => useState(state);
//Service
export const CreditPackService = {
  FetchSubscriptionForUser: (id, data) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/subscription/billing/${id}`, data)
      .then((res) => {
        if (res.data) {
          dispatch(CreditPackAction.loadUserSubscription(res.data.billing_info));
        } else {
          dispatch(CreditPackAction.loadUserSubscription('new_user'));
        }
      })
      .catch((err) => {
       console.log("err:-", err)
      });
  },


  CreateCustomer: async (id, data) => {
    const dispatch = useDispatch();
    return await axios
      .post(`${baseUrl}api/studio/createStripeCustomer/${id}`, data)
      .then((res) => {
        dispatch(CreditPackAction.createStripeCustomer(res.data));
        if (res.data.statuscode) {
          // dispatch(CreditPackAction.insertSubscription(res.data.message));
        }
        return res;
      })
  },

  AddCreditPack: async (data) => {
    const dispatch = useDispatch();
    return await axios
      .post(`${baseUrl}api/studio/addCreditPack`, data)
      .then((res) => {
        dispatch(CreditPackAction.addCreditPack(res));
        if (res.data.statuscode) {
          // dispatch(CreditPackAction.insertSubscription(res.data.message));
        }
        return res
      })
  },

  IncrementCredits: async (id, data) => {
    const dispatch = useDispatch();
    return await axios
      .post(`${baseUrl}api/studio/addCredits/${id}`, data)
      .then((res) => {
        let datas = { res, data };
        console.log("datas:---", datas);
        dispatch(CreditPackAction.incrementSubscription(datas));
        return res;
      });
  },

  DecrementVideo: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/studio/decrementVideo`, data)
      .then((res) => {
          let datas = { res, data };
        dispatch(CreditPackAction.decrementSubscription(datas));
      })
      .catch((err) => {});
  },


};

//Action
export const CreditPackAction = {
  loadUserSubscription: (data) => {
    return {
      type: 'SUBSCRIPTION_LOADED',
      data: data
    };
  },
  createStripeCustomer: (data) => {
    return {
      type: 'STRIPE_CUSTOMER_CREATED',
      data: data
    };
  },
  addCreditPack: (data) => {
    return {
      type: 'CREDIT_PACK_ADDED',
      data: data
    };
  },
  incrementSubscription: (data) => {
    return {
      type: 'SUBSCRIPTION_INCREMENTED',
      data: data
    };
  },
  decrementSubscription: (data) => {
    return {
      type: 'SUBSCRIPTION_DECREMENTED',
      data: data
    };
  }
};
