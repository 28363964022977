/* eslint-disable no-useless-escape */
import React, { useState, useRef } from 'react';
import '../../../App.css';
import axios from 'axios';
import { useAuth } from '../../../Context/AuthContext';
import { UsersService, useUserState } from '../../../Service/userServices';
import Loader from '../../Loader';

function UserAccount(props) {
  const userState = useUserState();
  const userEmail = useRef('');
  const userPassword = useRef('');
  const userPasswordConfirm = useRef('');
  const userName = useRef('');
  const { signup } = useAuth();
  const [error, setError] = useState({
    email: '',
    password: '',
    confirmPass: '',
    username: ''
  });
  const [loading, setLoading] = useState(false);
  const [setCurrentTab] = useState(0);

  var new_user = {};
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const usernameRe = /^[a-z0-9_\.]+$/;

  const onChange = (e) => {
    setError({ ...error, [e.target.name]: '' });
  };

  const handleChange = (e) => {
    if (e.currentTarget.value.includes(' ')) {
      e.currentTarget.value = e.currentTarget.value.replace(/\s/g, '');
    }
  };

  async function handleSubmit(e) {
    userState.merge({ loading: true });
    e.preventDefault();

    let err = error;

    if (userPassword.current.value !== userPasswordConfirm.current.value) {
      err.password = 'Passwords do not match.';
      return setError({ ...error, err });
    } else if (userPassword.current.value.length < 7) {
      err.email = 'Password must be at least 6 characters long.';
      return setError({ ...error, err });
    } else if (!re.test(userEmail.current.value)) {
      err.email = 'Email is not valid.';
      return setError({ ...error, err });
    } else if (!usernameRe.test(userName.current.value)) {
      err.username = 'You can only use alphanumerical characters, underscores and dots';
      return setError({ ...error, err });
    }
    if (!userPassword.current.value) {
      err.password = 'Password is required';
    }
    if (!userName.current.value) {
      err.userName = 'User name is required';
    }
    if (!userPasswordConfirm.current.value) {
      err.confirmPass = 'Confirm Password is required';
    } else if (userPassword.current.value.length < 6) {
      err.confirmPass = 'Password should be at least 6 characters';
    } else if (userPassword.current.value !== userPasswordConfirm.current.value) {
      err.confirmPass = 'Confirm Password and Password is not match';
    }

    try {
      setError({
        email: '',
        password: '',
        confirmPass: '',
        userName: '',
        phoneNumber: '',
        common: ''
      });
      setLoading(true);
      await axios
        .get('api/users/checkUsername/' + userName.current.value.toLowerCase())
        .then((res) => {
          if (!res.data.result) {
            err.username = 'Username is taken.';
            return setError({ ...error, err });
          }
        })
        .catch((error) => {});

      await signup(userEmail.current.value, userPassword.current.value.trim())
        .then((userCreds) => {
          new_user = {
            uid: userCreds.user.uid,
            username: userName.current.value.toLowerCase(),
            email: userEmail.current.value
          };
          UsersService.AddUser(new_user);
          setCurrentTab(1);
        })
        .catch((__err) => {});
    } catch (__error) {
      err.common = __error;
      setError({ ...error, err });
    }
    setLoading(false);
    props.handleComplete();
  }

  return (
    <div>
      <>
        <div className="mb-3">
          <label className="text-darkBlack font-bold capitalize">Add New User</label>
          <br></br>
        </div>
        <div>
          <div className="mb-3">
            <label className="text-darkBlack font-bold capitalize">Username</label>
            <input
              className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
              name="userName"
              onChange={handleChange}
              type="text"
              ref={userName}
              required
            />
            {error && error.username && <span className="text-sm text-Red">{error.username}</span>}
          </div>

          <div className="mb-3">
            <label className="text-darkBlack font-bold capitalize">Email</label>
            <input
              className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
              name="email"
              onChange={onChange}
              type="email"
              ref={userEmail}
              required
            />
            {error && error.email && <span className="text-sm text-Red">{error.email}</span>}
          </div>
          <div className="mb-3">
            <label className="text-darkBlack font-bold capitalize">Password</label>
            <input
              className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
              name="password"
              onChange={onChange}
              type="password"
              ref={userPassword}
              required
            />
            {error && error.password && <span className="text-sm text-Red">{error.password}</span>}
          </div>
          <div className="mb-10">
            <label className="text-darkBlack font-bold capitalize">Confirm Password</label>
            <input
              className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
              name="confirmPass"
              onChange={onChange}
              type="password"
              ref={userPasswordConfirm}
              required
            />
            {error && error.confirmPass && (
              <span className="text-sm text-Red">{error.confirmPass}</span>
            )}
          </div>
          <p>{error && error.common && error.common}</p>
          <button
            className="text-xl font-bold my-3 w-full mx-auto bg-Red rounded text-white py-2 px-14 block"
            type="button"
            disabled={loading}
            onClick={handleSubmit}>
            {userState.loading.value && <Loader />}
            Register User
          </button>
        </div>
      </>
    </div>
  );
}

export default UserAccount;
