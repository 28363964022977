import React, { useRef, useState } from 'react';
import { useAuth } from '../Context/AuthContext';
import S3FileUpload from 'react-s3';
import s3 from '../aws-s3';
import '../App.css';
import Loader from './Loader';
import { AuthService } from '../Service/authenticationService';
import { Mixpanel } from '../mixpanel';
import CustomButton from '../utils/customButton';

function AccountCreationModal(props) {
  const name = useRef('');
  const bio = useRef('');
  const fileRef = useRef(null);
  const [setImgLocation] = useState('');
  const { currentUser } = useAuth();
  const [loader, setLoader] = useState(false);
  const [imgUploaded, setImgUpload] = useState(false);
  const [userData, setUserData] = useState({
    name: '',
    avatar: '',
    bio: ''
  });
  const [error, setError] = useState({
    avatar: '',
    name: '',
    bio: ''
  });
  let update = {
    avatar: '',
    name: '',
    bio: ''
  };

  const onChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: '' });
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImgUpload(true);
    } else {
      setImgUpload(false);
    }
  };

  const closeModal = () => {
    if (props.handleClose) props.handleClose();
  };

  async function handleSubmit() {
    const userdata = userData;
    const avatarInput = fileRef.current.files[0];
    if (!avatarInput) {
      return setError({ ...error, avatar: 'avatar is required' });
    }
    if (name.current.value === '') return setError({ ...error, name: 'name is required' });
    if (bio.current.value === '') return setError({ ...error, bio: 'bio is required' });

    setLoader(true);
    try {
      setError({ avatar: '', name: '', bio: '' });
      if (imgUploaded) {
        await S3FileUpload.uploadFile(fileRef.current.files[0], s3).then((data) => {
          setImgLocation(data.location.toString());
          update = {
            bio: bio.current.value,
            name: name.current.value,
            avatar: data.location
          };
        });
      } else {
        update = {
          bio: bio.current.value,
          name: name.current.value,
          avatar: ''
        };
      }
      userdata.name = update.name;
      userdata.bio = update.bio;
      userdata.avatar = update.avatar;
      await AuthService.UpdateAuthUser(currentUser.uid, userdata);
      Mixpanel.completeRegistration(userdata);
      setLoader(false);
      closeModal();
    } catch (error) {
      setError('Failed to Update Profile');
    }
  }

  return (
    <>
      <div>
        <>
          <div className="mb-3">
            <label className="text-darkBlack font-bold capitalize">Profile Picture</label>
            <br></br>
            <input
              id="imageInput"
              type="file"
              ref={fileRef}
              className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
              onChange={handleChange}
            />
            {error && error.avatar && <span className="text-sm text-Red">{error.avatar}</span>}
          </div>
        </>
        <></>
        <div className="mb-3">
          <label className="text-darkBlack font-bold capitalize">Name</label>
          <input
            className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
            type="text"
            name="name"
            onChange={onChange}
            ref={name}
          />
          {error && error.name && <span className="text-sm text-Red">{error.name}</span>}
        </div>
        <div className="mb-3">
          <label className="text-darkBlack font-bold capitalize">Bio</label>
          <textarea
            className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
            name="bio"
            onChange={onChange}
            ref={bio}
          />
          {error && error.bio && <span className="text-sm text-Red">{error.bio}</span>}
        </div>
        <CustomButton
          className=" mt-2 mx-auto rounded text-white py-2 px-14 block"
          type="button"
          disabled={loader}
          onClick={() => handleSubmit()}>
          {loader && <Loader />}
          Save
        </CustomButton>
      </div>
    </>
  );
}
export default AccountCreationModal;
