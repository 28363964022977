export const baseUrl =
  process.env.REACT_APP_BASE_URL ||
  "https://crafted-web-dev-server.herokuapp.com/";

// 'https://crafted-web-prod-server.herokuapp.com/';

// "https://crafted-web-dev-server.herokuapp.com/";

// 'https://crafted-web-staging-server-17363be83ce5.herokuapp.com/'

// 'http://localhost:3080/'
