import React, { useRef, useState, useEffect, useContext} from 'react'
import GlobalDialog from '../../utils/brandModal'
import Loader from '../Loader';
import { OrganizationService, useOrganizationState } from '../../Service/OrganizationService';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../Context/AuthContext';
import S3FileUpload from 'react-s3';
import { AccountService, useAccountState } from '../../Service/AccountServices';
import { BrandService, useBrandState } from '../../Service/BrandServices';
import s3 from './../../aws-s3';
import toast, { Toaster } from 'react-hot-toast';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';
function NewBrandModal(props) {
  const orgState = useOrganizationState();
  const { currentUser } = useAuth();
  const {brandId} = useContext(BrandContext);
  const accountState = useAccountState();
  useEffect(() => {
    if (brandId !== null) {
      AccountService.FetchAccountInfo(currentUser.uid);
    }
    
  }, [accountState.updateNeed.value, brandId]);
  const [error, setError] = useState({
    brandname: '',
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmpassword: ''
  });
  const [companyData, setCompanyData] = useState({
    name: '',
    avatar: '',
    website: '', 
    bio: ''
  });

  let update = {
    avatar: '',
    name: '',
    website: '',
    bio: ''
  };
  const [imgUploaded, setImgUpload] = useState(false);
  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "campphoto") {
        const selectedFile = e.target.files[0];
        S3FileUpload.uploadFile(selectedFile, s3, { uniquePrefix: true })
            .then(data => {
                setCompanyData(prevData => ({
                    ...prevData,
                    avatar: data.location // save the URL of the uploaded file as the avatar
                }));
            })
            .catch(error => {
                // Handle error
            });
    } else {
        setCompanyData(prevData => ({
            ...prevData,
            [name]: value
        }));
    }
    setError(prevError => ({
        ...prevError,
        [name]: ''
    }));  
};
  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImgUpload(true);
    } else {
      setImgUpload(false);
    }
  };

  const account = accountState.account.value;
  const userEmail = useRef('');
  const userPassword = useRef('');
  const userPasswordConfirm = useRef('');
  const brandName = useRef('');
  const firstName = useRef('');
  const lastName = useRef('');
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [isTermsChecked,  setIsTermsChecked] = useState(false);
  const fileRef = useRef(null);
  const {id} = useParams();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      let avatarLocation = '';
      if (imgUploaded) {
        const data = await S3FileUpload.uploadFile(fileRef.current.files[0], s3, {
          uniquePrefix: true,
        });
        avatarLocation = data.location;
      }
  
      const data = {
        brand_name: companyData.name,
        org_id: id,
        brand_avatar: avatarLocation !== '' ? avatarLocation : companyData.avatar,
        uid: currentUser.uid,
        brand_website: companyData.website,
        bio: companyData.bio,
        first_name : account.first_name,
        last_name: account.last_name,
        email: account.email,
      };
  
      await OrganizationService.CreateBrand(id, data);
      console.log("data: ", data);
      toast.success('Brand Created Successfully');  
      setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, 2000)
    } catch (error) {
      console.error('Error creating brand:', error);
      // Handle error
    }
  }
  return (

      <GlobalDialog open= {props.open} onClose={props.onClose} >
        <Toaster/>
        <div className='flex flex-col items-center p-8'>
          <div className='font-bold text-3xl text-center w-full mb-2 border-b-2'>
            Create New Brand
          </div>

          <div className="my-0.5 mt-2 md:mt-4 w-full">
          <label htmlFor="campphoto" className="text-darkBlack font-bold md:text-lg mb-2">
           Company Photo
          </label>
          <input
            name="campphoto"
            className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
            placeholder=""
            required
            type='file'
            accept="image/png, image/jpeg, image/jpg, image/webp"
            onChange={onChange}
          />
        </div>
          <div className="mb-3 mt-3 w-full">
            <label className="text-darkBlack font-bold capitalize">Brand Name</label>
            <input
              className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
              name="name"
              onChange={onChange}
              type="text"
              required
            />
            {error && error.brandname && <span className="text-sm text-Red">{error.brandname}</span>}
          </div>
        
          <div className="mb-3 w-full">
            <label className="text-darkBlack font-bold capitalize">Website</label>
            <input
              className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
              name="website"
              onChange={onChange}
              type="text"
          
              required
            />
            {error && error.email && <span className="text-sm text-Red">{error.email}</span>}
          </div>
          <div className=" ml-1 w-full">
            <input type="checkbox" id="isTermsChecked" name="isTermsChecked" value={isTermsChecked} onChange={(e) => setIsTermsChecked(e.target.checked)} />
            <span className="mx-2 hover:text-black relative">
             I acknowledge that I have read and accepted the <a className='text-red underline' target = "_blank" href = 'https://crafted-react-images.s3.amazonaws.com/Client Terms of Services.docx.pdf'>Terms of Service</a>
            </span> 
          <p className='mt-1'>{error && error.terms && <span className="text-sm text-Red">{error.terms}</span>}</p>
          </div>
     
          <p>{error && error.common && error.common}</p>
          <button
            className="text-xl font-bold my-3 w-full mx-auto bg-Blue rounded-md text-white py-2 px-14 block"
            type="button"
            disabled={loading}
            onClick={(e) => handleSubmit(e)}>
            {loading && <Loader />}
            Create Brand
          </button>
        </div>  
      </GlobalDialog>

  )
}

export default NewBrandModal