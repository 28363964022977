import React, { useEffect, useState } from 'react';
import NewTaskModal from './NewTaskModal';
import TaskCard from './TaskCard';
import TasksTable from './TasksTable';
import { TaskService, useTaskState } from '../../../Service/TaskServices';
import { useHistory } from 'react-router-dom';
import { useUserState } from '../../../Service/userServices';
import { useAuth } from '../../../Context/AuthContext';
import { useBrandState, BrandService } from '../../../Service/BrandServices';
function Tasks() {
  const taskState = useTaskState();
  const userState = useUserState();
  const { currentBrand, currentUser } = useAuth();
  const history = useHistory();

  const brandState = useBrandState();
  console.log(currentUser.uid)
  useEffect(() => {
    if (currentUser.uid !== null) {
      BrandService.FetchSingleBrand(currentUser.uid);
    }
  }, [brandState.updateNeed.value]);

  const brand = brandState.brand.value;

  {/*IuseEffect(() => {
    if (brand === null){
      history.push('/');
    }
    if (brand && Object.keys(brand).length > 0) {
      console.log("Brand object populated:", brand);
      if (brand.admin === true) {
        console.log("Brand admin is true");
      }
      else {
      history.push('/');
    } 
    }
  }, [brand]); */}


  useEffect(() => {
    let i = 0;
    const checkAdmin = async () => {
      if ((userState.user.value && userState.user.value.admin === true) || i === 0) {
        i++
      } else {
        history.push('/');
      }
    };
  
    if (userState.user.value) {
      checkAdmin();
    }
  }, [userState.user.value, history]);
  const [addTask, setAddTask] = useState();
  const [status, setStatus] = useState('available');

  useEffect(() => {
    TaskService.FetchTasks();
  }, [taskState.updateNeed.value]);
  console.log("addTask:---", addTask)

  const res = taskState.tasks.value;
  const task = res
    ? Object.entries(res).map((e) => ({
        [e[0]]: e[1]
      }))
    : [];
    console.log("res:---", res)
  return (
    <div className="bg-white m-4 h-40 rounded-lg lg:w-full xs:h-fit">
      <div className="flex items-center justify-between">
        <h3 className="font-semibold p-4 pl-5 ml-12 md:text-2xl xs:text-sm"></h3>
        <button
          className="bg-red text-white px-4 py-2 mt-3 rounded-lg mr-8"
          onClick={() => setAddTask(true)}>
          + Add Task
        </button>
      </div>
      <div className="flex">
        <div
          onClick={() => setStatus('available')}
          className={`m-5 px-3 cursor-pointer bg-${
            status === 'available' ? 'red' : 'gray-400'
          } text-white rounded-lg`}>
          Available
        </div>
        <div
          onClick={() => setStatus('approved')}
          className={`m-5 px-3 cursor-pointer bg-${
            status === 'approved' ? 'red' : 'gray-400'
          } text-white rounded-lg`}>
          Creator Selected
        </div>
        <div
          onClick={() => setStatus('drafted')}
          className={`m-5 px-3 cursor-pointer bg-${
            status === 'drafted' ? 'red' : 'gray-400'
          } text-white rounded-lg`}>
          Draft Submitted
        </div>
        <div
          onClick={() => setStatus('revisions')}
          className={`m-5 px-3 cursor-pointer bg-${
            status === 'revisions' ? 'red' : 'gray-400'
          } text-white rounded-lg`}>
          Revisions Needed
        </div>
        <div
          onClick={() => setStatus('completed')}
          className={`m-5 px-3 cursor-pointer bg-${
            status === 'completed' ? 'red' : 'gray-400'
          } text-white rounded-lg`}>
          Completed
        </div>
      </div>
      <>
        <div className="grid lg:grid-cols-4 grid-cols-1 h-41 overflow-y-scroll md:grid-cols-2 gap-2 mx-2">
        {task.length > 0 &&
            task
              .slice(0)
              .reverse()
              .map((item, i) => {
                const res = item[Object.keys(item)];
                const id = Object.keys(item);
                if (
                  (status === 'available' && res.status !== 'completed' && (res.status === 'available' && !res.accepted_proposals && res.status !== 'completed')) ||
                  (status=== 'approved' && res.status !== 'completed' && (res.status === 'approved' || (res.accepted_proposals && res.product_showcase <= Object.keys(res.accepted_proposals).length))) ||
                  (status === 'drafted' && res.status !== 'completed' && (res.status === 'drafted' || (res.status === 'approved' && res.drafts))) ||
                  (status === 'revisions' && res.status !== 'completed' &&
                  (res.status === 'revisions' || (  res.status === 'approved' && 
                     res.drafts && 
                     Object.values(res.drafts).some(draft => draft.revision_notes)
                    ) ))
                   ||
                  (status === 'completed' && (res.status === 'completed' || (res.product_showcase && res.drafts && Object.values(res.drafts).filter(draft => draft.status === 'approved').length >= res.product_showcase)))
                ) {
                  return (
                    <div key={i}>
                      <TaskCard task={res} id={id} />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
        </div>
      </>
      <NewTaskModal isOpen={addTask} closeAction={() => setAddTask(false)} />
    </div>
  );
}

export default Tasks;
