
import React, { useState } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import './Carousel.css';
import logo from '../../../../Assets/logo-red.png';
const data = [
    {
      id: 'item-1',
      imgUrl:
      'https://images.unsplash.com/photo-1559386484-97dfc0e15539?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1234&q=80',
      title: 'Bunker',
      artist: 'Balthazar',
      duration: '4.05',
    },
    {
      id: 'item-2',
      imgUrl:
        'https://images.unsplash.com/photo-1559386484-97dfc0e15539?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1234&q=80',
      title: 'Words Remain',
      artist: 'Moderator',
      duration: '4.05',
    },
    {
      id: 'item-3',
      imgUrl:
        'https://images.unsplash.com/reserve/EnF7DhHROS8OMEp2pCkx_Dufer%20food%20overhead%20hig%20res.jpg?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1178&q=80',
      title: 'Falling Out',
      artist: 'Otzeki',
      duration: '4.05',
    },
    {
      id: 'item-3',
      imgUrl:
        'https://images.unsplash.com/photo-1547592180-85f173990554?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80',
      title: 'Falling Out',
      artist: 'Otzeki',
      duration: '4.05',
    },
    {
      id: 'item-3',
      imgUrl:
        'https://images.unsplash.com/photo-1605522469906-3fe226b356bc?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1172&q=80',
      title: 'Falling Out',
      artist: 'Otzeki',
      duration: '4.05',
    },
    {
      id: 'item-3',
      imgUrl:logo,
      title: 'Falling Out',
      artist: 'Otzeki',
      duration: '4.05',
    },
    // Add more images and songs as needed...
  ];

const Carousel = () => {
  const [selectedItem, setSelectedItem] = useState(data[0]);

  const handleChange = (e) => {
    const selectedItemId = e.target.value;
    const selectedData = data.find((item) => item.id === selectedItemId);
    setSelectedItem(selectedData);
  };

  const moveNext = () => {
    const currentIndex = data.findIndex((item) => item.id === selectedItem.id);
    const nextIndex = (currentIndex + 1) % data.length;
    setSelectedItem(data[nextIndex]);
  };

  const movePrevious = () => {
    const currentIndex = data.findIndex((item) => item.id === selectedItem.id);
    const previousIndex = (currentIndex - 1 + data.length) % data.length;
    setSelectedItem(data[previousIndex]);
  };

  return (
    <div className="container">
      <button className="move-btn left-0" onClick={movePrevious}>
        <FaArrowLeft />
      </button>
      {data.map((item) => (
        <input
          key={item.id}
          type="radio"
          name="slider"
          id={item.id}
          value={item.id}
          checked={selectedItem.id === item.id}
          onChange={handleChange}
        />
      ))}
      <div className="cards">
        {data.map((item, index) => (
          <label key={index} className="card" htmlFor={item.id} id={`song-${index + 1}`}>
            <video  controls src="https://crafted-react-images.s3.amazonaws.com/vid_1.mp4" />
          </label>
        ))}
      </div>
      <button className="move-btn right-0" onClick={moveNext}>
        <FaArrowRight />
      </button>
    </div>
  );
};

export default Carousel;
