import React, { useState } from 'react';
import { countryList } from './country';
import { useAuth } from '../../Context/AuthContext';
import progressArrow from '../../Assets/progressArrow.png';
import progressCurrent from '../../Assets/progressCurrent.png';
import progressUnreached from '../../Assets/progressUnreached.png';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

function PaymentAndShipping({ handleContinue, handleBack }) {
  const { currentUser } = useAuth();
  const [shippingData, setShippingData] = useState({
    country: '',
    address: '',
    additionalInfo: '',
    city: '',
    province: '',
    postalCode: '',
    phone: '',
    uid: currentUser.uid
  });
  const [error, setError] = useState({
    country: '',
    address: '',
    additionalInfo: '',
    city: '',
    province: '',
    postalCode: '',
    phone: '',
    uid: currentUser.uid
  });
  const handleShippingFormValidation = () => {
    if (shippingData.country === '') {
      return setError({
        error,
        country: 'Country field is required'
      });
    } else if (shippingData.address === '') {
      return setError({
        error,
        address: 'Address field is required'
      });
    } else if (shippingData.city === '') {
      return setError({
        error,
        city: 'City field is required'
      });
    } else if (shippingData.province === '') {
      return setError({
        error,
        province: 'Province field is required'
      });
    } else if (shippingData.postalCode === '') {
      return setError({
        error,
        postalCode: 'Postal code field is required'
      });
    } else if (shippingData.phone === '') {
      return setError({
        error,
        phone: 'Phone  field is required'
      });
    } else {
      handleContinue(shippingData);
    }
  };
  return (
    <div className="h-auto md:p-12 xs:p-3 md:mr-8 rounded-xl md:ml-80 md:mt-12 md:w-2/3 xs:mt-2 bg-white">
      <h1 className="text-red font-semibold md:text-5xl xs:text-sm mb-5">Payment & Details</h1>
      <div className="flex xs:my-2 md:my-12">
        <div className="">
          <img
            className="mr-3 md:w-9 md:h-9 xs:w-5 xs:h-5"
            src={progressCurrent}
            alt="ps_current"
          />
        </div>
        <div className="text-Blue md:text-4xl xs:text-xs  font-semibold">Shipping Details</div>
        <div className="">
          <img
            className="mt-1 md:mx-9 h-2 xs:mx-2 md:mt-3 md:w-24 xs:w-5"
            src={progressArrow}
            alt="ps_arrow"
          />
        </div>
        <div className="">
          <img
            className="mr-3 md:w-9 md:h-9 xs:w-5 xs:h-5"
            src={progressUnreached}
            alt="ps_reached"
          />
        </div>
        <div className="text-gray-400 md:mr-5 md:text-4xl xs:mr-2 xs:text-xs font-semibold">
          Payment
        </div>
      </div>
      <div className="bg-white rounded-xl ">
        <div className="md:flex w-full mb-2">
          <div className="md:w-2/4.5 md:mr-3">
            <label className="text-xs text-Lgray">Your Country</label>
            <br />
            <select
              className="w-full bg-gray-100 p-3 font-semibold rounded-xl"
              value={shippingData.country}
              onChange={(e) => setShippingData({ ...shippingData, country: e.target.value })}
              required>
              <option key={'a'}>Select Country</option>
              {countryList.map((country, index) => (
                <option key={index}>{country.name}</option>
              ))}
            </select>
          </div>
          <div className="md:w-2/4.5 xs:ml-0 md:ml-5">
            <label className="text-xs text-Lgray">Address</label>
            <br />
            <input
              className="w-full bg-gray-100 p-3 rounded-xl"
              placeholder="Type your address"
              type="text"
              value={shippingData.address}
              onChange={(e) => setShippingData({ ...shippingData, address: e.target.value })}
              required
            />
          </div>
        </div>
        <div className="md:flex w-full mb-2">
          <div className="md:w-2/4.5 mr-3">
            {error && error.country && <span className="text-sm text-Red">{error.country}</span>}
          </div>
          <div className="md:w-2/4.5 ml-5">
            {error && error.address && <span className="text-sm text-Red">{error.address}</span>}
          </div>
        </div>
        <div className="md:flex w-full mb-2">
          <div className="md:w-2/4.5 md:mr-3">
            <label className="text-xs text-Lgray">Additional Information(Optional)</label>
            <br />
            <input
              className="w-full bg-gray-100 p-3 rounded-xl"
              placeholder="United States"
              value={shippingData.additionalInfo}
              onChange={(e) =>
                setShippingData({
                  ...shippingData,
                  additionalInfo: e.target.value
                })
              }
              required
            />
          </div>
          <div className="md:w-2/4.5 md:ml-5">
            <label className="text-xs text-Lgray">City</label>
            <br />
            <input
              className="w-full bg-gray-100 p-3 rounded-xl"
              placeholder="Your City"
              value={shippingData.city}
              onChange={(e) =>
                setShippingData({
                  ...shippingData,
                  city: e.target.value
                })
              }
              required
            />
          </div>
        </div>
        <div className="md:flex w-full mb-2">
          <div className="md:w-2/4.5 mr-3"></div>
          <div className="md:w-2/4.5 ml-5">
            {error && error.city && <span className="text-sm text-Red">{error.city}</span>}
          </div>
        </div>

        <div className="md:flex w-full mb-2">
          <div className="md:w-2/4.5 md:mr-3">
            <label className="text-xs text-Lgray">State/Province/Region</label>
            <br />
            <input
              className="w-full bg-gray-100 p-3 rounded-xl"
              placeholder="United States"
              value={shippingData.province}
              onChange={(e) =>
                setShippingData({
                  ...shippingData,
                  province: e.target.value
                })
              }
              required
            />
          </div>
          <div className="md:w-1/4 md:mr-29 md:ml-5">
            <label className="text-xs text-Lgray">Postal Code</label>
            <br />
            <input
              className="w-full bg-gray-100 p-3 rounded-xl"
              placeholder="Your Postal Code"
              value={shippingData.postalCode}
              onChange={(e) =>
                setShippingData({
                  ...shippingData,
                  postalCode: e.target.value
                })
              }
              required
            />
          </div>
        </div>
        <div className="md:flex w-full mb-5">
          <div className="md:w-2/4.5 mr-3">
            {error && error.province && <span className="text-sm text-Red">{error.province}</span>}
          </div>
          <div className="md:w-2/4.5 mr-5 ml-5">
            {error && error.postalCode && (
              <span className="text-sm text-Red">{error.postalCode}</span>
            )}
          </div>
        </div>

        <div className="flex w-full mb-5">
          <div className="md:w-3/5">
            <label className="text-xs text-Lgray">Mobile Phone</label>
            <br />
            <div className="flex">
              <PhoneInput
                inputStyle={{
                  color: 'gray',
                  width: '100%',
                  backgroundColor: '#F3F3F3',
                  border: 'none',
                  borderRadius: '15px',
                  height: '45px'
                }}
                buttonStyle={{
                  backgroundColor: '#F3F3F3',
                  border: 'none',
                  borderRadius: '15px 0 0 15px'
                }}
                dropdownStyle={{ width: '500px' }}
                country={'us'}
                value="1234567890"
                enableSearch={true}
                onChange={(phone) => {
                  setShippingData({
                    ...shippingData,
                    phone: phone
                  });
                }}
              />
            </div>
            <div className="flex">
              {error && error.phone && <span className="text-sm text-Red">{error.phone}</span>}
            </div>
          </div>
        </div>
        <div className="flex w-full md:my-12">
          <div className="border-2 border-gray-100  w-20  py-3 mr-2 font-bold rounded-xl text-white mt-4 items-center justify-center text-center cursor-pointer">
            <button className="w-full text-black font-semibold" onClick={handleBack}>
              Back
            </button>
          </div>
          <div
            className="bg-red  w-32 mx-2 py-3 font-bold rounded-xl text-white mt-4 items-center justify-center text-center cursor-pointer"
            onClick={handleShippingFormValidation}>
            <button className="w-full font-semibold">Continue</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentAndShipping;
