import React from 'react';
import { AiOutlineClose } from 'react-icons/ai';
const GlobalDialog = (props) => {
  const { open, onClose, className } = props;
  if (!open) {
    return <></>;
  }
  return (
    <div className="fixed inset-0 overflow-auto bg-gray-600 bg-opacity-50 backdrop-filter backdrop-blur-sm flex z-50 ">
      <div
        className={`sticky w-full z-50 md:w-2/5 sm:w-3/5 bg-white m-auto flex-col flex rounded-lg md:mx-auto xs:mx-4 ${className} `}
        onClick={(event) => {
          event.stopPropagation();
        }}>
        <div className="relative flex rounded-t-lg justify-end">
          <span className="text-gray-400 m-2 font-bold cursor-pointer" onClick={onClose}>
            <AiOutlineClose className="h-6 w-6 fill-current text-grey hover:text-grey-darkest" />
          </span>
        </div>
        <div className="">{props.children}</div>
      </div>
    </div>
  );
};

export default GlobalDialog;
