import React, { useState, useEffect } from 'react';
import RecipeCard from './RecipeCard';
import { RecipeService, useRecipeState } from '../../Service/RecipeServices';
import { useAuth } from '../../Context/AuthContext';
import AddRecipe from '../AddRecipe';
import Loader from '../Loader2';
import CustomButton from '../../utils/customButton';

function Recipes() {
  const recipeState = useRecipeState();
  const [addrecipe, setAddRecipe] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (recipeState.updateNeed.value) {
      RecipeService.FetchRecipeForUser(currentUser.uid);
    }
  }, [recipeState.updateNeed.value]);

  const res = recipeState.recipes.value;
  const recipe = res
    ? Object.entries(res).map((e) => ({
        [e[0]]: e[1]
      }))
    : [];
  return (
    <div className="bg-white m-4 rounded-lg lg:w-full xs:h-fit" style={{ height: '78vh' }}>
      <div className="flex items-center justify-between">
        <h3 className="font-semibold p-8 md:text-2xl xs:text-sm">My Recipes</h3>

        <CustomButton
          className="bg-red text-white px-4 py-2 rounded-lg mr-8"
          onClick={() => setAddRecipe(true)}>
          + Add Recipe
        </CustomButton>
      </div>
      {recipeState.loading.value && <Loader />}
      {!recipeState.loading.value && recipe.length === 0 && (
      <div className='flex flex-col mt-10 mx-auto font-bold items-center justify-center text-center text-base xs:text-0.7'>
        <h1>
          Currently, you have no recipes.
        </h1>  
        <h1>
          Details about your recipes will appear here once you add them.
        </h1>
      </div>
      )}
      <div className="grid lg:grid-cols-5 grid-cols-1 overflow-y-scroll h-41 md:grid-cols-2 gap-2 mx-2">
        {recipe.length > 0 &&
          recipe.map((item, i) => {
            const res = item[Object.keys(item)];
            const id = Object.keys(item);
            return (
              <div key={i}>
                <RecipeCard recipe={res} id={id} />
              </div>
            );
          })}
      </div>
      {addrecipe ? <AddRecipe open={addrecipe} onClose={() => setAddRecipe(false)} /> : null}
    </div>
  );
}

export default Recipes;
