import React, { useState } from 'react';
import TaskDeleteModal from './TaskDeleteModal';
import ProposalModalTask from './ProposalModalTask';
import TaskDetailsModal from './SendTaskDeliverables';
import UpdateTask from './UpdateTask';
import ApprovalModalTask from './ApprovalModalTask';
import EditTaskModal from '../Brand_Dashboard/EditTaskModal';
import CustomButton from '../../utils/customButton';
function TaskCard(props) {
  const [delTask, setDelTask] = useState(false);
  const [taskDetails, setTaskDetails] = useState(false);
  const [proposalTask, setProposalTask] = useState(false);
  const [update, setUpdate] = useState(false);
  const [editTask, setEditTask] = useState(false);
  const detailsHander = () => {
    setTaskDetails(!taskDetails);
  };

  const proposalHandle = () => {
    setProposalTask(!proposalTask);
  };

  const updateHandle = () => {
    let updt = update;
    setUpdate(!updt);
  };

  return (
    <>
      <div onClick={props.task.status === 'available' ? () => proposalHandle() : () => detailsHander()}>
        <div className="bg-lightGray items-center rounded-xl p-1 flex flex-col cursor-pointer">
          <div className="relative w-full h-full">
              <img
                  src={props.task.brand_photo}
                  style={{ height: '120px' }}
                  className="rounded-lg w-full h-full bg-white object-contain"
                  alt="img"
                />
                {props.task && props.task.add_ons && props.task.add_ons.some((addOn) => 
                          addOn.name === "Whitelist") && 
                            <div className="absolute text-white px-2 bg-gray-900 rounded-md top-2 left-2">
                            <div className='text-0.7'>Whitelisting</div>
                          </div>
                          
              }
                {props.task && props.task.add_ons && props.task.add_ons.some((addOn) => 
                          addOn.name === "Whitelist"  && addOn.platform.includes("tiktok")) &&
                  <div className="absolute bottom-0 left-2">
                    <div className="instagram w-4 h-4"></div>
                  </div>
                }
            
              {props.task && props.task.add_ons && props.task.add_ons.some((addOn) => 
              addOn.name === "Whitelist" && addOn.platform.includes("tiktok")) &&
              <div className={`absolute bottom-0 ${props.task.add_ons.some((addOn) => addOn.name === "Whitelist" && addOn.platform.includes("tiktok")) ? 'left-7 ' : 'left-2'}`}>
                  <div className="tiktok w-4 h-4"></div>
              </div>
            }
          </div>
          <div className='text-center w-full overflow-hidden truncate line-camp-2'>
            {props.task && props.task.name || props.task.note1} 
          </div>
          <div className="text-lg my-2 mb-1 text-Blue font-bold text-center">
            {' '}
            ${(props.task.price / 100).toFixed(2)} + free product </div>
          <div className="text-base mb-1 font-bold tracking-tighter text-center">
             1 Video 
             {props.task && props.task.variations && 
             <>
             , Versions
             </>
             }
            {props.task && props.task.add_ons && props.task.add_ons.map((el, i) => (
            <span key={i}>,{i !== 0 } {el.name}</span>
          ))}
            {props.task.recipe_videos !== undefined && props.task.recipe_videos !== 0 && (props.task.recipe_videos + ' Recipe Video')}
          </div>
          <div className="text-base mb-1 font-bold"></div>
        </div>
      </div>
      <div className="items-center justify-center text-center">
        {props.task.status === 'available'  ? (
          <>  
            <CustomButton
              className=" text-white mr-3 px-2 rounded-lg outline-none text-xs"
              onClick={() => proposalHandle()}>
              Send Proposal
            </CustomButton>
          </>
        ) : null}

        {props.task.status === 'accepted' && props.task.status === 'approved'(
          <>
            <CustomButton
              className=" text-white px-2 mr-2 rounded-lg outline-none text-xs"
              onClick={() => detailsHander()}>
              Send Deliverables
            </CustomButton>
          </>
        )}
      </div>
      {proposalTask ? (
        <ProposalModalTask
          open={proposalTask}
          task={props.task}
          onClose={() => setProposalTask(false)}
          id={props.id}
        />
      ) : null}
      {taskDetails ? (
        <TaskDetailsModal
          open={taskDetails}
          close={() => setTaskDetails(false)}
          task={props.task}
          id={props.id}
        />
      ) : null}
      {editTask ? (
        <EditTaskModal
          open={editTask}
          close={() => setEditTask(false)}
          task={props.task}
          id={props.id}
        />
      ) : null}
    </>
  );
}

export default TaskCard;
