import React, { useContext, useEffect, useState } from 'react';
import { useRequestState, RequestService } from '../../Service/RequestServices';
import DeleteModal from './DeleteReqModal';
import ApproveRequestModal from './ApproveRequestModal';
import { useAuth } from '../../Context/AuthContext';
import { Link } from 'react-router-dom';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';

function Requests() {
  const requestState = useRequestState();
  const [delRec, setDelRec] = useState(false);
  const [open, setOpen] = useState(false);
  const [requestId, setRequestId] = useState();
  const [reqData, setReqData] = useState('');
  const { currentUser } = useAuth();
  const { brandId } = useContext(BrandContext);

  useEffect(() => {
    if (requestState.updateNeed.value) {
      RequestService.FetchRequestForBrand(brandId);
    }
  }, [requestState.updateNeed.value]);
  const res = requestState.requestsBrand.value;

  const req = res
    ? Object.entries(res).map((e) => ({
        [e[0]]: e[1]
      }))
    : [];

  const handleShowDetails = (res) => {};

  const handleApprove = (id, req) => {
    setRequestId(id);
    setReqData(req);
    setOpen(true);
  };

  // grab requests from db

  // grab sample requests from db
  return (
    <div className="bg-white m-4 p-4 rounded-lg lg:w-full xs:h-fit">
      <h3 className="font-semibold py-2 p-8 text-2xl">Sample request that need to be filled</h3>
      {open && (
        <ApproveRequestModal open onClose={() => setOpen(false)} id={requestId} reqData={reqData} />
      )}
      {delRec ? (
        <DeleteModal open={delRec} onClose={() => setDelRec(false)} id={requestId} />
      ) : null}

      {req ? (
        <div className="flex flex-col">
          <div className="sm:-mx-6 lg:-mx-8">
            <div className="py-2 overflow-y-scroll z-100 h-21 content-between inline-block min-w-full sm: lg:px-8">
              <table className="w-full bg-gray-100 rounded-lg z-0">
                <thead>
                  <tr className="border-b">
                    <th scope="col" className="">
                      Type
                    </th>
                    <th scope="col" className="text-xl font-extralight px-6 text-left py-4">
                      Product Name
                    </th>
                    <th scope="col" className="text-xl font-extralight px-6 text-left py-4">
                      QTY
                    </th>
                    <th scope="col" className="text-xl font-extralight  text-left px-6 py-4">
                      Approve
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {req.length > 0 &&
                    req
                      .slice(0)
                      .reverse()
                      .map((item, i) => {
                        const res = item[Object.keys(item)];
                        const id = Object.keys(item);
                        // if (res) {
                        return (
                          <tr className="text-sm text-xs border-b" key={i}>
                            <td className="px-6 py-2 whitespace-nowrap text-lg font-medium text-left">
                              {/* {res.customer && res.customer.customerName} */}
                              {res.type === 'Customer' ? (
                                <label className="mr-8 cursor-pointer bg-red text-white px-2  min-w-21 rounded-lg">
                                  Customer
                                </label>
                              ) : (
                                <label className="mr-8 cursor-pointer bg-Blue text-white  px-2 min-w-21 rounded-lg">
                                  Creator
                                </label>
                              )}
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-lg font-medium text-left">
                              {/* {res.customer && res.customer.customerName} */}
                              {res.product_name}
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-lg font-medium text-left">
                              {/* {res.customer && res.customer.customerName} */}
                              {res.qty}
                            </td>
                            <td className="px-6 py-2 whitespace-nowrap text-lg font-medium text-left">
                              {/* {res.customer && res.customer.customerName} */}
                              {res.status === 'approved' ? (
                                <button className="bg-red font-bold px-1 py-2 rounded-lg my-1 text-white text-lg w-32 items-left justify-left text-center cursor-pointer">
                                  Approved
                                </button>
                              ) : (
                                <button
                                  className="bg-red font-bold px-1 py-2 rounded-lg my-1 text-white text-lg w-32 items-left justify-left text-center cursor-pointer"
                                  onClick={() => handleApprove(id, res)}>
                                  Approve
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <h1 className="table mx-auto text-red text-2xl">No requests found</h1>
      )}
      {!requestState.loading.value && req.length === 0 && (
        <div className="w-full">
          <div className="text-center font-bold mx-auto mt-6 mb-4 text-2xl">
            You currently have no product requests to fill, when you do they will appear here. To
            request UGC videos, click below.
          </div>
          <div className="mt-6 text-center">
            <Link
              className="bg-red font-bold px-2 py-2 rounded-lg my-1 text-white text-lg w-32 items-left justify-left text-center cursor-pointer"
              to="/brand/dashboard/tasks">
              Create UGC Task
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default Requests;
