import React, {useState, useEffect} from 'react'
import ReactStars from "react-rating-stars-component";
import CreatorReviewInfo from './CreatorReviewInfo';

function ProfileReviewCard(props) {
  console.log("PROFILEReviewCard", props.review);
  const [review, setReview] = useState(false);
  return (
    <div className="flex flex-col sm:flex-row items-center shadow-md border-2 xl:text-base md:text-0.7 mx-3 bg-white hover:bg-gray-100 p-4 cursor-pointer rounded-lg mb-4" onClick={()=> setReview(true)}>
      <div className="flex justify-between w-full">
        <div>    
          <h1 className='font-bold text-2xl'>{props.review.brand_name}</h1>
          <p>{props.review.task_name}</p>
          {props.review.campaign_name ? <p>{props.review.campaign_name}</p> : null}
          <p className="overflow-hidden w-fit overflow-ellipsis whitespace-nowrap">{props.review.review}</p>
        </div>
          <div className="flex flex-row justify-end">
          {props.review.completed === true ? 
            <ReactStars 
              count={5}
              value={props.review.overall_rating}
              size={40}
              edit={false}
              activeColor="#FFD700"
            /> 
            : null}
          </div>
      </div>
      {review ? 
    <CreatorReviewInfo open={review} onClose={()=> setReview(false)} review={props.review} />  
 
    : null}
    </div>
  )
}

export default ProfileReviewCard