import React, { useContext, useEffect, useState } from "react";
import { TaskService, useTaskState } from "../../../Service/TaskServices";
import { useAuth } from "../../../Context/AuthContext";
import BillingHistory from "./BillingHistory";
import { toast, Toaster } from "react-hot-toast";
import Loader from "../../Loader";
import { BrandService, useBrandState } from "../../../Service/BrandServices";
import SubscribeModal from "./SubscribeModal";
import {
  useSubscriptionState,
  SubscriptionService,
} from "../../../Service/SubscriptionServices";
import SelectPlanModal from "./SelectPlanModal";
import {
  useCreditPackState,
  CreditPackService,
} from "../../../Service/CreditPackServices";
import { UsersService, useUserState } from "../../../Service/userServices";
import { BrandContext } from "../../../routes/PrivateRoute/BrandDashboardRoute";

function Tasks() {
  const taskState = useTaskState();
  const [credits, setCredits] = useState([0]);
  const [addlCredits, setAddlCredits] = useState(0);
  const [addAmpCredits, setAddAmpCredits] = useState(0);
  const [upgrade, setUpgrade] = useState(false);
  const [downgrade, setDowngrade] = useState(false);
  const [tab, setTab] = useState();
  const userState = useUserState();
  const creditPackState = useCreditPackState();
  const brandState = useBrandState();
  const { currentUser } = useAuth();
  const { brandId } = useContext(BrandContext);

  const { REACT_APP_STRIPE_CREDITS_PRICE } = process.env;

  console.log("Stripe price: ", REACT_APP_STRIPE_CREDITS_PRICE)

  useEffect(() => {
    if (brandId) {
      BrandService.FetchSingleBrand(brandId);
      CreditPackService.FetchSubscriptionForUser(brandId);
    }
  }, [creditPackState.updateNeed.value, brandId]);

  let user = brandState.brand.value;
  console.log(" user: ", user);
  let creditPack = creditPackState.creditPack.value;
  console.log(brandState.brand.value);
  console.log("creditPack", creditPack);
  console.log("paid usage: ", creditPack && creditPack.paidUsage);
  console.log("current usage: ", creditPack && creditPack.currentUsage);

  useEffect(() => {
    if (brandState.brand.value) {
      user = brandState.brand.value;
    }
  }, [user, brandState.updateNeed.value]);

  useEffect(() => {
    if (creditPackState.videosData.value) {
      let videodata = creditPackState.videosData.value;
      if (videodata.data) {
        if (videodata.data.statuscode !== 400) {
          toast.success("Plan Updated Successfully");
          creditPackState.merge({ videosData: {} });
        } else if (videodata.data.statuscode === 400) {
          toast.error("Unable to update plan, Please contact support");
          creditPackState.merge({ videosData: {} });
        }
      }
    }
  }, [creditPackState.updateNeed.value]);
  console.log(
    "payment method",
    user && user.billing && user.billing.payment_method,
  );

  const handleConfirm = async (type) => {
    if (creditPackState.videosData.value) {
      try {
        creditPackState.merge({ loading: true });
        console.log("uid:", brandId);
        const temp = {};
        if (type === "amp") {
          temp.quantity = Number(addAmpCredits);
          temp.plan =
            creditPack && creditPack.amplify && creditPack.amplify.plan;
        } else {
          temp.quantity = Number(addlCredits);
        }
        temp.uid = brandId;
        temp.id = user && user.billing && user.billing.payment_method;

        temp.customerstripeId = user && user.stripe_id;
        temp.subs_price = REACT_APP_STRIPE_CREDITS_PRICE; // price for additional credits
        console.log("requestData:---", temp);

        const response = await CreditPackService.IncrementCredits(
          brandId,
          temp,
        );

        creditPackState.merge({ loading: false }); // set loading to false after successful API call
        setAddlCredits(0);
        toast.success(response.message || "Credits added successfully"); // show success message
      } catch (error) {
        console.log(error.response, error.response.message, "Error");
        creditPackState.merge({ loading: false }); // set loading to false in case of error
        toast.error(error.response.data.message || "Failed to add credits"); // show error message
      }
    }
  }; // include all dependencies inside the array

  useEffect(() => {
    TaskService.FetchTasks();
  }, [taskState.updateNeed.value]);

  const handleUpgradeClick = () => {
    setUpgrade(true);
    setTab(1);
  };

  const handleBuyCreditPackClick = () => {
    setUpgrade(true);
    setTab(0); // Set the tab to 0 when the "Buy Credit Pack" button is clicked
  };

  return (
    <div
      className="my-5  rounded-lg lg:w-full xs:h-fit"
      style={{ height: "90vh" }}
    >
      <Toaster />
      <div className="grid lg:grid-cols-2 grid-cols-1 h-auto md:grid-cols-2 gap-4 mx-2">
        <div className="w-full rounded-lg bg-white text-center">
          <div className="flex flex-col place-items-center w-full rounded-lg bg-white text-center pt-12">
            <span className="font-black bg-gray-700 rounded-lg px-4 py-2 text-white w-1/3 text-2xl mx-12 mb-1 text-center">
              CRAFTED AMPLIFY
            </span>
            <div className="text-center my-8">
              {/*
                  creditPack && creditPack.amplify && creditPack.amplify.status && creditPack.amplify.status === 'active'
                    ? (
                      <div className='flex flex-col place-items-center'>
                        <p className='text-3xl'>Current Plan:</p> 
                        <p className=' uppercase font-bold'> {creditPack && creditPack.amplify && creditPack.amplify.plan}</p>
                        <span className='px-4 py-2 my-5 w-1/3 text-center bg-green rounded-lg text-white'>
                          ACTIVE
                        </span>
                       
                        <div>
                          {creditPack && creditPack.amplify && creditPack.amplify.plan ? (
                            <>
                            <span> {creditPack && creditPack.paidUsage && (creditPack.paidUsage - creditPack.currentUsage).toLocaleString()} credits remaining | </span>
                            <span>
                              {creditPack.amplify.plan == 'starter' && '$2.00 for each additional credit'}
                              {creditPack.amplify.plan == 'basic' && '$1.75 for each additional credit'}
                              {creditPack.amplify.plan == 'professional' && '$1.50 for each additional credit'}
                              {creditPack.amplify.plan == 'premium' && '$1.25 for each additional credit'}
                            </span>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <input
                          type='number'
                          className='text-2xl text-center mt-5 w-1/3 bg-white rounded-lg shadow-md text-black px-5'
                          placeholder = {0}
                          step={10}
                          min={10}
                          onChange={(e) => {setAddAmpCredits(e.target.value)}}
                        />
                        {creditPack && creditPack.payment_method &&
                        <button
                        className="text-white bg-Blue text-xs font-bold rounded-md shadow-md py-2 px-12 mt-5"
                        onClick={() => addlCredits > 0 && !creditPackState.loading.value && handleConfirm('amp')}
                        disabled={creditPackState.loading.value}>
                        {creditPackState.loading.value && <Loader />}  Add Credits
                      </button>
                      }

                      <span className='mt-2'>${creditPack && (addAmpCredits * ((creditPack.amplify.plan == 'starter' && 2) + (creditPack.amplify.plan == 'basic' && 1.75)+(creditPack.amplify.plan == 'professional' && 1.50)+(creditPack.amplify.plan == 'premium' && 1.25))).toFixed(2)}</span>
                    </div>
                    ) : (
                    <div className='flex flex-col place-items-center mt-10'>
                      <span className='px-4 py-2 rounded-lg text-center bg-gray-500 text-white'>
                        INACTIVE
                      </span>
                      <button
                        className="text-sm text-white bg-Blue font-bold rounded-md shadow-md py-2 px-12 mt-5"
                        onClick={handleUpgradeClick}>
                        Get Crafted Amplify
                      </button>
                    </div>
                    )
                */}
              <h1 className="font-normal flex items-center text-0.7 mt-10 justify-center">
                To activate Crafted Amplify or purchase more Amplify Credits,
                you can contact Sarah@usecrafted.com below.
              </h1>
              <button
                className="md:mt-10 2xl:mt-10 mx-auto 2xl:mb-0.5 text-2xl font-bold hover:bg-blue-darkest shadow-sm bg-Blue rounded-md text-white py-4 px-7 block"
                onClick={() =>
                  (window.location = "mailto:sarah@usecrafted.com")
                }
              >
                Contact Us
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-col place-items-center w-full rounded-lg bg-white text-center pt-12">
          <span className="font-black bg-gray-700 rounded-lg px-4 py-2 text-white w-1/3 text-2xl mx-12 mb-1 text-center">
            CRAFTED STUDIO
          </span>
          <button
            className=" w-1/2 text-white bg-Blue text-sm font-bold rounded-md shadow-md py-2 px-12 mt-5"
            onClick={handleBuyCreditPackClick}
          >
            Buy Credit Pack
          </button>
          {creditPack && creditPack.payment_method && (
            <>
              {" "}
              <p className="my-10">
                {" "}
                {creditPack &&
                  creditPack.paidUsage &&
                  (
                    creditPack.paidUsage - creditPack.currentUsage
                  ).toLocaleString()}{" "}
                credits remaining | $2.00 for each additional credit
              </p>
              <div className="flex flex-col place-items-center w-full my-2">
                <div className="credit-count m-auto font-bold rounded-md shadow-md py-2">
                  <input
                    type="number"
                    className="text-2xl bg-white text-center w-full text-black px-5"
                    placeholder={0}
                    step={10}
                    onChange={(e) => {
                      setAddlCredits(e.target.value);
                    }}
                  />
                </div>
              </div>
              <button
                className="ml-3 w-1/3 text-white bg-Blue text-xs font-bold rounded-md shadow-md py-2 px-12 mt-5"
                onClick={() =>
                  addlCredits > 0 &&
                  !creditPackState.loading.value &&
                  handleConfirm("other")
                }
                disabled={creditPackState.loading.value} // disable the button when loading
              >
                {creditPackState.loading.value && <Loader />} Add Credits
              </button>
              <span className="mt-2">
                ${creditPack && (addlCredits * 2).toFixed(2)}
              </span>
            </>
          )}
          <p className="font-bold mt-4">For Crafted Studio</p>
          <p className="px-4 pb-4">
            Videos (300 credits each), Recipe (50 credits each), Review (30
            credits each), Set of 3 Product Photos (25 credits each){" "}
          </p>
        </div>
      </div>
      <div className="m-4 p-4">
        {" "}
        Email us at alliana@usecrafted.com for help with any billing issues.
      </div>
      {/*<div className="bg-white rounded-lg p-4 ">
          <BillingHistory />
         </div>*/}
      {upgrade && (
        <SelectPlanModal
          close={() => setUpgrade(false)}
          open={upgrade}
          tab={tab}
          setTab={setTab}
        />
      )}
    </div>
  );
}

export default Tasks;
