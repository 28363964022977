import React, {useState, useEffect} from 'react';
import { useTabState } from '../../utils/TabState';
import 'react-responsive-modal/styles.css';
import AssetCard from './AssetCard';
import UgcTab from './ugcTab';
import { useParams } from 'react-router-dom';
import Loader2 from '../Loader2';
import { Link } from 'react-router-dom';
import { useAuth } from '../../Context/AuthContext';
import { TaskService, useTaskState } from '../../Service/TaskServices';
import { UsersService, useUserState } from '../../Service/userServices';
import { AssetService, useAssetState } from '../../Service/AssetService';
import CustomButton from '../../utils/customButton';
function UgcAssets() {
  const assetState = useAssetState();
  const taskState =  useTaskState();
  const tabState = useTabState();
  const { currentUser } = useAuth();
  const { id } = useParams();
  const tabhandle = (tab) => {
    tabState.merge({ currentUgcTab: tab });
  };
  useEffect(() => {
    if (id) {
      TaskService.FetchSingleTask(id);
    }
  }, [taskState.updateNeed.value]); // Make sure 'id' is in the dependency array // Make sur

  const task = taskState.tasks.value;

  let Assetdata = task && task.assets ? Object.entries(task.assets) : [];

  return (
    <div className="bg-white w-full p-4 rounded-lg z-100 h-40rem" style = {{height: '90%'}}>
    <div className="w-full z-100 mb-5 overflow-auto" style={{ height: '100vh' }}>
      {assetState.loading.value ? (
        <Loader2 />
      ) : (
        <>
          {Assetdata.length === 0 && (
            <div className="w-full">
              <div className="text-center font-bold mx-auto mt-6 mb-4 text-2xl">
                You currently have no UGC assets for this brief, when you do they will appear here.
              </div>
              <div className="mt-6 text-center">
                <p className='font-bold mb-4'>Assets are created once you accept a draft from a creator.</p>
                <CustomButton
                  className="font-bold px-2 py-2my-1 text-white text-lg w-32 items-left justify-left text-center cursor-pointer"
                  onClick={ () => tabState.merge({ currentUgcTab: 3})}>
                  View Drafts
                </CustomButton>
              </div>  
            </div>
          )}
      
          <div
            className="grid lg:grid-cols-4 grid-cols-1 md:grid-cols-2 gap-2 mx-2 pb-5 overflow-auto" 
            style = {{height: '68%'}}
          > 
            {Assetdata.length > 0 &&
              Assetdata.reverse().map((item, i) => {
                const data = item[Object.keys(item)];
                const id = Object.keys(item);
                //console.log("item : ", item[1]);
                return (
                  <AssetCard
                    key={i}
                    asset={item[1]}
                    name={item[1].creator_name}
                    date = {item[1].created}
                    task = {task}
                    id={id}
                    thumbnail={item[1].source}
                  />
                );
              })}
          </div>
        </>
      )}
    </div>
  </div>
  )
}

export default UgcAssets