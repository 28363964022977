import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../Context/AuthContext';
import PlacesAutocomplete from 'react-places-autocomplete';
import { AuthService, userAuthstate } from '../../../Service/authenticationService';
import { UsersService } from '../../../Service/userServices';
import { toast, Toaster } from 'react-hot-toast';
import CustomButton from '../../../utils/customButton';

function MailingAddressModal({ goToNextTab }) {
  const { currentUser } = useAuth();
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [success, setSuccess] = useState(false);
  const [fullname, setFullname] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [country, setCountry] = useState('USA'); // New state for country

  const [currentAddress, setCurrentAddress] = useState({
    currentFullName: '',
    currentAddress1: '',
    currentAddress2: '',
    currentCity: '',
    currentState: '',
    currentCountry: 'USA',
    currentZip: ''
  });

  const authState = userAuthstate();

  useEffect(() => {
    if (currentUser.uid !== null) {
      AuthService.FetchAuthUser(currentUser.uid);
    }
  }, [authState.updateNeed.value]);

  const user = authState.authUser.value;

  function handleChange(address) {
    setAddress1(address);
  }

  function handlePortfolio() {
    alert('Creator Portfolio Updated!');
  }

  function handleSelect(address, placeId, suggestion) {
    setAddress1(suggestion.formattedSuggestion.mainText);
    setCity(suggestion.terms[suggestion.terms.length - 3].value);
    setState(suggestion.terms[suggestion.terms.length - 2].value);
  }

  const US_STATES = {
    "Alabama": "AL",
    "Alaska": "AK",
    "Arizona": "AZ",
    "Arkansas": "AR",
    "California": "CA",
    "Colorado": "CO",
    "Connecticut": "CT",
    "Delaware": "DE",
    "Florida": "FL",
    "Georgia": "GA",
    "Hawaii": "HI",
    "Idaho": "ID",
    "Illinois": "IL",
    "Indiana": "IN",
    "Iowa": "IA",
    "Kansas": "KS",
    "Kentucky": "KY",
    "Louisiana": "LA",
    "Maine": "ME",
    "Maryland": "MD",
    "Massachusetts": "MA",
    "Michigan": "MI",
    "Minnesota": "MN",
    "Mississippi": "MS",
    "Missouri": "MO",
    "Montana": "MT",
    "Nebraska": "NE",
    "Nevada": "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    "Ohio": "OH",
    "Oklahoma": "OK",
    "Oregon": "OR",
    "Pennsylvania": "PA",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    "Tennessee": "TN",
    "Texas": "TX",
    "Utah": "UT",
    "Vermont": "VT",
    "Virginia": "VA",
    "Washington": "WA",
    "West Virginia": "WV",
    "Wisconsin": "WI",
    "Wyoming": "WY"
  };
  
  const CANADIAN_PROVINCES = {
    "Alberta": "AB",
    "British Columbia": "BC",
    "Manitoba": "MB",
    "New Brunswick": "NB",
    "Newfoundland and Labrador": "NL",
    "Nova Scotia": "NS",
    "Ontario": "ON",
    "Prince Edward Island": "PE",
    "Quebec": "QC",
    "Saskatchewan": "SK",
    "Northwest Territories": "NT",
    "Nunavut": "NU",
    "Yukon": "YT"
  };

  const combinedStatesAndProvinces = country === 'USA' ? US_STATES : CANADIAN_PROVINCES;

  function renderAddress() {
    if (user) {
      setCurrentAddress({
        currentFullName: fullname,
        currentAddress1: address1,
        currentAddress2: address2,
        currentCity: city,
        currentState: state,
        currentCountry: country,
        currentZip: zipcode
      });
    }
  }

  useEffect(() => {
    renderAddress();
  }, [authState.updateNeed.value]);

  async function saveAddress(event) {
    event.preventDefault();
    let mailing_address = {
      fullname: fullname,
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      country: country,
      zipcode: zipcode
    };

    await UsersService.AddAddress(currentUser.uid, mailing_address);

    toast.success('Address added successfully');
    setTimeout(() => {
      renderAddress();
      goToNextTab();
    }, 2000);
    setSuccess(true);
  }

  return (
    <div className='flex flex-col mt-10 p-4 pl-8 mx-auto items-center justify-center'>
      <Toaster />
      <h2 className='text-xs md:text-3xl font-extrabold mb-4'>1. &nbsp; Add your mailing Address</h2>
      <p className='font-thin italic xl:text-3xl text-xs text-gray-600 tracking-tighter'>
        Please provide your mailing address so brands can ship sample products to you for job opportunities.
      </p>
      <div className="text-center">
        {user && user.shipping_details ? (
          <p className="mt-6">
            <label className="block font-extrabold">
              {user.shipping_details && user.shipping_details.fullname && user.shipping_details.fullname}
            </label>
            <label className="block font-extrabold">
              {user.shipping_details && user.shipping_details.address1 && user.shipping_details.address1}
            </label>
            <label className="block font-extrabold">
              {user.shipping_details && user.shipping_details.address2 && user.shipping_details.address2}
            </label>
            <label className="block font-extrabold">
              {user.shipping_details && user.shipping_details.city && user.shipping_details.city},{' '}
              {user.shipping_details && user.shipping_details.state && user.shipping_details.state},{' '}
              {user.shipping_details && user.shipping_details.country && user.shipping_details.country}{' '}
              {user.shipping_details && user.shipping_details.zipcode && user.shipping_details.zipcode}
            </label>
          </p>
        ) : (
          <div>No address found.</div>
        )}
      </div>
      <form onSubmit={saveAddress}>
        <input
          className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
          type="text"
          name="name"
          placeholder="Full Name"
          value={fullname}
          onChange={(event) => setFullname(event.target.value)}
          required
        />
        <PlacesAutocomplete
          value={address1}
          onChange={handleChange}
          onSelect={handleSelect}
          className="flex focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: 'Street Address',
                  className: 'location-search-input focus:outline-none w-full bg-lightGray p-2 mt-2 rounded'
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        <input
          className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
          type="text"
          name="address2"
          placeholder="Apt Number, Suite, etc."
          value={address2}
          onChange={(event) => setAddress2(event.target.value)}
        />
        <div className=" grid grid-cols-4 gap-4">
          <input
            className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded mr-2"
            type="text"
            name="city"
            placeholder="City"
            value={city}
            onChange={(event) => setCity(event.target.value)}
            required
          /> 
          <select
            className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded mr-2"
            name="state"
            value={state}
            onChange={(event) => setState(event.target.value)}
            required
          >
            <option value="">Select a {country === 'USA' ? 'State' : 'Province'}</option>
            {Object.entries(combinedStatesAndProvinces).map(([name, code]) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))}
          </select>
          <select
            className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded mr-2"
            name="country"
            value={country}
            onChange={(event) => {
              setCountry(event.target.value);
              setState(''); // Reset state when country changes
            }}
            required
          >
            <option value="USA">USA</option>
            <option value="Canada">Canada</option>
          </select>
         
          <input
            className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
            type="text"
            name="zipcode"
            placeholder="Zipcode"
            value={zipcode}
            onChange={(event) => setZipcode(event.target.value)}
            required
          />
        </div>
        {success ? (
          <button className="bg-green text-white px-4 py-2 mt-2" onClick={() => saveAddress()}>
            Updated!
          </button>
        ) : (
          <CustomButton className="text-white px-4 py-2 mt-2" type="submit">
            Save
          </CustomButton>
        )}
      </form>
    </div>
  );
}

export default MailingAddressModal;
