import React from 'react';
import GlobalDialog from '../../utils/globalDialog';
import { IoMdMale, IoMdFemale } from 'react-icons/io';

function ViewDemographicsModal(props) {
    return (
        <GlobalDialog
            className="h-full"
            open={props.open}
            onClose={props.onClose}
        >
    <div className="flex mt-4 border-b-2" >
            <h1 className="text-3xl font-bold text-center mx-auto w-full mb-2">Full Audience Data: <span className='text-gray-400'>{props.proposal && props.proposal.creator_address && props.proposal.creator_address.fullname}</span></h1>
        </div>
    <div className='bg-white rounded-md flex items-center justify-center break-words text-xs h-full'>
        
        <div className="justify-end w-fit rounded-md h-fit flex 2xl:text-base md:text-xs">
            

                    {/* Render Demographics here */}
        {props.proposal && props.proposal.creator_socials && props.proposal.creator_socials.instagram && props.proposal.creator_socials.instagram.demographics && (
            <div className='flex flex-col mx-auto'>  
            <h1 className='font-bold text-center'>Gender</h1>
                <div className="2xl:w-2/3 md:w-full flex items-center justify-cneter mx-auto">
            <div className="flex mx-20 flex-wrap items-center justify-centera text-center w-full mb-5">
                {/* Male */}
                <div className="flex flex-row  w-full items-center">
                    <IoMdMale title="Male" className="mr-2 w-5 h-4 text-blue" />
                    <span className='font-bold mr-2'>M</span>
                    <div className="w-full bg-gray-300 rounded-lg" style={{ height: '6px' }}>
                        <div className="bg-Blue rounded-lg" style={{ width: `${((props.proposal.creator_socials.instagram.demographics.gender.M) / (props.proposal.creator_socials.instagram.demographics.gender.F + props.proposal.creator_socials.instagram.demographics.gender.M) * 100).toFixed(2)}%`, height: '5px' }}>
                        </div> 
                    </div>
                        <span className="ml-2 text-xs text-gray-800">
                            {((props.proposal.creator_socials.instagram.demographics.gender.M) / (props.proposal.creator_socials.instagram.demographics.gender.F + props.proposal.creator_socials.instagram.demographics.gender.M) * 100).toFixed(2)}%
                        </span>
                   
                </div>
            {/* Female */}
                <div className="flex flex-row w-full items-center">
                    <IoMdFemale title="Female" className="mr-2 w-5 h-4 text-red-coin" />
                    <span className='font-bold mr-3'>F</span>
                    <div className="w-full bg-gray-300 rounded-lg" style={{ height: '5px' }}>
                        <div className="bg-Blue rounded-lg" style={{ width: `${((props.proposal.creator_socials.instagram.demographics.gender.F) / (props.proposal.creator_socials.instagram.demographics.gender.F + props.proposal.creator_socials.instagram.demographics.gender.M) * 100).toFixed(2)}%`, height: '5px'}}>
                        </div>
                        </div>
                        <span className="ml-2 text-xs text-gray-800">
                            {((props.proposal.creator_socials.instagram.demographics.gender.F) / (props.proposal.creator_socials.instagram.demographics.gender.F + props.proposal.creator_socials.instagram.demographics.gender.M) * 100).toFixed(2)}%
                        </span>
                            
                        </div>
                    </div>
                </div>


                <div className="flex-none w-full mx-auto mb-5 ">
                    <h1 className="font-bold text-center mb-2">Age</h1>
                    <div className="flex flex-wrap mx-16">
                        {Object.entries(props.proposal.creator_socials.instagram.demographics.age)
                            .sort((a, b) => b[1] - a[1])
                            .map(([ageRange, value]) => (
                                <div key={ageRange} className="flex items-center w-full mb-2">
                                    <div className="inline font-bold w-1/6">{ageRange}</div>
                                    <div className="w-full bg-gray-300 rounded-lg" style={{ height: '5px' }}>
                                        <div className="bg-Blue rounded-lg" style={{ width: `${((value / Object.values(props.proposal.creator_socials.instagram.demographics.age).reduce((a, b) => a + b, 0)) * 100).toFixed(2)}%`, height: '5px' }}></div>
                                    </div>
                                    <span className="ml-2 text-xs text-gray-800">
                                        {((value / Object.values(props.proposal.creator_socials.instagram.demographics.age).reduce((a, b) => a + b, 0)) * 100).toFixed(2)}%
                                    </span>
                                </div>
                            ))}
                    </div>
                </div>

                <div className="flex-none w-full mx-auto ">
                    <h1 className='font-bold text-center mb-4'>State</h1>
                    <div className="flex flex-wrap mx-16">
                        {Object.entries(props.proposal.creator_socials.instagram.demographics.states)
                        .sort((a, b) => b[1].total - a[1].total)
                        .map(([state, value]) => (
                            <div key={state} className="flex items-center w-full mb-2">
                            <div className='inline font-bold text-xs w-1/6'>{state}</div>
                            <div className="w-full bg-gray-300 rounded-lg" style={{ height: '6px' }}>
                                <div className="bg-Blue rounded-lg" style={{ width: `${((value.total / Object.values(props.proposal.creator_socials.instagram.demographics.states).reduce((a, b) => a + b.total, 0)) * 100).toFixed(2)}%`, height: '5px' }}></div>
                            </div>
                            <div className="ml-2" style={{ width: '50px' }}>
                                {((value.total / Object.values(props.proposal.creator_socials.instagram.demographics.states).reduce((a, b) => a + b.total, 0)) * 100).toFixed(2)}%
                            </div>
                            </div>
                        ))}
                    </div>
                </div>


                            </div>
                        )}
                </div>
            </div>
        </GlobalDialog>
    )
}

export default ViewDemographicsModal;
