import React, { useState, useEffect, useContext } from 'react';
import NavBar from '../../Components/Navbar';
import { useAuth } from '../../Context/AuthContext';
import Sidebar from '../../Components/Brand_Dashboard/Sidebar';
import { UsersService } from '../../Service/userServices';
import BrandOnboarding from '../Onboarding/Brand';
import { BrandService, useBrandState } from '../../Service/BrandServices';
import { AccountService, useAccountState } from '../../Service/AccountServices';
import TaskCreationModal from '../../Components/Brand_Dashboard/TrialOnboarding/TrialBrandOnboarding';
import TrialNavbar from '../../Components/TrialNavbar';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';
export default function TrialLayout({ children }) {
  const { currentUser } = useAuth();
  const accountState = useAccountState();
  const brandState = useBrandState();
  const [isModalOpen, setIsModalOpen] = useState(false); // Added state to control modal visibility
  const {brandId} = useContext(BrandContext);
  useEffect(() => {
    if (currentUser.uid) {
      AccountService.FetchAccountInfo(currentUser.uid);
    }
  }, [currentUser.uid]);

  useEffect(() => {
    if (currentUser.uid) {
      UsersService.FetchUserInfo(currentUser.uid);
    }
  }, [currentUser.uid]);

  useEffect(() => {
    if (currentUser.uid) {
      BrandService.FetchSingleBrand(brandId).then((brand) => {
        if (brand && brand.brand_tasks) {
          setIsModalOpen(false);
        } else {
          setIsModalOpen(true);
        }
      });
    }
  }, [currentUser.uid]);

  const account = accountState.account.value;
  const brand = brandState.brand.value;
  //console.log("account in trial layout:", account);

  // Example function to toggle modal visibility
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const shouldRenderModal = isModalOpen && !(brand && brand.brand_tasks);

  return (
    <div>
      <TrialNavbar />
      <div className="md:flex flex-col z-100 md:flex-row h-screen w-full bg-gray-100"> 
        {children}
        {/* Pass isOpen and a method to change it as props */}
        {shouldRenderModal && <TaskCreationModal isOpen={isModalOpen} closeAction={toggleModal} />}
      </div>
    </div>
  );
}