import React, { useState } from 'react';
import Modal from 'react-responsive-modal';

function ReviewHelpModal(props) {
  const [activeTab, setActiveTab] = useState('instagram');

  return (
    <Modal
      open={props.open}
      onClose={props.close}
      center
      classNames={{ modal: 'customWideModal' }}
      closeOnOverlayClick={false}
    >
       <div className='h-screen p-6'>
        <h1 className="text-3xl text-center font-bold mb-4">Review Process Guide</h1>
        <div className='border-b w-full mb-4'></div>
        <div className="space-y-4"> 
          <h2 className="2xl:text-2xl md:text-base font-bold">Introduction</h2> 
          <p>Reviews are a critical part of our platform. They allow us to vet creators based on their brief performances and ensure the quality of content produced.</p>
          
          <h2 className="2xl:text-2xl md:text-base font-bold">Starting a Review</h2> 
          <p>Once you approve a creator's draft for an Amplify or Studio brief, a pending review will appear in the 'Pending' tab. This review will list the creator's name and the brief they completed.</p>

          <h2 className="2xl:text-2xl md:text-base font-bold">Scoring a Review</h2> 
          <p>Click on the pending review to start the review process. You will be asked to score the creator on three categories: Professionalism, Quality, and Performance. Each category can be scored from 1 to 5 stars.</p>

          <h2 className="2xl:text-2xl md:text-base font-bold">Leaving Feedback</h2> 
          <p>In addition to scoring, you can leave a written review for the creator. This feedback can provide valuable insights and suggestions for the creator.</p>

          <h2 className="2xl:text-2xl md:text-base font-bold">Completing a Review</h2> 
          <p>Once you have scored the creator and left your feedback, you can submit the review. Completed reviews will appear in the 'Completed' tab. You can click on a completed review to edit your scores and feedback at any time.</p>
            </div>
      </div>
    </Modal>
  );
}

export default ReviewHelpModal;