import React, { useState, useRef } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useAuth } from '../Context/AuthContext';
import CustomButton from '../utils/customButton';

function PasswordReset() {
  const [userEmail, setUserEmail] = useState('');
  const { resetPassword } = useAuth();
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    if (userEmail) {
      setMessage('');
      setError('');
      setLoading(true);
      resetPassword(userEmail)
        .then(() => {
          toast.success('Reset Email Sent', {
            duration: 7000,
            position: 'top-center',

            // Styling
            style: {},
            className: '',

            // Aria
            ariaProps: {
              role: 'status',
              'aria-live': 'polite'
            }
          });
          setUserEmail("");
          setLoading(false);
        })
        .catch(() => {
          toast.error('User not Found!');
          setUserEmail("");
          setError('User not Found!');
        })
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Toaster />
        <label className="text-darkBlack font-bold capitalize font-rubik">Email</label>
        <input
          className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
          type="email"
          onChange={(e) => setUserEmail(e.target.value)}
          value ={userEmail}
          placeholder="Email"
          required
        />
        <CustomButton
          className="text-xl font-bold my-3 mt-24 w-full mx-auto rounded text-white py-2 px-14 block"
          type="submit"
          disabled={loading}>
          Reset Password
        </CustomButton>
      </form>
    </div>
  );
}

export default PasswordReset;
