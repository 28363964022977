import React, { useEffect } from 'react';
import stripeInstructions from '../../../Assets/stripeInstructions.png';
import dotenv from 'dotenv';
dotenv.config();

function StripeSignupModal({ isOpen, onClose }) {
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.classList.contains('modal-overlay')) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="modal-overlay bg-gray-800 bg-opacity-50 absolute inset-0"></div>
          <div className="modal-content bg-white rounded-lg shadow-lg z-10">
            <div className="p-6">
              <h2 className="text-3xl font-bold mb-4">Sign Up for Stripe Account</h2>
              <p className="mb-4">To cash out your balance, you need to sign up for a Stripe account.</p>
              <p className="mb-4"><span className='text-2xl font-bold'>1.</span> Please click the button below to proceed with the registration process:</p>
                <a
                 href="https://dashboard.stripe.com/register"
                target="_blank"
                rel="noopener noreferrer"
                className="block text-center bg-gray-200 py-2 my-3 md:my-6 rounded-md font-medium mx-auto hover:bg-gray-300"
              >
                Sign Up for <span className='text-purple font-rubik font-bold ml-1'>Stripe</span>
              </a>
              <div>  
                <p className='mb-4'><span className='font-bold text-2xl'>2.</span> After you sign up, close this pop-up and click "Connect your Stripe account" to connect your account to Crafted.</p>
              <img src = {stripeInstructions} className=' my-10 h-fit flex items-center justify-center mx-auto border-2 rounded-md' />
              </div>
              <button
                onClick={onClose}
                className=" bg-red text-white py-2 px-4 flex items-center justify-center mx-auto rounded-md font-medium hover:bg-red "
              >
                Close
              </button>
          
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default StripeSignupModal;
