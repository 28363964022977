import React, { useState, useEffect, useContext } from 'react';
import { BsSearch } from 'react-icons/bs';
import CreatorEmail from './CreatorEmail';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Checkbox from '@material-ui/core/Checkbox';
import CustomPagination from './CreatorPagination';
import { UsersService, useUserState } from '../../Service/userServices';
import { FiInstagram, FiYoutube } from 'react-icons/fi';
import { FiFacebook, FiTwitter } from 'react-icons/fi';
import { EmailService } from '../../Service/EmailService';
import { useCampaignState, CampaignService } from '../../Service/CampaignServices';
import { BrandService, useBrandState } from '../../Service/BrandServices';
import { useAuth } from '../../Context/AuthContext';
import Multiselect from 'multiselect-react-dropdown';
import { BsTiktok } from 'react-icons/bs';
import {Toaster, toast} from 'react-hot-toast';
import Loader from '../Loader';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';

const useStyles = makeStyles({
  root: {
    color: 'red',
    '&$checked': {
      color: 'red',
    },
  },
  checked: {},
});
const styles = {
  option: {
      // To change css for dropdown options
  },
  optionContainer: {
      // To change css for option container
      overflowY: 'scroll',
      overflowX: 'hidden',
      position: 'relative'
  },
  searchWrapper : {
    maxHeight: '80px', 
    overflowY: 'scroll',
  },

  multiselectContainer: {
      // To change css for multiselect (Width,height,etc..)
      height: '20px',
      marginTop: '10px',
      marginBottom: '65px',
      position: 'relative',
      outerWidth: '95%'
  },
  chips: {
      // To change css chips(Selected options)
      // display:"none",
      background: '#A9A9A9'
  },
  searchBox: {
    // To change search box element look
    border: 'none',
    maxHeight: '50px',
    padding: '7px 10px',
    fontSize: '15px',
    overflowY: 'scroll',
    backgroundColor: '#F3F4F6',
    backgroundImage: 'url(../../Assets/drop_down.png)',
    borderRadius: '2px'
  }
};

function CreatorTable(props) {
  let statesObject = {
    "Alabama": "AL",
    "Alaska": "AK",
    "Arizona": "AZ",
    "Arkansas": "AR",
    "California": "CA",
    "Colorado": "CO",
    "Connecticut": "CT",
    "Delaware": "DE",
    "Florida": "FL",
    "Georgia": "GA",
    "Hawaii": "HI",
    "Idaho": "ID",
    "Illinois": "IL",
    "Indiana": "IN",
    "Iowa": "IA",
    "Kansas": "KS",
    "Kentucky": "KY",
    "Louisiana": "LA",
    "Maine": "ME",
    "Maryland": "MD",
    "Massachusetts": "MA",
    "Michigan": "MI",
    "Minnesota": "MN",
    "Mississippi": "MS",
    "Missouri": "MO",
    "Montana": "MT",
    "Nebraska": "NE",
    "Nevada": "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    "Ohio": "OH",
    "Oklahoma": "OK",
    "Oregon": "OR",
    "Pennsylvania": "PA",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    "Tennessee": "TN",
    "Texas": "TX",
    "Utah": "UT",
    "Vermont": "VT",
    "Virginia": "VA",
    "Washington": "WA",
    "West Virginia": "WV",
    "Wisconsin": "WI",
    "Wyoming": "WY",
  };
  

  function addCityToStates(statesObj, arr) {
    arr.forEach((item) => {
      if (item.shipping_details && item.shipping_details.city && item.shipping_details.state) {
        const { city, state } = item.shipping_details;
        if (statesObj[state]) {
          if (!statesObj[state].includes(city)) {
            statesObj[state].push(city);
          }
        }
      }
    });
    return statesObj;
  }

  


  const [selectedRows, setSelectedRows] = useState({});
  const [selectedState, setSelectedState] = useState("");
  const [data, setData] = useState();
  const userState = useUserState();
  const campaignState = useCampaignState();
  const [sliderValue, setSliderValue] = useState([0, 2000000]);
  const [inputValue, setInputValue] = useState('');
  const [selectedCity, setSelectedCity] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [eRValue, setERValue] = useState([0, 15]);
  const [currentPage, setCurrentPage] = useState(1);
  const { brandId } = useContext(BrandContext);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [recipients, setRecipients] = useState();
  const [userData, setUserData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const {currentUser} = useAuth();

  const brandState = useBrandState();
  useEffect(() => {
    BrandService.FetchBrandForUser(brandId)
  }, [brandState.updateNeed.value]);

  const brand = brandState.brand.value;
  
  useEffect(() => {
    if (userState.updateNeed.value && userData.length === 0) {
      UsersService.FetchCreators()
        .then((fetchedUserData) => {
          const transformedUserData = fetchedUserData.result && Object.entries(fetchedUserData.result)
            .map(([id, value]) => ({ id, ...value }))
            .filter(entry => getFirstName(entry) !== '') // Filter out entries with blank first names
            .filter(entry => entry.creator_profile && entry.creator_profile.socials) // Filter out entries without creator_profile.socials
            .filter(entry => entry.shipping_details && entry.shipping_details.state) // Filter out entries without shipping_details
            .sort((a, b) => getFirstName(a).localeCompare(getFirstName(b))); // Sort alphabetically by first name
          setUserData(transformedUserData); // Update the user data state with the transformed data
        })
        .catch((error) => {
          console.error('Error fetching user data:', error); // Log any errors during data fetching
        });
    }
  }, [userState.updateNeed.value, userData, getFirstName]);


  const getFirstName = entry => {
    if (entry.firstName && entry.firstName.trim() !== '') {
      return entry.firstName.trim();
    }
    if (entry.first_name && entry.first_name.trim() !== '') {
      return entry.first_name.trim();
    }
    return '';
  };
  

  

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const itemsPerPage = 8; // Set the desired number of items per page
  const startIdx = (currentPage - 1) * itemsPerPage;
  const endIdx = currentPage * itemsPerPage;
  const itemsToShow = filteredData && filteredData.slice(startIdx, endIdx);
  const totalPages = Math.ceil(filteredData && filteredData.length / itemsPerPage);


  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    setCurrentPage(1);
  
    const updatedFilteredData = filterData(sliderValue, value, selectedState, selectedCities, selectedPlatforms, userData);
    setFilteredData(updatedFilteredData); // Set the filtered data back to the state
  };

  function decimalStringToPercentage(decimalString) {
    if (typeof decimalString !== 'string') {
      return null;
    }
  
    const decimalNumber = parseFloat(decimalString);
  
    if (isNaN(decimalNumber)) {
      return null;
    }
  
    const percentage = (decimalNumber * 100).toFixed(1);
  
    return percentage + "%";
  }
  function extractPercentageAndConvertToFloat(percentageString) {
    const percentageWithoutSign = percentageString.slice(0, -1);
  
    const percentageValue = parseFloat(percentageWithoutSign);
  
    if (isNaN(percentageValue)) {
      throw new Error("Invalid percentage string");
    }
  
    return percentageValue;
  }
  

  const handlePlatformChange = (e) => {
    const selectedOption = e.target.value;
    const isChecked = e.target.checked;
    
    if (isChecked) {
      setSelectedPlatforms((prevSelectedPlatforms) => [...prevSelectedPlatforms, selectedOption]);
    } else {
      setSelectedPlatforms([]);
    }
  
    if (isChecked) {
      filterData(sliderValue, inputValue, selectedStates, selectedCity, [...selectedPlatforms, selectedOption], userData);
    } else {
      filterData(sliderValue, inputValue, selectedStates, selectedCity, selectedPlatforms, userData);
    }
  };

  const filterData = (sliderValue, inputValue, selectedStates, selectedCity, selectedSocials, userData) => {
    const [sliderValueMin, sliderValueMax] = sliderValue;
    const lowerInputValue = inputValue.toLowerCase();
    const selectedSocial = selectedSocials.length > 0 ? selectedSocials[0] : '';
  
    const filteredData = userData && userData.filter(entry => {
      const followerInRange = entry.followerCount >= sliderValueMin && entry.followerCount <= sliderValueMax;
      const matchesInputValue = (entry.firstName && entry.firstName.toLowerCase().includes(lowerInputValue)) || (entry.first_name && entry.first_name.toLowerCase().includes(lowerInputValue));
      const matchesState = selectedStates.length === 0 || selectedStates.includes(entry.shipping_details.state);
      const matchesCity = selectedCity === '' || selectedCity === entry.shipping_details.city || selectedCities.includes(entry.shipping_details.city);
      const matchesSocial = selectedSocial === '' || selectedSocial === Object.keys(entry.creator_profile.socials)[0];
  
      return followerInRange && matchesInputValue && matchesState && matchesCity && matchesSocial;
    });
  
    setLoading(false);
    return filteredData;
  };
  
  const stateOptions = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' },
  ];

  const handleCheckboxChange = (event, email, first_name, followerCount) => {
    console.log(email, first_name, followerCount);
    let upperBound = 0;
    let lowerBound = 0;

    lowerBound = (followerCount / 1000) * 0.075 * 2500
    upperBound = (followerCount / 1000) * 0.20 * 5000

    let suggestedRate = Math.round((upperBound + lowerBound) / 2);

    const isChecked = event.target.checked;
    if (isChecked && Object.keys(selectedRows).length >= 100) {
      event.preventDefault(); // Prevent further selections if the total selected rows exceeds 100
      return;
    }
  
    setSelectedRows((prevSelectedRows) => {
      const updatedSelectedRows = { ...prevSelectedRows };
      if (isChecked) {
        setShowButton(true);
        updatedSelectedRows[email] = { email: email, first_name: first_name, suggestedRate: suggestedRate }; // Add the email and first name as an object to selectedRows when checked
      } else {
        delete updatedSelectedRows[email]; // Remove the email from selectedRows when unchecked
        setShowButton(Object.keys(updatedSelectedRows).length > 0);
      }
      setRecipients(Object.keys(updatedSelectedRows).map(email => ({ email: email, first_name: updatedSelectedRows[email].first_name, suggestedRate: updatedSelectedRows[email].suggestedRate }))); // Update recipients based on the selected emails

      console.log("Selected Rows:", updatedSelectedRows);
      return updatedSelectedRows; // Return the updated selectedRows
    });
  };

const handleStateChange = (selectedList) => {
  const selectedValues = selectedList.map(item => item.value);

  setSelectedStates(selectedValues);

  if (selectedValues.length === 0) {
    setSelectedCity('');
    setSelectedCities([]);
  }

  filterData(sliderValue, inputValue, selectedValues, selectedCity, selectedPlatforms, userData);
};

const handleStateRemove = (removedItem) => {
  const updatedStates = selectedStates.filter(state => state !== removedItem.value);
  setSelectedStates(updatedStates);
  if (updatedStates.length === 0) {
    setSelectedCity('');
    setSelectedCities([]);
  }
  filterData(sliderValue, inputValue, updatedStates, selectedCity, selectedPlatforms, userData);
};

const StateSelect = () => {
  return (
    <div className='z-10'>
      <label className="block text-gray-700 text-xs font-bold mb-2">State</label>
      <Multiselect
        options={stateOptions} 
        onSelect={handleStateChange}
        displayValue="label" 
        closeOnSelect={false} 
        onRemove={handleStateChange}
        placeholder="Select State"
      />
    </div>

  );
};

  const [value, setValue] = useState(0);

  const handleSlideChange = (event, newValue) => {
    setCurrentPage(1);
    setSliderValue(newValue); // Update the sliderValue state
    filterData(newValue, inputValue, selectedStates, selectedCity, selectedPlatforms, userData);
  };

  const handleEr = (event, newValue) => {
    setERValue(newValue);
    filterData(sliderValue, inputValue, selectedState, selectedCity, selectedPlatforms, newValue);
  };

  function addCommasToNumberOrString(num) {
    let numStr = parseInt(num).toLocaleString('en-US', {maximumFractionDigits: 0});
    return numStr;
  }
  useEffect(() => {
    setLoading(true);
    setCurrentPage(1);
    if (userData) {
      const updatedFilteredData = filterData(sliderValue, inputValue, selectedStates, selectedCity, selectedPlatforms, userData);
      setFilteredData(updatedFilteredData); // Set the filtered data state
      setLoading(false);
    }
  }, [sliderValue, inputValue, selectedStates, selectedCity, selectedPlatforms, userData]);

  function shortenStringIfNeeded(str) {
    if (typeof str !== 'string') {
      return '';
    }
  
    if (str.length > 14) {
      return str.slice(0, 14) + '. . .';
    }
  
    return str;
  }
  

  const handleCityChange = (e) => {
    const value = e.target.value;
    if (value !== "") {
      setSelectedCities(prevCitiesArray => [...prevCitiesArray, value]);
    }else{
      setSelectedCities([])
    }
  
    setSelectedCity(value);
    filterData(sliderValue, inputValue, selectedState, value, selectedPlatforms, userData);
  };


  function getCitiesForState(state) {
    if (state && statesObject[state]) {
      return [...new Set(statesObject[state])];
    }
    return [];
  }

  const CitySelect = () => {
    const cities = getCitiesForState(selectedState);
    return (
      <div>
        <label className="block text-gray-700 text-xs font-bold mb-2">City</label>
        <select
          value={selectedCity}
          onChange={handleCityChange}
          className="block w-1/3 py-2 px-3 border border-gray-300 bg-white text-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        >
          <option value="">Select City</option>
          {cities.map((city) => (
            <option key={city} value={city}>
              {city}
            </option>
          ))}
        </select>
        <div className="mt-2">
          <label className="text-gray-500">Selected Cities:</label>
          <ul className="list-disc ml-6">
            {selectedCities.map((city) => (
              <li key={city}>{city}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  };


  const socialMediaOptions = [
    { value: 'instagram', label: 'Instagram' },
    { value: 'tiktok', label: 'Tiktok' },
  ];
  

  const SocialMediaIcon = ( platform ) => {
    const socialMediaIcons = {
      facebook: <div className='Facebook w-6 h-6 ml-2 items-center'></div>,
      twitter: <div className='Twitter w-6 h-6 ml-2 items-center'></div>,
      youtube:<div className='Youtube w-6 h-6 ml-2 items-center'></div>,
      instagram: <div className='instagram w-6 h-6 ml-2 items-center'></div>,
      tiktok: <div className='tiktok w-6 h-6 ml-2 items-center'></div>,
      
    };
  {/*tiktok: <BsTiktok />,*/}
    return socialMediaIcons[platform] || null;
  };
  



  const SocialMediaSelect = () => {
  
    const clearAll = () => {
      setSelectedPlatforms([]);
      setSelectedCities([]);
      setCurrentPage(1);
      setSelectedStates([]);
      setSelectedState("");
      setSliderValue([0, 500000]);
      setInputValue("");
      filterData(selectedPlatforms, inputValue, selectedStates, selectedCity, selectedPlatforms, userData); 
    };
  
    const dividePlatformsIntoRows = (platforms) => {
      const rows = [];
      for (let i = 0; i < platforms.length; i += 2) {
        const row = platforms.slice(i, i + 2);
        rows.push(row);
      }
      return rows;
    };
  
    const platformRows = dividePlatformsIntoRows(socialMediaOptions);
  
    return (
      <div>
        <label className="block text-gray-700 text-base font-bold mb-2">Social Media Platforms:</label>
        <div className="space-y-2">
          {platformRows.map((row, rowIndex) => (
            <div key={rowIndex} className="flex">
              {row.map((platform) => (
                <label key={platform.value} className="flex items-center mr-6">
                  <input
                    type="checkbox"
                    value={platform.value}
                    checked={selectedPlatforms.includes(platform.value)}
                    onChange={handlePlatformChange}
                    className="form-checkbox h-5 w-5 text-indigo-600"
                  />
                  <span className="ml-2 text-gray-700">{platform.label}</span>
                </label>
              ))}
            </div>
          ))}
        </div>
        {selectedPlatforms.length > 0 && (
          <button
            onClick={clearAll}
            className="mt-2 text-xs text-red hover:text-red focus:outline-none"
          >
            Clear All
          </button>
        )}
      </div>
    );
  };

  const FilterSelects = () => {
    return (
      <div className="flex flex-row">
        {StateSelect()}
        {/*{CitySelect()}*/}
      </div>
    );
  };
  
  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);

    setSelectedRows((prevSelectedRows) => {
      if (isChecked) {
        const allEmails = userData && userData.map((com) => com.email);
        const newSelectedRows = {};
        setShowButton(true);
        allEmails.forEach((email) => {
          newSelectedRows[email] = true;
        });
        return newSelectedRows;
      } else {
        setShowButton(false);
        return {};
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Assuming selectedRows is an object with email addresses as keys and { email, first_name } as values
    const selectedEmails = Object.values(selectedRows).map(row => row.email);
    const selectedNames = Object.values(selectedRows).map(row => row.first_name);
    const selectedRates = Object.values(selectedRows).map(row => row.suggestedRate);
    const obj = {
      recipients: selectedEmails,
      names: selectedNames, 
      dynamic_variables: '',  
      email_body: props.message || '', 
      brand_name: brand.brand_name || brand.name,
      suggested_rates: selectedRates,
      subjectLine: `${brand.brand_name || brand.name} has invited you to a Crafted Campaign!`,
    }
    EmailService.sendCreatorEmail(obj);
    toast.success('Invites sent successfully!');
    setModalOpen(false);
    setShowButton(false);
    console.log('Sending emails:', obj);
    setLoading(true); 
    setLoading(false);
  };
  const classes = useStyles();


  return (
    <>
      <Toaster />
      <div className="-my-2 py-2  sm:-mx-4 sm:px-4 lg:-mx-6 pr-8 lg:px-8 h-fit">
      <p className='text-center text-0.7'>This is a database of creators outside the Crafted network. You can invite up to 100 creators outside of the Crafted network to apply for your campaign.</p> 
        <div className="align-middle rounded-tl-lg rounded-tr-lg inline-block w-full py-4 bg-white px-12">
          <div className="flex justify-between mb-2 ">
            <div className="inline-flex border rounded w-1/3 px-2 lg:px-4 h-10 bg-transparent">
              <div className="flex justify-between items-center w-full h-full mb-4 relative">
                <div className="flex">
                  <span className="flex items-center leading-normal bg-transparent rounded rounded-r-none border border-r-0 border-none lg:px-1 py-2 whitespace-no-wrap text-grey-dark text-xs">
                    <BsSearch />
                  </span>
                </div>
                <input
                  type="text"
                  className="flex-shrink flex-grow flex-auto leading-normal tracking-wide w-px flex-1 border border-none border-l-0 rounded rounded-l-none px-3 relative focus:outline-none text-xxs lg:text-xs lg:text-base text-gray-500 font-thin"
                  placeholder="Search"
                  onChange={handleInputChange}
                  value={inputValue}
                />
              </div>
            </div>
          </div>
        <div className="flex justify-between mb-4">
        {/*<div className="flex flex-col w-1/4">
          <div className="text-xs text-gray-500">
            <span>Engagement Rate</span>
          </div>
          <Slider
            value={eRValue}
            onChange={handleEr}
            min={0}
            max={15}
            className="w-full"
            aria-labelledby="continuous-slider"
            marks={[
              { value: 0, label: '0%' },
              { value: (5), label: '5%' },
              { value: (10), label: '10%' },
              { value: (15), label: '15%' },
            ]}
          />
        </div>*/}
        <div className="flex flex-col 2xl:w-1/4 w-1/2">
          <div className="text-base text-gray-500">
            <span>Follower Count</span>
          </div>
          <Slider
            getAriaLabel={() => 'range'}
            value={sliderValue}
            onChange={handleSlideChange}
            valueLabelDisplay="auto"
            getAriaValueText={(sliderValue) => `${sliderValue}`}
            min={0}
            max={500000}
            className="w-full"
            marks={[
              { value: (0), label: '0' },
              { value: (125000), label: '125K' },
              { value: (250000), label: '250K' },
              { value: (375000), label: '375k' },
              { value: (500000), label: '500k+' },
            ]}
          />
        </div>
        <div className="flex flex-col w-1/4">
          <SocialMediaSelect />
        </div>
      </div>
          {FilterSelects()}
          {showButton && (
            <>
              <div className='flex items-center mt-2'>
              <button
                onClick={() => {
                  // Handle the action you want to perform when the button is clicked
                  // For example, you can do something with the selected rows
                  // Reset the selected rows and hide the button
                  setModalOpen(true);
                }}
                className="bg-red text-white px-4 py-2 rounded shadow"
              >
                Invite Influencers to Campaign
              </button>
              <p className='ml-3 '>{Object.entries(selectedRows).length > 1 ? `${Object.entries(selectedRows).length} influencers selected` : `${Object.entries(selectedRows).length} influencer selected`}</p>
              </div>
              <div className={modalOpen ? "flex mt-4" : "hidden"}>
                <span className='m-2 font-bold'>Ready to send?</span>
                <button className='bg-Blue text-white px-3 py-1 mr-2 rounded-lg' onClick={handleSubmit}>
                     Yes
                </button>
                <button className='bg-gray-400 text-white px-3 py-1 rounded-lg' onClick={() => setModalOpen(false)}>
                     No 
                </button>
              </div>
        </>
      )}
        </div>
        <div 
          className="align-middle stick yinline-block min-w-full overflow-hidden bg-white px-8 pt-3 rounded-bl-lg rounded-br-lg"
          style={{ width: "100%", display: "block", overflowX: "auto", position: "sticky"}}
>
          <table className="min-w-full overflow-x-auto sticky">
            <thead>
              <tr>
                <th className="border-b-2 sticky top-0 border-gray-300">
                  
                </th>
                <th className="px-4 py-2 text-xs border-b-2  sticky top-0 border-gray-300 text-left leading-4 text-blue-500 tracking-wider">
                  Name
                </th>
                <th className="px-4 py-2 text-xs border-b-2  sticky top-0 border-gray-300 text-left leading-4 text-blue-500 tracking-wider">
                  Platform
                </th>
                <th className="px-4 py-2 text-xs border-b-2  sticky right-0 border-gray-300 text-left leading-4 text-blue-500 tracking-wider">
                  Follower Count
                </th>
                <th className="px-4 py-2 text-xs border-b-2  sticky top-0 border-gray-300 text-left leading-4 text-blue-500 tracking-wider">
                  City/State
                </th>
              </tr>
            </thead>
              <tbody 
                className="bg-white scrolling-touch overflow-y-scroll w-full scrollbar">
                  {loading ? (
                  <tr>
                  <td colSpan="6"> {/* Use colSpan to make the loading circle span across all columns */}
                    <div className="flex justify-center items-center h-64">
                      <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-red"></div>
                      <Loader/>
                    </div>
                  </td>
                </tr>
                  ) : (
                <>
               {itemsToShow.length > 0 && itemsToShow.map((com, i) => {
                  let shippingContent = null;
                 
                  if (typeof com === 'object' && com.shipping_details) {
                    let city = "";
                    let unitedState = "";
                    if (typeof com === 'object' && com.shipping_details) {
                      try {
                        let city = com.shipping_details.city || '';
                        let unitedState = com.shipping_details.state || '';
                        shippingContent = <p>{city}/{unitedState}</p>;
                      } catch (error) {
                        console.error('Error accessing shipping details:', error);
                      }
                    }
                  }      
                    return (
                      <tr key={i} 
                      className={selectedRows[com.email] ? 'bg-gray-300 text-white'  : ''}                      
                      >
                        <td className="border-b border-gray-500">
                          <label>
                          <Checkbox
                            classes={{
                              root: classes.root,
                              checked: classes.checked,
                            }}
                            type="checkbox"
                            checked={selectedRows[com.email] || false}
                            onChange={(e) => handleCheckboxChange(e, com.email, (com.first_name || com.firstName), com.followerCount)}
                          />
                          </label>
                        </td>  
                        <td className="text-xs px-2 py-1 whitespace-no-wrap border-b border-gray-500 sticky 2xl:w-1/5  md:w-1/3">
                          <div className="text-xm leading-5 text-blue-900">{shortenStringIfNeeded(com.firstName) || shortenStringIfNeeded(com.first_name)}</div>
                        </td>
                        <td className="text-xs px-2 py-1 whitespace-no-wrap border-b text-blue-900 border-gray-500 text-xs leading-5 2xl:w-1/5  md:w-1/3">
                          <a target='_blank' href={com && com.creator_profile && com.creator_profile.socials && com.creator_profile.socials[Object.keys(com.creator_profile.socials)[0]]}>{com.creator_profile && SocialMediaIcon(Object.keys(com.creator_profile.socials)[0]) || ''}</a>
                        </td>
                        <td className="text-xs px-2 py-1 whitespace-no-wrap border-b border-gray-500 sticky 2xl:w-1/5  md:w-1/3">
                          <div className="flex items-center">
                            <div>
                              <div className="text-xs leading-5">{addCommasToNumberOrString(com.followerCount) || ""}</div>
                            </div>
                          </div>
                        </td>
                        <td className="text-xs px-2 text-xm py-1 whitespace-no-wrap border-b text-blue-900 border-gray-500 leading-5 sticky 2xl:w-1/5  md:w-1/3">
                          {shippingContent}
                        </td>
                      </tr>
                      );
                    })}
                </>
                )}
                  </tbody>
          </table>
        </div>
      </div>
      <CustomPagination
        totalItems={filteredData && filteredData.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        totalPages={totalPages}
      />
    </>
  );
}

export default CreatorTable;