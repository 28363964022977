import React, { useContext, useEffect, useRef, useState } from "react";
import CampaignAnalyticsChart from "./CamapaignAnalyticsChart";
import Chart from "./../../../Admin/Chart.js";
import {
  useCampaignState,
  CampaignService,
} from "../../../../Service/CampaignServices";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../Context/AuthContext";
import { baseUrl } from "../../../../baseUrl";
import { FaThumbsUp, FaThumbsDown, FaMinus } from "react-icons/fa";
import toast, { Toaster } from "react-hot-toast";
import { Mixpanel } from "../../../../mixpanel";
import { BsQuestionCircle } from "react-icons/bs";
import GaugeComponent from "react-gauge-component";
import { BrandContext } from "../../../../routes/PrivateRoute/BrandDashboardRoute";
import CampaignCommentsAnalytics from "./CampaignCommentsAnalytics";
import ReactApexChart from "react-apexcharts";
import Loader from "../../../Loader2";

// Commenting out as it is not working atm
// import RetailAnalytics from './RetailAnalytics';

function CampaignAnalytics(props) {
  const [analytics, setAnalytics] = useState([]);
  //const searchTerm = useRef('');
  const [searchTerm, setSearchTerm] = useState("");
  const [fetchAnalytics, setFetchAnalytics] = useState(false);
  const campaignState = useCampaignState();
  const [filtered, setFiltered] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const scrollDiv = useRef(null);
  const [tab, setTab] = useState(0);
  const { id } = useParams();
  const [analyticsData, setAnalyticsData] = useState([]);
  let numberOfProposals = 0;
  const { brandId } = useContext(BrandContext);
  const [filteredComments, setFilteredComments] = useState([]);
  const [positivePercentage, setPositivePercentage] = useState(0);
  const [negativePercentage, setNegativePercentage] = useState(0);
  const [neutralPercentage, setNeutralPercentage] = useState(0);
  const [purchaseIntentPercentage, setPurchaseIntentPercentage] = useState(0);
  const [loading, setLoading] = useState(true);

  // Used to fetch the campaign data from the API
  useEffect(() => {
    if (id) {
      CampaignService.FetchSingleCampaign(id);
    }
  }, [id, campaignState.updateNeed.value]);

  const { currentUser } = useAuth();
  const res = JSON.stringify(campaignState.campaign.value);
  const campaign = JSON.parse(res);
  //console.log(campaign);
  //console.log(campaign.analytics);
  const tasks = Object.keys(campaign).length !== 0 ? campaign.tasks : [];

  console.log("Current User: ", currentUser);

  useEffect(() => {
    if (campaign.analytics) {
      // Use the campaign_id in your API call
      const fetchAnalytics = async () => {
        try {
          const response = await fetch(
            `${baseUrl}api/campaigns/${id}/getVideoAnalytics`,
          );
          const data = await response.json();
          setAnalytics(data.result);
          //console.log('Fetched data:', data);
          //console.log('Campaign ID:', id); // Now you can access the campaign_id here
        } catch (error) {
          console.error("Error fetching Influencer Tasks:", error);
        }
      };

      fetchAnalytics();
    }
  }, [campaign.analytics, id]);

  const refreshAnalytics = async () => {
    try {
      let req_body = {
        brand_id: brandId,
      };
      const response = await fetch(
        `${baseUrl}api/campaigns/influencer/${id}/refreshAnalytics`,
        {
          method: "POST",
          body: JSON.stringify(req_body),
        },
      );
      const data = await response.json();
      setFetchAnalytics(data); // Assuming 'data' contains the new analytics
      //console.log('Fetched data:', data);
    } catch (error) {
      console.error("Error refreshing analytics:", error);
    }
  };

  // Listen for scroll events
  useEffect(() => {
    const handleScroll = () => {
      if (scrollDiv.current.scrollTop > 0) {
        setShowPopup(false);
      }
    };

    scrollDiv.current.addEventListener("scroll", handleScroll);

    return () => {
      scrollDiv.current.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Show popup if user hasn't scrolled yet
  useEffect(() => {
    if (!hasScrolled) {
      setShowPopup(true);
    }
  }, [hasScrolled]);

  // used for the charts in the analytics tab
  useEffect(() => {
    fetch(`${baseUrl}api/campaigns/influencer/${id}/analytics`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.result.historicalAnalytics)) {
          // Initializing chart data object
          const chartData = {};

          // Iterating over each analytics object
          data.result.historicalAnalytics.forEach((analytics) => {
            const updatedTime = new Date(analytics.value.updated).getTime();
            const properties = Object.keys(analytics.value).filter(
              (property) => property !== "updated",
            );

            // Iterating over each property in the analytics object
            properties.forEach((property) => {
              // Initializing chart data array for each property
              if (!chartData[property]) {
                chartData[property] = {
                  name: property.replace(/([A-Z])/g, " $1").toLowerCase(),
                  data: [],
                };
              }

              // Adding data point to the chart data array
              const value =
                property === "totalClicks"
                  ? analytics.value[property] || 0
                  : analytics.value[property];
              chartData[property].data.push({ x: updatedTime, y: value });
            });
          });

          // Updating the state with the chart data
          setAnalyticsData(Object.values(chartData));
        } else {
          console.error(
            "Analytics data is not an array:",
            data.result.analytics,
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching analytics data:", error);
      });
  }, [id]);
  const chartOptions = {
    type: "area",
    series: analyticsData.map((series) => ({
      ...series,
      tooltip: {
        enabled: true,
        formatter: function (val) {
          return Math.round(val[0].dataPoint.y).toString();
        },
      },
    })),
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      tickPlacement: "off",
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          if (val !== undefined) {
            return Math.round(val).toLocaleString(); // Rounding the number and format with commas
          }
          return val;
        },
      },
    },
    stroke: {
      curve: "smooth",
    },
    markers: {
      size: 6,
      hover: {
        sizeOffset: 3,
      },
    },
  };
  const colors = [
    "#648DE5", // Base color
    "#4C76BE", // Slightly darker
    "#7CA6FF", // Slightly lighter
    "#3C5A91", // Darker
    "#91B2FF", // Lighter
    "#2C3E50", // Much darker
    "#B0C6FF", // Much lighter
    "#5D7EAA", // Medium
    "#1B2631", // Very dark
    "#D6E0FF", // Very light
    "#415B76", // Medium-dark
    "#A3BFFA", // Medium-light
  ];

  const commentsData = (campaign && campaign.instagram_comments) || {};

  let totalLikesAndComments = 0;
  let impliedROI = 0.0;
  let totalLikes = 0;
  let totalComments = 0;
  let totalShares = 0;
  let totalViews = 0;
  let er = 0.0;
  let cpm = 0.0;
  if (campaign.realtime_analytics) {
    totalLikes = campaign.realtime_analytics.totalLikes || 0;
    totalComments = campaign.realtime_analytics.totalComments || 0;
    totalViews = campaign.realtime_analytics.totalViews;
    totalLikesAndComments = totalLikes + totalComments;
    totalShares = campaign.realtime_analytics.totalShares || 0;

    //calculate CPM
    cpm = (campaign.utilized_budget / 100 / (totalViews / 1000)).toFixed(2);

    //calculate impliedROI
    impliedROI = (
      (totalViews * 0.01 * 10) /
      (campaign.influencer_reach_budget / 100)
    ).toFixed(1);

    //calculate ER
    er = ((totalLikesAndComments / totalViews) * 100).toFixed(2);

    let viewership_target = (campaign.influencer_reach_budget / 33) * 1000;
    //console.log(viewership_target);
    if (totalViews >= viewership_target) {
      let mixpanel_campaign = {
        campaign_name: campaign.campaign_name,
        campaign_id: campaign.id,
      };
      Mixpanel.viewerShipReached(mixpanel_campaign);
    }
  }

  useEffect(() => {
    refreshAnalytics();
  }, []);

  const refreshAnalyticsButton = () => {
    refreshAnalytics();
    toast.success("Analytics Udpated");
  };

  const positiveKeywords = [
    "happy with",
    "excellent",
    "wonderful",
    "amazing",
    "fantastic",
    "delightful",
    "perfect",
    "great",
    "beautiful",
    "delicious",
    "incredible",
    "delivered",
    "good",
    "thrilled",
    "i want",
    "want",
    "buy",
    "purchase",
    "order",
    "get",
    "need",
    "must have",
    "nice",
    "awesome",
    "AMAZING!!",
    "love",
    "recommend",
    "acquire",
    "obtain",
    "try this out",
    "add to cart",
    "place an order",
    "appreciate",
    "can't wait",
    "impressed",
    "I'm ready to buy",
    "Adding to cart",
    "Ready to purchase",
    "Placing an order",
    "Considering buying",
    "Getting this soon",
    "Will buy",
    "Interested in purchasing",
    "Putting it on my shopping list",
    "Checking out",
    "Looking to buy",
    "Exploring options to purchase",
    "Getting one for myself",
    "Thinking of buying",
    "Adding it to my wishlist",
    "Ready to make a purchase",
    "Decided to buy",
    "Planning to purchase",
    "About to buy",
    "Taking the plunge and buying",
    "yum",
    "sold",
    "🔥",
    "🤤",
    "😍",
    "👏",
    "❤️",
    "walmart",
    "target",
    "kroger",
    "whole food",
    "buying",
    "find",
    "getting",
    "soon",
    "purchasing",
    "list",
    "shopping",
    "check",
    "please start carrying",
    "the best one",
    "looking",
    "explor",
    "wishlist",
    "ready",
    "plan",
    "wow",
    "got to",
    "have to",
    "Recipe please! thank you",
    "thank you",
    "recipe please",
    "can i get the recipe",
    "gimme recipe",
    "gimme",
    "need to try",
    "gotta try",
    "I def wanna try this",
    "I wanna try this",
    "i wanna try this",
    "i want to try this",
    "epic",
    "delicious",
    "tasty",
    "yummy",
    "yum",
    "mouthwatering",
    "mouth-watering",
    "so good",
    "genius",
    "brilliant",
    "amazing",
    "fantastic",
    "incredible",
  ];

  const purchaseIntentKeywords = [
    "purchase",
    "buy",
    "order",
    "get",
    "acquire",
    "obtain",
    "delivered",
    "try this out",
    "cart",
    "order",
    "sold",
    "try",
    "walmart",
    "target",
    "kroger",
    "whole food",
    "buying",
    "find",
    "place an order",
    "getting",
    "appreciate",
    "can't wait",
    "impressed",
    "add to cart",
    "soon",
    "purchasing",
    "list",
    "shopping",
    "check",
    "looking",
    "explor",
    "wishlist",
    "ready",
    "plan",
    "got to",
    "have to",
    "please start carrying",
    "please start selling",
    "please start stocking",
    "please start offering",
    "please start providing",
    "provide",
    "need to try",
    "gotta try",
    " I def wanna try this",
    "I wanna try this",
    "i wanna try this",
    "I want to try this",
    "Recipe please! thank you",
    "thank you",
    "recipe please",
    "can i get the recipe",
    "gimme recipe",
  ];

  const negativeKeywords = [
    "sad",
    "disappointing",
    "horrible",
    "terrible",
    "don’t keep buying these products",
    "ruined",
    "ruin",
    "dick",
    "disgusting",
    "awful",
    "wouldn't try this out",
    "don't try this out",
    "don't buy",
    "do not buy",
    "frustrating",
    "annoying",
    "displeased",
    "unpleasant",
    "nasty",
    "I hate",
    "i hate",
    "i don't like",
    "i don't recommend",
    "i dont recommend",
    "i wont buy",
    "i won't buy",
    "return",
    "unhappy",
    "refund",
    "bad",
    "dislike",
    "cancel",
    "not buy",
    "not purchase",
    "straight to jail",
    "straight to prison",
    "NOT an ethical",
    "not ethical",
    "not interested",
    "skip",
    "not good",
    "doesn't look good",
    "not great",
    "don't like it",
    "dont like it",
    "dont recommend",
    "don't recommend",
    "do not purchase",
    "avoid",
    "don't like",
    "don't buy",
    "won't buy",
    "won't purchase",
    "let down",
    "no one buys here",
    "no one buys",
    "dont like",
    "dont buy",
    "wont buy",
    "wont purchase",
    "dont keep buying",
    "don’t keep buying these products",
    "dont buy this",
    "can't buy",
    "cant buy",
    "waste",
    "pass",
    "reject",
    "expensive",
  ];

  console.log("Search Term:", searchTerm);

  const convertCommentsToArray = (data) => {
    if (data) {
      return Object.values(data)
        .flatMap((thread) => Object.values(thread))
        .flat();
    }
    return [];
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    const searchTermValue = searchTerm.toLowerCase();
    console.log("Search term:", searchTermValue);
    const commentsArray = convertCommentsToArray(commentsData);
    console.log("Comments array:", commentsArray);
    const updatedFiltered = commentsArray.filter((comment) => {
      return comment.text.toLowerCase().includes(searchTermValue);
    });
    console.log("Filtered comments:", updatedFiltered);
    setFilteredComments(updatedFiltered);
  };

  const matchKeyword = (text, keywords) => {
    const regex = new RegExp(
      `(${keywords
        .map((keyword) => keyword.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"))
        .join("|")})`,
      "i",
    );
    return regex.test(text);
  };

  const classifyCommentSentiment = (comment) => {
    const text = comment && comment.text ? comment.text.toLowerCase() : "";

    if (text && matchKeyword(text, negativeKeywords)) {
      return "negative";
    } else if (text && matchKeyword(text, positiveKeywords)) {
      return "positive";
    } else {
      return "neutral";
    }
  };

  useEffect(() => {
    if (commentsData) {
      const filtered = Object.values(commentsData).flatMap((thread) => thread);
      filtered.forEach((comment) => {
        if (comment) {
          comment.sentiment = classifyCommentSentiment(comment);
        }
      });
      setFilteredComments(filtered);
    }
  }, []);

  useEffect(() => {
    if (commentsData) {
      const allComments = Object.values(commentsData)
        .flatMap((thread) => Object.values(thread))
        .flat();

      const positiveComments = allComments.filter(
        (comment) => comment && comment.sentiment === "positive",
      );
      const negativeComments = allComments.filter(
        (comment) => comment && comment.sentiment === "negative",
      );
      const purchaseIntentComments = allComments.filter(
        (comment) =>
          comment &&
          comment.text &&
          matchKeyword(comment.text.toLowerCase(), purchaseIntentKeywords),
      );
      const neutralComments = allComments.filter(
        (comment) => comment && comment.sentiment === "neutral",
      );

      const totalComments = allComments.length;
      const positivePercentage =
        (positiveComments.length / totalComments) * 100;
      const negativePercentage =
        (negativeComments.length / totalComments) * 100;
      const neutralPercentage = (neutralComments.length / totalComments) * 100;
      const purchaseIntentPercentage =
        (purchaseIntentComments.length / totalComments) * 100;

      setPositivePercentage(positivePercentage);
      setNegativePercentage(negativePercentage);
      setNeutralPercentage(neutralPercentage);
      setPurchaseIntentPercentage(purchaseIntentPercentage);
    }
  }, []);

  const getTotalCommentsCount = (commentsData) => {
    let count = 0;
    Object.keys(commentsData).forEach((postId) => {
      Object.keys(commentsData[postId]).forEach((threadId) => {
        count += commentsData[postId][threadId].length;
      });
    });
    return count;
  };
  //console.log(filteredComments);

  useEffect(() => {
    console.log("Filtered comments BEFORE:", filteredComments);
  }, [filteredComments]);

  useEffect(() => {
    console.log("Filtered comments UPDATED:", filteredComments);
  }, [filteredComments]);

  // console.log("RE-RENDER");

  // loading spinner
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 800);

    return () => clearTimeout(timer); // This will clear the timeout if the component unmounts before the timeout finishes
  }, []);
  return (
    <div
      ref={scrollDiv}
      className="bg-white w-full rounded-lg z-100 overflow-x-auto"
      style={{ height: "calc(80vh - 5rem)" }}
    >
      <Toaster />

      {loading ? (
        <div className="flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <>
          {campaign &&
          campaign.realtime_analytics &&
          campaign.realtime_analytics.totalPosts > 0 ? (
            <>
              <div className="flex items-center justify-evenly mb-2 border-b-2 sticky top-0 h-fit z-10 bg-white">
                <div
                  className="cursor-pointer transform transition duration-300 ease-in-out hover:text-Blue hover:scale-110"
                  onClick={() => setTab(0)}
                >
                  <h2
                    className={`2xl:text-base md:text-xs font-semibold ${
                      tab === 0 ? "text-Blue" : "text-gray-600"
                    }`}
                  >
                    Analytics
                  </h2>
                </div>
                <div
                  className="cursor-pointer transform transition duration-300 ease-in-out hover:text-Blue hover:scale-110"
                  onClick={() => setTab(1)}
                >
                  <h2
                    className={`2xl:text-base md:text-xs font-semibold ${
                      tab === 1 ? "text-Blue" : "text-gray-600"
                    }`}
                  >
                    Comments
                  </h2>
                </div>
              </div>
              {tab === 0 && (
                <div className="grid grid-cols-2 gap-2 gap-y-4 p-4">
                  <div className="">
                    <div className="p-3 border shadow-md xl:h-35rem md:h-25rem bg-white">
                      <h2 className="text-center  font-bold text-2xl sm:text-3xl md:text-3xl lg:text-4xl mb-4">
                        Realtime Overall Metrics
                      </h2>
                      <div
                        className={`grid mt-10 gap-y-10 text-center 2xl:mb-5 ${
                          campaign &&
                          campaign.realtime_analytics &&
                          campaign.realtime_analytics.total_clicks > 0
                            ? "grid-cols-5"
                            : "grid-cols-3"
                        }`}
                      >
                        <div>
                          <p className="font-bold  md:text-0.65 2xl:text-2xl mb-2">
                            TOTAL VIEWS
                          </p>
                          <h3 className="font-black md:text-2xl 2xl:text-4xl mb-1">
                            {campaign.realtime_analytics.totalViews}
                          </h3>
                        </div>
                        <div>
                          <p className=" font-bold  md:text-0.65 2xl:text-2xl mb-2">
                            TOTAL LIKES
                          </p>
                          <h3 className="font-black md:text-2xl 2xl:text-4xl mb-1">
                            {totalLikes}
                          </h3>
                        </div>
                        <div>
                          <p className=" font-bold  md:text-0.65 2xl:text-2xl mb-2">
                            TOTAL COMMENTS
                          </p>
                          <h3 className="font-black md:text-2xl 2xl:text-4xl mb-1">
                            {totalComments}
                          </h3>
                        </div>
                        <div>
                          <p className=" font-bold   md:text-0.65 2xl:text-2xl mb-2">
                            TOTAL SHARES
                          </p>
                          <h3 className="font-black md:text-2xl 2xl:text-4xl mb-1">
                            {totalShares}
                          </h3>
                        </div>
                        <div>
                          <p className="font-bold   md:text-0.65 2xl:text-2xl mb-2">
                            AVERAGE VIEWS
                          </p>
                          <h3 className="font-black md:text-2xl 2xl:text-4xl mb-1">
                            {(
                              campaign.realtime_analytics.totalViews /
                              campaign.realtime_analytics.totalPosts
                            ).toFixed(1)}
                          </h3>
                        </div>
                        <div>
                          <p className="font-bold   md:text-0.65 2xl:text-2xl mb-2">
                            ENGAGEMENT RATE
                          </p>
                          <h3 className="font-black md:text-2xl 2xl:text-4xl mb-1">
                            {er}%
                          </h3>
                        </div>
                        <div>
                          <p className=" font-bold  md:text-0.65 2xl:text-2xl mb-2">
                            AVERAGE CPM
                          </p>
                          <h3 className="font-black md:text-2xl 2xl:text-4xl mb-1">
                            ${cpm}
                          </h3>
                        </div>
                        <div>
                          <p className=" font-bold  md:text-0.65 2xl:text-2xl mb-2">
                            INFERRED SALES
                          </p>
                          <h3 className="font-black md:text-2xl 2xl:text-4xl mb-1">
                            ${(totalViews * 0.03 * 7).toFixed(2)}
                          </h3>
                        </div>
                        {campaign &&
                          campaign.realtime_analytics &&
                          campaign.realtime_analytics.total_clicks > 0 && (
                            <div>
                              <p className="font-bold  text-sm sm:text-base md:text-0.7 2xl:text-2xl mb-2">
                                TOTAL CLICKS
                              </p>
                              <h3 className="font-black text-2xl sm:text-3xl md:text-2xl 2xl:text-4xl mb-1">
                                {campaign.realtime_analytics.total_clicks}
                              </h3>
                            </div>
                          )}
                        <div>
                          <p className=" font-bold text-sm sm:text-base md:text-0.7 2xl:text-2xl mb-2">
                            TOTAL POSTS
                          </p>
                          <h3 className="font-black text-2xl sm:text-3xl md:text-2xl 2xl:text-4xl mb-1">
                            {campaign.realtime_analytics.totalPosts}
                          </h3>
                        </div>
                        {campaign.invites &&
                          currentUser &&
                          currentUser.uid ===
                            "aOLwKHwgMAbQfJv3QIPXk616Nwp1" && (
                            <>
                              <div>
                                <p className=" font-bold text-sm sm:text-base md:text-0.7 2xl:text-2xl mb-2">
                                  TOTAL INVITES
                                </p>
                                <h3 className="font-black text-2xl sm:text-3xl md:text-2xl 2xl:text-4xl mb-1">
                                  {campaign.invites.sent +
                                    campaign.invites.failed}
                                </h3>
                              </div>
                              <div>
                                <p className=" font-bold text-sm sm:text-base md:text-0.7 2xl:text-2xl mb-2">
                                  SUCCESSFUL INVITES
                                </p>
                                <h3 className="font-black text-2xl sm:text-3xl md:text-2xl 2xl:text-4xl mb-1">
                                  {campaign.invites.sent}
                                </h3>
                              </div>
                              <div>
                                <p className=" font-bold text-sm sm:text-base md:text-0.7 2xl:text-2xl mb-2">
                                  FAILED INVITES
                                </p>
                                <h3 className="font-black text-2xl sm:text-3xl md:text-2xl 2xl:text-4xl mb-1">
                                  {campaign.invites.failed}
                                </h3>
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                    {showPopup && (
                      <div className="z-50 rounded fixed bottom-10 2xl:ml-60 md:ml-36 mx-auto w-1/2 font-bold bg-Blue text-white text-center py-2 animate-bounce">
                        Scroll for more data
                      </div>
                    )}
                  </div>
                  {analyticsData
                    .filter((series) => {
                      // Calculate the sum of all y values in the series
                      const totalY = series.data.reduce(
                        (acc, point) => acc + point.y,
                        0,
                      );
                      // Exclude "total clicks" series if the totalY is 0
                      return !(
                        series.name.toLowerCase() === "total clicks" &&
                        totalY === 0
                      );
                    })
                    .sort((a, b) => {
                      const order = [
                        "total views",
                        "total likes",
                        "total comments",
                        "total shares",
                        "total clicks",
                        "total posts",
                      ];
                      return (
                        order.indexOf(a.name.toLowerCase()) -
                        order.indexOf(b.name.toLowerCase())
                      );
                    })
                    .map((series, index) => {
                      // Rename "total clicks" to "Clicks Per Day"
                      if (series.name.toLowerCase() === "total clicks") {
                        series.name = "Clicks Per Day";
                      }

                      const maxVal =
                        Math.ceil(
                          (Math.max(...series.data.map((point) => point.y)) *
                            1.2) /
                            10,
                        ) * 10;
                      const tickAmount = Math.round(maxVal / 1000);
                      const options = {
                        ...chartOptions,
                        yaxis: {
                          ...chartOptions.yaxis,
                          min: 0,
                          max: maxVal,
                          tickAmount: 10,
                        },
                        series: [
                          {
                            ...series,
                            tooltip: {
                              enabled: true,
                              formatter: function (val) {
                                return (
                                  val[0].seriesName +
                                  ": " +
                                  val[0].dataPoint.y.toFixed(2)
                                );
                              },
                            },
                          },
                        ],
                        colors: [colors[index % colors.length]],
                        markers: {
                          size: 0,
                        },
                      };

                      return (
                        <div
                          key={index}
                          className="border items-center justify-center xl:h-25rem h-20rem w-full shadow-md col-span-1"
                        >
                          <h2 className="text-center my-4  font-bold text-2xl sm:text-3xl md:text-3xl lg:text-4xl">
                            {series.name
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() +
                                  word.substring(1),
                              )
                              .join(" ")}
                          </h2>
                          <div className="mt-10">
                            <ReactApexChart
                              type="area"
                              options={options}
                              series={options.series}
                              width="95%"
                              height="250px"
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
              {tab === 1 && (
                <>
                  <div className="p-2">
                    <div className="flex flex-col items-center justify-around mt-3 mb-6">
                      <div className="w-5/6 gap-10 grid grid-cols-2">
                        <div className="bg-gray-100 flex flex-col rounded-md shadow-md">
                          <div className="py-3 mt-7 flex items-center justify-center">
                            <h1 className="2xl:text-3xl md:text-2xl font-bold text-center mb-2">
                              Purchase Intent Score
                            </h1>
                            <div className="relative group ml-4 mb-4">
                              <span className=" hover:text-black">
                                <BsQuestionCircle className="" />
                                <div
                                  className="z-50 absolute flex border border-gray-400 top-0 right-0 transform translate-x-full -translate-y-full w-48 h-fit bg-gray-200 text-gray-800 py-2 px-2 rounded opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto"
                                  style={{
                                    lineHeight: "1.5",
                                    letterSpacing: "0.1em",
                                  }}
                                >
                                  <span className="font-bold inline-flex  md:text-sxs">
                                    This feature shows the overall purchase
                                    intent of the campaign products.
                                  </span>
                                </div>
                              </span>
                            </div>
                          </div>
                          <div className="flex flex-row">
                            <GaugeComponent
                              className="2xl:w-24rem md:w-15rem mx-auto text-black rounded-md"
                              type="semicircle"
                              arc={{
                                width: 0.1,
                                gradient: true,
                                colorArray: ["#FF2121", "#00FF15"],
                                padding: 0.02,
                                subArcs: [
                                  {
                                    limit: 0,
                                    color: "#FF0000",
                                    showTick: false,
                                    tooltip: { text: "0%" },
                                  },
                                  {
                                    limit: 16.5,
                                    color: "#FF4000",
                                    showTick: false,
                                    tooltip: { text: "33%" },
                                  },
                                  {
                                    limit: 33.5,
                                    color: "#FFFF00",
                                    showTick: false,
                                    tooltip: { text: "67%" },
                                  },
                                  {
                                    limit: 50,
                                    color: "#00FF00",
                                    showTick: false,
                                    tooltip: { text: "100%" },
                                  },
                                ],
                              }}
                              pointer={{
                                type: "blob",
                                animationDelay: 0,
                                width: 15,
                              }}
                              labels={{
                                valueLabel: {
                                  formatTextValue: (value) => {
                                    if (value <= 10) {
                                      return "Poor";
                                    } else if (value <= 20) {
                                      return "Fair";
                                    } else if (value <= 30) {
                                      return "Good";
                                    } else if (value <= 40) {
                                      return "Great";
                                    } else if (value > 40) {
                                      return "Excellent";
                                    } else {
                                      return "N/A";
                                    }
                                  },

                                  style: {
                                    fontFamily: "Arial, sans-serif",
                                    fontSize: 30,
                                    fill: "black",
                                    fontWeight: "bold",
                                    textShadow: "none",
                                  },
                                },
                                tickLabels: {
                                  type: "outer",
                                  valueConfig: {
                                    formatTextValue: (value) => value + "%",
                                    fontSize: 10,
                                    textColor: "blue",
                                  },
                                  ticks: [],
                                },
                              }}
                              value={
                                purchaseIntentPercentage
                                  ? purchaseIntentPercentage > 50
                                    ? 50
                                    : purchaseIntentPercentage
                                  : 0
                              }
                              minValue={0}
                              maxValue={50}
                            />
                          </div>
                        </div>
                        <div className="bg-gray-100 flex flex-col rounded-md shadow-md">
                          <div className="py-3 mt-7 flex items-center justify-center">
                            <h1 className="2xl:text-3xl md:text-2xl font-bold text-center mb-3 mr-2">
                              Sentiment Analysis
                            </h1>
                            <div className="relative group">
                              <span className="hover:text-black">
                                <BsQuestionCircle className="mb-3 ml-2" />
                                <div
                                  className="absolute z-50 flex border border-gray-400 top-0 right-0 transform translate-x-full -translate-y-full w-48 h-fit bg-gray-200 text-gray-800 py-2 px-2 rounded opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto"
                                  style={{
                                    lineHeight: "1.5",
                                    letterSpacing: "0.1em",
                                  }}
                                >
                                  <span className="font-bold inline-flex md:text-sxs">
                                    This feature shows the percentage of
                                    comments that are positive, negative, or
                                    neutral.
                                  </span>
                                </div>
                              </span>
                            </div>
                          </div>

                          <div className="flex items-center justify-center mt-12 gap-20">
                            <div className="text-center text-green-500">
                              <div className="2xl:text-4xl lg:text-3xl md:text-2xl font-bold">
                                Positive
                              </div>
                              <div className="2xl:text-4xl md:text-base text-green font-bold mt-1">
                                {parseInt(positivePercentage)}%
                              </div>
                            </div>
                            <div className="text-center">
                              <div className="2xl:text-4xl lg:text-3xl md:text-2xl font-bold">
                                Negative
                              </div>
                              <div className="2xl:text-4xl md:text-base text-red font-bold mt-1">
                                {parseInt(negativePercentage)}%
                              </div>
                            </div>
                            <div className="text-center">
                              <div className="2xl:text-4xl lg:text-3xl md:text-2xl font-bold">
                                Neutral
                              </div>
                              <div className="2xl:text-4xl md:text-base font-bold text-gray-400 mt-1">
                                {parseInt(neutralPercentage)}%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="border-1 w-full my-10" />

                    <div className="flex justify-center p-2">
                      <CampaignCommentsAnalytics comments={commentsData} />
                    </div>

                    <div className="border-1 w-full my-10" />

                    <h2>{filteredComments.length} Instagram comments</h2>
                    <div className="overflow-y-auto max-h-80">
                      {campaign && campaign.instagram_comments ? (
                        filteredComments.map((comment) => (
                          <div key={comment.id} className="mb-4">
                            <div className="border border-gray-300 rounded p-2 mt-2 flex justify-between items-center">
                              <div>
                                <p className="font-bold text-base">
                                  {comment.text}
                                </p>
                                <p className="text-xs text-gray-600">
                                  {new Date(comment.timestamp).toLocaleString()}
                                </p>
                              </div>
                              <div className="flex items-center">
                                {comment.sentiment === "positive" && (
                                  <FaThumbsUp
                                    title="Positive comment"
                                    className="text-green ml-1"
                                  />
                                )}
                                {comment.sentiment === "negative" && (
                                  <FaThumbsDown
                                    title="Negative comment"
                                    className="text-red ml-1"
                                  />
                                )}
                                {comment.sentiment === "neutral" && (
                                  <FaMinus
                                    title="Neutral comment"
                                    className="text-gray-500 ml-1 w-5 h-5"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p className="text-gray-500">No comments available</p>
                      )}
                    </div>
                  </div>
                </>
              )}

              {tab === 2 && (
                <div className="h-full">{/* <RetailAnalytics /> */}</div>
              )}
            </>
          ) : (
            <div className="flex items-center justify-center mx-auto mt-20 font-bold 2xl:text-4xl">
              No posts have been made for this campaign yet.
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default CampaignAnalytics;
