import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { FaCheck, FaEdit } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { TaskService, useTaskState } from "../../Service/TaskServices";
import EditTaskModal from "./EditTaskModal";
import WhiteListingInstructions from "./WhiteListingInstructions";
import CustomButton from "../../utils/customButton";
import TaskBriefInfo from "./GenerativeBriefs/TaskBriefInfo";
import { useAuth } from "../../Context/AuthContext";

function UgcInfo(props) {
  const taskState = useTaskState();
  const { currentUser } = useAuth();
  const [whiteListModal, setWhiteListModal] = useState(false);
  const [editTask, setEditTask] = useState(false);
  const [editBrief, setEditBrief] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      TaskService.FetchSingleTask(id);
    }
  }, [taskState.updateNeed.value]); // Make sure 'id' is in the dependency array // Make sur
  if (taskState.task.value) {
  }
  const task = taskState.tasks.value;

  return (
    <div
      className="bg-white w-11/12 p-4 rounded-lg z-100 overflow-auto"
      style={{ height: "90%" }}
    >
      <div className="flex font-extrabold">
        {task && task.brief_template && (
          <CustomButton
            onClick={() => setEditBrief(true)}
            className="ml-10 mb-5 text-white text-xs inline-flex items-center 2xl:px-3 2xl:py-2.5 font-bold md:px-2 md:py-1 lg:px-2 lg:py-1 text-center mr-2"
          >
            <FaEdit className="h-4 w-4 mr-2 font-bold" /> View Brief
          </CustomButton>
        )}
        {task.status !== "completed" && (
          <CustomButton
            onClick={() => setEditTask(true)}
            className="ml-5 mb-5 text-white text-xs inline-flex items-center 2xl:px-3 2xl:py-2.5 font-bold md:px-2 md:py-1 lg:px-2 lg:py-1 text-center mr-2"
          >
            <FaEdit className="h-4 w-4 mr-2 font-bold" /> Edit Task
          </CustomButton>
        )}
        {task.status === "completed" && (
          <div className="ml-10 mb-5 text-white rounded-md bg-Blue hover:bg-red-800 text-xs inline-flex items-center 2xl:px-3 2xl:py-2.5 md:px-2 md:py-1 lg:px-2 lg:py-1 lg:text-center mr-2">
            <FaCheck className="h-4 w-4 mr-2 font-bold " /> Brief Completed
          </div>
        )}
        {task &&
          task.add_ons &&
          task.add_ons.some((addOn) => addOn.name === "Whitelist") && (
            <div className="ml-3 mb-5 text-white font-bold bg-Blue rounded-md hover:bg-red-800 text-xs inline-flex items-center 2xl:px-3 2xl:py-2.5 md:px-2 md:py-1 lg:px-2 lg:py-1 text-center mr-2">
              <CustomButton
                className="font-bold"
                onClick={() => setWhiteListModal(true)}
              >
                Whitelisting Instructions
              </CustomButton>
            </div>
          )}
      </div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="2xl:text-3xl md:2xl:text-base font-semibold ml-10">
          Brief Details
        </h2>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-4 mx-10 h-fit">
        <div className="bg-gray-100 shadow 2xl:p-3 md:p-1 rounded">
          <h3 className="2xl:text-base md:text-0.7 font-semibold mb-1">
            Brief Credits
          </h3>
          <p className="ml-2">
            {task.task_credits === 1
              ? `${task.task_credits} credit`
              : `${task.task_credits} credits`}{" "}
          </p>
        </div>

        <div className="bg-gray-100 shadow 2xl:p-3 md:p-1 rounded">
          <h3 className="2xl:text-base md:text-0.7 font-semibold mb-2">
            Add-ons
          </h3>
          {task && task.add_ons && task.add_ons.length > 0 ? (
            task.add_ons.map((el, i) => (
              <span key={i}>
                {i !== 0 && ", "}
                {el.name}
              </span>
            ))
          ) : (
            <span>none</span>
          )}
        </div>
        {task &&
          task.add_ons &&
          task.add_ons.some((addOn) => addOn.name === "Whitelist") && (
            <div className="bg-gray-100 shadow 2xl:p-3 md:p-1 rounded">
              <h3 className="2xl:text-base md:text-0.7 font-semibold mb-2">
                Whitelisting Platform
              </h3>
              {task.add_ons
                .filter((el) => el.name === "Whitelist")
                .map((el, i) => (
                  <span key={i}>
                    {i !== 0 && ", "}
                    {Array.isArray(el.platform)
                      ? el.platform
                          .map(
                            (platform) =>
                              platform.charAt(0).toUpperCase() +
                              platform.slice(1),
                          )
                          .join(", ")
                      : el.platform.charAt(0).toUpperCase() +
                        el.platform.slice(1)}
                  </span>
                ))}
            </div>
          )}

        <div className="2xl:text-base md:text-0.7 bg-gray-100 shadow 2xl:p-3 md:p-1 rounded">
          <h3 className="font-semibold mb-2">Number of Videos</h3>
          {task.product_showcase}
        </div>

        <div className="2xl:text-base md:text-0.7 bg-gray-100 shadow 2xl:p-3 md:p-1 rounded">
          <h3 className="font-semibold mb-2">Products</h3>
          {task.note1}
        </div>

        {task && task.note2 !== "" && (
          <div className="2xl:text-base md:text-0.7 bg-gray-100 shadow 2xl:p-3 md:p-1 rounded">
            <h3 className="font-semibold mb-2">Task Audience</h3>
            {task.note2}
          </div>
        )}

        <div className="2xl:text-base md:text-0.7 bg-gray-100 shadow 2xl:p-3 md:p-1 rounded">
          <h3 className="font-semibold mb-2">Number of Proposals</h3>
          {task.proposals ? Object.entries(task.proposals).length : 0}
        </div>
        <div className="2xl:text-base md:text-0.7 bg-gray-100 shadow 2xl:p-3 md:p-1 rounded">
          <h3 className="font-semibold mb-2">Number of Accepted Proposals</h3>
          {task.accepted_proposals
            ? Object.entries(task.accepted_proposals).length
            : 0}
        </div>
        {task && task.variations && task.variations.length > 0 && (
          <div className="2xl:text-base md:text-0.7 bg-gray-100 shadow 2xl:p-3 md:p-1 rounded">
            <h3 className="font-semibold mb-2">Versions</h3>
            <div className="flex flex-col">
              {" "}
              {/* Change this line */}
              {task.variations.map((variation, index) => (
                <div key={variation.id}>
                  <p>Version {variation.id + 1}:</p>
                  <div className="flex w-full 2xl:text-base md:text-0.7">
                    {variation.voiceover && (
                      <div>
                        &nbsp;Voiceover
                        {variation.music ||
                        variation.onScreenText ||
                        variation.subtitles
                          ? ", "
                          : ""}
                      </div>
                    )}
                    {variation.music && (
                      <div>
                        &nbsp;Music
                        {variation.onScreenText || variation.subtitles
                          ? ", "
                          : ""}
                      </div>
                    )}
                    {variation.onScreenText && (
                      <div>
                        &nbsp;On-screen text{variation.subtitles ? ", " : ""}
                      </div>
                    )}
                    {variation.subtitles && <div>&nbsp;Subtitles</div>}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {task && task.video_requirements && (
          <div className="2xl:text-base md:text-0.7 bg-gray-100 shadow 2xl:p-3 md:p-1 rounded">
            <h3 className="font-semibold mb-2">Video Requirements</h3>
            <div className="flex flex-row">
              {Object.entries(task.video_requirements).map(
                ([key, value], index, array) =>
                  value && (
                    <div className="flex flex-row ml-1" key={key}>
                      {`${
                        key === "voiceover"
                          ? "Voiceover"
                          : key === "music"
                          ? "Music"
                          : key === "onScreenText"
                          ? "On-screen Text"
                          : key === "subtitles"
                          ? "Subtitles"
                          : null
                      }${index < array.length - 1 ? ", " : ""}`}
                    </div>
                  ),
              )}
            </div>
          </div>
        )}

        <div className="2xl:text-base md:text-0.7 bg-gray-100 shadow 2xl:p-3 md:p-1 rounded">
          <h3 className="font-semibold mb-2">Regions</h3>
          <div className="flex flex-row">
            {task && task.regions && task.regions.length > 0 ? (
              task.regions.map((region, index) => (
                <div key={region.value}>
                  {region.label}
                  {index !== task.regions.length - 1 && ","}
                </div>
              ))
            ) : (
              <span>none</span>
            )}
          </div>
        </div>
      </div>

      {task.note3 && task.note3.trim() !== "" && (
        <div className="2xl:text-base md:text-0.7 bg-gray-100 shadow 2xl:p-3 md:p-1 rounded mx-10">
          <h3 className="font-semibold mb-2">Talking Points</h3>
          <p style={{ overflowWrap: "break-word", overflowX: "hidden" }}>
            {task.note3}
          </p>
        </div>
      )}
      {task.brief_link && task.brief_link.trim() !== "" && (
        <div className="2xl:text-base md:text-0.7 bg-gray-100 shadow 2xl:p-3 md:p-1 rounded mx-10">
          <h3 className="font-semibold mb-2">Brief Link</h3>
          <a
            className="text-Blue hover:underline"
            href={task.brief_link}
            target="_blank"
            style={{ overflowWrap: "break-word", overflowX: "hidden" }}
          >
            {task.brief_link}
          </a>
        </div>
      )}

      {task.invites &&
        currentUser &&
        currentUser.uid === "aOLwKHwgMAbQfJv3QIPXk616Nwp1" && (
          <>
            <div className="flex justify-between items-center mb-4 mt-4">
              <h2 className="2xl:text-3xl md:2xl:text-base font-semibold ml-10">
                Invites Analytics
              </h2>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-4 mx-10 h-fit">
              <div className="2xl:text-base md:text-0.7 bg-gray-100 shadow 2xl:p-3 md:p-1 rounded">
                <h3 className="font-semibold mb-2">Total Invites</h3>
                {task.invites.sent + task.invites.failed}
              </div>
              <div className="2xl:text-base md:text-0.7 bg-gray-100 shadow 2xl:p-3 md:p-1 rounded">
                <h3 className="font-semibold mb-2">Successful Invites</h3>
                {task.invites.sent}
              </div>
              <div className="2xl:text-base md:text-0.7 bg-gray-100 shadow 2xl:p-3 md:p-1 rounded">
                <h3 className="font-semibold mb-2">Failed Invites</h3>
                {task.invites.failed}
              </div>
            </div>
          </>
        )}
      {/*{editTask ? (
            <EditTaskModal
              open={editTask}
              close={() => setEditTask(false)}
              task={task}
              id={id}
            />
           ) : null}*/}

      {whiteListModal ? (
        <WhiteListingInstructions
          open={whiteListModal}
          close={() => setWhiteListModal(false)}
          task={task}
        />
      ) : null}
      {editBrief ? (
        <TaskBriefInfo
          open={editBrief}
          close={() => setEditBrief(false)}
          task={task}
          id={id}
        />
      ) : null}
      {editTask ? (
        <EditTaskModal
          open={editTask}
          close={() => setEditTask(false)}
          task={task}
          id={id}
        />
      ) : null}
    </div>
  );
}

export default UgcInfo;
