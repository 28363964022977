import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';

//State
const state = createState({
  drafts: [],
  draft: [],
  retrieving: false,
  fetched: false,
  loading: false,
  updateNeed: true
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'BRAND_DRAFTS_LOADED':
        return s.merge({
          drafts: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
          fetched: true
        });

      case 'CREATOR_DRAFTS_LOADED':
        return s.merge({
          drafts: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
          fetched: true
        });

        case 'PENDING_BRAND_DRAFTS_LOADED':
          return s.merge({
            drafts: action.data,
            updateNeed: false,
            loading: false,
            retrieving: true,
            fetched: true
          });

          case 'PENDING_DRAFTS_LOADED':
            return s.merge({
              drafts: action.data,
              updateNeed: false,
              loading: false,
              retrieving: true,
              fetched: true
            });
  

      case 'ALL_DRAFTS_LOADED':
        return s.merge({
          drafts: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
          fetched: true
        });
      case 'CREATE_DRAFT':
        return s.merge({
          draft: action.data,
          loading: false,
          updateNeed: true
        });
      case 'CREATE_DRAFT_REVISION':
        return s.merge({
          draft: action.data,
          loading: false,
          updateNeed: true
        });
      case 'DRAFT_UPDATE':
        return s.merge({
          updateNeed: true,
          loading: false
        });
      case 'DRAFT_DELETE':
        return s.merge({
          loading: false,
          updateNeed: true
        });
      case 'DRAFT_LOADED':
        return s.merge({
          draft: action.data,
          updateNeed: false,
          loading: false,
          fetched: true
        });
      case 'TASK_DRAFTS_LOADED':
        return s.merge({
          drafts: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
          fetched: true,
        })
      default:
        return null;
    }
  }, action.type);
});

export const accessDraftState = () => state;

export const useDraftState = () => useState(state);
//Service
export const DraftService = {
  FetchDraftsForBrand: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/drafts/brands/${id}`)
      .then((res) => {
        dispatch(DraftAction.loadBrandDrafts(res.data.result));
      })
      .catch((err) => {});
  },

  FetchPendingDraftsForBrand: (id) =>{
    const dispatch = useDispatch();
    axios
    .get(`${baseUrl}api/drafts/${id}/pending`)
    .then((res) => {
      dispatch(DraftAction.pendingBrandDrafts(res.data.result));

    })
    .catch((err) => {});
  },
  FetchPendingDrafts: (id) =>{
    const dispatch = useDispatch();
    axios
    .get(`${baseUrl}api/pending/drafts/`)
    .then((res) => {
      dispatch(DraftAction.pendingDrafts(res.data.result));

    })
    .catch((err) => {});
  },  

  FetchDraftsForTask: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/drafts/tasks/${id}`)
      .then((res) => {
        dispatch(DraftAction.loadTaskDrafts(res.data.result));
      })
      .catch((err) => {});
  },

  FetchDraftsForCreator: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/drafts/creators/${id}`)
      .then((res) => {
        dispatch(DraftAction.loadCreatorDrafts(res.data.result));
      })
      .catch((err) => {});
  },
  FetchDrafts: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/drafts`)
      .then((res) => {
        dispatch(DraftAction.loadDrafts(res.data.result));
      })
      .catch((err) => {});
  },
  AddDraft: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/drafts/createDraft`, data)
      .then((res) => {
        dispatch(DraftAction.createDraft(res));
      })
      .catch((err) => {});
  },
  
  UpdateDraft: (id, data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/drafts/updateDraft/${id}`, data)
      .then((res) => {
        dispatch(DraftAction.updateDraft(res));
      })
      .catch((err) => {});
  },
  DeleteDraft: (id, data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/drafts/deleteDraft/${id}`, data)
      .then((res) => {
        dispatch(DraftAction.deleteDraft(res));
      })
      .catch((err) => {});
  },
  FetchSingleDraft: (id) => {
    const dispatch = useDispatch();

    axios
      .get(`${baseUrl}api/drafts/${id}`)
      .then((res) => {
        dispatch(DraftAction.loadSingleDraft(res.data.result));
      })
      .catch((err) => {});
  }
};

//Action
export const DraftAction = {
  loadBrandDrafts: (data) => {
    return {
      type: 'BRAND_DRAFTS_LOADED',
      data: data
    };
  },

  pendingDrafts: (data) => {
    return {
      type: 'PENDING_DRAFTS_LOADED',
      data: data
    };
  },

  pendingBrandDrafts: (data) => {
    return {
      type: 'PENDING_BRAND_DRAFTS_LOADED',
      data: data
    };
  },

  loadCreatorDrafts: (data) => {
    return {
      type: 'CREATOR_DRAFTS_LOADED',
      data: data
    };
  },

  loadTaskDrafts: (data) => {
    return {
      type: 'TASK_DRAFTS_LOADED',
      data: data
    };
  },

  loadDrafts: (data) => {
    return {
      type: 'ALL_DRAFTS_LOADED',
      data: data
    };
  },
  loadSingleDraft: (data) => {
    return {
      type: 'DRAFT_LOADED',
      data: data
    };
  },
  createDraft: (data) => {
    return {
      type: 'CREATE_DRAFT',
      data: data
    };
  },
  createRevisionDraft: (data) => {
    return {
      type: 'CREATE_DRAFT_REVISION',
      data: data
    };
  },
  updateDraft: (data) => {
    return {
      type: 'DRAFT_UPDATE',
      data: data
    };
  },
  deleteDraft: (data) => {
    return {
      type: 'DRAFT_DELETE',
      data: data
    };
  }
};
