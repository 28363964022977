import React, { useState } from 'react'

export default function useSubmitStatus() {

  const [retrySubmit, setRetrySubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  return {
    retrySubmit,
    setRetrySubmit,
    isSubmitting,
    setIsSubmitting
  }
}
