import React, {useEffect, useState} from 'react'
import { BsArrowLeft } from 'react-icons/bs';
import moment from 'moment';
import { TaskService, useTaskState } from '../../../Service/TaskServices';
import { UsersService, useUserState } from '../../../Service/userServices';
const PaymentHistory = ({ onClose, UserData }) => {
  const userState = useUserState();
  console.log("userdata: ", UserData);
  useEffect(() => {
    UsersService.FetchUserInfo(UserData.key);
  }, []);
  let user = userState.user.value;
  console.log("user: ", user)
  let completedCreatorTasks = {};
  let completedInfluencerTasks = {};
  
  if (user && user.creator_tasks) {
    completedCreatorTasks = Object.fromEntries(
      Object.entries(user.creator_tasks).filter(([key, task]) => task.status === 'completed')
    );
  }

  if (user && user.influencer_tasks) {
    completedInfluencerTasks = Object.fromEntries(
      Object.entries(user.influencer_tasks).filter(([key, task]) => task.status === 'completed')
    );
  }
  
  console.log("infl tasks: ", completedInfluencerTasks);
  
  const completedTasks = { ...completedCreatorTasks, ...completedInfluencerTasks };
  console.log("completed tasks:", completedTasks);
  let completedTasksArray = Object.values(completedTasks);
  if (Array.isArray(completedTasksArray)) {
    completedTasksArray.sort((a, b) => {
      if (a.payment_date && !b.payment_date) return -1; // a has a payment date, b doesn't
      if (!a.payment_date && b.payment_date) return 1; // b has a payment date, a doesn't
      return new Date(b.payment_date) - new Date(a.payment_date); // both have payment dates or neither has a payment date
    });
  }

  return (
  <div className='relative bg-white h-full overflow-y-scroll w-full  rounded-lg z-50'>
    <div className='sticky top-0 bg-white w-full p-4 rounded-t-lg z-50' style={{backgroundColor: '#ffffff'}}>
        <button className = 'flex flex-row right-0 top-0 mb-10 font-bold text-base' onClick={onClose}><BsArrowLeft className='mx-3 mt-0.5'/>Back </button>
        <h1 className='ml-3 mt-3 font-bold'>Payment History</h1>
        <h1 className='ml-3'> Creator: {user && user.shipping_details && user.shipping_details.fullname}</h1>
    </div>
    <div className="bg-white  m-4 rounded-lg w-full p-6 border-gray-300 border ">
    {completedTasksArray.map((task, index) => {
        const payment = task.payment_amount 
          ? `$${(task.payment_amount / 100).toFixed(2)}` 
          : `$${(task.price / 100).toFixed(2)}`;

        return (
          <div key={index} className='mt-4 rounded-md block font-thin border-gray-200 border-b-2'>
          <div className='flex justify-between'>
              <div className='flex flex-col'>
                  <h1 className='font-bold md:text-3xl ml-10'>{task.brand_name}</h1>
                  <div className='ml-10 text-xs'>{task.campaign_id ? `Influencer Brief` : 'UGC Brief'}</div>
              </div>
              <div className='flex flex-col items-end mr-7 w-48'>
                  <h1 className='font-bold md:text-3xl text-right ml-10'>
                      {task.payment_amount
                          ? `$${(task.payment_amount / 100).toFixed(2)}` 
                          : `$${(task.price / 100).toFixed(2)}`
                      }
                  </h1>
                  <div className='w-24 text-right'>
                      {task.payment_date && (
                          <h1 className='md:text-3xl text-xs'>{moment(task.payment_date).format('MM/DD/YYYY')}</h1>
                      )}
                  </div>
              </div>
          </div>
      </div>
        );
      })}
      </div>
    
    </div>
  )
}

export default PaymentHistory