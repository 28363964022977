import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Layout from '../../Layout/Dashboard/Admin';
import { useAuth } from '../../Context/AuthContext';

function AdminDashBoardRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();
  console.log(currentUser);

  return (
    <Route
      {...rest}
      render={(props) => {
        return currentUser && currentUser.uid === 'aOLwKHwgMAbQfJv3QIPXk616Nwp1' ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
}

export default AdminDashBoardRoute;