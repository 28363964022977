import React, { useRef, useState, useEffect } from 'react';
import { useAuth } from './../../Context/AuthContext';
import { ImCross } from 'react-icons/im';
import S3FileUpload from 'react-s3';
import toast, { Toaster } from 'react-hot-toast';
import s3 from './../../aws-s3';
import Loader2 from '../Loader2';
import Loader from '../Loader';
import { useHistory } from 'react-router-dom';
import { useUserState , UsersService} from '../../Service/userServices';



import { AuthService, userAuthstate } from '../../Service/authenticationService';
function Profile(props) {
  const userState = useUserState();
  const history = useHistory();
  const name = useRef('');
  const bio = useRef('');
  const fileRef = useRef(null);
  const [setImgLocation] = useState('');
  const { currentUser } = useAuth();
  const [loader, setLoader] = useState(false);
  const [imgUploaded, setImgUpload] = useState(false);
  const [userData, setUserData] = useState({
    name: '',
    avatar: '',
    bio: ''
  });


  useEffect(() => {
    let i = 0;
    const checkAdmin = async () => {
      if ((userState.user.value && userState.user.value.admin === true) || i === 0) {
        i++
        console.log("User is an admin");
      } else {
        console.log("User is not an admin. Redirecting...");
        history.push('/');
      }
    };
  
    if (userState.user.value) {
      checkAdmin();
    }
  }, [userState.user.value, history]);



  const [error, setError] = useState({
    avatar: '',
    name: '',
    bio: '',
    companyName: '',
    companyOneLine: '',
    companyLocation: '',
    companyType: '',
    companyLinks: ''
  });
  const authState = userAuthstate();
  let update = {
    avatar: '',
    name: '',
    bio: ''
  };

  const [companyProfileData, setCompanyProfileData] = useState({
    companyName: '',
    companyOneLine: '',
    companyProfileData: '',
    companyCountry: '',
    companyLocation: '',
    companyType: '',
    companyLinks: ''
  });

  const onChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: '' });
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImgUpload(true);
    } else {
      setImgUpload(false);
    }
  };

  useEffect(() => {
    if (currentUser.uid !== null) {
      AuthService.FetchAuthUser(currentUser.uid);
    }
  }, [authState.updateNeed.value]);

  const user = authState.authUser.value;

  useEffect(() => {
    if (user !== null) {
      setUserData({
        name: user.name !== undefined ? user.name : '',
        bio: user.bio !== undefined ? user.bio : '',
        avatar: user.avatar !== undefined ? user.avatar : ''
      });
    }
  }, [authState.loading.value]);

  async function handleSubmit(e) {
    e.preventDefault();
    const userdata = userData;
    const avatarInput = user ? user.avatar : fileRef.current.files[0];
    if (!avatarInput) {
      return setError({ ...error, avatar: 'avatar is required' });
    }

    try {
      setError({ avatar: '', name: '', bio: '' });
      setLoader(true);
      if (imgUploaded) {
        await S3FileUpload.uploadFile(fileRef.current.files[0], s3).then((data) => {
          setImgLocation(data.location.toString());
          update = {
            bio: bio.current.value,
            name: name.current.value,
            avatar: data.location
          };
        });
      } else {
        update = {
          bio: bio.current.value,
          name: name.current.value,
          avatar: ''
        };
      }
      userdata.name = update.name !== '' ? update.name : userdata.name;
      userdata.bio = update.bio !== '' ? update.bio : userdata.bio;
      userdata.avatar = update.avatar !== '' ? update.avatar : userdata.avatar;

      if (userdata.name === '') return setError({ ...error, name: 'name is required' });
      if (userdata.bio === '') return setError({ ...error, bio: 'bio is required' });
      await AuthService.UpdateAuthUser(currentUser.uid, userdata);
      toast.success('Updated Successful');
      setLoader(false);
    } catch (error) {
      setError('Failed to Update Profile');
    }
  }
  return (
    <div className="bg-white m-4 z-0 overflow-scroll rounded-lg w-full p-6">
      <h2 className="font-semibold py-2 text-2xl">My Profile</h2>
      <Toaster />
      <>
        {authState.loading.value ? (
          <Loader2 />
        ) : (
          <div>
            <>
              <div className="mb-3">
                <label className="text-darkBlack font-bold capitalize">Profile Picture</label>
                <br></br>
                {userData.avatar === '' && (
                  <input
                    id="imageInput"
                    type="file"
                    ref={fileRef}
                    className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
                    onChange={handleChange}
                  />
                )}
                {userData.avatar !== '' && (
                  <div className="w-32 relative mt-4">
                    <img src={userData.avatar} alt="" className="object-cover " />
                    <div
                      className="absolute -top-2 -right-2 cursor-pointer"
                      onClick={() => setUserData({ ...userData, avatar: '' })}>
                      <ImCross className="text-red" />
                    </div>
                  </div>
                )}
                {error && error.avatar && <span className="text-sm text-Red">{error.avatar}</span>}
              </div>
            </>

            <div className="mb-3">
              <label className="text-darkBlack font-bold capitalize">Name</label>
              <input
                className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
                type="text"
                name="name"
                value={userData.name}
                onChange={onChange}
                ref={name}
              />
              {error && error.name && <span className="text-sm text-Red">{error.name}</span>}
            </div>
            <div className="mb-3">
              <label className="text-darkBlack font-bold capitalize">Bio</label>
              <textarea
                className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
                rows="10"
                cols="50"
                value={userData.bio}
                name="bio"
                onChange={onChange}
                ref={bio}
              />
              {error && error.bio && <span className="text-sm text-Red">{error.bio}</span>}
            </div>
            <h1 className="font-bold mb-3 font-black xs:text-lg md:text-4xl">Company Profile</h1>
            <hr />
            <div className="mb-4">
              <label className="text-black block md:text-lg mb-2 font-extrabold">
                Company Name
              </label>
              <label className="block">
                <input
                  type="text"
                  name="company profile"
                  value={companyProfileData.companyName}
                  onChange={(e) =>
                    setCompanyProfileData({
                      ...companyProfileData,
                      companyName: e.target.value
                    })
                  }
                  className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                />
              </label>
              {error && error.companyName && (
                <span className="text-sm text-Red">{error.companyName}</span>
              )}
              <br></br>
              <label className="text-black block md:text-lg mb-2 font-extrabold">
                Company 1-liner
              </label>
              <label className="block">
                <input
                  type="text"
                  name="company 1-liner"
                  value={companyProfileData.companyOneLine}
                  onChange={(e) =>
                    setCompanyProfileData({
                      ...companyProfileData,
                      companyOneLine: e.target.value
                    })
                  }
                  className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                />
              </label>
              {error && error.companyOneLine && (
                <span className="text-sm text-Red">{error.companyOneLine}</span>
              )}
              <br />
              <>
                <label className="text-black block md:text-lg mb-2 font-bold">
                  Company Location
                </label>
                <label className="block">
                  <div className="bg-gray-100 p-3 rounded-lg w-full">
                    <input
                      type="text"
                      name="company location"
                      value={companyProfileData.companyLocation}
                      onChange={(e) =>
                        setCompanyProfileData({
                          ...companyProfileData,
                          companyLocation: e.target.value
                        })
                      }
                      className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                    />
                  </div>
                </label>
                {error && error.companyLocation && (
                  <span className="text-sm text-Red">{error.companyLocation}</span>
                )}
              </>
              <br />
              {error && error.companyType && (
                <span className="text-sm text-Red">{error.companyType}</span>
              )}
              <br></br>
              <label className="text-black block md:text-lg mb-2 font-bold">Company Links</label>
              <label className="block">
                <input
                  type="text"
                  name="company Links"
                  value={companyProfileData.companyLinks}
                  onChange={(e) =>
                    setCompanyProfileData({
                      ...companyProfileData,
                      companyLinks: e.target.value
                    })
                  }
                  className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                />
              </label>
              {error && error.companyLinks && (
                <span className="text-sm text-Red">{error.companyLinks}</span>
              )}
            </div>
            <button
              className=" mt-2 mx-auto bg-Red rounded text-white py-2 px-14 block"
              type="button"
              disabled={loader}
              onClick={(e) => handleSubmit(e)}>
              {loader && <Loader />}
              Update Profile
            </button>
          </div>
        )}
      </>
    </div>
  );
}

export default Profile;
