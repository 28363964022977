import React, { useState, useEffect, useContext } from 'react';
import logo from '../../Assets/updated crafted logo.png';
import { useAuth } from '../../Context/AuthContext';
import noAvatar from '../../Assets/profilePicture.png';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { BiMenuAltRight } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import ProfileDropDown from '../ProfileDropDown';
import AuthModal from '../AuthModal';
import UpdateProfileModal from '../UpdateProfileModal';
import { AuthService, userAuthstate } from '../../Service/authenticationService';
import { BrandService, useBrandState } from '../../Service/BrandServices';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';
import { useAccountState, AccountService } from '../../Service/AccountServices';
import CustomButton from '../../utils/customButton';

function OrganizationNavbar() {
  const [ OpenProfileModal, setOpenProfileModal] = useState(false);
  const [OpenDropModal, setOpenDropModal] = useState(false);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [link, setLink] = useState("/brands/dashboard")
  const { currentBrand, currentUser, logout } = useAuth();
  const {brandId} = useContext(BrandContext);
  const accountState = useAccountState();
  const authState = userAuthstate();
  const brandState = useBrandState();
  const user = authState.authUser.value;

  const brandInfo = brandState.account.value;
  const avatar = user ? user.avatar : null;

  useEffect(() => {
    if (account) {
    AccountService.FetchAccountInfo(currentUser && currentUser.uid);
    } 
  }, [accountState.updateNeed.value]);

  console.log("account info:", brandInfo);
  const account = accountState.account.value;
  async function handleLogout() {
   localStorage.removeItem('brandId');
    logout()
      .then(() => {
        setNavbarOpen(false);
      })
      .catch((error) => {});
  }

  function handleOpenProfile() {
    if (!OpenProfileModal) {
      setOpenProfileModal(true);
    } else {
      setOpenProfileModal(false);
    }
  }

  console.log("account:", account);
  return (
    <div>
    <nav className="bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-800 z-100">
      <div className="flex flex-wrap justify-between items-center">
        <Link to={Link} className="flex">
        <img src={logo} loading="eager" className="2xl:w-7rem md:w-4rem xs:w-10rem " alt="" />
        </Link>
        <div className="flex items-center md:order-2">
          <div className="hidden sm:flex sm:ml-6">
            {account && (
              <div className="flex space-x-4 items-center">
                <div className="mr-2 bg-gray-600 px-4 py-1 text-white rounded-lg font-extrabold">
                  ORGANIZATION
                </div>
                <div
                  className="flex items-center bg-red-plight py-1 px-2 rounded-full cursor-pointer relative"
                  onClick={() => setOpenDropModal(!OpenDropModal)}
                >
                  <img
                    className="h-8 w-8 rounded-full object-cover"
                    src={account && account.avatar ? account && account.avatar : noAvatar}
                    alt="true"
                  />
                  <RiArrowDropDownLine className="text-red" />
                  {account && (
                    <div className={OpenDropModal ? "origin-top-right" : "hidden"}>
                      <ProfileDropDown account={account} />
                    </div>
                  )}
                </div>
              </div>
            )}
        
          </div>
        </div>
      <div
        className={
          navbarOpen
            ? 'z-50 my-4 text-base list-none bg-white absolute right-3 top-14 rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600'
            : 'hidden z-50 my-4 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600'
        }
        id="dropdown"
      >
        <ul className="py-1" aria-labelledby="dropdown">
          <li>
            <button
              className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
              onClick={() => handleOpenProfile()}
            >
              Update Profile
            </button>
          </li>
          <li>
            <CustomButton
              className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
              onClick={(e) => handleLogout(e)}
            >
              Logout
            </CustomButton>
          </li>
        </ul>
      </div>
      {currentUser ? (
        <button
          data-collapse-toggle="mobile-menu-2"
          type="button"
          className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="mobile-menu-2"
          aria-expanded="false"
          onClick={() => setNavbarOpen(!navbarOpen)}
        >
          <span className="sr-only">Open main menu</span>
          <BiMenuAltRight className=" text-4xl text-black outline-none" />
        </button>
      ) : (
        <div></div>
      )}
    </div>
  </nav>
  {openAuthModal && (
    <AuthModal isOpen={openAuthModal} closeAction={() => setOpenAuthModal(false)}></AuthModal>
  )}

  {OpenProfileModal && (
    <UpdateProfileModal
      closeAction={() => {
        setOpenProfileModal(false);
      }}
    />
  )}
</div>

  );
}

export default OrganizationNavbar;
