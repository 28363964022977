import React, { useState, useEffect } from 'react'
import { TaskService, useTaskState } from '../../Service/TaskServices';
import { useAuth } from '../../Context/AuthContext';
import { EmailService, useEmailState } from '../../Service/EmailService';
import GlobalDialog from '../../utils/globalDialog'
import tiktokInstructions1 from '../../Assets/tiktokInstructions1.png'
import tiktokInstructions2 from '../../Assets/tiktokInstructions2.png'
import tiktokInstructions3 from '../../Assets/tiktokInstructions3.png'
import toast, { Toaster } from 'react-hot-toast';
function SubmitTiktokCode(props) {
  const [tiktokCode, setTiktokCode] = useState('');
  const taskState = useTaskState();
  const { currentUser } = useAuth();
  const emailState = useEmailState();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (tiktokCode.length !== 64) {
      alert('Ad authorization code must be 64 characters long');
      return;
    }
    try{
      let data = {
        tiktok_code: tiktokCode,
        task_id: props.task.id,
        creator_id: currentUser.uid
      }  
      TaskService.SendTiktokCode(props.task.id, data);
      let emailData = {
        task_id: props.task.id,
        brand_id: props.task.brand_id,
        creator_id: currentUser.uid,
        tiktok_code: tiktokCode
      }
      EmailService.sendTiktokCodeEmailBrand(emailData);
        setTimeout(() => {
        toast.success('Tiktok Code Sent!');
        window.location.reload();
      },2000);
    } catch(error) {
      console.log(error);
      toast.error('Error sending tiktok code');
    }
  }
  return (
<GlobalDialog className="h-auto sm:h-auto max-w-4xl" open={props.open} onClose={props.close}>
  <Toaster/>
  <h3 className='border-b-2 w-full my-4 text-center font-bold text-lg md:text-xl lg:text-2xl'>Submit TikTok Ad Code</h3>
  <p className="text-center mx-2 2xl:text-base text-xs ">For TikTok whitelisting, you must upload the approved video to TikTok, tap the three dots on your post, and tap on "ad settings". Select "Ad Authorization" and set the authorization period to "60 days". Then you must generate an ad code and paste it here.</p>
   <form onSubmit={handleSubmit}>
    <div className='flex flex-col items-center justify-center mx-auto md:w-1/2'> 
      <div className="flex md:flex-row md:w-fit xs:w-fit xs:flex-col justify-center">
      
    
      <img
        src={tiktokInstructions1}
        alt="tiktok_instructions"
        className="w-fit sm:w-1/2 mx-auto object-cover m-2"
        style={{ height: '85%' }} // Set the same height for consistency
      />
      <img
        src={tiktokInstructions2}
        alt="tiktok_instructions"
        className="w-fit sm:w-1/2 mx-auto object-cover m-2"
        style={{ height: '85%' }} // Set the same height for all images
      />  
      <img
        src={tiktokInstructions3}
        alt="tiktok_instructions"
        className="w-fit sm:w-1/2 mx-auto object-cover m-2"
        style={{ height: '85%' }} // Set a specific height for all images
      />
    </div>
      <input
        type="text"
        value={tiktokCode}
        onChange={(e) => setTiktokCode(e.target.value)}
        placeholder="Enter TikTok Ad Code"
        maxLength={64}
        minLength={64}
        required
        className="w-full h-12 border mx-2 p-2 my-2 rounded"
      />
    </div>
        <button type="submit" className="bg-red text-white flex items-center justify-center mx-auto font-bold py-2 px-4 rounded my-4">
          Submit Code
        </button>
    </form>
</GlobalDialog>
  )
}

export default SubmitTiktokCode