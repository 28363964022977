import React, { useState, useEffect, useContext } from 'react';
import NewProductModal from './NewProductModal';
import ProductCard from './ProductCard';
import { ProductService, useProductState } from '../../Service/ProductServices';
import { useAuth } from '../../Context/AuthContext';
import toast, { Toaster } from 'react-hot-toast';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';
import CustomButton from '../../utils/customButton';


function Products() {
  const productState = useProductState();
  const [setOpenProfileModal] = useState(false);
  const [addproduct, setAddProduct] = useState(false);
  const { currentUser } = useAuth();
  const { brandId } = useContext(BrandContext);
  console.log("brandId: " + brandId);
  useEffect(() => {
    if (brandId) {
      ProductService.FetchProductForUser(brandId);
      console.log("Fetching products for user: " + brandId);
    }
  }, [productState.updateNeed.value, brandId]);

  const res = productState.products.value;

  const product = res
    ? Object.entries(res).map((e) => ({
        [e[0]]: e[1]
      }))
    : [];
  return (
    <div className="bg-white m-4 rounded-lg lg:w-full xs:h-fit"  style={{ height: 'calc(95vh - 5rem)'}}>
     
      <div className="flex items-center h-16 justify-between">
        <h3 className="font-semibold p-8 pl-2 ml-5 2xl:text-2xl md:text-base xs:text-base">My Products</h3>

        <CustomButton
          className=" text-white lg:px-4 py-2 xs:px-2  mr-8 xs:text-sm"
          onClick={() => {
            setAddProduct(true);
          }}>
          + Add Product
        </CustomButton>
      </div> 
      <div className='p-4'>
      {!productState.loading.value && product.length === 0 && (
        <div className='w-full'>
          <div className="text-center font-bold mx-auto mt-6 mb-4 text-2xl">
            You currently have no products, when you do they will appear here. Add products before creating a brief to let creators know which ones to submit video proposals for and to generate sample product requests.
          </div>
          <div className='mt-6 text-center'>
            <CustomButton
                className=" font-bold px-2 py-2 my-1 text-white text-lg w-32 items-left justify-left text-center cursor-pointer"
                onClick={() => {
                  setAddProduct(true);
                }}>
                Add Your First Product
            </CustomButton>
          </div>
        </div>
      )}
      <div className="grid lg:grid-cols-4 grid-cols-1 overflow-y-scroll h-41 md:grid-cols-2 gap-2 mx-2" style={{ height: 'calc(80vh - 5rem)'}}>
        {product.length > 0 &&
          product.map((item, i) => {
            const res = item[Object.keys(item)];
            const id = Object.keys(item);
            return (
              <div key={i}>
                <ProductCard product={res} id={id} />
              </div>
            );
          })}
      </div>

      <NewProductModal
        open={addproduct}
        onClose={() => setAddProduct(false)}
        closeAction={() => {
          setOpenProfileModal(false);
        }}
      />
      </div>
    </div>
  );
}

export default Products;
