import React, { useState } from 'react';
import ReactStars from "react-rating-stars-component";
import { ReviewService, useReviewState } from '../../Service/ReviewService';
import CustomGlobalDialog from '../Brand_Dashboard/CustomGlobalDialog';
function TrialReviewInfo(props) {
  const [professionalismScore, setProfessionalismScore] = useState(props.review.professionalism_score);
  const [qualityScore, setQualityScore] = useState(props.review.quality_score);
  const [performanceScore, setPerformanceScore] = useState(props.review.performance_score);
  const [review, setReview] = useState(props.review.review);
  const reviewState = useReviewState();
  return (
    <CustomGlobalDialog className="2xl:w-2/3 shadow-lg" open={props.open} onClose={props.onClose}> 
    <h1 className="text-2xl font-bold mb-4 text-center">Review by {props.review && props.review.brand_name ? props.review.brand_name : "the creator"}</h1>
    <div className='border-b w-full'></div>
    <div className='md:grid md:grid-cols-3 xs:flex xs:flex-col xs:items-center justify-items-center align-items-center'>
      <div className='col-span-1 w-fit justify-items-center'>   
        <video className="w-fit max-w-xs h-96 xs:flex xs:items-center xs:justify-center object-contain shadow-inner mx-auto mt-1 mb-4" src={props.review.video} controls></video> 
        <div className='flex items-center justify-center mb-0 xs:mb-3 mx-auto'>
          {/*<img src={props.review.creator_avatar} alt="Profile" className="w-3rem h-3rem rounded-full mr-2 object-cover" />
          <h2 className="2xl:text-2xl xl:text-xs md:text-0.7 font-bold text-center ml-2">{props.review.creator_name}</h2>*/}
        </div>
      </div>  
      <div className="col-span-2 p-2">
        <form className="space-y-4">
        <div className="space-y-4">
              <div className="flex flex-col space-y-4">
                <div className="flex flex-col items-center justify-center">
                  <label className="font-bold text-2xl mb-1">Timeliness Score:</label>
                  <p className="text-xs mb-2">This score represents how satisfied the client was with timeliness on video draft submissions and revisions.</p>
                  <ReactStars 
                    count={5}
                    value={professionalismScore}
                    size={40}
                    edit={false}
                    activeColor="#FFD700"
                  />
                </div>
                <div className="flex flex-col items-center justify-center">
                  <label className="font-bold text-2xl mb-1">Quality Score:</label>
                  <p className="text-xs mb-2">This score represents the quality of the work delivered. How well the creator followed the brief. Includes camera quality & lighting, video concept, creativity and execution.</p>
                  <ReactStars 
                    count={5}
                    value={qualityScore}
                    size={40}
                    edit={false}
                    activeColor="#FFD700"
                  />
                </div>
                <div className="flex flex-col items-center justify-center">
                  <label className="font-bold text-2xl mb-1">Performance Score:</label>
                  <p className="text-xs mb-2">This score represents the performance of the video once the post was made. Did viewership & engagement meet expectations.</p>
                  <ReactStars 
                    count={5}
                    value={performanceScore}
                    size={40}
                    edit={false}
                    activeColor="#FFD700"
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label className="font-bold mb-1">Detailed Review:</label>
                <p className="border p-2 rounded-md h-20">{review}</p>
              </div>
            </div>
        </form>
      </div>
    </div>
</CustomGlobalDialog>
  );
}

export default TrialReviewInfo;