import React, { useState, useEffect, useContext } from 'react';
import { BsSearch } from 'react-icons/bs';
import { BsFillPersonPlusFill } from 'react-icons/bs';
import UserFormModal from '../Admin/Users/UserFormModal';
import UpdateModal from '../Admin/Users/UserUpdateModal';
import { UsersService, useUserState } from '../../Service/userServices';
import { GrFormPrevious } from 'react-icons/gr';
import { MdOutlineNavigateNext } from 'react-icons/md';
import UsersDeleteModal from '../Admin/Users/UsersDeleteModal';
import { useRef } from 'react';
import { FaEdit } from "react-icons/fa";
import UpdateUserForm from '../Admin/Users/UserUpdateModal';
import NewOrganizationModal from './NewOrganizationModal';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';
import { BrandService, useBrandState } from '../../Service/BrandServices';
import { Link, useHistory } from 'react-router-dom';
import Loader from '../Loader2';
import { useAuth } from '../../Context/AuthContext';
import InviteBrandModal from './InviteBrandModal';
import AddBrandModal from './AddBrandModal';
import CustomButton from '../../utils/customButton';

function BrandUsers() {
  const [OpenProfileModal, setOpenProfileModal] = useState(false);
  const [OpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [OpenAddUserModal, setOpenAddUserModal] = useState(false);
  const [selectedId, SetSelectedId] = useState('');
  const userState = useUserState();
  const { brandId } = useContext(BrandContext);
  const brandState = useBrandState();
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const [userData, setUserData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [display, setDisplay] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const modalRef = useRef();
  const [modal, setModal] = useState(false);
  const [isOpenOrganization, setIsOpenOrganization] = useState(false);
  const [orgId, setOrgId] = useState(null);
  const history = useHistory();
  const {currentUser} = useAuth();
  useEffect(() => {
    setLoading(true);
    if (brandId) {
      BrandService.FetchSingleBrand(brandId)
        .then((brand) => {
          if (brand && brand.org_id) {
            setOrgId(brand.org_id);
          }
          if (brand && brand.users){
            setUserData(Object.values(brand.users) || []); // Initialize as an empty array if null or undefined
          }
        })
        .then(() => setLoading(false))
        .catch((error) => {
          // Handle fetch error
        });
    }
  }, [brandId]);
  
  const brand = brandState.brand.value;
  console.log("brand state", brandState.brand.value);
  console.log("brand organization: ", brand && brand.organization_owned);
  console.log("brand organization: ", brand && brand.organization_owned);
  console.log("brand users: ", brand && brand.users);
  const dummyUserData = [
    { email: 'user1@example.com', role: 'Admin' },
    { email: 'user2@example.com', role: 'User' },
    { email: 'user3@example.com', role: 'User' },
    { email: 'user4@example.com', role: 'Admin' },
    { email: 'user5@example.com', role: 'User' },
    { email: 'user6@example.com', role: 'User' },
    { email: 'user7@example.com', role: 'Admin' },
    // Add more dummy user data as needed
  ];

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };


  const inviteHandler = () => {
    setOpenInviteModal(true)
  }

   {/** const handleSearch = (e) => {
      const value = e.target.value;
      setSearchTerm(value);
    
      const results = arr.filter((user) => {
        const { bio, email, profile_name, username, name, shipping_details, fullname, creator_socials, negotiated }= user;
        const { address1, address2, city, country, state, zipcode } =
          shipping_details || {};
    
        const lowerCaseValue = value.toLowerCase(); // Convert search value to lowercase
    
        // Search by bio, email, profile name, username, name, and shipping details (case-insensitive)
        return (
          (bio && bio.toLowerCase().includes(lowerCaseValue)) ||
          (email && email.toLowerCase().includes(lowerCaseValue)) ||
          (profile_name && profile_name.toLowerCase().includes(lowerCaseValue)) ||
          (username && username.toLowerCase().includes(lowerCaseValue)) ||
          (name && name.toLowerCase().includes(lowerCaseValue)) ||
          (fullname && fullname.toLowerCase().includes(lowerCaseValue)) ||
          (address1 && address1.toLowerCase().includes(lowerCaseValue)) ||
          (address2 && address2.toLowerCase().includes(lowerCaseValue)) ||
          (city && city.toLowerCase().includes(lowerCaseValue)) ||
          (country && country.toLowerCase().includes(lowerCaseValue)) ||
          (state && state.toLowerCase().includes(lowerCaseValue)) ||
          (creator_socials && (
            (creator_socials.tiktok && Object.values(creator_socials.tiktok).some((value) => typeof value === 'string' && value.toLowerCase().includes(lowerCaseValue))) ||
            (creator_socials.instagram && Object.values(creator_socials.instagram).some((value) => typeof value === 'string' && value.toLowerCase().includes(lowerCaseValue))) ||
            (creator_socials.youtube && Object.values(creator_socials.youtube).some((value) => typeof value === 'string' && value.toLowerCase().includes(lowerCaseValue)))
          )) ||
          (zipcode && zipcode.toLowerCase().includes(lowerCaseValue))
        );
      });
    
      setSearchResults(results);
      setcurrentPage(1);
    };
  
  
  const pages = Math.ceil(Userdata.length / itemsPerPage);

  const getPaginatedData = () => {
    const startIndex = currentPage * itemsPerPage - itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return Userdata.slice(startIndex, endIndex);
  };
  
  const data = getPaginatedData();
  
  function goToNextPage() {
    if (data.length < itemsPerPage) {
      return;
    } else {
      setcurrentPage((page) => page + 1);
    }
  }
  
  function goToPreviousPage() {
    if (currentPage === 1) {
      return;
    } else {
      setcurrentPage((page) => page - 1);
    }
  }
 */}
console.log("org id", brand && brand.org_id)
console.log("org id", orgId)
const handleSearch = (e) => {
  const value = e.target.value;
  setSearchTerm(value);

  const results = userData && userData.filter((user) => { // Add a check for userData
    return (
      user.email.toLowerCase().includes(value.toLowerCase()) ||
      user.role.toLowerCase().includes(value.toLowerCase())
    );
  });

  setSearchResults(results);
};

  const handleUpdate = async (el, id) => {
    setUserData(el);
    SetSelectedId(id);
    setOpenProfileModal(true);
  };

  const handleDelete = (id) => {
    setOpenDeleteModal(true);
    SetSelectedId(id);
  };

  const openModal = (data, id) => {
    setUserData(data)
    SetSelectedId(id)
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const organizationModalHandler = () => {
    setIsOpenOrganization(true);
  }
  
  const handleGoToOrganization = () => {
    history.push(`/organization/dashboard/${orgId}`);
    window.location.reload();
  }
  
  return (

    <div className='flex flex-col m-4  w-full rounded-md bg-white'  style={{ height: 'calc(95vh - 5rem)'}}>
        <div className="flex items-center h-16 justify-between ">
          <h1 className="font-semibold p-8 pl-2 ml-5 2xl:text-2xl md:text-base xs:text-base">Team Members</h1>
        </div>  
    {loading ? (
      <div className='w-10 h-10 items-center mx-auto'><Loader/></div>
    ) : ( <div className='mx-10'>
    
     <div className="flex justify-end items-left border-b">
         
          <CustomButton
            onClick={inviteHandler} 
            className="bg-red text-white w-fit my-2 lg:px-4 py-2 xs:px-2 rounded-lg mr-8 xs:text-sm"
          >
            Invite
          </CustomButton>
          {currentUser && currentUser.uid === "aOLwKHwgMAbQfJv3QIPXk616Nwp1" && (
            <CustomButton
            onClick={() => setModal(!modal)}
              className="bg-red text-white w-fit my-2 lg:px-4 py-2 xs:px-2 rounded-lg mr-8 xs:text-sm"
            >
              Add Brand
            </CustomButton>
          )}
          {brand && brand.organization_owned ? (
            <Link
              to={`/organization/dashboard/${orgId}`}
              onClick={handleGoToOrganization}
              className="bg-Blue text-white w-fit my-2 lg:px-4 py-2 xs:px-2 rounded-lg mr-8 xs:text-sm"
            >
              Go to Organization
            </Link>
          ) : (
            <button
              onClick={organizationModalHandler}
              className="bg-Blue text-white w-fit  my-2 lg:px-4 py-2 xs:px-2 rounded-lg mr-8 xs:text-sm"
            >
              Create Organization
            </button>
          )}
        </div>
    
    <input
      type="text"
      placeholder="Search by email or role"
      onChange={handleSearch}
      className="p-2 my-4 border w-full border-gray-200 rounded-md"
    />
    <div className="overflow-x-auto h-full">
      <table className="w-full table-auto">
        <thead className='border-b-2 border-gray-500'>
          <tr className='border-b-2 border-gray-500'>
            <th className="px-10 py-4 text-left text-xs font-bold text-gray-500 uppercase sticky fixed top-0 w-1/2 bg-white">Email</th>
            <th className="px-10 py-4 text-left text-xs font-bold text-gray-500 uppercase sticky fixed top-0 w-1/2 bg-white">Role</th>
          </tr>
        </thead>
        <tbody>
          {searchResults.length > 0 ? (
            searchResults.map((user, index) => (
              <tr key={index} className={index % 2 === 0 ? "bg-gray-200 group hover:bg-gray-300" : "bg-white group hover:bg-gray-100"}>
              <td className="px-10 py-4 whitespace-nowrap">{user.email}</td>
              <td className="px-10 py-4 whitespace-nowrap relative">{user.role}
                <button className="absolute top-0 right-12 p-4  text-black rounded-full opacity-0 group-hover:opacity-100"><FaEdit className='w-10 h-6'/></button>
              </td>
            </tr>
            ))
          ) : searchTerm !== "" ? (
            <tr>
              <td colSpan="2" className="px-6 py-4 text-center">
                <div className='h-full mx-auto my-20 flex items-center justify-center'>
                  No members fit your search criteria
                </div>
              </td>
            </tr>
          ) : (
            userData.length > 0 && userData.map((user, index) => (
              <tr key={index} className={index % 2 === 0 ? "bg-gray-200 group hover:bg-gray-300" : "bg-white group hover:bg-gray-100"}>
              <td className="px-10 py-4 whitespace-nowrap">{user.email}</td>
              <td className="px-10 py-4 whitespace-nowrap relative">{user.role}
                <button className="absolute top-0 right-12 p-4  text-black rounded-full opacity-0 group-hover:opacity-100"><FaEdit className='w-10 h-6'/></button>
              </td>
            </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
    {
          openInviteModal ?
           <InviteBrandModal
            open = {openInviteModal}
            brand_name = {brand && brand.brand_name}
            onClose={() => setOpenInviteModal(false)}
           /> : null
        }
      {isOpenOrganization && (
        <NewOrganizationModal
        isOpen = {isOpenOrganization}
        closeAction = {() => setIsOpenOrganization(false)}
        />
      )}
       {modal ?
      <AddBrandModal open={modal} onClose={() => setModal(false)} /> 
      : null
      }
    </div>)}
    </div>
  );
}

export default BrandUsers;
