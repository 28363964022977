import React, { useState, useEffect } from 'react';
import { useTabState } from '../../utils/TabState';
import 'react-responsive-modal/styles.css';
import UgcAssets from './UgcAssets';
import UgcVideoDrafts from './UgcVideoDrafts';
import UgcProposals from './UgcProposals';
import UgcFulfillment from './UgcFulfillment';
import { Link } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import UgcInfo from './UgcInfo';
import UgcTab from './ugcTab';
import { TaskService, useTaskState } from '../../Service/TaskServices';
import { UsersService, useUserState } from '../../Service/userServices';
import Loader from '../Loader2';
import TrialUgcProposals from './TrialTaskComponent/TrialUgcProposals';
export default function UgcTaskSubNav(props) {
  const tabState = useTabState();
  const taskState = useTaskState();
  const userState = useUserState();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true); // Initialize loading state

  useEffect(() => {
    let isMounted = true; // Track whether the component is mounted
  
    if (id) {
      TaskService.FetchSingleTask(id).then(() => {
        if (isMounted) {
          setIsLoading(false); // Set loading to false once data is fetched
        }
      });
    }
  
    return () => {
      isMounted = false; // Cleanup function to set the flag to false
    };
  }, [id, taskState.updateNeed.value]); // Include 'id' in the dependency array

  const task = taskState.tasks.value;

  return (
    <div className="bg-white z-10 m-4 rounded-lg w-full text-base" style={{ height: 'calc(95vh - 5rem)' }}>
      <div className='flex items-center z-50 bg-white sticky' style={{ top: 0 }}>
        <h1 className='mr-auto ml-9 font-bold 2xl:text-base md:text-0.7'>{task.name || task.note1} {task && task.trial === true && (<span className='font-extra text-white bg-gray-800 rounded-md px-4 py-0.5 ml-3'>TRIAL</span>)}</h1>
        <Link to='/brand/dashboard/tasks' className='flex mt-2 ml-4 mb-4 w-fit'>
          <BsArrowLeft className='mx-3 mt-0.5' />
          <h1 className='font-bold mr-6 2xl:text-base md:text-0.7'>Back to Tasks</h1>
        </Link>
      </div>
      <UgcTab task={task} currentUgcTab={tabState.currentUgcTab.value} />
      {tabState.currentUgcTab.value === 0 && <UgcInfo />}
      {tabState.currentUgcTab.value === 1 && 
      (task && task.trial === true ? <TrialUgcProposals /> :
      <UgcProposals />)}
      {tabState.currentUgcTab.value === 2 && <UgcFulfillment />}
      {tabState.currentUgcTab.value === 3 && <UgcVideoDrafts />}
      {tabState.currentUgcTab.value === 4 && <UgcAssets />}
    </div>
  );
}