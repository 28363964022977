import React from 'react';

function Pricing(props) {

    return (
        <div className="bg-white">
            <>
                <h1 className="text-center text-4xl font-bold">How It Works</h1>
                <p className="text-center mb-8">Select the plan that fits your needs</p>
                <div className="md:h-41 xs:h-auto  md:flex md:w-6px xs:w-full mx-auto">
                  <div className="h-28 mt-10 bg-lightGray md:w-3px xs:w-4px md:mx-8 xs:mx-auto rounded-xl">
                    <h1 className="text-xl m-8 font-bold mb-3 font-black xs:xl md:text-4xl text-center">
                      BASIC
                    </h1>
                    <h1 className="text-xl m-8 font-bold mb-3 font-black xs:xl md:text-4xl text-center">
                      $199/mo <span className='line-through text-sm text-darkGray'>$299/mo</span>
                    </h1>

                    <ul className="ml-12" style={{ listStyle: 'disc' }}>
                      <li className='py-2'>3 credits</li>
                      <li className='py-2'>
                        <a className="text-blue underline" href="#">
                          Recipe Video
                        </a>
                        (2 credits)
                      </li>
                      <li className='py-2'>
                        <a
                          className="text-blue underline"
                          style={{ textDecoration: 'disc' }}
                          href="#">
                          Product Showcase
                        </a>
                        (1 credit)
                      </li>
                      <li className='py-2'>$150 for each additional credit</li>
                    </ul>

                    <button
                      className="mt-8 mx-auto bg-Red rounded text-white py-2 px-14 block"
                      type="button">
                      Select Plan
                    </button>
                  </div>
                  <div className="h-32 bg-red md:w-3px xs:w-4px md:mx-8 xs:mx-auto rounded-xl">
                    <h1 className="text-xl m-8 font-bold mb-3 text-white xs:xl md:text-4xl text-center">
                      PREMIUM
                    </h1>
                    <h1 className="text-xl m-8 font-bold mb-3 text-white xs:xl md:text-4xl text-center">
                      $625/mo
                    </h1>

                    <ul className="ml-12 text-white" style={{ listStyle: 'disc' }}>
                      <li className='py-2'>5 credits</li>
                      <li className='py-2'>
                        <a className="underline text-white" href="#">
                          Recipe Video
                        </a>
                        (2 credits)
                      </li>
                      <li className='py-2'>
                        <a
                          className="underline text-white"
                          style={{ textDecoration: 'disc' }}
                          href="#">
                          Product Showcase
                        </a>
                        (1 credit)
                      </li>
                      <li className='py-2 text-white'>$150 for each additional credit</li>
                    </ul>
                    <button
                      className="mt-15 mx-auto bg-white rounded text-red py-2 px-14 block"
                      type="button">
                      Select Plan
                    </button>
                  </div>
                  <div className="h-28  mt-10 bg-black md:w-3px xs:w-4px md:mx-8 xs:mx-auto rounded-xl">
                    <h1 className="text-xl m-8 font-bold mb-3 text-white xs:xl md:text-4xl text-center">
                      ENTERPRISE
                    </h1>
                    <h1 className="text-xl m-8 font-bold mb-3 text-white xs:xl md:text-4xl text-center">
                      Contact Us
                    </h1>
                    <div>
                      <p className="text-center mb-8 text-white">2 product Showcase</p>
                      <p className="text-center mb-8 text-white">
                        1 recipe video +1 product showcase
                      </p>
                    </div>
                    <button
                      className="mt-15 mx-auto bg-Red rounded text-white py-2 px-14 block"
                      type="button">
                      Select Plan
                    </button>
                  </div>
                </div>
              </>
        </div>
    );
}

export default Pricing;