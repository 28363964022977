import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';

//State
const state = createState({
  offers: [],
  offer: [],
  retrieving: false,
  fetched: false,
  loading: false,
  updateNeed: true
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'CREATOR_OFFERS_LOADED':
        return s.merge({
          offers: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
          fetched: true
        });
      case 'BRAND_OFFERS_LOADED':
        return s.merge({
          offers: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
          fetched: true
        });
      case 'OFFERS_LOADED':
        return s.merge({
          offers: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
          fetched: true
        });
      case 'CREATE_OFFER':
        return s.merge({
          offer: action.data,
          loading: false,
          updateNeed: true
        });
      case 'OFFER_UPDATE':
        return s.merge({
          updateNeed: true,
          loading: false
        });
      case 'OFFER_DELETE':
        return s.merge({
          loading: false,
          updateNeed: true
        });
      case 'OFFER_LOADED':
        return s.merge({
          offer: action.data,
          updateNeed: false,
          loading: false,
          fetched: true
        });
      default:
        return null;
    }
  }, action.type);
});

export const accessOfferState = () => state;

export const useOfferState = () => useState(state);
//Service
export const OfferService = {
  FetchOffersForCreator: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/offers/creator/getOffers/${id}`)
      .then((res) => {
        dispatch(OfferAction.loadCreatorOffers(res.data.result));
      })
      .catch((err) => {});
  },
  FetchOffersForBrand: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/offers/brands/getOffers/${id}`)
      .then((res) => {
        dispatch(OfferAction.loadBrandOffers(res.data.result));
      })
      .catch((err) => {});
  },
  FetchOffers: () => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/offers`)
      .then((res) => {
        dispatch(OfferAction.loadOffers(res.data.result));
      })
      .catch((err) => {});
  },
  AddOffer: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/offers/createOffer`, data)
      .then((res) => {
        dispatch(OfferAction.createOffer(res));
      })
      .catch((err) => {});
  },
  UpdateOffer: (id, data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/offer/updateOffer/${id}`, data)
      .then((res) => {
        dispatch(OfferAction.updateOffer(res));
      })
      .catch((err) => {});
  },
  DeleteOffer: (id, data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/offers/cancelOffer/${id}`, data)
      .then((res) => {
        dispatch(OfferAction.deleteOffer(res));
      })
      .catch((err) => {});
  },
  ApproveOffer: (id, data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/offers/acceptOffer/${id}`, data)
      .then((res) => {
        dispatch(OfferAction.updateOffer(res));
      })
      .catch((err) => {});
  },
  FetchSingleOffer: (id) => {
    const dispatch = useDispatch();

    axios
      .get(`${baseUrl}api/offer/${id}`)
      .then((res) => {
        dispatch(OfferAction.loadSingleOffer(res.data.result));
      })
      .catch((err) => {});
  }
};

//Action
export const OfferAction = {
  loadCreatorOffers: (data) => {
    return {
      type: 'CREATOR_OFFERS_LOADED',
      data: data
    };
  },
  loadBrandOffers: (data) => {
    return {
      type: 'BRAND_OFFERS_LOADED',
      data: data
    };
  },
  loadOffers: (data) => {
    return {
      type: 'OFFERS_LOADED',
      data: data
    };
  },
  loadSingleOffer: (data) => {
    return {
      type: 'OFFER_LOADED',
      data: data
    };
  },
  createOffer: (data) => {
    return {
      type: 'CREATE_OFFER',
      data: data
    };
  },
  updateOffer: (data) => {
    return {
      type: 'OFFER_UPDATE',
      data: data
    };
  },
  deleteOffer: (data) => {
    return {
      type: 'OFFER_DELETE',
      data: data
    };
  }
};
