import React, { useState, useEffect, useContext } from "react";
import "react-responsive-modal/styles.css";
import Loader from "../../Loader";
import { useAuth } from "../../../Context/AuthContext";
import {
  AuthService,
  userAuthstate,
} from "../../../Service/authenticationService";
import { useTabState } from "../../../utils/TabState";
import { CampaignState } from "../../../utils/campaignState";
import S3FileUpload from "react-s3";
import s3 from "../../../aws-s3";
import { useBrandState, BrandService } from "../../../Service/BrandServices";
import {
  SubscriptionService,
  useSubscriptionState,
} from "../../../Service/SubscriptionServices";
import { Link } from "react-router-dom";
import {
  useProductState,
  ProductService,
} from "../../../Service/ProductServices";
import { BrandContext } from "../../../routes/PrivateRoute/BrandDashboardRoute";
import Multiselect from "multiselect-react-dropdown";
import { Divider } from "@material-ui/core";
import CustomButton from "../../../utils/customButton";

export default function BasicCampaignInfo() {
  const { currentUser, currentBrand } = useAuth();
  const [targetBudget, setTargetBudget] = useState("");
  const [isRetailCampaign, setIsRetailCampaign] = useState(false);
  const [numInfluencers, setNumInfluencers] = useState("");
  const [numViews, setNumViews] = useState("");
  const { brandId } = useContext(BrandContext);
  const [credits, setCredits] = useState("");
  const tabState = useTabState();
  const productState = useProductState();
  const [selectedProduct, setSelectedProduct] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [storeLocator, setStoreLocator] = useState("");
  const [retailer, setRetailer] = useState("");

  useEffect(() => {
    // tabState.merge({ currentTab: 1 });
    if (productState.updateNeed.value) {
      ProductService.FetchProductForUser(brandId);
    }
  }, [productState.updateNeed.value]);

  const res = JSON.stringify(productState.products.value);

  const productTemp = [];
  if (productState.products.value) {
    Object.keys(JSON.parse(res)).forEach((el) => {
      productTemp.push({
        id: el,
        ...JSON.parse(res)[el],
      });
    });
  }

  const handleStartDateChange = (event) => {
    const selectedStartDate = event.target.value;
    setStartDate(selectedStartDate);

    if (endDate && selectedStartDate > endDate) {
      setEndDate("");
    }
  };

  const handleEndDateChange = (event) => {
    const selectedEndDate = event.target.value;
    if (selectedEndDate >= startDate) {
      setEndDate(selectedEndDate);
    }
  };

  const handleStoreLocatorChange = (event) => {
    setStoreLocator(event.target.value);
  };

  const handleRetailerChange = (event) => {
    setRetailer(event.target.value);
  };

  const [metrics, setMetrics] = useState({
    views: 0,
    influencers: 0,
    lowerRangeInfluencers: 0,
    upperRangeInfluencers: 0,
    lowerRangeViews: 0,
    upperRangeViews: 0,
    credits: 0,
    targetBudget: 0,
    probableBudget: 0,
  });
  const [selectedColor, setSelectedColor] = useState("#ffffff"); // Default to white
  const [couponType, setCouponType] = useState(""); // Coupon type state
  const [dollarOff, setDollarOff] = useState(0);
  const [percentageOff, setPercentageOff] = useState(0);
  const [selectedObjective, setSelectedObjective] = useState("");
  const [lowerRangeViews, setLowerRangeViews] = useState("");
  const [upperRangeViews, setUpperRangeViews] = useState("");
  const [lowerRangeInfluencers, setLowerRangeInfluencers] = useState("");
  const [upperRangeInfluencers, setUpperRangeInfluencers] = useState("");
  const campaignState = CampaignState();
  const [campaign, setCampaign] = useState({
    campaignName: "",
    campphoto: "",
    budget: "",
    credits: "",
    number_of_videos: "",
    target_viewership: "",
  });
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedDestinations, setSelectedDestinations] = useState([]);
  const brandState = useBrandState();
  const subsState = useSubscriptionState();

  useEffect(() => {
    if (brandId !== null) {
      BrandService.FetchSingleBrand(brandId);
      SubscriptionService.FetchSubscriptionForUser(brandId);
    }
  }, [brandState.updateNeed.value]);

  const brand = brandState.brand.value;
  const subs = subsState.subscription.value;
  let availUsage = 0;
  if (subs) {
    availUsage = subs.paidUsage - subs.currentUsage;
  }
  useEffect(() => {
    if (brand && subs) {
      availUsage = subs.paidUsage - subs.currentUsage;
    }
  }, [subs, subsState.updateNeed.value]);

  const handleBudgetChange = (e) => {
    const inputName = e.target.name;
    if (inputName === "target_budget") {
      const budget = e.target.value;
      const divide2 = budget / 2;
      const influencerBudget = divide2 / 200;
      const viewsBudget = (divide2 / 33) * 1000;

      setMetrics({
        ...metrics,
        targetBudget: budget,
        credits: budget,
        views: Math.round(viewsBudget),
        influencers: Math.round(influencerBudget),
        probableBudget: "",
      });
      setTargetBudget(budget);
      setCredits(budget);
      setNumViews(Math.round(viewsBudget));
      setNumInfluencers(Math.round(influencerBudget));
    } else if (inputName === "total_views") {
      const views = e.target.value;
      let influencers = metrics.influencers;
      let credits = influencers * 200 + (views / 1000) * 33;
      setMetrics({
        ...metrics,
        views: parseInt(views),
        credits: credits,
        probableBudget: credits,
      });
    } else {
      const influencers = e.target.value;
      let views = metrics.views;
      let credits = influencers * 200 + (views / 1000) * 33;
      setMetrics({
        ...metrics,
        influencers: influencers,
        credits: credits,
        probableBudget: credits,
      });
    }
  };

  const handleViewsChange = (e) => {
    const inputName = e.target.name;
    if (inputName === "total_views") {
      const views = e.target.value;
      const lowerRangeInfluencers = views / 15000;
      const upperRangeInfluencers = views / 7500;

      const influencers = parseInt(
        (upperRangeInfluencers + lowerRangeInfluencers) / 2,
      );

      let credits = influencers * 200 + (views / 1000) * 33;

      setMetrics({
        ...metrics,
        views: parseInt(views),
        influencers: influencers,
        lowerRangeInfluencers: Math.round(lowerRangeInfluencers),
        upperRangeInfluencers: Math.round(upperRangeInfluencers),
        credits: credits,
        targetBudget: credits,
      });
    } else {
      let influencers = e.target.value;
      let views = metrics.views;
      let credits = influencers * 200 + (views / 1000) * 33;
      setMetrics({
        ...metrics,
        influencers: influencers,
        credits: credits,
        targetBudget: credits,
      });
    }
  };

  const handleInfluencerChange = (e) => {
    const inputName = e.target.name;
    if (inputName === "total_influencers") {
      const numInfluencers = e.target.value;
      const lowerRange = numInfluencers * 7500;
      const upperRange = numInfluencers * 15000;
      const views = (upperRange + lowerRange) / 2;
      let credits = numInfluencers * 200 + (views / 1000) * 33;
      setMetrics({
        ...metrics,
        influencers: numInfluencers,
        views: parseInt(views),
        lowerRangeViews: Math.round(lowerRange),
        upperRangeViews: Math.round(upperRange),
        credits: credits,
        targetBudget: credits,
      });
    } else {
      let numInfluencers = metrics.influencers;
      let views = e.target.value;
      let credits = numInfluencers * 200 + (views / 1000) * 33;
      setMetrics({
        ...metrics,
        views: views,
        targetBudget: credits,
        credits: credits,
        influencers: numInfluencers,
      });
    }
  };

  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
    setMetrics({
      ...metrics,
      views: "",
      influencers: "",
      lowerRangeInfluencers: "",
      upperRangeInfluencers: "",
      lowerRangeViews: "",
      upperRangeViews: "",
      credits: "",
      targetBudget: "",
    });
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setCampaign({ ...campaign, campphoto: e.target.files[0] });
    }
    setCampaign({ ...campaign, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (campaign.campphoto && selectedValue !== "") {
      console.log("Campaign Photo:", campaign.campphoto);
      const file = event.target.campphoto.files[0]; // Access the file from the input element

      await S3FileUpload.uploadFile(file, s3).then((data) => {
        const reach_budget = parseInt(campaign.budget * 100);
        console.log(data.location);

        const campaignData = {
          campaign_name: campaign.campaignName,
          campaign_photo: data.location,
          date_created: "",
          budget: metrics.targetBudget,
          credits: Math.round(metrics.credits),
          number_of_videos: metrics.influencers,
          target_viewership: metrics.views,
          campaign_objective: selectedObjective,
          traffic_destination: selectedDestinations,
        };

        if (isRetailCampaign) {
          const product = productTemp.find((obj) => obj.id === selectedProduct);
          campaignData.retail_campaign = {
            brand_id: brandId,
            brand_name: brand && brand.brand_name,
            brand_photo: brand && brand.brand_avatar,
            budget_allocated: metrics.views * 0.05 * 0.4,
            coupon_type: couponType,
            dollar_off: dollarOff,
            promo_start_date: startDate,
            expiry_date: endDate,
            percentage_off: percentageOff,
            product_name: campaign.campaignName, // Assuming the product name is the same as the campaign name
            product_photo: product.thumbnail,
            retail_store_locator: storeLocator,
            retailer_name: retailer, // Assuming the retailer is selected in destinations
            theme_color: selectedColor,
          };
        }

        campaignState.merge(campaignData);
        tabState.merge({ currentCampModalTab: 1 });
        console.log(campaignState.campaign_photo);
        console.log(campaignData);
      });
    }
  };

  const handleObjectiveChange = (e) => {
    setSelectedObjective(e.target.value);
  };

  const trafficDestinationChange = (e) => {
    setSelectedDestinations(e.target.value);
  };

  const handleColorChange = (event) => {
    setSelectedColor(event.target.value);
  };

  const handleCouponTypeChange = (event) => {
    console.log("coupon_type: " + event.target.value);
    setCouponType(event.target.value);
    setDollarOff(0);
    setPercentageOff(0);
  };

  const handleDollarOffChange = (event) => {
    setDollarOff(event.target.value);
  };

  const handlePercentageOffChange = (event) => {
    setPercentageOff(event.target.value);
  };

  return (
    <div className="h-fit">
      <form onSubmit={handleSubmit} className="px-4 mt-4 md:mt-8 h-full">
        <div className="my-0.5 mt-2 md:mt-4">
          <label
            htmlFor="campaignName"
            className="text-black font-bold md:text-lg mb-2"
          >
            Campaign name
          </label>
          <p className="text-gray-500 text-sm mb-1">
            Please choose a name for your influencer campaign. Make sure it is
            short and distinctive.
          </p>
          <input
            name="campaignName"
            className="mr-2 outline-none bg-gray-100 p-2 rounded-lg w-full"
            placeholder=""
            required
            maxLength="30"
            onChange={handleChange}
            value={campaign.campaignName}
          />
        </div>

        <div className="my-0.5 mt-2 md:mt-4">
          <label
            htmlFor="campphoto"
            className="text-black font-bold md:text-lg mb-2"
          >
            Campaign Photo
          </label>
          <p className="text-gray-500 text-sm mb-1">
            Please choose a distinctive photo for your campaign. This will not
            be visible to creators, only to you.
          </p>
          <input
            name="campphoto"
            className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
            placeholder=""
            required
            type="file"
            accept="image/png, image/jpeg, image/jpg, image/webp"
            onChange={handleChange}
          />
        </div>

        <div className="w-full border-2 my-10"></div>

        <div className="my-0.5 mt-2 md:mt-4">
          <label
            htmlFor="campaignName"
            className="text-black font-bold md:text-lg mb-2"
          >
            Traffic Destination
          </label>
          <p className="text-gray-500 text-sm mb-1">
            Please indicate where you would like to direct the traffic for your
            influencer campaign.
          </p>
          <div className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full">
            <select
              className="outline-none bg-gray-100 w-full"
              value={selectedDestinations}
              onChange={trafficDestinationChange}
            >
              <option value="">---Select a destination---</option>
              <option value="amazon">Amazon Product Detail Page</option>
              <option value="brand_website">Brand Website</option>
              <option value="store_locator">Store Locator</option>
              <option value="retail_website">Retail Website</option>
              <option value="sms_rebate">SMS Rebate Landing Page</option>
              <option value="other">Other</option>
            </select>
          </div>
        </div>

        <div className="my-0.5 mt-2 md:mt-4">
          <label
            htmlFor="campaignName"
            className="text-black font-bold md:text-lg mb-2"
          >
            Campaign Objective
          </label>
          <p className="text-gray-500 text-sm mb-1">
            Please indicate the purpose of this campaign.
          </p>
          <div className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full">
            <select
              className="outline-none bg-gray-100 w-full"
              value={selectedObjective}
              onChange={handleObjectiveChange}
            >
              <option value="">---Select an objective---</option>
              <option value="phone_number_emails">
                Collect phone numbers/emails
              </option>
              <option value="survey_data">Collect survey data</option>
              <option value="in_store_trial">Driving in-store trial</option>
              <option value="in_store_purchases">
                Driving in-store purchases
              </option>
              <option value="online_purchases">Driving online purchases</option>
            </select>
          </div>
        </div>

        <div className="w-full border-2 my-10"></div>
        {/* <div className="my-0.5 mt-2 md:mt-4">
          <label
            htmlFor="retailCampaign"
            className="text-black font-bold md:text-lg mb-2"
          >
            Retail Campaign
          </label>
          <div className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full flex items-center">
            <label className="mr-3">Enable Retail Campaign</label>
            <input
              type="checkbox"
              id="retailCampaign"
              checked={isRetailCampaign}
              onChange={(e) => setIsRetailCampaign(e.target.checked)}
            />
          </div>
        </div>

        
        {isRetailCampaign && (
          <div className="mt-4">
            <div className="mb-4">
              <label className="text-black font-bold md:text-lg mb-2">
                Retail Campaign Coupon Type
              </label>
              <div className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full">
                <select
                  className="outline-none bg-gray-100 w-full"
                  value={couponType}
                  onChange={handleCouponTypeChange}
                >
                  <option value="">---Select Coupon Type---</option>
                  <option value="percentage_off">Percentage Off</option>
                  <option value="dollar_off">Dollar Off</option>
                  <option value="bogo">BOGO</option>
                  <option value="free">Free</option>
                </select>
              </div>
            </div>

            {couponType === "percentage_off" && (
              <div className="mb-4">
                <label className="text-black font-bold md:text-lg mb-2">
                  Percentage Off
                </label>
                <input
                  className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                  placeholder="Enter Percentage Off"
                  type="number"
                  min="1"
                  max="100"
                  value={percentageOff}
                  onChange={handlePercentageOffChange}
                />
              </div>
            )}

            {couponType === "dollar_off" && (
              <div className="mb-4">
                <label className="text-black font-bold md:text-lg mb-2">
                  Dollar Off
                </label>
                <input
                  className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                  placeholder="Enter Dollar Off"
                  type="number"
                  min="0"
                  value={dollarOff}
                  onChange={handleDollarOffChange}
                />
              </div>
            )}

            <div className="mb-4">
              <label className="text-black font-bold md:text-lg mb-2">
                Store Locator
              </label>
              <input
                className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                placeholder="Enter Store Locator URL"
                type="url"
                value={storeLocator}
                onChange={handleStoreLocatorChange}
              />
            </div>

            <div className="mb-4">
              <label className="text-black font-bold md:text-lg mb-2">
                Retailer
              </label>
              <div className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full">
                <select
                  className="outline-none bg-gray-100 w-full"
                  value={retailer}
                  onChange={handleRetailerChange}
                >
                  <option value="">---Select Retailer---</option>
                  <option value="walmart">Walmart</option>
                  <option value="target">Target</option>
                  <option value="whole_foods">Whole Foods</option>
                  <option value="kroger">Kroger</option>
                  <option value="costco">Costco</option>
                  <option value="publix">Publix</option>
                  <option value="albertsons">Albertsons</option>
                  <option value="sams_club">Sam’s Club</option>
                  <option value="wegmans">Wegmans</option>
                  <option value="heb">H-E-B</option>
                  <option value="trader_joes">Trader Joe’s</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>

            <div className="my-2">
              <label
                htmlFor="audience"
                className="text-black font-bold block md:text-lg mb-2"
              >
                Showcased Product
              </label>
              <p className="text-gray-500 text-base mb-1">
                Please choose the product you want creators to showcase, there
                is only 1 showcased product per brief.
              </p>
              <select
                // ref={selectRef}
                value={selectedProduct}
                onChange={(e) => setSelectedProduct(e.target.value)}
                className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                required
                placeholder="Choose Product..."
              >
                <option className="text-sm text-indigo-800" value="">
                  Choose Product
                </option>
                {productTemp.map((item) => {
                  return (
                    <option
                      className="text-sm text-indigo-800"
                      value={item.id}
                      key={item.id}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="mb-4">
              <label className="text-black font-bold md:text-lg mb-2">
                Start - End Date
              </label>
              <input
                type="date"
                className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                placeholder="Select Start Date"
                value={startDate}
                onChange={handleStartDateChange}
              />
              <input
                type="date"
                className="mt-2 mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                placeholder="Select End Date"
                value={endDate}
                onChange={handleEndDateChange}
                disabled={!startDate}
              />
            </div>

            <div className="mb-4">
              <label className="text-black font-bold md:text-lg mb-2">
                Theme Color
              </label>
              <div className="flex items-center">
                <input
                  type="color"
                  className="mr-2 outline-none bg-gray-100 p-3 rounded-lg cursor-pointer"
                  value={selectedColor}
                  onChange={handleColorChange}
                  style={{ display: "none" }} // Hide the actual color input
                  id="colorPicker"
                />
                <input
                  type="text"
                  className="outline-none bg-gray-100 p-3 rounded-lg w-24 text-center"
                  value={selectedColor}
                  readOnly
                />
                <div
                  className="ml-2 h-8 w-8 rounded-lg cursor-pointer"
                  style={{
                    backgroundColor: selectedColor,
                    border: "1px solid #000",
                  }}
                  onClick={() => document.getElementById("colorPicker").click()} // Trigger the color input's click
                ></div>
              </div>
            </div>
            {Math.round(metrics.credits) > 0 && (
              <h1 className="text-base md:text-3xl tracking-tighter mx-auto text-center font-extrabold text-red">
                {Math.round(metrics.views * 0.05 * 0.4)} Credits will be
                allocated towards retail campaign
              </h1>
            )}
          </div>
        )} */}

        <div className="my-0.5 mt-2 md:mt-4">
          <label
            htmlFor="primary_metric"
            className="text-black font-bold md:text-lg mb-2"
          >
            Campaign Sizing Metric
          </label>
          <p className="text-gray-500 text-sm mb-1">
            Please indicate how you would like to size your influencer campaign.
            By views, by influencers, or by budget.
          </p>
          <div className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full">
            <select
              className="outline-none bg-gray-100 w-full"
              value={selectedValue}
              onChange={handleSelectChange}
            >
              <option value="">---Select a metric---</option>
              <option value="total_views">Total Views Across Campaign</option>
              <option value="total_influencers">
                Total Influencers Across Campaign (1 video asset/post per
                influencer)
              </option>
              <option value="staying_budget">Staying on Budget</option>
            </select>
          </div>
        </div>

        {selectedValue === "total_views" && (
          <div className="flex flex-col items-center justify-center">
            <div className="my-0.5 mt-2 md:mt-4 w-full">
              <label
                htmlFor="campaignName"
                className="text-black font-bold md:text-lg mb-2"
              >
                What is the viewership goal for your campaign? (33 credits for
                every 1,000 views)
              </label>
              <input
                name="total_views"
                className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                placeholder=""
                required
                maxLength="30"
                type="number"
                onChange={handleViewsChange}
                value={metrics.views}
              />
            </div>
            <div className="my-0.5 mt-2 md:mt-4 w-full">
              <label
                htmlFor="target_budget"
                className="text-black font-bold md:text-lg mb-2"
              >
                Target Influencers Across Campaign
              </label>
              <div className="text-sm text-gray-600">
                Each influencer will generate 1 video (200 credits per video).
              </div>
              <input
                name="total_influencers"
                className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                placeholder=""
                required
                maxLength="30"
                type="number"
                onChange={handleViewsChange}
                value={metrics.influencers}
              />
              <p className="2xl:text-base text-xs text-left text-gray-500">
                {" "}
                Based on your viewership goal, we suggest targeting{" "}
                {metrics.lowerRangeInfluencers} -{" "}
                {metrics.upperRangeInfluencers} influencers for your campaign.
              </p>
            </div>
          </div>
        )}

        {selectedValue === "total_influencers" && (
          <div className="flex flex-col items-center justify-center">
            <div className="my-0.5 mt-2 md:mt-4 w-full">
              <label
                htmlFor="target_budget"
                className="text-black font-bold mb-2"
              >
                How many influencers do you want to join this campaign?
              </label>
              <div className="text-sm text-gray-600">
                Each influencer will generate 1 video (200 credits per video).
              </div>
              <input
                name="total_influencers"
                className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                placeholder=""
                required
                maxLength="30"
                type="number"
                onChange={handleInfluencerChange}
                value={metrics.influencers}
              />
            </div>
            <div className="my-0.5 mt-2 md:mt-4 w-full">
              <label
                htmlFor="campaignName"
                className="text-black font-bold md:text-lg mb-2"
              >
                Target Viewership Across Campaign (33 credits for every 1,000
                views)
              </label>
              <input
                name="target_budget"
                className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                placeholder=""
                required
                maxLength="30"
                type="number"
                onChange={handleInfluencerChange}
                value={metrics.views}
              />
              <p className="text-sm text-left text-gray-500">
                Based on the number of influencers for your campaign, we suggest
                a viewership target of{" "}
                {metrics.lowerRangeViews.toLocaleString()} -{" "}
                {metrics.upperRangeViews.toLocaleString()}
              </p>
            </div>
          </div>
        )}

        {selectedValue === "staying_budget" && (
          <div className="flex flex-col items-center justify-center">
            <div className="my-0.5 mt-2 md:mt-4 w-full">
              <label
                htmlFor="target_budget"
                className="text-black font-bold md:text-lg mb-2"
              >
                What is the budget for your campaign?
              </label>
              <input
                name="target_budget"
                className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                placeholder="Enter target budget to find out the target views and target influencers"
                required
                maxLength=""
                type="number"
                onChange={handleBudgetChange} // Add the onChange event here
                value={metrics.targetBudget}
              />
              <p className="2xl:text-base mt-0.5 tracking-tighter text-left text-gray-400">
                Based on the budget you gave, this is the typical campaign size.
                You may adjust this, but it may increase your budget.
              </p>
            </div>
            {metrics.probableBudget > 0 && (
              <p
                className={
                  metrics.probableBudget > metrics.targetBudget
                    ? "2xl:text-base text-xs mt-0.5 tracking-tighter text-Blue"
                    : "2xl:text-base text-xs mt-0.5 tracking-tighter text-green"
                }
              >
                You are currently{" "}
                {metrics.probableBudget > metrics.targetBudget
                  ? `over budget by ${Math.round(
                      metrics.probableBudget - metrics.targetBudget,
                    ).toLocaleString()}`
                  : `under budget by ${Math.round(
                      metrics.targetBudget - metrics.probableBudget,
                    ).toLocaleString()}`}
              </p>
            )}
            <div className="my-0.5 mt-2 md:mt-4 w-full">
              <label
                htmlFor="campaignName"
                className="text-black font-bold md:text-lg mb-2"
              >
                Target Views Across Campaign (33 credits for every 1,000 views)
              </label>
              <input
                name="total_views"
                className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                placeholder=""
                required
                type="number"
                onChange={handleBudgetChange}
                maxLength="30"
                value={metrics.views} // Make this input read-only
              />
              <p className="2xl:text-base text-xs text-left text-gray-500">
                Based on your budget, we suggest a viewership target of{" "}
                {metrics.views.toLocaleString()} views
              </p>
            </div>
            <div className="my-0.5 mt-2 md:mt-4 w-full">
              <label
                htmlFor="campaignName"
                className="text-black font-bold md:text-lg mb-2"
              >
                Target Influencers Across Campaign
              </label>
              <div className="text-sm text-gray-600">
                Each influencer will generate 1 video (200 credits per video).
              </div>
              <input
                name="total_influencers"
                className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                placeholder=""
                required
                type="number"
                onChange={handleBudgetChange}
                maxLength="30"
                value={metrics.influencers}
              />
              <p className="2xl:text-base text-xs text-left text-gray-500">
                {" "}
                Based on your budget, we suggest targeting{" "}
                {metrics.influencers.toLocaleString()} influencers for your
                campaign.
              </p>
            </div>
          </div>
        )}

        <div className="flex flex-col items-center justify-center">
          <h1 className="text-Blue font-bold mt-4">Budget for Campaign</h1>
          <h1 className="text-black font-bold text-2xl text-center tracking-tighter mb-0.5 w-full">
            {" "}
            This budget is just an estimate, we won't charge you any credits
            until you approve an influencer.
          </h1>
          {Math.round(metrics.credits) > 0 && (
            <h1 className="text-base md:text-3xl tracking-tighter mx-auto font-extrabold text-black">
              {Math.round(metrics.credits)} Credits{" "}
              {isRetailCampaign &&
                `(${Math.round(
                  metrics.views * 0.05 * 0.4,
                )} credits for retail campaign)`}
            </h1>
          )}
        </div>

        <div className="flex justify-center md:mb-2">
          {subs && subs.status === "active" ? (
            <div className="flex flex-col justify-center md:mb-2">
              {Math.round(metrics.credits) > availUsage ? (
                <>
                  <p className="text-center text-3xl font-bold text-red">
                    ({subs && availUsage} credits available)
                  </p>
                  <div className="flex justify-center">
                    <Link
                      className="py-2 w-fit bg-darkGray px-4 rounded-lg text-white mt-4 items-center justify-center text-center cursor-pointer"
                      to="/brand/dashboard/billing"
                    >
                      Add Credits
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <p className="text-center text-3xl font-bold">
                    ({subs && availUsage} credits available)
                  </p>
                  <div className="flex justify-center">
                    <CustomButton
                      type="submit"
                      className="py-2 w-fit bg-red px-4 rounded-lg text-white mt-4 items-center justify-center text-center cursor-pointer"
                    >
                      Next
                    </CustomButton>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="justify-center">
              <p className="text-center text-xl text-red font-bold mt-2">
                Your subscription is inactive, check the billing tab.{" "}
              </p>
              <div className="flex justify-center">
                <Link
                  className="py-2 w-fit bg-darkGray px-4 rounded-lg text-white mt-4 items-center justify-center text-center cursor-pointer"
                  to="/brand/dashboard/billing"
                >
                  Go to Billing
                </Link>
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
