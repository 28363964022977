import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';

//State
const state = createState({
  organization: [],
  brands: [],
  stripe_user: '',
  retrieving: false,
  fetched: false,
  loading: false,
  updateNeed: true
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'ORGANIZATION_CREATED':
        return s.merge({
          ogranization: action.data,
          updateNeed: false,
          retrieving: true,
          fetched: true
        });
      case 'ORGANIZATION_LOADED':
        return s.merge({
          organization: action.data,
          updateNeed: false,
          retrieving: true,
          fetched: true
        })
      case 'ORGANIZATION_USERS_LOADED':
        return s.merge({
          organization: action.data,
          updateNeed: false,
          retrieving: true,
          fetched: true
        })
      case 'BRANDS_LOADED':
        return s.merge({
          brands: action.data,
          updateNeed: false,
          retrieving: true,
          fetched: true
        })
      case 'BRAND_CREATED':
        return s.merge({
          organization: action.data,
          updateNeed: false,
          retrieving: true,
          fetched: true
        })
      case 'USER_INVITED':
        return s.merge({
          organization: action.data,
          updateNeed: false,
          retrieving: true,
          fetched: true
        })
      case 'ROLE_CHANGED':
         return s.merge({
           organization: action.data,
           updateNeed: false,
           retrieving: true,
           fetched: true
         })
      case 'USER_REMOVED':
        return s.merge({
          organization: action.data,
          updateNeed: false,
          retrieving: true,
          fetched: true
        })
      default:
        return s.merge({
          user: action.data,
          loading: false,
          fetched: true,
          updateNeed: true
        });
    }
  }, action.type);
});

export const accessOrganizationState = () => state;

export const useOrganizationState = () => useState(state);

//Service
export const OrganizationService = {
  CreateOrganization: (data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/orgs/createOrganization`, data )
      .then((res) => {
        dispatch(OrganizationAction.createOrganization(res.data.result));
      })
      .catch((err) => {});
  },

   GetOrganization: (id) => {
    const dispatch = useDispatch();
  
    return axios
      .get(`${baseUrl}api/orgs/${id}`)
      .then((res) => {
        // Dispatch the action to update the organization state
        dispatch(OrganizationAction.loadOrganization(res.data.result));
        return res.data.result; // Return the organization data if needed
      })
      .catch((err) => {
        console.error('Error fetching organization:', err);
        throw err; // Rethrow the error to handle it in the calling code
      });
  },

  GetBrands: (id) => {
    const dispatch = useDispatch();
    return axios
      .get(`${baseUrl}api/orgs/${id}/brands`)
      .then((res) => {
        
        dispatch(OrganizationAction.loadOrganizationBrands(res.data.result));
        return res.data.result;
      })
      .catch((err) => {
        console.error('Error fetching organization:', err);
        throw err;
      })
  },

  GetOrgUsers : (id) => {
    const dispatch = useDispatch();
    return axios
      .get(`${baseUrl}api/orgs/${id}/users`)
      .then((res) => {
        dispatch(OrganizationAction.loadOrganizationBrands(res.data.result));
        return res.data.result;
      })
      .catch((err) => {
        console.error('Error fetching organization:', err);
        throw err;
      })
  },

  InviteUser: (id, data) => {
    const dispatch = useDispatch();
    return axios
      .post(`${baseUrl}api/orgs/${id}/invite`, data)
      .then((res) => {
        dispatch(OrganizationAction.inviteUser(res.data.result));
        return res; // return the entire response
      })
      .catch((err) => {
        console.error('Error inviting user:', err);
        throw err;
      })
  },

  CreateBrand: (id, data) => {
    const dispatch = useDispatch();
    return axios
      .post(`${baseUrl}api/orgs/${id}/createBrand`, data)
      .then((res) => {
        dispatch(OrganizationAction.loadOrganizationBrands(res.data.result));
        return res.data.result;
      })
      .catch((err) => {
        console.error('Error creating brand:', err);
        throw err;
      })
  },

  ChangeUserRole: (id, data) => {
    const dispatch = useDispatch();
    return axios
      .post(`${baseUrl}api/orgs/${id}/users/changeRole`, data)
      .then((res) => {
        dispatch(OrganizationAction.changeUserRole(res.data.result));
        return res.data.result;
      })
      .catch((err) => {
        console.error('Error changing user role:', err);
        throw err;
      })
  },

  RemoveUser: (id, data) => {
    const dispatch = useDispatch();
    return axios
      .post(`${baseUrl}api/orgs/${id}/users/removeUser`, data)
      .then((res) => {
        dispatch(OrganizationAction.userRemoved(res.data.result));
        return res.data.result;
      })
      .catch((err) => {
        console.error('Error removing user:', err);
        throw err;
      })
  }
  
};

//Action
export const OrganizationAction = {
 createOrganization: (data) => {
    return {
      type: 'ORGANIZATION_CREATED',
      data: data
    };
  },

  loadOrganization: (data) => {
    return {
      type: 'ORGANIZATION_LOADED',
      data: data
    }
  },
  loadOrgUsers: (data) => {
    return {
      type: 'ORGANIZATION_USERS_LOADED',
      data: data
    }
  },
  
  loadOrganizationBrands: (data) => {
    return {
      type: 'BRANDS_LOADED',
      data: data
    }
  },

  createBrand: (data) => {
    return {
      type: 'BRAND_CREATED',
      data: data
    }
  },

  inviteUser: (data) => {
    return {
      type: 'USER_INVITED',
      data: data
    }
  },
   
  changeUserRole: (data) => {
    return {
      type: 'USER_ROLE_CHANGED',
      data: data
    }
  },

  removeUser : (data) => {
    return {
      type: 'USER_REMOVED',
      data: data
    }
  }
};
