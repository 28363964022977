import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';

//State
const state = createState({
  templates: [],
  loading: false,
  updateNeed: true,
  template: null
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'SEND_SUBMISSION_EMAIL':
        return s.merge({
          template: action.data,
          loading: false,
          updateNeed: true,
        });
      case 'SEND_NEW_PROPOSAL_EMAIL':
        return s.merge({
          updateNeed: false,
          loading: false
        });
      case 'SEND_PROPOSAL_DENIAL_EMAIL':
        return s.merge({
          updateNeed: false,
          loading: false
        });
      case 'SEND_PROPOSAL_APPROVAL_EMAIL':
        return s.merge({
          updateNeed: false,
          loading: false
        });
      case 'SEND_REVISION_EMAIL':
        return s.merge({
          updateNeed: false,
          loading: false
        });
      case 'RECEIVE_REVISION_EMAIL':
        return s.merge({
          updateNeed: false,
          loading: false
        })
      case 'SEND_TASK_COMPLETION_EMAIL':
        return s.merge({
          updateNeed: false,
          loading: false
        });
      case 'SEND_DELIVERABLES_EMAIL':
        return s.merge({
          updateNeed: false,
          loading: false
        });
      case 'RECEIVE_DELIVERABLES_EMAIL':
        return s.merge({
          updateNeed: false,
          loading: false
        });

      case 'SEND_INFLUENCER_SUBMIT_LINK_EMAIL':
        return s.merge({
          updateNeed: false,
          loading: false
        });

        case 'SEND_SHIPPING_CREATOR_FULFILLMENT_EMAIL':
        return s.merge({
          updateNeed: false,
          loading: false
        });

        case 'SEND_PROMO_FULFILLMENT_EMAIL':
        return s.merge({
          updateNeed: false,
          loading: false
        });

        case 'SEND_IN_STORE_FULFILLMENT_EMAIL':
        return s.merge({
          updateNeed: false,
          loading: false
        });

        
      default:
        return null;
    }
  }, action.type);
});

export const accessEmailState = () => state;

export const useEmailState = () => useState(state);

//Service
export const CampaignEmailService = {
  SendNewProposalEmail: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/campaigns/email/newProposal`, data)
      .then((res) => {
        dispatch(CampaignEmailAction.sendNewProposalEmail(res));
      })
      .catch((err) => { });
  },
  SendSubmissionEmail: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/campaigns/email/newProposalSubmission`, data)
      .then((res) => {
        dispatch(CampaignEmailAction.sendSubmissionEmail(res));
      })
      .catch((err) => { });
  },
  SendDenialEmail: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/campaigns/email/proposalDenial`, data)
      .then((res) => {
        dispatch(CampaignEmailAction.sendProposalDenialEmail(res.data));
      })
      .catch((err) => { });
  },
  SendApprovalEmail: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/campaigns/email/proposalApproval`, data)
      .then((res) => {
        dispatch(CampaignEmailAction.SendApprovalEmail(res.data));
      })
      .catch((err) => { });
  },
  SendRevisionEmail: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/campaigns/email/sendRevision`, data)
      .then((res) => {
        dispatch(CampaignEmailAction.sendRevisionEmail(res.data));
      })
      .catch((err) => { });
  },

  ReceiveRevisionEmail: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/campaigns/email/receiveRevision`, data)
      .then((res) => {
        dispatch(CampaignEmailAction.receiveRevisionEmail(res.data));
      })
      .catch((err) => { });
  },

  InfluencerSubmitLinkEmail: async (req, res) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/campaigns/email/influencerSubmitLink`, req)
      .then((res) => {
        dispatch(CampaignEmailAction.influencerSubmitLinkEmail(res.data));
      })
      .catch((err) => { });
  },

  SendTaskCompletionEmail: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/campaigns/email/sendTaskCompletion`, data)
      .then((res) => {
        dispatch(CampaignEmailAction.sendTaskCompletionEmail(res.data));
      })
      .catch((err) => { });
  },
  SendDeliverablesEmail: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/campaigns/email/sendDeliverables`, data)
      .then((res) => {
        dispatch(CampaignEmailAction.sendDeliverablesEmail(res.data));
      })
      .catch((err) => { });
  },
  ReceiveDeliverablesEmail: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/campaigns/email/receiveDeliverables`, data)
      .then((res) => {
        dispatch(CampaignEmailAction.receiveDeliverablesEmail(res.data));
      })
      .catch((err) => { });
  },

  SendInfluencerFulfillmentEmail: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/campaigns/email/influencerFulfillmentEmail`, data)
      .then((res) => {
        dispatch(CampaignEmailAction.sendInfluencerFulfillmentEmail(res.data));
      }).catch((err) => { });
  },

  PromoFulfillmentEmail: async (data) => {
    const dispatch = useDispatch();
    await axios
    .post(`${baseUrl}api/campaigns/email/promoFulfillment`, data)
    .then((res) => {
      dispatch(CampaignEmailAction.sendPromoFulfillmentEmail(res.data));
    }).catch((err) => { });
  },

  InStoreFulfillmentEmail: async (data) => {
    const dispatch = useDispatch();
    await axios
    .post(`${baseUrl}api/campaigns/email/inStoreFulfillment`, data)
    .then((res) => {
      dispatch(CampaignEmailAction.sendInStoreFulfillmentEmail(res.data));
    }).catch((err) => { });

  }
};

//Action
export const CampaignEmailAction = {
  sendSubmissionEmail: (data) => {
    return {
      type: 'SEND_SUBMISSION_EMAIL',
      data: data
    };
  },
  sendNewProposalEmail: (data) => {
    return {
      type: 'SEND_NEW_PROPOSAL_EMAIL',
      data: data
    };
  },
  sendProposalDenialEmail: (data) => {
    return {
      type: 'SEND_PROPOSAL_DENIAL_EMAIL',
      data: data
    };
  },
  sendProposalApprovalEmail: (data) => {
    return {
      type: 'SEND_PROPOSAL_APPROVAL_EMAIL',
      data: data
    };
  },
  sendRevisionEmail: (data) => {
    return {
      type: 'SEND_REVISION_EMAIL',
      data: data
    };
  },

  influencerSubmitLinkEmail: (data) => {
    return {
      type: 'INFLUENCER_SUBMIT_LINK_EMAIL',
      data: data
    };
  },

  receiveRevisionEmail  : (data) => {
    return {
      type: 'RECEIVE_DELIVERABLES_EMAIL',
      data: data
    };
  },

  sendTaskCompletionEmail: (data) => {
    return {
      type: 'SEND_TASK_COMPLETION_EMAIL',
      data: data
    };
  },
  sendDeliverablesEmail: (data) => {
    return {
      type: 'SEND_DELIVERABLES_EMAIL',
      data: data
    };
  },
  receiveDeliverablesEmail: (data) => {
    return {
      type: 'RECEIVE_DELIVERABLES_EMAIL',
      data: data
    };
  },
  sendInfluencerFulfillmentEmail: (data) => {
    return {
      type: 'SEND_INFLUENCER_FULFILLMENT_EMAIL',
      data: data
    };
  },

  sendPromoFulfillmentEmail: (data) => {
    return {
      type: 'SEND_PROMO_FULFILLMENT_EMAIL',
      data: data
    };
  },

  sendInStoreFulfillmentEmail: (data) => {
    return {
      type: 'SEND_IN_STORE_FULFILLMENT_EMAIL',
      data: data
    };
  }
};