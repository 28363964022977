import React, { useContext, useEffect, useState } from 'react';
import { useAuth } from '../../../Context/AuthContext';
import instagram from '../../../Assets/instagram (1).png';
import {BsEyeFill, BsFillPeopleFill, BsFillPersonFill, BsQuestionCircle} from 'react-icons/bs';
import { useParams, useHistory } from 'react-router-dom';
import tiktok from '../../../Assets/tiktok-square.png';
import WhiteListingInstructions from '../WhiteListingInstructions';
import { toast, Toaster } from 'react-hot-toast';
import Loader from '../../LoaderRed';
import ReactStars from 'react-rating-stars-component';
import { TaskService, useTaskState } from '../../../Service/TaskServices';
import { BrandContext } from '../../../routes/PrivateRoute/BrandDashboardRoute';
import { InstagramService, useInstagramState } from '../../../Service/InstagramService';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import ViewDemographicsModal from '../ViewDemographicsModal';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import TrialProfilePage from '../../Creator_Dashboard/TrialProfilePage';
import TrialSelectPlanModal from '../TrialOnboarding/TrialSelectPlanModal';
import { SubscriptionService, useSubscriptionState } from '../../../Service/SubscriptionServices';
import { BrandService, useBrandState } from '../../../Service/BrandServices';
function TrialUgcProposals(props) {
  let taskState = useTaskState();
  const [taskData, setTaskData] = useState(null);
  const {currentUser} = useAuth();
  const { id } = useParams();
  const subscriptionState = useSubscriptionState();
  const brandState = useBrandState();
  const [loading, setLoading] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const { brandId } = useContext(BrandContext);
  const [toggle1, setToggle1] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [tabs, setTabs] = useState(0);
  const [toggle3, setToggle3] = useState(false);
  const [toggle4, setToggle4] = useState(false);
  const [whitelist, setWhitelist] = useState(false);
  const [filteredProposals, setFilteredProposals] = useState([]);
  const [tab, setTab] = useState(0);
  const [upgrade, setUpgrade] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const handleToggle1 = () => {
    setToggle1(prevToggle => !prevToggle);
  };
  
  const handleBuyCreditPackClick = () => {
    setUpgrade(true);
    setTab(0); // Set the tab to 0 when the "Buy Credit Pack" button is clicked
  };
  const fetchData = async () => {
    if (!id) return;
  
    setLoading(true);
    try {
      const task = await TaskService.FetchSingleTask(id);
      setTaskData(task);
      if (task && typeof task === "object" && task.proposals && Object.keys(task.proposals).length > 0) {
        setFilteredProposals(task.proposals);
      } else {
        setFilteredProposals({});
      }
      console.log("Task data:", task);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);
 
  
  let task = taskState.tasks.value;

  useEffect(() => {
    if (brandId !== null) {
      BrandService.FetchSingleBrand(brandId);
      SubscriptionService.FetchSubscriptionForUser(brandId);
    }
    
  }, [brandState.updateNeed.value]);
  const brand = brandState.brand.value;
  console.log("brand info:", brand);
  const subs = subscriptionState.subscription.value;
  console.log("subs info:", subs);
  
  let availUsage = 0
  if (subs) {
    availUsage = subs.paidUsage - subs.currentUsage;
  }
  useEffect(() => {
    if (brand && subs) {
      availUsage = subs.paidUsage - subs.currentUsage;
    }
  }, [subs, subscriptionState.updateNeed.value]); 

  useEffect(() => {
    // Reverse the filteredProposals array directly
    const reversedProposals = [...Object.values(filteredProposals)].reverse();
    //console.log('Reversed/Reset Proposals:', reversedProposals);
    
    // Update the state with the reversed array
    setFilteredProposals(reversedProposals);
  }, [toggle1]);

  useEffect(() => {
    const filtered = Object.values(filteredProposals).filter(proposal => proposal.status !== 'denied');
    const sortedProposals = filtered.sort((a, b) => {
      const followerCountSumA = (a.creator_socials && a.creator_socials.instagram && a.creator_socials.instagram.follower_count || 0) + (a.creator_socials && a.creator_socials.tiktok && a.creator_socials.tiktok.performance && a.creator_socials.tiktok.performance.followerCount || 0);
      const followerCountSumB = (b.creator_socials && b.creator_socials.instagram && b.creator_socials.instagram.follower_count || 0) + (b.creator_socials && b.creator_socials.tiktok && b.creator_socials.tiktok.performance && b.creator_socials.tiktok.performance.followerCount || 0);
  
      if (toggle2) {
        return followerCountSumB - followerCountSumA; // Sort in descending order of follower count sum
      } else {
        return followerCountSumA - followerCountSumB; // Sort in ascending order of follower count sum
      }
    });
  
    //console.log('Sorted Proposals:', sortedProposals);
  
    // Update the state with the sorted array
    setFilteredProposals(sortedProposals);
  }, [toggle2]);

  useEffect(() => {
    const filtered = Object.values(filteredProposals).filter(proposal => proposal.status !== 'denied');
    const sortedProposals = filtered.sort((a, b) => {
      const instagramValueA = Math.floor((a.creator_socials && a.creator_socials.instagram && a.creator_socials.instagram.median_plays || 0) || (Math.floor((a.creator_socials && a.creator_socials.instagram && a.creator_socials.instagram.suggested_rate)/2500)*1000) || 0);
      const tiktokValueA = (a.creator_socials && a.creator_socials.tiktok && a.creator_socials.tiktok.performance && a.creator_socials.tiktok.performance.median_views || 0) || Math.round((a.creator_socials && a.creator_socials.tiktok && a.creator_socials.tiktok.performance && a.creator_socials.tiktok.performance.avg10_views) || 0);
  
      const instagramValueB = Math.floor((b.creator_socials && b.creator_socials.instagram && b.creator_socials.instagram.median_plays || 0) || (Math.floor((b.creator_socials && b.creator_socials.instagram && b.creator_socials.instagram.suggested_rate)/2500)*1000) || 0);
      const tiktokValueB = (b.creator_socials && b.creator_socials.tiktok && b.creator_socials.tiktok.performance && b.creator_socials.tiktok.performance.median_views || 0) || Math.round((b.creator_socials && b.creator_socials.tiktok && b.creator_socials.tiktok.performance && b.creator_socials.tiktok.performance.avg10_views) || 0);
  
      const valueA = instagramValueA + tiktokValueA;
      const valueB = instagramValueB + tiktokValueB;
  
      if (toggle3) {
        return valueB - valueA; // Sort in descending order of the calculated value
      } else {
        return valueA - valueB; // Sort in ascending order of the calculated value
      }
    });
  
    //console.log('Sorted Proposals:', sortedProposals);
  
    // Update the state with the sorted array
    setFilteredProposals(sortedProposals);
  }, [toggle3]);

  useEffect(() => {
    const filtered = Object.values(filteredProposals).filter(proposal => proposal.status !== 'denied');
    const sortedProposals = filtered.sort((a, b) => {
    const ratingA = (typeof a.average_rating === 'number') ? a.average_rating : 0;
    const ratingB = (typeof b.average_rating === 'number') ? b.average_rating : 0;
      if (toggle4) {
        return ratingB - ratingA; // Sort in descending order of rating
      } else {
        return ratingA - ratingB; // Sort in ascending order of rating
      }
    });
  
    // Update the state with the sorted array
    setFilteredProposals(sortedProposals);
  }, [toggle4]);

  const handleToggle2 = () => {
    setToggle2(prevToggle => !prevToggle);
  };

  const handleToggle4 = () => {
    setToggle4(prevToggle => !prevToggle);
  };

  const history = useHistory();

  //to redirect to the profile page
  const handleRedirect = (creator_id) => {
    console.log("creator id for the profile page", creator_id);
    // Store creator_id in session storage
    sessionStorage.setItem('creator_id', creator_id);
    // Open the new tab
    window.open('/profile', '_blank');
};

  {/*useEffect(() => {
    if (id) {
      TaskService.FetchSingleTask(id);
    }
  }, [taskState.updateNeed.value]); // Make sure 'id' is in the dependency array // Make sur
  if (taskState.task.value) {
    console.log("VALUE: ", taskState.tasks.value)
    setTaskData(taskState.tasks.value);
  }*/}

  const removeInfluencer = async (proposal, proposalKey) => {
    console.log("task id: " + proposal.task_id);
    console.log("key: " + proposalKey);
    const data = {
      creator_id: proposal.creator_id,
      task_id: task.id,
      bid: brandId,
      proposal_id: proposalKey
    };
    
    try {
      await TaskService.RemoveInfluencer(proposalKey, data);
      fetchData();
      toast.success('Influencer Removed');
    }
    catch (error) {
      toast.error('An error occurred while removing the influencer.');
    }

  }
  function formatNumber(number) {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1) + "M";
    } else if (number >= 1000) {
      return (number / 1000).toFixed(1) + "k";
    } else {
      return number.toString();
    }
  }

  const whitelistInstructions = () => {
    setWhitelist(!whitelist);
    setTabs(0);
  }
  
  //console.log("task: ", task);
  //console.log("accepted proposals length:", task.accepted_proposals ? Object.entries(task.accepted_proposals).length : 0);
  return (
    <div className="bg-white w-full p-4 rounded-lg z-100 shadow-md overflow-y-scroll h-full">
      <Toaster />
      {loading ? (
        <div className='flex justify-center'>
          <Loader />
        </div>
      ) : task && !task.proposals ? (
        <div className="flex justify-center font-bold mt-24">
            <div className="text-center">
              <p className="2xl:text-base md:text-0.65">
                We are currently inviting creators to apply to your task,
                when they do you will be able to review their profiles here.<br />
                This process normally takes 3-4 days, but could be sooner.
                We will send a notification via email when there are proposals for review.
              </p>
            </div>
        </div>
      ) : (
        <div className='ml-10 md:text-base text-xs'>
          <div className='flex items-center'>
              <div className="2xl:text-3xl w-full md:text-0.7 flex items-center justify-center mx-auto text-center">
                <span className='font-bold text-gray-800 italic mr-2'>NOTE:</span>You won't be able to approve/deny creators or view their contact details without buying credits
              </div>
            <div className="relative ml-auto">
              <button
                className='border-2 2xl:px-9 2xl:py-2 md:px-6 md:py-1 sticky rounded-t-md bg-gray-100 text-black hover:bg-gray-200'
                onClick={toggleDropdown}
              >
                Sort
              </button>
              {showDropdown && (
                <div className="absolute right-0 z-10 bg-white border border-gray-300 rounded-md shadow-lg">
                  <ul className="p-0">
                    <li className="border-b border-gray-300 flex items-center justify-between px-4 py-2 hover:bg-gray-100 cursor-pointer w-full" onClick={handleToggle1}>
                      <span className='text-base'>Date</span>
                      <span>{toggle1 ? <BsChevronDown /> : <BsChevronUp />}</span>
                    </li>
                    <li className="border-b border-gray-300 flex items-center justify-between px-4 py-2 hover:bg-gray-100 cursor-pointer w-full" onClick={handleToggle2}>
                      <span className='text-base'>Followers</span>
                      <span>{toggle2 ? <BsChevronDown /> : <BsChevronUp />}</span>
                    </li>
                    <li className="border-b border-gray-300 flex items-center justify-between px-4 py-2 hover:bg-gray-100 cursor-pointer w-full" onClick={handleToggle4}>
                      <span className='text-base'>Creator Rating</span>
                      <span>{toggle4 ? <BsChevronDown /> : <BsChevronUp />}</span>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
          {Object.entries(filteredProposals)
            .filter(([proposalKey, proposal]) => proposal.status !== 'denied')
            .map(([proposalKey, proposal], index) => (
              <div key={proposalKey} className="proposal-container ">
                <div className="mr-4 grid grid-cols-2">
                  <div className="flex flex-row flex-wrap items-start mt-2 2xl:h-fit md:h-fit">
                    <img className="2xl:w-20 2xl:h-20 md:w-4rem md:h-4rem ml-2 border-2 rounded-lg object-cover" src={proposal.creator_photo || 'https://crafted-react-images.s3.amazonaws.com/Default_pfp_crafted_default.png'} alt="avatar" />
                    <div className="ml-6">
                      <p>

                          <span className='font-bold 2xl:text-base md:text-0.7 mr-2' style={{ filter: 'blur(3px)' }}>Jane Doe</span>        
                        <span className='italic text-gray-500 2xl:text-base md:text-0.7'>
                          {proposal && proposal.creator_address && proposal.creator_address.city}, {proposal && proposal.creator_address && proposal.creator_address.state}, {proposal && proposal.creator_address && proposal.creator_address.country}
                        </span>
                        <div>
                          {typeof proposal.average_rating === 'number' ? (
                            <div className='flex flex-row items-center mt-2'>
                              <span className="mr-2 font-bold mt-0.5 2xl:text-base md:text-0.65">
                                {proposal.average_rating.toFixed(1)}
                              </span>
                              <ReactStars
                                count={5}
                                isHalf={true}
                                value={Number(proposal.average_rating.toFixed(1))}
                                size={15}
                                edit={false}
                                activeColor="#FFD700"
                              />
                            </div>
                          ) : (
                            <p className='2xl:text-0.8 md:text-0.65 text-white px-2 rounded-md my-2 w-fit py-1 bg-red'>
                              NEW
                            </p>
                          )}
                        </div>
                      </p>
                      <div className="flex flex-row mt-2">
                        <div className="flex flex-row ">
                          {proposal.creator_socials && (
                            <>
                              <BsFillPeopleFill title="Followers" className='mr-2 2xl:w-6 2xl:h-6 md:w-4 md:h-4' />
                              {proposal && proposal.creator_socials && proposal.creator_socials.instagram && proposal.creator_socials.instagram.handle && (
                                <div className="flex items-center">
                                    <img className="2xl:w-5 2xl:h-5 md:w-3 md:h-3" src={instagram} alt="instagram" />   
                                  {proposal.creator_socials.instagram.follower_count && (
                                    <span title="Follower count (Instagram)" className="ml-2 font-bold 2xl:text-base md:text-0.7">
                                      {formatNumber(proposal.creator_socials.instagram.follower_count) || 0}
                                    </span>
                                  )}
                                </div>
                              )}
                              {proposal && proposal.creator_socials && proposal.creator_socials.tiktok && proposal.creator_socials.tiktok.handle && proposal.creator_socials.tiktok.performance && proposal.creator_socials.tiktok.performance.followerCount && (
                                <div className="flex items-center ml-4">
                                    <img className="2xl:w-5 2xl:h-5 md:w-3 md:h-3" src={tiktok} alt="tiktok" />
                                  <span title="Follower count (TikTok)" className="ml-2 font-bold cursor-pointer 2xl:text-base md:text-0.7">
                                    {formatNumber(proposal.creator_socials.tiktok.performance.followerCount)}
                                  </span>
                                </div>
                              )}
                            </>
                          )}
                          <div className="ml-3">
                                <button  onClick={() => handleRedirect(proposal.creator_id)} className="bg-Blue text-white px-2 py-1 rounded-lg outline-none 2xl:text-base md:text-0.7">
                                  <span className="text-white">View Portfolio + Reviews</span>
                                </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 ml-2 w-full" style={{ display: 'flex', flexDirection: 'column' }}>
                      <p className='2xl:text-base md:text-0.7'>
                        Video Idea: {proposal && proposal.proposal_details && proposal.proposal_details.proposal1}
                      </p>
                      <div className="mt-2.5 ml-2"></div>
                      <div className="mt-2.5 ml-2">
                      <div>
                          <button
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                            className={`mb-2 2xl:py-2 2xl:px-4 md:py-1 md:px-2 2xl:text-base md:text-0.6 rounded transform transition-transform duration-500 ease-in-out ${hoveredIndex === index ? 'bg-Blue hover:scale-105 text-white ' : 'bg-gray-100 text-darkGray'}`}
                            onClick={handleBuyCreditPackClick}
                          >
                            {hoveredIndex === index ? 'Purchase credits' : 'Approve Creator'}
                          </button>
                        </div>
                          
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="w-full my-3 h-0.5 border-t-2 h-6 bg-gray-200 opacity-100 dark:opacity-50" />
              </div>
            ))}
        </div>
      )}
      {upgrade && <TrialSelectPlanModal close={() => setUpgrade(false)} open={upgrade} tab={tab} setTab={setTab} />}
</div>

  )
 }
  


export default TrialUgcProposals