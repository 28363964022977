import React, { useContext, useEffect } from 'react';
import { CardElement, CardNumberElement, CardExpiryElement, CardCvcElement,  useElements, useStripe,  } from '@stripe/react-stripe-js';
import { useAuth } from '../../Context/AuthContext';
import { UsersService, useUserState } from '../../Service/userServices';
import { CreditPackService } from '../../Service/CreditPackServices';
import { BrandService, useBrandState } from '../../Service/BrandServices';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';
import CustomButton from '../../utils/customButton';
import toast, { Toaster } from 'react-hot-toast';

const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "14px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        iconColor: '#ffc7ee',
        color: '#ffc7ee'
      }
    }
  };


function PaymentForm(props) {
    const stripe = useStripe();
    const elements = useElements();
    const { currentUser } = useAuth();
    const { brandId } = useContext(BrandContext);
    const userState = useUserState();
    const brandState = useBrandState();

    useEffect(()=> {
        if (currentUser) {
            BrandService.FetchSingleBrand(brandId);
        }
    }, [user, brandState.updateNeed.value]);

    const user = brandState.brand.value;

    console.log("Initially User-Stripe-Id: " + user.stripe_id);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const cardNumberElement = elements.getElement(CardNumberElement);
            const cardExpiryElement = elements.getElement(CardExpiryElement);
            const cardCvcElement = elements.getElement(CardCvcElement);
            const {error: tokenizationError, token} = await stripe.createToken(cardNumberElement);

                if (tokenizationError) {
                    console.error(tokenizationError);
                    alert("Tokenization error: " + tokenizationError.message);
                    return;
                }

                // Now use the token to create a payment method
                const {error, paymentMethod} = await stripe.createPaymentMethod({
                    type: 'card',
                    card: {token: token.id},
                });
            if (!error) {
                const user_info = {
                    email: user.email,
                    username: user.username,
                    paymentMethod: paymentMethod.id, 
                };
    
                if (user && user.stripe_id === undefined) {
                    console.log('Right before Subscription Call');
                    await CreditPackService.CreateCustomer(brandId, user_info);
                    console.log("Prev. undefined, User-Stripe-Id: " + user.stripe_id);
                }
    
                console.log("Before DB call User-Stripe-Id: " + (user && user.stripe_id));
    
                const temp = {
                    uid: brandId,
                    id: paymentMethod.id,
                    account_id: currentUser.uid,
                    plan: props.plan,
                    customerstripe_id: user.stripe_id || null,
                    subs_price: props.price,
                    quantity: props.qty,
                    promo: props.promo,
                };
    
                await CreditPackService.AddCreditPack(temp);
                console.log("subscription_called");
                console.log(temp);
                props.handleSubscribe();
            } else {
                console.error(error);
                toast.error("Payment error: " + error.message);  // Display a user-friendly error message
            }
        } catch (error) {
            console.error("Payment failed", error);
            toast.error("An error occurred during payment processing. Please try again."); // Display a user-friendly error message
        }
    };                                                                                                                                                          

    return (
        <div className="flex justify-center items-center w-fit mx-auto mt-4 2xl:h-28rem rounded-lg">
          <Toaster />
  <form className="w-full max-w-lg 2xl:p-8 md:p-6 md:h-28rem 2xl:h-28rem space-y-4 bg-white rounded-xl" onSubmit={handleSubmit}>
    <h2 className="2xl:text-base md:text-0.7 font-bold text-center text-gray-800">Enter your payment details to purchase this credit pack</h2>
    
    <div className="space-y-4">
      <div>
        <label htmlFor="cardNumber" className="block 2xl:text-base md:text-0.8 font-semibold text-gray-700">Card Number</label>
        <div className="mt-1">
          <CardNumberElement id="cardNumber" options={CARD_ELEMENT_OPTIONS} className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:bg-white focus:border-blue-500" />
        </div>
      </div>

      <div>
        <label htmlFor="cardExpiry" className="block 2xl:text-base md:text-0.8 font-semibold text-gray-700">Expiration Date</label>
        <div className="mt-1">
          <CardExpiryElement id="cardExpiry" options={CARD_ELEMENT_OPTIONS} className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:bg-white focus:border-blue-500" />
        </div>
      </div>

      <div>
        <label htmlFor="cardCvc" className="block 2xl:text-base md:text-0.8 font-semibold text-gray-700">CVC</label>
        <div className="mt-1">
          <CardCvcElement id="cardCvc" options={CARD_ELEMENT_OPTIONS} className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:bg-white focus:border-blue-500" />
        </div>
      </div>
    </div>

    <div className="text-center md:mt-10 2xl:text-base md:text-0.7 text-gray-500">Powered by <span className='text-purple hover:text-purple-dark font-bold font-rubik'>Stripe</span></div>
    
    <div className="flex w-34rem my-3 ml-12 text-center mx-auto">
                    <div className="mr-3">
                        {/*<Switch
                            onChange={handleChangeSwitch}
                            checked={checked}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor="#648DE5"
                            offColor="#999999"
                        />*/}
                        <input className='mt-2' type="checkbox"></input>
                    </div>
                    <div className="2xl:text-base md:text-xs ml-1 mt-1">
                        <p className='flex flex-row items-center'>I accept the 
                        <a className="text-Blue pl-1" target="_blank" href="https://crafted-react-images.s3.amazonaws.com/Client+Service+Agreement.pdf">
                            Terms of the Client Service Agreement
                        </a>
                        </p>
                    </div>
                </div>

    <div className="flex items-center justify-center mt-4">
      <CustomButton type="submit" className="w-2/3 2xl:px-5 2xl:py-3 md:px-3 md:py-1.5 text-lg font-bold text-white shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
        Purchase
      </CustomButton>
    </div>
  </form>
</div>
    );
}

export default PaymentForm;