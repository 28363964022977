import React from 'react';
import Chart from './Chart';
import { useUserState } from '../../Service/userServices';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useAuth } from '../../Context/AuthContext';
import { useBrandState, BrandService } from '../../Service/BrandServices';

function Index() {
  const userState = useUserState();
  const history = useHistory();
  const counterRef = useRef(0);
  const { currentBrand, currentUser } = useAuth();
  const brandState = useBrandState();
  console.log(currentUser.uid)
  useEffect(() => {
    if (currentUser.uid !== null) {
      BrandService.FetchSingleBrand(currentUser.uid);
    }
  }, [brandState.updateNeed.value]);

  const brand = brandState.brand.value;

  {/*IuseEffect(() => {
    if (brand === null){
      history.push('/');
    }
    if (brand && Object.keys(brand).length > 0) {
      console.log("Brand object populated:", brand);
      if (brand.admin === true) {
        console.log("Brand admin is true");
      }
      else {
      history.push('/');
    } 
    }
  }, [brand]); */}
  return (
    <div className="m-4  w-full ">
      <div className="bg-white rounded-lg p-4  h-2/4">
        <Chart />
      </div>
    </div>
  );
}

export default Index;
