import React, { useContext, useState } from 'react';
import ReactStars from "react-rating-stars-component";
import { ReviewService, useReviewState } from '../../../Service/ReviewService';
import CustomGlobalDialog from '../CustomGlobalDialog';
import {toast, Toaster} from 'react-hot-toast';
import { BrandContext } from '../../../routes/PrivateRoute/BrandDashboardRoute';
function ReviewModal(props) {
  const [professionalismScore, setProfessionalismScore] = useState(0);
  const [qualityScore, setQualityScore] = useState(0);
  const [performanceScore, setPerformanceScore] = useState(0);
  const [review, setReview] = useState('');
  const reviewState = useReviewState();
  const {brandId} = useContext(BrandContext);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      brand_id: props.review.brand_id,
      creator_id: props.review.creator_id,
      review_id: props.review.id,
      professionalism_score: professionalismScore,
      quality_score: qualityScore,
      performance_score: performanceScore,
      review: review,
      overall_rating: (professionalismScore + qualityScore + performanceScore) / 3
    }
    try {
      reviewState.merge({updateNeed: true});
      await ReviewService.createReview(data);
      await ReviewService.getBrandReviews(brandId);
     
      toast.success('Review edited successfully');
      setTimeout(() => {    
        props.onClose();
      }, 2000);  
      reviewState.merge({updateNeed: false});
    } catch (error) {
      console.error(error);
      reviewState.merge({updateNeed: false});
    }
  };
  return (
    <CustomGlobalDialog className="2xl:w-2/3 h-fit shadow-lg" open={props.open} onClose={props.onClose}> 
    <Toaster/>
    <h1 className="text-2xl font-bold mb-4 text-center">Please leave a review for {props.review && props.review.creator_name ? props.review.creator_name : "the creator"}</h1>
    <div className='border-b w-full'></div>
    <div className='grid grid-cols-3 justify-items-center align-items-center'>
      <div className='col-span-1 w-fit justify-items-center'>   
        <video className="w-fit max-w-xs h-96 object-contain shadow-inner mx-auto mt-10 mb-4" src={props.review.video} controls></video> 
        <div className='flex items-center justify-center mx-auto'>
          <img src={props.review.creator_avatar} alt="Profile" className="w-3rem h-3rem rounded-full mr-2 object-cover" />
          <h2 className="2xl:text-2xl xl:text-xs md:text-0.7 font-bold text-center ml-2">{props.review.creator_name}</h2>
        </div>
      </div>  
      <div className="col-span-2 p-2">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col items-center justify-center">
              <label className="font-bold 2xl:text-2xl xl:text-xs md:text-0.7 mb-1">Timeliness Score:</label>
              <p className="text-xs mb-2 text-center">How satisfied were you with the time it took for this creator to submit their first video draft? How satisfied were you with how quickly they turned around revisions?</p>              
              <ReactStars 
                count={5}
                value={professionalismScore}
                onChange={(newRating) => setProfessionalismScore(newRating)}
                size={40}
                activeColor="#FFD700"
              />
            </div>
            <div className="flex flex-col items-center justify-center">
              <label className="font-bold 2xl:text-2xl xl:text-xs md:text-0.7 mb-1">Quality Score:</label>
              <p className="text-xs mb-2 text-center">How satisfied were you with the content itself? Did they follow the brief? Was the lighting & camera quality good? Were you satisfied with video concept, creativity and execution?</p>
              <ReactStars 
                count={5}
                value={qualityScore}
                onChange={(newRating) => setQualityScore(newRating)}
                size={40}
                activeColor="#FFD700"
              />
            </div>
            <div className="flex flex-col items-center justify-center">
              <label className="font-bold 2xl:text-2xl xl:text-xs md:text-0.7 mb-1">Performance Score:</label>
              <p className="text-xs mb-2 text-center">How satisfied were you with the performance of this video? Did it meet viewership expectations? Did it lead to better CTRs for video ads?</p>
              <ReactStars 
                count={5}
                value={performanceScore}
                onChange={(newRating) => setPerformanceScore(newRating)}
                size={40}
                required
                activeColor="#FFD700"
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label className="font-thin mb-1 text-base">Please explain why you gave the scores above. This will be shared with the creator for improvement purposes.</label>
            <textarea 
              className="border p-2 rounded-md h-20 w-full"
              value={review} 
              onChange={(e) => setReview(e.target.value)} 
              required
            />
          </div>
          <button type="submit" className="bg-Blue flex items-center justify-center mx-auto text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors duration-200">Submit</button>
        </form>
      </div>
    </div>
</CustomGlobalDialog>
  );
}

export default ReviewModal;