import React, { useState } from 'react';
import DeleteModal from './DeleteModal';
import UpdateRecepe from './UpdateRecipe';
import { Link } from 'react-router-dom';

function ProfileRecipeCard(props) {

  return (
    <>
      <Link to={`/recipe/${props.id}`}>
        <div className="bg-lightGray items-center rounded-xl p-1 flex flex-col cursor-pointer">
          <img
            src={props.recipe.thumbnail}
            style={{ height: '200px' }}
            className="rounded-lg w-full h-full object-cover"
            alt={props.recipe.name}
          />
          <div className="text-base mb-1 font-bold"> {props.recipe.name}</div>
        </div>
      </Link>
    </>
  );
}

export default ProfileRecipeCard;
