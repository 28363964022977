import React from 'react';
import ReactPlayer from 'react-player';

function VideoPlayer(props) {
  const streamURL =
    'https://5c92cfd51029.us-east-1.playback.live-video.net/api/video/v1/us-east-1.286039432834.channel.nrf6vv5QenQ4.m3u8';

  return (
    <div className="w-full h-full pb-16/9 sm:px-0 md:px-2 lg:pl-8 md:pr-0">
      <ReactPlayer
        url={props.url || streamURL}
        width="100%"
        height="100%"
        light={props.thumbnail}
        playing
        controls
      />
    </div>
  );
}

export default VideoPlayer;
