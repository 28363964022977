import React, { useState, useEffect, useContext } from 'react';
import { useProductState, ProductService } from '../../../Service/ProductServices';
import { BrandContext } from '../../../routes/PrivateRoute/BrandDashboardRoute';
import GenerateBriefLoading from '../GenerativeBriefs/GenerateBriefLoading';
import GenerateBriefResult from '../GenerativeBriefs/GenerateBriefResult';
import { useGenerativeBriefState, GenerativeBriefService } from '../../../Service/GenerativeBriefService';
import { toast, Toaster } from 'react-hot-toast';
import Loader from '../../Loader2';
import GenerateBriefForm from '../GenerativeBriefs/GenerateBriefForm';
import '../GenerativeBriefs/GenerativeBriefs.css';
import GenerateBriefResultOnboarding from './GenerateBriefResultOnboarding';
import GenerateBriefFormOnboarding from './GenerativeBriefFormOnboarding';

const GenerateBriefOnboarding = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [step, setStep] = useState(0); // 0: form, 1: loading, 2: generated
  const [briefId, setBriefId] = useState();
  // loading the form

  let content;
  if (step === 1) {
    content = <GenerateBriefLoading step={step} setStep={setStep} />;
  } else if (step === 2) {
    content = <GenerateBriefResultOnboarding step={step} briefCreated={props.briefCreated} setBriefCreated={props.setBriefCreated} setStep={setStep} briefId={briefId} setBriefData={props.setBriefData} briefData={props.briefData} setActiveTab={props.setActiveTab} />;
  } else {
    content = <GenerateBriefFormOnboarding step={step} briefCreated={props.briefCreated} setBriefCreated={props.setBriefCreated} setStep={setStep} setBriefId={setBriefId} setBriefData={props.setBriefData} setActiveTab={props.setActiveTab} productName ={props.productName} />; // Pass step and setStep as props
  }

  return (
    <div>
      <Toaster/>
      <div className={`component-loaded`}>
        {content}
      </div>
    </div>
  );
};

export default GenerateBriefOnboarding;