import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from './../../Context/AuthContext';
import S3FileUpload from 'react-s3';
import s3 from './../../aws-s3';
import '../../App.css';
import Loader from './../Loader';
import { AuthService } from '../../Service/authenticationService';
import { BrandService } from '../../Service/BrandServices';
import { Mixpanel } from '../../mixpanel';
import { AccountService, useAccountState } from '../../Service/AccountServices';

function AccountCreation() {
  const name = useRef('');
  const bio = useRef('');
  const website = useRef('');
  const fileRef = useRef(null);
  const history = useHistory();
  const { currentBrand } = useAuth();
  const [loader, setLoader] = useState(false);
  const [imgUploaded, setImgUpload] = useState(false);
  const [companyData, setCompanyData] = useState({
    name: '',
    avatar: '',
    website: '', 
    bio: ''
  });
  const [error, setError] = useState({
    avatar: '',
    name: '',
    website: '',
    bio: ''
  });

  let update = {
    avatar: '',
    name: '',
    website: '',
    bio: ''
  };

  const onChange = (e) => {
    setCompanyData({ ...companyData, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: '' });
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImgUpload(true);
    } else {
      setImgUpload(false);
    }
  };
  useEffect(() => {
    AccountService.FetchAccountInfo(currentBrand.uid);
  }, [currentBrand.uid]);  

  let accountState = useAccountState();
  
  let accountData = accountState.account.value;
  let brandId = accountData && accountData.brands && Object.keys(accountData.brands)[0];
  console.log("brandId", brandId);
  async function handleSubmit() {
    const companydata = companyData;
    const avatarInput = fileRef.current.files[0];
    if (!avatarInput) {
      return setError({ ...error, avatar: 'avatar is required' });
    }
    if (name.current.value === '') return setError({ ...error, name: 'name is required' });
    if (bio.current.value === '') return setError({ ...error, bio: 'bio is required' });
    if (website.current.value === '') return setError({ ...error, website: 'bio is required' });

    setLoader(true);
    try {
      setError({ avatar: '', name: '', bio: '' });
      if (imgUploaded) {
        console.log('img uopldd:-', imgUploaded);
        await S3FileUpload.uploadFile(fileRef.current.files[0], s3, {
          uniquePrefix: true, // Add this option to generate a unique prefix for each file
        }).then((data) => {
          console.log('data:-', data);
          update = {
            bio: bio.current.value,
            name: name.current.value,
            website: website.current.value,
            avatar: data.location
          };
        });
      } else {
        update = {
          bio: bio.current.value,
          name: name.current.value,
          website: website.current.value,
          avatar: ''
        };
      }
      companydata.name = update.name;
      companydata.bio = update.bio;
      companydata.website = update.website;
      companydata.avatar = update.avatar;
      await AuthService.UpdateAuthBrand(brandId, companydata);
      await BrandService.UpdateBrand(brandId, companydata);
      Mixpanel.completeRegistration(companydata);
      setLoader(false);
      history.push('/brand/dashboard');
      window.location.reload();
    } catch (error) {
      setError('Failed to Update Profile');
    }
  }

  return (
    <>
      <div>
        <>
          <div className="mb-3">
            <label className="text-darkBlack font-bold capitalize">Company Logo</label>
            <br></br>
            <input
              id="imageInput"
              type="file"
              accept="jpeg, jpg, png"
              ref={fileRef}
              className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
              onChange={handleChange}
            />
            {error && error.avatar && <span className="text-sm text-Red">{error.avatar}</span>}
          </div>
        </>
        <div className="mb-3">
          <label className="text-darkBlack font-bold capitalize">Company Name</label>
          <input
            className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
            type="text"
            name="name"
            onChange={onChange}
            ref={name}
          />
          {error && error.name && <span className="text-sm text-Red">{error.name}</span>}
        </div>
        <div className="mb-3">
          <label className="text-darkBlack font-bold capitalize">Company Website</label>
          <input
            className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
            type="text"
            name="website"
            onChange={onChange}
            ref={website}
          />
          {error && error.website && <span className="text-sm text-Red">{error.website}</span>}
        </div>
        <div className="mb-3">
          <label className="text-darkBlack font-bold capitalize">
            Tell us a little about your company
          </label>
          <textarea
            className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
            rows="7"
            name="bio"
            onChange={onChange}
            ref={bio}
          />
          {error && error.bio && <span className="text-sm text-Red">{error.bio}</span>}
        </div>
        <button
          className=" mt-2 mx-auto bg-Red rounded text-white py-2 px-14 block"
          type="button"
          disabled={loader}
          onClick={() => handleSubmit()}>
          {loader && <Loader />}
          Join Us
        </button>
      </div>
    </>
  );
}
export default AccountCreation;
