import React, { useEffect } from 'react';

import '@react-firebase/auth';
import PrivateRoute from './PrivateRoute/customRoutes';
import { Switch, Route } from 'react-router-dom';
import NotFound from '../Components/NotFound';
import { AuthProvider } from '../Context/AuthContext';



import VideoPlayer from '../Components/VideoPlayer';
import AccountCreation from '../Components/AccountCreation';
import RecipeDetails from '../Components/RecipePage';
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import { GA4React } from 'ga-4-react';

//brand dashbaord
import BrandDashboardRoute from './PrivateRoute/BrandDashboardRoute';

import Products from '../Components/Brand_Dashboard/Products';
import Campaigns from '../Components/Brand_Dashboard/Campaign/CampaignNavTabs';
import Assets from '../Components/Brand_Dashboard/Assets';
import Drafts from '../Components/Brand_Dashboard/Drafts';
import AdminDrafts from '../Components/Admin/Drafts';
import Billings from '../Components/Brand_Dashboard/Billing/Billings';
import CreatorAssets from '../Components/Creator_Dashboard/Assets';
import BrandTasks from '../Components/Brand_Dashboard/Tasks';
import BrandPricing from '../Components/Brand_Dashboard/Pricing';
import Requests from '../Components/Brand_Dashboard/DashRequests';
import CampaignSubNav from "../Components/Brand_Dashboard/Campaign/CampaignSubNav"
import UgcTaskSubNav from '../Components/Brand_Dashboard/UgcTaskSubNav';
import GenerateBriefs from '../Components/Brand_Dashboard/GenerativeBriefs/GenerateBriefs';
//admin
import AdminDashBoardRoute from './PrivateRoute/AdminDashboard';
import Admin from '../Components/Admin';
import AdminAssets from '../Components/Admin/Assets';
import Users from '../Components/Admin/Users';
import AllOrders from '../Components/Admin/Orders';
import AdminTasks from '../Components/Admin/Tasks/Tasks'
import AdminEmail from '../Components/Admin/AdminEmail';

//Creator
import CreatorDashboard from './PrivateRoute/CreatorDashboard';
import Recipes from '../Components/Creator_Dashboard/Recipes';
import CreatorTasks from '../Components/Creator_Dashboard/Tasks';

import Login from '../Components/Login';
import BrandLogin from '../Components/Brand_Dashboard/Login';
import Profile from '../Components/Admin/Profile';
import DProfile from '../Components/Brand_Dashboard/Profile';
import CProfile from '../Components/Creator_Dashboard/Profile';
import Payment from '../Components/Payment';
import CreatorOffers from '../Components/Creator_Dashboard/Offers';
import ProfilePage from '../Components/Creator_Dashboard/ProfilePage';
import MyCreatorTasks from '../Components/Creator_Dashboard/MyTasks';

//test
import CreatorPayout from '../Components/Creator_Dashboard/CreatorPayout';
import StripeConnectSuccess from '../Components/Creator_Dashboard/StripeConnectSuccess';
import Balances from '../Components/Admin/Balances/Balances';
//import AdminDrafts from '../Components/Admin/Drafts/AdminDrafts';
import CreatorLookup from '../Components/Brand_Dashboard/CreatorLookup';
import TiktokConnectSuccess from '../Components/Creator_Dashboard/Onboarding/TiktokConnectSuccess';
import InstagramConnectSuccess from '../Components/Creator_Dashboard/Onboarding/InstagramConnectSuccess';
import OrganizationLogin from '../Components/Organization_Dashboard/OrganizationLogin';
import OrganizationDashboardRoute from './PrivateRoute/OrganizationDashboard';
import OrganizationBrands from '../Components/Organization_Dashboard/OrganizationBrands';
import OrganizationUsers from '../Components/Organization_Dashboard/OrganizationUsers';
import OrganizationBilling from '../Components/Organization_Dashboard/OrganizationBilling';
import BrandUsers from '../Components/Brand_Dashboard/BrandUsers';
import OrganizationSignIn from '../Components/Organization_Dashboard/OrganizationSignIn';
import OrganizationInvite from '../Components/Organization_Dashboard/OrganizationInvite';
import Reviews from '../Components/Brand_Dashboard/Reviews/Reviews';
import CreatorReviews from '../Components/Creator_Dashboard/CreatorReviews';
import BrandInviteSignUp from '../Components/Brand_Dashboard/BrandInviteOnboarding';
import BrandInviteOnboarding from '../Components/Brand_Dashboard/BrandInviteOnboarding';
import CreatorHome from '../Components/Creator_Dashboard/CreatorHome';
import TaskPageInfo from '../Components/UgcTaskPage';
import CreatorDiscovery from '../Components/Admin/CreatorDiscovery';
import CreatorDiscoveryProfile from '../Components/Admin/CreatorDiscoveryProfile';
import MyTasks from '../Components/Creator_Dashboard/MyTasks';
import BrandOnboardingSetup from '../Components/Brand_Dashboard/BrandOnboardingSetup';
import TrialProfilePage from '../Components/Creator_Dashboard/TrialProfilePage';
import RebateLandingPage from '../Components/Customer/RebateLandingPage';
import CustomerRetailCampaign from '../Components/Customer/CustomerRetailCampaign';
import TestAnalytics from '../Components/Brand_Dashboard/TestAnalytics';
import RetailAnalytics from '../Components/Brand_Dashboard/Campaign/CampaignDetails/RetailAnalytics';


const pubnub = new PubNub({
  publishKey: 'pub-c-d5821960-e2e6-4891-93fd-000b4ac349c7',
  subscribeKey: 'sub-c-806c2248-b25e-11eb-b48e-0ae489c2794e',
  uuid: 'generic_uid'
});

export default function index() {
  useEffect(() => {
    const ga4react = new GA4React('G-01PGQQFCHK');
    ga4react.initialize().then(
      (ga4) => {
        ga4.pageview(window.location.pathname);
      },
      (err) => { }
    );
  }, []);

  return (
    <AuthProvider>
      <PubNubProvider client={pubnub}>
        <Switch>
          <CreatorDashboard exact path="/" component={MyTasks} />
          <Route path="/profile/:uid" component={ProfilePage} />
          <Route path="/profile" component={TrialProfilePage} />
          <Route path="/usercreation" component={AccountCreation} />
          <Route path="/dashboard/payment" component={Payment} />
          <Route path="/recipe/:id" component={RecipeDetails} />
          <Route path="/login" component={Login} />
          <Route path="/brand/login" component={BrandLogin} />
          <Route path = "/invite/:id" component = {OrganizationInvite}/>
          <Route path="/brand/invite/:id" component={BrandInviteOnboarding} />
          <Route path="/organization/login" component={OrganizationLogin} />
          <Route path = "/task/info/:id" component = {TaskPageInfo} />
          <Route path = "/trial/sign-up" component = {BrandOnboardingSetup}/>
          <Route path="/test/landing-page" component={RebateLandingPage} />
          <Route path="/test/retail" component={CustomerRetailCampaign} />
          <Route path="/test/analytics" component={RetailAnalytics} />
          <Route path = "/retail-rebate/:campaignId/:influencerId" component = {RebateLandingPage}/>
          <Route path="/retail/receipt/:campaignId/:influencerId/:enrolleId" component={CustomerRetailCampaign} />
          {/* Brand */}
          <BrandDashboardRoute path="/brand" component={BrandTasks} exact />
          <BrandDashboardRoute path="/brand/dashboard/tasks" component={BrandTasks} />
          <BrandDashboardRoute path="/brand/dashboard/pricing" component={BrandPricing} />
          <BrandDashboardRoute path="/brand/dashboard/products" component={Products} />
          <BrandDashboardRoute path="/brand/dashboard/campaigns/:id" component={CampaignSubNav} />
          <BrandDashboardRoute exact path="/brand/dashboard/campaigns" component={Campaigns} />
          <BrandDashboardRoute path="/brand/dashboard/assets" component={Assets} />
          <BrandDashboardRoute path="/brand/dashboard/drafts" component={Drafts} />
          <BrandDashboardRoute path="/brand/dashboard/requests" component={Requests} />
          <BrandDashboardRoute path="/brand/dashboard/billing" component={Billings} />
          <BrandDashboardRoute path="/brand/dashboard/profile" component={DProfile} exact />
          <BrandDashboardRoute path="/brand/dashboard" component={BrandTasks} exact />
          <BrandDashboardRoute path="/brand/dashboard/recipes" component={Recipes} exact />
          <BrandDashboardRoute path="/brand/creatorlookup" component={CreatorLookup} exact />
          <BrandDashboardRoute path="/brand/dashboard/task/:id" component={UgcTaskSubNav} />
          <BrandDashboardRoute path ="/brand/dashboard/users/" component = {BrandUsers} />
          <BrandDashboardRoute path="/brand/dashboard/brief-builder" component={GenerateBriefs} />
          <BrandDashboardRoute path = "/brand/dashboard/reviews" component = {Reviews} />
          <BrandDashboardRoute path="/brand/dashboard/retailAnalytics" component={RetailAnalytics} />
          {/* Admin */}
          <AdminDashBoardRoute path="/admin" component={Admin} exact />
          <AdminDashBoardRoute path="/admin/profile" component={Profile} exact />
          <AdminDashBoardRoute path="/admin/users" component={Users} exact />
          <AdminDashBoardRoute path="/admin/balances" component={Balances} />
          <AdminDashBoardRoute path="/admin/orders" component={AllOrders} exact />
          <AdminDashBoardRoute path="/admin/tasks" component={AdminTasks} exact />
          <AdminDashBoardRoute path="/admin/drafts" component={AdminDrafts} />
          <AdminDashBoardRoute path="/admin/assets" component={AdminAssets} exact />
          <AdminDashBoardRoute path="/admin/email" component={AdminEmail} exact />
          <AdminDashBoardRoute path="/admin/drafts" component={AdminDrafts} exact />
          <AdminDashBoardRoute path="/admin/assets" component={AdminAssets} exact />
          <AdminDashBoardRoute path="/admin/discovery" component={CreatorDiscovery} exact />
          <AdminDashBoardRoute path="/admin/discovery/profile/:handle" component={CreatorDiscoveryProfile} exact />
          {/* Organization */}
          <OrganizationDashboardRoute path="/organization/dashboard/brands/:id" component={OrganizationBrands} />
          <OrganizationDashboardRoute path="/organization/dashboard/users/:id" component={OrganizationUsers} />
          <OrganizationDashboardRoute path="/organization/dashboard/billing/:id" component={OrganizationBilling} />
          <OrganizationDashboardRoute path="/organization/dashboard/:id" component={OrganizationBrands} />
          








          {/* Creator */}

          <CreatorDashboard
            path="/creator/dashboard/cash-out/stripe-success"
            component={StripeConnectSuccess}
          />
          <CreatorDashboard exact path="/creator/dashboard/home" component={CreatorHome} />
          <CreatorDashboard path="/creator/dashboard" component={CreatorTasks} exact />
          <CreatorDashboard path="/creator/dashboard/settings" component={CProfile} exact />
          <CreatorDashboard path="/creator/dashboard/recipes" component={Recipes} />
          <CreatorDashboard path="/creator/dashboard/tasks" component={CreatorTasks} />
          <CreatorDashboard path="/creator/dashboard/cash-out" component={CreatorPayout} />
          <CreatorDashboard path="/creator/dashboard/tik-tok-success/" component={TiktokConnectSuccess} />
          <CreatorDashboard path="/creator/dashboard/instagram-success" component={InstagramConnectSuccess} />
          <CreatorDashboard path="/creator/dashboard/mytasks" component={MyCreatorTasks} />
          <CreatorDashboard path="/creator/dashboard/assets" component={CreatorAssets} />
          <CreatorDashboard path="/creator/dashboard/offers" component={CreatorOffers} />
          <CreatorDashboard path="/creator/dashboard/reviews" component={CreatorReviews} />
          <Route path="*" exact={true} component={NotFound} />
        </Switch>
      </PubNubProvider>
    </AuthProvider>
  );
}
