import React, { useEffect, useState, useCallback } from 'react';
import { TaskService, useTaskState } from '../../Service/TaskServices';
import { useAuth } from '../../Context/AuthContext';
import { toast, Toaster } from 'react-hot-toast';
import Loader from '../Loader';
import { BrandService, useBrandState } from '../../Service/BrandServices';
import SelectPlanModal from '../../Components/Brand_Dashboard/Billing/SelectPlanModal';
import { useCreditPackState, CreditPackService } from '../../Service/CreditPackServices';
import CustomButton from '../../utils/customButton';
function  OrganizationBilling() {
  const taskState = useTaskState();
  const [credits, setCredits] = useState([0]);
  const [addlCredits, setAddlCredits] = useState(0);
  const [addAmpCredits, setAddAmpCredits] = useState(0);
  const [upgrade, setUpgrade] = useState(false);
  const [downgrade, setDowngrade] = useState(false);
  const brandState = useBrandState();
  const [load, setLoad] = useState(false);
  
  const creditPackState = useCreditPackState();
  const { currentUser } = useAuth();
  const [tab, setTab] = useState(0);
  useEffect(() => {
    if (currentUser.uid) {
      BrandService.FetchSingleBrand(currentUser && currentUser.uid);
      CreditPackService.FetchSubscriptionForUser(currentUser.uid);
    }
  }, [creditPackState.updateNeed.value]);

  let user = brandState.brand.value;
  console.log(" user: ", user);
  let creditPack = creditPackState.creditPack.value;
  console.log(brandState.brand.value);
  console.log("creditPack", creditPack);
  console.log("paid usage: ", creditPack && creditPack.paidUsage);
  console.log("current usage: ", creditPack && creditPack.currentUsage);

  useEffect(() => {
    if (brandState.brand.value) {
      user = brandState.brand.value;
    }
  }, [user, brandState.updateNeed.value]);

  useEffect(() => {
    if (creditPackState.videosData.value) {
      let videodata = creditPackState.videosData.value;
      if (videodata.data) {
        if (videodata.data.statuscode !== 400) {
          toast.success('Plan Updated Successfully');
          creditPackState.merge({ videosData: {} });
        } else if (videodata.data.statuscode === 400) {
          toast.error('Unable to update plan, Please contact support');
          creditPackState.merge({ videosData: {} });
        }
      }
    }
  }, [creditPackState.updateNeed.value]);
console.log("payment method", user && user.billing && user.billing.payment_method);

const handleConfirm = async (type) => {
 if (creditPackState.videosData.value) {
   

  try { 
    creditPackState.merge({ loading: true });
    console.log('uid:', currentUser.uid);
    const temp = {};
    if (type === 'amp') {
      temp.quantity = addAmpCredits;
      temp.plan = creditPack && creditPack.amplify && creditPack.amplify.plan
    } else {
      temp.quantity = addlCredits;
    }
    temp.uid = currentUser.uid;
    temp.id = user && user.billing && user.billing.payment_method;
    temp.customerstripeId = user && user.stripe_id;
    temp.subs_price = 'price_1OgUbhJZT64pbPqUAlByu3kZ'; // test price for additional credits
    console.log('requestData:---', temp);
  
    await CreditPackService.IncrementCredits(currentUser.uid, temp);
    
    creditPackState.merge({ loading: true }); // set loading to false after successful API call
    // toast.success('Credits added successfully'); // show success message
  } catch (error) {
    console.log(error);
    creditPackState.merge({ loading: true }); // set loading to false in case of error
    // toast.error('Failed to add credits'); // show error message
  } 
}
} // include all dependencies inside the array

  useEffect(() => {
    TaskService.FetchTasks();
  }, [taskState.updateNeed.value]);
  
  const handleUpgradeClick = () => {
    setUpgrade(true); 
    setTab(1);
  }

  const handleBuyCreditPackClick = () => {
    setUpgrade(true);
    setTab(0); // Set the tab to 0 when the "Buy Credit Pack" button is clicked
  };


  return (
    <div className="my-5  rounded-lg lg:w-full h-full xs:h-fit">
      <Toaster />
        <div className="grid lg:grid-cols-2 grid-cols-1 h-auto md:grid-cols-2 gap-4 mx-2">
          <div className="w-full rounded-lg bg-white text-center">
            <div className='flex flex-col place-items-center w-full rounded-lg bg-white text-center pt-12'>
              <span className="font-black bg-gray-700 rounded-lg px-4 py-2 text-white w-1/3 text-2xl mx-12 mb-1 text-center">CRAFTED AMPLIFY</span>
              <div className='text-center my-8'>
                {
                  creditPack && creditPack.amplify && creditPack.amplify.status && creditPack.amplify.status === 'active'
                    ? (
                      <div className='flex flex-col place-items-center'>
                        <p className='text-3xl'>Current Plan:</p> 
                        <p className=' uppercase font-bold'> {creditPack && creditPack.amplify && creditPack.amplify.plan}</p>
                        <span className='px-4 py-2 my-5 w-1/3 text-center bg-green rounded-lg text-white'>
                          ACTIVE
                        </span>
                       
                        <div>
                          {creditPack && creditPack.amplify && creditPack.amplify.plan ? (
                            <>
                            <span> {creditPack && creditPack.paidUsage && (creditPack.paidUsage - creditPack.currentUsage).toLocaleString()} credits remaining | </span>
                            <span>
                              {creditPack.amplify.plan == 'starter' && '$2.00 for each additional credit'}
                              {creditPack.amplify.plan == 'basic' && '$1.75 for each additional credit'}
                              {creditPack.amplify.plan == 'professional' && '$1.50 for each additional credit'}
                              {creditPack.amplify.plan == 'premium' && '$1.25 for each additional credit'}
                            </span>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <input
                          type='number'
                          className='text-2xl text-center mt-5 w-1/3 bg-white rounded-lg shadow-md text-black px-5'
                          placeholder = {0}
                          step={10}
                          min={10}
                          onChange={(e) => {setAddAmpCredits(e.target.value)}}
                        />
                        {creditPack && creditPack.payment_method &&
                        <CustomButton
                        className="text-white bg-Blue text-xs font-bold rounded-md shadow-md py-2 px-12 mt-5"
                        onClick={() => addlCredits > 0 && !creditPackState.loading.value && handleConfirm('amp')}
                        disabled={creditPackState.loading.value}>
                        {creditPackState.loading.value && <Loader />}  Add Credits
                      </CustomButton>
                      }

                      <span className='mt-2'>${creditPack && (addAmpCredits * ((creditPack.amplify.plan == 'starter' && 2) + (creditPack.amplify.plan == 'basic' && 1.75)+(creditPack.amplify.plan == 'professional' && 1.50)+(creditPack.amplify.plan == 'premium' && 1.25))).toFixed(2)}</span>
                    </div>
                    ) : (
                    <div className='flex flex-col place-items-center mt-10'>
                      <span className='px-4 py-2 rounded-lg text-center bg-gray-500 text-white'>
                        INACTIVE
                      </span>
                      <CustomButton
                        className="text-sm text-white bg-Blue font-bold rounded-md shadow-md py-2 px-12 mt-5"
                        onClick={handleUpgradeClick}>
                        Get Crafted Amplify
                      </CustomButton>
                    </div>
                    )
                }
              </div>
            </div>
          </div>
             <div className="flex flex-col place-items-center w-full rounded-lg bg-white text-center pt-12">
            <span className="font-black bg-gray-700 rounded-lg px-4 py-2 text-white w-1/3 text-2xl mx-12 mb-1 text-center">CRAFTED STUDIO</span>
            <button
                className=" w-1/2 text-white bg-Blue text-sm font-bold rounded-md shadow-md py-2 px-12 mt-5"
                onClick={handleBuyCreditPackClick}>
                Buy Credit Pack
            </button>
           {creditPack && creditPack.payment_method && 
           <> <p className="my-10"> {creditPack && creditPack.paidUsage && (creditPack.paidUsage - creditPack.currentUsage).toLocaleString()} credits remaining | $2.00 for each additional credit</p>
            <div className="flex flex-col place-items-center w-full my-2">
              <div className="credit-count m-auto font-bold rounded-md shadow-md py-2">
                <input
                  type='number'
                  className='text-2xl bg-white text-center w-full text-black px-5'
                  placeholder = {0}
                  step={10}
                  onChange={(e) => {setAddlCredits(e.target.value)}}
                />
              </div>
            </div>
        
            <button
              className="ml-3 w-1/3 text-white bg-Blue text-xs font-bold rounded-md shadow-md py-2 px-12 mt-5"
              onClick={() => addlCredits > 0 && !creditPackState.loading.value && handleConfirm('other')}
              disabled={creditPackState.loading.value} // disable the button when loading
            >
              {creditPackState.loading.value && <Loader />} Add Credits
            </button> 
               
              <span className='mt-2'>${creditPack && (addlCredits * 2).toFixed(2)}</span>
            </>}
            <p className='font-bold mt-4'>For Crafted Studio</p>
            <p className='px-4 pb-4'>Videos (300 credits each), Recipe (50 credits each), Review (30 credits each), Set of 3 Product Photos (25 credits each) </p>
          </div> 
        </div>

       <div className='my-10 flex flex-col items-center justify-center relative'>
          {/*<h1 className="mb-4 2xl:text-3xl text-base font-bold  ">Allocate Credits to Brand</h1>
          <select className="block w-1/2 bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded shadow focus:outline-none focus:shadow-outline">
            <option value="" disabled selected hidden>Select Brand</option>
            <option value="1">Brand A</option>
            <option value="2">Brand B</option>
            <option value="3">Brand C</option>
          </select>
          <input type="number" className="block w-48 mt-4 bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded shadow-inner focus:outline-none focus:shadow-outline" placeholder="Enter Credits" />
          <button onClick={ () => toast.success('Credits allocated')} className="mt-4 bg-red hover:bg-red-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Add Credits</button>*/}
          <div className="m-4 p-4 left-0 bottom-0">
            {' '}
            Email us at alliana@usecrafted.com for help with any billing issues.
          </div>
        </div>
        {/*<div className="bg-white rounded-lg p-4 ">
          <BillingHistory />
         </div>*/}
      {upgrade && <SelectPlanModal close={() => setUpgrade(false)} open={upgrade} tab={tab} setTab={setTab} />}
    </div>
  );
}

export default OrganizationBilling;
