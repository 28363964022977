import React, { useState, useContext } from 'react';
import { GenerativeBriefService, useGenerativeBriefState } from '../../../Service/GenerativeBriefService';
import { Toaster, toast } from 'react-hot-toast';
import { BrandContext } from '../../../routes/PrivateRoute/BrandDashboardRoute';
function GenerateBriefResultOnboarding({  step, setBriefCreated,  setStep, briefId, setBriefData, briefData = {}, setActiveTab  }) {
  const [name, setName] = useState(briefData.name || "");
  const [brandDescription, setBrandDescription] = useState(briefData.brand_description || "");
  const [campaignDescription, setCampaignDescription] = useState(briefData.campaign_description || "");
  const [targetAudience, setTargetAudience] = useState(briefData.target_audience || "");
  const [usp, setUsp] = useState(briefData.usp || "");
  const [brandPositioning, setBrandPositioning] = useState(briefData.brand_positioning || "");
  const [videoConcept, setVideoConcept] = useState(briefData.video_concept || "");
  const [videoDos, setVideoDos] = useState(briefData.video_dos || "");
  const [videoDonts, setVideoDonts] = useState(briefData.video_donts || "");
  const [exampleVideos, setExampleVideos] = useState(briefData.example_videos || "");
  const [id, setId] = useState(briefData.id || "");
  const {brandId} = useContext(BrandContext);
  const [productName, setProductName] = useState(briefData.name || "");
  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      brand_id: brandId,
      brief_id: id,
      data : {
      name: name,
      product_name: productName,
      brand_description: brandDescription,
      campaign_description: campaignDescription,
      target_audience: targetAudience,
      usp,
      brand_positioning: brandPositioning,
      video_concept: videoConcept,
      video_dos: videoDos,
      video_donts: videoDonts,
      example_videos: exampleVideos
      }
      
      
    }
    GenerativeBriefService.updateBriefTemplate(briefData.id, data )
    toast.success('Brief saved successfully!');

    setTimeout(() => {
      setActiveTab(3);
      setBriefCreated(true);
      setBriefData(briefData);
    }, 2000);
  };

  const handleDelete = () => {
    let data = {
      brand_id: brandId,
    }
    GenerativeBriefService.deleteBriefTemplate(briefData.id, data);
    setStep(0)
    setBriefCreated(false);
  }
  console.log("brief data: ", briefData);
  return (
    <div className='' >
      <Toaster/>
         <h1 className="2xl:text-2xl md:text-base font-semibold text-center mt-2 text-gray-800 mb-4">Edit Creative Brief</h1>
        <hr />
        <p className='text-gray-500 2xl:text-base md:text-0.7 text-center my-2'>We’ve leveraged high performing briefs and your details to generated a creative brief that will yield high-converting content!</p>
      <div className="p-3 mx-4 generateBriefResult md:text-0.7 2xl:text-2xl component-loaded slide-in-right" >
      <form onSubmit={handleSubmit} className="flex flex-col justify-between" style={{ height: 'calc(95vh - 10rem)' }}>
        <div className="overflow-auto">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Brief Name:
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              value = {name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Product Name (pronunciation):
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="brandDescription">
              Brand Description:
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="brandDescription"
              value={brandDescription}
              onChange={(e) => setBrandDescription(e.target.value)}
            ></textarea>
          </div>
          {/* Add the rest of the form fields here */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="campaignDescription">
              Campaign Description:
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="campaignDescription"
              value={campaignDescription}
              onChange={(e) => setCampaignDescription(e.target.value)}
            ></textarea>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="targetAudience">
              Target Audience:
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="targetAudience"
              type="text"
              value={targetAudience}
              onChange={(e) => setTargetAudience(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="usp">
              Unique Selling Point:
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="usp"
              type="text"
              value={usp}
              onChange={(e) => setUsp(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="brandPositioning">
              Brand Positioning:
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="brandPositioning"
              type="text"
              value={brandPositioning}
              onChange={(e) => setBrandPositioning(e.target.value)}
            ></textarea>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="videoConcept">
              Video Concept:
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="videoConcept"
              value={videoConcept}
              onChange={(e) => setVideoConcept(e.target.value)}
            ></textarea>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="videoDos">
              Video Dos:
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="videoDos"
              value={videoDos}
              onChange={(e) => setVideoDos(e.target.value)}
            ></textarea>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="videoDonts">
              Video Donts:
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="videoDonts"
              value={videoDonts}
              onChange={(e) => setVideoDonts(e.target.value)}
            ></textarea>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="exampleVideos">
              Example Videos:
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="exampleVideos"
              value={exampleVideos}
              onChange={(e) => setExampleVideos(e.target.value)}
            ></textarea>
          </div> </div>
          <div className='flex mt-5 flex-row gap-10 items-center justify-center'>
    <button
      type="submit"
      className="bg-Blue text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
    >
      Save Changes
    </button>
    <button
    type='button'
            className="text-gray hover:underline font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={handleDelete}
          >
          Start Over
    </button>
  </div>
       
        </form>
    
      </div>
    </div>
  );
}

export default GenerateBriefResultOnboarding;