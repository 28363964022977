import React from 'react';
import Chart from 'react-apexcharts';
import {
  ComposableMap,
  Geographies,
  Geography,
  Annotation,
} from "react-simple-maps";
import { scaleLinear } from 'd3-scale';
import Multiselect from 'multiselect-react-dropdown';

function RetailAnalytics() {
  const cities = [
    { location: "Washington, DC", views: 47591, clicks: 15000, signUps: 8000, sales: 427, conv: "0.89%", coordinates: [-77.0369, 38.9072] },
    { location: "Knoxville, TN", views: 40123, clicks: 12000, signUps: 7000, sales: 422, conv: "1.05%", coordinates: [-83.9207, 35.9606] },
    { location: "Fresno, CA", views: 36456, clicks: 11000, signUps: 6000, sales: 523, conv: "1.43%", coordinates: [-119.7871, 36.7378] },
    { location: "San Francisco, CA", views: 33002, clicks: 10000, signUps: 5000, sales: 201, conv: "0.61%", coordinates: [-122.4194, 37.7749] },
    { location: "Nashville, TN", views: 29883, clicks: 9000, signUps: 4500, sales: 438, conv: "1.47%", coordinates: [-86.7816, 36.1627] },
    { location: "Raleigh, NC", views: 24333, clicks: 8000, signUps: 4000, sales: 352, conv: "1.45%", coordinates: [-78.6382, 35.7796] },
    { location: "Durham, NC", views: 24234, clicks: 7500, signUps: 3800, sales: 277, conv: "1.14%", coordinates: [-78.8986, 35.9940] },
    { location: "Charlottesville, VA", views: 22112, clicks: 7000, signUps: 3500, sales: 199, conv: "0.90%", coordinates: [-78.4767, 38.0293] },
    { location: "Alexandria, VA", views: 21343, clicks: 6800, signUps: 3400, sales: 178, conv: "0.83%", coordinates: [-77.0469, 38.8048] },
    { location: "Baltimore, MD", views: 19877, clicks: 6400, signUps: 3200, sales: 165, conv: "0.83%", coordinates: [-76.6122, 39.2904] }
  ];

  const influencers = [
    {id: "00001", name: "@dannydeferrari", location: "Washington, DC", views: 47591, clicks: 15000, signUps: 8000, sales: 427, conv: "0.89%"},
    {id:"00002", name: "@thepointsguy", location: "Knoxville, TN", views: 40123, clicks: 12000, signUps: 7000, sales: 422, conv: "1.05%"},
    {id:"00003", name: "@tatemcrae", location: "Fresno, CA", views: 36456, clicks: 11000, signUps: 6000, sales: 523, conv: "1.43%"},
    {id: "00004", name: "@paulapareto", location: "San Francisco, CA", views: 33002, clicks: 10000, signUps: 5000, sales: 201, conv: "0.61%"},
    {id: "00005", name: "@simone_biles", location: "Nashville, TN", views: 29883, clicks: 9000, signUps: 4500, sales: 438, conv: "1.47%"},
    {id: "00006", name: "@liampayne", location: "Raleigh, NC", views: 24333, clicks: 8000, signUps: 4000, sales: 352, conv: "1.45%"},
    {id: "00007", name: "@billnye", location: "Durham, NC", views: 24234, clicks: 7500, signUps: 3800, sales: 277, conv: "1.14%"},
    {id: "00008", name: "@amyschumer", location: "Charlottesville, VA", views: 22112, clicks: 7000, signUps: 3500, sales: 199, conv: "0.90%"},
    {id: "00009", name: "@katyperry", location: "Alexandria, VA", views: 21343, clicks: 6800, signUps: 3400, sales: 178, conv: "0.83%"},
    {id: "00010", name: "@neilhimself", location: "Baltimore, MD", views: 19877, clicks: 6400, signUps: 3200, sales: 165, conv: "0.83%"}
  ];

  const retailers = [
    { name: "Walmart", color: "bg-blue-400" },
    { name: "Target", color: "bg-blue-300" },
    { name: "Whole Foods", color: "bg-blue-200" }
  ];
  
  const exportContactsOptions = [
    { name: "Mailchimp", icon: "https://s3.amazonaws.com/www-inside-design/uploads/2018/10/mailchimp-sq-810x810.jpg"},
    { name: "Klaiviyo", icon: "https://pageflows.imgix.net/media/logos/klaviyo.jpg?auto=compress&ixlib=python-1.1.2&s=68e5f17351ff821bf95e84441d3487e0"},
    { name: "Hubspot", icon: "https://akaunting.com/public/assets/media/206-sokian/hubspot/hubspot-logo.jpg"},
    { name: "csv", icon: "https://www.computerworld.com/wp-content/uploads/2024/07/cw_microsoft_office_365_excel-100787147-orig-1.jpg?quality=50&strip=all"}
    
  ];
  const dtrFunnelOptions = {
    chart: {
      type: "bar",
      animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
              enabled: true,
              delay: 150
          },
          dynamicAnimation: {
              enabled: true,
              speed: 350
          }
      }
    },
    grid: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top", // show labels on top of bars
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toLocaleString(); // Format numbers with commas
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      categories: ["Views", "Clicks", "Sign-Ups", "Sales"],
      position: "bottom",
      labels: {
        offsetY: 0,
      },
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
    },
    /*yaxis: {
      labels: {
        formatter: function (val) {
          return val.toLocaleString(); // Format numbers with commas
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    title: {
      text: "Funnel Metrics",
      floating: false,
      offsetY: 10,
      align: "center",
      style: {
        color: "#444",
      },
    },*/
  };

  const dtrFunnelData = [298954, 92700, 48400, 3182];
  const dtrFunnelSeries = [{
    name:'Totals', 
    data: [298954, 92700, 48400, 3182]
  }];

  const retailerBreakdownOptions = {
    chart: {
      type: 'donut',
    },
    labels: retailers.map(retailer => retailer.name),
  };

  const retailerBreakdownData = [30, 40, 30]; // Example data

  const purchaseIntentOptions = {
    chart: {
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        hollow: {
          margin: 15,
          size: '70%',
        },
      },
    },
  };

  const purchaseIntentData = [75]; // Example value
  const customOptionRenderer = (option) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img style={{ marginRight: 8, width: "2rem", height: "2rem", objectFit: "cover"}} src={option.icon}/>
        <span>{option.name}</span>
      </div>
    );
  };

  // Color scale for map annotations
  const colorScale = scaleLinear()
    .domain([0, Math.max(...cities.map(city => city.sales))])
    .range(["#ADD8E6", "#0000FF"]); // Light blue to dark blue

  return (
    <div className="flex flex-col items-center justify-center min-h-screen w-full bg-lightGray">
      <div className="w-full max-w-full p-6 bg-lightGray">
        <div className="p-2 flex justify-between items-center bg-white shadow-md mb-4 z-40">
          <h1 className="text-xl font-bold mr-8 text-center">Export Contacts</h1>
          <Multiselect 
            options={exportContactsOptions} 
            customValueDecorator={customOptionRenderer}
            displayValue="name" 
            placeholder="Select Export Option"
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">

          {/* Panel 1 - Overall Metrics */}
          <div className="p-4 border shadow-md bg-white flex flex-col justify-center items-center">
            <h2 className="text-center font-bold text-2xl mb-4">Campaign Performance Metrics</h2>
            <div className="grid mt-10 gap-x-6 gap-y-10 text-center grid-cols-2">
              <div className="p-4">
                <p className="font-bold text-2xl mb-2">ROAS</p>
                <h3 className="font-black text-4xl mb-1">1.7x</h3>
              </div>
              <div className="p-4">
                <p className="font-bold text-2xl mb-2">SALES</p>
                <h3 className="font-black text-4xl mb-1">{dtrFunnelData[3].toLocaleString()}</h3>
              </div>
              <div className="p-4">
                <p className="font-bold text-2xl mb-2">CTR</p>
                <h3 className="font-black text-4xl mb-1">{(dtrFunnelData[1]/dtrFunnelData[0] * 100).toFixed(1)}%</h3>
              </div>
              <div className="p-4">
                <p className="font-bold text-2xl mb-2">CAC</p>
                <h3 className="font-black text-4xl mb-1">$8.45</h3>
              </div>
            </div>
          </div>

          <div className="p-4 border shadow-md bg-white flex flex-col justify-center items-center">
            <h2 className="text-center font-bold text-2xl mb-4">Campaign Funnel Performance</h2>
            <div className="flex justify-center items-center">
              <Chart options={dtrFunnelOptions} series={dtrFunnelSeries} type="bar" width="500px" />
            </div>
          </div>
          
          <div className="p-4 border shadow-md bg-white flex flex-col justify-center items-center">  
            <h2 className="text-center font-bold text-2xl mb-4">Campaign Performance by Geography</h2>
            <ComposableMap projection="geoAlbersUsa" width={800} height={500}>
              <Geographies geography="https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json">
                {({ geographies }) =>
                  geographies.map(geo => (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill="#D3D3D3"
                      stroke="#FFFFFF"
                    />
                  ))
                }
              </Geographies>
              {cities.map((city, index) => (
                <Annotation
                  key={index}
                  subject={city.coordinates}
                  dx={0}
                  dy={0}
                >
                  <circle
                    cx={0}
                    cy={0}
                    r={20} // Adjust the radius as needed
                    fill={colorScale(city.sales)} // Use the color scale for the fill color
                    stroke="#0000FF"
                    strokeWidth={2}
                  />
                </Annotation>
              ))}
            </ComposableMap>
          </div>

          <div className="p-4 border shadow-md bg-white h-{500px} flex flex-col justify-center items-center">
            <h2 className="text-center font-bold text-2xl mb-4">Campaign Performance by City</h2>
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left text-base">Location</th>
                  <th className="text-left text-base">Views</th>
                  <th className="text-left text-base">Sales</th>
                  <th className="text-left text-base">Conv.</th>
                </tr>
              </thead>
              <tbody>
                {cities.map((city, index) => (
                  <tr key={index}>
                    <td className="flex items-center text-left text-base">
                      <div className={`w-4 h-4 bg-Blue rounded-full mr-2`} style={{ opacity: 1 - (index * 0.1) }}></div>
                      {city.location}
                    </td>
                    <td className='text-left text-base'>{city.views}</td>
                    <td className='text-left text-base'>{city.sales}</td>
                    <td className='text-left text-base'>{city.conv}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="p-4 border shadow-md bg-white flex flex-col justify-center items-center">
            <h2 className="text-center font-bold text-2xl mb-4">Campaign Performance by Influencer</h2>
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left text-base">Influencer</th>
                  <th className="text-left text-base">Views</th>
                  <th className="text-left text-base">Sales</th>
                  <th className="text-left text-base">Conv.</th>
                </tr>
              </thead>
              <tbody>
                {influencers.map((influencer, index) => (
                  <tr key={index}>
                    <td className="flex items-center text-left text-base">
                      <div className={`w-4 h-4 bg-Blue rounded-full mr-2`} style={{ opacity: 1 - (index * 0.1) }}></div>
                      {influencer.name}
                    </td>
                    <td className='text-left text-base'>{influencer.views}</td>
                    <td className='text-left text-base'>{influencer.sales}</td>
                    <td className='text-left text-base'>{influencer.conv}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default RetailAnalytics;
