import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Layout from '../../Layout/Dashboard/Brand';
import { useAuth } from '../../Context/AuthContext';
import { AccountService, useAccountState } from '../../Service/AccountServices';
import { BrandService, useBrandState } from '../../Service/BrandServices';
import TrialLayout from '../../Layout/Dashboard/BrandTrial';
import Loader from '../../Components/Loader2';
export const BrandContext = React.createContext({
  brandId: "",
  setBrandId: () => {}
});

export function BrandDashboardRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();
  const [loadingAccount, setLoadingAccount] = useState(true);
  const [loadingBrand, setLoadingBrand] = useState(true);
  const [brandId, setBrandId] = useState(() => localStorage.getItem('brandId') || "");
  const value = { brandId, setBrandId };
  const accountState = useAccountState();
  
  useEffect(() => {
    const fetchAccountInfo = async () => {
      if (currentUser) {
        setLoadingAccount(true);
        try {
          await AccountService.FetchAccountInfo(currentUser.uid);
        } catch (error) {
          console.error("Error fetching account info:", error);
        } finally {
          setLoadingAccount(false);
        }
      }
    };
  
    fetchAccountInfo();
  }, [currentUser]);
  
  const brandState = useBrandState();
  const brand = brandState.brand.value;
  const account = accountState.account.value;
  
  useEffect(() => {
    if (!loadingAccount && account && account.organizations && (!brandId || brandId === '0')) {
      const brands = account.organizations && Object.values(account.organizations).map(org => org.brands)[0];
      const defaultBrandId = brands && Object.keys(brands)[0];
      if (defaultBrandId) {
        setBrandId(defaultBrandId);
        localStorage.setItem('brandId', defaultBrandId); // Store the brandId in localStorage
      }
    } else if (!loadingAccount && account && !account.organizations && account.brands && (!brandId || brandId === '0')) {
      const brands = account.brands;
      const defaultBrandId = brands && Object.keys(brands)[0];
      if (defaultBrandId) {
        setBrandId(defaultBrandId);
        localStorage.setItem('brandId', defaultBrandId); // Store the brandId in localStorage
      }
    }
  }, [loadingAccount, account]);
  
  useEffect(() => {
    const fetchBrandInfo = async () => {
      if (brandId) {
        setLoadingBrand(true);
        try {
          await BrandService.FetchSingleBrand(brandId);
        } catch (error) {
          console.error("Error fetching brand info:", error);
        } finally {
          setLoadingBrand(false);
        }
      }
    };
  
    fetchBrandInfo();
  }, [brandId]);
  
  if (loadingAccount || loadingBrand) {
    return <div className='flex flex-col justify-center items-center h-screen'>
      <Loader/>
    <h1 className='my-2'>Loading...</h1></div>; // Simple loading screen
  }
  
  return (
    <Route
    {...rest}
    render={(props) => {
      if (account && account.trial === true) {
        return (
          <BrandContext.Provider value={value}>
            <TrialLayout>
              <Component {...props} />
            </TrialLayout>
          </BrandContext.Provider>
        );
      } else if (account) {
        return (
          <BrandContext.Provider value={value}>
            <Layout>
              <Component {...props} />
            </Layout>
          </BrandContext.Provider>
        );
      } else {
        return <Redirect to="/login" />;
      }
    }}
  />
  );
}

export default BrandDashboardRoute;