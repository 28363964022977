import React, { useContext, useEffect, useState } from 'react'
import GlobalDialog from '../../../utils/globalDialog';
import { BsExclamationCircle } from 'react-icons/bs';
import { TaskService } from '../../../Service/TaskServices';
import { CampaignService, useCampaignState } from '../../../Service/CampaignServices';
import { useAuth } from '../../../Context/AuthContext';
import { useTabState } from '../../../utils/TabState';
import Modal from 'react-responsive-modal';
import { useParams } from 'react-router-dom';
import {toast, Toaster} from 'react-hot-toast';
import { BrandContext } from '../../../routes/PrivateRoute/BrandDashboardRoute';

function DeleteInfluencerTaskModal(props) {
  const { currentUser } = useAuth();
  const campaignStateData = useCampaignState();
  const [loading, setLoading] = useState(false);
  const tabState = useTabState();
  const {id} = useParams();
  const { brandId } = useContext(BrandContext);
  
  const fetchData = async () => {
    if (id) {
      setLoading(true);
      const campaignData = await CampaignService.FetchSingleCampaign(id);
      setLoading(false);
  
      if (campaignData) {
        const tasks = Object.keys(campaignData.tasks).length !== 0 ? Object.values(campaignData.tasks) : [];
        // Use the tasks data as needed
        
        // Assign the tasks to the state or perform any other logic with the tasks data
      }
    }
  };
  
  useEffect(() => {
    fetchData();
  }, [id]);
    const deleteTask = async () => {
    campaignStateData.merge({loading: true});
    const data = {
      campaign_id: id,
      task_id: props.task.id,
      brand_id: brandId
    }
    await CampaignService.deleteTask(props.task.id, data);
    toast.success("Task Edited Successfully", {duration: 1500});
        props.reload();
        props.onClose();
        window.location.reload();
  }

  return (
    <Modal
    open = {props.open}
    onClose={props.onClose}
    center
    classNames={{ modal: 'customModal' }}
    closeOnOverlayClick={true}>
      <Toaster/>
      <div className=" bg-white rounded-lg dark:bg-gray-700">
        <div className="p-6 pt-0 text-center">
          <BsExclamationCircle className="mx-auto mb-4 w-32 h-14 text-gray-400 dark:text-gray-200" />
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            Are you sure you want to delete task <span className='font-bold'>{props.task.name}</span>?
          </h3>
          <button
            type="button"
            className="text-white bg-red hover:bg-red-800  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
            onClick={() => deleteTask()}>
            Yes, I'm sure
          </button>
          <button
            type="button"
            className="text-gray-500 bg-white hover:bg-gray-100  rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600"
            onClick={() => props.onClose()}>
            No, cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteInfluencerTaskModal