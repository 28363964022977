import React from 'react';
import OrdersTable from './OrdersTable';
import { useUserState , UsersService} from '../../Service/userServices';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
function Orders() {
  const userState = useUserState();
  const history = useHistory();


  useEffect(() => {
    let i = 0;
    const checkAdmin = async () => {
      if ((userState.user.value && userState.user.value.admin === true) || i === 0) {
        i++
      } else {
        history.push('/');
      }
    };
  
    if (userState.user.value) {
      checkAdmin();
    }
  }, [userState.user.value, history]);
  return (
    <div className="bg-white m-4 rounded-lg w-full p-6">
      <h1 className="font-semibold py-2 text-2xl"> All Orders</h1>
      <OrdersTable />
    </div>
  );
}

export default Orders;
