import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';

//State
const state = createState({
  templates: [],
  loading: false,
  updateNeed: true,
  template: null
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'SEND_EMAIL':
        return s.merge({
          template: action.data,
          loading: false,
          updateNeed: true,
        });
      case 'CREATE TEMPLATE':
        return s.merge({
          updateNeed: false,
          loading: false
        });
      case 'TIKTOK_CODE_BRAND': 
        return s.merge({
          updateNeed: false,
          loading: false
        });
      case 'TIKTOK_CODE_CREATOR':
        return s.merge({
          updateNeed: false,
          loading: false
        });
      case 'IN_STORE_FULFILLMENT':
        return s.merge({
          updateNeed: false,
          loading: false
        });
        case 'IN_STORE_FULFILLMENT':
          return s.merge({
            updateNeed: false,
            loading: false
          });
        case 'PROMO_FULFILLMENT':
        return s.merge({
          updateNeed: false,
          loading: false
        });
        case 'SHIP_FULFILLMENT':
          return s.merge({
            updateNeed: false,
            loading: false
          });
      case 'GET ALL TEMPLATE':
        return s.merge({
          templates: action.data,
          updateNeed: true,
          loading: false
        });

      case 'INVITE_BRAND_USER':
        return s.merge({
          updateNeed: false,
          loading: false
        }); 

      default:
        return null;
    }
  }, action.type);
});

export const accessEmailState = () => state;

export const useEmailState = () => useState(state);

//Service
export const EmailService = {
  SendEmail: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/email/send`, data)
      .then((res) => {
        dispatch(EmailAction.sendEmail(res));
      })
      .catch((err) => { });
  },sendCreatorEmail: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/email/sendCreator`, data)
      .then((res) => {
        dispatch(EmailAction.sendEmail(res));
      })
      .catch((err) => { });
  },
  AddTemplate: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/admin/create`, data)
      .then((res) => {
        dispatch(EmailAction.createTemplate());
      })
      .catch((err) => { });
  },
  getAllTemplate: async () => {
    const dispatch = useDispatch();
    await axios
      .get(`${baseUrl}api/admin/templates`)
      .then((res) => {
        dispatch(EmailAction.getAllTemplates(res.data));
      })
      .catch((err) => { });
  },
  sendTiktokCodeEmailCreator: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/email/sendTikTokEmailCreator`, data)
      .then((res) => {
        dispatch(EmailAction.sendEmail(res));
      })
      .catch((err) => { });
  },

  sendTiktokCodeEmailBrand: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/email/sendTikTokEmailBrand`, data)
      .then((res) => {
        dispatch(EmailAction.sendEmail(res));
      })
      .catch((err) => { });
  },

  inStoreFullfillmentEmail: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/email/inStoreFulfillment`, data)
      .then((res) => {
        dispatch(EmailAction.sendEmail(res));
      })
      .catch((err) => { });
  },
  promoFulfillmentEmail: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/email/promoFulfillment`, data)
      .then((res) => {
        dispatch(EmailAction.sendEmail(res));
      })
      .catch((err) => { });
  },
  shipFulfillmentEmail: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/email/shipFulFillment`, data)
      .then((res) => {
        dispatch(EmailAction.sendEmail(res));
      })
      .catch((err) => { });
  },
  inviteBrandUser: async (id, data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/email/inviteBrandUser/${id}`, data)
      .then((res) => {
        dispatch(EmailAction.inviteBrandUser(res));
      })
      .catch((err) => { });
  }

};

//Action
export const EmailAction = {
  sendEmail: (data) => {
    return {
      type: 'SEND_EMAIL',
      data: data
    };
  },
  createTemplate: () => {
    return {
      type: 'CREATE TEMPLATE',
    };
  },

  getAllTemplates: (data) => {
    return {
      type: 'GET ALL TEMPLATE',
      data: data
    };
  },

  sendTiktokCodeEmailCreator: (data) => {
    return {
      type: 'TIKTOK_CODE_CREATOR',
      data: data
    };
  },
  sendTiktokCodeEmailBrand: (data) => {
    return {
      type: 'TIKTOK_CODE_BRAND',
      data: data
    };
  },
  inStoreFulfillmentEmail: (data) => {
    return {
      type: 'IN_STORE_FULFILLMENT',
      data: data
    };
  },
  promoFulfillmentEmail: (data) => {
    return {
      type: 'PROMO_FULFILLMENT',
      data: data
    };
  },
  shipFulfillmentEmail: (data) => {
    return {
      type: 'SHIP_FULFILLMENT',
      data: data
    };
  },
  inviteBrandUser: (data) => {
    return {
      type: 'INVITE_BRAND_USER',
      data: data
    };
  }
};
