import React, {useState, useEffect, useRef} from 'react'
import { useAuth } from '../../../Context/AuthContext';
import { toast, Toaster } from 'react-hot-toast';
import { AuthService, userAuthstate } from '../../../Service/authenticationService';
import { useUserState, UsersService } from '../../../Service/userServices';
import {FiAtSign} from 'react-icons/fi'
import { InstagramService } from '../../../Service/InstagramService';
function InstagramOnboarding(props) {
  const socialHandle = useRef(null);
	const [errorMessage, setErrorMessage] = useState('');
	const {currentUser} = useAuth();
	const userState = useUserState();
  const startInstagramLoginFlow = () =>{
    const clientId = process.env.REACT_APP_FACEBOOK_APP_CLIENT_ID;
    const redirectUri = "https://www.app.cookwithcrafted.com/creator/dashboard/instagram-success";
    const scope = "instagram_basic,instagram_manage_insights,pages_show_list,pages_read_engagement,business_management";
    const display  = "page";
    const extras = {"setup":{"channel":"IG_API_ONBOARDING"}} 
    const responseType = "token"; 
    const authUrl = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${clientId}&display=${display}&extras=${extras}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}`;
  
    window.open(authUrl, '_blank') 
    props.close();
  }


  return (
	
    <div className='flex flex-col mt-10 h-fit bg-white w-full xl:text-xs pl-8'>
      <Toaster/>
      <h3 className='md:text-3xl text-xs font-extrabold mx-auto text-center mb-4'>3. Connect your Instagram account</h3>
        <p className='font-thin italic xl:text-3xl text-xs text-gray-600 tracking-tighter'>In order to be eligible for influencer briefs that require an Instagram Account, you must first connect your Instagram Account to Crafted.</p>
        <div className="flex flex-col mt-4 items-start">
          <p className='my-5'>Before connecting your account, please ensure you have the following requirements:</p>
          <ul className='list-disc'>
              <li>You must have a Facebook Page for your creator account (not a personal profile).</li>
              <li>You must have a Instagram Business Account or Instagram Creator Account.</li>
              <li>You must connect your Facebook Page (for your creator account) to your Instagram Business/Creator Account.</li>
          </ul>
          <p className='my-5'>If you do not have these requirements, please visit the links below for instructions:</p>
          <div className='space-y-5 text-left'>
            <p><a href="https://help.instagram.com/2358103564437429" target = "_blank" rel="noreferrer" className='text-blue underline hover:underline'>How to set up a creator account on Instagram</a></p>
            <p><a href="https://www.facebook.com/business/help/473994396650734?id=939256796236247" target = "_blank" rel="noreferrer"    className='text-blue underline hover:underline'>Create a Facebook Page for your business (creator page)</a></p>
            <p><a href="https://help.instagram.com/570895513091465" target = "_blank" rel="noreferrer" className='text-blue underline hover:underline'>Add or change the Facebook Page connected to your Instagram professional account</a></p>
          </div>
        <button className=' mx-auto font-bold flex flex-row bg-gradient-to-t from-purple to-Orange mt-10 text-white rounded-md py-4 px-10' type="submit" onClick={() => startInstagramLoginFlow()}> <div className='instagram w-5 h-5 mr-3 mt-0.5'></div>Connect to Instagram</button>
      </div>
    </div>
  )
}

export default InstagramOnboarding