import React, { useState, useEffect } from 'react';
import GlobalDialog from '../../../utils/globalDialog';
import moment from 'moment-timezone';
import { BsChevronCompactDown, BsChevronCompactUp } from 'react-icons/bs';


import Collapsible from 'react-collapsible';
import { Link } from 'react-router-dom';

function ProposalModal({ open, onClose, id, task }) {
  const [tab, setTab] = useState(0);
  const timezone = moment.tz.guess();
  var task_deadline_time = moment(task.deadline).tz(timezone).format('MMM. D, YYYY');
  const [confirm, setConfirm] = useState(false);
  const [url, setUrl] = useState('');


  const StringChecker = (text) => {
    let formattedText = text;
    if (formattedText[0].length > 50) {
      formattedText[0] = formattedText[0].substring(0, 50) + '...';
    }
    return (formattedText[0]);
  };



  function extractBeforeAndAfterURL(str) {
    const regex = /(.*?)(https?:\/\/[^\s]+)/;
    const match = str.match(regex);

    if (match && match.length >= 3) {
      const beforeURL = match[1];
      const afterURL = str.substring(match[1].length + match[2].length);
      return { beforeURL, afterURL };
    }

    return { beforeURL: str, afterURL: '' };
  }
  const covertingString = () => {
    const regex = /(https?|ftp):\/\/[^\s/$.?#].[^\s]*/i;
    if (task && task.talking_points) {
      if (regex.test(task.talking_points)) {
        let extractedUrl = task.talking_points.match(/\bhttps?:\/\/\S+/gi);
        let visualLink = StringChecker(extractedUrl);
        const { beforeURL, afterURL } = extractBeforeAndAfterURL(task.talking_points);
        return (
          <div className='break-words max-w-full'>
            {beforeURL}
            <p className="max-w-full text-blue" >
              <a className='text-blue hover:underline cursor-pointer' href={task.talking_points.match(/\bhttps?:\/\/\S+/gi)[0]} target="_blank" >{visualLink}</a>
            </p>
            {afterURL}
          </div>
        )
      }
      else {
        return (
          <div>
            {task.talking_points}
          </div>
        )
      }
    }
  }

  return (
    <div>
      {tab === 0 ? (
        <div>
          <GlobalDialog className='p-2 w-2/3' open={open} onClose={onClose}>
            <Collapsible
              trigger={[
                'Brief Details',
                <div className="px-3 absolute right-0">
                  <BsChevronCompactDown />
                </div>
              ]}
              triggerWhenOpen={[
                'Brief Details',
                <div className="px-3 absolute right-0">
                  <BsChevronCompactUp />
                </div>
              ]}
              contentOuterClassName="mb-6"
              triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
              triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
            </Collapsible>
            <div className="m-1 bg-white rounded-lg text-3xl dark:bg-gray-700 p-5">
              <div className="flex ml-7 mb-5">
                <div className="flex mr-5 font-bold">Brand:</div>
                <a className="text-blue underline" target="_blank" href={`http://${task && task.brand_website}`} rel="noreferrer" >{task && task.brand_name}</a>
              </div>
                    <div className="flex ml-7 mb-5">
                      <div className="flex mr-5 font-bold">Platform(s):</div>
                      <div>
                        {task && task.platform.map((platform, index) => (
                          <span key={index} className="mr-2">
                            {platform}
                            {index < task.platform.length - 1 ? ',' : ''}
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="flex ml-7 mb-5">
                <div className="flex mr-5 font-bold">Brief Name: <p className='ml-2 font-thin'>{task && task.name}</p></div>
               
              </div>
              <div className="flex ml-7 mb-5">
                <div className="flex flex-col">
                    <div className="font-bold">Brief:</div>
                    <div className='flex-wrap'>
                        <span>We invite you to craft a unique video that highlights the brand in a non-salesy way and share it on the platforms specified below (ie, TikTok & Instagram Reels). Submit the link to us afterwards, so we can track and analyze the engagement.</span>
                    </div>
                    <div className='flex-wrap'>
                        <span>Crafted and the brand will have the rights to the video assets in perpetuity.</span>
                    </div>
                </div>
            </div>  
              <div className="ml-7 mb-5">
                <div>
                  <div className="mt-2 font-bold">
                    {task && task.brand_name} is prioritizing these products/flavors:
                  </div>
                  <div className="">
                    {task && task.product_name}
                  </div>
                </div>
                <div>
                  <div className="mt-2 font-bold">
                    {task && task.brand_name}'s Target Audience:
                  </div>
                  <div className="">
                    {task && task.target_audience}
                  </div>
                </div>
                <div>
                  <div className="mt-2 font-bold">
                    Key talking points for creators:
                  </div>
                  <div className="text-justify break-all flex-wrap">
                    {task && covertingString(task.talking_points)}
                  </div>
                  <div className="mt-2 font-bold">
                    Number of Videos: 
                  </div>
                  <div className="">
                    {task && task.number_of_videos}
                  </div>
                  <div className="mt-2 font-bold">
                    Add ons: 
                  </div>
                  <div className="">
                    {task && task.add_ons && task.add_ons.map((el, i) => (
                      <span key={i}>{i !== 0 && ', '} {el.name}</span>
                    ))}
                  </div>
                  <div className="mt-2 font-bold">
                    Retailer:
                  </div>
                  <div className="text-justify">
                    {task && task.retailer}
                  </div>
                  <div className="mt-2 font-bold">
                    Store locator:
                  </div>
                  <div className="text-left ">
                    {task && task.store_list && task.store_list.includes("https://") || task.store_list.includes(".com") ? (
                      <a
                        className="text-blue hover:underline break-all cursor-pointer"
                        href={task.store_list.startsWith("http") ? task.store_list : `https://${task.store_list}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {task.store_list}
                      </a>
                    ) : (
                      task.store_list
                    )}
                  </div>
                  <div className="mt-2 font-bold">
                    Regions:
                  </div>
                  <div className="text-justify">
                {task && task.regions && 
                  ((Array.isArray(task.regions) && task.regions.length > 0) ? (
                    task.regions.map((region, index) => (
                      <div key={index}>
                        <p>{region.value || region}</p>
                      </div>
                    ))
                  ) : (
                    Object.values(task.regions).map((region, index) => (
                      <div key={index}>
                        <p>{region.value}</p>
                      </div>
                    ))
                  ))
                }
              </div>
                </div>
              </div>
              <div className="items-center justify-center text-center">
              </div>


            </div>
          </GlobalDialog>
        </div>
      ) : null}
    
    </div>
  );
}

export default ProposalModal;
