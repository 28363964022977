import React, { useState, useRef, useEffect, useContext, forwardRef, useImperativeHandle } from 'react';

import 'react-responsive-modal/styles.css';
import { validateFormFields } from '../../utils/functions';
import Loader from '../Loader';
import { Modal } from 'react-responsive-modal';
import Multiselect from 'multiselect-react-dropdown';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { BsQuestionCircle } from 'react-icons/bs';
import logo from '../../Assets/logo-red-ugc.png';
import video from '../../Assets/Aspirational.mp4';
import { useAuth } from '../../Context/AuthContext';
import { AuthService, userAuthstate } from '../../Service/authenticationService';
import Toggle from '../Toggle';
import Switch from 'react-switch';
import { useTaskState, TaskService } from '../../Service/TaskServices';
import { useBrandState, BrandService } from '../../Service/BrandServices';
import { SubscriptionService, useSubscriptionState } from '../../Service/SubscriptionServices';
import { Link } from 'react-router-dom';
import { useProductState, ProductService } from '../../Service/ProductServices';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';
import { AiOutlineDown } from 'react-icons/ai';
import GenerateBriefs from './GenerativeBriefs/GenerateBriefs';
import CustomButton from '../../utils/customButton';

const NewTaskModal = forwardRef((props, ref) => {
 // const { currentUser, currentBrand } = useAuth();
  const taskState = useTaskState();
  const subsState = useSubscriptionState();
  const brandState = useBrandState();
  const [activeTab, setActiveTab] = useState('NewTaskModal');
  const productState = useProductState();
  const [raw, setRaw] = useState(false);
  const [error, setError] = useState('');
  const [contentUsage, setContentUsage] = useState('');
  const [isRecipesChecked, setIsRecipesChecked] = useState(false);
  const [divs, setDivs] = useState([]);
  const [isReviewChecked, setIsReviewChecked] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [isPhotosChecked, setIsPhotosChecked] = useState(false);
  const [onScreenTextChecked, setOnScreenTextChecked] = useState(false);
  const [subtitlesChecked, setSubtitlesChecked] = useState(false);
  const [voiceoverChecked, setVoiceoverChecked] = useState(false);
  const [musicChecked, setMusicChecked] = useState(false);
 // const [updateNeeded, setUpdateNeeded] = useState(false);
  const [recipe, setRecipe] = useState(0);
  const [videos, setVideos] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [taskCredits, setTaskCredits] = useState(0);
  const [platformsSelected, setPlatformsSelected] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [showBrief, setShowBrief] = useState(false);
  const [selectedBriefData, setSelectedBriefData] = useState({});
  //const [generateBrief, setGenerateBrief] = useState(false);
  const { brandId } = useContext(BrandContext);

  const openModal = (briefData) => {
    setSelectedBriefData(briefData); // Set the selected brief data
    setShowBrief(true); // Show the modal
  };
  const fieldErrors = {
    name: 'Please enter brief name',
    note1: 'Please enter the products do they want to showcase ',
    note2: 'Please enter their target demographic',
    note3: 'Please enter other talking points'
  };

  const handleRecipeAddOn = () => {
    // if checked -> not checked
      // decrement the taskCredits by the number of vidoes
    if (!isRecipesChecked) {
      setTaskCredits(taskCredits + (50 * videos));
    }
    if (isRecipesChecked) {
      setTaskCredits(taskCredits - (50 * videos));
    }
    
    setIsRecipesChecked(!isRecipesChecked); 
    recalculateCredits();
  }

  const handleRawFootageAddOn = () => {
    // if checked -> not checked
      // decrement the taskCredits by the number of vidoes
    if (!raw) {
      setTaskCredits(taskCredits + (300 * videos));
    }
    if (raw) {
      setTaskCredits(taskCredits - (300 * videos));
    }
    
    setRaw(!raw); 
    recalculateCredits();
  }

  const handleReviewsAddOn = () => {
    // if checked -> not checked
      // decrement the taskCredits by the number of vidoes
    if (!isReviewChecked) {
      setTaskCredits(taskCredits + (30 * videos));
    }
    if (isReviewChecked) {
      setTaskCredits(taskCredits - (30 * videos));
    }
    
    setIsReviewChecked(!isReviewChecked); 
    recalculateCredits();
  }

  const handlePhotosAddOn = () => {
    // if checked -> not checked
      // decrement the taskCredits by the number of vidoes
    if (!isPhotosChecked) {
      setTaskCredits(taskCredits + (25 * videos));
    }
    if (isPhotosChecked) {
      setTaskCredits(taskCredits - (25 * videos));
    }
    
    setIsPhotosChecked(!isPhotosChecked); 
    recalculateCredits();
  }
  const incrementVideos = () => {
    {/*let currentCredits = taskCredits;
    let costPerVideo = 300;
    if (isPhotosChecked) {
      costPerVideo = costPerVideo + 25
    }

    if (isRecipesChecked) {
      costPerVideo = costPerVideo + 50
    }

    if (isReviewChecked) {
      costPerVideo = costPerVideo + 30
    }

    if (divs.length > 0) {
      costPerVideo = costPerVideo + 100 * divs.length
    }
    if (contentUsage === "whitelist-ads") {
      costPerVideo += 300;
    }
    if (raw === true) {
      costPerVideo += 200;
    }
  setTaskCredits(currentCredits + costPerVideo); */}
    setVideos(videos + 1); // Increment product by 1
    setTaskCredits(taskCredits + 300);
    recalculateCredits();
  };

const decrementVideos = () => {
   if (videos > 0) { {/*
      let currentCredits = taskCredits;
      let costPerVideo = 300;
      if (isPhotosChecked) {
        costPerVideo = costPerVideo + 25
      }
  
      if (isRecipesChecked) {
        costPerVideo = costPerVideo + 50
      }
  
      if (isReviewChecked) {
        costPerVideo = costPerVideo + 30
      }
      if (divs.length > 0) {
        costPerVideo = (costPerVideo + 100) * divs.length
      }
      if (contentUsage === "whitelist-ads") {
        costPerVideo += 300;
      }
      if (raw === true) {
        costPerVideo += 300;
      }
    setTaskCredits(currentCredits - costPerVideo);
     */}  
     setVideos(videos - 1);
     setTaskCredits(taskCredits - 300);
     recalculateCredits();
    } 
  
  };


  const recalculateCredits = () => {
    //console.log("recalculating credits..");
    let costPerVideo = 0;
    let numCredits = 300;
    //console.log("videos", videos)
    if (isPhotosChecked) {
      costPerVideo = costPerVideo + 25
    }
  
    if (isRecipesChecked) {
      costPerVideo = costPerVideo + 50
    }
  
    if (isReviewChecked) {
      costPerVideo = costPerVideo + 30
    }
    if (raw){
      costPerVideo = costPerVideo + 300;
    }
    if (divs.length > 0) {
      console.log("divs length:", divs.length);
      costPerVideo = costPerVideo + (100 * divs.length)
    }
    if (contentUsage === "whitelist-ads") {
      costPerVideo += 300;
    }

   //console.log("cost per video:", costPerVideo);
    setTaskCredits((numCredits + costPerVideo) * videos);
   // console.log("task credits:", taskCredits);
  }

    useEffect(() => {
      recalculateCredits();
    }, [isPhotosChecked, isRecipesChecked, isReviewChecked, divs.length, contentUsage, raw, videos]);
    
// HANDLING CHECKBOXES FOR WHAT VIDEOS SHOULD INCLUDE 
const handleOnScreenTextChange = () => {
  setOnScreenTextChecked(!onScreenTextChecked);
}
const handleSubtitlesChange = () => {
  setSubtitlesChecked(!subtitlesChecked);
}
const handleVoiceoverChange = () => {
  setVoiceoverChecked(!voiceoverChecked);
}

const handleMusicChange = () => {
  setMusicChecked(!musicChecked);
}


  
  //adding here
  const addDiv = () => {
    if (divs.length < 5) {
      const newDivs = [
        ...divs,
        {
          id: divs.length,
          voiceover: false,  // Initial checkbox states
          music: false,
          onScreenText: false,
          subtitles:false
        }
      ];
      setDivs(newDivs);
      console.log(newDivs); 
    }
  };

  //rendering the variations
  const renderCheckboxes = () => {
    return divs.map((variation) => (
      <>
      <div key={variation.id} data-testid={`div-${variation.id}`} className="flex-grow rounded-md 2xl:text-base lg:text-xs md:text-xs bg-gray-800 text-white w-full 2xl:p-10 lg:p-4 md:p-4  mb-4 flex flex-col items-center md:mx-1 2xl:mx-4">
        <p className='text-center mx-auto font-semibold 2xl:text-2xl lg:text-xs md:text-base'>Version {variation.id + 1}</p>
        <div className="flex-grow flex flex-col mt-2 w-full md:px-4 text-white">
        <div className="flex-grow items-center mb-2 text-white ">
            <Switch
              text="Voiceover"
              checked={variation.voiceover}
              disabled={variation.separateClips}
              onColor="#ff2642"
              offColor="#999999"
              uncheckedIcon={false}
              checkedIcon={false}
              width={24} // set the width of the switch in pixels
              height={10}
              onChange={() => handleCheckboxChange(variation.id, 'voiceover')}
            />
            <label className="ml-2 pointer-events-none text-white" htmlFor="voiceover">Voiceover</label>
          </div>
          <div className="flex items-center mb-2 text-white">
            <Switch
              type="checkbox" 
              id="music" 
              name="music" 
              value="Music" 
              onColor="#ff2642"
              offColor="#999999"
              uncheckedIcon={false}
              checkedIcon={false}
              width={24} // set the width of the switch in pixels
              height={10}
              className="form-checkbox h-4 w-4 text-indigo-600"
              checked={variation.music}
              disabled={variation.separateClips}
              onChange={() => handleCheckboxChange(variation.id, 'music')}
            />
            <label className="ml-4 pointer-events-none text-white" htmlFor="music">Music</label>
          </div>
 
          <div className="flex items-center mb-2 text-white">
            <Switch 
              type="checkbox" 
              id="onScreenText" 
              name="onScreenText" 
              value="OnScreenText" 
              onColor="#ff2642"
              offColor="#999999"
              uncheckedIcon={false}
              checkedIcon={false}
              width={24} // set the width of the switch in pixels
              height={10}
              className="form-checkbox h-4 w-4 text-indigo-600"
              checked={variation.onScreenText}
              disabled={variation.separateClips}
              onChange={() => handleCheckboxChange(variation.id, 'onScreenText')}
            />
            <label className="ml-4 pointer-events-none 2xl:text-xs lg:text-0.7 text-white" htmlFor="onScreenText">On-screen Text</label>
          </div>
          <div className="flex items-center mb-2 text-white">
            <Switch 
              type="checkbox" 
              id="onScreenText" 
              name="onScreenText" 
              value="OnScreenText" 
              onColor="#ff2642"
              offColor="#999999"
              uncheckedIcon={false}
              checkedIcon={false}
              width={24} // set the width of the switch in pixels
              height={10}
              className="form-checkbox h-4 w-4 text-indigo-600"
              checked={variation.subtitles}
              disabled={variation.separateClips}
              onChange={() => handleCheckboxChange(variation.id, 'subtitles')}
            />
            <label className="ml-4 pointer-events-none  text-white" htmlFor="onScreenText">Subtitles</label>
          </div>
          
          <CustomButton className='py-2 mt-2 w-fit text-xs bg-red px-4 flex rounded-lg text-white items-center justify-center text-center cursor-pointer mx-auto' onClick={() => deleteDiv(variation.id)}>Remove</CustomButton>

        </div>
      </div>
      </>
    ));
  };
  // Function to delete a specific div at an index
  const deleteDiv = (index) => {
    const updatedDivs = divs.filter((_, idx) => idx !== index).map((div, idx) => ({
      ...div,
      id: idx
    }));
  
    setDivs(updatedDivs);
    recalculateCredits();
  };
const handleCheckboxChange = (id, checkboxName) => {
  setDivs(prevDivs => {
    return prevDivs.map((variation) => {
      if (variation.id === id) {
        let updatedVariation = {
          ...variation,
          [checkboxName]: !variation[checkboxName]
        };

        // If separateClips checkbox is selected, set other checkboxes to false
        if (checkboxName === "separateClips" && updatedVariation.separateClips) {
          updatedVariation = {
            ...updatedVariation,
            voiceover: false,
            music: false,
            onScreenText: false, 
            subtitles: false
          };
        }

        return updatedVariation;
      }
      return variation;
    });
  });
  recalculateCredits();
};
  // PROVINCE CONFIGURATIONS
  const canadianProvinces = [
    'Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador',
    'Northwest Territories', 'Nova Scotia', 'Nunavut', 'Ontario', 'Prince Edward Island',
    'Quebec', 'Saskatchewan', 'Yukon'
  ];
  
  const canadianAbbreviations = [
    'AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'
  ];

  // STATE CONFIGURAITONS
  const stateNames = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
    'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
    'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
    'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina',
    'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming',
    ...canadianProvinces // Add Canadian provinces here
  ];
  
  const stateAbbreviations = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY',
    ...canadianAbbreviations // Add Canadian abbreviations here
  ];
const stateOptions = [
  { label: 'United States of America', value: 'USA' },
  { label: 'Canada', value: 'CAN' },
  ...stateNames.map((name, index) => ({
    label: name,
    value: stateAbbreviations[index]
  }))
];
// END OF STATE CONFIGURAITONS


// PLATFORM CONFIGURATIONS
const platformOptions = [
  { value: "tiktok", label: "TikTok" },
  { value: "instagram", label: "Instagram" }
];
  const styles = {
    option: {
        // To change css for dropdown options
    },
    optionContainer: {
        // To change css for option container
        overflowY: 'scroll',
        overflowX: 'hidden',
        position: 'relative'
    },
    searchWrapper : {
      maxHeight: '80px', 
      overflowY: 'scroll',
    },

    multiselectContainer: {
        // To change css for multiselect (Width,height,etc..)
        height: '20px',
        marginTop: '10px',
        marginBottom: '65px',
        position: 'relative',
        outerWidth: '95%'
    },
    chips: {
        // To change css chips(Selected options)
        // display:"none",
        background: '#A9A9A9'
    },
    searchBox: {
      // To change search box element look
      border: 'none',
      maxHeight: '50px',
      padding: '7px 10px',
      fontSize: '15px',
      overflowY: 'scroll',
      backgroundColor: '#F3F4F6',
      backgroundImage: 'url(../../Assets/drop_down.png)',
      borderRadius: '2px'
    }
};

const onSelect = (selectedList) => {
  if (selectedList.includes('USA')) {
    setSelectedStates(['USA']);
  } else {
    setSelectedStates(selectedList);
  }
  //console.log("Selected states: ", selectedList);
};

const onRemove = (selectedList) => {
  setSelectedStates(selectedList);
  //console.log("Removed states: ", selectedList);
};

  const name = useRef('');
  const note1 = useRef('');
  const note2 = useRef('');
  const note3 = useRef('');
  const briefLink = useRef('');
  const regions = useRef([]);
  const authState = userAuthstate();

  function resetForm() {
    name.current = '';
    note1.current = '';
    note2.current = '';
    note3.current = '';
    regions.current = [];
    setSelectedStates([]);
    setPlatformsSelected([]);
    setDivs([]);
    setVideos(0);
    setContentUsage('');
    setTaskCredits(0);
    setIsRecipesChecked(false);
    setIsReviewChecked(false);
    setIsPhotosChecked(false);
    setOnScreenTextChecked(false);
    setSubtitlesChecked(false);
    setVoiceoverChecked(false);
    setMusicChecked(false);
    setRaw(false);  
    setSelectedProduct([]);
  }

  useImperativeHandle(ref, () => ({
    resetForm,
  }));

  useEffect(() => {
    if (brandId !== null) {
      BrandService.FetchSingleBrand(brandId);
      SubscriptionService.FetchSubscriptionForUser(brandId);
    }
    
  }, [brandState.updateNeed.value]);
  useEffect(() => {
    // tabState.merge({ currentTab: 1 });
    if (productState.updateNeed.value) {
        ProductService.FetchProductForUser(brandId);
    }
}, [productState.updateNeed.value]);

const res = JSON.stringify(productState.products.value);

const onPlatformSelect = (selectedList) => {
  setPlatformsSelected(selectedList);
};

const onPlatformRemove = (selectedList) => { 
  setSelectedStates(selectedList);
};


const productTemp = [];
if (productState.products.value){
    Object.keys(JSON.parse(res)).forEach(el => {
    productTemp.push({
        id: el,
        ...JSON.parse(res)[el]
    })
})
}
const isAtLeastOneCheckboxChecked = () => {
  return divs && divs.length > 0 && divs.some(variation => 
    variation.voiceover || variation.music || variation.onScreenText || variation.subtitles
  );
};
//console.log("divs", divs);
  const brand = brandState.brand.value;
  //console.log("brand info:", brand);
  const subs = subsState.subscription.value;
  let availUsage = 0
  if (subs) {
    availUsage = subs.paidUsage - subs.currentUsage;
  }
  useEffect(() => {
    if (brand && subs) {
      availUsage = subs.paidUsage - subs.currentUsage;
    }
  }, [subs, subsState.updateNeed.value]); 

  const [errors, setErrors] = useState({});
 const briefTemplates = brand && brand.brief_templates;

  const handleSubmit = async (event) => {
    try{
    event.preventDefault();
    event.stopPropagation();
    if (selectedStates.length === 0) {
      alert("Please select at least one region");
      return ;
    }
    const productObject = productTemp.find((obj) => obj.id === selectedProduct);
    if (videos == 0 && recipe == 0) {
      setError("Please Add Credits");
      return '';
    }
    
    if (divs.length > 0 && !isAtLeastOneCheckboxChecked()) {
          alert("Please add at least one variation option");
          return;
      }
    const formValues = {
      name: name.current.value,
      note1: productObject.name,
      note2: note2.current.value,
      //note3: note3.current.value,
      regions: selectedStates
    };

    const validationErrors = validateFormFields(formValues, fieldErrors);
    if (Object.keys(validationErrors).length) {
      return setErrors(validationErrors);
    }
    const temp = {};
    temp.uid = brandId;
    temp.brand_name = brand.brand_name;
    temp.brand_photo = brand.brand_avatar;
    temp.brand_website = brandId  && brand.brand_website ? brand.brand_website : 'www';
    temp.name = name.current.value;
    temp.note1 = productObject.name;
    temp.product = productObject;
    temp.note2 = note2.current.value;
    temp.note3 = note3.current.value;
    temp.brief_link = briefLink.current.value;
    temp.add_ons = [];
    temp.variations = divs;
    temp.video_requirements = {
      voiceover: voiceoverChecked,
      music: musicChecked,
      onScreenText: onScreenTextChecked,
      subtitles: subtitlesChecked,
    }
    if (isRecipesChecked) {
      temp.add_ons.push({
        name: "Recipes",
        price: 25
      });
    }
    if (isReviewChecked) {
      temp.add_ons.push({
        name: "Reviews",
        price: 15
      });
    }
    if (isPhotosChecked) {
      temp.add_ons.push({
        name: "Photos",
        price: 10
      });
    }
    if (raw) {
      temp.add_ons.push({
        name: "Raw Footage",
        price: 150
      });
    }
    if (contentUsage === "whitelist-ads") {
      const selectedValues = platformsSelected.map(option => option.value);
      temp.add_ons.push({
        name: "Whitelist",
        price: 150,
        platform: "instagram"
      }
        )
    }
    temp.credits = taskCredits;
    temp.recipe_videos = recipe;
    temp.regions = selectedStates;
    temp.product_showcase = videos;
    temp.product = productObject;
    temp.brief_template = selectedTemplate;
    temp.price = temp.add_ons.reduce((acc, curr) => acc + curr.price, 0) * 100 + 7500;
    temp.task_type = 'SERVICE';
    const createdTask = await TaskService.AddTask(temp);
    console.log("temp right now", temp);
    console.log("Created task: ", createdTask);
    temp.uid = createdTask.result.task_id;
    props.setTaskData(temp);
    console.log('Before setActiveTab');
    props.setActiveTab(1);
    console.log('After setActiveTab');
  }
  catch (error) {
    console.error(error);
  }
  };

  return (
    <div>
      <>
        <>
          <div>
            <h1 className="text-xl font-bold my-3 font-black xs:xl mx-auto flex items-center justify-center md:text-3xl">
              Create a Studio Brief
            </h1>
            <hr />
            <h1 className="2xl:text-2xl md:text-base mt-5 font-bold text-center">
              Describe Your Ideal UGC Video
            </h1>
  
            <p className='mt-1 italic 2xl:text-xs md:text-0.7 text-center text-darkGray'><strong>NOTE:</strong> Create one task per product. </p>
            <p className='mb-5 italic 2xl:text-xs md:text-0.7 text-center text-darkGray'>If you want multiple videos per task, add multiple videos at the bottom of this tab.</p>

            <form onSubmit={handleSubmit} className="px-4">
            <div className='my-3'>
                <div className="relative">
                <label htmlFor="contentPurpose" className="text-black block 2xl:text-base md:text-0.7 mb-2 font-bold">
                    Content Purpose
                  </label>
                  <select
                    id="contentPurpose"
                    value={contentUsage}
                    onChange={
                      (e) => setContentUsage(e.target.value)
                    }
                    required
                    className="block appearance-none w-full 2xl:text-base md:text-0.7 bg-gray-100 hover:border-gray-500 2xl:px-4 2xl:py-2 md:px-2 md:py-1 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                  >
                    <option className = "text-gray-500 2xl:text-base md:text-0.7  " value="" >Content Purpose</option>
                    <option value="social-media-post">Organic Social Media</option>
                    <option value="content-ads">Paid Social Media</option> 
                    <option value="whitelist-ads">Whitelisting for Instagram (additional 300 credits per creator)</option>

                  </select>
                    <AiOutlineDown className="absolute 2xl:top-7 right-0 mt-3 mr-4 text-gray-600" />
                  </div>
                
              </div>
              { contentUsage === "whitelist-ads" && 
                <div data-testid = 'whitelist'>
                  <p className='2xl:text-base md:text-0.7font-bold text-indigo-600'>Whitelisting for Instagram is Selected </p>
                  <div className="p-2 relative group w-full flex items-center">
                       How does whitelisting work? 
                       <span className="ml-2 hover:text-black relative">
                           <BsQuestionCircle className="text-gray-500" />
                          <div className="absolute italic border 2xl:text-base md:text-0.7 border-gray-400 top-0 right-0 transform translate-x-full -translate-y-full w-50rem  h-20rem bg-gray-200 text-gray-800 py-2 px-4 rounded opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
                            <div className='flex flex-row gap-2 mt-4'>
                              <div>
                              <p className='font-bold'>For Instagram:</p>
                              <ol type='1' className='mt-2'>
                                <li>
                                  <strong>1.</strong> After a creator's proposal has been approved, we will provide their handle for you to add the approved creator as an ad partner.
                                </li>
                                <li>
                                <strong>2.</strong> The creator will receive a notification and will need to accept the partnership.
                                </li>
                                <li>
                                <strong>3.</strong> Once accepted, you will be able to run ads from your Meta Business Manager using the creator's account.
                                </li>
                              </ol>
                            </div>
                          </div>
                          </div>
                        </span>
                      </div>
                </div>}
            <div className="flex-grow w-full my-2">
              <div className="flex  w-full">
                <div className="my-2 w-full mr-2">
                  <label htmlFor="description" className="text-black block 2xl:text-base md:text-0.7 mb-2 font-bold">
                    What is the name of your UGC brief?
                  </label>
                  <input
                    name="name"
                    className="outline-none 2xl:text-base md:text-0.7 bg-gray-100 2xl:p-3 md:p-1 rounded-lg w-full"
                    placeholder="Enter your UGC brief name."
                    required
                    ref={name}
                  />
                  <div className="text-red px-3 font-medium">{errors.note2 || ''}</div>
                </div>
                <div className="my-2 w-full">
                  <label htmlFor="note1" className="text-black block 2xl:text-base md:text-0.7 mb-2 font-bold">
                    Which product will be featured?
                  </label>
                  <div className="my-2">
                 {
                    productTemp.length === 0 ? (
                      <p className='text-base flex mt-2 items-center'>You currently have no products, click <a href="/brand/dashboard/products" className='font-bold text-red mx-2'>here</a> to add a product</p>
                    ) : (
                      <select
                        value={selectedProduct}
                        data-testid='productSelect'
                        onChange={(e) => {
                          const newSelectedProductId = e.target.value;
                          const productObj = productTemp.find((obj) => obj.id === newSelectedProductId);
                          setSelectedProduct(newSelectedProductId);
                          console.log("selectedProduct: ", productObj);
                        }}
                        className="outline-none 2xl:text-base md:text-0.7 bg-gray-100 2xl:p-3 md:p-1 rounded-lg w-full"
                        required
                        placeholder="Choose Product..."
                      >
                        <option className="text-sm text-indigo-800" value="">
                          Choose Product
                        </option>
                        {
                          productTemp.map((item) => (
                            <option className="text-sm text-indigo-800" value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))
                        }
                      </select>
                    )
                  }
                  </div>

              </div>
            </div>
                <div className="text-red px-3 font-medium">{errors.note1 || ''}</div>
              </div>
            
              <div data-testid = "targetDemographic" className="my-2">
                <label htmlFor="description" className="text-black block 2xl:text-base md:text-0.7 mb-2 font-bold">
                  What is your target demographic?
                </label>
                <input
                  name="note2"
                  className="mr-2 outline-none 2xl:text-base md:text-0.7 bg-gray-100 2xl:p-3 md:p-1  rounded-lg w-full"
                  placeholder="Enter your target demographic."
                  required
                  ref={note2}
                />
                <div className="text-red px-3 font-medium">{errors.note2 || ''}</div>
              </div>
              <div data-testid = "briefLink" className="my-2">
                  <label htmlFor="briefLink" className="text-black block 2xl:text-base md:text-0.7 mb-2 font-bold">
                    Add a link to your brief here.
                  </label>
                  <input
                    name="briefLink"
                    type="url"
                    className="mr-2 outline-none 2xl:text-base md:text-0.7 bg-gray-100 2xl:p-3 md:p-1  rounded-lg w-full"
                    placeholder="Include a link to your brief here."
                    ref={briefLink}
                  />
                </div>
              {/* <div data-testid = "selectBrief" className="my-2">
                <label htmlFor="description" className="text-black block 2xl:text-base md:text-0.7 mb-1\ font-bold">
                  Choose brief
                </label>
               
                  briefTemplates && Object.keys(briefTemplates).length === 0 ? (
                    <p className='2xl:text-base md:text-0.7 flex mt-2 items-center'>
                      You currently have no briefs, click 
                      <a href="/brand/dashboard/brief-builder" className='font-bold text-blue mx-2'>here</a> 
                      to create a brief
                    </p>
                  ) : (
                    <div className="flex items-center">
                      <select 
                          value={selectedTemplate}
                          onChange={(e) => {
                            const newSelectedTemplateId = e.target.value;
                            if (newSelectedTemplateId === "") {
                              setSelectedTemplate({}); // Reset or clear the selection
                            } else {
                              const briefData = briefTemplates[newSelectedTemplateId];
                              setSelectedTemplate(briefData); // Set the selected template
                            }
                            console.log("selectedTemplate: ", selectedTemplate);
                          }}
                        className="outline-none 2xl:text-base md:text-0.7 bg-gray-100 2xl:p-3 md:p-1 rounded-lg w-full" 
                        placeholder="Choose Brief..."
                      >
                        <option className="text-sm text-indigo-800" value="">Choose Brief</option>
                        {Object.entries(briefTemplates || {}).map(([id, template]) => (
                          <option className="text-sm text-indigo-800 font-bold" value={id} key={id}>
                            {template.name} &nbsp; Date Updated: {(new Date(template.updated || template.date_created)).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })}
                          </option>
                        ))}
                      </select>
                      {selectedTemplate.id && (
                        <CustomButton
                          onClick={() => openModal(selectedTemplate)} // Pass the selectedTemplate as argument
                          className="bg-Blue rounded-md ml-2 w-8rem py-2 text-center mx-auto font-bold flex 2xl:text-base md:text-0.7 items-center justify-center flex-row text-white"
                        >
                          View Brief
                        </CustomButton>
                      )}
                    </div>
                  )
                
                      <div className="text-red px-3 font-medium">{errors.brief_template || ''}</div>
                </div>}*/}
              <div data-testid="videoInstructions" className="my-2">
              <>
                  <label htmlFor="note3" className="text-black block 2xl:text-base md:text-0.7 mb-2 font-bold">
                    If you have additional notes, please include them here.
                  </label>
                  <input
                    name="note3"
                    className="mr-2 outline-none 2xl:text-base md:text-0.7 bg-gray-100 2xl:p-3 md:p-1  rounded-lg w-full"
                    placeholder="Include any notes on the video concept, brand safety guidelines,  do's and don'ts and example videos, etc."
                    ref={note3}
                  />
                </>
                 <div data-testid ="stateSelect" className="my-2">
                  <label htmlFor="stateDropdown" className="text-black block 2xl:text-base md:text-0.7 mb-2 font-bold">
                    Do creators need to be from specific regions for this brief? Specify here.
                  </label>
                  <Multiselect
                    id="stateSelect"
                    name="state"
                    options={stateOptions}
                    selectedValues={selectedStates} 
                    onSelect={onSelect}
                    onRemove={onRemove}
                    displayValue="label"
                    style={styles}
                  />
                  <div className="text-red px-3 font-medium">{errors.regions || ''}</div>
                </div>
                
                <div className='w-full'>
                  <div className='font-bold 2xl:text-base md:text-0.7'>Which type of add-ons would you like?</div>
                  <div className="flex items-center rounded-lg gap-1 2xl:text-base md:text-0.7">
                      <div className="p-2 relative group w-fit flex items-center">
                        <input type="checkbox" data-testid= "isRecipesChecked" id="isRecipesChecked" name="isRecipesChecked" checked={isRecipesChecked} onChange={() => handleRecipeAddOn()} className='2xl:2xl:p-3 md:p-1 md:p-1' />
                        <p className='flex items-center ml-2'>Recipe <BsQuestionCircle className="text-gray-700 ml-2" /></p>
                          <span className="mx-2 hover:text-black relative">
                            <div>(addl. 50 credits/video)</div>  
                          <div className="absolute italic border z-50 border-gray-400 top-0 right-0 transform translate-x-full -translate-y-full w-10rem h-fit bg-gray-200 text-gray-800 py-2 px-4 rounded opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
                           The accompanying written recipe for the video.
                          </div>
                        </span>
                      </div>
                      <div className="p-2 relative group w-fit  flex items-center">
                        <input type="checkbox" data-testid= "isReviewChecked" id="isReviewChecked" name="isReviewChecked" value={isReviewChecked} onChange={() => handleReviewsAddOn()} />
                         <p className='flex items-center ml-2'> Review <BsQuestionCircle className="text-gray-700 ml-2" /></p> <span className="mx-2 hover:text-black relative">
                      
                          <div>(addl. 30 credits/video)</div>  
                          <div className="absolute italic border z-50 border-gray-400 top-0 right-0 transform translate-x-full -translate-y-full w-36 h-27 bg-gray-200 text-gray-800 py-2 px-4 rounded opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
                            Screenshot of the online review. 
                          </div>
                        </span>
                      </div>
                      <div className="p-2 relative group w-fit flex items-center">
                        <input type="checkbox" data-testid= "isPhotosChecked"id="isPhotosChecked" name="isPhotosChecked" value={isPhotosChecked} onChange={() => handlePhotosAddOn()} />
                            <p className='flex items-center ml-2'>Photos <BsQuestionCircle className="text-gray-700 ml-2 " /></p>
                          <span className="mx-2 hover:text-black relative">
                            <div>(addl. 25 credits/video)</div>  
                          <div className="absolute italic border z-50 border-gray-400 top-0 right-0 transform translate-x-full -translate-y-full w-32 h-27 bg-gray-200 text-gray-800 py-2 px-4 rounded opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
                            3 photos of the final product.
                          </div>
                        </span>
                      </div>
                      <div className="p-2 relative group w-fit flex items-center">
                        <input type="checkbox" data-testid= "isRawFootageChecked" id="isRawFootageChecked" name="isRawFootageChecked" checked={raw} onChange={() => handleRawFootageAddOn()} className='2xl:p-3 md:p-1' />
                        <p className='flex items-center ml-2'>Raw Footage <BsQuestionCircle className="text-gray-700 ml-2" /></p>
                          <span className="mx-2 hover:text-black relative">
                            <div>(addl. 300 credits/video)</div>  
                          <div className="absolute italic border z-50 border-gray-400 top-0 right-0 transform translate-x-full -translate-y-full w-14rem h-fit bg-gray-200 text-gray-800 py-2 px-4 rounded opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
                          If "raw footage" is selected, then creator will upload all of their unedited clips of the video (will come as is - no voiceover, music, on-screen text).
                          </div>
                        </span>
                      </div>
                    </div>
                </div>
                <div className='md:w-full mt-2'>
                  <div className="relative">
                      <p className='mx-1 font-bold 2xl:text-base md:text-0.7'>Original video should include:</p>
                      <div className='flex gap-5 2xl:text-base md:text-0.7 w-full'>
                  
                      <div className="p-2 relative group w-fit flex items-center">
                        <input type="checkbox" id="on-screen-text" name="on-screen-text" checked={onScreenTextChecked} onChange={handleOnScreenTextChange} />
                        <p className='ml-2'>On-screen text</p>
                         <span className="ml-2 hover:text-black relative">
                         <BsQuestionCircle className="text-gray-500" />
                          <div className="absolute italic border z-50 text-base border-gray-400 top-0 right-0 transform translate-x-full -translate-y-full w-20rem h-fit bg-gray-200 text-gray-800 py-2 px-4 rounded opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
                            Text present on the screen, such as buzz words or phrases.
                            {/*<video src = {video} controls controlsList="fullscreen" alt = "video" className = ""/>*/}
                          </div>
                        </span>
                      </div>
                      <div className="p-2 relative group w-fit flex items-center">
                        <input type="checkbox" id="subtitles" name="subtitles" checked={subtitlesChecked} onChange={handleSubtitlesChange} />
                        <p className='ml-2'>Subtitles  </p>
                        <span className="ml-2 hover:text-black relative">
                          <BsQuestionCircle className="text-gray-500" />
                          <div className="absolute italic border z-50 text-base border-gray-400 top-0 right-0 transform translate-x-full -translate-y-full w-20rem  h-fit bg-gray-200 text-gray-800 py-2 px-4 rounded opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
                            Subtitles or captions present in the video.
                            {/*<video src = {video} controls controlsList="fullscreen" alt = "video" className = ""/>*/}
                          </div>
                        </span>
                      </div>
                      <div className="p-2 relative group w-fit flex items-center">
                        <input type="checkbox" id="voiceover" name="voiceover" checked={voiceoverChecked} onChange={handleVoiceoverChange} />
                        <p className='ml-2'>Voiceover </p>
                        <span className="ml-2 hover:text-black relative">
                             <BsQuestionCircle className="text-gray-500" />
                          <div className="absolute italic border z-50 text-base border-gray-400 top-0 md:right-0 transform md:translate-x-full -translate-y-full w-10rem  h-fit bg-gray-200 text-gray-800 py-2 px-4 rounded opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto md:left-auto left-0">
                            Voiceover present in the video.
                            {/*<video src = {video} controls controlsList="fullscreen" alt = "video" className = ""/>*/}
                          </div>
                        </span>
                      </div>
                      <div className="p-2 relative group w-fit flex items-center">
                        <input type="checkbox" id="music" name="music" checked={musicChecked} onChange={handleMusicChange} />
                        <p className='ml-2'>Music</p>
                        <span className="ml-2 hover:text-black relative">
                             <BsQuestionCircle className="text-gray-500" />
                          <div className="absolute italic border z-auto text-base border-gray-400 top-0 md:right-0 transform md:translate-x-full -translate-y-full w-10rem  h-fit bg-gray-200 text-gray-800 py-2 px-4 rounded opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto md:left-auto left-0">
                            Music present in the video.
                            {/*<video src = {video} controls controlsList="fullscreen" alt = "video" className = ""/>*/}
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
          
              <div>
                <div className="flex items-center mt-2">
                  <div className="flex flex-col">
                    <div className='font-bold 2xl:text-base md:text-0.7'>Versions (100 adl credits/ per video)</div>
                    <p className="ml-2 2xl:text-2xl md:text-xs text-gray-600">Indicate here if you want a separate version (additional file) with different specs (ie. same video but without the on-screen text). 
                      Up to five different versions are allowed. 
                    </p>
                  </div>
                </div>
                <div>
                <div className="flex flex-col items-center mb-4">
                  <div className="flex justify-start left-0 mb-2"> 
                      <CustomButton className='bg-red flex rounded-md p-2 items-start text-white' onClick={addDiv}>+ Add Version</CustomButton>
                  </div>
                  <div className='flex flex-row items-center'>
                    {renderCheckboxes()}
                  </div>
                </div>
              </div>
              </div>
                  <p className='font-bold 2xl:text-base md:text-0.7'>How many videos would you like?</p>
                  {
                    // WORKING HERE!
                    videos == 0 ? <div style={{ color: 'red' }}>{error}</div> : ''
                  }
                  <div className="text-red px-3 font-medium">{errors.note3 || ''}</div>
              </div>
              <div className="w-full ">
                <div className="flex bg-gray-100 rounded-md">
                  <div className="w-2/5 p-2 font-bold text-xs ">
                    <span className="mx-2">{videos}</span> X {' '}
                    <span className="mx-2 2xl:text-base md:text-0.7">Video(s)</span>
                  </div>
                  <div className="flex w-1/5 p-2 font-bold text-xs">
                    <span data-testid="taskCredits" className="mx-2 2xl:text-base md:text-0.7">{taskCredits}</span> credits
                  </div>
                  <div className="flex w-2/5 font-bold ">
                    <div
                      data-testid="incrementVideos"
                      className="ml-5px 2xl:text-4xl md:text-0.7 font-bold cursor-pointer"
                      onClick={incrementVideos}>
                      +
                    </div>
                    <div  data-testid="decrementVideos" className="ml-8 text-4xl font-bold cursor-pointer" onClick={decrementVideos}>
                      -
                    </div>
                  </div>              
                </div> 
              </div>
              {subs && subs.status === 'active' ? (
                <div>
                  {taskCredits > availUsage ? (
                    <>
                      <p className="text-center text-3xl font-bold ">

                        {taskCredits} credits 
                      </p>
                      <p className="text-center text-xl 2xl:text-base md:text-0.7 text-black font-bold mt-2">
                          Credits will only be deducted once you approve a creator
                      </p>
                      <div className="flex justify-center">
                      <CustomButton
                          type="submit"
                          className="py-2 w-fit px-4 rounded-lg text-white mt-4 items-center justify-center text-center cursor-pointer">
                          {taskState.loading.value && <Loader />}
                          Create Task
                        </CustomButton>
                      </div>
                    </>
                  ) : (
                    <>
                      <p className="text-center text-3xl font-bold">

                        {taskCredits} credits 
                      </p>
                      <p className="text-center text-xl 2xl:text-base md:text-0.7 text-black font-bold mt-2">
                      Credits will only be deducted once you approve a creator
                  </p>
                      <div className="flex justify-center">
                        <CustomButton
                          type="submit"
                          className="py-2 w-fit px-4 rounded-lg text-white mt-4 items-center justify-center text-center cursor-pointer">
                          {taskState.loading.value && <Loader />}
                          Create Task
                        </CustomButton>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className="justify-center">
                    <p className="text-center text-xl 2xl:text-base md:text-0.7 text-red font-bold mt-2">
                    {taskCredits} credits 
                    </p>
                  <p className="text-center text-xl 2xl:text-base md:text-0.7 text-black font-bold mt-2">
                      Credits will only be deducted once you approve a creator
                  </p>
                  <div className="flex justify-center">
                  <CustomButton
                          type="submit"
                          className="py-2 w-fit bg-Blue px-4 rounded-md text-white mt-4 items-center justify-center text-center cursor-pointer">
                          {taskState.loading.value && <Loader />}
                          Create Task
                    </CustomButton>
                  </div>
                </div>
              )}
            </form>
          </div>
        </>
      </>
    </div>
  );
})
export default NewTaskModal;