import React, {useState, useEffect} from 'react';
import { CampaignState } from '../../../utils/campaignState';
import CampaignSubTab from '../../../common/steps/campaign/campaignSubTab';
import { useTabState } from '../../../utils/TabState';
import 'react-responsive-modal/styles.css';
import CampaignAnalyticsByPost from './CampaignDetails/CampaignAnalyticsByPost';
import CampignBrief from './CampaignDetails/CampaignBrief';
import CreatorProposals from './CampaignDetails/CreatorProposals';
import ProductFullfillment from './CampaignDetails/ProductFullfillment';
import VideoDrafts from './CampaignDetails/VideoDrafts';
import FinalDeliverables from './CampaignDetails/FinalDeliverables';
import Payment from './CampaignDetails/Payment';
import Overview from './CampaignDetails/Overview';
import CampaignAnalytics from './CampaignDetails/CampaignAnalytics';
import CampaignInviteInfluencers from './CampaignInfluencerInvites';
import CampaignOverview from './CampaignOverView';
import { Link } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import Campaigns from './Campaigns';
import { useParams } from 'react-router-dom';
import { CampaignService, useCampaignState } from '../../../Service/CampaignServices';
import ViewCampaignTasks from './ViewCampaignTasks';
import { useAuth } from '../../../Context/AuthContext';
import CreatorTable from '../CreatorTable';
export default function CampaignSubNav(props) {
  const campaignState = useCampaignState();
  const tabState = useTabState();
  const {id } = useParams();
  const currentUser = useAuth();
  useEffect(() => {
    if (id) {
      CampaignService.FetchSingleCampaign(id);
    }
  }, [id]);
  const res = campaignState.campaign.value; 
  
  const campaign = res
  return (
  <div className='m-4 bg-white w-10/12 p-1 rounded-lg z-100'  style={{ height: 'calc(95vh - 5rem)'}}>
      <div className='inline-block' style={{ display: 'flex', alignItems: 'center' }}>
        <h1 className='mr-auto ml-9 font-bold 2xl:text-base md:text-0.7'>{campaign.campaign_name}</h1>
        <Link to='/brand/dashboard/campaigns' className='flex 2xl:text-2xl md:text-0.7 mt-2 ml-4 mb-4 w-fit font-bold mr-6 transition-all duration-200 ease-in-out transform hover:scale-110'>
          <BsArrowLeft className='mx-3 mt-0.5' />
          <h1 className='font-bold mr-6'>Back to campaigns</h1>
        </Link>
      </div>
     <CampaignSubTab currentSubCampTab={tabState.currentSubCampTab.value} />
      {tabState.currentSubCampTab.value === 0 && <Campaigns />}
      {tabState.currentSubCampTab.value === 1 && <CampignBrief />}
      {tabState.currentSubCampTab.value === 2 && <CreatorProposals />}
      {tabState.currentSubCampTab.value === 3 && <ViewCampaignTasks/>}
      {tabState.currentSubCampTab.value === 4 && <ProductFullfillment />}
      {tabState.currentSubCampTab.value === 5 && <VideoDrafts />}
      {tabState.currentSubCampTab.value === 6 && <FinalDeliverables />}
      {tabState.currentSubCampTab.value === 7 && <CampaignAnalyticsByPost />}
      {tabState.currentSubCampTab.value === 8 && <CampaignAnalytics />}
      {tabState.currentSubCampTab.value === 9 && <CampaignOverview />}
      {tabState.currentSubCampTab.value === 10 && <CreatorTable/>}
    </div>
  );
}
