import React, {useState, useEffect, useContext} from 'react';
import GlobalDialog from '../../utils/brandModal'
import { EmailService, useEmailState } from '../../Service/EmailService';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';
function InviteBrandModal(props) {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const {id} = useParams();
  const {brandId} = useContext(BrandContext);
  const emailState = useEmailState();
  const handleInvite = (e) => {
    try{
    e.preventDefault(); // Prevent the default form submission
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      toast.error('Invalid email format');
      return; // Stop the form submission
    }
    const data = {
      role: role,
      invite_email: email,
      brand_id: brandId,
      brand_name: props.brand_name
    };
    console.log('Invite sent to: ', email, ' with role: ', role);  
    EmailService.inviteBrandUser(brandId, data);
    // Perform the action to send the invite using the data object
    toast.success("Invite sent!");
  }
  catch(err) {
    toast.error("Error sending invite");
  }
  };

  return (
    <GlobalDialog open={props.open} onClose={props.onClose}>
      <Toaster/>
      <div className='flex flex-col h-80 bg-white rounded-md p-4'>
        <p className='text-2xl  font-bold mb-4 text-center border-b pb-2'>Invite a user to {props.brand_name} </p>
        <form onSubmit={handleInvite} className='flex flex-col mt-5'>
        <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email"
            className='border border-gray-400 rounded-md p-2 mb-10'
          />
          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className='border border-gray-400 rounded-md p-2 mb-4'
            >
            <option value="" disabled selected>Select Role</option>
            <option value="admin">Admin</option>
            <option value="user">User</option>
          </select>
          <button type="submit" className='bg-Blue font-bold text-white rounded-md py-2'>Send Invite</button>
        </form>
      </div>
    </GlobalDialog>
  );
}
export default InviteBrandModal