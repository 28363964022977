import React from 'react';
import { useTaskState, TaskService } from '../../Service/TaskServices';
import { useAuth } from '../../Context/AuthContext';
import CustomButton from '../../utils/customButton';
function AssetDeploy(props) {
   const { currentUser } = useAuth();


  
  return (
    <div className="w-5/6 mx-auto" style={{ height: '45vh' }}>
      <div className="rounded-lg w-5/6 mx-auto  m-4 flex">
        <div
          className={`${props.count > 0 ? 'left-arrow' : 'ml-3'}`}
          onClick={() => props.dCount()}></div>
        <div className={`bg-lightGray`}>
          {props.draftData.video ? (
            <div className={`w-full  items-center justify-center rounded-lg text-center p-5`}>
              <video className="h-auto rounded-lg" controls src={props.draftData.video} />
              <div className="my-2 w-full text-left">
                <h1 className="text-xs font-bold">{props.draftData.creator_name}</h1>
              </div>
              <div className="my-2 mx-auto w-64 flex justify-center text-center">
                <CustomButton
                  className=" px-1 mr-3 text-xs font-bold py-1 text-white mt-41 w-auto text-center cursor-pointer"
                  onClick={() => props.approveDraft()}>
                  Approve Draft
                </CustomButton>
                <CustomButton
                  onClick={props.setSendNote}
                  className={`bg-${
                    props.sendNote ? 'darkGray' : 'Blue'
                  } px-1 text-xs font-bold py-1 text-white mt-41 w-auto text-center cursor-pointer`}>
                  Send Revisions
                </CustomButton>
              </div>
            </div>
          ) : (
            <div className="w-full bg-green mx-auto items-center justify-center text-center">
              <img
                src={props.draftData.thumbnail}
                alt={props.draftData.name}
                className="w-full min-w-1/3 h-auto rounded-lg"
              />
            </div>
          )}
        </div>
        <div
          className={`${props.count < props.assetLen - 1 ? 'right-arrow' : 'mr-5'}`}
          onClick={() => props.eCount()}></div>
      </div>
    </div>
  );
}

export default AssetDeploy;
