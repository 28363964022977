import React, { useEffect, useState } from "react";
import { useAuth } from "../../../Context/AuthContext";
import { baseUrl } from "../../../baseUrl";
import { Link } from "react-router-dom";
import { FcCheckmark, FcHighPriority } from "react-icons/fc";
import axios from "axios";
import Loader from "../../Loader2";

function TiktokConnectSuccess() {
  const { currentUser } = useAuth();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const connectToTikTok = async () => {
      const url = new URL(window.location.href);
      const urlParams = new URLSearchParams(url.search);
      const code = urlParams.get("code"); // Retrieve the code parameter from the URL

      try {
        // Check if the code parameter is present in the URL
        if (!code) {
          throw new Error("Code parameter not found in redirect URL.");
        }

        const apiUrl = `${baseUrl}api/influencers/tiktok/onboarding/${currentUser.uid}?code=${code}`;

        await axios(apiUrl, { method: "GET" });
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        const { result: errorMessage } = error.response.data;

        setError(
          errorMessage ||
            "There was an issue with the TikTok authentication process. Please try again or contact support."
        );
        setIsLoading(false);
      }
    };

    // Run the onboarding process
    connectToTikTok();
  }, []);

  return (
    <div
      className="bg-white m-4 rounded-lg lg:w-full xs:h-fit"
      style={{ height: "78vh" }}
    >
      {error ? (
        <div className="font-bold flex flex-col items-center">
          <FcHighPriority className="w-36 h-36 mt-20" />
          <h1 className="mb-4 md:text-3xl mt-10 text-center">{error}</h1>
          <div className="flex justify-center mt-5">
            <Link
              to="/creator/dashboard/settings"
              className="bg-red font-bold text-white text px-12 py-2 rounded-lg"
            >
              Go to Settings
            </Link>
          </div>
        </div>
      ) : isLoading ? (
        <div className="font-bold flex flex-col items-center">
          {isLoading && <Loader />}
          <h1 className="mb-4 md:text-3xl mt-10">
            Retrieving information from TikTok. Please wait.
          </h1>
        </div>
      ) : (
        <div className="font-bold flex flex-col items-center">
          <FcCheckmark className="w-36 h-36 mt-20" />
          <h1 className="mb-4 md:text-3xl mt-10">
            Your TikTok account has been successfully connected!
          </h1>
          <div className="flex justify-center mt-5">
            <Link
              to="/creator/dashboard/settings"
              className="bg-red font-bold text-white text px-12 py-2 rounded-lg"
            >
              Go to Settings
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default TiktokConnectSuccess;
