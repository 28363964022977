import React, { useEffect, useState, useContext } from 'react';
import TaskCard from './TaskCard';
import { useAuth } from '../../Context/AuthContext';
import { baseUrl } from '../../baseUrl';
import InfluencerTasks from './InfluencerTasks';
import { TaskService, useTaskState } from '../../Service/TaskServices';
import { BrandService, useBrandState } from '../../Service/BrandServices';
import MyTaskCard from './MyTaskCard';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';
import { useUserState , UsersService} from '../../Service/userServices';
import { useHistory } from 'react-router-dom';
import { Mixpanel } from '../../mixpanel';
import { useAccountState, AccountService } from '../../Service/AccountServices';

function RecommendedTasks(props) {
  const taskState = useTaskState();
  const userState = useUserState();
  const { currentUser, currentBrand } = useAuth();
  const history = useHistory();
  const brandState = useBrandState();
  const accountState = useAccountState();
  const [loading, setLoading] = useState(true);
  let account = accountState.account.value;
  setTimeout(() => {
    setLoading(false);
  }
  , 1200);
  useEffect(() => {
    const fetchAccountInfo = async () => {
      if (currentUser.uid !== null) {
        await AccountService.FetchAccountInfo(currentUser.uid);
      }
    };
  
    fetchAccountInfo();
  }, [currentUser.uid]);
  
  useEffect(() => {
    if (accountState.account.value && accountState.account.value.brands) {
      console.log("IS BRAND");
      history.push("/brand/dashboard");
    }
  }, [accountState.account.value]);
  {/*useEffect(() => {
    const connectToTikTok = async () => {
      const url = new URL(window.location.href);
      const urlParams = new URLSearchParams(url.search);
      const code = urlParams.get('code'); // Retrieve the code parameter from the URL
  
      console.log("Code: " + code);
    
      const apiUrl = `${baseUrl}api/influencers/tiktok/onboarding/${currentUser.uid}?code=${code}`;
  
      try {
        const response = await fetch(apiUrl, { method: 'GET' });
        const data = await response.json();
  
        // Handle the response data
        console.log(data);
      } catch (error) {
        // Handle the error
        console.error(error);
      }
    };
  
    // Check if the code parameter is present in the URL
    const url = new URL(window.location.href);
    const urlParams = new URLSearchParams(url.search);
    const code = urlParams.get('code');
    if (code) {
      // Run the onboarding process
      connectToTikTok();
    }
  }, []);*/}

  console.log("User: ", props.user);
  useEffect(() => {
    TaskService.FetchTasks();
  }, [taskState.updateNeed.value]);

  const res = taskState.tasks.value;
  const tasks = res ? Object.entries(res).map((e) => ({ [e[0]]: e[1] })) : [];  
  console.log("tasks", tasks)


  
  return (
    <div className={`bg-white rounded-lg w-full h-full ${loading ? 'bg-white rounded-lg w-full h-full' : ' bg-white rounded-lg w-full h-full overflow-y-scroll'}`} style={{height: '40vh'}}> 
    <div  className='w-full' style={{ position: 'sticky', top: 0, zIndex: 1 }}>
      <h3 className="font-semibold ml-4 items-start bg-white w-full 2xl:text-base md:text-0.7 xs:text-sm">Recommended Briefs</h3>
    </div>
    <div className=" m-4 md:h-16rem " style={{maxHeight: '400px'}}>
  <div className="grid lg:grid-cols-2 grid-cols-1 md:grid-cols-2 gap-2 mx-2">
      {tasks.length > 0 &&
      tasks
        .slice(0)
        .reverse()
        .filter((item) => {
          const res1 = item[Object.keys(item)];

          // Get the current date and subtract 3 weeks from it
          const threeWeeksAgo = new Date();
          threeWeeksAgo.setDate(threeWeeksAgo.getDate() - 21);

          // Check if the task meets the filter conditions
          return (
            res1.status &&
            res1.status !== 'approved' &&
            res1.status !== 'accepted' &&
            res1.status !== 'revisions' &&
            res1.status !== 'drafted' &&
            res1.status !== 'completed' &&
            (!res1.proposals || !Object.values(res1.proposals).some(proposal => proposal.creator_id === currentUser.uid)) &&
            (!res1.accepted_proposals || Object.entries(res1.accepted_proposals).length < res1.product_showcase) &&
            new Date(res1.date_issued) >= threeWeeksAgo && // Check if the date_issued of the task is within the last 3 weeks
            res1.regions && res1.regions.some(region => 
              region && region.value === 'USA'
            )
          );
        })
        .sort((a, b) => {
          // Sort tasks based on the number of proposals
          const resA = a[Object.keys(a)];
          const resB = b[Object.keys(b)];
          const proposalsA = resA.proposals ? Object.values(resA.proposals).length : 0;
          const proposalsB = resB.proposals ? Object.values(resB.proposals).length : 0;
          return proposalsA - proposalsB;
        })
        .slice(0,4)
        .map((item, i) => {
          const res1 = item[Object.keys(item)];
          const id = Object.keys(item);

          return (
            <div key={i}>
              <TaskCard task={res1} id={id} />
            </div>
          );
        })}
      </div>
    </div>
    </div>
  );

}

export default RecommendedTasks;
