import React, { useEffect, useState } from 'react';
import MyInfluencerTaskCard from './MyInfluencerTaskCard';
import { baseUrl } from '../../baseUrl';
import { useAuth } from '../../Context/AuthContext';
import Loader2 from '../Loader2';

function AmplifyBriefPreview(props) {
  const [loading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false);
  }, 1200);
  const influencerTasksArray = props.influencerTasks && Object.entries(props.influencerTasks).map((e) => ({ id: e[0], ...e[1] }))
  return (
    <div className={`bg-white rounded-lg w-full h-full ${loading ? '' : 'overflow-y-scroll'}`} style={{height: '40vh'}}> 
      <div  className='w-full' style={{ position: 'sticky', top: 0, zIndex: 1 }}>
        <h3 className="font-semibold ml-4 bg-white w-full 2xl:text-base md:text-0.6 xs:text-sm">Ongoing Influencer Briefs</h3>
      </div>
      {loading ? (
       <div className = "w-full"style={{maxHeight: '40vh'}}>
        <Loader2/>
      </div>
      ) : (
        <div className="bg-white rounded-lg h-fit p-2 xs:p-0">
          <div className="h-21" style={{height: '40vh'}}>
            {(!influencerTasksArray || influencerTasksArray.length === 0 || 
              influencerTasksArray.filter(item => ["approved", "drafted", "revision", "submitted"].includes(item.status)).length === 0) && (
              <>
                <div className="md:text-3xl flex mt-10 font-bold items-center justify-center">
                  You have no current influencer briefs.
                </div>
                <div className="md:text-3xl text-center text-lg flex mt-10 font-bold items-center justify-center">
                  Influencer briefs will appear here once you have applied and are accepted for one.
                </div>
              </>
            )}
            <div className="  m-4 grid lg:grid-cols-2 xs:grid-cols-1 grid-cols-2 md:grid-cols-2 gap-2 ">
              {influencerTasksArray &&
                influencerTasksArray
                  .filter((item) => ["approved", "drafted", "revision", "submitted"].includes(item.status))
                  .slice(0)
                  .reverse()
                  .slice(0,4)
                  .map((item, i) => {
                    const id = item.id; // Extract id from the key
                    return (
                      <div key={i}>
                        <MyInfluencerTaskCard task={item} id={id} fetchInfluencerTasks={props.influencerTasks} />
                      </div>
                    );
                  })}
                {influencerTasksArray.filter((item) => ["approved", "drafted", "revision", "submitted"].includes(item.status)).length > 4 && (
                  <div className="flex justify-center mt-2">
                  <div className="bg-red text-white flex rounded-md items-center justify-center p-2 backdrop-blur">
                    <a href="/creator/dashboard/mytasks" className="text-white">
                      <span>View More</span>
                    </a>
                  </div>
                </div>
                )}
            </div>
          </div>
        </div>
      )}
  </div>
  );
}

export default AmplifyBriefPreview;