import React, { useState } from 'react';
import '../App.css';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import logo from '../Assets/logo-red.png';
import PasswordReset from './PasswordReset';
import SignInModal from './SignInModal';
import SignUpModal from './SignUpModal';

function AuthModal(props) {
  const [isOpen, setOpen] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  function closeModal() {
    setOpen(false);
    if (props.closeAction) {
      props.closeAction();
    }
  }

  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => {
          props.closeAction();
        }}
        center
        classNames={{ modal: 'customModal' }}
        closeOnOverlayClick={false}>
        <div>
          <img src={logo} alt="logo" className="mx-auto" />
          <ul id="tabs" className="flex mb-14">
            <li
              className={
                currentTab === 0
                  ? `w-full px-7 font-semibold py-2 border-solid border-b-4 border-Red text-center text-Red`
                  : `w-full px-7 font-semibold py-2 border-solid border-b-4 border-transparent text-center`
              }
              onClick={() => {
                setCurrentTab(0);
              }}>
              <p id="default-tab" className="">
                Log In
              </p>
            </li>
            <li
              className={
                currentTab === 1
                  ? `w-full px-7 font-semibold py-2 border-solid border-b-4 border-Red text-center text-Red`
                  : `w-full px-7 font-semibold py-2 border-solid border-b-4 border-transparent text-center`
              }
              onClick={() => {
                setCurrentTab(1);
              }}>
              <p className="">Sign Up</p>
            </li>
          </ul>
          <div>
            {currentTab === 0 ? (
              <>
                <SignInModal
                  handleClose={() => {
                    closeModal();
                  }}
                />
                <p
                  className="text-darkGray font-regular italics text-center cursor-pointer"
                  onClick={() => setCurrentTab(2)}>
                  Forgot Password
                </p>
                <p
                  className="text-black font-bold text-center cursor-pointer"
                  onClick={() => setCurrentTab(1)}>
                  Don't have an account? Create one.
                </p>
              </>
            ) : null}
            {currentTab === 1 ? (
              <>
                <SignUpModal
                  handleClose={() => {
                    closeModal();
                  }}
                />
                <p
                  className="text-black font-bold text-center cursor-pointer"
                  onClick={() => setCurrentTab(0)}>
                  Have an account already? Log in.
                </p>
              </>
            ) : null}
            {currentTab === 2 ? (
              <>
                <PasswordReset
                  handleClose={() => {
                    closeModal();
                  }}
                />
                <p
                  className="text-black font-bold text-center cursor-pointer"
                  onClick={() => setCurrentTab(1)}>
                  Don't have an account? Create one.
                </p>
                <p
                  className="text-black font-bold text-center cursor-pointer"
                  onClick={() => setCurrentTab(0)}>
                  Have an account already? Log in.
                </p>
              </>
            ) : null}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AuthModal;
