import React, { useState, useEffect } from 'react';
import { BiLogOut } from 'react-icons/bi';
import { FiSettings, FiVideo, FiBox, FiMail, FiZoomIn } from 'react-icons/fi';
import { MdGroups } from 'react-icons/md';
import { HiOutlineUsers, HiOutlineCash, HiOutlinePencilAlt } from 'react-icons/hi';
import { CgProfile } from 'react-icons/cg';
import { MdOutlineDashboard, MdLiveTv } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { useAuth } from '../../Context/AuthContext';
import { AiOutlineMail } from 'react-icons/ai';
import CustomButton from '../../utils/customButton';
function Sidebar() {
  const { logout } = useAuth();
  const handleLogOut = () => {
    logout();
  };
  const [pathname, setPathname] = useState('dashboard');
  const path = window.location.pathname;
  useEffect(() => {
    switch (path) {
      case '/admin':
        setPathname('admin');
        break;
      case '/admin/profile':
        setPathname('profile');
        break;
      case '/admin/tasks':
        setPathname('tasks');
        break;
      case '/admin/events':
        setPathname('events');
        break;
      case '/admin/orders':
        setPathname('orders');
        break;
      case '/admin/users':
        setPathname('users');
        break;
      case '/admin/communities':
        setPathname('communities');
        break;
      case '/admin/requests':
        setPathname('requests');
        break;
      case '/admin/products':
        setPathname('products');
        break;
        case '/admin/balances':
        setPathname('balances');
        break;
      case '/admin/assets':
        setPathname('assets');
        break;
      case '/admin/billing':
        setPathname('billing');
      case '/admin/drafts':
        setPathname('drafts');
        break;
      case '/admin/email':
        setPathname('email');
        break;
      case '/admin/discovery':
        setPathname('discovery');
        break;
      default:
        break;
    }
  }, [path]); 

  return (
<div className="flex flex-col 2xl:w-64 md:w-48  m-4 sticky text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800 rounded-md shadow dark-mode:bg-gray-800 flex-shrink-0" style={{height: '85vh'}}>
      <nav className="flex-grow md:block px-4 pb-4 md:pb-0 md:overflow-y-auto">
        <Link
          className={
            pathname === 'admin'
            ? 'block px-4 py-2 mt-2  2xl:text-2xl text-base md:text-0.7  font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2  2xl:text-2xl text-base md:text-0.7 font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/admin">
          <MdOutlineDashboard className="mr-2 mb-1.5 inline 2xl:w-8 2xl:h-6" />
          Dashboard
        </Link>

        {/*<Link
          className={
            pathname === 'profile'
              ? 'block px-4 py-2 mt-2 text-sm font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/admin/profile">
          <CgProfile className="mr-2 mb-1.5 inline w-5 h-2 2xl:w-8 2xl:h-6" />
          My Profile
        </Link>*/}
        <Link
          className={
            pathname === 'users'
            ? 'block px-4 py-2 mt-2  2xl:text-2xl text-base md:text-0.7  font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2  2xl:text-2xl text-base md:text-0.7 font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/admin/users">
          <HiOutlineUsers className="mr-2 mb-1.5 inline 2xl:w-8 2xl:h-6" />
          Users
        </Link>
        <Link
          className={
            pathname === 'balances'
            ? 'block px-4 py-2 mt-2  2xl:text-2xl text-base md:text-0.7  font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
            : 'block px-4 py-2 mt-2  2xl:text-2xl text-base md:text-0.7 font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/admin/balances">
          <HiOutlineCash className="mr-2 mb-1.5 inline 2xl:w-8 2xl:h-6" />
          Creator Payouts
        </Link>
        {/*<Link
          className={
            pathname === 'drafts'
              ? 'block px-4 py-2 mt-2 text-sm font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/admin/drafts">
          <HiOutlinePencilAlt className="mr-2 mb-1.5 inline 2xl:w-8 2xl:h-6" />
          Drafts
        </Link>*/}
       {/*  <Link
          className={
            pathname === 'communities'
              ? 'block px-4 py-2 mt-2 text-sm font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/admin/communities">
          <MdGroups className="mr-2 mb-1.5 inline 2xl:w-8 2xl:h-6" />
          Communities
        </Link>
        <Link
          className={
            pathname === 'orders'
              ? 'block px-4 py-2 mt-2 text-sm font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/admin/orders">
          <FiSettings className="mr-2 mb-1.5 inline 2xl:w-8 2xl:h-6" />
          Orders
        </Link>*/}
        {/* <Link
          className={
            pathname === 'events'
              ? 'block px-4 py-2 mt-2 text-sm font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/admin/events">
          <MdLiveTv className="mr-2 mb-1.5 inline 2xl:w-8 2xl:h-6" />
          Events
        </Link> 
        <Link
          className="block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline"
          to="/brand/dashboard">
          <MdLiveTv className="mr-2 mb-1.5 inline 2xl:w-8 2xl:h-6" />
          Brands
        </Link>
        <Link
          className={
            pathname === 'assets'
              ? 'block px-4 py-2 mt-2 text-sm font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/admin/assets">
          <MdLiveTv className="mr-2 mb-1.5 inline 2xl:w-8 2xl:h-6" />
          UGC Assets
        </Link>*/}

        <Link
          className={
            pathname === 'tasks'
            ? 'block px-4 py-2 mt-2  2xl:text-2xl text-base md:text-0.7  font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2  2xl:text-2xl text-base md:text-0.7 font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/admin/tasks">
          <FiBox className="mr-2 mb-1.5 inline 2xl:w-8 2xl:h-6" />
          UGC Tasks
        </Link>
        {/* <Link
          className="block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline"
          to="/admin/streams">
          <FiVideo className="mr-2 mb-1.5 inline 2xl:w-8 2xl:h-6" />
          Streams
        </Link> */}
        {/* <Link
          className={
            pathname === 'drafts'
              ? 'block px-4 py-2 mt-2 text-sm font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/admin/drafts">
          <MdLiveTv className="mr-2 mb-1.5 inline 2xl:w-8 2xl:h-6" />
          Drafts
        </Link> */}
        <Link
          className={
            pathname === 'email'
            ? 'block px-4 py-2 mt-2  2xl:text-2xl text-base md:text-0.7  font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
            : 'block px-4 py-2 mt-2  2xl:text-2xl text-base md:text-0.7 font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/admin/email">
          <FiMail className="mr-2 mb-1.5 inline 2xl:w-8 2xl:h-6" />
          Emails
        </Link>
        <Link
          className={
            pathname === 'discovery'
            ? 'block px-4 py-2 mt-2  2xl:text-2xl text-base md:text-0.7  font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
            : 'block px-4 py-2 mt-2  2xl:text-2xl text-base md:text-0.7 font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/admin/discovery">
          <FiZoomIn className="mr-2 mb-1.5 inline 2xl:w-8 2xl:h-6" />
          Creator Discovery
        </Link>
      </nav>
      <CustomButton
        className=" text-white font-bold py-2 px-1 text-lg m-2 rounded-md"
        onClick={() => handleLogOut()}>
        <BiLogOut className="mr-2 mb-1.5 inline 2xl:w-8 2xl:h-6" />
        Logout
      </CustomButton>
    </div>
  );
}

export default Sidebar;
