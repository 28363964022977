import React, { useContext, useState } from 'react';
import { BiLogOut } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { useAuth } from './../Context/AuthContext';
import UpdateProfileModal from './UpdateProfileModal';
import { BrandContext } from '../routes/PrivateRoute/BrandDashboardRoute';

function ProfileDropDown(props) {
  const history = useHistory();
  const { logout } = useAuth();
  const {brandId, setBrandId} = useContext(BrandContext);


  const [OpenProfileModal, setOpenProfileModal] = useState(false);
  async function handleLogout(e) {
    e.preventDefault();
    localStorage.removeItem('brandId');
    setBrandId('');
    history.push('/brand/login');
    logout()
      .then(() => {})
      .catch(() => {});
  }

  const handleCreator = () => {
    history.push('/creator/dashboard/tasks');
  };
  const handleRecipe = () => {
    history.push('/creator/dashboard/recipes');
  };

  function handleOpenProfile() {
    if (!OpenProfileModal) {
      setOpenProfileModal(true);
    } else {
      setOpenProfileModal(false);
    }
  }
  return (
    <div className=''>
      <div className="origin-top-right absolute right-0  top-9 w-48 py-2 mt-1 bg-white rounded shadow-md z-50">
        <ul className="space-y-3 dark:text-white">
          <li className="font-medium">
            <div
              className="pl-2 flex items-center transform transition-colors duration-200 border-r-4 border-transparent hover:border-indigo-700"
              onClick={handleOpenProfile}>
              Update Profile
            </div>
          </li>

          {/*<hr className="dark:border-gray-700" />
          <li className="font-medium">
            <div
              className="pl-2 flex items-center transform transition-colors duration-200 border-r-4 border-transparent hover:border-red-600"
              onClick={handleCreator}>
              Creator Dashboard
            </div>
          </li>

          <hr className="dark:border-gray-700" />
          <li className="font-medium">
            <div
              className="pl-2 flex items-center transform transition-colors duration-200 border-r-4 border-transparent hover:border-red-600"
              onClick={handleRecipe}>
              Add Recipe
            </div>
          </li>*/}
          <hr className="dark:border-gray-700" />
          <li className="font-medium">
            <div
              className="flex items-center transform transition-colors duration-200 border-r-4 border-transparent hover:border-red-600"
              onClick={handleLogout}>
              <BiLogOut className="mr-2 mb-1.5 inline w-8 h-6" />
              Logout
            </div>
          </li>
        </ul>
      </div>

      {OpenProfileModal ? (
        <UpdateProfileModal
          closeAction={() => {
            setOpenProfileModal(false);
          }}
        />
      ) : null}
    </div>
  );
}

export default ProfileDropDown;
