import React, { useState, useEffect, useContext } from 'react';
import { useProductState, ProductService } from '../../../Service/ProductServices';
import { BrandContext } from '../../../routes/PrivateRoute/BrandDashboardRoute';
import GenerateBriefLoading from './GenerateBriefLoading';
import GenerateBriefResult from './GenerateBriefResult';
import CustomGlobalDialog from '../CustomGlobalDialog';
import { useGenerativeBriefState, GenerativeBriefService } from '../../../Service/GenerativeBriefService';
import { toast, Toaster } from 'react-hot-toast';
import Loader from '../../Loader2';


const GenerateBriefForm = ({ step, setStep, setBriefData,  setBriefId }) => {
  const [productDescription, setProductDescription] = useState('');
  const [uniqueSellingPoints, setUniqueSellingPoints] = useState('');
  const [targetAudience, setTargetAudience] = useState('');
  const [videoConcept, setVideoConcept] = useState('');
  const productState = useProductState();
  const [selectedProduct, setSelectedProduct] = useState('');
  const {brandId} = useContext(BrandContext);
  const genBriefState = useGenerativeBriefState();
  const [isLoading, setIsLoading] = useState(true);

  // loading the form
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 200); // 1000ms = 1 second
  
    // Cleanup function to clear the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);
    // fetching products
    useEffect(() => {
      // tabState.merge({ currentTab: 1 });
      if (productState.updateNeed.value) {
          ProductService.FetchProductForUser(brandId);
      }
    }, [productState.updateNeed.value]);

    const res = JSON.stringify(productState.products.value);

    const productTemp = [];
    if (productState.products.value){
        Object.keys(JSON.parse(res)).forEach(el => {
        productTemp.push({
            id: el,
            ...JSON.parse(res)[el]
        })
    })
    }

    // Handle form submission here
    const handleSubmit = async (e) => {
      e.preventDefault();
      setStep(1); 
      // You can access the form values using the state variables
      console.log({
        selectedProduct,
        uniqueSellingPoints,
        targetAudience,
        videoConcept
      });
    
      const requestBody = {
        featured_product: selectedProduct,
        usp: uniqueSellingPoints,
        target_audience: targetAudience,
        video_concept: videoConcept,
        brand_id: brandId,
      };
    
      try {
        // Retrieve the brief ID and brief data from the response
        const { briefId, briefData } = await GenerativeBriefService.createBriefTemplate(requestBody);
        setBriefId(briefId);
        setBriefData(briefData);
        setStep(2);
        console.log("Brief ID:", briefId);
        console.log("Brief data:", briefData);
        toast.success("Brief created successfully");
      } catch (error) {
        console.error("An error occurred while creating the brief:", error);
        setStep(0);
        toast.error("An error occurred while creating the brief");
      }
    };
   

  return (
      <div className='overflow-hidden 2xl:text-2xl'>
        <div className='border-b w-full my-5 text-center 2xl:text-3xl md:text-2xl font-bold'>
          Generate Brief
        </div>
        <p className='text-gray-500 2xl:text-base md:text-0.7 text-center my-4'>Here you'll be able to create a full brief by filling out your basic information for your brief below</p>
        <form onSubmit={handleSubmit} className="p-3 mx-auto space-y-6">
          <label className="block">
            <span className="text-black mb-2 font-bold 2xl:text-2xl md:text-base">What is the name of your product?</span>
            <div className="my-2">
            <input
              type="text"
              required placeholder ="List the name of your product (ie. Veggie Burger)"
              value={selectedProduct}
              onChange={(e) => setSelectedProduct(e.target.value)}
              className="mr-2 outline-none text-base bg-gray-100 2xl:p-3 md:p-2  rounded-lg w-full"
            />
            </div>
          </label>
          <label className="block">
            <span className="text-black mb-2 font-bold 2xl:text-2xl md:text-base">Unique Selling Points of Product:</span>
            <input
              type="text"
              required placeholder ="List the unique selling points of your product (ie. ready to eat under 15 min, gluten-free)"
              value={uniqueSellingPoints}
              onChange={(e) => setUniqueSellingPoints(e.target.value)}
              className="mr-2 outline-none text-base bg-gray-100 2xl:p-3 md:p-2  rounded-lg w-full"
            />
          </label>
          <label className="block">
            <span className="text-black mb-2 font-bold 2xl:text-2xl md:text-base">Target Audience:</span>
            <input
              type="text"
              required placeholder ="List your target audiences (ie. vegans, on the go moms)"
              value={targetAudience}
              onChange={(e) => setTargetAudience(e.target.value)}
              className="mr-2 outline-none text-base bg-gray-100 2xl:p-3 md:p-2  rounded-lg w-full"
            />
          </label>
          <label className="block">
            <span className="text-black mb-2 font-bold 2xl:text-2xl md:text-base">Describe the video concept:</span>
            <input
              type="text"
              value={videoConcept}
              required placeholder ="Describe the purpose of the campaign and the video concept."
              onChange={(e) => setVideoConcept(e.target.value)}
              className="mr-2 outline-none text-base bg-gray-100 2xl:p-3 md:p-2  rounded-lg w-full"
            />
          </label>
          <button type="submit" className="bg-red flex mx-auto items-center justify-center text-white py-2 px-4 rounded-md">
            Use AI to get started
          </button>
        </form>
      </div>
  );
};

export default GenerateBriefForm;