import React, { useEffect, useState, useRef, useContext } from 'react';
import { useDraftState, DraftService } from '../../../../Service/DraftService';
import { useTaskState, TaskService } from '../../../../Service/TaskServices';
import { useCampaignState, CampaignService } from '../../../../Service/CampaignServices';
import DraftVideo from './DraftVideo';
import { useInfluencerTaskState, InfluencerTaskService } from '../../../../Service/InfluencerTaskService';
import Loader2 from '../../../Loader2';
import { useAuth } from '../../../../Context/AuthContext';
import { Carousel } from 'react-responsive-carousel';// requires a loader
import { Link } from 'react-router-dom';
import { validateFormFields } from '../../../../utils/functions';
import { useParams } from 'react-router-dom';
import Loader from '../../../LoaderRed';
import { IoMdArrowDropright } from "react-icons/io";
import { IoMdArrowDropleft } from "react-icons/io";
import GlobalDialog from '../../../../utils/brandModal';
import { toast, Toaster } from 'react-hot-toast';
import { CampaignEmailService } from '../../../../Service/CampaignEmailService';
import { BrandContext } from '../../../../routes/PrivateRoute/BrandDashboardRoute';
import CustomButton from '../../../../utils/customButton';

function VideoDrafts() {
  const { id } = useParams();
  const [currentVideo, setCurrentVideo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [error, setError] = useState({
    avatar: ''
  });
  const [deployAsset, setDeployAsset] = useState(false);
  const [sendNote, setSendNote] = useState(false);
  const [selectedDraft, setSelectedDraft] = useState(null);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const notes = useRef('');
  const [isSelected, setIsSelected] = useState("");
  const [isHover, setIsHover] = useState(false);
  const campaignState = useCampaignState();
  const [videos, setVideos] = useState({})
  const [reviews, setReviews] = useState(false);
  const [recipe, setRecipe] = useState(false);
  const [photos, setPhotos] = useState(false);
  const [drafts, setDrafts] = useState([]);

  const [selectedTaskIndex, setSelectedTaskIndex] = useState(0);

  const fieldErrors = {
    note: 'Please Enter Note'
  };
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  
    return () => clearTimeout(timeout);
  }, []);
  const fetchData = async () => {
    if (id) {
      setLoading(true);
      const campaignData = await CampaignService.FetchSingleCampaign(id);
      setLoading(false);
  
      if (campaignData) {
        const tasks = Object.keys(campaignData.tasks).length !== 0 ? Object.values(campaignData.tasks) : [];
        // Use the tasks data as needed
        
        // Assign the tasks to the state or perform any other logic with the tasks data
      }
    }
  };
  
  useEffect(() => {
    fetchData();
  }, [id]);
  
  const campaign = campaignState.campaign.value;
  const tasks = Object.keys(campaign.tasks).length !== 0 ? Object.values(campaign.tasks) : [];
  
  //console.log("tasks:");
  //console.log(tasks);
 // console.log("tasks[0]:");
 // console.log(tasks[selectedTaskIndex]);
  
  const approveDraft = async (draft) => {
    let approvedData = {
      drafts: [{ video: draft.video }],
      creator_id: draft.creator_id,
      campaign_id: id,
      creator_name: draft.creator_name,
      brand_id: draft.brand_id,
      task_id: draft.task_id
    };
    
    
    const emailData = {
      creator_id: draft.creator_id,
      brand_id: brandId,
      task_id: draft.task_id,

    }

    await CampaignService.ApproveDraft(draft.task_id, approvedData);  
    CampaignEmailService.InfluencerSubmitLinkEmail(emailData);
   //  await CampaignEmailService.SendDeliverablesEmail(emailData);
    fetchData(); // Fetch the updated drafts data after approving the draft
    toast.success('Draft Approved Successfully! please leave a review in the "Leave Reviews" tab.');
    setSelectedDraft(null); // Reset the selected draft
  };
  
  const sendRevisionNote = async (draft) => {
    let revisionData = {
      task_id: draft.task_id,
      campaign_id: id,
      creator_id: draft.creator_id,
      brand_id: draft.brand_id,
      revision_notes: notes.current.value
    };

    const emailData = {
      creator_id: draft.creator_id,
      brand_id: brandId,  
      task_id: draft.task_id,
      revision_notes: notes.current.value
    }
    await InfluencerTaskService.SendRevisions(draft.task_id, revisionData);
    await CampaignEmailService.SendRevisionEmail(emailData);
    fetchData(); // Fetch the updated drafts data after sending the revision note
    toast.success('Revision Notes Sent');
    setSelectedDraft(null);
  };
  
  const undoApproval = async (draft) => {
    let data = {
    task_id: draft.task_id,
    campaign_id: id,
    creator_id: draft.creator_id,
    brand_id: draft.brand_id
  }
  //console.log("this is the body", data);
    await CampaignService.UndoDraftApproval(draft.creator_id, data);
    fetchData(); // Fetch the updated drafts data after undoing the approval
    toast.success('Draft Undone');
    setSelectedDraft(null); // Reset the selected draft
  }


  const handleReview = (draft) => {
    setSelectedDraft(draft);
    setReviews(!reviews);
  }

  const handleRecipe = (draft) => {
    setSelectedDraft(draft);
    setRecipe(!recipe);
  }

  const handlePhotos = (draft) => {
    setSelectedDraft(draft);
    setPhotos(!photos);
  }


  const { currentUser } = useAuth();
  const { brandId } = useContext(BrandContext);

  const openRevision = (draft) => {
    setSelectedDraft(draft)
    setSendNote(!sendNote)
  }

  const handleSelectTask = (item, i) => { 
    setSelectedTaskIndex(i);
  };

  function downloadPhotoInNewTab(url) {
    window.open(url, '_blank');
  }
  
  return (
    <div className=" bg-white w-full p-4 rounded-lg z-100  overflow-x-auto" style={{height: '90vh'}}>
       <Toaster />
      <div>
          <div className={` mx-2 pb-5 flex flex-row h-full `} style={{ height: '100%' }}>
            <div className={`mb-5 w-3/12 h-23`}>
              {campaignState.loading.value ? (
                <Loader2 />
              ) : (
                <div
                  className="overflow-y-scroll video-revisions"
                  style={{ height: '100%' }}>
                  <div>
                    {Object.keys(tasks).length > 0 ? (
                      <>
                        {Object.values(tasks).map((item, i) => {
                          if (item.campaign_task) {
                            return (
                              <div key={i} onClick={()=> handleSelectTask(item, i)} className="mt-2 mb-4 mr-2 cursor-pointer" >
                                <div className={`block px-4 py-2 mt-2 text-0.7 font-semibold rounded  text-white bg-${selectedTaskIndex == i ? "gray-900" : "gray-500"}  shadow-outline`}>
                                  <p>
                                    {item.name ? item.name : 'No Name'} 
                                  </p>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </>
                    ) : (
                      <div className="h-full w-full flex items-center justify-center">
                        <p>No drafts available</p>
                      </div>
                    )}
                  </div>

                  <div className="z-100 bg-lightGray">
                    <div
                      // className="max-h-min z-0 p-3 rounded-lg my-2 bg-black text-white cursor-pointer"
                      // onClick={() => deployHandle(data, id, i)}
                    >
                      {
                        videos && Object.keys(videos).map((video,i)=>{
                          return(
                            <p className={` py-3 px-2 rounded  text-gray-500 mr-2 bg-${
                              i % 2 === 0 ? 'lightGray' : 'white'
                            } border-none`}>{video}</p>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="text-center h-full w-1/2">
              <div className='relative h-10rem'>
                         <Carousel className="relative items-center" showIndicators={false} showArrows={true} showThumbs={true} useKeyboardArrows={true} swipeable={true}
                renderArrowPrev={(clickHandler, hasPrev) => {
                  return (
                    <div
                      className={`${
                        hasPrev ? "absolute" : "hidden"
                      } 2xl:top-1/3 md:top-8rem left-3 lg:left-10 xl:left-16 2xl:left-32 flex justify-center items-center p-3 opacity-100 hover:opacity-100 cursor-pointer z-20 transform -translate-y-1/2`}
                      onClick={() => {
                        if (currentVideo) {
                          currentVideo.pause(); // Pause the current video
                        }
                        clickHandler();
                        setSendNote(false);
                      }}
                    >
                      <IoMdArrowDropleft className="2xl:w-24 2xl:h-24 w-16 h-16 text-Blue" />
                    </div>
                  );
                }}
                renderArrowNext={(clickHandler, hasNext) => {
                  return (
                    <div
                      className={`${
                        hasNext ? "absolute" : "hidden"
                      } 2xl:top-1/3 md:top-8rem right-3 lg:right-10 xl:right-16 2xl:right-32 flex justify-center items-center p-3 opacity-100 hover:opacity-100 cursor-pointer z-20 transform -translate-y-1/2`}
                      onClick={() => {
                        if (currentVideo) {
                          currentVideo.pause(); // Pause the current video
                        }
                        clickHandler();
                        setSendNote(false);
                      }}
                    >
                      <IoMdArrowDropright className="2xl:w-24 2xl:h-24 w-16 h-16 text-Blue" />
                    </div>
                  );
                }}>
                {tasks[selectedTaskIndex].drafts &&
                  Object.values(tasks[selectedTaskIndex].drafts).some(draft => draft.status == "pending" || draft.status === "approved" || draft.status === "revision") ? (
                    Object.values(tasks[selectedTaskIndex].drafts)
                    .filter(draft => draft.status === "pending" || draft.status === "approved" || draft.status === "revision")
                    .map((draft, i) => {
                      return (
                      <div className='mb-2 '>
                        <h3 className='font-bold md:text-0.7 2xl:text-base w-full'>{draft.creator_name}</h3>
                        <div className="mx-auto w-full items-center justify-center text-center">
                        <video className="mx-auto w-fit 2xl:h-328 md:h-15rem" 
                        controls src={draft.video} onContextMenu={(e) => e.preventDefault()} controlsList=" nodownload fullscreen" style={{ objectFit: 'contain' }}
                        ref={(el) => {
                          if (el) {
                            el.onplay = () => setCurrentVideo(el); // Set the current video when it starts playing
                          }}}

                        /> 
                        <div className='my-3'>
                        {/*<a
                          href={draft.video}
                          download
                          target="_blank"
                          rel="noreferrer"
                          className="text-red hover:underline mb-4 px-4 text-0.7 font-bold py-3 rounded-lg mt-4 w-auto text-center cursor-pointer">
                          Having trouble viewing? Click here to download the video and review offline.
                        </a>*/}
                        </div>
                         <div className='flex flex-col items-center mx-auto justify-center w-1/2 '>
                          {draft && draft.photos && (
                                
                          <button className='text-gray-600' onClick={() => handlePhotos(draft)}>View Product Photos</button>
                              )}
                              {draft && draft.recipes && (
                              <button className='text-gray-600' onClick={() => handleRecipe(draft)}>View Recipes</button>
                              )}
                              {draft && draft.reviews && (
                            <button className='text-gray-600' onClick={() => handleReview(draft)}>View Review</button>
                        )}
                        </div>
                        {draft && draft.caption && draft.caption !== "" && ( 
                          <>
                            <h3 className='text-center md:text-0.6 2xl:text-base mt-1 font-bold'>Video Caption:</h3>
                            <div className="w-1/2 mx-auto h-4rem overflow-auto bg-gray-100 rounded-md border-2 text-center my-2 p-2">
                              <p className='md:text-0.7 2xl:text-base break-words'>
                                {draft && draft.caption}
                              </p>
                            </div>
                          </>
                        )}
                        </div>
                        {draft.status === "pending" && (
                          
                          <div className="my-2 mx-auto w-64 flex justify-center text-center">

                          <CustomButton
                              className=" px-1 mr-3 text-xs font-bold py-1 text-white mt-41 w-auto text-center cursor-pointer"
                              onClick={() => approveDraft(draft)}>
                              Approve Draft
                          </CustomButton>
                          <CustomButton
                                onClick={() => {
                                  setSelectedDraft(draft); // Set the selected draft
                                  setSendNote(!sendNote);
                                }}
                              className={`bg-${sendNote ? 'darkGray' : 'red'} px-1 text-xs font-bold py-1 rounded-lg text-white mt-41 w-auto text-center cursor-pointer`}>
                              Send Revisions
                          </CustomButton>
                      </div> 
                        )
                        
                        
                        }
                      
                        {draft.status === "revision" && (
                          <>
                          <div className="my-2 mx-auto  w-1/2 break-words flex flex-col items-center justify-center rounded-md text-center bg-white p-4">
                             <h1 className='2xl:text-base md:text-0.7'>Revision Notes</h1>
                             <div className="w-full mx-auto 2xl:text-base md:text-0.6 break-words rounded-md border-2 h-4rem overflow-auto text-center my-2">
                              {draft.revision_notes}
                            </div>
                            <CustomButton
                              onClick={() => {
                                setSelectedDraft(draft); // Set the selected draft
                                setSendNote(!sendNote);
                              }}
                              className={`px-4 py-2 text-xs font-bold text-white mt-2 w-auto cursor-pointer ${sendNote ? 'bg-darkGray' : 'bg-Blue'}`}
                            >
                              Edit revisions
                            </CustomButton>
                          </div>
                          </>
                        )}
                        
                        {draft.status === "approved" && (
                        <div className="my-2 mx-auto w-64 flex justify-center text-center">
                            <div
                                className="bg-green px-2 mr-3 text-xs font-bold py-1 rounded-md text-white mt-41 w-auto text-center">
                               Draft Approved
                            </div>
                        </div>
                        )}
                        
                    </div>
                      )
                    })
                    ) : (
                      <p>No drafts available.</p>
                    )}
                </Carousel>
              </div>
            
              {/* {deployAsset ? (
              <DraftVideo
                open={deployAsset}
                draftData={draftData.draftData}
                approveDraft={() => approveDraft()}
                onClose={() => setDeployAsset(false)}
                id={draftData.id}
                count={count}
                assetLen={assetLen}
                dCount={() => setCount(count - 1)}
                eCount={() => setCount(count + 1)}
                setSendNote={() => setSendNote(true)}
                sendNote={sendNote}
              />
              ) : null} */}
            </div>
          {sendNote ? (
          <div className="h-full w-6/3 mb-5">
            <div className="w-full bg-gray-200 mb-5 rounded-lg" style={{ height: '45vh' }}>
              <textarea
                className="focus:outline-none w-full bg-lightGray p-2 rounded-lg"
                style={{ height: '45vh' }}
                placeholder="Please provide revision note to the creator here..."
                name="notes"
                ref={notes}
              />
            </div>
            <div className="items-center">
              <CustomButton
                onClick={(e) => {
                  e.preventDefault(); // Prevent form submission
                  sendRevisionNote(selectedDraft); // Call the sendRevisionNote function
                  setSendNote(false); // Reset sendNote state
                }}
                className=" text-white font-bold px-4 py-2">
                Submit Notes
              </CustomButton>
            </div>
          </div>
        ) : null}
          {reviews ? (
          <div className="">
            <GlobalDialog open={reviews} onClose={() => setReviews(false)}>
              <h1 className='font-bold text-center'>{selectedDraft.creator_name}'s review</h1>
             <img className='w-1/2 h1/2 my-5 mx-auto flex justify-center object-cover rounded-md' onContextMenu={(e) => e.preventDefault()} src = {selectedDraft.reviews.review1}/>
             <CustomButton className='mx-auto flex items-center mb-4 text-white font-bold 2xl:px-10 2xl:py-3 md:px-5 md:py-2' onClick={() => downloadPhotoInNewTab(selectedDraft.reviews.review1)}>
                    Download
               </CustomButton>
            </GlobalDialog>
          </div>
        ): null}
         {photos ? (
          
          <GlobalDialog open={photos} onClose={() => setPhotos(false)}>
          <div className="flex mx-auto rounded-md bg-white w-full flex-col h-40 items-center">
            <h1 className='font-bold text-center'>{selectedDraft.creator_name}'s photos</h1>
            <div className="grid grid-cols-3 gap-4 mx-10 mt-10">
              {Object.values(selectedDraft.photos).map((photo, index) => (
                <div key={index} className="relative" style={{ paddingBottom: 'calc(33.333% - 0.5rem)' }}>
                 {isLoading ? (
                <div className='w-full h-full flex items-center justify-center'>
                  <Loader />
                </div>
                 ) : (
                <div>
                  <img
                    className='w-full h-full border-2 object-cover rounded-md'
                    loading='lazy'
                    src={photo.photo}
                    onContextMenu={(e) => e.preventDefault()}
                    onLoad={() => setIsLoading(false)}
                  />
                  <button className='mx-auto flex items-center mt-2 bg-red rounded-md text-white font-bold 2xl:px-10 2xl:py-3 md:px-5 md:py-2' onClick={() => downloadPhotoInNewTab(photo.photo)}>
                    Download
                  </button>
                </div>
                )}  
                </div>
              ))}
            </div>
          </div>
        </GlobalDialog>
          
        ) : null}
         {recipe ? (
          <div className="">
            <GlobalDialog open={recipe} onClose={() => setRecipe(false)}>
              <h1 className='font-bold text-center'>{selectedDraft.creator_name}'s Recipe</h1>
             <img onContextMenu={(e) => e.preventDefault()} className='w-fit rounded-md h-fit' src = {selectedDraft.recipe}/>
            </GlobalDialog>
          </div>
        ): null}
          </div>
      </div>
    </div>
  );
}

export default VideoDrafts;
