import React from 'react';
import UsersTable from './Users/UsersTable';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { UsersService, useUserState } from '../../Service/userServices';
import { useAuth } from '../../Context/AuthContext';
import { useBrandState, BrandService } from '../../Service/BrandServices';

function Users() {
  const { currentBrand, currentUser } = useAuth();
  const history = useHistory();

  const brandState = useBrandState();
  console.log(currentUser.uid)
  useEffect(() => {
    if (currentUser.uid !== null) {
      BrandService.FetchSingleBrand(currentUser.uid);
    }
  }, [brandState.updateNeed.value]);

  const brand = brandState.brand.value;

  {/*IuseEffect(() => {
    if (brand === null){
      history.push('/');
    }
    if (brand && Object.keys(brand).length > 0) {
      console.log("Brand object populated:", brand);
      if (brand.admin === true) {
        console.log("Brand admin is true");
      }
      else {
      history.push('/');
    } 
    }
  }, [brand]); */}

  return (
    <div className="bg-white m-4 rounded-lg w-full  overflow-scroll p-6">
      <h2 className="font-semibold py-2 text-2xl "> All Users</h2>
      <UsersTable />
    </div>
  );
}

export default Users;
