import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';

//State
const state = createState({
  user: [],
  users: [],
  stripe_user: '',
  retrieving: false,
  fetched: false,
  loading: false,
  updateNeed: true
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'USERS_LOADED':
        return s.merge({
          user: action.data,
          updateNeed: false,
          retrieving: true,
          fetched: true
        });
      case 'QUERIED_USERS_LOADED':
        return s.merge({
          user: action.data,
          updateNeed: false,
          retrieving: true,
          fetched: true
        });
      case 'BATCH_USERS_LOADED':
        return s.merge({
          user: action.data,
          updateNeed: false,
          retrieving: true,
          fetched: true
        })
      case 'CREATORS_LOADED':
        return s.merge({
          user: action.data,
          updateNeed: false,
          retrieving: true,
          fetched: true
        })
      case 'BALANCED_CREATORS_LOADED':
        return s.merge({
          users: action.data,
          updateNeed: false,
          retrieving: true,
          fetched: true
        })
      case 'CREATE_USERS':
        return s.merge({
          user: action.data,
          loading: false,
          updateNeed: true
        });
      case 'ADD_ADDRESS':
        return s.merge({
          loading: true,
          updateNeed: true
        });
      case 'ADD_NEGOTIATED_RATE':
        return s.merge({
          loading: false,
          updateNeed: true
        });
      case 'SETUP_PAYOUTS':
        return s.merge({
          stripe_user: action.data,
          loading: true,
          updateNeed: true
        });
      case 'USER_UPDATE':
        return s.merge({
          updateNeed: true,
          loading: false
        });
      case 'CREATOR_PORTFOLIO':
        return s.merge({
          updateNeed: true,
          loading: false
        });
      case 'USER_INFO':
        return s.merge({
          user: action.data,
          loading: false,
          updateNeed: true
        });
      case 'USER_NAME_INFO':
        return s.merge({
          user: action.data,
          loading: false,
          updateNeed: true
        });
      case 'USER_EMAIL_UPDATE':
        return s.merge({
          user: action.data,
          loading: false,
          updateNeed: true
        })
      case 'USER_DELETE':
        return s.merge({
          loading: false,
          updateNeed: true
        });
      case 'ALL_USER_INFO':
        return s.merge({
          user: action.data,
          loading: false,
          fetched: true,
          updateNeed: false
        });
      default:
        return s.merge({
          user: action.data,
          loading: false,
          fetched: true,
          updateNeed: true
        });
    }
  }, action.type);
});

export const accessUserState = () => state;

export const useUserState = () => useState(state);

//Service
export const UsersService = {
  FetchUsers: () => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/users`)
      .then((res) => {
        dispatch(UsersAction.loadUsers(res.data.result));
      })
      .catch((err) => {});
  },
  QueryUsers:  async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/users/query`, data)
      .then((res) => {
        dispatch(UsersAction.loadQueriedUsers(res));
      })
      .catch((err) => {});
  },
  LoadUsersInBatches: async (data) => {
    try {
      const response = await axios.get(`${baseUrl}api/users/batch`, { params: data });
      return response.data; // Return the data from the response
    } catch (error) {
      console.error('Error loading users:', error);
      return null; // Return null or handle the error as needed
    }
  }
  
  ,

  FetchBalancedCreators: async () => {
    const dispatch = useDispatch();
    return axios.get(`${baseUrl}api/users/getUsersWithBalances`)
      .then((res) => {
        dispatch(UsersAction.fetchBalancedCreators(res.data)); // Update the store with the fetched data
        return res.data; // Return the data for further processing in the component
      })
      .catch((err) => {
        // Handle any errors here or let them propagate
        throw err;
      })
  },

  FetchCreators: async () => {
    const dispatch = useDispatch();
    return axios.get(`${baseUrl}api/creator/getall`)
      .then((res) => {
        dispatch(UsersAction.fetchCreators(res.data)); // Update the store with the fetched data
        return res.data; // Return the data for further processing in the component
      })
      .catch((err) => {
        // Handle any errors here or let them propagate
        throw err;
      });
  },
  AddUser: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/users/createUser`, data)
      .then((res) => {
        dispatch(UsersAction.createUsers(res));
      })
      .catch((err) => {});
  },
  AddAddress: async (id, data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/users/addAddress/${id}`, data)
      .then((res) => {
        dispatch(UsersAction.addAddress(res));
      })
      .catch((err) => {});
  },
  SetupPayouts: async (id) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/creator/payouts/setup/${id}`)
      .then((res) => {
        dispatch(UsersAction.setupPayouts(res.data.result));
      })
      .catch((err) => {});
  },
  UpdateUser: (id, data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/users/updateUser/${id}`, data)
      .then((res) => {
        dispatch(UsersAction.updateUser(res));
      })
      .catch((err) => {});
  },
  UpdatePayPalEmail: (id, data) => {
      const dispatch = useDispatch();
      axios
        .put(`${baseUrl}api/users/updatePayPalEmail/${id}`, data)
        .then((res) => {
          dispatch(UsersAction.updatePayPalEmail(res));
        })
  },

  FetchBasicInfo: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/users/basic_info/${id}`)
      .then((res) => {
        dispatch(UsersAction.fetchBasicInfo(res.data.result));
      })
      .catch((err) => {});
  },
  CheckUserInfo: (name) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/users/checkUsername/${name}`)
      .then((res) => {
        dispatch(UsersAction.checkusername(res.data.result));
      })
      .catch((err) => {});
  },
  DeleteUser: (id) => {
    const dispatch = useDispatch();
    axios
      .delete(`${baseUrl}api/users/deleteUser/${id}`)
      .then((res) => {
        dispatch(UsersAction.deleteUser(res));
      })
      .catch((err) => {});
  },
  FetchUserInfo: (id) => {
    const dispatch = useDispatch();
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}api/users/${id}`)
        .then((res) => {
          const userInfo = res.data.result;
          dispatch(UsersAction.fetchUserInfo(userInfo));
          resolve(userInfo);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  AddCreatorPortfolio: (id, data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/users/addCreatorPortfolio/${id}`, data)
      .then((res) => {
        dispatch(UsersAction.addCreatorPortfolio(res));
      })
      .catch((err) => {});
  },
  AddNegotiatedRate : (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/users/addNegotiatedRate/${id}`, data)
      .then((res) => {
        dispatch(UsersAction.addNegotiatedRate(res));
      })
  }
};

//Action
export const UsersAction = {
  loadUsers: (data) => {
    return {
      type: 'USERS_LOADED',
      data: data
    };
  },
  loadQueriedUsers: (data) => {
    return {
      type: 'QUERIED_USERS_LOADED',
      data: data
    };
  },

  loadBatchUsers: (data) => {
    return {
      type: 'BATCH_USERS_LOADED',
      data: data
    }
  },
  
  fetchCreators : (data) => {
      return {
      type: 'FETCH_CREATORS',
      data: data
    }
  },

  fetchBalancedCreators : (data) => {
      return {
      type: 'BALANCED_CREATORS_LOADED',
      data: data
      }
  },
  createUsers: (data) => {
    return {
      type: 'CREATE_USER',
      data: data
    };
  },
  addAddress: (data) => {
    return {
      type: 'ADD_ADDRESS',
      data: data
    };
  },
  addNegotiatedRate: (data) => {
    return {
      type: 'ADD_NEGOTIATED_RATE',
      data: data
    };
  },

  setupPayouts: (data) => {
    return {
      type: 'SETUP_PAYOUTS',
      data: data
    };
  },
  updateUser: (data) => {
    return {
      type: 'USER_UPDATE',
      data: data
    };
  },  
  updatePayPalEmail: (data) => {
    return {
      type: 'USER_EMAIL_UPDATE',
      data: data
    };
  },

  addCreatorPortfolio: (data) => {
    return {
      type: 'CREATOR_PORTFOLIO',
      data: data
    };
  },
  fetchBasicInfo: (data) => {
    return {
      type: 'USER_INFO',
      data: data
    };
  },
  checkusername: (data) => {
    return {
      type: 'USER_NAME_INFO',
      data: data
    };
  },
  deleteUser: (data) => {
    return {
      type: 'USER_DELETE',
      data: data
    };
  },
  fetchUserInfo: (data) => {
    return {
      type: 'ALL_USER_INFO',
      data: data
    };
  }
};
