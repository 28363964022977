import React, { useContext, useEffect, useState } from 'react';
import { useAuth } from '../../Context/AuthContext';
import instagram from '../../Assets/instagram (1).png';
import viewIcon from '../../Assets/view_icon.png';
import rectangleIcon from '../../Assets/rectangle_icon.png';
import announce from '../../Assets/announce_icon.png';
import bucketIcon from '../../Assets/buy_cart_icon.png';
import { SlUserUnfollow } from "react-icons/sl";
import {BsEyeFill, BsFillPeopleFill, BsFillPersonFill, BsQuestionCircle} from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import tiktok from '../../Assets/tiktok-square.png';
import WhiteListingInstructions from './WhiteListingInstructions';
import { toast, Toaster } from 'react-hot-toast';
import Loader from '../LoaderRed';
import {Link} from 'react-router-dom';
import youtube from '../../Assets/youtube-shorts.png';
import logo from '../../Assets/logo.png';
import { BiDollar, BiDollarCircle } from 'react-icons/bi';
import ReactStars from 'react-rating-stars-component';
import { EmailService } from '../../Service/EmailService';
import { BsArrowLeft } from 'react-icons/bs';
import { IoMdFemale, IoMdMale  } from "react-icons/io";
import UgcCreatorRemovalModal from './UgcCreatorRemovalModal';
import { TaskService, useTaskState } from '../../Service/TaskServices';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';
import { InstagramService, useInstagramState } from '../../Service/InstagramService';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import ViewDemographicsModal from './ViewDemographicsModal';
import UgcApprovalModal from './UgcApprovalModal';
import UgcDenialModal from './UgcDenialModal';
import CustomButton from '../../utils/customButton';

function UgcProposals(props) {
  const taskState = useTaskState();
  const [taskData, setTaskData] = useState(null);
  const {currentUser} = useAuth();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [proposalRemove, setProposalRemove] = useState();
  const [dropdownVisible, setDropdownVisible] = useState(false);    
  const [sortBy, setSortBy] = useState(null);
  const [proposalKey, setProposalKey] = useState();
  const [modal, setModal] = useState(false);
  const [demoModal, setDemoModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedProposal, setSelectedProposal] = useState(null);
  const { brandId } = useContext(BrandContext);
  const [toggle1, setToggle1] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [tabs, setTabs] = useState(0);
  const [toggle3, setToggle3] = useState(false);
  const [toggle4, setToggle4] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [whitelist, setWhitelist] = useState(false);
  const [denyModal, setDenyModal] = useState(false);
  const [filteredProposals, setFilteredProposals] = useState([]);
  
  const instagramState = useInstagramState();
  const handleButtonClick = (proposal, proposalKey) => {
    setModal(true);
    setProposalRemove(proposal);
    setProposalKey(proposalKey);
  };
  
  const handleSelection = (proposal) => {
    setDemoModal(true);
    setProposalRemove(proposal);
    console.log("proposal: ", proposal);
    console.log("demo modal", demoModal);
  }
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const handleToggle1 = () => {
    setToggle1(prevToggle => !prevToggle);
  };
  
  useEffect(() => {
    // Reverse the filteredProposals array directly
    const reversedProposals = [...Object.values(filteredProposals)].reverse();
    //console.log('Reversed/Reset Proposals:', reversedProposals);
    
    // Update the state with the reversed array
    setFilteredProposals(reversedProposals);
  }, [toggle1]);

  useEffect(() => {
    const filtered = Object.values(filteredProposals).filter(proposal => proposal.status !== 'denied');
    const sortedProposals = filtered.sort((a, b) => {
      const followerCountSumA = (a.creator_socials && a.creator_socials.instagram && a.creator_socials.instagram.follower_count || 0) + (a.creator_socials && a.creator_socials.tiktok && a.creator_socials.tiktok.performance && a.creator_socials.tiktok.performance.followerCount || 0);
      const followerCountSumB = (b.creator_socials && b.creator_socials.instagram && b.creator_socials.instagram.follower_count || 0) + (b.creator_socials && b.creator_socials.tiktok && b.creator_socials.tiktok.performance && b.creator_socials.tiktok.performance.followerCount || 0);
  
      if (toggle2) {
        return followerCountSumB - followerCountSumA; // Sort in descending order of follower count sum
      } else {
        return followerCountSumA - followerCountSumB; // Sort in ascending order of follower count sum
      }
    });
  
    //console.log('Sorted Proposals:', sortedProposals);
  
    // Update the state with the sorted array
    setFilteredProposals(sortedProposals);
  }, [toggle2]);

  useEffect(() => {
    const filtered = Object.values(filteredProposals).filter(proposal => proposal.status !== 'denied');
    const sortedProposals = filtered.sort((a, b) => {
      const instagramValueA = Math.floor((a.creator_socials && a.creator_socials.instagram && a.creator_socials.instagram.median_plays || 0) || (Math.floor((a.creator_socials && a.creator_socials.instagram && a.creator_socials.instagram.suggested_rate)/2500)*1000) || 0);
      const tiktokValueA = (a.creator_socials && a.creator_socials.tiktok && a.creator_socials.tiktok.performance && a.creator_socials.tiktok.performance.median_views || 0) || Math.round((a.creator_socials && a.creator_socials.tiktok && a.creator_socials.tiktok.performance && a.creator_socials.tiktok.performance.avg10_views) || 0);
  
      const instagramValueB = Math.floor((b.creator_socials && b.creator_socials.instagram && b.creator_socials.instagram.median_plays || 0) || (Math.floor((b.creator_socials && b.creator_socials.instagram && b.creator_socials.instagram.suggested_rate)/2500)*1000) || 0);
      const tiktokValueB = (b.creator_socials && b.creator_socials.tiktok && b.creator_socials.tiktok.performance && b.creator_socials.tiktok.performance.median_views || 0) || Math.round((b.creator_socials && b.creator_socials.tiktok && b.creator_socials.tiktok.performance && b.creator_socials.tiktok.performance.avg10_views) || 0);
  
      const valueA = instagramValueA + tiktokValueA;
      const valueB = instagramValueB + tiktokValueB;
  
      if (toggle3) {
        return valueB - valueA; // Sort in descending order of the calculated value
      } else {
        return valueA - valueB; // Sort in ascending order of the calculated value
      }
    });
  
    //console.log('Sorted Proposals:', sortedProposals);
  
    // Update the state with the sorted array
    setFilteredProposals(sortedProposals);
  }, [toggle3]);

  useEffect(() => {
    const filtered = Object.values(filteredProposals).filter(proposal => proposal.status !== 'denied');
    const sortedProposals = filtered.sort((a, b) => {
    const ratingA = (typeof a.average_rating === 'number') ? a.average_rating : 0;
    const ratingB = (typeof b.average_rating === 'number') ? b.average_rating : 0;
      if (toggle4) {
        return ratingB - ratingA; // Sort in descending order of rating
      } else {
        return ratingA - ratingB; // Sort in ascending order of rating
      }
    });
  
    // Update the state with the sorted array
    setFilteredProposals(sortedProposals);
  }, [toggle4]);

  const handleToggle2 = () => {
    setToggle2(prevToggle => !prevToggle);
  };

  const handleToggle4 = () => {
    setToggle4(prevToggle => !prevToggle);
  };
  const fetchData = async () => {
    if (id) {
      setLoading(true);
      try {
        const task = await TaskService.FetchSingleTask(id); // Wait for the task data to be fetched
        setTaskData(task);
        if (task && typeof task === "object" && task.proposals && Object.keys(task.proposals).length > 0) {
          setFilteredProposals((task.proposals));
        } else {
          setFilteredProposals({});
        }
        console.log("Task data:", task); // Log the task data to check its structure and contents
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false); // Ensure setLoading is set to false in case of an error
      }
    }
  }

useEffect(() => {
  fetchData();
}, [taskState.updateNeed.value]);

  const approveCreator = async (proposal, proposalKey) => { 
    if (task.accepted_proposals && task.product_showcase === Object.entries(task.accepted_proposals).length) {
      toast.error('You have reached the maximum number of creators');
    } else {
      console.log("proposal in function: ", proposal);
      setProposalKey(proposalKey);
      setSelectedProposal(proposal);
      setApproveModal(true);
    }
  }

  const deniedProp = async (proposal, proposalKey) => { 
    setProposalKey(proposalKey);
      setSelectedProposal(proposal);
      setDenyModal(true);
    
  }

  const task = taskState.tasks.value;
  {/*useEffect(() => {
    if (id) {
      TaskService.FetchSingleTask(id);
    }
  }, [taskState.updateNeed.value]); // Make sure 'id' is in the dependency array // Make sur
  if (taskState.task.value) {
    console.log("VALUE: ", taskState.tasks.value)
    setTaskData(taskState.tasks.value);
  }*/}

  const removeInfluencer = async (proposal, proposalKey) => {
    console.log("task id: " + proposal.task_id);
    console.log("key: " + proposalKey);
    const data = {
      creator_id: proposal.creator_id,
      task_id: task.id,
      bid: brandId,
      proposal_id: proposalKey
    };
    
    try {
      await TaskService.RemoveInfluencer(proposalKey, data);
      fetchData();
      toast.success('Influencer Removed');
    }
    catch (error) {
      toast.error('An error occurred while removing the influencer.');
    }

  }
  function formatNumber(number) {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1) + "M";
    } else if (number >= 1000) {
      return (number / 1000).toFixed(1) + "k";
    } else {
      return number.toString();
    }
  }

  const whitelistInstructions = () => {
    setWhitelist(!whitelist);
    setTabs(0);
  }
  
  //console.log("task: ", task);
  //console.log("accepted proposals length:", task.accepted_proposals ? Object.entries(task.accepted_proposals).length : 0);
  return (
    <div className="bg-white w-full p-4 rounded-lg z-100 shadow-md overflow-y-scroll h-full">
    <Toaster />
    {!task.proposals ? (
     <div className="flex justify-center font-bold mt-24">
      <h1 >There are currently no proposals submitted for this brief.</h1>
     </div>
    ) : (
      loading ? (
        <div className='flex justify-center'  >
          <Loader/>
        </div>
      ) : 
      (<div className='ml-5 md:text-base text-xs'>
                  <div className='flex flex-row items-center justify-center'>
                    <div className="relative items-center group ml-4 my-4">
                    </div>
                    <div className="relative ml-auto">
                      <button
                        className='border-2 2xl:px-9 2xl:py-2 md:px-6 md:py-1 sticky rounded-t-md bg-gray-100 text-black hover:bg-gray-200'
                        onClick={toggleDropdown}
                      >
                        Sort
                      </button>
                      {showDropdown && (
                        <div className="absolute right-0 z-10 bg-white border border-gray-300 rounded-md shadow-lg">
                          {/* Dropdown content goes here */}
                          <ul className="p-0">
                            <li className="border-b border-gray-300 flex items-center justify-between px-4 py-2 hover:bg-gray-100 cursor-pointer w-full" onClick={handleToggle1}>
                              <span className='text-base'>Date</span>
                              <span>{toggle1 ?  <BsChevronDown />: <BsChevronUp />}</span>
                            </li>

                            <li className="border-b border-gray-300 flex items-center justify-between px-4 py-2 hover:bg-gray-100 cursor-pointer w-full" onClick={handleToggle2}>
                              <span className='text-base'>Followers</span>
                              <span>{toggle2 ? <BsChevronDown /> :<BsChevronUp /> }</span>
                            </li>
                            <li className="border-b border-gray-300 flex items-center justify-between px-4 py-2 hover:bg-gray-100 cursor-pointer w-full" onClick={handleToggle4}>
                              <span className='text-base'>Creator Rating</span>
                              <span>{toggle4 ? <BsChevronDown /> :<BsChevronUp /> }</span>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>

                  </div>
                  {Object.entries(filteredProposals)
                .filter(([proposalKey, proposal]) => proposal.status !== 'denied')
                .map(([proposalKey, proposal]) => {
                  proposal = filteredProposals[proposalKey];  
                  return (
                    
                  <div key={proposalKey} className="proposal-container ">
                <div className="mr-4 grid grid-cols-2"> 
                <div className="flex flex-row flex-wrap items-start mt-2 2xl:h-fit md:h-fit">
                  <img className="2xl:w-20 2xl:h-20 md:w-16 md:h-16 ml-2 border-2 rounded object-cover" src={proposal.creator_photo || logo} alt="avatar" />
                  <div className="ml-6">
                   
                    <p className=''>
                <span className='font-bold 2xl:text-base md:text-0.7'>{proposal.creator_address && proposal.creator_address.fullname}</span> &nbsp; 
          
                <span className='italic text-gray-500 2xl:text-base md:text-0.7'>
                  {proposal.creator_address && proposal.creator_address.city}, {proposal.creator_address && proposal.creator_address.state}, {proposal.creator_address && proposal.creator_address.country}
                </span> 
                <div>
                {typeof proposal.average_rating === 'number' ? (
                <div className='flex flex-row items-center'>
                   <span  className="mr-2 font-bold">{proposal.average_rating.toFixed(1)}</span>
                   <ReactStars
                  count={5}
                  isHalf={true}
                  value={proposal.average_rating.toFixed(1)}
                  size={24}
                  edit={false}
                  activeColor="#FFD700"
                />
             
              </div>
                ) : (
                  <p className='2xl:text-0.8 md:text-0.7'>Not rated</p>
                )}
              </div>
              </p>     
              
              <div className="flex flex-row mt-2">
                <div className="flex flex-row ">
                  {proposal.creator_socials && (
                    <>
                      <BsFillPeopleFill title="Followers" className='mr-2 2xl:w-6 2xl:h-6 md:w-1rem md:h-1rem'/>  
                      {proposal.creator_socials && proposal.creator_socials.instagram && proposal.creator_socials.instagram.handle && (
                        <div className="flex items-center">
                          <a href={`https://instagram.com/${proposal.creator_socials.instagram.handle}`} target="_blank">
                            <img className="2xl:w-5 2xl:h-5 md:w-1rem md:h-1rem" src={instagram} alt="instagram" />
                          </a>
                          {proposal.creator_socials &&
                            proposal.creator_socials.instagram &&
                            proposal.creator_socials.instagram.follower_count && (
                              <span title="Follower count (Instagram)" className="ml-2 font-bold 2xl:text-base md:text-0.7">
                                {formatNumber(
                                  proposal.creator_socials &&
                                  proposal.creator_socials.instagram &&
                                  proposal.creator_socials.instagram.follower_count
                                ) || 0}
                              </span>
                            )}
                        </div>
                      )}
                      {proposal.creator_socials && proposal.creator_socials.tiktok && proposal.creator_socials.tiktok.handle && proposal.creator_socials.tiktok.performance && proposal.creator_socials.tiktok.performance.followerCount && (
                        <div className="flex items-center ml-4">
                          <a href={`https://tiktok.com/@${proposal.creator_socials.tiktok.handle}`} target="_blank">
                            <img className="2xl:w-5 2xl:h-5 md:w-1rem md:h-1rem" src={tiktok} alt="tiktok" />
                          </a>
                          <span title="Follower count (TikTok)" className="ml-2 font-bold cursor-pointer 2xl:text-base md:text-0.7">
                            {formatNumber(proposal.creator_socials.tiktok.performance.followerCount)}
                          </span>
                        </div>
                      )}
                      <div/>
                    </>
                  )}  
                        <div className="ml-3">
                          <Link to={`/profile/${proposal.creator_id}`} target="_blank" className="bg-Blue text-white px-2 py-1 rounded-lg outline-none 2xl:text-base md:text-0.7">
                            <span className="text-white">View Portfolio + Reviews</span>
                          </Link>
                        </div>
                      </div> 
                    </div>
                  </div>
                  <div className="mt-4 ml-2 w-full" style={{ display: 'flex', flexDirection: 'column' }}>
                    <p className='2xl:text-base md:text-0.7'>
                      Video Idea: {proposal && proposal.proposal_details && proposal.proposal_details.proposal1}
                    </p>
                    <div className="mt-2.5 ml-2">
                    
                    </div>
                    <div className="mt-2.5 ml-2">
                      {proposal.status === "approved" || proposal.creator_id === (task && task.creator_id) ? (
                        <div className="flex flex-row">
                          <button className="bg-green cursor-default text-white text-base md:text-0.7 2xl:px-4 2xl:py-2 md:px-3 md:py-1 rounded-lg flex items-center justify-center mr-5">
                            Approved
                          </button>
                          <button onClick={() => handleButtonClick(proposal, proposalKey)} title='Remove influencer from studio task' className="bg-red text-white md:text-0.7 2xl:px-4 2xl:py-2 md:px-3 md:py-1 rounded-lg flex items-center justify-center">
                            <SlUserUnfollow className='font-bold w-5 h-5' />
                          </button>
                        </div>
                      ) : (
                        <>
                          <CustomButton onClick={() => approveCreator(proposal, proposalKey)} className=" text-white md:text-0.7 2xl:px-4 2xl:py-2 md:px-3 md:py-1 rounded-lg mr-8">
                            Approve
                          </CustomButton>
                          <CustomButton onClick={() => deniedProp(proposal, proposalKey)} className=" text-white md:text-0.7 2xl:px-4 2xl:py-2 md:px-3 md:py-1 rounded-lg mr-8">
                            Deny
                          </CustomButton>
                          {task && task.add_ons && task.add_ons.some((addOn) => addOn.name === "Whitelist" && addOn.platform.includes("tiktok")) && 
                            <div className="my-2 font-bold italics">
                              <strong>For TikTok Whitelisting:</strong> After you have approved the video assets from this creator, they will provide a TikTok Spark Code for you to whitelist/boost their post.
                            </div>
                          }
                          {task && task.add_ons && task.add_ons.some((addOn) => addOn.name === "Whitelist" && addOn.platform.includes("instagram")) && 
                            <div className="my-2 font-bold italics">
                              <strong>For Instagram Whitelisting:</strong> Before you approve this creator (@{proposal && proposal.creator_socials && proposal.creator_socials.instagram && proposal.creator_socials.instagram.handle}), you will need to request them as an ad partner through your Facebook Business Manager account. For instructions on how to do this, click <button className='underline font-bold text-Blue' onClick={whitelistInstructions}>here</button>.
                            </div>
                          }
                        </>
                      )}
                </div>
              </div>
                </div>                       
                    </div>
                                      
            

                    <hr className=" w-full my-3 h-0.5 border-t-2 h-6 bg-gray-200 opacity-100 dark:opacity-50" />
                  </div>
              )})}
              </div>) 
            )} 
            
              {modal ? (
            <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center h-full w-full items-center z-50 bg-black bg-opacity-10">
            <UgcCreatorRemovalModal
            proposal={proposalRemove}
            proposalKey={proposalKey}
            _id = {id}
            task_id = {task.id}
            brand_id = {brandId}
            close = {() => setModal(false)}
            handleRemove = {removeInfluencer}
            fetchData = {fetchData}
            />
              </div>
            ): null}

            
            {whitelist ? (
            <>        
              <WhiteListingInstructions
                open={whitelist}
                close={() => setWhitelist(false)}
                task={task}
              />
            </>
            ): null}

            {approveModal ?
              (            <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center h-full w-full items-center z-50 bg-black bg-opacity-10">
              <UgcApprovalModal
              proposal={selectedProposal}
              proposalKey={proposalKey}
              _id = {id}
              task_id = {task.id}
              task = {task}
              brand_id = {brandId}
              close = {() => setApproveModal(false)}
              handleRemove = {removeInfluencer}
              fetchData = {fetchData}
              />
                </div>):
              null
            }
            {denyModal ?
            (<div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center h-full w-full items-center z-50 bg-black bg-opacity-10">
              <UgcDenialModal
              proposal={selectedProposal}
              proposalKey={proposalKey}
              _id = {id}
              task_id = {task.id}
              task = {task}
              brand_id = {brandId}
              close = {() => setDenyModal(false)}
              handleRemove = {removeInfluencer}
              fetchData = {fetchData}
              />
                </div>):
              null
            }

            {demoModal ? (
                
                
                <ViewDemographicsModal
                proposal={proposalRemove}
                open = {demoModal}
                onClose = {() => setDemoModal(false)}
                />

                ): null}
  </div>

  )
  
}

export default UgcProposals