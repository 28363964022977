import React from 'react';
import GlobalDialog from '../../../../utils/globalDialog';
import logo from '../../../../Assets/logo-red.png';
import tiktok from '../../../../Assets/tiktok-square.png';
import youtube from '../../../../Assets/youtube-shorts.png';
import instagram from '../../../../Assets/instagram (1).png';

function EditModal({ open, onClose, asset }) {
    console.log(asset.source)

    return (
        <>
            {
                asset &&
                <GlobalDialog className='h-auto ' open={open} onClose={onClose}>
                <div className=" bg-white rounded-lg dark:bg-gray-700">
                  <div>
                    <img src={logo} className="mx-auto" alt="log1" />
                  </div>
                  <h1 className='mt-4 text-center font-bold'>{asset.creator_name}</h1>
                  <div className="flex flex-col items-center rounded-lg h-auto w-auto p-3">
                    {asset.type === 'video' ? (
                      <div className="w-1/3 bg-green mx-auto items-center justify-center text-center">
                        <video className="w-full min-w-1/3 h-auto rounded-lg" controls src={asset.source} style={{ objectFit: 'contain' }} />
                      </div>
                    ) : (
                      <div className="w-1/3 bg-green mx-auto items-center justify-center text-center">
                        <img src={'https://crafted-react-images.s3.amazonaws.com/pexels-chevanon-photography-302899.jpg'} alt={'thumbnail'} className="w-full min-w-1/3 h-auto rounded-lg" />
                      </div>
                    )}
                    <div className="my-10 w-full items-center justify-center text-center">
                      <a
                        href={asset.source}
                        download
                        target="_blank"
                        rel="noreferrer"
                        className="bg-Blue m-auto px-4 text-3xl font-bold py-3 rounded-md text-white mt-4 w-auto text-center cursor-pointer"
                      >
                        Download
                      </a>
                    </div>
                  </div>
                </div>
              </GlobalDialog>
            }
        </>

    );
}

export default EditModal;
