import React, { useState } from 'react';
import axios from 'axios';
import GlobalDialog from '../../utils/globalDialog';
import logo from '../../Assets/logo-red.png';
import Loader from '../LoaderRed';
import { Carousel } from 'react-responsive-carousel';
import JSZip from 'jszip';
import { IoMdArrowDropleft,IoMdArrowDropright  } from "react-icons/io";
import fileDownload from 'js-file-download';
import { FaRegCopy } from "react-icons/fa";
import { useEffect, useRef } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import CustomButton from '../../utils/customButton';
function AssetModal({ open, name, asset, onClose, thumbnail, task }) {
  useEffect(() => console.table(asset.source), []);
  const [photos, setPhotos] = useState(false);
  const videoRef = useRef(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [variation, setVariation] = useState('');
  const [currentVideo, setCurrentVideo] = useState(asset.source);
  const [overallVariation, setOverallVariation] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastClickedVersion, setLastClickedVersion] = useState('Version 1');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [copied, setCopied] = useState(false);
  const [raw, setRaw] = useState(false);
  const [recipes, setRecipes] = useState(false);
  const modalRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowPopup(false);
        setSelectedFiles([]);
      }
    }
  
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  const handlePhotos = (asset) => {
    setSelectedAsset(asset);
    setPhotos(!photos);
  }
  const handleRaw = (asset) => {
    setSelectedAsset(asset);
    setRaw(!raw);
  }
  
  const handleRecipes = (asset) => {
    setSelectedAsset(asset);
    setRecipes(!recipes);
  }
  const handleDownloadClick = () => {
    setShowPopup(!showPopup);
  };

  const handleVariationClick = (fileUrl, variation, overallVariation) => {
    setVariation(variation);
    setShowVideoModal(true);
  
    // Check if fileUrl is an array or a string
    const currentVideo = Array.isArray(fileUrl) ? fileUrl : fileUrl;
  
    setCurrentVideo(currentVideo);
    setOverallVariation(overallVariation);
    console.log("overall: ", overallVariation);
    console.log("variation: ", variation);
    console.log("fileUrl: ", fileUrl);
  };

   console.log("selected files: ", selectedFiles);
   console.log('task', task)
   //CREATING A ZIP FOLDER OF ALL THE SELECTED FILES
   const createZipFolder = async (selectedFiles) => {
    setLoading(true);
    const zip = new JSZip();
    const folder = zip.folder(`${asset.creator_name}`);
  
    const getFileContent = async (fileUrl) => {
      const response = await fetch(fileUrl);
      const data = await response.blob();
      return data;
    };
  
    for (const fileUrl of selectedFiles) {
      const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
      const fileContent = await getFileContent(fileUrl);
      folder.file(fileName, fileContent);
    }
  
    const content = await zip.generateAsync({ type: 'blob' });
  
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(content);
    downloadLink.download = `${asset.creator_name}.zip`;
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    setLoading(false);
  };

  const handleCopy = () => {
    const link = asset.tiktok_code;
    navigator.clipboard.writeText(link)
      .then(() => setCopied(true))
      .catch((error) => console.error('Error copying link: ', error));
      setTimeout(() => {
        setCopied(false);
      }, 3000);
  };

  // OPENING NEW TAB TO DOWNLOAD VIDEO
  function downloadPhotoInNewTab(url) {
    window.open(url, '_blank');
  }
  return (
    <GlobalDialog className = 'h-fit w-full' open={open} onClose={onClose}>
      <div className=" bg-white rounded-lg dark:bg-gray-700">
        <div>
          <img src={logo} className="mx-auto" alt="log1" />
        </div>
        <div className='text-center text-base font-bold'>
          {asset.creator_name}
        </div>
        {task && task.add_ons && task.add_ons.some(addon => addon.name === "Whitelist" && addon.platform.includes("tiktok")) ? (
          asset.tiktok_code  ? (
            <div className="flex flex-col text-center 2xl:text-base mx-auto md:text-0.7 mx-5 w-fit tracking-tighter items-center my-2 justify-center">
              <div style={{ wordWrap: 'break-word' }}>
                Paste the TikTok Spark Ad video authorization code in the TikTok Ads Manager to Generate Your Ad: &nbsp;
                <div className='font-bold mx-10'>{asset.tiktok_code} 
                  <button onClick={handleCopy} className="ml-2">
                    {copied ? (
                      <span className="ml-2 text-0.7 text-green">Link copied!</span>
                    ) : (
                      <FaRegCopy title='Copy link' />
                    )}
                  </button>
                </div>
              </div>
              <div>Authorization Code Expires on: <span className='font-bold'>{asset.expires_at}</span></div>
            </div>
          ) : (
            <div className='mx-auto text-base text-center flex items-center justify-center'> Awaiting TikTok Spark Code from creator. Please check back soon.</div>
          )
        ) : null}

        {task && task.add_ons && task.add_ons.some(addon => addon.name === "Whitelist" && addon.platform.includes("instagram")) &&
          <div className='mx-auto text-base text-center flex flex-col items-center justify-center'>
            <div>The creator has approved your Instagram ad partner request, you are now able to run an ad with these assets from the Meta Business Suite.</div>
            <a className = "text-Blue hover:underline" href = "https://www.youtube.com/watch?v=IP0cBHWIH1U" target='_blank'>See Instructions</a>
          </div>
        }
        <div className="rounded-lg w-auto p-3 ">
        {asset.type === 'video' ? (
            <div className=" w-2/3 mx-auto items-center justify-center text-center">
              {overallVariation && Array.isArray(overallVariation.files) && overallVariation.files.length > 1 ? (
             <Carousel 
             showIndicators={false} 
             showArrows={true} 
             showThumbs={false} 
             useKeyboardArrows={true} 
             selectedIndex={0}
             swipeable={true}
             onChange={(newIndex) => {
              setCurrentIndex(newIndex);
              setCurrentVideo(overallVariation.files[newIndex]);
            }}
             renderArrowPrev={(clickHandler, hasPrev) => {
               return (
                 <div
                   className={`${
                     hasPrev ? "absolute" : "hidden"
                   }  flex justify-center items-center p-3 opacity-100 hover:opacity-100 cursor-pointer z-20 2xl:-left-10 md:-left-5  top-1/3`}
                   onClick={clickHandler}
                 >
                   <IoMdArrowDropleft className="2xl:w-24 2xl:h-24 w-16 h-16 text-Blue" />
                 </div>
               );
             }}
             renderArrowNext={(clickHandler, hasNext) => {
               return (
                 <div
                   className={`${
                     hasNext ? "absolute" : "hidden"
                   }  flex justify-center items-center p-3 opacity-100 hover:opacity-100 cursor-pointer z-20 2xl:-right-10 md:-right-5 top-1/3`}
                   onClick={clickHandler}
                 >
                   <IoMdArrowDropright className="2xl:w-24 2xl:h-24 w-16 h-16 text-Blue" />
                 </div>
               );
             }}
           >
             {overallVariation.files.map((file, index) => (
               <div>
                 {file.replace("https://crafted-react-images.s3.amazonaws.com/", "")}
                 <video key={index} className="w-fit mx-auto min-w-1/3 2xl:h-328  md:h-18 rounded-lg" controls controlsList="fullscreen" style={{ objectFit: 'contain' }} src={currentVideo} />
               </div>
             ))}
           </Carousel>
              ) : (
                <video className="w-fit mx-auto min-w-1/3 2xl:h-328  md:h-18  rounded-lg" controls controlsList="fullscreen" style={{ objectFit: 'contain' }} src={currentVideo} />
              )}
            </div>
          ) : (
            <div className=" w-2/3 mx-auto items-center justify-center text-center">
              <img src={thumbnail} alt={name} className="w-full min-w-1/3 h-auto rounded-lg" />
            </div>
          )}

          {asset.photos && (
            <div className="mt-2 w-full items-center justify-center text-center">
              <button className='text-gray-400' onClick={() => handlePhotos(asset)}>View Product Photos</button>
            </div>
          )}
           {asset.recipes && (
            <div className="mt-2 w-full items-center justify-center text-center">
              <button className='text-gray-400' onClick={() => handleRecipes(asset)}>View Recipe</button>
            </div>
          )}
          {asset.raw_footage && (
            <div className="mt-4 w-full items-center justify-center text-center">
              <button className='text-gray-400' onClick={() => handleRaw(asset)}>View Raw Footage</button>
            </div>
          )}
          {asset && asset.variations && (
                    <div>
                       <div className='flex flex-row items-center mx-auto justify-center w-full'>
                          {Object.entries(variation)
                            .filter(([key, value]) => value === true)
                            .map(([key], index, array) => (
                              <span className='font-bold ml-1' key={key}>
                                {key === "music" && "Music"}
                                {key === "onScreenText" && "On-screen text"}
                                {key === "separateClips" && "Separate clips"}
                                {key === "voiceover" && "Voiceover"}
                                {index !== array.length - 1 && ", "}
                              </span>
                            ))}
                        </div>
                        {task && task.video_requirements && !showVideoModal && 
                        <div className='flex flex-row items-center mx-auto justify-center w-full'>
                           {Object.entries(task.video_requirements)
                            .filter(([key, value]) => value === true)
                            .map(([key], index, array) => (
                              <span className='font-bold ml-1' key={key}>
                                {key === "music" && "Music"}
                                {key === "onScreenText" && "On-screen text"}
                                {key === "separateClips" && "Separate clips"}
                                {key === "voiceover" && "Voiceover"}
                                {index !== array.length - 1 && ", "}
                              </span>
                            ))}
                        </div>
                        }
                      <div className='flex flex-row items-center mx-auto justify-between md:w-12rem 2xl:w-15rem'>
                      <div  
                          onClick={() => {
                            setShowVideoModal(false);
                            setCurrentVideo(asset.source);
                            setLastClickedVersion('Version 1');
                            setVariation('');
                          }} 
                         className={`focus-within:bg-gray-600 w-1/2 mx-2 cursor-pointer text-white ${lastClickedVersion === 'Version 1' ? 'bg-red' : 'bg-gray-500'} rounded-lg border-2 md:text-base text-center my-1 p-1`}>
                         <p className='text-xs font-bold break-words'>
                            Version 1
                          </p>
                      </div>
                        <div className='flex flex-row items-center mx-auto justify-center w-1/2 '>
                        {Object.keys(asset.variations).map((variationId, i) => (
                            <div key={i} className={`w-full mx-2 cursor-pointer text-white ${lastClickedVersion === `Version ${i + 2}` ? 'bg-red' : 'bg-gray-500'} rounded-lg border-2 md:text-base text-center my-1 p-1`}  onClick={() => {
                              const files = Array.isArray(asset.variations[variationId].video.files) ? asset.variations[variationId].video.files : [asset.variations[variationId].video.files];
                              handleVariationClick(files, asset.variations[variationId].video.variation, asset.variations[variationId].video);
                              setLastClickedVersion(`Version ${i + 2}`);
                            }}>
                              <p className='text-0.7 font-bold break-words'>
                                Version {i + 2}
                              </p>
                            </div>
                         ))}
                        </div>
                       
                      </div>
                    </div> )}
                </div>  
                        <div className='flex items-center justify-center'>
                          <a
                            href={currentVideo}
                            download
                            target="_blank"
                            rel="noreferrer"
                            className="text-red hover:underline mb-4 px-4 text-0.7 font-bold py-3 rounded-lg mt-4 mx-auto w-auto text-center cursor-pointer">
                            Having trouble viewing? Click here to download the video and review offline.
                          </a>
                        </div>
                {overallVariation && Array.isArray(overallVariation.files) && overallVariation.files.length > 0 ? (
                <div className="relative w-full text-xs text-center">
                    <CustomButton onClick={handleDownloadClick} className="mb-4 bg-red m-auto px-4 text-3xl font-bold py-3 rounded-lg text-white mt-2 w-auto text-center cursor-pointer">Download Files</CustomButton>
              
                  </div>
                ) : (<div className="my-4 w-full items-center justify-center text-center">
                    <a
                      href={currentVideo}
                      download
                      target="_blank"
                      rel="noreferrer"
                      className="bg-Blue mb-4 px-4 text-3xl font-bold py-3 rounded-md text-white mt-4 w-auto text-center cursor-pointer">
                      Download
                    </a>
                    </div>

                )}
          
          {showPopup && (
            <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center z-auto bg-black bg-opacity-50" style={{zIndex: 100}}>
              <div ref={modalRef} className="bg-white border-2 rounded-md h-fit border-gray-300 shadow-md p-8 max-w-md mx-auto">
                <p className='text-2xl border-b-2 w-full text-center'>Select files to download:</p>
                {Object.values(asset.raw_footage).map((footage, index) => (
                  <div key={index} className="border-b my-5 w-full flex items-center">
                    <input
                      type="checkbox"
                      id={`file${index}`}
                      name={`file${index}`}
                      value={footage.file}
                      required
                      onChange={(e) => {
                        const { value, checked } = e.target;
                        setSelectedFiles((prevSelectedFiles) =>
                          checked ? [...prevSelectedFiles, value] : prevSelectedFiles.filter((file) => file !== value)
                        );
                      }}
                    />
                    <label className='ml-2 flex text-xs w-fit truncate overflow-ellipsis ' htmlFor={`file${index}`}>
                      {footage.file.replace("https://crafted-react-images.s3.amazonaws.com/", "")}
                    </label>
                  </div>
                ))}
                <button 
                  className="bg-red mx-auto px-4 text-3xl font-bold py-3 rounded-lg text-white mt-2 flex text-center cursor-pointer"
                  onClick={() => createZipFolder(selectedFiles)}
                >
                  {loading ? (
                    <>
                      <Loader/> Downloading
                    </>
                  ) : (
                    `Download Selected Files ${selectedFiles.length > 0 ? `(${selectedFiles.length})` : ''}`
                  )}
                </button>
              </div>
            </div>
          )}
      
            {photos ? (
                
                <GlobalDialog open={photos} onClose={() => setPhotos(false)}>
                  <div className="flex mx-auto bg-white w-full flex-col h-full items-center">
                    <h1 className='font-bold text-base text-center'>{selectedAsset.creator_name.split("_")[1]}'s photos</h1>
                    <div className="flex gap-4 mx-10 mt-20 items-center">
                    {Object.values(selectedAsset.photos).map((photo, index) => (
                      <div key={index} className={`w-1/3 relative ${Object.values(selectedAsset.photos).length === 1 ? 'mx-auto' : ''}`} style={{ paddingBottom: 'calc(33.333% - 0.5rem)' }}>
                        <img className='w-full h-full border-4 object-cover rounded-md' src={photo.photo} />
                        <CustomButton className='mx-auto flex items-center mt-2 bg-red rounded-md text-white font-bold px-10 py-3' onClick={() => downloadPhotoInNewTab(photo.photo)}>
                          Download
                        </CustomButton>
                      </div>
                    ))}
                    </div>
                  </div>
                </GlobalDialog>
                  
                ) : null} 
            {recipes ? (
               <GlobalDialog open={recipes} onClose={() => setRecipes(false)}>
               <div className="flex mx-auto bg-white w-full flex-col h-full items-center">
                 <h1 className='font-bold text-base text-center'>{selectedAsset.creator_name.split("_")[1]}'s recipe</h1>
                 <div className="flex items-center w-full md:w-11/12 md:max-w-6xl md:mx-auto mx-0 mb-20 md:mb-52">
        {asset.recipes && (
          <div id="main" className="w-full mx-6 md:mx-0">
            <div className="mb-6 bg-gray-100 px-5 py-4 rounded-2xl lg:flex hidden " id="top-banner">
              <img src={asset.recipes.thumbnail} alt={asset.recipes.name} className="w-80 h-80 object-cover" />
              <div className="ml-9">
                <div>
                  <p className="font-rubik font-bold text-2xl text-black mb-4">{asset.recipes.name}</p>
                </div>
                <div className="flex flex-col items-start">
                  {asset.metatags !== undefined ? (
                    <div className="flex mr-5  mb-4 " id="meta-asset.metatags">
                      {Object.values(asset.metatags).map((tag, i) => {
                        return (
                          <div
                            className="bg-lightBlue text-xs font-bold text-white rounded-xl py-1 px-3 mr-1"
                            key={i}>
                            {tag}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
                <div className="text-xs my-5 font-bold mb-3">{asset.recipes.description}</div>
              </div>
            </div>
            <div className="mb-6 rounded-2xl flex lg:hidden" id="top-banner">
              <div className="w-full bg-lightGray items-center rounded-xl p-3 flex flex-col">
                <img
                  src={asset.recipes.thumbnail}
                  className="w-full md:w-64 h-auto md:h-64 mb-3 rounded-2xl object-cover"
                  alt={asset.recipes.name}
                />
                <div className="text-xl mb-1 font-bold"> {asset.recipes.name}</div>
                <div className="text-xs font-bold mb-3">{asset.recipes.description}</div>
                <div className="flex">
                  {asset.recipes.metatags !== undefined ? (
                    <div className="flex mr-5 " id="meta-asset.metatags">
                      {Object.values(asset.recipes.metatags).map((tag, i) => {
                        return (
                          <div
                            className="bg-lightBlue text-xs font-bold text-white rounded-xl py-1 px-3 mr-1"
                            key={i}>
                            {tag}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row">
              <div id="ingredient" className="bg-lightGray p-7 rounded-2xl">
                <div className="text-2xl text-center font-bold font-rubik">Ingredients</div>
                <hr className="w-full my-6 border-t-2 border-divider" />
                {asset.recipes.ingredients !== undefined
                  ? Object.values(asset.recipes.ingredients).map((ing, i) => {
                      return (
                        <li className="mb-3 font-rubik text-xl" key={i}>
                          {ing.measurement} {ing.unit} {ing.name}
                        </li>
                      );
                    })
                  : null}
              </div>
              <div id="directions" className="flex-1 py-7 pl-7 rounded-2xl">
                <div className="text-2xl text-center font-bold font-rubik">Directions</div>
                <hr className="w-full my-6 border-t-2 border-divider" />
                {asset.recipes.steps !== undefined
                  ? Object.values(asset.recipes.steps).map((d, i) => {
                      return (
                        <div className="mb-3 font-rubik text-xl" key={i}>
                          {i + 1}. {d.step}
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        )}

      </div>
               </div>
             </GlobalDialog>)
              : null}   
          {raw ? (
          <GlobalDialog open={raw} onClose={() => setRaw(false)}>
            <div className="flex mx-auto bg-white z-0 rounded-md w-full flex-col h-full items-center">
              <h1 className='font-bold text-center'>{selectedAsset.creator_name.split("_")[1]}'s raw footage</h1>
              <div className="flex gap-4 mx-10 mt-10 items-center">
                <Carousel
                showIndicators={false} 
                showArrows={true} 
                showThumbs={false} 
                useKeyboardArrows={true} 
                selectedIndex={0}
                swipeable={true}  
                renderArrowPrev={(clickHandler, hasPrev) => {
                  return (
                    <div
                      className={`${
                        hasPrev ? "absolute" : "hidden"
                      }  flex justify-center items-center p-3 opacity-100 hover:opacity-100 cursor-pointer z-20 2xl:-left-10 md:-left-5  top-1/3`}
                      onClick={clickHandler}
                    >
                      <IoMdArrowDropleft className="2xl:w-24 2xl:h-24 w-16 h-16 text-Blue" />
                    </div>
                  );
                }}
                renderArrowNext={(clickHandler, hasNext) => {
                  return (
                    <div
                      className={`${
                        hasNext ? "absolute" : "hidden"
                      }  flex justify-center items-center p-3 opacity-100 hover:opacity-100 cursor-pointer z-20 2xl:-right-10 md:-right-5 top-1/3`}
                      onClick={clickHandler}
                    >
                      <IoMdArrowDropright className="2xl:w-24 2xl:h-24 w-16 h-16 text-Blue" />
                    </div>
                  );
                }}
              >
                  {Object.values(selectedAsset.raw_footage).map((file, index) => (
                    <div key={index}>
                       {file.file.replace("https://crafted-react-images.s3.amazonaws.com/", "")}
                      <video className='w-fit mx-auto min-w-1/3 2xl:h-328  md:h-18 rounded-lg' src={file.file} controls />
                      <CustomButton className='mx-auto flex items-center my-2 bg-red rounded-md text-white font-bold px-10 py-3' onClick={handleDownloadClick}>
                        Download Files
                      </CustomButton>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </GlobalDialog>
        ) : null}    
            </div>
    </GlobalDialog>
  );
}

export default AssetModal;
