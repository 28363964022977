import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';

//State
const state = createState({
  recipes: [],
  recipe: [],
  retrieving: false,
  fetched: false,
  loading: true,
  updateNeed: true
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'RECIPES_LOADED':
        return s.merge({
          recipes: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true
        });
      case 'CREATE_RECIPE':
        return s.merge({
          recipe: action.data,
          loading: false,
          updateNeed: true
        });
      case 'RECIPE_UPDATE':
        return s.merge({
          updateNeed: true,
          loading: false
        });
      case 'RECIPE_DELETE':
        return s.merge({
          loading: false,
          updateNeed: true
        });
      case 'RECIPE_LOADED':
        return s.merge({
          recipe: action.data,
          updateNeed: false,
          loading: false,
          fetched: true
        });
      default:
        return null;
    }
  }, action.type);
});

export const accessRecipeState = () => state;

export const useRecipeState = () => useState(state);

//Service
export const RecipeService = {
  FetchRecipeForUser: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/users/recipes/${id}`)
      .then((res) => {
        dispatch(RecipeAction.loadUserRecipe(res.data.result));
      })
      .catch((err) => {});
  },
  AddRecipe: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/recipes/createRecipe`, data)
      .then((res) => {
        dispatch(RecipeAction.createRecipe(res));
      })
      .catch((err) => {});
  },
  UpdateRecipe: (id, data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/recipes/updateRecipe/${id}`, data)
      .then((res) => {
        dispatch(RecipeAction.updateRecipe(res));
      })
      .catch((err) => {});
  },
  DeleteRecipe: (id, data) => {
    const dispatch = useDispatch();
    axios
      .delete(`${baseUrl}api/recipes/deleteRecipe/${id}`, { data: data })
      .then((res) => {
        dispatch(RecipeAction.deleteRecipe(res));
      })
      .catch((err) => {});
  },
  FetchSingleRecipe: (id) => {
    const dispatch = useDispatch();

    axios
      .get(`${baseUrl}api/recipes/${id}`)
      .then((res) => {
        dispatch(RecipeAction.loadSingleRecipe(res.data.result));
      })
      .catch((err) => {});
  }
};

//Action
export const RecipeAction = {
  loadUserRecipe: (data) => {
    return {
      type: 'RECIPES_LOADED',
      data: data
    };
  },
  loadSingleRecipe: (data) => {
    return {
      type: 'RECIPE_LOADED',
      data: data
    };
  },
  createRecipe: (data) => {
    return {
      type: 'CREATE_RECIPE',
      data: data
    };
  },
  updateRecipe: (data) => {
    return {
      type: 'RECIPE_UPDATE',
      data: data
    };
  },
  deleteRecipe: (data) => {
    return {
      type: 'RECIPE_DELETE',
      data: data
    };
  }
};
