import React, { useRef, useState, useEffect } from 'react';
import { UsersService, useUserState } from '../../Service/userServices';
import CreatorTable from './CreatorTable';


const CreatorLookup = () => {
  const userState = useUserState();
  useEffect(() => {
    if (userState.updateNeed.value) {
      UsersService.FetchCreators();
    }
  }, [userState.updateNeed.value]);

  const temp = userState.user.value;

  const dataArray = temp && Object.entries(temp).map(([id, value]) => ({ id, ...value }));


  return (
    <div className="bg-white m-4 rounded-lg w-fit p-6">
      <h2 className="font-semibold py-2 text-2xl"> All Creators</h2>
      <CreatorTable  data = {dataArray}/>
    </div>
  );
}


export default CreatorLookup;