import React, { useContext, useEffect, useState } from 'react'
import Modal from '../../utils/ugcModal';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';
import { useAuth } from '../../Context/AuthContext';
import { OrganizationService } from '../../Service/OrganizationService';
import {Toaster, toast} from 'react-hot-toast';
import { BrandService, useBrandState } from '../../Service/BrandServices';
import { AccountService, useAccountState } from '../../Service/AccountServices';
function NewOrganizationModal(props) {
const { brandId } = useContext(BrandContext);
const {currentUser} = useAuth();
const [name, setName] = useState();
const brandState = useBrandState();
const accountState = useAccountState();
console.log("current user uid", currentUser.uid);
  const createOrganization = (e) => {
    try{
    e.preventDefault();
    let data = {
      uid: currentUser && currentUser.uid,
      name: name,
      email: account.email,
      brand_id: brandId,
      brand_avatar: brand.brand_avatar,
      first_name: account.first_name,
      brand_name: brand.brand_name,
      last_name: account.last_name
    }
    console.log(data);
    OrganizationService.CreateOrganization(data);
    toast.success("Organization Created");

  } catch(err) {
    toast.error("Error creating organization");
    console.error("Error creating organization: ", err);
  }

  }
  useEffect(() => {
    if (currentUser.uid){
      AccountService.FetchAccountInfo(currentUser.uid);
    }
  }, [currentUser.uid]);

 useEffect(() => {
   if (brandState.updateNeed.value) {
     BrandService.FetchSingleBrand(brandId);
   }
 }, [brandState.updateNeed.value, brandId]);

  let account = accountState.account.value
  console.log("Account info: ", account);
  let brand = brandState.brand.value
  console.log("Brand info: ", brand);
  return (
    <Modal
    open={props.isOpen}
    onClose={() => {
      props.closeAction();
    }}
    center
    closeOnOverlayClick={false}>
      <Toaster/>  
      <form onSubmit={createOrganization}>
        <div className="2xl:h-328 md:h-34 w-full flex flex-col items-center justify-center">
        
          <p className="text-4xl font-bold mb-4 text-center border-b w-3/4 pb-2">Create organization </p>
          <p className="text-center mb-4">With organizations, you can manage multiple brands under one team.</p> {/* Add explanatory text */}
          <div className="mx-4 mt-5 w-full max-w-md">
            <div className="my-6">
              <label className="text-center block mb-2">Name your organization</label>
              <input
                className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded border"
                type="text"
                required
                placeholder="Organization Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            
            <button type = "submit" className="bg-Blue text-white p-2 font-bold rounded-md w-full">Create Organization</button>
          
          </div> 
        </div>
      </form>
    </Modal>
  )
}

export default NewOrganizationModal