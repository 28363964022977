import React from 'react';
import NavBar from '../../Components/Navbar';
import Sidebar from '../../Components/Admin/Sidebar';

export default function AdminDashboard({ children }) {
  return (
    <div className="bg-gray-100 h-screen">
      <NavBar />
      <div className="md:flex flex-col md:flex-row  w-full">
        <Sidebar />
        {children}
      </div>
    </div>
  );
}
