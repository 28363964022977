import React, { useEffect, useState } from 'react';
import { useAssetState, AssetService } from '../../Service/AssetService';
import { useDraftState,DraftService } from '../../Service/DraftService';
import ReactPlayer from 'react-player';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useAuth } from '../../Context/AuthContext';

function ProposalVideos(props) {
  const assetState = useAssetState();
  const [count, setCount] = useState(0);
  const [draftData, setDraftData] = useState({
    id: '',
    assetData: ''
  });
  const { currentUser } = useAuth();
  useEffect(() => {
    if (assetState.updateNeed.value) {
      assetState.merge({ loading: true });
      AssetService.FetchAssetsForCreator(currentUser.uid);
    }
  }, [assetState.updateNeed.value]);

  let Assetdata = [];
  if (assetState.assets.value) {
    Assetdata = Object.entries(assetState.assets.value).map((e) => ({
      [e[0]]: e[1]
    }));
  }
  useEffect(() => {
    Assetdata.map((item, i) => {
      let assetObj = {};
      const data = item[Object.keys(item)];
      assetObj.data = data;
      const id = Object.keys(item);
      assetObj.id = id;
      if (i === count) {
        setDraftData({
          ...draftData,
          id: id,
          assetData: data
        });
      }
    });
  }, [0, count]);
  let examples = {
    asmr: 'https://crafted-react-images.s3.amazonaws.com/NiceCreamJOI.mp4',
    story: 'https://crafted-react-images.s3.amazonaws.com/Dinner+party+with+VO.mp4',
    review: 'https://crafted-react-images.s3.amazonaws.com/Kids+honest+reaction.mp4',
    cooking_show:
      'https://crafted-react-images.s3.amazonaws.com/A7D27B50-E9B3-407C-9ACD-3D3E680FF611.MOV',
    experience: 'https://crafted-react-images.s3.amazonaws.com/1849wine_experience.mp4',
    how_to: 'https://crafted-react-images.s3.amazonaws.com/Mango+cheesecake+VO.mp4',
    aesthetic: 'https://crafted-react-images.s3.amazonaws.com/Ginger+Turmeric+Mocktail+.mov'
  };

  let examples_captions = {
    asmr: 'No face, overhead angle, no voiceover, sound super prominent audio, aesthetic',
    story:
      "Face, 'that time I [hosted a dinner party]...', cuts with multiple settings (kitchen, eating), best if you show the context ",
    review: 'Face, often involves trying multiple flavors, providing honest feedback on flavors',
    cooking_show: 'Face, commentary, along with recipe & aesthetic top down shots',
    experience:
      'Like story, but really puts the product in context (think a nice bottle of wine at a candle lit dinner)',
    how_to:
      'No face, overhead angle, cutting board, often a pretty involved recipe, instructional voiceover',
    aesthetic: 'No face, same instrutional voiceover, front on angle, modern & clean aesthetic'
  };

  const userProposals = [];

  for (const proposalId in props.task.proposals) {
    const proposal = props.task.proposals[proposalId];
    if (proposal.creator_id === props.task.creator_id) {
      for (const key in proposal.proposal_details) {
        userProposals.push(proposal.proposal_details[key]);
      }
    }
  }

  return (
    <div>
      <div className='my-2'>
      {userProposals.map((proposalDetail, index) => (
                  <div key={index}><span className='font-bold'>Proposal</span>: <div>{proposalDetail}</div></div>
                ))}
        <div>
          <video
            className="w-full min-w-1/3 h-21 rounded-lg"
            controls
            style={{ objectFit: 'contain' }}
              src={props.task.drafts[currentUser.uid].video}
          />
        </div>
      </div>

      <div className='mt-2'>
        <span className="font-semibold">Revision Notes: </span>
        <div>{props.task.drafts[currentUser.uid].revision_notes}</div>
      </div>
    </div>
  );
}

export default ProposalVideos;
