/* eslint-disable no-useless-escape */
import React, { useState, useRef } from 'react';
import { useAuth } from '../Context/AuthContext';
import { Link } from 'react-router-dom';
import '../App.css';
import axios from 'axios';
import 'react-phone-input-2/lib/style.css';
import AccountCreationModal from './AccountCreationModal';
import success from './../Assets/GirlWithEggs.png';
import { UsersService } from '../Service/userServices';
import toast, { Toaster } from 'react-hot-toast';
import { Mixpanel } from '../mixpanel';
import Loader from './Loader';
import CustomButton from '../utils/customButton';

function SignUpModal(props) {
  const userEmail = useRef('');
  const userPassword = useRef('');
  const userPasswordConfirm = useRef('');
  const userName = useRef('');
  const { signup } = useAuth();
  const [error, setError] = useState({
    email: '',
    password: '',
    confirmPass: '',
    username: '',
    phoneNumber: '',
    common: ''
  });
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  var new_user = {};
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const usernameRe = /^[a-z0-9_\.]+$/;

  const onChange = (e) => {
    setError({ ...error, [e.target.name]: '' });
  };

  const handleChange = (e) => {
    if (e.currentTarget.value.includes(' ')) {
      e.currentTarget.value = e.currentTarget.value.replace(/\s/g, '');
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();

    let err = error;
    // return;
    if (userPassword.current.value !== userPasswordConfirm.current.value) {
      err.password = 'Passwords do not match.';
      return setError({ ...error, err });
    } else if (userPassword.current.value.length < 7) {
      err.email = 'Password must be at least 6 characters long.';
      return setError({ ...error, err });
    } else if (!re.test(userEmail.current.value)) {
      err.email = 'Email is not valid.';
      return setError({ ...error, err });
    } else if (!usernameRe.test(userName.current.value)) {
      err.username = 'You can only use alphanumerical characters, underscores and dots';
      return setError({ ...error, err });
    }
    if (!userPassword.current.value) {
      return (err.password = 'Password is required');
    }
    if (userName.current.value === '') {
      return (err.userName = 'User name is required');
    }
    if (!userPasswordConfirm.current.value) {
      err.confirmPass = 'Confirm Password is required';
    } else if (userPassword.current.value.length < 6) {
      err.confirmPass = 'Password should be at least 6 characters';
    } else if (userPassword.current.value !== userPasswordConfirm.current.value) {
      err.confirmPass = 'Confirm Password and Password is not match';
    }

    try {
      setError({
        email: '',
        password: '',
        confirmPass: '',
        userName: '',
        phoneNumber: '',
        common: ''
      });
      setLoading(true);

      await axios
        .get('api/users/checkUsername/' + userName.current.value.toLowerCase())
        .then((res) => {
          if (!res.data.result) {
            err.username = 'Username is taken.';
            setLoading(false);
            return setError({ ...error, err });
          }
        })
        .catch((error) => {
          // Error
          if (error.response) {
          } else if (error.request) {
          } else {
          }
        });
      const currEmail = userEmail.current.value;
      const currUser = userName.current.value.toLowerCase();
      await signup(userEmail.current.value, userPassword.current.value.trim())
        .then((userCreds) => {
          new_user = {
            uid: userCreds.user.uid,
            username: currUser,
            email: currEmail
          };
          UsersService.AddUser(new_user);
          Mixpanel.signup(new_user);
          setCurrentTab(1);
        })
        .catch((error) => {
          toast.error('email is already taken!!');
          setLoading(false);
        });
    } catch (__error) {
      err.common = __error;
      setError({ ...error, err });
    }
  }

  return (
    <div>
      {currentTab === 0 ? (
        <div>
          <Toaster />
          <div className="mb-3">
            <label className="text-darkBlack font-bold capitalize">Username</label>
            <input
              className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
              name="userName"
              onChange={handleChange}
              type="text"
              ref={userName}
              required
            />
            {error && error.username && <span className="text-sm text-Red">{error.username}</span>}
          </div>
          <div className="mb-3">
            <label className="text-darkBlack font-bold capitalize">Email</label>
            <input
              className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
              name="email"
              onChange={onChange}
              type="email"
              ref={userEmail}
              required
            />
            {error && error.email && <span className="text-sm text-Red">{error.email}</span>}
          </div>
          <div className="mb-3">
            <label className="text-darkBlack font-bold capitalize">Password</label>
            <input
              className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
              name="password"
              onChange={onChange}
              type="password"
              ref={userPassword}
              required
            />
            {error && error.password && <span className="text-sm text-Red">{error.password}</span>}
          </div>
          <div className="mb-10">
            <label className="text-darkBlack font-bold capitalize">Confirm Password</label>
            <input
              className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
              name="confirmPass"
              onChange={onChange}
              type="password"
              ref={userPasswordConfirm}
              required
            />
            {error && error.confirmPass && (
              <span className="text-sm text-Red">{error.confirmPass}</span>
            )}
          </div>
          <p>{error && error.common && error.common}</p>
          <CustomButton
            className="text-xl font-bold my-3 w-full mx-auto rounded text-white py-2 px-14 block"
            type="button"
            disabled={loading}
            onClick={(e) => handleSubmit(e)}>
            {loading && <Loader />}
            Join Us
          </CustomButton>
        </div>
      ) : currentTab === 1 ? (
        <div>
          <AccountCreationModal
            handleComplete={() => {
              props.handleClose();
            }}
          />
        </div>
      ) : (
        currentTab === 2 && (
          <div>
            <img src={success} alt="logo" className="mx-auto h-48" />
            <div>
              <h2 className="text-4xl text-center font-bold mb-4">Congrats! You're in.</h2>
              {/*<p className="text-lg text-center font-bold text-black mb-2">
                Here are our community guidelines, to make sure
                <br /> this is a fun and safe environment to celebrate food.
              </p>
              <p className="text-lg text-Red font-bold text-center mb-2">Be respectful.</p>
              <p className="text-lg text-Red font-bold text-center mb-2">Be encouraging.</p>
              <p className="text-lg text-Red font-bold text-center mb-2">Be curious.</p>*/}
              <Link
                className="text-xl text-center font-bold my-3 w-full mx-auto bg-Blue rounded text-white py-2 px-14 block"
                to="/creator/dashboard">
                Let's Go!
              </Link>
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default SignUpModal;
