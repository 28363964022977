import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import GlobalDialog from '../../utils/globalDialog';
import { useAuth } from '../../Context/AuthContext';
import { ProductService, useProductState } from '../../Service/ProductServices';
import { AuthService, userAuthstate } from '../../Service/authenticationService';
import { TaskService } from '../../Service/TaskServices';
import { useCampaignState, CampaignService } from '../../Service/CampaignServices';
import Multiselect from 'multiselect-react-dropdown';
import Collapsible from 'react-collapsible';
import {toast, Toaster} from 'react-hot-toast'
import VideoCarousel from './VideoCarousel';
import { BsChevronCompactDown, BsChevronCompactUp } from 'react-icons/bs';
import CustomButton from '../../utils/customButton';

function ProposalModal({ open, onClose, id, task, deadline }) {
  const { currentUser } = useAuth();
  const [selectedValue] = useState([]);
  const history = useHistory();
  const productState = useProductState();
  const userState = userAuthstate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTermsChecked,  setIsTermsChecked] = useState(false);
  const [error, setError] = useState({
    terms: ''
  });
  const [formData, setFormData] = useState({
    recipe: '',
    incorporate: ''
  });
  const [proposals, setProposals] = useState({});
  const [selectedProd, setSelectedProd] = useState('');

  console.log('# of Videos: ' + num_videos);
  // var num_videos = 1;
  var num_videos = task.recipe_videos + task.product_showcase;
  const styles = {
    option: {
      // To change css for dropdown options
    },
    optionContainer: {
      // To change css for option container
      overflowY: 'scroll',
      height: '120px',
      overflowX: 'hidden',
      position: 'relative'
    },
    multiselectContainer: {
      // To change css for multiselect (Width,height,etc..)
      height: '120px',
      marginTop: '10px',
      marginBottom: '65px',
      position: 'relative'
    },
    chips: {
      // To change css chips(Selected options)
      // display:"none",
      background: '#A9A9A9'
    },
    searchBox: {
      // To change search box element look
      border: 'none',
      padding: '7px 10px',
      fontSize: '15px',
      backgroundColor: '#F3F4F6',
      backgroundImage: 'url(../../Assets/drop_down.png)'
    }
  };
  const onSelect = (selectedList) => {
    let selectedObj = {};
    selectedList.forEach((selected) => {
      selectedObj[selected.id] = selected;
    });
    setSelectedProd(selectedObj);
  };

  const customValidator = () => {
    if (selectedValue.length === 0) {
      return 'Please select at least one option.';
    }
  };

  useEffect(() => {
    if (currentUser !== null) {
      AuthService.FetchAuthUser(currentUser.uid);
      ProductService.FetchProductForUser(task.brand_id);
    } else if (currentUser === null) {
      history.push('/');
    }
  }, [history, currentUser]);
  const prod = productState.products.value;
  const user = userState.authUser.value;
  //map products data into single array
  const productdata = prod
    ? Object.entries(prod).map((e) => ({
      [e[0]]: e[1]
    }))
    : [];

  const opti = [];
  if (productdata) {
    if (productdata.length) {
      productdata.map((rec, i) => {
        let obj = {};
        const el = rec[Object.keys(rec)];
        const id = Object.keys(rec);
        obj['brand_id'] = el.brand_id;
        obj['brand_name'] = el.brand_name;
        obj['name'] = el.brand_name + ' - ' + el.name;
        obj['id'] = id[0];
        opti.push(obj);
        return null;
      });
    }
  }

  const handleAddProposals = (e, i) => {
    const value = e.target.value;
    if (!value.trim()) {
      alert(`Proposal is required.`);
      return;
    }

    setProposals({
      ...proposals,
      [`proposal1`]: e.target.value
    });
  };

  //submit proposal
  const handleConfirm = async (e) => {
    
    setIsSubmitting(true);
    e.preventDefault();
    let err = error;
    if (!isTermsChecked) {
      err.terms = 'You must agree to the terms below';
      setIsSubmitting(false);
      return setError({ ...error, err });
    }
    const temp = formData;
    const data = {
      uid: currentUser.uid,
      task_id: id[0],
      brand_id: task.brand_id,
      campaign_id: task.campaign_id,
      proposals: proposals,
      task_type: task.type,
      shippingDetails: user.shipping_details ? user.shipping_details : 'No address',
      creator_id: currentUser.uid,
      creator_name: user.name,
      creator_username: user.username,
      creator_socials: user.creator_socials ? user.creator_socials : 'No socials',
      creator_photo: user.avatar,
      average_rating: user.average_rating || "Not rated",
      recipe: temp.recipe,
      products: selectedProd
    };
    if (!task.campaign) {
      await TaskService.SendProposalTask(id[0], data);

      toast.success((t) => (
        <div
          className={`${
            t.visible ? 'animate-enter' : 'animate-leave'
          } max-w-md w-full bg-white  rounded-lg pointer-events-auto flex `}
          style={{
            padding: '16px',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <p style={{ fontSize: '16px', fontWeight: 'bold' }}>
            Proposal sent. You can view the status of your proposal in "Manage Tasks".
          </p>
        </div>
      ), { duration: 3800 });
     setTimeout(() => {
       onClose();
     }, 4000)
    } else {
      await CampaignService.SendProposalCampTask(id[0], data);
    }

  };

  const onRemove = (selectedList, removedItem) => { };

  return (
    <>
      <GlobalDialog className="px-3" open={open} onClose={onClose}>
        
        <Toaster/>
        <Collapsible
          trigger={[
            'Brief Details',
            <div className="px-3 absolute right-0">
              <BsChevronCompactDown />
            </div>
          ]}
          triggerWhenOpen={[
            'Brief Details',
            <div className="px-3 absolute right-0">
              <BsChevronCompactUp />
            </div>
          ]}
          contentOuterClassName="mb-6"
          triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
          triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
          <div className="m-3 bg-white rounded-lg text-3xl dark:bg-gray-700">
            <div className="flex ml-12 mb-5">
              <div className="flex mr-5 font-bold">Brand:</div>
              <a
                className="text-blue underline"
                target="_blank"
                href={`http://${task && task.brand_website}`}
                rel="noreferrer">
                {task && task.brand_name}
              </a>
            </div>

            {/*<div className="flex ml-12 mb-5">
              <div className="flex mr-5 font-bold">Deadline:</div>
              <div>{task && deadline}</div>
            </div>*/}
            <div className="flex ml-12 mb-5">
              <div className="flex mr-5 font-bold">Brief:</div>
              <div className="flex flex-col space-y-2">
                <div>
                  {task &&
                    task.recipe_videos > 0 &&
                    `Create ${task.recipe_videos} short form recipe videos showcasing the brand as organically (not ad-style or salesy) as possible.`}
                </div>
                <div>
                  {task &&
                    task.product_showcase > 0 &&
                    `Create a short form product demo video showcasing the brand as organically (not ad-style or salesy) as possible.`}
                </div>
                <div>
                  Crafted and the brand will own these videos and will post them on their social
                  accounts, use them for paid social, website, etc. in perpetuity.
                </div>
              </div>
            </div>
            <div className="ml-12 mb-5">
              <div>
                <div className="mt-2 font-bold">
                  {task && task.brand_name} is prioritizing these products/flavors:
                </div>
                <div className="">{task && task.note1}</div>
              </div>
              <div>
                <div className="mt-2 font-bold">{task && task.brand_name}'s Target Audience:</div>
                <div className="">{task && task.note2}</div>
              </div>
              <div>
                <div className="mt-2 font-bold">Key talking points for creators:</div>
                <div className="">{task && task.note3}</div>
              </div>
              { task && task.regions && (
              <div className=''>
              <div className="mt-2 font-bold">Key talking points for creators:</div>
              <div>
              {task.regions.map((region, index) => (
                <div className="flex mt-2flex-row" key={region.value}>
                  {region.label}
                  {index !== task.regions.length - 1 && ','}
               </div>
              ))} 
              </div>
            </div>)}
            </div>
          </div>
        </Collapsible>
        <Collapsible
          trigger={[
            'Examples of Video Types',
            <div className="px-3 absolute right-0">
              <BsChevronCompactDown />
            </div>
          ]}
          triggerWhenOpen={[
            'Examples of Video Types',
            <div className="px-3 absolute right-0">
              <BsChevronCompactUp />
            </div>
          ]}
          contentOuterClassName="mb-6"
          triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
          triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
          <div className="m-3">
            <VideoCarousel />
          </div>
        </Collapsible>

        <Collapsible
          trigger={[
            'Proposal Details',
            <div className="px-3 absolute right-0">
              <BsChevronCompactDown />
            </div>
          ]}
          triggerWhenOpen={[
            'Proposal Details',
            <div className="px-3 absolute right-0">
              <BsChevronCompactUp />
            </div>
          ]}
          contentOuterClassName="mb-6"
          triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
          triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
        </Collapsible>
        <div className="m-3 bg-white rounded-lg text-3xl dark:bg-gray-700">
          <form onSubmit={handleConfirm}>
            <div className=" my-5 justify-between">
              <span>Select Product</span>

              {currentUser === null ? (
                <p className="text-red">No product found!</p>
              ) : (
                <div>
                  <Multiselect
                    options={opti} // Options to display in the dropdown
                    selectedValues={selectedValue} // Preselected value to persist in dropdown
                    showCheckbox={true}
                    closeOnSelect={true}
                    placeholder="Select Product"
                    style={styles}
                    onSelect={onSelect} // Function will trigger on select event
                    onRemove={onRemove} // Function will trigger on remove event
                    displayValue="name" // Property name to display in the dropdown options
                    required
                    validation={customValidator}
                  />
                </div>
              )}
            </div>
            <div className="font-bold text-center">
              Please submit video ideas below. Creative and descriptive responses improve your
              chance of getting selected.
            </div>
              <>
                <div className="py-3">
                  <span className="mb-2"> Share your idea for this video.</span>
                  <textarea
                    type="textarea"
                    rows={5}
                    onChange={(e) => handleAddProposals(e)}
                    className="py-3 px-4 text-black flex items-center text-sm font-medium leading-none bg-gray-200 hover:bg-gray-300 cursor-pointer  w-full rounded"
                    required
                  />
                </div>
              </>
            
            {/*<div className="py-3">
            <span className="mb-2"> Share your idea for video 1.</span>
            <textarea
              type="textarea"
              rows={5}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  recipe: e.target.value
                })
              }
              className="py-3 px-4 text-black flex items-center text-sm font-medium leading-none bg-gray-200 hover:bg-gray-300 cursor-pointer  w-full rounded"
            />
          </div>
          <div>
            <span className="mb-2"> Share your idea for video 2.</span>
            <textarea
              type="textarea"
              rows={5}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  incorporate: e.target.value
                })
              }
              className="py-3 px-4 flex items-center text-sm font-medium leading-none text-black bg-gray-200 hover:bg-gray-300 cursor-pointer  w-full rounded"
            />
            </div>*/}
          <div className="ml-4 items-start">
            {error && error.terms && <div className="ml-1 mb-3 text-sm text-Red">{error.terms}</div>}
            <input className='mt-1' type="checkbox" id="isTermsChecked" name="isTermsChecked" value={isTermsChecked} onChange={(e) => setIsTermsChecked(e.target.checked)} />
            <label htmlFor="isTermsChecked" className="ml-2 2xl:text-base md:text-0.7 hover:text-black">
              I acknowledge that I have read the brief and my proposal adheres to the guidelines of this brief.
            </label>
          </div>
            <div className="items-center justify-center text-center">
            <CustomButton
              className=" text-white w-1/3 mt-8 py-4 outline-none text-base"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Confirming...' : 'Confirm Proposal'}
            </CustomButton>
            </div>
          </form>
        </div>
      </GlobalDialog>
    </>
  );
}

export default ProposalModal;
