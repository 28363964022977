import React from 'react';

function Toggle(props) {
  const { text, size = 'default', checked, onChange } = props;

  let displayStyle = checked ? 'bg-Green' : 'bg-Lgray';
  return (
    <>
      <label>
        <span className={`${size} switch-wrapper`}>
          <input
            type="checkbox"
            disabled={checked}
            checked={checked}
            onChange={(e) => onChange(e)}
          />
          <span className={`${displayStyle} switch`}>
            <span className="switch-handle" />
          </span>
        </span>
        <span className="switch-label">{text}</span>
      </label>
    </>
  );
}

export default Toggle;
