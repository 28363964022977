import React, { useState } from 'react';
import AssetDeployModal from './AssetDeployModal';
import { useEffect, useRef } from 'react';
import AssetImg from '../../Assets/logo-red-ugc.png';
import CustomButton from '../../utils/customButton';


function AssetCard(props) {
  const [deployAsset, setDeployAsset] = useState(false);
  const [updatedLink, setUpdatedLink] = useState('');
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);


  const deployHandle = () => {
    setDeployAsset(true);
  };

    // Format the date and time
  const formatDate = (date) => {
    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    const formattedDate = new Date(date).toLocaleDateString(undefined, options);

    const optionsTime = { hour: 'numeric', minute: 'numeric' };
    const formattedTime = new Date(date).toLocaleTimeString(undefined, optionsTime);

    return `${formattedDate} ${formattedTime}`;
  };

  const formattedDate = formatDate(props.date);

    console.log(props && props.asset && props.asset.source);
    const source = props && props.asset && (typeof props.asset.source === 'object' ? props.asset.source.video : props.asset.source);
    const isMov = typeof source === 'string' && source.endsWith('.MOV');

    const [videoError, setVideoError] = useState(false);

    const handleVideoError = (event) => {
      console.error('Video error:', event.target.error);
      setVideoError(true);
    };
  
    
  return (
    <div className="bg-lightGray items-center md:h-14rem my-4 rounded-xl p-4 flex 2xl:w-20rem xl:15-rem md:10-rem flex-col cursor-pointer" onClick={deployHandle}>
      <div className='w-full md:h-10rem'>
        <video  ref={videoRef} preload="metadata"  src={source} className="w-full h-full rounded-t-lg object-cover" controls={false} onError={handleVideoError} />
      </div>
      
      {props.asset.photos  && (
        <div className="h-full w-full flex items-center justify-center">
          Photos
        </div>
      )}
       {props.asset.recipes  && (
        <div className="h-full w-full flex items-center justify-center">
          Recipe
        </div>
      )}
        {props.asset.tiktok_code  && (
        <div className="h-full w-full flex items-center justify-center">
         TikTok Ad Code
        </div>
      )}
      {props.asset.variations && (
        <div className="flex items-center justify-center">
          Versions
        </div>
      )}
       {props.asset.raw_footage && (
        <div className="flex items-center justify-center">
          Raw Footage
        </div>
      )}
      <div className=" max-h-min rounded-b-lg">
      <div className="text-center 2xl:text-base md:text-0.7 font-bold max-h-min break-normal rounded-b-lg">{props.name}</div>
        <div className="text-center text-xs">{formattedDate}</div>
        <div className="flex justify-center">
          <CustomButton
            type="submit"
            onClick={deployHandle}
            className="items-center px-3 m-3 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2">
            VIEW
          </CustomButton>
        </div>
      </div>
      {deployAsset ? (
        <AssetDeployModal
          open={deployAsset}
          name={props.name}
          task = {props.task}
          asset={props.asset}
          thumbnail={props.thumbnail}
          onClose={() => setDeployAsset(false)}
          id={props.id}
        />
      ) : null}
    </div>
  );
}

export default AssetCard;
