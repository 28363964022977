import React, { useState, useEffect } from 'react';
import TaskDeleteModal from './TaskDeleteModal';
import TaskDetailsModal from './SendTaskDeliverables';
import UpdateTask from './UpdateTask';
import {AiFillInstagram} from'react-icons/ai';
import { Mixpanel } from '../../mixpanel';
import {FaTiktok} from 'react-icons/fa';
import InfluencerProposalModalTask from './InfluencerProposalModalTask';
import { UsersService, useUserState } from '../../Service/userServices';
import { useAuth } from '../../Context/AuthContext';
import CustomButton from '../../utils/customButton';
function InfluencerTaskCard(props) {
  const [delTask, setDelTask] = useState(false);
  const [taskDetails, setTaskDetails] = useState(false);
  const [proposalTask, setproposalTask] = useState(false);
  const [update, setUpdate] = useState(false);
  const userState = useUserState();
  const {currentUser} = useAuth();
  const detailsHander = () => {
    setTaskDetails(!taskDetails);
  };

  useEffect(() => {
    if (currentUser !== null) {
      UsersService.FetchUserInfo(currentUser.uid);
    }
  }, []);

  const user = userState.user.value;

  const tiktokRate = user && 
  user.creator_socials && 
  user.creator_socials.tiktok && 
  user.creator_socials.tiktok.performance && 
  user.creator_socials.tiktok.performance.suggestedRate

  const instagramRate = 
  user && 
  user.creator_socials && 
  user.creator_socials.instagram && 
  user.creator_socials.instagram.suggested_rate


  const proposalHandle = () => {
    setproposalTask(!proposalTask);
  };

  const updateHandle = () => {
    let updt = update;
    setUpdate(!updt);
  };

  const handleClick = () => {
    if (props.task) {
      let mixpanel_task = {
        'brand_id': props.task.brand_id,
        'brand_name': props.task.brand_name,
        'campaign_id': props.task.campaign_id,
        'id': props.task.id,
        'name': props.task.name
      }

      Mixpanel.influencerTaskView(mixpanel_task);
    }

  }


  return (
    <>
   <div onClick={() => {
        handleClick();
        if (props.task && props.task.status === 'available' || props.task && props.task.approved_proposals && props.task.approved_proposals.length < props.task.number_of_videos) {
          proposalHandle();
        } else {
          detailsHander();
        }
      }}>
      <div className="bg-lightGray items-center rounded-xl p-1 flex flex-col cursor-pointer">
      <div className="relative w-full h-full">
      <img
            src={props.task.brand_photo}
            style={{ height: '150px' }}
            className="rounded-lg w-full h-full bg-white object-contain"
            alt="img"
          />
          {props.task && props.task.platform && props.task.platform.includes('Instagram') &&
            <div className="absolute bottom-0 left-2">
              <div className="instagram w-4 h-4"></div>
            </div>
          }
          {props.task && props.task.platform && props.task.platform.includes('TikTok') &&
            <div className={`absolute bottom-0 ${props.task.platform.length === 1 && props.task.platform.includes('TikTok') ? 'left-2' : 'left-7'}`}>
              <div className="tiktok w-4 h-4"></div>
            </div>
          }
        </div>
        <div className='lg:text-3xl md:text-xs text-center truncate overflow-hidden leading-tight mt-0.5 md:w-36'>
          {props.task.name}
        </div>
        <h2 className='font-bold'>{props.task.brand_name}</h2>
        <div className="text-base my-2 mb-1 flex text-Blue font-bold text-center">
          {props.task && props.task.platform && props.task.platform.includes('Instagram') && props.task.platform.includes('TikTok') ?
            tiktokRate && instagramRate ?
              `$${(((tiktokRate / 100) + (instagramRate / 100)) + 75 + (props.task && props.task.add_ons ? props.task.add_ons.reduce((total, addon) => total + addon.price, 0) : 0)).toFixed(2)}`
              :
              tiktokRate ?
                `$${((tiktokRate / 100) + 75 + (props.task && props.task.add_ons ? props.task.add_ons.reduce((total, addon) => total + addon.price, 0) : 0)).toFixed(2)}`
                :
                instagramRate ?
                  `$${((instagramRate / 100) + 75 + (props.task && props.task.add_ons ? props.task.add_ons.reduce((total, addon) => total + addon.price, 0) : 0)).toFixed(2)}`
                  :
                  `Connect your socials to view your rate`
            :
            props.task && props.task.platform && props.task.platform.includes('Instagram') ?
              instagramRate ?
                `$${((instagramRate / 100) + 75 + (props.task && props.task.add_ons ? props.task.add_ons.reduce((total, addon) => total + addon.price, 0) : 0)).toFixed(2)}`
                :
                `$${(75 + (props.task && props.task.add_ons ? props.task.add_ons.reduce((total, addon) => total + addon.price, 0) : 0)).toFixed(2)}`
            :
            props.task && props.task.platform && props.task.platform.includes('TikTok') ?
              tiktokRate ?
                `$${((tiktokRate / 100) + 75 + (props.task && props.task.add_ons ? props.task.add_ons.reduce((total, addon) => total + addon.price, 0) : 0)).toFixed(2)}`
                :
                `$${(75 + (props.task && props.task.add_ons ? props.task.add_ons.reduce((total, addon) => total + addon.price, 0) : 0)).toFixed(2)}`
            :
            `Connect your socials to view your rate`
          }
        </div>
        <div className="text-base mb-1 tracking-tighter font-bold text-center">
          {props.task && props.task.add_ons && props.task.add_ons.map((el, i) => (
            <span key={i}>{i !== 0 && '+'} {el.name}</span>
          ))}
        </div>
      </div>
    </div>
    <div className="items-center justify-center text-center">
      {props.task && props.task.status === 'available' ? (
        <CustomButton
          className="bg-red text-white mr-3 px-2 rounded-lg outline-none text-xs"
          onClick={() => proposalHandle()}>
          Send Proposal
        </CustomButton>
      ) : null}
  
      {(props.task.status === 'accepted' || props.task.status === 'approved') && (
        <CustomButton
          className="bg-red text-white px-2 mr-2 rounded-lg outline-none text-xs"
          onClick={() => detailsHander()}>
          Pending Approval
        </CustomButton>
      )}
    </div>
    {delTask ? (
      <TaskDeleteModal open={delTask} onClose={() => setDelTask(false)} id={props.id} />
    ) : null}
    {proposalTask ? (
      <InfluencerProposalModalTask
        open={proposalTask}
        task={props.task}
        onClose={() => setproposalTask(false)}
        fetchInfluencerTasks={props.fetchInfluencerTasks}
        id={props.id}
      />
    ) : null}
    {update ? <UpdateTask open={update} onClose={updateHandle} id={props.id} /> : null}
    {taskDetails ? (
      <TaskDetailsModal
        open={taskDetails}
        task={props.task}
        close={() => setTaskDetails(false)}
        id={props.id}
      />
    ) : null}
  </>
  );
}

export default InfluencerTaskCard;
