import React from 'react'
import { useCampaignState, CampaignService } from '../../../../Service/CampaignServices';
import { toast, Toaster } from 'react-hot-toast';
import CustomButton from '../../../../utils/customButton';
function InfluencerRemovalModal(props) {
  console.log(props);
  const removeInfluencer = async (proposal, proposal_id) => {
    console.log("task id: " + proposal.task_id);
    console.log("key: " + proposal_id);
    const data = {
      campaign_id: props.campaign_id,
      creator_id: proposal.creator_id,
      task_id: proposal.task_id,
      brand_id: props.brand_id,
      proposal_id: props.proposal_id,
      influencer_rate: props.influencerRate
    };
    
    try {
      await CampaignService.RemoveInfluencer(props.proposal_id, data);
      toast.success('Influencer Removed');
      props.close();
      props.fetchData();
    }
    catch (error) {
      toast.error('An error occurred while removing the influencer.');
    }

  }

  return (
    <div className='bg-white rounded-md break-words text-xs h-fit w-1/3'>
      <p className='justify-center text-center font-bold mx-auto mt-10'>Remove Influencer</p>
      <h1 className='flex mx-auto items-center  mt-20 justify-center'>
      Are you sure you want to remove &nbsp;<span className='font-bold'>{props.proposal.creator_address.fullname}</span>&nbsp; from this campaign?
      </h1>
      <div className='flex my-14 items-center justify-center gap-x-20'>
        <CustomButton onClick={() => removeInfluencer(props.proposal, props.proposal_id)} className='font-bold text-white rounded-md py-1 px-3 md:py-3 md:px-10'>Yes
        </CustomButton>
        <button  onClick={() => props.close()} className='border-2 font-bold hover:bg-gray-100 text-gray-500 rounded-md py-1 px-3 md:py-3 md:px-10'>No
        </button>

      </div>
    </div>
  )
}

export default InfluencerRemovalModal