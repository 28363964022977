import React, { useRef, useState, useEffect } from 'react';
import { validateFormFields } from '../../utils/functions';
import GlobalDialog from '../../utils/globalDialog';
import { useAuth } from '../../Context/AuthContext';
import { useEmailState, EmailService } from '../../Service/EmailService';
import CustomButton from '../../utils/customButton';

const fieldErrors = {
  name: 'Please Enter Name Carefully',
  subject_line: 'Please Enter subject_line',
  dynamic_variables: 'Please Enter dynamic variables',
  email_body: 'Please Enter email body'
};
const Prodform = (props) => {
  const { open, onClose } = props;
  const { currentUser } = useAuth();
  const name = useRef('');
  const email_body = useRef('');
  const [dynamic_variables, setDynamicVariables] = useState([]);
  const subject_line = useRef('');
  const [errors, setErrors] = useState({});
  const [address1, setAddress1] = useState(false);
  const [address2, setAddress2] = useState(false);
  const [user, setUser] = useState(false);
  const [twitter, setTwitter] = useState(false);
  const [instagram, setInstagram] = useState(false);
  const [tiktok, setTiktok] = useState(false);
  const [firstName, setFirstName] = useState(false);
  const handleAddress1 = () => {
    setAddress1(!address1);
    if (address1 === false) {
      setDynamicVariables([...dynamic_variables, 'address1']);
    } else {
      const index = dynamic_variables.indexOf('address1');
      if (index > -1) {
        dynamic_variables.splice(index, 1);
        setDynamicVariables(dynamic_variables);
      }
    }
  };

  const handleAddress2 = () => {
    setAddress2(!address2);
    if (address2 === false) {
      setDynamicVariables([...dynamic_variables, 'address2']);
    } else {
      const index = dynamic_variables.indexOf('address2');
      if (index > -1) {
        dynamic_variables.splice(index, 1);
        setDynamicVariables(dynamic_variables);
      }
    }
  };

  const handleUser = () => {
    setUser(!user);
    if (user === false) {
      setDynamicVariables([...dynamic_variables, 'user']);
    } else {
      const index = dynamic_variables.indexOf('user');
      if (index > -1) {
        dynamic_variables.splice(index, 1);
        setDynamicVariables(dynamic_variables);
      }
    }
  };
  const handleInstagram = () => {
    setInstagram(!instagram);
    if (instagram === false) {
      setDynamicVariables([...dynamic_variables, 'instagram']);
    } else {
      const index = dynamic_variables.indexOf('instagram');
      if (index > -1) {
        dynamic_variables.splice(index, 1);
        setDynamicVariables(dynamic_variables);
      }
    }
  };

  const handleTiktok = () => {
    setTiktok(!tiktok);
    if (tiktok === false) {
      setDynamicVariables([...dynamic_variables, 'tiktok']);
    } else {
      const index = dynamic_variables.indexOf('tiktok');
      if (index > -1) {
        dynamic_variables.splice(index, 1);
        setDynamicVariables(dynamic_variables);
      }
    }
  };

  const handleTwitter = () => {
    setTwitter(!twitter);
    if (twitter === false) {
      setDynamicVariables([...dynamic_variables, 'twitter']);
    } else {
      const index = dynamic_variables.indexOf('twitter');
      if (index > -1) {
        dynamic_variables.splice(index, 1);
        setDynamicVariables(dynamic_variables);
      }
    }
  };
  const handleFirstName = () => {
    setFirstName(!firstName);
    if (firstName === false) {
      setDynamicVariables([...dynamic_variables, 'firstName']);
    } else {
      const index = dynamic_variables.indexOf('firstName');
      if (index > -1) {
        dynamic_variables.splice(index, 1);
        setDynamicVariables(dynamic_variables);
      }
    }
  };
  console.log('dy----', dynamic_variables);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setErrors({});
    const formValues = {
      name: name.current.value,
      subject_line: subject_line.current.value,
      email_body: email_body.current.value
    };

    const validationErrors = validateFormFields(formValues, fieldErrors);
    if (Object.keys(validationErrors).length) {
      return setErrors(validationErrors);
    }
    let dynamic_var = dynamic_variables.reduce((a, v) => ({ ...a, [v]: v }), {});

    const temp = {};
    temp.template_name = name.current.value;
    temp.subject_line = subject_line.current.value;
    temp.email_body = email_body.current.value;
    temp.dynamic_variables = dynamic_var;
    await EmailService.AddTemplate(temp);
    props.onClose();
  };

  return (
    <GlobalDialog open={open} onClose={onClose}>
      <div className="m-12">
        <h1 className="text-xl font-bold mb-3 font-black xs:xl md:text-4xl">Add a template</h1>
        <hr />

        <form onSubmit={handleSubmit} className="p-4">
          <div className="my-2">
            <label htmlFor="templateName" className="text-black block md:text-lg mb-2 font-bold">
              Template Name
            </label>
            <input
              name="templateName"
              className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
              placeholder="Enter template Name"
              required
              ref={name}
            />
            <div className="text-red px-3 font-medium">{errors.name || ''}</div>
          </div>
          <div className="my-2">
            <label htmlFor="description" className="text-black block md:text-lg mb-2 font-bold">
              Email Body
            </label>
            <textarea
              className="focus:outline-none w-full bg-lightGray p-2 rounded-lg"
              style={{ height: '15vh' }}
              placeholder="Please enter Email body"
              name="email_body"
              ref={email_body}
            />
            <div className="text-red px-3 font-medium">{errors.email_body || ''}</div>
          </div>

          <div className="my-2">
            <label
              htmlFor="dynamic variables"
              className="text-black block md:text-lg mb-2 font-bold">
              Dynamic Variables
            </label>
          </div>
          <div className="grid lg:grid-cols-4 grid-cols-1 items-center left-4">
            <p
              className={`bg-${
                firstName ? 'gray-700' : 'gray-400'
              } px-3  mr-3 mb-3 cursor-pointer text-white rounded-full`}
              onClick={() => handleFirstName()}>
              First Name
            </p>
            <p
              className={`bg-${
                address1 ? 'gray-700' : 'gray-400'
              } px-3  mr-3 mb-3 cursor-pointer text-white rounded-full`}
              onClick={() => handleAddress1()}>
              Address 1
            </p>
            <p
              className={`bg-${
                address2 ? 'gray-700' : 'gray-400'
              } px-3  mr-3 mb-3 cursor-pointer text-white rounded-full`}
              onClick={() => handleAddress2()}>
              Address 2
            </p>
            <p
              className={`bg-${
                user ? 'gray-700' : 'gray-400'
              } px-3  mr-3 mb-3 cursor-pointer text-white rounded-full`}
              onClick={() => handleUser()}>
              User
            </p>
            <p
              className={`bg-${
                instagram ? 'gray-700' : 'gray-400'
              } px-3  mr-3 mb-3 cursor-pointer text-white rounded-full`}
              onClick={() => handleInstagram()}>
              Instagram
            </p>
            <p
              className={`bg-${
                tiktok ? 'gray-700' : 'gray-400'
              } px-3  mr-3 mb-3 cursor-pointer text-white rounded-full`}
              onClick={() => handleTiktok()}>
              Tiktok
            </p>
            <p
              className={`bg-${
                twitter ? 'gray-700' : 'gray-400'
              } px-3  mr-3 mb-3 cursor-pointer text-white rounded-full`}
              onClick={() => handleTwitter()}>
              Twitter
            </p>
          </div>

          <div className="my-2">
            <label htmlFor="subject_line" className="text-black block md:text-lg mb-2 font-bold">
              Subject Line
            </label>
            <textarea
              className="focus:outline-none w-full bg-lightGray p-2 rounded-lg"
              style={{ height: '15vh' }}
              placeholder="Please enter subject line"
              name="subject_line"
              ref={subject_line}
            />
            <div className="text-red px-3 font-medium">{errors.subject_line || ''}</div>
          </div>
          <div className="bg-Blue w-full px-4 text-3xl font-bold py-3 rounded-md text-white mt-4 w-1/4 items-center justify-center text-center cursor-pointer">
            <CustomButton
              type="submit"
              className=" w-full px-4 text-3xl font-extrabold"
              onClick={handleSubmit}>
              Create Template
            </CustomButton>
          </div>
        </form>
      </div>
    </GlobalDialog>
  );
};

export default Prodform;
