import React, { useState } from 'react';
import GlobalDialog from '../../utils/globalDialog';
import Modal from 'react-responsive-modal';

function WhiteListingInstructions(props) {
  const [activeTab, setActiveTab] = useState('instagram');

  return (
    <Modal
      open={props.open}
      onClose={props.close}
      center
      classNames={{ modal: 'customWideModal' }}
      closeOnOverlayClick={false}
    >
      <div className='h-screen'>
        <h1 className='text-center 2xl:text-2xl md:text-base font-bold'>Whitelisting Instructions</h1>
        <div className='flex justify-center border-b-2 w-full my-4'>
          <button 
            className={`mx-2 2xl:text-base md:text-0.7 ${activeTab === 'instagram' ? 'font-bold text-red underline' : 'text-black'}`}
            onClick={() => setActiveTab('instagram')}
          >
            Instagram
          </button>
          <button 
            className={`mx-2 2xl:text-base md:text-0.7 ${activeTab === 'tiktok' ? 'font-bold text-red underline' : 'text-black'}`}
            onClick={() => setActiveTab('tiktok')}
          >
            TikTok
          </button>
        </div>

        {activeTab === 'instagram' && (
          <div className='2xl:text-base md:text-0.7'>
            <ol>
              <li className='p-2 m-2'><strong>Step 1: Log into Meta Business Suite</strong> - <a className= "font-bold text-Blue underline" href="https://business.facebook.com/" target='_blank' >Log in here</a> with the business credentials you normally use to run ads.</li>
              <li className='p-2 m-2'><strong>Step 2: Navigate to Meta Business Suite Settings</strong> - Click on 'Settings' located at the bottom left corner of the Meta Business Suite interface.</li>
              <li className='p-2 m-2'><strong>Step 3: Navigate to Ad Partnerships</strong> - Select 'Ad partnerships' from the settings menu.</li>
              <li className='p-2 m-2'><strong>Step 4: Add a New Partnership</strong> - Click on 'Add partnership' located at the top right corner of the Ad partnerships interface.</li>
              <li className='p-2 m-2'><strong>Step 5: Search for Partner/Approved Creator's Instagram Handle</strong> - Enter the username of the partner you wish to add in the search bar, then select their account from the search results.</li>
              <li className='p-2 m-2'><strong>Step 6: Select the Business Instagram Account you wish to manage the ad from</strong> - Click 'Next' and select your own Business Instagram account. Note that partnership ad permissions will be toggled on by default.</li>
              <li className='p-2 m-2'><strong>Step 7: Send Partnership Ad Request</strong> - Click 'Next' again, then click 'Send request'. Your partner will be notified and can accept the request in the Instagram app.</li>
              <li className='p-2 m-2'><strong>Step 8: Share Request Link</strong> - You can also share the link under 'Link to request' with your partner. This link will direct them to the screen on the Instagram app where they can view and accept your request.</li>
              <li className='p-2 m-2'><strong>Step 9: Manage Sent Requests</strong> - Once the partner accepts your request, account-level permissions will be established and you can create partnership ads with this partner without additional permission requests. Partners who have not accepted the request will have a 'Request sent' status in the Ad partnerships tab. Click 'Manage' to manage the request. You can click 'Get request link' to share a link that takes them directly to the screen where they can view and accept the request in the Instagram app. You can also click 'Notify again' to re-send the request notification.</li>
              <li className='p-2 m-2'><strong>Step 9: View Video Guide for more Information</strong> - Click <a className = "text-Blue font-bold underline" href ='https://www.youtube.com/watch?v=IP0cBHWIH1U' target='_blank' >here</a> to view a video guide on how to post on Instagram using the influencer's account.</li>
            </ol>
          </div>
        )}

      {activeTab === 'tiktok' && (
        <div className='2xl:text-base md:text-0.7'>
          <ol>
            <li className='p-2 m-2'>
              <strong>Step 1: Enter the video code on TikTok Ads Manager.</strong> - Once you have received the video code from a creator, you will need to enter that code on TikTok Ads Manager.
              <ul>
                <li>Go to Tools, under the Creative tab click Creative library, click Spark ads posts, and click Apply for Authorization.</li>
                <li>Paste the video code in the search bar and click Search.</li>
                <li>Review the post and click Confirm.</li>
              </ul>
            </li>
            <li className='p-2 m-2'>
              <strong>Step 2: Create a Spark Ad.</strong> - After an authorized post is successfully added to the TikTok Ads Manager, you can create your Spark Ads.
              <ul>
                <li>At the Campaign Level, select an Advertising Objective.</li>
                <li>Next, complete Campaign and Ad Group settings.</li>
                <li>At the Ad Level, under Identity, enable Use TikTok account to deliver Spark Ads.</li>
                <li>Select the TikTok account that authorized the post you will use as your Spark Ad's creative.</li>
                <li>Under Ad details, click +TikTok post to select your creative.</li>
                <li>Complete the rest of the Ad Settings and click Submit.</li>
              </ul>
            </li>
            {/* Add the rest of the steps here */}
          </ol>
          <p> <strong>Note:</strong> Stitch posts made by creators based in the US are not supported at this time.</p>
          <p> <strong>Note:</strong> You cannot edit a post's caption after it's been authorized as an ad. Please make sure the ad caption is the final version when creating Spark Ads.</p>
          <p> <strong>Note:</strong> A private video will become public once it is used in a campaign, and the video privacy status cannot be changed during promotion (however, you can turn on the TikTok Ads Only Mode).</p>
          <p> <strong>Note:</strong> Video code can only be deleted when all the ads this video is used in are deleted on TikTok Ads Manager.</p>
          <p> <strong>Note:</strong> Each TikTok Ads Manager account can support a maximum of 10,000 Spark Ads.</p>
        </div>
      )}
      </div>
    </Modal>
  );
}

export default WhiteListingInstructions;