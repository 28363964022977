import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { useAuth } from '../../Context/AuthContext'
import CreatorDiscoveryContent from './CreatorDiscoveryContent'
import CreatorDiscoverySearch from './CreatorDiscoverySearch'
function CreatorDiscovery() {
  const [searchMode, setSearchMode] = useState('filters')
  return (
  <div className="bg-white m-4 rounded-lg w-full" style={{ height: 'calc(97vh - 7rem)' }}>
   <div className="flex items-center justify-center 2xl:text-base md:text-0.7 border-2 mx-auto w-fit mt-3 space-x-2 rounded-3xl relative">
      <div 
        className={`absolute left-0 top-0 h-full bg-Blue text-white rounded-3xl transition-transform duration-500 ease-in-out ${searchMode === 'filters' ? 'transform translate-x-0' : 'transform translate-x-full'}`}
        style={{ width: '50%', background: '#648de5', zIndex: 1 }}
      />
      <div 
        className={`cursor-pointer hover:bg-Blue hover:text-white px-2 py-1 z-10 ${searchMode === 'filters' ? ' text-white rounded-3xl' : 'bg-white text-gray-700 rounded-2xl'}`} 
        onClick={() => setSearchMode('filters')}
      >
        Search by Filters
      </div>
      <div 
        className={`cursor-pointer hover:bg-Blue hover:text-white px-2 py-1 z-10 ${searchMode === 'profile' ? ' text-white rounded-3xl' : 'bg-white text-gray-700 rounded-2xl'}`} 
        onClick={() => setSearchMode('profile')}
      >
        Search By Profile
      </div>
    </div>
    {searchMode === 'filters' ? <CreatorDiscoveryContent/> : <CreatorDiscoverySearch/>}
  </div>
  )
}

export default CreatorDiscovery