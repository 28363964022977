import React from 'react'
import { useCampaignState, CampaignService } from '../../../../Service/CampaignServices';
import { toast, Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../Context/AuthContext';
import { CampaignEmailService } from '../../../../Service/CampaignEmailService';
import CustomButton from '../../../../utils/customButton';
function DenyInfluencerModal(props) {
  const { id } = useParams();
  const {currentUser} = useAuth();
  const deniedProp = async (proposal, proposal_id) => {
    console.log("task id: " + proposal.task_id);
    console.log("key: " + proposal_id);
    const data = {
      campaign_id: id,
      creator_id: proposal.creator_id,
      task_id: proposal.task_id,
      proposal_id: proposal_id
    };
  

    try {
      await CampaignService.DenyProposal(proposal.task_id, proposal_id, data);
      props.fetchData();
      await CampaignService.FetchSingleCampaign(id);
      toast.error('Proposal Denied');
      props.close();
    } 
    catch (error) {
      
    }
  };

  return (
    <div className='bg-white rounded-md break-words text-xs my-5 h-auto md:h-fit w-1/3 md:w-1/3 flex flex-col items-center justify-center'>
      <p className='2xl:text-3xl text-base text-center mt-10'>Deny Proposal</p>
      <h1 className='flex mx-auto items-center text-base mt-10 justify-center'>
      Are you sure you want to deny &nbsp;<span className='font-bold'>{props.proposal.creator_address.fullname}'s</span>&nbsp; proposal?
      </h1>
      <div className='flex my-5 items-center justify-center gap-x-20'>
        <CustomButton onClick={() => deniedProp(props.proposal, props.proposal_id)} className=' font-bold text-white py-1 px-3 md:py-3 md:px-10'>Yes
        </CustomButton>
        <button  onClick={() => props.close()} className='border-2 font-bold hover:bg-gray-100 text-gray-500 rounded-md py-1 px-3 md:py-3 md:px-10'>No
        </button>

      </div>
    </div>
  )
}

export default DenyInfluencerModal