import React from 'react'
import { useTabState } from '../../../utils/TabState';
import CheckmarkIcon from '../../../Assets/Eo_circle_green_checkmark.png'
import { useCampaignState } from '../../../Service/CampaignServices';
function CampaignLaunchSucess(props) {
  const tabState = useTabState();
  const campaignState = useCampaignState();
  console.log("CAMPAIGN MESSAGE HERE", campaignState);
  return (
    <div className='text-center flex items-center flex-col justify-center'>
      <img src={CheckmarkIcon} className='w-48 h-48 my-10' alt="Checkmark Icon" />
      <p className='font-bold mx-10'>
        Congratulations! Your campaign has been launched. We've already invited select Crafted creators to apply, but you can also invite creators outside of the Crafted network to apply for your campaign.
        This will help increase the number of proposals for this campaign.
      </p>
      <button
        onClick={() =>  tabState.merge({ currentCampModalTab: 5})}
        className="py-2 w-fit bg-red font-bold w-1/3 px-10 mb-5 rounded-lg text-white mt-4 items-center justify-center text-center cursor-pointer"
      >
        Invite More Creators
      </button>
      <button onClick={props.onClose} className="mb-3">Not right now</button>
    </div>
  )
}

export default CampaignLaunchSucess