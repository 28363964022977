import React, { useContext, useEffect, useState } from 'react';
import { CampaignState } from '../../../../utils/campaignState';
import { useCampaignState, CampaignService } from '../../../../Service/CampaignServices';
import { useTabState } from '../../../../utils/TabState';
import twitter from '../../../../Assets/twitter.png';
import tiktok from '../../../../Assets/tiktok-square.png';
import instagram from '../../../../Assets/instagram (1).png';
import 'react-responsive-modal/styles.css';
import { FaRegCommentDots } from "react-icons/fa";
import { useAuth } from '../../../../Context/AuthContext';
import { FaPlus, FaChartLine } from 'react-icons/fa';
import AddInstagramAnalyticsModal from './AddInstagramAnalytics';
import { useParams } from 'react-router-dom';
import { baseUrl } from '../../../../baseUrl';
import PostAnalyticsChart from './PostAnalyticsChart';
import zIndex from '@material-ui/core/styles/zIndex';
import { BrandContext } from '../../../../routes/PrivateRoute/BrandDashboardRoute';
import InstagramPostComments from './InstagramPostComments';

export default function CompaignDetails(props) {
  const campaignState = useCampaignState();
  const [open, setOpen] = useState();
  const [fetchAnalytics, setFetchAnalytics] = useState( );
  const [followerCountMap, setFollowerCountMap] = useState({});
  const [tikTokUserInfo, setTikTokUserInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [addAnalyticsModal, setAddAnalyticsModal] = useState(false);
  const [analyticsChart, setAnalyticsChart] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [analytics, setAnalytics] = useState([]);
  const [igComments,  setIgComments] = useState(false);
  const [postArray, setPostArray] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [shouldReloadAnalytics, setShouldReloadAnalytics] = useState(false);
  const [postId, setPostId] = useState(null);
  const {id} = useParams();
  const currentUser = useAuth();
  const { brandId } = useContext(BrandContext);

  const handleAddAnalytics = (post, postId, task, task_id) => {
    setSelectedPost({ post, postId, task, task_id });
    setAddAnalyticsModal(true);
    setShouldReloadAnalytics(true); // Set shouldReloadAnalytics to true
  };

  const postAnalyticsChart = (post, postId, task, task_id) => {
    setSelectedPost({ post, postId, task, task_id });
    setAnalyticsChart(true); // Set shouldReloadAnalytics to true
  };


  const postComments = (post, postId, task, task_id) => {
    setSelectedPost({ post, postId, task, task_id });
    setIgComments(true);
  };
  useEffect(() => {
    if (id) {
      CampaignService.FetchSingleCampaign(id);
    }
  }, [id, shouldReloadAnalytics]);

const res = JSON.stringify(campaignState.campaign.value);
const campaign = JSON.parse(res);

useEffect(() => {
  if (campaign.analytics) {
    // Use the campaign_id in your API call
    const fetchAnalytics = async () => {
      try {
        const response = await fetch(`${baseUrl}api/campaigns/${id}/getVideoAnalytics`);
        const data = await response.json();
        setAnalytics(data.result);
      } catch (error) {
        console.error('Error fetching Influencer Tasks:', error);
      }
    };

    fetchAnalytics();
  }
}, [campaign.analytics, id]);


const refreshAnalytics = async () => {
  try {
    let req_body = {
      brand_id: brandId,
    };
    const response = await fetch(`${baseUrl}api/campaigns/influencer/${id}/refreshAnalytics`, {
      method: 'POST',
      body: JSON.stringify(req_body),
    });
    const data = await response.json();
    setFetchAnalytics(data); // Assuming 'data' contains the new analytics
    console.log('Fetched data:', data);
  } catch (error) {
    console.error('Error refreshing analytics:', error);
  }

};

useEffect(() => {
  refreshAnalytics();
}, []);

const tasks = campaign.tasks || {};
const allPosts = [];

for (const taskId in tasks) {
  const task = tasks[taskId];

  const posts = task.posts || {};

  for (const postId in posts) {
    const post = posts[postId];
    allPosts.push({ postId, post });
  }
}



const fetchTikTokInfo = async (creator_uid) => {
  try {
    const accessTokenResponse = await fetch(`${baseUrl}api/influencers/tiktok/access_token/${creator_uid}`);

    if (!accessTokenResponse.ok) {
      throw new Error('Failed to retrieve access token');
    }

    const accessTokenData = await accessTokenResponse.json();

    const accessToken = accessTokenData.result;
    const response = await fetch('https://open.tiktokapis.com/v2/user/info/?fields=open_id,union_id,avatar_url,follower_count,likes_count,view_count,video_count,is_verified,profile_deep_link,display_name,likes_count', {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });

    if (response.ok) {
      const data = await response.json();
      const userInfo = data.data.user;
      const followerCount = userInfo.follower_count;
      return followerCount; // Return the follower count
    }
  } catch (error) {
    console.error('Error retrieving user info:', error);
    return null;
  }
};

const fetchInstagramInfo = async (creator_uid, media_id) => {
  try {
    const igInfoResponse = await fetch(`${baseUrl}/api/influencers/instagram/${creator_uid}`);
    if (!igInfoResponse.ok) {
      throw new Error('Failed to retrieve access token');
    }

    const instagramData = await igInfoResponse.json();
    const userAccessToken = instagramData.access_token;
    const response = await fetch(`https://graph.facebook.com/v18.0/${media_id}/insights?access_token=${userAccessToken}&metric=plays,comments,likes,shares`, {
    });

    if (response.ok) {
      const data = await response.json(); // Return the follower count
      const { plays, comments, likes, shares } = data.data[0].values[0];
      const instagramInfo = {
        plays,
        comments,
        likes,
        shares,
      };
      return instagramInfo;
    }
  } catch (error) {
    console.error('Error retrieving user info:', error);
    return null;
  }
};

const fetchUserInfoForPosts = async () => {
  const creatorIds = [];

  for (const { post } of allPosts) {
    if (!tikTokUserInfo[post.creator_id]) {
      if (!creatorIds.includes(post.creator_id)) {
        creatorIds.push(post.creator_id);
      }
    }
  }

  for (const creatorId of creatorIds) {
    const followerCount = await fetchTikTokInfo(creatorId);
    if (followerCount !== null) {
      setFollowerCountMap((prevMap) => ({
        ...prevMap,
        [creatorId]: followerCount,
      }));
    }
  }
};

useEffect(() => {
  fetchUserInfoForPosts();

}, []);


useEffect(() => {
  if (shouldReloadAnalytics) {
    fetchUserInfoForPosts();
    setShouldReloadAnalytics(false); // Reset the shouldReloadAnalytics state variable
  }
}, [shouldReloadAnalytics]);

const calculateEngagementRate = (likes, comments, views) => {
  let totalLikes = parseInt(likes);
  let totalComments = parseInt(comments);
  let totalViews = parseInt(views);
  console.log("likes: ", likes);
  console.log("comments: ", comments);
  console.log("views: ", views);
  const engagementRate = ((totalLikes + totalComments) / totalViews * 100)  ;
  console.log("engagement rate: ", engagementRate) ;
  return engagementRate.toFixed(2); // Round to 2 decimal places
};

  return (
    <div
    className="px-3 md:px-10 md:mt-3 font-bold  overflow-x-auto h-40">
      
    {tasks && Object.keys(tasks).length > 0 ? (
      <>
        {Object.entries(tasks).map(([task_id,task], i) => {
          if (task.campaign_task) {
            const postArray = [];
  
            for (const postId in task.posts) {
              const post = task.posts[postId];
              postArray.push({ postId, post });
            }
  
            return (
              <div key={task.id} className='mb-10 overflow-x-auto shadow-md rounded-md'>
              <div>
                <h1 className="2xl:text-3xl md:text-0.7 bg-gray-700 w-full rounded-t-md py-3 text-white font-bold ">
                <p className='ml-3'>{task.name} {postArray.length > 0 ? `(${postArray.length} posts)` : null} </p>
               </h1>
              </div>
              <table className=" 2xl:text-3xl md:text-0.7 font-thin rounded-lg table-auto">
                <thead className="w-full gap-x-8">
                    <tr className={`h-auto mx-2 bg-lightGray border-none `}>
                    <th className="px-8 py-3 text-center leading-4 tracking-wider md:w-1/6 lg:w-1/6">

                        Creator
                      </th>
                      <th className="px-8 py-3 text-center leading-4 tracking-wider md:w-1/6 lg:w-1/6">

                        Post
                      </th>
                      <th className="px-8 py-3 text-center leading-4 tracking-wider md:w-1/6 lg:w-1/6">

                        Views
                      </th>
                      <th className="px-8 py-3 text-center leading-4 tracking-wider md:w-1/6 lg:w-1/6">

                        Likes
                      </th>
                      <th className="px-8 py-3 text-center leading-4 tracking-wider md:w-1/6 lg:w-1/6">

                        Comments
                      </th>
                      <th className="px-8 py-3 text-center leading-4 tracking-wider md:w-1/6 lg:w-1/6">

                        Engagement Rate
                      </th>
                      {task && task.traffic_link && Object.keys(task.traffic_link).length > 0 && (
                         <th className="px-8 py-3 text-center mx-auto leading-4 tracking-wider md:w-1/6 lg:w-1/6">
                        Clicks
                       </th>
                      )}
                      <th className="w-fit py-3 text-center text-sm leading-4 tracking-wider">
                      </th>
                      <th className="w-fit py-3 text-center text-sm leading-4 tracking-wider">
                      </th>
                      <th className="w-fit py-3 text-center text-sm leading-4 tracking-wider">
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {postArray.map(({ postId, post }) => (
                      <tr key={postId} className="px-4 py-2 border-b-2 border-gray-200">
                        <td className="px-6 py-4 whitespace-no-wrap">
                          <div className="flex justify-center items-center">
                            {post.creator_name}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap">
                          <div className="flex justify-center items-center">
                            <a href={post.link} target="_blank">
                              {post.platform === 'tiktok' ? (
                                <img className="w-5 h-5" src={tiktok} alt="tiktok" />
                              ) : (
                                <img className="w-5 h-5" src={instagram} alt="instagram" />
                              )}
                            </a>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap">
                          <div className="flex justify-center items-center">
                            {post.performance ? post.performance.views : '0'}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap">
                          <div className="flex justify-center items-center">
                            {post.performance ? post.performance.likes : '0'}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap">
                          <div className="flex justify-center items-center">
                            {post.performance ? post.performance.comments : '0'}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap">
                          <div className="flex justify-center items-center">
                            {(
                              post.performance ? (
                                <span>
                                  {calculateEngagementRate(
                                    post.performance.likes,
                                    post.performance.comments,
                                    post.performance.views
                                  )}%
                                </span>
                              ) : (
                                <span className="text-gray-400"></span>
                              )
                            )}
                          </div>
                        </td> 
                        <td className="px-6 py-4 whitespace-no-wrap">
                        {
                          post.performance && (
                            Object.values(post.performance).reduce((acc, curr) => acc + (curr.clicks || 0), 0) > 0 && (
                              <div className="flex justify-center items-center">
                                {Object.values(post.performance).reduce((acc, curr) => acc + (curr.clicks || 0), 0)}
                              </div>
                            )
                          )
                        }
                          </td>
                       
                        <td className="px-6 py-4 whitespace-no-wrap">
                          {post.platform === 'instagram' && !post.media_id && (
                            <div title="Add Instagram analytics" className="px-2 py-2 bg-red rounded-md cursor-pointer" onClick={() => handleAddAnalytics(post, postId, task, task_id)}>
                              <button className="flex justify-center items-center text-white">
                                <FaPlus className="w-3 h-3" />
                              </button>
                            </div>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap">
                          <button title="View post analytics" onClick={() => postAnalyticsChart(post, postId, task, task_id)}>
                            <FaChartLine className="mt-2 w-5 h-5" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>

              </table>
            </div>
              );
            }
          })}
        </>
      ) : (
        <div className="h-full w-full flex items-center justify-center">
          <p>No Posts available</p>
        </div>
      )}
     {addAnalyticsModal ? (
  <AddInstagramAnalyticsModal
    open={addAnalyticsModal}
    post={selectedPost.post}
    reloadAnalytics={shouldReloadAnalytics}
    setReloadAnalytics={setShouldReloadAnalytics}
    close={() => setAddAnalyticsModal(false)}
    id={selectedPost.postId}
    taskId={selectedPost.task_id}
  />
) : null}
  { analyticsChart ? (
    <PostAnalyticsChart
      open={analyticsChart}
      close={() => setAnalyticsChart(false)}
      post={selectedPost.post}
      postId={selectedPost.postId}
      taskId={selectedPost.task_id}
    />
  ) : null }
{ igComments ? (
    <InstagramPostComments
      open={igComments}
      close={() => setIgComments(false)}
      post={selectedPost.post}
      postId={selectedPost.postId}
      taskId={selectedPost.task_id}
    />
  ) : null }
 

    </div>
  );
}
