import React, { useState } from 'react';
import Modal from 'react-responsive-modal';

function CreatorReviewsGuide(props) {
  const [activeTab, setActiveTab] = useState('instagram');

  return (
    <Modal
      open={props.open}
      onClose={props.close}
      center
      classNames={{ modal: 'customWideModal' }}
      closeOnOverlayClick={false}
    >
      <div className='h-fit p-2'>
        <h1 className="text-3xl font-bold mb-4">Creator Review Guide</h1>
        <div className='border-b w-full mb-4'></div>
        <div className="space-y-4"> 
          <h2 className="2xl:text-2xl md:text-base font-bold">Introduction</h2> 
          <p className="2xl:text-2xl md:text-base xs:text-0.7">Reviews are an integral part of our platform, serving as a tool for brands to evaluate creators based on their brief performances. This process ensures the high quality of content produced on our platform.</p>
          
          <h2 className="2xl:text-2xl md:text-base font-bold">Your Rating</h2> 
          <p className="2xl:text-2xl md:text-base xs:text-0.7">On this page, your overall creator rating, which is an aggregate of all your reviews, will be displayed. This rating provides a snapshot of your performance as perceived by the brands you've worked with.</p>

          <h2 className="2xl:text-2xl md:text-base font-bold">Review Creation</h2> 
          <p className="2xl:text-2xl md:text-base xs:text-0.7">Reviews are created by brands after evaluating your work. You can view these reviews in this tab, which serves as a record of your interactions with different brands.</p>

          <h2 className="2xl:text-2xl md:text-base font-bold">Review Details</h2> 
          <p className="2xl:text-2xl md:text-base xs:text-0.7">Clicking on a review will reveal its details. This includes a score for Professionalism, Quality, and Performance, as well as any additional feedback provided by the brand. This feedback can provide valuable insights into how you can improve your future performances.</p>
        </div>
      </div>
    </Modal>
  );
}

export default CreatorReviewsGuide;