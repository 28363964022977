import React, { useEffect, useState } from 'react';
import { useTabState } from '../../../utils/TabState';
import { useAuth } from '../../../Context/AuthContext';
import { CampaignService } from '../../../Service/CampaignServices';
import { CampaignState } from '../../../utils/campaignState';
import Loader from '../../Loader';
import toast, { Toaster} from 'react-hot-toast';
import { Mixpanel } from '../../../mixpanel';

export default function CampaignConfirmation({ onClose, campaign }) {
  const tabState = useTabState();
  const { currentUser } = useAuth();
  const campaignState = CampaignState();
  const [numberOfVideos, setNumberOfVideos] = useState({});

  const handleContinue = async () => {
    const task = JSON.stringify(campaign.tasks);

    const edited_campaign = {
      campaign_name: campaign.campaignName,
      campaign_photo: campaign.campaignPhoto,
      influencer_reach_budget: (campaign.influencerBudget),
      total_budget: TOTAL_BUDGET * 100,
      brand_id: campaign.brandId,
    }
    if (campaign.brandId) {
      await CampaignService.UpdateCampaign(campaign.id, edited_campaign);
    toast.success('Campaign Updated successfully!');
    setTimeout(() => {
    onClose(); 
    window.location.reload();
    }, 2000)
  }
    
  };


  const tasks = campaign && campaign.tasks ? Object.values(campaign.tasks) : [];

  const influencerBudget = campaign.influencerBudget

  useEffect(() => {
    if (campaign && campaign.tasks && campaign.tasks.length) {
      const data = {};
      campaign.tasks.forEach(el => {
        data[el.name] = el.number_of_videos;
      });
      setNumberOfVideos(data);
    }
  }, [campaign.tasks]); // Add campaign.tasks as a dependency for this effect
  console.log(campaign.tasks)

  const calcualtedVal = (task) => {
    let sum = 0;
    if (Array.isArray(task && task.add_ons)) {
      sum = task.add_ons.map(addOn => ({
        ...addOn,
        price: Number(task.number_of_videos) * Number(addOn.price)
      })).reduce((acc, addOn) => acc + addOn.price, 0);
    }
    return sum + (Number(task.number_of_videos) * 200);
  };
  
  const calcualtedTotal = () => {
    const tasksArray = campaign && campaign.tasks ? Object.values(campaign.tasks) : [];
    const data = tasksArray.map(task => calcualtedVal(task));
    return data.reduce((acc, curr) => (!isNaN(curr) ? acc + curr : acc), 0);
  };

  const TOTAL_BUDGET = eval(calcualtedTotal() + (Number(campaign.influencerBudget) / 100));


  return (
 
    <div className='p-4'>   
    <Toaster/>
      <p className='ml-2 font-bold'>Campaign Name: {campaign.campaignName}</p>
      {tasks  ? (
        tasks.map((el, i) => {
          return (
            <div className={` ${(i + 1 % 2 !== 0) ? 'bg-lightGray' : ''} m-2 p-5 rounded-lg `}>
              <div className="flex rounded-lg ">
                 {/*<div className="w-1/5 p-2">
                 <input disabled= {true} type="number" id="number" name={el.name} value={currentNumberOfVideos} className='p-3 w-2/3 bg-white rounded' onChange={(e) =>setNumberOfVideos(e.target.value)} />
                </div>*/}
                 <div className='w-1/5 p-2'>
                  <img src={el.product_photo}/>
                </div>
                 <div className="w-4/5 p-2">
            <p className="mx-2 ml-2 font-bold">
              {el.name} Budget
            </p>
            <p className='mx-2 ml-2'>{el.number_of_videos} Videos x $200</p>
            {Array.isArray(el.add_ons) ? (
              el.add_ons.map(addOn => (
                <p className="mx-2">
                  {el.number_of_videos} {addOn.name} x ${addOn.price}
                </p>
                    ))
                  ) : (
                    <p className='ml-2'>No add-ons</p>
                  )}
                  <p className="mx-2 mt-5 font-bold">
                    ${calcualtedVal(el)}
                  </p>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <p>No tasks added</p>
      )}
      
      <div className="w-full mt-4 ">
        <div className="flex rounded-lg">
          <div className="w-1/4 p-2 font-bold border border-gray-300 rounded-lg items-center justify-center text-center ">
            <span>${influencerBudget / 100}</span>
          </div>

          <div className="w-3/4 pl-4 font-bold">
            <span>Influencer Reach Budget</span>
          </div>

        </div>
      </div>
      <div className='justify-center text-center text-red'>
        <h1>Total Budget = ${TOTAL_BUDGET}</h1>
        
      </div>

      <div className="flex justify-center">
        <button
          type="submit"
          className="py-2 w-fit bg-red font-bold w-1/3 px-4 mb-5 rounded-lg text-white mt-4 items-center justify-center text-center cursor-pointer"
          onClick={handleContinue}
        >
          {campaignState.loading.value && <Loader />}
          Edit Campaign
        </button>
      </div>
    </div>
  );
}
