import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import ProfileHeader from './ProfileHeader';
import ProfileAssets from './ProfileAssets';
import ProfileRecipes from './ProfileRecipes';
import Navbar from './../Navbar';
import { useUserState, UsersService } from '../../Service/userServices';
import ProfileReviews from './ProfileReviews';
import { ReviewService, useReviewState } from '../../Service/ReviewService';

function ProfilePage({ match }) {
  const [currentTab, setCurrentTab] = useState(1);
  const userState = useUserState();
  const reviewState = useReviewState();
  let { uid } = useParams();

  console.log("UserId outside: " + uid);

  useEffect(() => {
    console.log("UserId inside: " + uid);
    if (uid !== null) {
      UsersService.FetchUserInfo(uid);
    }
  }, [userState.updateNeed.value]);

  const user = userState.user.value;
  console.log("user state", userState.user.value);
  console.log("user:--", user)

/**useEffect(() => {
    if (uid) {
      ReviewService.getAverageRating(uid);
    }
}, [])**/
console.log("Review State: ", reviewState);
  return (
    <>
      <Navbar />
      <div className="items-center">
        <div className="w-11/12 md:max-w-6xl md:mx-auto mx-4 mb-20 md:mb-52">
          {user && <ProfileHeader user={user}/>}
          <ul id="tabs" className="flex mt-4 mb-4 justify-center md:justify-start">
            <li
              className={
                currentTab === 1
                  ? `border-b-4 cursor-pointer text-Red font-bold text-xl border-Red mr-4`
                  : `border-b-4 text-xl text-grayLight1 cursor-pointer border-transparent mr-4`
              }
              onClick={() => {
                setCurrentTab(1);
              }}>
              <p>Showcase</p>
            </li>
            {/*<li
              className={
                currentTab === 2
                  ? `border-b-4 cursor-pointer text-Red font-bold text-xl border-Red mr-4`
                  : `border-b-4 text-xl text-grayLight1 cursor-pointer border-transparent mr-4`
              }
              onClick={() => {
                setCurrentTab(2);
              }}>
                
              <p>Recipes</p>
            </li>*/}
            <li
              className={
                currentTab === 3
                  ? `border-b-4 cursor-pointer text-Red font-bold text-xl border-Red mr-4`
                  : `border-b-4 text-xl text-grayLight1 cursor-pointer border-transparent mr-4`
              }
              onClick={() => {
                setCurrentTab(3);
              }}>
                
              <p>Reviews</p>
            </li>
          </ul>
          {currentTab === 1 && (
            <div>
              <ProfileAssets uid={uid} />
            </div>
          )}
          {currentTab === 2 && (
            <div>
              <ProfileRecipes uid={uid} />
            </div>
          )}
          {currentTab === 3 && (
            <div>
              <ProfileReviews uid={uid} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ProfilePage;
