import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useAuth } from '../../Context/AuthContext'
import { baseUrl } from '../../baseUrl';
function StripeConnectSuccess() {

  const { currentUser } = useAuth();

  useEffect(() => {
    const connectToStripeAccount = async () => {
      const url = new URL(window.location.href);
      const urlParams = new URLSearchParams(url.search);
      const code = urlParams.get('code'); // Retrieve the code parameter from the URL
  
      console.log("Code: " + code);
      
      const apiUrl = `${baseUrl}api/payouts/${currentUser.uid}/connect-stripe-account?code=${code}`;
    
      try {
        const response = await fetch(apiUrl, { method: 'GET' });
        const data = await response.json();
  
        // Handle the response data
        console.log(data);
      } catch (error) {
        // Handle the error
        console.error(error);
      }
    };
  
    // Trigger the connectToStripeAccount function
    connectToStripeAccount();
  }, []);
  


  return (
    <div className="bg-white m-4 rounded-lg lg:w-full xs:h-fit overflow-hidden " style={{ height: '85vh' }}>
      <h3 className='flex text-center items-center mx-auto justify-center font-bold text-4xl mt-40'>Congratulations, your Stripe account is successfully connected!</h3>
      <Link to = '/creator/dashboard/cash-out' className='bg-red rounded  font-bold flex w-52 mx-auto mt-20 text-white bold px-10 py-5 items-center justify-center text-xl'>Go to Cash Out</Link>
    </div>
  )
}

export default StripeConnectSuccess