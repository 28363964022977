import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';

//State
const state = createState({
  reviews: [],
  review: [],
  averageRating: 0,
  retrieving: false,
  fetched: false,
  loading: true,
  updateNeed: true
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'REVIEWS_LOADED':
        return s.merge({
          reviews: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true
        });
      case 'USER_REVIEWS_LOADED':
        return s.merge({
          reviews: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true
        });
      case 'BRAND_REVIEWS_LOADED':
        return s.merge({
          reviews: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true
        });
      case 'CREATE_REVIEW':
        return s.merge({
          review: action.data,
          loading: false,
          updateNeed: true
        });
      case 'REVIEW_LOADED':
        return s.merge({
          review: action.data,
          loading: false,
          updateNeed: true
        });
      case 'REVIEW_UPDATE':
        return s.merge({
          review: action.data,
          updateNeed: true,
          loading: false
        });
      case 'AVERAGE_RATING': 
        return s.merge({
          averageRating: action.data,
          loading: false,
          updateNeed: false
        });
      default:
        return null;
    }
  }, action.type);
});

export const accessReviewState = () => state;

export const useReviewState = () => useState(state);

//Service
export const ReviewService = {
  getAverageRating: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/review/getAverageRating/${id}`)
      .then((res) => {
        dispatch(ReviewAction.getAverageRating(res.data));
      })
      .catch((err) => {
        // handle the error
      });
  },
  getBrandReviews: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/review/getBrandReviews/${id}`)
      .then((res) => {
       dispatch(ReviewAction.loadBrandReviews(res.data));
      })
      .catch((err) => {
        // handle the error
      });
  },
  createReview: (data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/review/create`, data)
      .then((res) => {
        dispatch(ReviewAction.createReview(res.data));
      })
      .catch((err) => {
        // handle the error
      });
  },
  getUserReviews: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/review/getUserReviews/${id}`)
      .then((res) => {
        dispatch(ReviewAction.loadUserReviews(res.data));
      })
      .catch((err) => {
        // handle the error
      });
  },
  getAllReviews: () => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/reviews`)
      .then((res) => {
        dispatch(ReviewAction.loadReviews(res.data));
      })
      .catch((err) => {
        // handle the error
      });
  },
  getReviewById: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/review/${id}`)
      .then((res) => {
        dispatch(ReviewAction.loadSingleReview(res.data));
      })
      .catch((err) => {
        // handle the error
      });
  },

  updateReview: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/review/updateReview/${id}`, data)
      .then((res) => {
        dispatch(ReviewAction.updateReview(res.data));
      })
      .catch((err) => {
        // handle the error
      });
  }
}
//Action
export const ReviewAction = {
  loadReviews: (data) => {
    return {
      type: 'REVIEWS_LOADED',
      data: data
    };
  },
  loadUserReviews: (data) => {
    return {
      type: 'USER_REVIEWS_LOADED',
      data: data
    };
  },
  loadBrandReviews: (data) => {
    return {
      type: 'BRAND_REVIEWS_LOADED',
      data: data
    };
  },
  getAverageRating: (data) => {
    return {
      type: 'AVERAGE_RATING',
      data: data
    };
  },
  createReview: (data) => {
    return {
      type: 'CREATE_REVIEW',
      data: data
    };
  },
  loadSingleReview: (data) => {
    return {
      type: 'REVIEW_LOADED',
      data: data
    };
  },
  updateReview: (data) => {
    return {
      type: 'REVIEW_UPDATE',
      data: data
    };
  },

};