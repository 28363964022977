export const validateFormFields = (values, fieldErrors) => {
  let validationErrors = {};

  for (const [fieldName, value] of Object.entries(values)) {
    if (!value) {
      validationErrors = {
        ...validationErrors,
        [fieldName]: fieldErrors[fieldName]
      };
    }
  }
  return validationErrors;
};
