import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';

//State
const state = createState({
  coin: [],
  retrieving: false,
  fetched: false,
  loading: false,
  updateNeed: true
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'COIN_BALANCE':
        return s.merge({
          coin: action.data,
          loading: false,
          updateNeed: true
        });
      case 'ADD_COINS':
        return s.merge({
          coin: action.data,
          loading: false,
          updateNeed: true
        });
      case 'REMOVE_COINS':
        return s.merge({
          coin: action.data,
          loading: false,
          updateNeed: true
        });
      default:
        return null;
    }
  }, action.type);
});

export const accessCoinState = () => state;

export const useCoinState = () => useState(state);

//Service
export const CoinService = {
  FetchBalance: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/coins/${id}`)
      .then((res) => {
        dispatch(CoinAction.loadCoin(res.data.result));
      })
      .catch((err) => {});
  },
  AddCoins: (data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/coins/addCoins`, data)
      .then((res) => {
        dispatch(CoinAction.addCoins(res.data.result));
      })
      .catch((err) => {});
  },
  SpendCoins: (data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/coins/spendCoins`, data)
      .then((res) => {
        dispatch(CoinAction.spendCoins(res.data.result));
      })
      .catch((err) => {});
  }
};

//Action
export const CoinAction = {
  loadCoin: (data) => {
    return {
      type: 'COIN_BALANCE',
      data: data
    };
  },
  addCoins: (data) => {
    return {
      type: 'ADD_COINS',
      data: data
    };
  },
  spendCoins: (data) => {
    return {
      type: 'REMOVE_COINS',
      data: data
    };
  }
};
