import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import SignIn from '../SignIn';
import SignUp from '../SignUp';
import logo from '../../Assets/logo-red.png';
import brand_icon from '../../Assets/brand-login.jpg'
import PasswordReset from '../PasswordReset';
import { useAuth } from '../../Context/AuthContext';
import { Link, useParams } from 'react-router-dom';
import OrganizationSignUp from '../Organization_Dashboard/OrganizationSignUp';
import OrganizationSignIn from './OrganizationSignIn';
function OrganizationLogin() {
  const [currentTab, setCurrentTab] = useState(0);
  const { currentUser } = useAuth();
  const {id} = useParams();
  useEffect(() => {
    if (currentUser) {
    }
  }, [currentUser]);

  return (
    <div className="bg-gray-600 min-h-screen h-max w-screen pb-10">
      <Navbar />
      <div className="grid md:grid-cols-2 xs:grid-row-2 gap-4 xs:grid-row-2 mx-auto lg:mt-32">
        <div className="flex flex-col justify-center align-center">
          <div className='flex justify-left'>
            <h2 className="justify-center rounded-lg text-center ml-16 mb-4 px-4 bg-red font-black text-2xl lg:text-5xl text-white">FOR ORGANIZATIONS</h2>
          </div>
          <img className="mt-10 h-72 rounded-xl mx-auto" src={brand_icon}/>
          <div className="text-white text-center font-black lg:text-5xl mx-10 mt-8 mb-8">Manage several brands under one organization.</div>

        </div>
        <div className="bg-white p-4 rounded-2xl md:w-4/6 xs:w-full place-self-center xs:mt-10">
          <img src={logo} alt="logo" className="mx-auto" />
          <ul id="tabs" className="flex mb-14">
            <li
              className={
                currentTab === 0
                  ? `w-full px-7 font-semibold py-2 border-solid border-b-4 border-Red text-center text-Red`
                  : `w-full px-7 font-semibold py-2 border-solid border-b-4 border-transparent text-center`
              }
              onClick={() => {
                setCurrentTab(0);
              }}>
              <p className="cursor-pointer">Sign Up</p>
            </li>
          </ul>
          <div>
            {currentTab === 0 ? (
              <>
                <OrganizationSignUp />
                <p
                  className="text-black font-bold text-center cursor-pointer"
                  onClick={() => setCurrentTab(0)}>
                  Have an account already? Log in.
                </p>
              </>
            ) : null}
            {currentTab === 1 ? (
              <>
                <PasswordReset />
                <p
                  className="text-black font-bold text-center cursor-pointer"
                  onClick={() => setCurrentTab(1)}>
                  Don't have an account? Create one.
                </p>
                <p
                  className="text-black font-bold text-center cursor-pointer"
                  onClick={() => setCurrentTab(0)}>
                  Have an account already? Log in.
                </p>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrganizationLogin;
