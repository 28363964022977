import React, { useState } from 'react';
import AssetDeployModal from './AssetDeployModal';
import { useEffect, useRef } from 'react';
import AssetImg from '../../Assets/logo-red-ugc.png';
import CustomButton from '../../utils/customButton';


function AssetCard(props) {
  const [deployAsset, setDeployAsset] = useState(false);
  const [updatedLink, setUpdatedLink] = useState('');
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);


  const deployHandle = () => {
    setDeployAsset(true);
  };

    // Format the date and time
  const formatDate = (date) => {
    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    const formattedDate = new Date(date).toLocaleDateString(undefined, options);

    const optionsTime = { hour: 'numeric', minute: 'numeric' };
    const formattedTime = new Date(date).toLocaleTimeString(undefined, optionsTime);

    return `${formattedDate} ${formattedTime}`;
  };

  const formattedDate = formatDate(props.date);

    console.log(props && props.asset && props.asset.source);
    const source = props && props.asset && (typeof props.asset.source === 'object' ? props.asset.source.video : props.asset.source);
    const isMov = typeof source === 'string' && source.endsWith('.MOV');

    const [videoError, setVideoError] = useState(false);

    const handleVideoError = (event) => {
      console.error('Video error:', event.target.error);
      setVideoError(true);
    };
  
    
  return (
<div className="bg-lightGray items-center rounded-xl p-2 flex md:h-15rem 2xl:h-18rem flex-col cursor-pointer" onClick={deployHandle}>
  <div className='w-full h-2/3 '>
    <video  ref={videoRef} preload="metadata"  src={source} className="w-full h-full rounded-t-lg object-cover" controls={false} onError={handleVideoError} />
  </div>

  <div className=" max-h-min rounded-b-lg">
<div className="text-center 2xl:text-base md:text-0.7 font-bold max-h-min break-normal rounded-b-lg">{props.name.split('_')[0]}</div>
    <div className="text-center text-xs">{formattedDate}</div>
    <div className="flex justify-center">
      <CustomButton
        type="submit"
        onClick={deployHandle}
        className="items-center px-3 m-3 border border-transparent shadow-sm text-sm font-medium text-white bg-red  focus:outline-none focus:ring-2 focus:ring-offset-2">
        VIEW
      </CustomButton>
    </div>
  </div>
  {deployAsset ? (
    <AssetDeployModal
      open={deployAsset}
      name={props.name}
      task = {props.task}
      asset={props.asset}
      thumbnail={props.thumbnail}
      onClose={() => setDeployAsset(false)}
      id={props.id}
    />
  ) : null}
</div>
  );
}

export default AssetCard;
