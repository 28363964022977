import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { baseUrl } from '../../../../baseUrl';
import GlobalDialog from '../../../../utils/globalDialog';
import { useParams } from 'react-router-dom';
import { CampaignService, useCampaignState } from '../../../../Service/CampaignServices';
function PostAnalyticsChart(props) {
  const [analyticsData, setAnalyticsData] = useState([]);
  const campaignState = useCampaignState();
  const {id} = useParams();
  return (
    <GlobalDialog open={props.open} onClose={props.close} className="w-full mx-auto h-fit md:h-40 xl:h-40">
    <div className='overflow-auto'>
      <h1 className='flex items-center justify-center mb-4'>Post Comments</h1>
      {props.post.comments.map((comment, index) => {
        const date = new Date(comment.timestamp);
        return (
          <div key={index} className="bg-white w-full border-2 shadow-md p-4 my-4 mx-auto rounded-md">
            <p className="font-bold text-2xl">{comment.text}</p>
            <p className="text-gray-500 text-base text-right"> {date.toLocaleString()}</p>
          </div>
        );
      })}
    </div>
  </GlobalDialog>
  );
};

export default PostAnalyticsChart;