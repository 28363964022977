import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { baseUrl } from '../../../../baseUrl';

function CampaignAnalyticsChart(props) {
  const [analyticsData, setAnalyticsData] = useState([]);

  useEffect(() => {
    fetch(`${baseUrl}api/campaigns/influencer/${props.id}/analytics`)
    .then(response => response.json())
    .then(data => {
      if (Array.isArray(data.result.historicalAnalytics)) {
        // Initialize chart data object
        const chartData = {};
  
        // Iterate over each analytics object
        data.result.historicalAnalytics.forEach(analytics => {
          const updatedTime = new Date(analytics.value.updated).getTime();
          const properties = Object.keys(analytics.value).filter(property => property !== 'updated');
  
          // Iterate over each property in the analytics object
          properties.forEach(property => {
            // Initialize chart data array for each property
            if (!chartData[property]) {
              chartData[property] = {
                name: property.replace(/([A-Z])/g, ' $1').toLowerCase(),
                data: []
              };
            }
  
            // Add data point to the chart data array
            const value = property === 'totalClicks' ? analytics.value[property] || 0 : analytics.value[property];
            chartData[property].data.push({ x: updatedTime, y: value });
          });
        });
  
        // Update the state with the chart data
        setAnalyticsData(Object.values(chartData));
      } else {
        console.error('Analytics data is not an array:', data.result.analytics);
      }
    })
    .catch(error => {
      console.error('Error fetching analytics data:', error);
    });
  
  
  }, [props.id]);
  const chartOptions = {
    type: 'area',

    series: analyticsData.map(series => ({
      ...series,
      tooltip: {
        enabled: true,
        formatter: function (val) {
          return val[0].seriesName + ": " + val[0].dataPoint.y.toFixed(2);
        }
      }
    })),
    dataLabels: {
      enabled: false,
    },

    xaxis: {
      type: 'datetime',
      tickPlacement: 'off'
    },
    yaxis: {
      title: {
        text: 'Analytics'
      },
      labels: {
        formatter: function (val) {
          if (val !== undefined) {
            return val.toLocaleString(); // Format the number with commas
          }
          return val;
        }
      }
    },
    stroke: {
      curve: 'smooth'
    },  
    markers: {
      size: 6,
      hover: {
        sizeOffset: 3
      }
    }
  };
  const colors = [
    '#FFC300', '#008080', '#FF33FF', '#3399FF', '#33FF99', '#FF6633', // new colors
    '#800000', '#008080', '#808000', '#800080', '#008000', '#000080'  // new colors
  ];

  
return (
  <div className='ml-10 grid grid-cols-3 gap-4 '>
    {analyticsData.map((series, index) => {
      const options = {
        ...chartOptions,
        series: [{
          ...series,
          tooltip: {
            enabled: true,
            formatter: function (val) {
              return val[0].seriesName + ": " + val[0].dataPoint.y.toFixed(2);
            }
          }
        }],
        colors: [colors[index % colors.length]],
        title: {
          text: series.name.toLowerCase().replace(/\b\w/g, l => l.toUpperCase()),
          align: 'center'
        },
        markers: {
          size: 0
        }
      };

      return (
        <div key={index} className="p-3 border shadow-md"> {/* Add your tile styling here */}
          <ReactApexChart
            type="area"
            options={options}
            series={options.series}
            width="95%"
            height="150"
          />
        </div>
      );
    })}
  </div>
);
}

export default CampaignAnalyticsChart;