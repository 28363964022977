import React from 'react';

function EndPurchasing() {
  return (
    <div className="md:w-1/3 h-auto md:p-5 pt-12  rounded-lg mx-auto  md:mt-12 bg-white">
      <h1 className="w-full text-center font-bold md:text-3xl xs:text-lg">
        Thank you for purchasing
      </h1>

      <div className="flex w-full my-5">
        <div className=" w-full my-5 md:text-xl xs:text-base text-center">
          <h1>We are processing it now, You will receive an email confirmation shortly</h1>

          <div className="bg-red  w-80  py-2 m-2 mx-auto font-bold rounded-lg text-white mt-4 items-center justify-center text-center cursor-pointer">
            <button className={'w-full'}>Go to The Main Page</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EndPurchasing;
