import React from 'react';
import { BsExclamationCircle } from 'react-icons/bs';
import { UsersService } from '../../../Service/userServices';
import CustomButton from '../../../utils/customButton';

function DeleteUser(props) {
  const handleDeleteUser = () => {
    UsersService.DeleteUser(props.userID);
    props.handleComplete();
  };
  return (
    <div className=" bg-white rounded-lg dark:bg-gray-700">
      <div className="p-6 pt-0 text-center">
        <BsExclamationCircle className="mx-auto mb-4 w-32 h-14 text-gray-400 dark:text-gray-200" />
        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
          Are you sure you want to delete this User?
        </h3>
        <CustomButton
          type="button"
          className="text-white  hover:bg-red-800  font-medium text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
          onClick={() => handleDeleteUser()}>
          Yes, I'm sure
        </CustomButton>
        <button
          type="button"
          className="text-gray-500 bg-white hover:bg-gray-100  rounded-md border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600"
          onClick={() => props.handleComplete()}>
          No, cancel
        </button>
      </div>
    </div>
  );
}

export default DeleteUser;
