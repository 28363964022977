import React, {useEffect, useState} from 'react'
import Loader from '../Loader2';
import ProfileReviewCard from './ProfileReviewCard';
import { ReviewService, useReviewState } from '../../Service/ReviewService';
import TrialProfileReviewCard from './TrialProfileReviewCard';
function TrialProfileReviews(props) {
  const reviewState = useReviewState();
  useEffect(() => {
    if (reviewState) {
      ReviewService.getUserReviews(props.uid);
    }
  }, [reviewState.updateNeed.value]);

  const res = reviewState.reviews.value;
  const reviews = res
  console.log("res", res)
  return (
    <div className="bg-white rounded-lg h-full w-full z-100">
    {reviewState.loading.value && <Loader />}
    {!reviewState.reviews.value && (
      <h1 className="mx-auto flex justify-center items-center text-red text-2xl">No reviews found</h1>
    )}
    <div className="flex flex-col overflow-auto h-20rem">
    {reviews && Object.entries(reviews).map(([id, review]) => {
        console.log("REVIEW", review);
        return (
          <div key={id}>
            <TrialProfileReviewCard review={review}/>
          </div>
        );
      })}
    </div>
  </div>
  )
}

export default TrialProfileReviews