import React, { useState, useEffect } from 'react';
import UploadModal from './UploadModal';
import { useAssetState, AssetService } from '../../Service/AssetService';
import Loader2 from '../Loader2';
import AssetCard from './AssetCard';
import { useAuth } from '../../Context/AuthContext';

function Products() {
  const [upload, setUpload] = useState(false);
  const { currentUser } = useAuth();
  const handleUpload = () => {
    setUpload(true);
  };
  const assetState = useAssetState();
  useEffect(() => {
    if (assetState.updateNeed.value) {
      assetState.merge({ loading: true });
      AssetService.FetchAssetsForBrand(currentUser.uid);
    }
  }, [assetState.updateNeed.value]);
  const Assetdata = Object.entries(assetState.assets.value).map((e) => ({
    [e[0]]: e[1]
  }));

  return (
    <div className="bg-white m-3 rounded-lg w-full z-100">
      <div className="flex items-center justify-between">
        <h3 className="font-semibold p-3 text-2xl">My Assets</h3>
      </div>
      <div className="w-full  md:h-45 z-100">
        {assetState.loading.value ? (
          <Loader2 />
        ) : (
          <div className="grid xl:grid-cols-5 grid-cols-4 overflow-x-hidden h-21 md:grid-cols-2 gap-2 mx-2 w-full">
            {Assetdata.length > 0 &&
              Assetdata.map((item, i) => {
                const data = item[Object.keys(item)];
                const id = Object.keys(item);
                return (
                  <AssetCard
                    key={i}
                    asset={data}
                    name={data.creator_name}
                    id={id}
                    thumbnail={data.source}

                  />
                );
              })}
          </div>
        )}
        <button
          className="bg-red flex w-auto text-white px-4 mt-5 py-2 ml-4px rounded-lg mr-5"
          onClick={() => handleUpload()}>
          <span className="text-white text-2xl">+</span> Upload Asset
        </button>

        <div className="flex items-center justify-between">
          <h3 className="font-semibold pb-4 pt-2 ml-8 text-2xl">My Clips</h3>
        </div>
        <div className="grid lg:grid-cols-5 overflow-x-hidden z-0 h-21 md:grid-cols-2 gap-2 mx-2 w-full">
          {Assetdata.length > 0 &&
            Assetdata.map((item, i) => {
              const data = item[Object.keys(item)];
              const id = Object.keys(item);
              return (
                <AssetCard
                key={i}
                asset={data}
                name={data.creator_name}
                id={id}
                thumbnail={data.source}
                />
              );
            })}
        </div>
      </div>

      {upload ? (
        <UploadModal
          open={upload}
          onClose={() => {
            setUpload(false);
          }}
        />
      ) : null}
    </div>
  );
}

export default Products;
