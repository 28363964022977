import React, { useContext, useEffect } from 'react';
import { CampaignState } from '../../../utils/campaignState';
import { useTabState } from '../../../utils/TabState';
import { AuthService, userAuthstate } from '../../../Service/authenticationService';
import { useAuth } from '../../../Context/AuthContext';
import { useHistory } from 'react-router-dom';
import { useBrandState, BrandService } from '../../../Service/BrandServices';
import { Link } from 'react-router-dom';
import { BrandContext } from '../../../routes/PrivateRoute/BrandDashboardRoute';
import CustomButton from '../../../utils/customButton';

function CampaignCard(props) {
  const campaignState = CampaignState();
  const { currentBrand } = useAuth();
  const tabState = useTabState();
  const authState = userAuthstate();
  const brandState = useBrandState();
  const {brandId} = useContext(BrandContext);
  const history = useHistory();

  useEffect(() => {
    if (brandId) {
      BrandService.FetchSingleBrand(brandId);
    }
    
  }, [brandState.updateNeed.value, brandId]);

  const brand = brandState.brand.value;

  let totalApprovedPropNum = 0;
  let totalPropNum = 0;
  let totalVid = 0;

  const viewCampaign = (campaign) => {
    console.log(campaign)
    tabState.merge({ currentCampTab: 2 });
    history.push(`/brand/dashboard/campaigns/${campaign.campaignId}`);
  };





  const resTasks = props.campaign.tasks
    ? Object.entries(props.campaign.tasks).map((e) => {
      return {
        [e[0]]: e[1]
      };
    })
    : [];
  resTasks
    .slice(0)
    .reverse()
    .map((item, i) => {
      const res = item[Object.keys(item)];
      let approvedPropNum = res.accepted_proposals ? Object.keys(res.accepted_proposals).length : 0;
      totalApprovedPropNum = totalApprovedPropNum + approvedPropNum;
      let propNum = res.proposals ? Object.keys(res.proposals).length : 0;
      totalPropNum = totalPropNum + propNum;
      const id = Object.keys(item);
      const resProposals = res.proposals
        ? Object.entries(res.proposals).map((e) => {
          return {
            [e[0]]: e[1]
          };
        })
        : [];

      resProposals
        .slice(0)
        .reverse()
        .map((item, i) => {
          const res = item[Object.keys(item)];
          let propVid = res.proposal_details ? Object.keys(res.proposal_details).length : 0;
          totalVid = totalVid + propVid;
        });
    });

  const brand_photo = 'https://crafted-react-images.s3.amazonaws.com/apex.jpg';
  return (
    <Link to={`/brand/dashboard/campaigns/${props.campaign.campaignId}`} className="card-link">
    <div className="bg-lightGray items-center rounded-xl p-1 flex flex-col cursor-pointer">
    <div className="relative">
        <img
          src={props.campaign.campaign_photo}
          className="rounded-lg w-full 2xl:h-12rem md:h-8rem object-over"
          alt="img"
        />
        {props.campaign.status === "completed" && (
          <div className="absolute inset-0 bg-black opacity-75 rounded-lg flex items-center justify-center">
            <span className="text-white bg-red px-2 py-1 rounded-full text-sm opacity-100">
              Completed
            </span>
          </div>
        )}
        <div className="text-lg my-2 mb-1 text-red font-extrabold text-center">
          {/*props.campaign.campaign_credits && props.campaign.campaign_credits + ' credits'*/}
        </div>
        <div className="text-base mb-1 font-bold text-center"></div>
      </div>
      <p className="text-red w-full font-extrabold text-center">
        {/*{totalVid} / {totalApprovedPropNum}/ {totalPropNum} proposals*/}
      </p>
      <div className="2xl:text-base md:text-0.7 text-center mb-1 font-bold truncate w-36">{props.campaign.campaign_name}</div>
      <div className="items-center justify-center text-center">
        <>
          <CustomButton
            className=" text-white px-2
            outline-none text-xs"
          >
            View Campaign
          </CustomButton>
        </>
      </div>
    </div>
  </Link>
  );
}

export default CampaignCard;
