import React, {useState, useEffect} from 'react';
import { useTabState } from '../../utils/TabState';
import 'react-responsive-modal/styles.css';
import UgcTab from './ugcTab';
import { useParams } from 'react-router-dom';
import { TaskService, useTaskState } from '../../Service/TaskServices';
import { UsersService, useUserState } from '../../Service/userServices';
import UgcSendProduct from './UgcSendProductModal';
import UgcUndoFulfillment from './UgcUndoFulfillmentModal';
import UgcSendProductModal from './UgcSendProductModal';
import CustomButton from '../../utils/customButton';
function UgcFulfillment() {
  const {id} = useParams();
  const taskState = useTaskState();
  const userState = useUserState();
  const [open, setOpen] = useState(false);
  const [proposal, setProposal] = useState({});
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [proposalId, setProposalId] = useState();
  const [fulfillment, setFulfillment] = useState();
  const [brandId, setBrandId] = useState({}); 
  const [creatorId, setCreatorId] = useState({});
  const fetchData = async () => {
    if (id) {
      setLoading(true);
      TaskService.FetchSingleTask(id);
      setLoading(false);
    }
  }
  
    useEffect(() => {
      fetchData();
    }, [taskState.updateNeed.value]); // Make sure 'id' is in the dependency array // Make sur

  const task = taskState.tasks.value;
console.log("task.accepted_proposals: ", task.accepted_proposals)
  const handleSendProduct = (proposal, creator_id, task_id, brand_id, brand_name, product_name, proposalId) => {
    let selected_proposal = {
      taskId: task_id,
      creatorId: creator_id,
      brandId: brand_id,
      brand_name: brand_name,
      product_name: product_name,
      proposal_id: proposalId,
      proposal: proposal
    };

    setCreatorId(creator_id);
    setProposalId(proposalId);
    setBrandId(brand_id);
    setProposal(selected_proposal);
    setOpen(true);
  }

  const handleUndoFulfillment = (proposal, creator_id, task_id, brand_id, brand_name, proposalId, fulfillment) => {
    let selected_proposal = {
      taskId: task_id,
      creatorId: creator_id,
      brandId: brand_id,
      brand_name: brand_name,
      proposal_id:proposalId,
      proposal: proposal,
    }
    setCreatorId(creator_id);
    setBrandId(brand_id);
    setProposal(proposal);
    setProposalId(selected_proposal);
    setFulfillment(fulfillment);
    setModal(true);
  }

  return (
    <div className="bg-white w-full p-4 rounded-lg z-100 overflow-y-scroll h-full" >
    {task.accepted_proposals && Object.keys(task.accepted_proposals).length > 0 ? (
      <>
        <div className='mb-10 shadow-md rounded-md'>          
            <div className="w-full shadow-md rounded-md">
              <h1 className="md:text-3xl  bg-gray-700 w-full py-3 text-white rounded font-bold">
              <p className='ml-5 text-base'>
                {
                  task &&
                  `${task.note2} (Fulfillment completed: ${
                    Object.values(task.accepted_proposals).filter(proposal => proposal.fulfillment).length
                  } / ${Object.entries(task.accepted_proposals).length})`
                }
              </p>
              </h1>
            

            <table className=" 2xl:text-3xl md:text-0.7 font-thin rounded-lg h-full table-auto">
              <thead className="w-full gap-x-8">
                  <tr className={`h-auto mx-2 bg-lightGray border-none `}>
                <th className=" px-6 py-3 text-left leading-4 md:w-1/6 lg:w-1/6 tracking-wider">
                      Product
                    </th>
                    <th className=" px-6 py-3 text-left text-sm leading-4 md:w-1/6 lg:w-1/6 tracking-wider ">
                      Ship to Name
                    </th>
                    <th className=" px-6 py-3 text-left text-sm leading-4 md:w-1/6 lg:w-1/6 tracking-wider">
                      Address 1
                    </th>
                    <th className=" px-6 py-3 text-left text-sm leading-4 md:w-1/6 lg:w-1/6 tracking-wider">
                      Address 2
                    </th>
                    <th className=" px-6 py-3 text-left text-sm leading-4 md:w-1/6 lg:w-1/6 tracking-wider">
                      City
                    </th>
                    <th className=" px-6 py-3 text-left text-sm leading-4 md:w-1/6 lg:w-1/6 tracking-wider">
                      State
                    </th>
                    <th className=" px-6 py-3 text-left text-sm leading-4 md:w-1/6 lg:w-1/6 tracking-wider">
                      Zipcode
                    </th>
                    <th
                      className=" px-6 py-3 text-left text-sm leading-4 md:w-1/6 lg:w-1/6 tracking-wider">
                      Send Product
                    </th>
                  </tr>
                </thead>                       
            {Object.entries(task.accepted_proposals)
            .map(([proposalId, proposal], i) => {
                const brand_id = task.brand_id;
                const hasFulfillment = proposal.fulfillment;
                console.log("brand_name : ", proposal.proposal)
                
                const products = proposal && proposal.products;
                const proposal_id = proposalId; 

                // You need to make sure you're accessing the product name for each product.
                const productElements = products ? Object.entries(products).map(([productKey, productDetails]) => {
                  const productName = productDetails.name.replace(/^.*-/, '');
                console.log("proposal:")
                  return (
                    <div key={productKey} className="items-center">
                      {productName}
                    </div>
                  );
                }) : null;
                return (
                <tbody className="bg-white">
                        <tr
                         
                          className={`h-auto mx-2 bg-white border-b-2 border-gray-200`}
                        >
                          <td className="px-6 py-4 whitespace-no-wrap">
                            <div className="items-center">{productElements}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap">
                            <div className="flex items-center">
                              <div className="text-sm leading-5 text-gray-800">
                                {proposal.creator_address &&
                                  proposal.creator_address.fullname}
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap">
                            <div className="flex items-center">
                              <div className="text-sm leading-5 text-gray-800">
                                {proposal.creator_address &&
                                  proposal.creator_address.address1}
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap">
                            <div className="flex items-center">
                              <div className="text-sm leading-5 text-gray-800">
                                {proposal.creator_address &&
                                  proposal.creator_address.address2}
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap">
                            <div className="flex items-center">
                              <div className="text-sm leading-5 text-gray-800">
                                {proposal.creator_address &&
                                  proposal.creator_address.city}
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap">
                            <div className="flex items-center">
                              <div className="text-sm leading-5 text-gray-800">
                                {proposal.creator_address &&
                                  proposal.creator_address.state}
                              </div>
                            </div>
                          </td>
                          <td className=" px-8 py-4 whitespace-no-wrap">
                            <div className="flex items-center">
                              <div className="text-sm leading-5 text-gray-800">
                                {proposal.creator_address &&
                                  proposal.creator_address.zipcode}
                              </div>
                            </div>
                          </td>
                          <td className=" px-6 py-4 whitespace-no-wrap">
                            <div className="flex items-center">
                            {hasFulfillment ? 
                              (
                                <CustomButton
                                onClick={() =>
                                  handleUndoFulfillment(
                                    proposal,
                                    proposal.creator_id,
                                    task.id,
                                    brand_id, 
                                    proposal.brand_name,
                                    proposal_id,
                                    proposal.fulfillment
                                  )
                                }
                                className="px-5 py-2 text-0.7 text-white focus:outline-none">
                                  Undo Fulfillment ({proposal.fulfillment.type})
                                </CustomButton>
                              ) : (
                              <CustomButton
                                className="px-5 py-2  text-white focus:outline-none"
                                onClick={() =>
                                  handleSendProduct(
                                    proposal,
                                    proposal.creator_id,
                                    task.id,
                                    brand_id, 
                                    proposal.brand_name,
                                    productElements,
                                    // product_id,
                                    proposal_id
                                  )
                                }
                              >
                                Send Product
                              </CustomButton>)
                                  }
                            </div>
                          </td>
                                  
                        </tr>
                    
                </tbody>
                )})
            }

          </table>  
          
          </div>
        </div>
        
          
      </>
      ) : (
        <div className="flex justify-center font-bold h-full mt-24">
          <p>No fulfillments available</p>
        </div>
      )} 
      {open ?
  <UgcSendProductModal
    open={open}
    proposal={proposal}
    creator_id={proposal.creator_id}
    task_id={id}
    brand_id={brandId}
    task={task}
    id={id}
    fetchData={fetchData}
    onClose={() => setOpen(false)}
  />
  : null }   
    {modal ? (
     <div className="fixed top-0 left-0 right-0 bottom-0 flex  break-words justify-center h-full w-full items-center z-50 bg-black bg-opacity-10">
    <UgcUndoFulfillment
      proposal={proposal}
      creator_id={proposal.creator_id}
      proposalId={proposalId}
      task_id={id}
      fulfillment={fulfillment}
      brand_id={task.brand_id}
      task={task}
      id={id}
      fetchData={fetchData}
      close = {() => setModal(false)}
    />
      </div>
    ): null}
    </div>
  )
}

export default UgcFulfillment