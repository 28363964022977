import React, { useEffect } from 'react';
import avatar from './../Assets/profilePicture.png';
import Navbar from './Navbar';
import { RecipeService, useRecipeState } from '../Service/RecipeServices';
import { useUserState, UsersService } from '../Service/userServices';
import Loader from './Loader2';

function RecipeDetails(props) {
  const recipeId = props.match.params.id;
  const recipeState = useRecipeState();
  const userState = useUserState();
  let user;
  let recip;
  let userId;

  useEffect(() => {
    recipeState.merge({ loading: true });
    RecipeService.FetchSingleRecipe(recipeId);
  }, []);

  if (recipeState.recipe.value) {
    recip = recipeState.recipe.value;
    userId = recip.creator;
  }

  useEffect(() => {
    if (recip) {
      UsersService.FetchBasicInfo(recip.creator);
    }
  }, [userId]);

  user = userState.user.value;

  let tags = {};
  let steps = {};
  let ingredient = {};
  if (recip) {
    tags = recip.metatags;
    steps = recip.steps;
    ingredient = recip.ingredients;
  }

  return (
    <div>
      <Navbar />
      <div className="flex items-center w-full md:w-11/12 md:max-w-6xl md:mx-auto mx-0 mb-20 md:mb-52">
        {recipeState.loading.value && <Loader />}
        {!recipeState.loading.value && recip && (
          <div id="main" className="w-full mx-6 md:mx-0">
            <div className="mb-6 bg-gray-100 px-5 py-4 rounded-2xl lg:flex hidden " id="top-banner">
              <img src={recip.thumbnail} alt={recip.name} className="w-80 h-80 object-cover" />
              <div className="ml-9">
                <div>
                  <p className="font-rubik font-bold text-2xl text-black mb-4">{recip.name}</p>
                </div>
                <div className="flex flex-col items-start">
                  {tags !== undefined ? (
                    <div className="flex mr-5  mb-4 " id="meta-tags">
                      {Object.values(tags).map((tag, i) => {
                        return (
                          <div
                            className="bg-lightBlue text-xs font-bold text-white rounded-xl py-1 px-3 mr-1"
                            key={i}>
                            {tag}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                  <div className="flex rounded-xl  mt-2 bg-white bg-opacity-10">
                    <img
                      src={user ? user.avatar : avatar}
                      className="w-20 h-20 mr-8 rounded-full object-cover"
                      alt="avatar"
                    />
                    <div className="flex items-center">
                      <div className="mr-16">
                        <div className="text-2xl font-medium mb-2 font-poppins text-black">
                          {user ? user.name : ''}
                        </div>
                        <div className="text-xl font-poppins text-black">
                          @ {user ? user.username : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-xs my-5 font-bold mb-3">{recip.description}</div>
              </div>
            </div>
            <div className="mb-6 rounded-2xl flex lg:hidden" id="top-banner">
              <div className="w-full bg-lightGray items-center rounded-xl p-3 flex flex-col">
                <img
                  src={recip.thumbnail}
                  className="w-full md:w-64 h-auto md:h-64 mb-3 rounded-2xl object-cover"
                  alt={recip.name}
                />
                <div className="text-xl mb-1 font-bold"> {recip.name}</div>
                <div className="text-xs font-bold mb-3">{recip.description}</div>
                <div className="flex">
                  {tags !== undefined ? (
                    <div className="flex mr-5 " id="meta-tags">
                      {Object.values(tags).map((tag, i) => {
                        return (
                          <div
                            className="bg-lightBlue text-xs font-bold text-white rounded-xl py-1 px-3 mr-1"
                            key={i}>
                            {tag}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row">
              <div id="ingredient" className="bg-lightGray p-7 rounded-2xl">
                <div className="text-2xl text-center font-bold font-rubik">Ingredients</div>
                <hr className="w-full my-6 border-t-2 border-divider" />
                {ingredient !== undefined
                  ? Object.values(ingredient).map((ing, i) => {
                      return (
                        <li className="mb-3 font-rubik text-xl" key={i}>
                          {ing.measurement} {ing.unit} {ing.name}
                        </li>
                      );
                    })
                  : null}
              </div>
              <div id="directions" className="flex-1 py-7 pl-7 rounded-2xl">
                <div className="text-2xl text-center font-bold font-rubik">Directions</div>
                <hr className="w-full my-6 border-t-2 border-divider" />
                {steps !== undefined
                  ? Object.values(steps).map((d, i) => {
                      return (
                        <div className="mb-3 font-rubik text-xl" key={i}>
                          {i + 1}. {d.step}
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        )}
        {!recipeState.loading.value && !recip && (
          <h1 className="mx-auto text-red text-2xl">Oops! Recipe not found.</h1>
        )}
      </div>
    </div>
  );
}

export default RecipeDetails;
