import React, { useState, useEffect } from 'react';
import GlobalDialog from '../../utils/globalDialog';
import ConfirmModalTask from './SendTaskProposal';
import moment from 'moment';
import { UsersService, useUserState } from '../../Service/userServices';
import { BsChevronCompactDown, BsChevronCompactUp } from 'react-icons/bs';
import Collapsible from 'react-collapsible';
import { useAuth } from '../../Context/AuthContext';
import {Link } from 'react-router-dom';
import CustomButton from '../../utils/customButton';
import BriefTemplateInfo from '../Brand_Dashboard/GenerativeBriefs/BriefTemplateInfo';
import BriefTemplateView from './BriefTemplateView';

function ProposalModal({ open, onClose, id, task }) {
  const [tab, setTab] = useState(0);
  const [briefView, setBriefView] = useState(false);
  const userState = useUserState();
  const timezone = moment.tz.guess();
  const {currentUser} = useAuth();
  var task_deadline_time = moment(task.deadline).tz(timezone).format('MMM. D, YYYY');
  const [confirm, setConfirm] = useState(false);
  const [url, setUrl] = useState('');
  const proposalHandle = () => {
    tab ? setTab(2) : setTab(1);
    confirm ? setConfirm(false) : setConfirm(true);
  };

  const StringChecker = (text) => {
    let formattedText = text;
    if (formattedText[0].length > 50) {
      formattedText[0] = formattedText[0].substring(0, 50) + '...';
    }
    return (formattedText[0]);
  };
  
  const viewBriefHandle = () => {
    briefView ? setBriefView(false) : setBriefView(true);
  }


  useEffect(() => {
    if (currentUser.uid !== null) {
      UsersService.FetchUserInfo(currentUser.uid);
    }
  }, []);
  const userInfoData = userState.user.value;
  console.log('userInfoData:', userInfoData);

  function extractBeforeAndAfterURL(str) {
    const regex = /(.*?)(https?:\/\/[^\s]+)/;
    const match = str.match(regex);

    if (match && match.length >= 3) {
      const beforeURL = match[1];
      const afterURL = str.substring(match[1].length + match[2].length);
      return { beforeURL, afterURL };
    }

    return { beforeURL: str, afterURL: '' };
  }
  const covertingString = () => {
    const regex = /(https?|ftp):\/\/[^\s/$.?#].[^\s]*/i;
    if (task && task.note3) {
      if (regex.test(task.note3)) {
        let extractedUrl = task.note3.match(/\bhttps?:\/\/\S+/gi);
        let visualLink = StringChecker(extractedUrl);
        const { beforeURL, afterURL } = extractBeforeAndAfterURL(task.note3);
        return (
          <div className='break-words max-w-full'>
            {beforeURL}
            <p className="max-w-full text-blue-500" >
              <a className='text-blue-500 hover:underline cursor-pointer' href={task.note3.match(/\bhttps?:\/\/\S+/gi)[0]} target="_blank" style={{ color: 'blue' }}>{visualLink}</a>
            </p>
            {afterURL}
          </div>
        )
      }
      else {
        return (
          <div>
            {task.note3}
          </div>
        )
      }
    }
  }
  const whitelistAddOn = task && task.add_ons && task.add_ons.find(addOn => addOn.name === 'Whitelist');

  const tiktokAccountMissing = whitelistAddOn && whitelistAddOn.platform.includes('tiktok') && (!userInfoData || !userInfoData.creator_socials || !userInfoData.creator_socials.tiktok || !userInfoData.creator_socials.tiktok.access_token || !userInfoData.creator_socials.tiktok.performance || !userInfoData.creator_socials.tiktok.performance.suggestedRate);
  const instagramAccountMissing = whitelistAddOn && whitelistAddOn.platform.includes('instagram') && (!userInfoData || !userInfoData.creator_socials || !userInfoData.creator_socials.instagram || !userInfoData.creator_socials.instagram.access_token || !userInfoData.creator_socials.instagram.suggested_rate);
  const bothAccountsMissing = tiktokAccountMissing && instagramAccountMissing;

  return (
    <div className=''>
      {tab === 0 ? (
        <div>
          <GlobalDialog className='md:p-2 w-2/3' open={open} onClose={onClose}>
            <Collapsible
              trigger={[
                'Brief Details',
                <div className="px-3 absolute right-0">
                  <BsChevronCompactDown />
                </div>
              ]}
              triggerWhenOpen={[
                'Brief Details',
                <div className="px-3 absolute right-0">
                  <BsChevronCompactUp />
                </div>
              ]}
              contentOuterClassName="mb-6"
              triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
              triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
            </Collapsible>
            <div className="m-1 bg-white rounded-lg text-3xl dark:bg-gray-700 md:p-5">
              <div className="flex ml-8 xs:ml-4 mb-5">
                <div className="flex mr-5 font-bold">Brand:</div>
                <a className="text-blue underline" target="_blank" href={task && task.brand_website} rel="noreferrer" >{task && task.brand_name}</a>
              </div>

              {/*<div className="flex ml-8 xs:ml-4 mb-5">
                <div className="flex mr-5 font-bold">Deadline:</div>
                <div>{task && task_deadline_time}</div>
              </div>*/}
          <div className="flex ml-8 xs:text-0.7 xs:ml-4 mb-5">
                <div className="flex flex-col">
                    <div className="font-bold">Brief:</div>
                    <div className='flex-wrap'>
                      <div className="flex flex-col space-y-2 md:text-base xs:text-0.7 sm:text-xs">
                        {task && task.recipe_videos > 0 && (
                          <div>
                            Create {task.recipe_videos} short form recipe videos showcasing the brand as organically (not ad-style or salesy) as possible.
                          </div>
                        )}
                        {task && task.product_showcase > 0 && (
                          <div>
                            Create a short form product demo video showcasing the brand as organically (not ad-style or salesy) as possible.
                          </div>
                        )}
                        <div>
                          Crafted and the brand will own these videos and will post them on their social accounts, use them for paid social, website, etc. in perpetuity.
                        </div>
                      </div>
              </div>
              </div>
              </div>
              <div className="ml-8 xs:ml-4 mb-5 xs:text-0.7">
                <div>
                  <div className="mt-2 font-bold">
                    {task && task.brand_name} is prioritizing these products/flavors:
                  </div>
                  <div className="">
                    {task && task.note1}
                  </div>
                </div>
                <div>
                  <div className="mt-2 font-bold">
                    {task && task.brand_name}'s Target Audience:
                  </div>
                  <div className="">
                    {task && task.note2}
                  </div>
                </div>
                <div>
                  <div className="mt-2 font-bold">
                    Key talking points for creators:
                  </div>
                  
                  <div className="text-justify">
                    {covertingString()}
                  </div>
                </div>
                <div>
               
                {task && task.video_requirements && 
                        <> 
                          <div className="mt-2 font-bold">
                            Original Video Requirements:
                          </div>
                            <div className=' flex w-full 2xl:text-base md:text-0.7 xs:text-0.7 flex-wrap'>
                              - 1 version with &nbsp; {task.video_requirements.voiceover ? <div>Voiceover (English){task.video_requirements.music || task.video_requirements.onScreenText ? ', ' : ''}</div> : <div> No Voiceover{task.video_requirements.music || task.video_requirements.onScreenText ? ', ' : ''}</div>}
                              {task.video_requirements.music ? <div>&nbsp;Background Music{task.video_requirements.onScreenText ? ', ' : ''}</div>: <div> &nbsp;No Background Music{task.video_requirements.onScreenText ? ', ' : ''}</div>}
                              {task.video_requirements.onScreenText ? <div>&nbsp;On Screen Text{task.video_requirements.subtitles ? ', ' : ''}</div> : <div> &nbsp;No On Screen Text{task.video_requirements.subtitles ? ', ' : ''}</div>}
                              {task.video_requirements.subtitles ? <div>Subtitles</div>: <div>, &nbsp;No Subtitles</div>}
        
                            </div>
                        </>
                        }
                </div>
                {task && task.regions && (
                  <div className=''>
                    <div className="mt-2 font-bold">Regions:</div>
                    {Array.isArray(task.regions) && task.regions.length > 0 ? (
                      task.regions.map((region, index) => (
                        <span key={index}>{region.value || region}{index !== task.regions.length - 1 && ', '}</span>
                      ))
                    ) : (
                      Object.values(task.regions).map((region, index, arr) => (
                        <span key={index}>{region.value}{index !== arr.length - 1 && ', '}</span>
                      ))
                    )}
                  </div>
                )}
               {task && task.variations && (
                    <div>
                      <div className="mt-2 font-bold">Deliverables ({task.variations.length + 1} versions {task && task.add_ons && task.add_ons.some((addOn) => addOn.name == "Raw Footage") && '+ raw footage'}):</div>
                      <p className='text-base font-bold italic'>You will be responsible for submitting {task.variations.length + 1} separate files that adhere to the following specs. Each version will be the same video content, just with a different specification (ie. same video but with no voiceover or music, same video but without subtitles). You will be compensated $50 for each additional version you are asked to submit. (ie. ${((task.variations.length)*50)} for {task.variations.length} additional version on top of the original video) </p>
                      <ul className="flex flex-col mt-2">
                      {task && task.add_ons && task.add_ons.map((el, i) => (
                          <span className='2xl:text-base md:text-0.7' key={i}>-{i !== 0 } {el.name}</span>
                        ))}
                        {task && task.video_requirements && 
                        <>
                          <div className=' flex w-full 2xl:text-base md:text-0.7'>
                            - 1 version with &nbsp; {task.video_requirements.voiceover ? <div>Voiceover (English){task.video_requirements.music || task.video_requirements.onScreenText ? ', ' : ''}</div> : <div> No Voiceover{task.video_requirements.music || task.video_requirements.onScreenText ? ', ' : ''}</div>}
                            {task.video_requirements.music ? <div>&nbsp;Background Music{task.video_requirements.onScreenText ? ', ' : ''}</div>: <div> &nbsp;No Background Music{task.video_requirements.onScreenText ? ', ' : ''}</div>}
                            {task.video_requirements.onScreenText ? <div>&nbsp;On Screen Text{task.video_requirements.subtitles ? ', ' : ''}</div> : <div> &nbsp;No On Screen Text{task.video_requirements.subtitles ? ', ' : ''}</div>}
                            {task.video_requirements.subtitles ? <div>Subtitles</div>: <div>, &nbsp;No Subtitles</div>}
      
                          </div>
                        </>
                        }
                      {task.variations.map((variation, index) => (
                        <li key={variation.id}>
                          
                          <div className=' flex w-full 2xl:text-base md:text-0.7'>
                            - 1 version with &nbsp; {variation.voiceover ? <div>Voiceover (English){variation.music || variation.onScreenText || variation.separateClips ? ', ' : ''}</div> : <div> No Voiceover{variation.music || variation.onScreenText || variation.separateClips ? ', ' : ''}</div>}
                            {variation.music ? <div>&nbsp;Background Music{variation.onScreenText || variation.separateClips ? ', ' : ''}</div>: <div> &nbsp;No Background Music{variation.onScreenText || variation.separateClips ? ', ' : ''}</div>}
                            {variation.onScreenText ? <div>&nbsp;On Screen Text{variation.separateClips || variation.subtitles ? ', ' : ''}</div> : <div> &nbsp;No On Screen Text{variation.separateClips || variation.subtitles ? ', ' : ''}</div>}
                            {variation.subtitles ? <div>Subtitles{variation.separateClips ? ', ' : ''}</div>: <div> &nbsp;No Subtitles{variation.separateClips ? ', ' : ''}</div>}
                            {variation.separateClips && <div>&nbsp; just the raw footage</div>}
                          </div>
                        </li>
                      ))}
                      </ul>
                    </div>
                  )}

            {task && task.add_ons && task.add_ons.some((addOn) => addOn.name === "Whitelist") && (

                    <div className='mt-2'>
                      <p className='font-bold text-Blue'>ATTENTION: Whitelisting is required for this brief. For info on how to whitelist for TikTok and Instagram view these links below:</p>
                      <div><a href='https://ads.tiktok.com/help/article/spark-ads-creation-guide' target='_blank' className='text-Blue underline'>TikTok Whitelisting Guide</a></div>
                      <div><a href='https://help.instagram.com/742368993017210' target='_blank' className='text-Blue underline'>Instagram Whitelisting Guide</a></div>
                    </div>  
                  )
               }
                {task && task.brief_link && task.brief_link.trim() !== "" ? (
                  <>
                   <div className="mt-2 font-bold">Brief Link:</div>
                      <a href={task.brief_link} target="_blank" rel="noopener noreferrer" className="text-Blue text-base hover:underline">
                        View Brief Details Here
                      </a>
                  </>
                    ) : null}
              </div>
              <div className="items-center justify-center text-center">
            {instagramAccountMissing || tiktokAccountMissing ? (
              <div className='text-red text-0.7 font-bold '>
                <p>This brief requires an Instagram and TikTok account. Please add your Instagram or TikTok account to your profile before sending a proposal.</p>
                <Link to="/creator/dashboard/settings" style={{ display: 'block' }} className="bg-red text-white px-2 py-4 mt-5 rounded-lg outline-none text-base">
                  Go to Settings
                </Link>
              </div>
            ) : userInfoData && !userInfoData.shipping_details ? (
              <div className='text-red font-bold flex flex-col'>
                <p className='text-0.7 '>You are required to have an address on file to send a proposal. Please add your address to your profile before sending a proposal.</p>
                <Link to="/creator/dashboard/settings" style={{ display: 'block' }} className="bg-red text-white px-2 py-4 mt-5 rounded-lg outline-none text-base">
                  Go to Settings
                </Link>
              </div>
            ) : !task.regions.some(region => 
              // Check if the region matches the user's state/province
              region.value.toLowerCase() === userInfoData.shipping_details.state.toLowerCase() ||
              
              // Check if the region is "USA" and the user's country is "USA"
              (region.value.toLowerCase() === 'usa' && userInfoData.shipping_details.country.toLowerCase() === 'usa') ||
              
              // Check if the region is "CAN" and the user's country is "Canada"
              (region.value.toLowerCase() === 'can' && userInfoData.shipping_details.country.toLowerCase() === 'canada') ||
              
              // Check if the region is "Canada" and the user's country is "Canada"
              (region.value.toLowerCase() === 'canada' && userInfoData.shipping_details.country.toLowerCase() === 'canada') ||
              
              // Additional check for "CAN" abbreviation if needed
              (region.value.toLowerCase() === 'can' && userInfoData.shipping_details.country.toLowerCase() === 'can')
            ) ? (
              <div className='text-red font-bold flex flex-col'>
                <p className='2xl:text-2xl text-base xs:text-sxs '>Unfortunately, this brief is not available in your state or province.</p>
                <p className='2xl:text-2xl text-base xs:text-sxs'> Please check for other briefs that are available in your region.</p>
              </div>
            ): (
              <>
                <CustomButton
                  className=" text-white px-2 py-4 outline-none 2xl:text-base md:text-0.7 xs:text-sxs"
                  onClick={() => proposalHandle()}>
                  Send Proposal
                </CustomButton>
                {task.brief_template && (
                  <CustomButton
                    className="ml-4 text-white px-2 py-4 outline-none 2xl:text-base md:text-0.7 xs:text-sxs"
                    onClick={() => viewBriefHandle()}>
                    View Brief
                  </CustomButton>
                )}
              </>
            )}
          </div>
            </div>
          </GlobalDialog>
        </div>
      ) : null}
      {tab === 1 ? (
        <ConfirmModalTask open={confirm} onClose={() => proposalHandle()} id={id} task={task} />
      ) : null}

      {briefView ? (
        <BriefTemplateView open={briefView} close={() => viewBriefHandle()} id={task.brief_template.id} task={task} />
      ) : null}
    </div>
  );
}

export default ProposalModal;
