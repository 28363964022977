import React from 'react';
import ReactApexChart from 'react-apexcharts';
export default class ApexChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: 'Views',
          type: 'column',
          data: [420, 467, 500, 561, 587, 640, 750]
        },
        {
          name: 'Engagement Rate',
          type: 'line',
          data: [3.1, 2.1, 4.1, 5.1, 10, 7.1, 8]
        }
      ],
      options: {
        chart: {
          height: 400,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            columnWidth: '20%',
            endingShape: 'rounded'
          }
        },
        stroke: {
          width: [0, 3]
        },
        title: {
          text: 'Performance'
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1]
        },
        xaxis: {
          labels: {
            rotate: -45
          },
          categories: ['Mon', 'Tue', 'Wed', 'Thus', 'Fri', 'Sat', 'Sun'],
          tickPlacement: 'on'
        },
        yaxis: [
          {
            title: {
              text: 'engagements'
            }
          },
          {
            opposite: true,
            title: {
              text: 'Social Media'
            }
          }
        ]
      }
    };
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="line"
          height={400}
        />
      </div>
    );
  }
}
