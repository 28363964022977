import React, { useState, useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import Loader from '../../Loader';
import { useAuth } from '../../../Context/AuthContext';
import { AuthService, userAuthstate } from '../../../Service/authenticationService';
import { useTabState } from '../../../utils/TabState';
import { CampaignState } from '../../../utils/campaignState';
import S3FileUpload from 'react-s3';
import s3 from '../../../aws-s3'
import UgcTab from '../../../common/steps/campaign/ugcTab';
import { ImCross } from 'react-icons/im';
import GlobalDialog from '../../../utils/globalDialog';
import EditCampaignInfo from './EditCampaignInfo';
import EditCampaignTasks from './EditCampaignTasks';
import EditCampaignConfirmation from './EditCampaignConfirmation';
function EditCampaign(props) {
  const tabState = useTabState();  
  useEffect(() => {
    tabState.merge({ currentTab: 0 });
  }, []);

  const [campaign, setCampaign] = useState({
    influencerBudget: props.campaign.influencer_reach_budget,
    campaignPhoto: props.campaign.campaign_photo,
    campaignName: props.campaign.campaign_name,
    tasks: props.campaign.tasks,
    totalBudget: props.campaign.total_budget,
    brandId: props.campaign.brand_id,
    id: props.campaign.id
  });

  const updateCampaign = (key, value) => {
    setCampaign(prevCampaign => ({
      ...prevCampaign,
      [key]: value
    }));
  };

  function goToNextTab() {
    tabState.merge({ currentCampModalTab: tabState.currentCampModalTab.value + 1 });
  }
  function goToTab(index) {
    tabState.merge({ currentCampModalTab: index });
  }

  return (
    <GlobalDialog className='w-screen' open={props.open} 
    onClose={() => { 
        tabState.merge({ currentCampModalTab: 0 });
        props.onClose();
    }}>
      <div>
            <h1 className="text-xl font-bold mb-3 mx-5 my-5 font-black xs:xl md:text-4xl">
             Edit Influencer Campaign
            </h1>
            <hr className="my-5" />
            <UgcTab currentCampModalTab={tabState.currentCampModalTab.value} onTabClick={goToTab} />

            {tabState.currentCampModalTab.value === 0 && <EditCampaignInfo  campaign = {campaign} updateCampaign={updateCampaign}  goToNextTab={goToNextTab}/>}
            {tabState.currentCampModalTab.value === 1 && <EditCampaignTasks campaign = {campaign}  updateCampaign={updateCampaign} goToNextTab={goToNextTab} />}
            {tabState.currentCampModalTab.value === 2 && (
              <EditCampaignConfirmation
              campaign = {campaign}
                onClose={() => {
                  props.onClose();
                }}
              />
            )}
          </div>
  </GlobalDialog>
  )
}

export default EditCampaign