import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from "react-router-dom";
import TrialProfileHeader from './TrialProfileHeader';
import TrialProfileAssets from './TrialProfileAssets';
import TrialProfileReviews from './TrialProfileReviews';
import { useUserState, UsersService } from '../../Service/userServices';
import { useLocation } from 'react-router-dom';
import { useReviewState } from '../../Service/ReviewService';

function TrialProfilePage() {
  const [currentTab, setCurrentTab] = useState(1);
  const userState = useUserState();
  const reviewState = useReviewState();
  const [creatorId, setCreatorId] = useState(null);
  const [userData, setUserData] = useState(null);
  let { uid } = useParams();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { creator_id } = location.state || {}; 
  console.log("CREATOR ID", creator_id);
  useEffect(() => {
    const creator_id = sessionStorage.getItem('creator_id');
    console.log("Retrieved creator id from session storage", creator_id);
    // Use the creator_id as needed
    if (creator_id) {
        setCreatorId(creator_id);
    }
}, []);
  const fetchUserData = useCallback(async () => {
    if (creatorId !== null) {
      setLoading(true);
      try {
        const data = await UsersService.FetchUserInfo(creatorId);
        console.log('User data:', data);
        setUserData(data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    }
  }, [creatorId]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const user = userState.user.value;

  useEffect(() => {
    console.log('User state:', user);
  }, [user]);
  return (
    <div className="items-center">
      <div className="w-11/12 md:max-w-6xl md:mx-auto mx-4 mb-20 md:mb-52">
        {loading ? (
          <div className="flex justify-center items-center h-screen">
            <p>Loading...</p>
          </div>
        ) : (
          <>
            {<TrialProfileHeader user={userData} />}
            <ul id="tabs" className="flex mt-4 mb-4 justify-center md:justify-start">
              <li
                className={currentTab === 1 ? 'border-b-4 cursor-pointer text-Red font-bold text-xl border-Red mr-4' : 'border-b-4 text-xl text-grayLight1 cursor-pointer border-transparent mr-4'}
                onClick={() => setCurrentTab(1)}
              >
                <p>Showcase</p>
              </li>
              <li
                className={currentTab === 3 ? 'border-b-4 cursor-pointer text-Red font-bold text-xl border-Red mr-4' : 'border-b-4 text-xl text-grayLight1 cursor-pointer border-transparent mr-4'}
                onClick={() => setCurrentTab(3)}
              >
                <p>Reviews</p>
              </li>
            </ul>
            {currentTab === 1 && <TrialProfileAssets uid={creatorId} />}
            {currentTab === 3 && <TrialProfileReviews uid={creatorId} />}
          </>
        )}
      </div>
    </div>
  );
}

export default TrialProfilePage;