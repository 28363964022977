import React, {useState, useEffect} from 'react'
import { useAuth } from '../../Context/AuthContext';
import moment from 'moment';
import { UsersService, useUserState } from '../../Service/userServices';
import { TaskService, useTaskState } from '../../Service/TaskServices';
import { baseUrl } from '../../baseUrl';
import { InfluencerTaskService } from '../../Service/InfluencerTaskService';
function PayoutsCard() {
  const taskState = useTaskState();
  const { currentUser } = useAuth();
  const [influencerTasks, setInfluencerTasks] = useState();
  const userState = useUserState();
  useEffect(() => {
    UsersService.FetchUserInfo(currentUser.uid);
    TaskService.FetchTaskForUser(currentUser.uid);
  }, [taskState.updateNeed.value]);

  useEffect(() => {
    const fetchInfluencerTasks = async () => {
      try {
        const response = await fetch(`${baseUrl}api/influencers/${currentUser.uid}/tasks`);
        if (!response.ok) {
          throw new Error('Failed to fetch Influencer Tasks');
        }
        const data = await response.json();
        const tasksArray = Object.values(data.result);
        const completedTasksArray = tasksArray.filter((task) => task.status === 'completed');
        setInfluencerTasks(completedTasksArray.reverse());
      } catch (error) {
        console.error('Error fetching Influencer Tasks:', error);
      }
    };
    
    fetchInfluencerTasks();
  }, []);
  
  useEffect(() => {
    console.log("influencer tasks: ", influencerTasks);
  }, [influencerTasks]);


  const res = taskState.tasks.value;
  const user = userState.user.value;
  console.log("user: ", user);

  if (user && user.fulfillment) {
    console.log("fulfillment: ", user.fulfillment);
  }
  const resUser = taskState.userTasks.value;
  const task = res
    ? Object.entries(res).map((e) => ({
        [e[0]]: e[1]
      }))
    : [];

    //FOR THE NON INFLUENCER TASKS

    
    let completedCreatorTasks = [];
    let completedInfluencerTasks = [];
  
    
    let combinedTasks = [];

    if (influencerTasks) {
      for (let task of Object.values(influencerTasks)) {
        if (task.status === 'completed' ) {
          completedInfluencerTasks.push(task);
        }
      }
    }
    
    if (resUser) {
      for (let task of Object.values(resUser)) {
        if (task.status === 'completed') {
          completedCreatorTasks.push(task);
        }
      }
    }
    console.log("infl tasks: ", completedInfluencerTasks);
    console.log("creator tasks: ", completedCreatorTasks);
    const completedTasks = completedCreatorTasks.concat(completedInfluencerTasks);
    if (user && user.fulfillment) {
      for (let key in user.fulfillment) {
        let fulfillment = user.fulfillment[key];
        // Add the fulfillment to the completed tasks array
        completedTasks.push(fulfillment);
      }
    }
    console.log("completed Task: ", completedTasks);

    completedTasks.sort((a, b) => {
      if (a.payment_date && !b.payment_date) return -1; // a has a payment_date, b doesn't
      if (!a.payment_date && b.payment_date) return 1; // b has a payment_date, a doesn't
      if (!a.payment_date && !b.payment_date) return 0; // neither a nor b has a payment_date
      return new Date(b.payment_date) - new Date(a.payment_date); // both a and b have a payment_date
    });

    
  return (
<div className='px-8 md:px-10 text-3xl md:text-3xl md:mt-3 font-bold'>
  <h1 className='mb-5 xl:text-base test-xs'>Payments</h1>
  <div className='bg-white rounded shadow-md border-gray-600 mt-3 lg:w-full h-40 md:h-32 overflow-y-scroll'>
    {completedTasks.length > 0 ? (
        completedTasks.map((task, index) => {
        const payment = task.payment_amount 
          ? `$${task.payment_amount }` 
          : `$${task.price}`;

        return (
          <div key={index} className='mt-4 rounded-md block font-thin border-gray-200 border-b-2'>
          <div className='flex justify-between'>
              <div className='flex flex-col'>
                  <h1 className='font-bold md:text-3xl ml-10'>{task.brand_name}</h1>
                  <div className='ml-10 text-xs'>
                      {task.campaign_id 
                          ? `Influencer Brief${task.reimbursement ? ' (In-store reimbursement)' : ''}` 
                          : `UGC Brief${task.reimbursement ? ' (In-store reimbursement)' : ''}`
                      }
                  </div>
              </div>
              <div className='flex flex-col items-end mr-7 w-48'>
                  <h1 className='font-bold md:text-3xl text-right ml-10'>
                      {task.payment_amount
                          ? `$${(task.payment_amount / 100).toFixed(2)}` 
                          : task.reimbursement
                              ? `$${(task.reimbursement).toFixed(2)}`
                              : `$${(task.price / 100).toFixed(2)}`
                      }
                  </h1>
                  <div className='w-24 text-right'>
                      {task.payment_date && (
                          <h1 className='md:text-3xl text-xs'>{moment(task.payment_date).format('MM/DD/YYYY')}</h1>
                      )}
                  </div>
              </div>
          </div>
      </div>
        );
      })
    ) : (
      <div className='text-center flex justify-center items-center mx-auto mt-10 md:text-4xl'>No Payments Available</div>
    )}
  </div>
</div>
  )
}

export default PayoutsCard