import React, {useState, useEffect} from 'react'
import ReactStars from 'react-rating-stars-component';
import CreatorReviewInfo from './CreatorReviewInfo';

function CreatorReviewCard(props) {
  console.log("ReviewCard", props.review);
  const [review, setReview] = useState(false);
  const date = new Date(props.review.date_reviewed);
  const formattedDate = date.toLocaleDateString();

  return (
<div className="flex flex-col sm:flex-row items-center shadow-md border-2 xl:text-base md:text-0.7 sm:text-xs xs:text-0.7 mx-3 bg-white hover:bg-gray-100 p-4 xs:p-4 cursor-pointer rounded-lg mb-4" onClick={()=> setReview(true)}>
  <img src={props.review.brand_avatar} alt="Profile" className="w-16 h-16 rounded-full mr-4 xs:w-12 xs:h-12" />
<div className="flex sm:flex-row justify-between w-full">
  <div>
    <h4 className="font-bold xs:text-sm">{props.review.brand_name}</h4>
    <p className="overflow-hidden overflow-ellipsis whitespace-nowrap xs:text-xs">{props.review.task_name}</p>
    {props.review.campaign_name ? <p className="overflow-hidden overflow-ellipsis whitespace-nowrap xs:text-xs">{props.review.campaign_name}</p> : null}
    <p className="xs:text-xs">{formattedDate}</p>
  </div>
  <div className="flex flex-col justify-end">
    {props.review.completed === true ? 
      <ReactStars 
        count={5}
        value={props.review.overall_rating}
        size={30}
        edit={false}
        isHalf={true}
        activeColor="#FFD700"
        className="xs:size-24"
      /> 
      : null}
  </div>
</div>
  {review ? 
    <CreatorReviewInfo open={review} onClose={()=> setReview(false)} review={props.review} />  
 
    : null}
</div>
  )
}

export default CreatorReviewCard