import React, { useState, useEffect, useContext } from 'react';
import 'react-responsive-modal/styles.css';
import Loader from '../../Loader';
import { useAuth } from '../../../Context/AuthContext';
import { AuthService, userAuthstate } from '../../../Service/authenticationService';
import { useTaskState } from '../../../Service/TaskServices';
import { BrandService, useBrandState } from '../../../Service/BrandServices';
import { SubscriptionService } from '../../../Service/SubscriptionServices';
import { CampaignState } from '../../../utils/campaignState';
import Multiselect from 'multiselect-react-dropdown';
import { ProductService, useProductState } from '../../../Service/ProductServices';
import { BsArrowRight } from 'react-icons/bs';
import { BrandContext } from '../../../routes/PrivateRoute/BrandDashboardRoute';
import CustomButton from '../../../utils/customButton';

const styles = {
    option: {
        // To change css for dropdown options
    },
    optionContainer: {
        // To change css for option container
        overflowY: 'scroll',
        height: '120px',
        overflowX: 'hidden',
        position: 'relative'
    },
    multiselectContainer: {
        // To change css for multiselect (Width,height,etc..)
        height: '120px',
        marginTop: '10px',
        marginBottom: '65px',
        position: 'relative',
        outerWidth: '95%'
    },
    chips: {
        // To change css chips(Selected options)
        // display:"none",
        background: '#A9A9A9'
    },
    searchBox: {
        // To change search box element look
        border: 'none',
        padding: '7px 10px',
        fontSize: '15px',
        backgroundColor: '#F3F4F6',
        backgroundImage: 'url(../../Assets/drop_down.png)',
        borderRadius: '10px'
    }
};

export default function NewTaskModal({ setAddCampaign }) {
    const { currentUser } = useAuth();
    const taskState = useTaskState();
    const [isSubmitAttempted, setIsSubmitAttempted] = useState(false);
    const [recipe, setRecipe] = useState(0);
    const [linkRequired, setLinkRequired] = useState(false);
    const [trafficLink, setTrafficLink] = useState('');
    const [taskCredits, setTaskCredits] = useState(0);
    const productState = useProductState();
    const [selectedStates, setSelectedStates] = useState([]);
    const brandState = useBrandState();
    const campaignStateData = CampaignState();
    const trafficDestination = campaignStateData.traffic_destination.value;

    console.log("campaignStateData", campaignStateData);

    const stateNames = [
        'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
        'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
        'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
        'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina',
        'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming',
        'Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 
        'Quebec', 'Saskatchewan', 'Northwest Territories', 'Nunavut', 'Yukon'
    ];
    
    const stateAbbreviations = [
        'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
        'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
        'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
        'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
        'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY',
        'AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'PE', 'QC', 'SK', 'NT', 'NU', 'YT'
    ];
    
    const stateOptions = [
        { label: 'United States of America', value: 'USA' },
        { label: 'Canada', value: 'CAN' },
        ...stateNames.map((name, index) => ({
            label: name,
            value: stateAbbreviations[index]
        }))
    ];
    
    //handle input 
    const [taskName, setTaskName] = useState('');
    const [isRecipesChecked, setIsRecipesChecked] = useState(false);
    const [isReviewChecked, setIsReviewChecked] = useState(false);
    const [isPhotosChecked, setIsPhotosChecked] = useState(false);
    const [audience, setAudience] = useState();
    const [generalTalking, setGeneralTalking] = useState();
    const [targetRetailer, setTargetRetailer] = useState();
    const [linkLocator, setLinkLocator] = useState();
    const [capInstructions, setCapInstructions] = useState();
    const [specificRegion, setSpecificRegion] = useState();
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedValue] = useState([]);
    const [selectedRec, setSelectedRec] = useState([]);
    const { brandId } = useContext(BrandContext);



    useEffect(() => {
        if (brandId !== null) {
            BrandService.FetchBrandForUser(brandId);
        }
    }, [brandState.updateNeed.value]);

    const brand = brandState.brand.value;

    useEffect(() => {
        // tabState.merge({ currentTab: 1 });
        if (productState.updateNeed.value) {
            ProductService.FetchProductForUser(brandId);
        }
    }, [productState.updateNeed.value]);

useEffect(() => {
    if (campaignStateData.editTask.value) {
        const tempEditTask = campaignStateData.editTask.value;
        setTaskName(tempEditTask.name);
        setRecipe(tempEditTask.number_of_videos);
        setIsRecipesChecked(tempEditTask.add_ons.some(addon => addon.name === 'Recipes'));
        setIsReviewChecked(tempEditTask.add_ons.some(addon => addon.name === 'Review'));
        setIsPhotosChecked(tempEditTask.add_ons.some(addon => addon.name === 'Photos'));
        setAudience(tempEditTask.target_audience);
        setGeneralTalking(tempEditTask.talking_points);
        setTargetRetailer(tempEditTask.retailer);
        setLinkLocator(tempEditTask.store_list);
        setSpecificRegion(tempEditTask.regions[0]);
        setSelectedRec(tempEditTask.platform);
        setSelectedProduct(tempEditTask.product_id); // Assuming product_id is the selected product
        setTrafficLink(tempEditTask.traffic_link);
        setSelectedRec(tempEditTask.platform);
        setLinkRequired(tempEditTask.link_required);
        setCapInstructions(tempEditTask.caption_instructions);
    }
}, []);



    const res = JSON.stringify(productState.products.value);


    const productTemp = [];
    if (productState.products.value) {
        Object.keys(JSON.parse(res)).forEach(el => {
            productTemp.push({
                id: el,
                ...JSON.parse(res)[el]
            })
        })
    }
    const handleAddRecipe = () => {
        let rec = recipe;
        let cred = taskCredits;
        setTaskCredits(cred + 2);
        setRecipe(rec + 1);
    };
    const handleDRecipe = () => {
        if (taskCredits >= 2 && recipe > 0) {
            let rec = recipe;
            let cred = taskCredits;
            setTaskCredits(cred - 2);
            setRecipe(rec - 1);
        }
    };
    const handleRecipeChange = (event) => {
        const value = parseInt(event.target.value);
        if (isNaN(value)) {
            setRecipe(0);
        } else {
            setRecipe(value);
        }
    };
    const authState = userAuthstate();

    useEffect(() => {
        const user = brandId ? brandId : null;
        if (user) {
            AuthService.FetchAuthUser(brandId);
            SubscriptionService.FetchSubscriptionForUser(brandId);
        }
    }, [authState.updateNeed.value]);

    const onStateSelect = (selectedList) => {
        setSelectedStates(selectedList);
        console.log("Selected states: ", selectedList);
    };

    const onStateRemove = (selectedList) => {
        setSelectedStates(selectedList);
        console.log("Removed states: ", selectedList);
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!selectedRec || selectedRec.length === 0) {
            alert("Please select at least one platform.");
            return; // Exit the function early
        }
        const product = productTemp.find((obj) => obj.id === selectedProduct);
        const task = {
            "brand_name": brand.brand_name,
            "brand_photo": brand.brand_avatar || "https://crafted-react-images.s3.amazonaws.com/pexels-chevanon-photography-302899.jpg",
            "brand_website": brand.brand_website || "none",
            "name": taskName,
            "photo": product.thumbnail ? product.thumbnail : "https://crafted-react-images.s3.amazonaws.com/pexels-chevanon-photography-302899.jpg",
            "platform": selectedRec,
            "product_name": product.name ? product.name : 'none',
            "product_id": selectedProduct,
            "product_link": product.link ? product.link : 'none',
            "product_photo": product.thumbnail ? product.thumbnail : 'none',
            "product_price": product.price1 ? product.price1 : 1000,
            "add_ons": [],
            "target_audience": audience,
            "traffic_link": trafficLink != '' ? trafficLink : null,
            "traffic_destination": trafficDestination,
            "link_required": linkRequired,
            "talking_points": generalTalking,
            "caption_instructions": capInstructions,
            "retailer": targetRetailer,
            "store_list": linkLocator,
            "regions": selectedStates
        }

        if (isRecipesChecked) task.add_ons.push({ name: 'Recipes', price: 25.00 })
        if (isReviewChecked) task.add_ons.push({ name: 'Review', price: 15.00 })
        if (isPhotosChecked) task.add_ons.push({ name: 'Photos', price: 10.00 })

        const tasksCopy = [...campaignStateData.tasks.value];

        if (campaignStateData.editTask.value) {
            const editTaskIndex = tasksCopy.findIndex((task) => task.id === campaignStateData.editTask.value.id);

            if (editTaskIndex !== -1) {
                tasksCopy[editTaskIndex] = task;
                campaignStateData.merge({ tasks: tasksCopy });
            }
        } else {
            const tempTask = JSON.stringify(campaignStateData.tasks.value);
            const tasks = JSON.parse(tempTask);
            tasks.push(task);
            campaignStateData.merge({ tasks });
        }

        campaignStateData.merge({ editTask: null }); // Reset editTask to null
        setAddCampaign(false);
    };
    const onSelect = (selectedList, selectedItem) => {
        const selectedData = selectedList.map(el => el.name);
        setSelectedRec(selectedData);
    };

    const onRemove = (selectedList, removedItem) => { };

    return (
        <form onSubmit={handleSubmit} className="px-4">
            <div className="my-2">
                <div className='flex justify-end items-start cursor-pointer font-bold mb-2' onClick={() => setAddCampaign(false)}>Back to Briefs<BsArrowRight className='mx-3 mt-1' /></div>
                <label htmlFor="note1" className="text-black font-bold block md:text-lg mb-2">
                    Brief Name
                </label>
                <p className='text-gray-500 text-base mb-1'>Please choose a brief name that is short, but descriptive & distinctive. This will help creators to differentiate between briefs.</p>
                <input
                    name="note1"
                    className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                    placeholder=""
                    maxLength={40}
                    required
                    value={taskName}
                    onChange={(e) => setTaskName(e.target.value)}
                />
            </div>
            {/**<div className="w-full">          
                <label htmlFor="description" className="text-black font-bold block md:text-lg mb-2">
                    How many videos would you like?
                </label>
               <div className="flex bg-lightGray rounded-lg">
                    <div className="w-2/5 p-2 font-bold text-xs">
                    <input
                        type="number"
                        value={recipe}
                        onChange={handleRecipeChange}
                        className="mx-2 w-16 text-center rounded-md"
                    />
                        <span className="mx-2">Influencers (Video Assets)</span>
                    </div>
                    <div className="flex p-2 w-1/5 font-bold text-xs">
                         <span className="mx-2">{recipe * 2}</span> credits 
                    </div>
                    <div className="flex w-2/5 font-bold">
                        <div
                            className="ml-5px text-4xl font-bold cursor-pointer"
                            onClick={handleAddRecipe}>
                            +
                        </div>
                        <div className="ml-8 text-4xl font-bold cursor-pointer" onClick={handleDRecipe}>
                            -
                        </div>
                    </div>
                </div>
            </div>
            **/}
            <div className="w-full">
                <label htmlFor="description" className="text-black font-bold block md:text-lg mb-2">
                    What add-ons would you like?
                </label>
                <p className='text-gray-500 text-base mb-1'>Please choose any additional deliverables for this brief, the number of videos will match the number of deliverables. (ex. 10 videos = 10 recipes) </p>
                <div className="flex rounded-lg">
                    <div className="w-2/5 p-2 ">
                        <input type="checkbox" id="isRecipesChecked" name="isRecipesChecked" value={isRecipesChecked} onChange={(e) => setIsRecipesChecked(e.target.checked)} className='p-3' />
                        <span className="mx-2">Recipes (50 credits each) </span>
                    </div>
                    <div className="w-2/5 p-2 ">
                        <input type="checkbox" id="isReviewChecked" name="isReviewChecked" value={isReviewChecked} onChange={(e) => setIsReviewChecked(e.target.checked)} />
                        <span className="mx-2">Reviews (30 credits each) </span>
                    </div>
                    <div className="w-2/5 p-2">
                        <input type="checkbox" id="myCheckbox" name="myCheckbox" value={isPhotosChecked} onChange={(e) => setIsPhotosChecked(e.target.checked)} />
                        <span className="mx-2"> 3 Photos (25 credits each) </span>
                    </div>
                </div>
            </div>

            <div className='w-full border-2 my-10' />
            <h1 className='w-full text-center font-extrabold my-2'>Brief Details</h1>

            <div className="my-2">
                <label htmlFor="audience" className="text-black font-bold block md:text-lg mb-2">
                    Showcased Product
                </label>
                <p className='text-gray-500 text-base mb-1'>Please choose the product you want creators to showcase, there is only 1 showcased product per brief.</p>
                <select
                    // ref={selectRef}
                    value={selectedProduct}
                    onChange={(e) => setSelectedProduct(e.target.value)}
                    className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                    required
                    placeholder="Choose Product...">
                    <option className="text-sm text-indigo-800" value="">
                        Choose Product
                    </option>
                    {productTemp
                        .map((item) => {
                            return (
                                <option className="text-sm text-indigo-800" value={item.id} key={item.id}>
                                    {item.name}
                                </option>
                            );
                        })}
                </select>
            </div>

            <div className="my-2">
                <label htmlFor="description" className="text-black font-bold block md:text-lg mb-2">
                    Ideal Target Audience
                </label>
                <p className='text-gray-500 text-base mb-1'>Please indicate here which customers you aim to target with this brief (ie. age, gender, demographics).</p>
                <input
                    name="audience"
                    className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                    placeholder=""
                    required
                    value={audience || ''} // Set a default empty string if audience is undefined or null
                    onChange={(e) => setAudience(e.target.value)}
                />
            </div>
            <div className="my-2">
                <label htmlFor="generalTalking" className="text-black font-bold block md:text-lg mb-2">
                    General talking points for the videos
                </label>
                <p className='text-gray-500 text-base mb-1'>Please indicate any specific topics or key product benefits to be covered by each creator in the video.</p>
                <textarea
                    className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
                    rows="5"
                    cols="50"
                    required
                    name="generalTalking"
                    value={generalTalking || ''}
                    onChange={(e) => setGeneralTalking(e.target.value)}
                // ref={generalTalking}
                />
            </div>

            <div className="my-2">
                <label htmlFor="generalTalking" className="text-black font-bold block md:text-lg mb-2">
                    Caption Instructions
                </label>
                <p className='text-gray-500 text-base mb-1'>Please add any instructions you have for the caption that will accompany any influencer post from this brief.</p>
                <textarea
                    className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
                    rows="5"
                    cols="50"
                    required
                    name="generalTalking"
                    value={capInstructions || ''}
                    onChange={(e) => setCapInstructions(e.target.value)}
                // ref={generalTalking}
                />
            </div>
            <div className="w-full">
                <div className="flex rounded-lg">
                    <div className="w-1/2 p-2">
                        <label htmlFor="description" className="text-black font-bold block md:text-lg mb-2">
                            Link in Bio
                        </label>
                        <p className='text-gray-500 text-base mb-1'>Please indicate whether including a link in bio is required for creators applying to this brief.</p>
                        <select
                            name="linkRequired"
                            className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                            value={linkRequired}
                            onChange={(e) => setLinkRequired(e.target.value === 'true')}>
                            <option value={true}>Required</option>
                            <option value={false}>Not Required</option>
                        </select>
                    </div>
                    <div className="w-1/2 p-2">
                        <span htmlFor="description" className="text-black font-bold block md:text-lg mb-2">
                            {trafficDestination === 'amazon' ? 'Amazon Product Page'
                                : trafficDestination === 'brand_website' ? 'Brand Website'
                                    : trafficDestination === 'store_locator' ? 'Store Locator'
                                        : trafficDestination === 'retail_website' ? 'Retail Website'
                                            : trafficDestination === 'sms_rebate' ? 'SMS Rebate Landing Page'
                                                : 'Other'} Link
                        </span>
                        <p className='text-gray-500 text-base mb-1'>Provide the {trafficDestination} link, where we will direct all traffic from the influencer posts for this brief.</p>
                        <input
                            name="linkLocator"
                            className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                            placeholder=""
                            required
                            value={trafficLink || ''}
                            onChange={(e) => setTrafficLink(e.target.value)}
                            disabled={!linkRequired} // disable the input field when linkRequired is false
                        />
                    </div>

                </div>
            </div>
            <div className="my-2">
                <label htmlFor="description" className="text-black font-bold block md:text-lg mb-2">
                    Influencer Platform
                </label>
                <p className='text-gray-500 text-base mb-1'>Please indicate which platforms you will be targeting for this creative brief.</p>
                <Multiselect
                    options={[{ name: 'Instagram' }, { name: 'TikTok' }]}
                    selectedValues={selectedValue}
                    showCheckbox={true}
                    closeOnSelect={true}
                    placeholder="Select Platform"
                    onSelect={onSelect}
                    onRemove={onRemove}
                    displayValue="name"
                />
                {isSubmitAttempted && selectedValue.length === 0 && (
                    <div style={{ color: 'red' }}>Please select at least one platform.</div>
                )}
            </div>
            <div className='w-full border-2 my-10' />
            <h1 className='w-full text-center font-extrabold my-2'>Fulfillment Logistics</h1>
            <div className="w-full">
                <div className="flex flex-col rounded-lg">
                    <div className="w-full p-2">
                        <label htmlFor="description" className="text-black font-bold block md:text-lg mb-2">
                            Target Retailer
                        </label>
                        <p className='text-gray-500 text-base mb-1'>Please indicate which retailer you want creators to purchase your product from. Example: Target, Walmart, etc</p>
                        <input
                            name="targetRetailer"
                            className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                            placeholder=""
                            required
                            value={targetRetailer || ''}
                            onChange={(e) => setTargetRetailer(e.target.value)}
                        />
                    </div>
                    <div className="w-full p-2">
                        <label htmlFor="description" className="text-black font-bold block md:text-lg mb-2">
                            Link to store locator or store list
                        </label>
                        <p className='text-gray-500 text-base mb-1'>Please provide a link to a product page or store locator where the creator can find the product. Example: https://www.target.com/stores.</p>
                        <input
                            name="linkLocator"
                            className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                            placeholder=""
                            required
                            value={linkLocator || ''}
                            onChange={(e) => setLinkLocator(e.target.value)}
                        />
                    </div>

                </div>
            </div>
            <div className="my-2">
                <label htmlFor="stateDropdown" className="text-black block md:text-base mb-2 font-bold">
                    Do influencers need to be from specific regions for this task? Specify here.
                </label>
                <p className='text-gray-500 text-base mb-1'>If your launch is only regional, indicate the states that you will be launching in here.</p>
                <Multiselect
                    id="stateSelect"
                    name="state"
                    options={stateOptions}
                    selectedValues={selectedStates}
                    onSelect={onStateSelect}
                    onRemove={onStateRemove}
                    displayValue="label"
                    required
                    style={styles}
                />

            </div>

            <div className="flex justify-center">
                <CustomButton
                    type="submit"
                    className="py-2 w-fit  w-fit px-4 rounded-lg text-white mt-2 items-center justify-center text-center cursor-pointer">
                    Add Brief to Campaign
                </CustomButton>
            </div>
        </form>

    );
}
