import React, { useState } from 'react';
import DeleteModal from './DeleteModal';
import UpdateRecepe from './UpdateRecipe';
import { Link } from 'react-router-dom';
import CustomButton from '../../utils/customButton';

function RecipeCard(props) {
  const [delRec, setDelRec] = useState(false);
  const [update, setUpdate] = useState(false);
  const deleteHander = () => {
    setDelRec(true);
  };
  return (
    <>
      <Link to={`/recipe/${props.id}`}>
        <div className="bg-lightGray items-center rounded-xl p-1 flex flex-col cursor-pointer">
          <img
            src={props.recipe.thumbnail}
            style={{ height: '200px' }}
            className="rounded-lg w-full h-full object-cover"
            alt={props.recipe.name}
          />
          <div className="text-base mb-1 font-bold"> {props.recipe.name}</div>
        </div>
      </Link>
      <div className="grid grid-cols-2 justify-center gap-x-2 mt-2 mb-2">
        <CustomButton
          className=" text-white px-2 outline-none"
          onClick={() => setUpdate(!update)}>
          Update
        </CustomButton>
        <CustomButton
          className=" text-white px-2 outline-none"
          onClick={() => deleteHander()}>
          Delete
        </CustomButton>
      </div>
      {delRec ? <DeleteModal open={delRec} onClose={() => setDelRec(false)} id={props.id} /> : null}
      {update ? (
        <UpdateRecepe open={UpdateRecepe} onClose={() => setUpdate(false)} id={props.id} />
      ) : null}
    </>
  );
}

export default RecipeCard;
