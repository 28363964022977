import React, { useEffect, useState,useRef } from 'react';
import { UsersService, useUserState } from '../../../Service/userServices';
import { InstagramService, useInstagramState } from '../../../Service/InstagramService';
import { useAuth } from '../../../Context/AuthContext';
import { Toaster, toast } from 'react-hot-toast';
import { FaTiktok } from 'react-icons/fa';
import { useTabState } from '../../../utils/TabState';
import { CampaignState } from '../../../utils/campaignState';
import InstagramOnboarding from './InstagramOnboarding';
import TiktokOnboarding from './TiktokOnboarding';
import GlobalDialog from '../../../utils/globalDialog';
import Mailing from './Mailing';
import { useLocation } from 'react-router-dom';
import GeneralOnboardingSubTab from './GeneralOnboardingSubTab';
import MailingAddressModal from '../MailingAddressModal';

function GeneralOnboarding(props) {
    const location = useLocation(); 
    const userState = useUserState();
    const [instagram, setInstagram] = useState('');
    const socialHandle = useRef(null);
    const [activeTab, setActiveTab] = useState(1);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const { currentUser } = useAuth();
    const [userLoading, setUserLoading] = useState(true);
    const [address, setAddress] = useState({
        fullname: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: 'USA',
        zipcode: ''
    });
   
    useEffect(() => {
      if (currentUser.uid !== null) {
        UsersService.FetchUserInfo(currentUser.uid);
      }
    }, []);
    
    let user = userState.user.value;
    const goToNextTab = () => {
      setActiveTab(prevTab => {
        const newTab = prevTab + 1;
        console.log('goToNextTab newTab:', newTab);
        return newTab;
      });
    };
    
    const goToPreviousTab = () => {
      if (activeTab > 1) {
        setActiveTab(activeTab - 1);
      }
    };
    
    const finishOnboarding = () => {
      props.onClose();
    }

    useEffect(() => {
      if (user) {
        if (!user.shipping_details) {
          setActiveTab(1);
        } else if (user.creator_socials && !user.creator_socials.tiktok) {
          setActiveTab(2);
        } else {
          setActiveTab(3);
        }
      }
    }, []);

    const renderTabContent = () => {
      if (activeTab === 1) {
        return <Mailing goToNextTab={goToNextTab} activeTab={1}/>;
      } else if (activeTab === 2) {
        return <TiktokOnboarding goToNextTab={goToNextTab} setActiveTab={setActiveTab} activeTab={2} />;
      } else if (activeTab === 3) {
        return <InstagramOnboarding close={finishOnboarding} activeTab={3}/>;
      }
    
      return null;
    };

    let submitAddress = async () => {
        try {
            let data = address;
            await UsersService.AddAddress(currentUser.uid, data);
            toast.success('Address added successfully!')
        } catch (error) {
            console.log("Error:" + error);
        }
    }
    
    const csrfState = Math.random().toString(36).substring(2);

    const startTikTokLoginFlow = () => {
      const SERVER_ENDPOINT_OAUTH = 'https://app.cookwithcrafted.com/creator/dashboard/tik-tok-success/'; // Redirect URI, for dev: 'https://d464-144-121-204-234.ngrok-free.app/'
    
      const tikTokAuthURL = `https://www.tiktok.com/v2/auth/authorize/?client_key=${process.env.REACT_APP_TIKTOK_CLIENT_KEY}&response_type=code&scope=user.info.basic,video.list&redirect_uri=${SERVER_ENDPOINT_OAUTH}&state=${csrfState}`;
    
      // Store the CSRF token in a cookie or in a secure place on the client-side
      document.cookie = `csrfState=${csrfState}; path=/`;
    
      // Open the TikTok authorization URL in a new tab
      window.open(tikTokAuthURL, '_blank');
    };

  return (

    <div className="flex flex-col font-bold justify-end px-7 h-4/5">
      <div className=''>
        <p className="mb-2 w-full md:text-3xl text-xs text-center">Let's get started! To ensure the best experience on our platform, we need some information from you.</p>
        <p className=" w-full md:text-3xl text-xs text-center"> Please provide the necessary details in each step.</p>
      </div>
    <div className="flex w-full h-40">{renderTabContent()}</div>
  </div>

    );
}

export default GeneralOnboarding;