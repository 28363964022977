import React, { useState, useRef } from 'react';
import GlobalDialog from '../../utils/globalDialog';
import { useAssetState, AssetService } from '../../Service/AssetService';
import S3FileUpload from 'react-s3';
import Loader from '../../Components//Loader';
import s3 from '../../aws-s3';
import { useAuth } from '../../Context/AuthContext';

function EditModal({ open, onClose, id }) {
  const { currentUser } = useAuth();
  const assetState = useAssetState();
  const name = useRef('');
  const product = useRef('');
  const fileRef = useRef(null);
  const [imgUploaded, setImgUpload] = useState(false);
  const [setImgLocation] = useState('');
  const [assetData, setAssetData] = useState({
    name: '',
    thumbnail: '',
    product: '',
    creatorUID: '',
    recordingLength: '',
    sourceURL: ''
  });

  const onProductChange = (e) => {
    setAssetData({ ...assetData, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.value]: '' });
  };
  const onNameChange = (e) => {
    setAssetData({ ...assetData, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.value]: '' });
  };
  const [error, setError] = useState({
    name: '',
    thumbnail: '',
    creatorUID: '',
    product: '',
    recordingLength: '',
    sourceURL: ''
  });
  let update = {
    thumbnail: ''
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImgUpload(true);
    } else {
      setImgUpload(false);
    }
  };
  async function handleSubmit() {
    assetState.merge({ loading: true });
    const thumbnailInput = fileRef.current.files[0];
    if (!thumbnailInput) {
      return setError({ ...error, thumbnail: 'thumbnail is required' });
    }

    try {
      setError({ thumbnail: '' });
      if (imgUploaded) {
        await S3FileUpload.uploadFile(fileRef.current.files[0], s3).then((data) => {
          setImgLocation(data.location.toString());
          update = {
            thumbnail: data.location
          };
        });
      } else {
        update = {
          thumbnail: ''
        };
      }

      if (update.thumbnail === '') return setError({ ...error, name: 'image fails in to upload' });
      const temp = assetData;
      temp.name = assetData.name;
      temp.thumbnail = update.thumbnail;
      temp.creatorUID = currentUser.uid;
      temp.creatorName = currentUser.uid;
      temp.recordingLength = '12';
      temp.sourceURL = update.thumbnail;
      temp.uid = currentUser.uid;
      temp.productTags = [1, 2, 3];
      AssetService.AddAsset(temp);
      assetState.merge({ loading: false });
      onClose();
    } catch (error) {
      setError('Failed to Update Profile');
    }
  }

  return (
    <GlobalDialog open={open} onClose={onClose}>
      <div className=" bg-white rounded-lg dark:bg-gray-700">
        <div className="flex mx-12 mb-12">
          <div className="rounded-lg w-full p-3 ">
            <h1 className="text-xl font-bold mb-3 font-black xs:xl md:text-4xl">Upload Asset</h1>
            <div className="my-2">
              <label htmlFor="RetailPrice" className="text-black block md:text-lg mb-2 font-bold">
                Asset Name
              </label>
              <input
                className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
                type="text"
                name="name"
                value={assetData.name}
                onChange={onNameChange}
                ref={name}
              />
              {error && error.name && <span className="text-sm text-Red">{error.name}</span>}
            </div>
            <div className="my-2">
              <label
                htmlFor="assetThumbnail"
                className="text-black block md:text-lg mb-2 font-bold">
                Asset
              </label>
              <input
                id="imageInput"
                type="file"
                ref={fileRef}
                className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
                onChange={handleChange}
              />
            </div>
            <div className="my-2">
              <label htmlFor="RetailPrice" className="text-black block md:text-lg mb-2 font-bold">
                Select Products
              </label>
              <select
                name="product"
                className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                placeholder="Enter Exclusive Right To Content"
                value={assetData.product}
                onChange={onProductChange}
                ref={product}>
                <option>"Peace, Love & Thai Food" Apron</option>
                <option>Natural Carbon Steel Work Pan "12.5</option>
                <option>Miracle Noodles-Zero Carb Pad Thai Noodles</option>
                <option>Thai Kitchen-Pad Thai Sauce</option>
              </select>
            </div>
            <div className="bg-red w-full px-4 text-3xl font-bold py-3 rounded-lg text-white mt-4 w-1/4 items-center justify-center text-center cursor-pointer">
              <button
                type="submit"
                className="bg-red w-full px-4 text-3xl font-extrabold"
                onClick={handleSubmit}>
                {assetState.loading.value && <Loader />}
                Upload Asset
              </button>
            </div>
          </div>
        </div>
      </div>
    </GlobalDialog>
  );
}

export default EditModal;
