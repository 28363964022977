import React, { useContext, useState } from 'react'
import GlobalDialog from '../../utils/brandModal'
import {toast, Toaster} from 'react-hot-toast';
import { OrganizationService,useOrganizationState } from '../../Service/OrganizationService';
import { OrgContext } from '../../routes/PrivateRoute/OrganizationDashboard';
function EditOrgUserModal(props) {
  console.log(props.id)
  const [role, setRole] = useState(props.user.role); 
  const {orgId} = useContext(OrgContext);
  const handleRoleChange = (event) => {
    setRole(event.target.value); // Update the role when it changes
  };

  const handleSubmit = () => {
    const data = {
      user_id: props.id,
      role: role, 
      org_id: orgId
    }
    OrganizationService.ChangeUserRole(orgId, data);
  
    toast.success('User role updated successfully');
     setTimeout(() => { 
      props.onClose();
    },1500)
  
  }

  const handleDeleteUser = () => {
    // Handle delete user logic here
  };
  console.log("props: ", props)
  return (
    <GlobalDialog open={props.open} onClose={props.onClose}>
      <Toaster/>
      <div className='flex flex-col h-fit bg-white rounded-md p-4 shadow-md'>
        <p className='text-2xl font-bold mb-4 text-center border-b pb-2'>Edit User</p>
        <div className="form-group">
          <label htmlFor="firstName" className="text-gray-600">Name</label>
          <input type="text" id="firstName" value={props.user.first_name} disabled className="border bg-white border-gray-400 rounded-md p-2 mb-4 w-full" />
        </div>
        <div className="form-group">
          <label htmlFor="lastName" className="text-gray-600">Last Name</label>
          <input type="text" id="lastName" value={props.user.last_name} disabled className="border bg-white border-gray-400 rounded-md p-2 mb-4 w-full" />
        </div>
        <div className="form-group">
          <label htmlFor="email" className="text-gray-600">Email</label>
          <input type="email" id="email" value={props.user.email} disabled className="border bg-white border-gray-400 rounded-md p-2 mb-4 w-full" />
        </div>
        <div className="form-group">
          <label htmlFor="role" className="text-gray-600">Role</label>
          <select
            id="role"
            value={role} // Use the state value for the role
            onChange={handleRoleChange} // Call the state update function
            className='border border-gray-400 rounded-md p-2 mb-4 w-full'
          >
            <option value="" disabled>Select Role</option>
            <option value="admin">Admin</option>
            <option value="user">User</option>
          </select>
        </div>
        <button type="submit" className='bg-red text-white rounded-md py-1 px-2 mb-2'>Remove User</button>
        {role !== props.user.role && <button onClick = {handleSubmit} className='bg-Blue text-white rounded-md py-1 px-2'>Change Role</button>}
      </div>
    </GlobalDialog>
  )
}

export default EditOrgUserModal