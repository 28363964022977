import React from 'react'
import ProgressBar from '@ramonak/react-progress-bar';

export default function FileProgressBar({ progress }) {
  return progress > 0 ? <ProgressBar 
    width="200px"
    height="14px"
    labelSize="10px"
    padding={0}
    baseBgColor="rgba(246, 246, 246)"
    className="flex-1 relative" 
    bgColor="rgba(100, 141, 229)" 
    labelAlignment="center"
    completed={progress}/> : null
}
