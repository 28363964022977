export const countryList = [
  {
    name: 'Afghanistan',
    value: 'AF',
    timezone: 'Afghanistan Standard Time',
    utc: 'UTC+04:30',
    mobileCode: '+93'
  },
  {
    name: 'Åland Islands',
    value: 'AX',
    timezone: 'FLE Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+358-18'
  },
  {
    name: 'Albania',
    value: 'AL',
    timezone: 'Central Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+355'
  },
  {
    name: 'Algeria',
    value: 'DZ',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+213'
  },
  {
    name: 'American Samoa',
    value: 'AS',
    timezone: 'UTC-11',
    utc: 'UTC-11:00',
    mobileCode: '+1-684'
  },
  {
    name: 'Andorra',
    value: 'AD',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+376'
  },
  {
    name: 'Angola',
    value: 'AO',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+244'
  },
  {
    name: 'Anguilla',
    value: 'AI',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-264'
  },
  {
    name: 'Antarctica',
    value: 'AQ',
    timezone: 'Pacific SA Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+'
  },
  {
    name: 'Antigua and Barbuda',
    value: 'AG',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-268'
  },
  {
    name: 'Argentina',
    value: 'AR',
    timezone: 'Argentina Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+54'
  },
  {
    name: 'Armenia',
    value: 'AM',
    timezone: 'Caucasus Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+374'
  },
  {
    name: 'Aruba',
    value: 'AW',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+297'
  },
  {
    name: 'Australia',
    value: 'AU',
    timezone: 'AUS Eastern Standard Time',
    utc: 'UTC+10:00',
    mobileCode: '+61'
  },
  {
    name: 'Austria',
    value: 'AT',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+43'
  },
  {
    name: 'Azerbaijan',
    value: 'AZ',
    timezone: 'Azerbaijan Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+994'
  },
  {
    name: 'Bahamas, The',
    value: 'BS',
    timezone: 'Eastern Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+1-242'
  },
  {
    name: 'Bahrain',
    value: 'BH',
    timezone: 'Arab Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+973'
  },
  {
    name: 'Bangladesh',
    value: 'BD',
    timezone: 'Bangladesh Standard Time',
    utc: 'UTC+06:00',
    mobileCode: '+880'
  },
  {
    name: 'Barbados',
    value: 'BB',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-246'
  },
  {
    name: 'Belarus',
    value: 'BY',
    timezone: 'Belarus Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+375'
  },
  {
    name: 'Belgium',
    value: 'BE',
    timezone: 'Romance Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+32'
  },
  {
    name: 'Belize',
    value: 'BZ',
    timezone: 'Central America Standard Time',
    utc: 'UTC-06:00',
    mobileCode: '+501'
  },
  {
    name: 'Benin',
    value: 'BJ',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+229'
  },
  {
    name: 'Bermuda',
    value: 'BM',
    timezone: 'Atlantic Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-441'
  },
  {
    name: 'Bhutan',
    value: 'BT',
    timezone: 'Bangladesh Standard Time',
    utc: 'UTC+06:00',
    mobileCode: '+975'
  },
  {
    name: 'Bolivarian Republic of Venezuela',
    value: 'VE',
    timezone: 'Venezuela Standard Time',
    utc: 'UTC-04:30',
    mobileCode: '+58'
  },
  {
    name: 'Bolivia',
    value: 'BO',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+591'
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    value: 'BQ',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+599'
  },
  {
    name: 'Bosnia and Herzegovina',
    value: 'BA',
    timezone: 'Central European Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+387'
  },
  {
    name: 'Botswana',
    value: 'BW',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+267'
  },
  {
    name: 'Bouvet Island',
    value: 'BV',
    timezone: 'UTC',
    utc: 'UTC',
    mobileCode: '+'
  },
  {
    name: 'Brazil',
    value: 'BR',
    timezone: 'E. South America Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+55'
  },
  {
    name: 'British Indian Ocean Territory',
    value: 'IO',
    timezone: 'Central Asia Standard Time',
    utc: 'UTC+06:00',
    mobileCode: '+246'
  },
  {
    name: 'Brunei',
    value: 'BN',
    timezone: 'Singapore Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+673'
  },
  {
    name: 'Bulgaria',
    value: 'BG',
    timezone: 'FLE Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+359'
  },
  {
    name: 'Burkina Faso',
    value: 'BF',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+226'
  },
  {
    name: 'Burundi',
    value: 'BI',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+257'
  },
  {
    name: 'Cabo Verde',
    value: 'CV',
    timezone: 'Cape Verde Standard Time',
    utc: 'UTC-01:00',
    mobileCode: '+238'
  },
  {
    name: 'Cambodia',
    value: 'KH',
    timezone: 'SE Asia Standard Time',
    utc: 'UTC+07:00',
    mobileCode: '+855'
  },
  {
    name: 'Cameroon',
    value: 'CM',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+237'
  },
  {
    name: 'Canada',
    value: 'CA',
    timezone: 'Eastern Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+1'
  },
  {
    name: 'Cayman Islands',
    value: 'KY',
    timezone: 'SA Pacific Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+1-345'
  },
  {
    name: 'Central African Republic',
    value: 'CF',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+236'
  },
  {
    name: 'Chad',
    value: 'TD',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+235'
  },
  {
    name: 'Chile',
    value: 'CL',
    timezone: 'Pacific SA Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+56'
  },
  {
    name: 'China',
    value: 'CN',
    timezone: 'China Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+86'
  },
  {
    name: 'Christmas Island',
    value: 'CX',
    timezone: 'SE Asia Standard Time',
    utc: 'UTC+07:00',
    mobileCode: '+61'
  },
  {
    name: 'Cocos (Keeling) Islands',
    value: 'CC',
    timezone: 'Myanmar Standard Time',
    utc: 'UTC+06:30',
    mobileCode: '+61'
  },
  {
    name: 'Colombia',
    value: 'CO',
    timezone: 'SA Pacific Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+57'
  },
  {
    name: 'Comoros',
    value: 'KM',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+269'
  },
  {
    name: 'Congo',
    value: 'CG',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+242'
  },
  {
    name: 'Congo (DRC)',
    value: 'CD',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+243'
  },
  {
    name: 'Cook Islands',
    value: 'CK',
    timezone: 'Hawaiian Standard Time',
    utc: 'UTC-10:00',
    mobileCode: '+682'
  },
  {
    name: 'Costa Rica',
    value: 'CR',
    timezone: 'Central America Standard Time',
    utc: 'UTC-06:00',
    mobileCode: '+506'
  },
  {
    name: "Côte d'Ivoire",
    value: 'CI',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+225'
  },
  {
    name: 'Croatia',
    value: 'HR',
    timezone: 'Central European Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+385'
  },
  {
    name: 'Cuba',
    value: 'CU',
    timezone: 'Eastern Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+53'
  },
  {
    name: 'Curaçao',
    value: 'CW',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+599'
  },
  {
    name: 'Cyprus',
    value: 'CY',
    timezone: 'E. Europe Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+357'
  },
  {
    name: 'Czech Republic',
    value: 'CZ',
    timezone: 'Central Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+420'
  },
  {
    name: 'Democratic Republic of Timor-Leste',
    value: 'TL',
    timezone: 'Tokyo Standard Time',
    utc: 'UTC+09:00',
    mobileCode: '+670'
  },
  {
    name: 'Denmark',
    value: 'DK',
    timezone: 'Romance Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+45'
  },
  {
    name: 'Djibouti',
    value: 'DJ',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+253'
  },
  {
    name: 'Dominica',
    value: 'DM',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-767'
  },
  {
    name: 'Dominican Republic',
    value: 'DO',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-809 and 1-829'
  },
  {
    name: 'Ecuador',
    value: 'EC',
    timezone: 'SA Pacific Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+593'
  },
  {
    name: 'Egypt',
    value: 'EG',
    timezone: 'Egypt Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+20'
  },
  {
    name: 'El Salvador',
    value: 'SV',
    timezone: 'Central America Standard Time',
    utc: 'UTC-06:00',
    mobileCode: '+503'
  },
  {
    name: 'Equatorial Guinea',
    value: 'GQ',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+240'
  },
  {
    name: 'Eritrea',
    value: 'ER',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+291'
  },
  {
    name: 'Estonia',
    value: 'EE',
    timezone: 'FLE Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+372'
  },
  {
    name: 'Ethiopia',
    value: 'ET',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+251'
  },
  {
    name: 'Falkland Islands (Islas Malvinas)',
    value: 'FK',
    timezone: 'SA Eastern Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+500'
  },
  {
    name: 'Faroe Islands',
    value: 'FO',
    timezone: 'GMT Standard Time',
    utc: 'UTC',
    mobileCode: '+298'
  },
  {
    name: 'Fiji Islands',
    value: 'FJ',
    timezone: 'Fiji Standard Time',
    utc: 'UTC+12:00',
    mobileCode: '+679'
  },
  {
    name: 'Finland',
    value: 'FI',
    timezone: 'FLE Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+358'
  },
  {
    name: 'France',
    value: 'FR',
    timezone: 'Romance Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+33'
  },
  {
    name: 'French Guiana',
    value: 'GF',
    timezone: 'SA Eastern Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+594'
  },
  {
    name: 'French Polynesia',
    value: 'PF',
    timezone: 'Hawaiian Standard Time',
    utc: 'UTC-10:00',
    mobileCode: '+689'
  },
  {
    name: 'French Southern and Antarctic Lands',
    value: 'TF',
    timezone: 'West Asia Standard Time',
    utc: 'UTC+05:00',
    mobileCode: '+'
  },
  {
    name: 'Gabon',
    value: 'GA',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+241'
  },
  {
    name: 'Gambia, The',
    value: 'GM',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+220'
  },
  {
    name: 'Georgia',
    value: 'GE',
    timezone: 'Georgian Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+995'
  },
  {
    name: 'Germany',
    value: 'DE',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+49'
  },
  {
    name: 'Ghana',
    value: 'GH',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+233'
  },
  {
    name: 'Gibraltar',
    value: 'GI',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+350'
  },
  {
    name: 'Greece',
    value: 'GR',
    timezone: 'GTB Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+30'
  },
  {
    name: 'Greenland',
    value: 'GL',
    timezone: 'Greenland Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+299'
  },
  {
    name: 'Grenada',
    value: 'GD',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-473'
  },
  {
    name: 'Guadeloupe',
    value: 'GP',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+590'
  },
  {
    name: 'Guam',
    value: 'GU',
    timezone: 'West Pacific Standard Time',
    utc: 'UTC+10:00',
    mobileCode: '+1-671'
  },
  {
    name: 'Guatemala',
    value: 'GT',
    timezone: 'Central America Standard Time',
    utc: 'UTC-06:00',
    mobileCode: '+502'
  },
  {
    name: 'Guernsey',
    value: 'GG',
    timezone: 'GMT Standard Time',
    utc: 'UTC',
    mobileCode: '+44-1481'
  },
  {
    name: 'Guinea',
    value: 'GN',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+224'
  },
  {
    name: 'Guinea-Bissau',
    value: 'GW',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+245'
  },
  {
    name: 'Guyana',
    value: 'GY',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+592'
  },
  {
    name: 'Haiti',
    value: 'HT',
    timezone: 'Eastern Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+509'
  },
  {
    name: 'Heard Island and McDonald Islands',
    value: 'HM',
    timezone: 'Mauritius Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+ '
  },
  {
    name: 'Honduras',
    value: 'HN',
    timezone: 'Central America Standard Time',
    utc: 'UTC-06:00',
    mobileCode: '+504'
  },
  {
    name: 'Hong Kong SAR',
    value: 'HK',
    timezone: 'China Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+852'
  },
  {
    name: 'Hungary',
    value: 'HU',
    timezone: 'Central Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+36'
  },
  {
    name: 'Iceland',
    value: 'IS',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+354'
  },
  {
    name: 'India',
    value: 'IN',
    timezone: 'India Standard Time',
    utc: 'UTC+05:30',
    mobileCode: '+91'
  },
  {
    name: 'Indonesia',
    value: 'ID',
    timezone: 'SE Asia Standard Time',
    utc: 'UTC+07:00',
    mobileCode: '+62'
  },
  {
    name: 'Iran',
    value: 'IR',
    timezone: 'Iran Standard Time',
    utc: 'UTC+03:30',
    mobileCode: '+98'
  },
  {
    name: 'Iraq',
    value: 'IQ',
    timezone: 'Arabic Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+964'
  },
  {
    name: 'Ireland',
    value: 'IE',
    timezone: 'GMT Standard Time',
    utc: 'UTC',
    mobileCode: '+353'
  },
  {
    name: 'Israel',
    value: 'IL',
    timezone: 'Israel Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+972'
  },
  {
    name: 'Italy',
    value: 'IT',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+39'
  },
  {
    name: 'Jamaica',
    value: 'JM',
    timezone: 'SA Pacific Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+1-876'
  },
  {
    name: 'Jan Mayen',
    value: 'SJ',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+47'
  },
  {
    name: 'Japan',
    value: 'JP',
    timezone: 'Tokyo Standard Time',
    utc: 'UTC+09:00',
    mobileCode: '+81'
  },
  {
    name: 'Jersey',
    value: 'JE',
    timezone: 'GMT Standard Time',
    utc: 'UTC',
    mobileCode: '+44-1534'
  },
  {
    name: 'Jordan',
    value: 'JO',
    timezone: 'Jordan Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+962'
  },
  {
    name: 'Kazakhstan',
    value: 'KZ',
    timezone: 'Central Asia Standard Time',
    utc: 'UTC+06:00',
    mobileCode: '+7'
  },
  {
    name: 'Kenya',
    value: 'KE',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+254'
  },
  {
    name: 'Kiribati',
    value: 'KI',
    timezone: 'UTC+12',
    utc: 'UTC+12:00',
    mobileCode: '+686'
  },
  {
    name: 'Korea',
    value: 'KR',
    timezone: 'Korea Standard Time',
    utc: 'UTC+09:00',
    mobileCode: '+82'
  },
  {
    name: 'Kosovo',
    value: 'XK',
    timezone: 'Central European Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+'
  },
  {
    name: 'Kuwait',
    value: 'KW',
    timezone: 'Arab Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+965'
  },
  {
    name: 'Kyrgyzstan',
    value: 'KG',
    timezone: 'Central Asia Standard Time',
    utc: 'UTC+06:00',
    mobileCode: '+996'
  },
  {
    name: 'Laos',
    value: 'LA',
    timezone: 'SE Asia Standard Time',
    utc: 'UTC+07:00',
    mobileCode: '+856'
  },
  {
    name: 'Latvia',
    value: 'LV',
    timezone: 'FLE Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+371'
  },
  {
    name: 'Lebanon',
    value: 'LB',
    timezone: 'Middle East Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+961'
  },
  {
    name: 'Lesotho',
    value: 'LS',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+266'
  },
  {
    name: 'Liberia',
    value: 'LR',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+231'
  },
  {
    name: 'Libya',
    value: 'LY',
    timezone: 'E. Europe Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+218'
  },
  {
    name: 'Liechtenstein',
    value: 'LI',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+423'
  },
  {
    name: 'Lithuania',
    value: 'LT',
    timezone: 'FLE Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+370'
  },
  {
    name: 'Luxembourg',
    value: 'LU',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+352'
  },
  {
    name: 'Macao SAR',
    value: 'MO',
    timezone: 'China Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+853'
  },
  {
    name: 'Macedonia, Former Yugoslav Republic of',
    value: 'MK',
    timezone: 'Central European Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+389'
  },
  {
    name: 'Madagascar',
    value: 'MG',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+261'
  },
  {
    name: 'Malawi',
    value: 'MW',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+265'
  },
  {
    name: 'Malaysia',
    value: 'MY',
    timezone: 'Singapore Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+60'
  },
  {
    name: 'Maldives',
    value: 'MV',
    timezone: 'West Asia Standard Time',
    utc: 'UTC+05:00',
    mobileCode: '+960'
  },
  {
    name: 'Mali',
    value: 'ML',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+223'
  },
  {
    name: 'Malta',
    value: 'MT',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+356'
  },
  {
    name: 'Man, Isle of',
    value: 'IM',
    timezone: 'GMT Standard Time',
    utc: 'UTC',
    mobileCode: '+44-1624'
  },
  {
    name: 'Marshall Islands',
    value: 'MH',
    timezone: 'UTC+12',
    utc: 'UTC+12:00',
    mobileCode: '+692'
  },
  {
    name: 'Martinique',
    value: 'MQ',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+596'
  },
  {
    name: 'Mauritania',
    value: 'MR',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+222'
  },
  {
    name: 'Mauritius',
    value: 'MU',
    timezone: 'Mauritius Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+230'
  },
  {
    name: 'Mayotte',
    value: 'YT',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+262'
  },
  {
    name: 'Mexico',
    value: 'MX',
    timezone: 'Central Standard Time (Mexico)',
    utc: 'UTC-06:00',
    mobileCode: '+52'
  },
  {
    name: 'Micronesia',
    value: 'FM',
    timezone: 'West Pacific Standard Time',
    utc: 'UTC+10:00',
    mobileCode: '+691'
  },
  {
    name: 'Moldova',
    value: 'MD',
    timezone: 'GTB Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+373'
  },
  {
    name: 'Monaco',
    value: 'MC',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+377'
  },
  {
    name: 'Mongolia',
    value: 'MN',
    timezone: 'Ulaanbaatar Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+976'
  },
  {
    name: 'Montenegro',
    value: 'ME',
    timezone: 'Central European Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+382'
  },
  {
    name: 'Montserrat',
    value: 'MS',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-664'
  },
  {
    name: 'Morocco',
    value: 'MA',
    timezone: 'Morocco Standard Time',
    utc: 'UTC',
    mobileCode: '+212'
  },
  {
    name: 'Mozambique',
    value: 'MZ',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+258'
  },
  {
    name: 'Myanmar',
    value: 'MM',
    timezone: 'Myanmar Standard Time',
    utc: 'UTC+06:30',
    mobileCode: '+95'
  },
  {
    name: 'Namibia',
    value: 'NA',
    timezone: 'Namibia Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+264'
  },
  {
    name: 'Nauru',
    value: 'NR',
    timezone: 'UTC+12',
    utc: 'UTC+12:00',
    mobileCode: '+674'
  },
  {
    name: 'Nepal',
    value: 'NP',
    timezone: 'Nepal Standard Time',
    utc: 'UTC+05:45',
    mobileCode: '+977'
  },
  {
    name: 'Netherlands',
    value: 'NL',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+31'
  },
  {
    name: 'New Caledonia',
    value: 'NC',
    timezone: 'Central Pacific Standard Time',
    utc: 'UTC+11:00',
    mobileCode: '+687'
  },
  {
    name: 'New Zealand',
    value: 'NZ',
    timezone: 'New Zealand Standard Time',
    utc: 'UTC+12:00',
    mobileCode: '+64'
  },
  {
    name: 'Nicaragua',
    value: 'NI',
    timezone: 'Central America Standard Time',
    utc: 'UTC-06:00',
    mobileCode: '+505'
  },
  {
    name: 'Niger',
    value: 'NE',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+227'
  },
  {
    name: 'Nigeria',
    value: 'NG',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+234'
  },
  {
    name: 'Niue',
    value: 'NU',
    timezone: 'UTC-11',
    utc: 'UTC-11:00',
    mobileCode: '+683'
  },
  {
    name: 'Norfolk Island',
    value: 'NF',
    timezone: 'Central Pacific Standard Time',
    utc: 'UTC+11:00',
    mobileCode: '+672'
  },
  {
    name: 'North Korea',
    value: 'KP',
    timezone: 'Korea Standard Time',
    utc: 'UTC+09:00',
    mobileCode: '+850'
  },
  {
    name: 'Northern Mariana Islands',
    value: 'MP',
    timezone: 'West Pacific Standard Time',
    utc: 'UTC+10:00',
    mobileCode: '+1-670'
  },
  {
    name: 'Norway',
    value: 'NO',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+47'
  },
  {
    name: 'Oman',
    value: 'OM',
    timezone: 'Arabian Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+968'
  },
  {
    name: 'Pakistan',
    value: 'PK',
    timezone: 'Pakistan Standard Time',
    utc: 'UTC+05:00',
    mobileCode: '+92'
  },
  {
    name: 'Palau',
    value: 'PW',
    timezone: 'Tokyo Standard Time',
    utc: 'UTC+09:00',
    mobileCode: '+680'
  },
  {
    name: 'Palestinian Authority',
    value: 'PS',
    timezone: 'Egypt Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+970'
  },
  {
    name: 'Panama',
    value: 'PA',
    timezone: 'SA Pacific Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+507'
  },
  {
    name: 'Papua New Guinea',
    value: 'PG',
    timezone: 'West Pacific Standard Time',
    utc: 'UTC+10:00',
    mobileCode: '+675'
  },
  {
    name: 'Paraguay',
    value: 'PY',
    timezone: 'Paraguay Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+595'
  },
  {
    name: 'Peru',
    value: 'PE',
    timezone: 'SA Pacific Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+51'
  },
  {
    name: 'Philippines',
    value: 'PH',
    timezone: 'Singapore Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+63'
  },
  {
    name: 'Pitcairn Islands',
    value: 'PN',
    timezone: 'Pacific Standard Time',
    utc: 'UTC-08:00',
    mobileCode: '+870'
  },
  {
    name: 'Poland',
    value: 'PL',
    timezone: 'Central European Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+48'
  },
  {
    name: 'Portugal',
    value: 'PT',
    timezone: 'GMT Standard Time',
    utc: 'UTC',
    mobileCode: '+351'
  },
  {
    name: 'Puerto Rico',
    value: 'PR',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-787 and 1-939'
  },
  {
    name: 'Qatar',
    value: 'QA',
    timezone: 'Arab Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+974'
  },
  {
    name: 'Reunion',
    value: 'RE',
    timezone: 'Mauritius Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+262'
  },
  {
    name: 'Romania',
    value: 'RO',
    timezone: 'GTB Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+40'
  },
  {
    name: 'Russia',
    value: 'RU',
    timezone: 'Russian Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+7'
  },
  {
    name: 'Rwanda',
    value: 'RW',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+250'
  },
  {
    name: 'Saint Barthélemy',
    value: 'BL',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+590'
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'SH',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+290'
  },
  {
    name: 'Saint Kitts and Nevis',
    value: 'KN',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-869'
  },
  {
    name: 'Saint Lucia',
    value: 'LC',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-758'
  },
  {
    name: 'Saint Martin (French part)',
    value: 'MF',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+590'
  },
  {
    name: 'Saint Pierre and Miquelon',
    value: 'PM',
    timezone: 'Greenland Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+508'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    value: 'VC',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-784'
  },
  {
    name: 'Samoa',
    value: 'WS',
    timezone: 'Samoa Standard Time',
    utc: 'UTC+13:00',
    mobileCode: '+685'
  },
  {
    name: 'San Marino',
    value: 'SM',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+378'
  },
  {
    name: 'São Tomé and Príncipe',
    value: 'ST',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+239'
  },
  {
    name: 'Saudi Arabia',
    value: 'SA',
    timezone: 'Arab Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+966'
  },
  {
    name: 'Senegal',
    value: 'SN',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+221'
  },
  {
    name: 'Serbia',
    value: 'RS',
    timezone: 'Central Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+381'
  },
  {
    name: 'Seychelles',
    value: 'SC',
    timezone: 'Mauritius Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+248'
  },
  {
    name: 'Sierra Leone',
    value: 'SL',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+232'
  },
  {
    name: 'Singapore',
    value: 'SG',
    timezone: 'Singapore Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+65'
  },
  {
    name: 'Sint Maarten (Dutch part)',
    value: 'SX',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+599'
  },
  {
    name: 'Slovakia',
    value: 'SK',
    timezone: 'Central Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+421'
  },
  {
    name: 'Slovenia',
    value: 'SI',
    timezone: 'Central Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+386'
  },
  {
    name: 'Solomon Islands',
    value: 'SB',
    timezone: 'Central Pacific Standard Time',
    utc: 'UTC+11:00',
    mobileCode: '+677'
  },
  {
    name: 'Somalia',
    value: 'SO',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+252'
  },
  {
    name: 'South Africa',
    value: 'ZA',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+27'
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    value: 'GS',
    timezone: 'UTC-02',
    utc: 'UTC-02:00',
    mobileCode: '+'
  },
  {
    name: 'South Sudan',
    value: 'SS',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+211'
  },
  {
    name: 'Spain',
    value: 'ES',
    timezone: 'Romance Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+34'
  },
  {
    name: 'Sri Lanka',
    value: 'LK',
    timezone: 'Sri Lanka Standard Time',
    utc: 'UTC+05:30',
    mobileCode: '+94'
  },
  {
    name: 'Sudan',
    value: 'SD',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+249'
  },
  {
    name: 'Suriname',
    value: 'SR',
    timezone: 'SA Eastern Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+597'
  },
  // {
  //   name: "Svalbard",
  //   value: "SJ",
  //   timezone: "W. Europe Standard Time",
  //   utc: "UTC+01:00",
  //   mobileCode: "+47",
  // },
  {
    name: 'Swaziland',
    value: 'SZ',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+268'
  },
  {
    name: 'Sweden',
    value: 'SE',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+46'
  },
  {
    name: 'Switzerland',
    value: 'CH',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+41'
  },
  {
    name: 'Syria',
    value: 'SY',
    timezone: 'Syria Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+963'
  },
  {
    name: 'Taiwan',
    value: 'TW',
    timezone: 'Taipei Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+886'
  },
  {
    name: 'Tajikistan',
    value: 'TJ',
    timezone: 'West Asia Standard Time',
    utc: 'UTC+05:00',
    mobileCode: '+992'
  },
  {
    name: 'Tanzania',
    value: 'TZ',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+255'
  },
  {
    name: 'Thailand',
    value: 'TH',
    timezone: 'SE Asia Standard Time',
    utc: 'UTC+07:00',
    mobileCode: '+66'
  },
  {
    name: 'Togo',
    value: 'TG',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+228'
  },
  {
    name: 'Tokelau',
    value: 'TK',
    timezone: 'Tonga Standard Time',
    utc: 'UTC+13:00',
    mobileCode: '+690'
  },
  {
    name: 'Tonga',
    value: 'TO',
    timezone: 'Tonga Standard Time',
    utc: 'UTC+13:00',
    mobileCode: '+676'
  },
  {
    name: 'Trinidad and Tobago',
    value: 'TT',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-868'
  },
  {
    name: 'Tunisia',
    value: 'TN',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+216'
  },
  {
    name: 'Turkey',
    value: 'TR',
    timezone: 'Turkey Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+90'
  },
  {
    name: 'Turkmenistan',
    value: 'TM',
    timezone: 'West Asia Standard Time',
    utc: 'UTC+05:00',
    mobileCode: '+993'
  },
  {
    name: 'Turks and Caicos Islands',
    value: 'TC',
    timezone: 'Eastern Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+1-649'
  },
  {
    name: 'Tuvalu',
    value: 'TV',
    timezone: 'UTC+12',
    utc: 'UTC+12:00',
    mobileCode: '+688'
  },
  {
    name: 'U.S. Minor Outlying Islands',
    value: 'UM',
    timezone: 'UTC-11',
    utc: 'UTC-11:00',
    mobileCode: '+1'
  },
  {
    name: 'Uganda',
    value: 'UG',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+256'
  },
  {
    name: 'Ukraine',
    value: 'UA',
    timezone: 'FLE Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+380'
  },
  {
    name: 'United Arab Emirates',
    value: 'AE',
    timezone: 'Arabian Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+971'
  },
  {
    name: 'United Kingdom',
    value: 'GB',
    timezone: 'GMT Standard Time',
    utc: 'UTC',
    mobileCode: '+44'
  },
  {
    name: 'United States',
    value: 'US',
    timezone: 'Pacific Standard Time',
    utc: 'UTC-08:00',
    mobileCode: '+1'
  },
  {
    name: 'Uruguay',
    value: 'UY',
    timezone: 'Montevideo Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+598'
  },
  {
    name: 'Uzbekistan',
    value: 'UZ',
    timezone: 'West Asia Standard Time',
    utc: 'UTC+05:00',
    mobileCode: '+998'
  },
  {
    name: 'Vanuatu',
    value: 'VU',
    timezone: 'Central Pacific Standard Time',
    utc: 'UTC+11:00',
    mobileCode: '+678'
  },
  {
    name: 'Vatican City',
    value: 'VA',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+379'
  },
  {
    name: 'Vietnam',
    value: 'VN',
    timezone: 'SE Asia Standard Time',
    utc: 'UTC+07:00',
    mobileCode: '+84'
  },
  {
    name: 'Virgin Islands, U.S.',
    value: 'VI',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-340'
  },
  {
    name: 'Virgin Islands, British',
    value: 'VG',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-284'
  },
  {
    name: 'Wallis and Futuna',
    value: 'WF',
    timezone: 'UTC+12',
    utc: 'UTC+12:00',
    mobileCode: '+681'
  },
  {
    name: 'Yemen',
    value: 'YE',
    timezone: 'Arab Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+967'
  },
  {
    name: 'Zambia',
    value: 'ZM',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+260'
  },
  {
    name: 'Zimbabwe',
    value: 'ZW',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+263'
  }
];
