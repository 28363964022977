import React from 'react'
import {useState, useEffect} from 'react'
import { BsSearch } from 'react-icons/bs';
import { BsFillPersonPlusFill } from 'react-icons/bs';
import UserFormModal from '../Users/UserFormModal';
import UpdateModal from '../Users/UserUpdateModal';
import { UsersService, useUserState } from '../../../Service/userServices';
import { GrFormPrevious } from 'react-icons/gr';
import { MdOutlineNavigateNext } from 'react-icons/md';
import UsersDeleteModal from '../Users/UsersDeleteModal';
import { baseUrl } from '../../../baseUrl';
import {AiOutlineCheckCircle, AiOutlineCloseCircle}  from 'react-icons/ai';
import axios from 'axios';
import { useAuth } from '../../../Context/AuthContext';
import { useBrandState, BrandService } from '../../../Service/BrandServices';
import { useHistory } from 'react-router-dom';
import PaymentHistory from './PaymentHistory';
import {toast, Toaster} from 'react-hot-toast';
import Loader from '../../Loader2';
import CustomButton from '../../../utils/customButton';


function Balances() {
  const [OpenProfileModal, setOpenProfileModal] = useState(false);
  const [OpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [OpenAddUserModal, setOpenAddUserModal] = useState(false);
  const [selectedId, SetSelectedId] = useState('');
  const userState = useUserState();
  const [selectedUserData, setSelectedUserData] = useState();
  const [display, setDisplay] = useState();
  const [currentPage, setcurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [itemsPerPage] = useState(8);
  const [userData, setUserData] = useState('');
  const [paymentHistory, setPaymentHistory] = useState(false);
  const { currentBrand, currentUser } = useAuth();
  const history = useHistory();

  const brandState = useBrandState();

  const handlePaymentClick = (userData) => {
    setPaymentHistory(true);
    setSelectedUserData(userData);
  };

  useEffect(() => {
    if (currentUser.uid !== null) {
      BrandService.FetchSingleBrand(currentUser.uid);
    }
  }, [brandState.updateNeed.value]);

  const brand = brandState.brand.value;

  useEffect(() => {
    fetch('https://us-central1-crafted-dev-v1.cloudfunctions.net/getBalancedUsers')
      .then(response => response.json())
      .then(data => setUserData(data))
      .catch(error => console.error(error));
  }, []);

    const handleMarkAsPaid = async (userId) => {
      try {
        console.log("userId",userId)
        await axios.put(`${baseUrl}api/users/${userId}/paid`);
        // Handle success or any other logic after marking the user as paid
        toast.success('User marked as paid successfully!');
        //  window.location.reload();
      } catch (error) {
        // Handle error if the API request fails
        console.error(error);
      }
    };
    
    //const pages = Math.ceil(Userdata.length / itemsPerPage);
    
   {/*  const getPaginatedData = () => {
      const startIndex = currentPage * itemsPerPage - itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      
      // Sorting from highest to lowest 
      const filteredData = Userdata.filter((com) => com[Object.keys(com)].creator_balance > 0);
      const sortedData = filteredData.sort((a, b) => {
        const balanceA = a[Object.keys(a)].creator_balance;
        const balanceB = b[Object.keys(b)].creator_balance;
        return balanceB - balanceA;
      });
      
      console.log(filteredData);
      return sortedData.slice(startIndex, endIndex);
    };
     let data = Userdata.filter((com) => com[Object.keys(com)].creator_balance > 0);
     data = data.sort((a, b) => {
      const balanceA = a[Object.keys(a)].creator_balance;
      const balanceB = b[Object.keys(b)].creator_balance;
      return balanceB - balanceA; 
     })
    
    let arr = [];
    let arrId = [];
    //console.log(Userdata);
    data.forEach((user, i) => {
      const id = Object.keys(user)[0]
      if ((typeof user[id] === 'string' || typeof user[id] === 'boolean') || user[id].brand === true){
      }else{
        arrId.push(id)
        arr.push(user[id])
      }
    })
    arr = arr.map((object, index) => ({
      ...object,
      index: index
    }));


  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const results = arr.filter((user) => {
      const {
        bio,
        email,
        profile_name,
        username,
        paypal_email,
        name,
        shipping_details,
        creator_balance
      } = user;
      const { address1, address2, city, country, state, zipcode } =
        shipping_details || {};
      const lowerCaseValue = value.toLowerCase();
      // Convert search value to lowercase
      // Search by bio, email, profile name, username, name, and shipping details (case-insensitive)
      return (
        (bio && bio.toLowerCase().includes(lowerCaseValue)) ||
        (email && email.toLowerCase().includes(lowerCaseValue)) ||
        (paypal_email && paypal_email.toLowerCase().includes(lowerCaseValue)) ||
        (profile_name && profile_name.toLowerCase().includes(lowerCaseValue)) ||
        (username && username.toLowerCase().includes(lowerCaseValue)) ||
        (name && name.toLowerCase().includes(lowerCaseValue)) ||
        (address1 && address1.toLowerCase().includes(lowerCaseValue)) ||
        (address2 && address2.toLowerCase().includes(lowerCaseValue)) ||
        (city && city.toLowerCase().includes(lowerCaseValue)) ||
        (country && country.toLowerCase().includes(lowerCaseValue)) ||
        (state && state.toLowerCase().includes(lowerCaseValue)) ||
        (zipcode && zipcode.toLowerCase().includes(lowerCaseValue)) ||
        (creator_balance && creator_balance.toString().includes(lowerCaseValue))
      );
    });
    setSearchResults(results);
    console.log("search results", searchResults);
    if (searchResults.length === 0) {
      // setError("No results found")
    } else {
      // setError('')
    }
  }
  ;

  function goToNextPage() {
    if (data.length < itemsPerPage) {
      return;
    } else {
      setcurrentPage((page) => page + 1);
    }
  }
  function goToPreviousPage() {
    if (currentPage === 1) {
      return;
    } else {
      setcurrentPage((page) => page - 1);
    }
  }*/}
  return (
    <>
 
    <div className="bg-white m-4 rounded-lg w-full " style={{ height: 'calc(95vh - 5rem)'}}>
      <Toaster/>
      <h2 className="font-semibold py-2 text-2xl ml-4"> Creator Payouts</h2>
      <div className="-my-2 py-2  sm:-mx-6 sm:px-6 lg:-mx-8 pr-10 lg:px-8 h-47">
      <div className="align-middle rounded-tl-lg rounded-tr-lg inline-block w-full py-4  mt-4 bg-white  px-12">
           
          <div className="flex justify-between">
            <div className="inline-flex border rounded w-7/12 px-2 lg:px-6 h-12 bg-transparent">
              <div className="flex justify-between items-center w-full h-full mb-6 relative">
                <div className="flex">
                  <span className="flex items-center leading-normal bg-transparent rounded rounded-r-none border border-r-0 border-none lg:px-3 py-2 whitespace-no-wrap text-grey-dark text-sm">
                    <BsSearch />
                  </span>
                </div>
                <input
                  type="text"
                  className="flex-shrink flex-grow flex-auto leading-normal tracking-wide w-px flex-1 border border-none border-l-0 rounded rounded-l-none px-3 relative focus:outline-none text-xxs lg:text-xs lg:text-base text-gray-500 font-thin"
                  placeholder="Search"
                  onChange='{handleSearch}'
                  value={searchTerm}
                />
              </div>
            </div>
           
          </div>
        </div>
        <div className="align-middle inline-block min-w-full shadow h-5/6 overflow-y-auto bg-white shadow-dashboard px-8 pt-3 rounded-bl-lg rounded-br-lg">
          <table className="min-w-full overflow-scroll">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-blue-500 tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3  border-b-2 border-gray-300 text-left text-sm leading-4 text-blue-500 tracking-wider">
                  Full Name
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-blue-500 tracking-wider">
                  Stripe
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-blue-500 tracking-wider">
                  Balance
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-blue-500 tracking-wider">
                  Action
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300"></th>
              </tr>
            </thead>
              {
                 (searchTerm.length === 0 && searchResults.length >= 0)?
                 <tbody className="bg-white overflow-y-scroll h-fit">
                 {userData && userData.result  ?
                  (userData && userData.result 
                .slice() // Create a shallow copy of the array to prevent mutation
                .sort((a, b) => b.creator_balance - a.creator_balance) // Sort users by balance in descending order
                .map((user, i) => {

                  return (
                    <tr key={i} className='cursor-pointer hover:bg-gray-300' onClick={() => handlePaymentClick(user)}>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                        <div className="flex items-center">
                          <div>
                            <div className="text-sm leading-5 text-gray-800">{user.paypal_email || user.email}</div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                        <div className="text-sm leading-5 text-blue-900">{user && user.fullname}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b text-blue-900 border-gray-500 text-sm leading-5">
                        {user.STRIPE_CONNECTED_ACCOUNT_ID ? <AiOutlineCheckCircle className='w-5 h-5 bg-green rounded-full' /> : <AiOutlineCloseCircle className='w-5 h-5 bg-red outline-white rounded-full' />}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b text-blue-900 border-gray-500 text-sm leading-5">
                        {"$" + (user.creator_balance.toFixed(2) / 100)}
                      </td>
                      <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500 text-blue-900 text-sm leading-5">
                        <CustomButton
                          className="px-5 py-2  z-50 text-white focus:outline-none"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevents the click event from propagating to the parent <tr>
                            handleMarkAsPaid(user.key); // Passing the index i as user identifier
                          }}>
                          Mark as Paid
                        </CustomButton>
                      </td>
                    </tr>
                  );
                }))
                :  
                (<div className='w-full flex flex-col ml-22rem items-center justify-center align-middle'>
                  <Loader/>
                    <h1 className='mt-2 flex mx-auto items-center justify-center'>Loading Users</h1>
                </div>
                )}
              </tbody>:
                  searchResults.map((data, i)=>{
                    if (display){
                      setDisplay(false)
                    }
                    return (
                      <tbody key={i}>
                      <tr >
                        
                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                        <div className="text-sm leading-5 text-blue-900">{data && data.paypal_email || data && data.email}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                        <div className="text-sm leading-5 text-blue-900">{data && data.fullname}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap border-b text-blue-900 border-gray-500 text-sm leading-5">
                        {data && data.STRIPE_CONNECTED_ACCOUNT_ID ? <AiOutlineCheckCircle className='w-5 h-5 bg-green rounded-full'/> : <AiOutlineCloseCircle className='w-5 h-5 bg-red rounded-full'/>}
                          
                          
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap border-b text-blue-900 border-gray-500 text-sm leading-5">
                          {
                            "$" + data.creator_balance.toFixed(2) / 100
                          }
                        </td>
                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500 text-blue-900 text-sm leading-5">
                          <CustomButton
                            className="px-5 py-2  text-white  focus:outline-none"
                            onClick={() => handleMarkAsPaid(data.key)}>
                            Mark as Paid
                          </CustomButton>
                        </td>

                      </tr>
                      </tbody>
                    );
                  })
              }
          </table>
        </div>
        </div>
        {OpenProfileModal ? (
          <UpdateModal
            closeAction={() => {
              setOpenProfileModal(false);
            }}
            userId={selectedId}
            data={userData}
          />
        ) : null}
       {/*  {display && <div className="flex items-center justify-center mt-4" style={{marginTop:"100px"}}>
                <button
                  onClick={() => goToPreviousPage()}
                  className="mr-8 cursor-pointer bg-red text-white py-1 px-2 rounded-lg">
                  <GrFormPrevious className="inline text-white fill-current" />
                  Previous
                </button>
                <div className="mr-4 font-bold">
                  {currentPage} - {pages}
                </div>
                <button
                  onClick={() => goToNextPage()}
                  className="cursor-pointer bg-red text-white py-1 px-2 rounded-lg">
                  Next
                  <MdOutlineNavigateNext className="inline text-white fill-current" />
                </button>
              </div>}*/}

      </div>
      {paymentHistory ? (
      <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center item-center z-50 bg-black bg-opacity-50">
      
        <PaymentHistory
          UserData = {selectedUserData}
          onClose={() => {
            setPaymentHistory(false);
          }}
        />
      
      </div>
      ) : null}

    </>
  )
}

export default Balances