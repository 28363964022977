import React from "react";
import checkMark from '../../Assets/Eo_circle_green_checkmark.png';

function PayoutConfirmation(props) {

  return (
    <div className="flex flex-col items-center p-6">
      <div className="flex justify-center my-10">
        <img src={checkMark} alt="Confirmation Checkmark" className="w-24 h-24"/>
      </div>

      <div className="text-center space-y-4">
        <h1 className="text-2xl font-bold">Payment details confirmed!</h1>
        <p className="text-gray-600">
          Expect a <span className="font-bold">{props.selectedOption}</span> payout to <span className="font-bold">{props.selectedOption === "Venmo" ? `@${props.inputValue}` : props.inputValue}</span> within 3-5 business days. You will receive a text message once payment is remitted.
        </p>
        <p className="text-gray-500">
          You can close this tab now.
        </p>
      </div>
    </div>
  );
}

export default PayoutConfirmation;
