import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';

const state = createState({
    creator: [],
    creators: [],
    retrieving: false,
    fetched: false,
    loading: true,
    updateNeed: true
  });
                  

  store.receptors.push((action) => {
    state.batch((s) => {
      switch (action.type) {
        // Add your existing case statements here
  
        case 'TIKTOK_ONBOARDING':
          return s.merge({
            tiktokOnboardingData: action.data,
            updateNeed: false,
            loading: false,
          });
  
        case 'GET_TIKTOK_TOKENS':
          return s.merge({
            tiktokTokens: action.data,
            updateNeed: false,
            loading: false,
          });
        
        case 'FETCH_INFLUENCER_NETWORK':
          return s.merge({
            creator: action.data,
            updateNeed: false,
            retrieving: true,
            fetched: true
          })
        case 'GET_TIKTOK_ACCESS_TOKEN':
          return s.merge({
            tiktokAccessToken: action.data,
            updateNeed: false,
            loading: false,
          });
  
        case 'GET_TIKTOK_REFRESH_TOKEN':
          return s.merge({
            tiktokRefreshToken: action.data,
            updateNeed: false,
            loading: false,
          });
  
        case 'REFRESH_TIKTOK_TOKENS':
          return s.merge({
            tiktokTokens: action.data, // Assuming you want to refresh the existing tokens
            updateNeed: false,
            loading: false,
          });
  
        case 'GET_TOTAL_VIEW_COUNT':
          return s.merge({
            totalViewCount: action.data,
            updateNeed: false,
            loading: false,
          });
  
        case 'FIND_CAMPAIGN_CREATORS':
          return s.merge({
            campaignCreators: action.data,
            updateNeed: false,
            loading: false,
          });
  
        case 'GET_VIDEO_ANALYTICS':
          return s.merge({
            videoAnalytics: action.data,
            updateNeed: false,
            loading: false,
          });
  
        case 'get_suggested_rate':
          return s.merge({
            suggestedRate: action.data,
            updateNeed: false,
            loading: false,
          });
  
        case 'GET_INFLUENCER_PRICE':
          return s.merge({
            influencerPrice: action.data,
            updateNeed: false,
            loading: false,
          });
  
        case 'ACCOUNT_UNLINKED':
          return s.merge({
            accountUnlinked: action.data,
            updateNeed: false,
            loading: false,
          });
  
        // Add additional cases as necessary
        default:
          // Handle any other actions or return the current state by default
          return s;
      }
    });
  });
    
    export const accessInfluencerState = () => state;

export const useInfluencerState = () => useState(state);

export const InfluencerService = {

  SendRevisions: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/campaigns/influencer/${id}/sendRevisions`, data)
      .then((res) => {
        dispatch(InfluencerAction.sendRevisions(res.data));
      })
      },

      TiktokOnboarding: (id, data) => {
        const dispatch = useDispatch();
        axios
          .get(`${baseUrl}api/influencers/tiktok/onboarding/${id}`, data)
          .then((res) => {
            dispatch(InfluencerAction.tiktokOnboarding(res.data));
          });
      },
    
      GetTikTokTokens: (creatorId) => {
        const dispatch = useDispatch();
        axios
          .get(`${baseUrl}api/influencers/tiktok/${creatorId}`)
          .then((res) => {
            dispatch(InfluencerAction.getTikTokTokens(res.data));
          });
      },
    
      GetTiktokAccessToken: (creatorId) => {
        const dispatch = useDispatch();
        axios
          .get(`${baseUrl}api/influencers/tiktok/access_token/${creatorId}`)
          .then((res) => {
            dispatch(InfluencerAction.getTiktokAccessToken(res.data));
          });
      },
    
      GetTiktokRefreshToken: (creatorId) => {
        const dispatch = useDispatch();
        axios
          .get(`${baseUrl}api/influencers/tiktok/refresh_token/${creatorId}`)
          .then((res) => {
            dispatch(InfluencerAction.getTiktokRefreshToken(res.data));
          });
      },
    
      RefreshTiktokAccessTokens: (data) => {
        const dispatch = useDispatch();
          axios
          .post(`${baseUrl}api/influencers/tiktok/refresh`, data)
          .then((res) => {
            dispatch(InfluencerAction.refreshTiktokAccessTokens(res.data));
          });
      },
    
      GetTotalViewCount: (creatorId, data) => {
        const dispatch = useDispatch();
        axios
          .post(`${baseUrl}api/influencers/tiktok/${creatorId}/view_count`, data)
          .then((res) => {
            dispatch(InfluencerAction.getTotalViewCount(res.data));
          });
      },
    
      FindCreatorsForCampaign: (data) => {
        const dispatch = useDispatch();
        axios
          .post(`${baseUrl}api/influencers/findCreators`, data)
          .then((res) => {
            dispatch(InfluencerAction.findCreatorsForCampaign(res.data));
          });
      },
    
      GetVideoAnalytics: (campaignId) => {
        const dispatch = useDispatch();
          axios
          .get(`${baseUrl}api/campaigns/${campaignId}/getVideoAnalytics`)
          .then((res) => {
            dispatch(InfluencerAction.getVideoAnalytics(res.data));
          });
      },
    
      GetSuggestedRate: (creatorId, data) => {
        const dispatch = useDispatch();
          axios
          .post(`${baseUrl}api/influencers/tiktok/${creatorId}/getSuggestedRate`, data)
          .then((res) => {
            dispatch(InfluencerAction.getSuggestedRate(res.data));
          });
      },

      FetchInfluencerNetwork : async () => {
        const dispatch = useDispatch();
        axios
          .get(`${baseUrl}api/influencers/influencer_network`)
          .then((res) => {
            dispatch(InfluencerAction.fetchInfluencerNetwork(res.data.result));
            return res.data.result;
          })
          .catch((err) => {});
      }
}
    
//Action
export const InfluencerAction = {   
  sendRevisions: (data) => {
    return {
      type: 'ADD_REVISION_NOTE',
      data: data
    };
  },

  tiktokOnboarding: (data) => {
    return {
      type: 'TIKTOK_ONBOARDING',
      data: data
    }
  },

  getTikTokTokens: (data) => {
    return {
      
      type: 'GET_TIKTOK_TOKENS',
      data: data
    }
  },

  fetchInfluencerNetwork : (data) => {
    return {
      type: 'FETCH_INFLUENCER_NETWORK',
      data: data
    }
  },
  
  findCreatorsForCampaign: (data) => {
    return {
      type: 'FIND_CAMPAIGN_CREATORS',
      data: data
    }
  }

}