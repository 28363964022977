import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const app = initializeApp({
  apiKey: 'AIzaSyDV9TvKicGAPbBUJMnjx4USCkztQg2GftU',
  authDomain: 'crafted-v1.firebaseapp.com',
  databaseURL: 'https://crafted-v1.firebaseio.com',
  projectId: 'crafted-v1',
  storageBucket: 'crafted-v1.appspot.com',
  messagingSenderId: '875601052690', 
  appId: '1:875601052690:web:559726ef8bf2655888c4cb',
  measurementId: 'G-13PKY6114K'
});

export const auth = getAuth(app);
export default app;
