import React, { useRef, useState, useEffect } from 'react';
import { Modal } from 'react-responsive-modal';
import Collapsible from 'react-collapsible';
import { BsChevronCompactDown, BsChevronCompactUp } from 'react-icons/bs';
import { useTaskState, TaskService } from '../../../Service/TaskServices';
import s3 from '../../../aws-s3';
import S3FileUpload from 'react-s3';
import moment from 'moment';
import { IoMdArrowDropright } from "react-icons/io";
import { IoMdArrowDropleft } from "react-icons/io";
import Loader from '../../Loader';
import VideoCarousel from '../../Creator_Dashboard/VideoCarousel';
import ProposalVideo from '../../Creator_Dashboard/ProposalVideo';
import { useAuth } from '../../../Context/AuthContext';
import { validateFormFields } from '../../../utils/functions';
import { Toaster, toast } from 'react-hot-toast';
import { Carousel } from 'react-responsive-carousel';


const TaskDetails = (props) => {
  console.log('props:-', props);
  const fileRefs = useRef({});
  const taskState = useTaskState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { currentUser } = useAuth();
  let videoLinks = {};
 const [currentVideo, setCurrentVideo] = useState(null);
const [deployAsset, setDeployAsset] = useState(false);
const [sendNote, setSendNote] = useState(false);
const [selectedDraft, setSelectedDraft] = useState(null);
const [count, setCount] = useState(0);
const [loading, setLoading] = useState(false);
const notes = useRef('');
const [isSelected, setIsSelected] = useState("");
const [isHover, setIsHover] = useState(false);

const [videos, setVideos] = useState({})
const [drafts, setDrafts] = useState([]);

const [selectedTaskIndex, setSelectedTaskIndex] = useState(0);
const bg = {
  overlay: {
    background: 'rgba(128, 128, 128, 0.099)'
  }
};
//const [videosUploaded, setVideoUploads] = useState(false);
  var uploadedVideos = {};
  let video1 = '';
  let video2 = '';
  let products = [];
  const timezone = moment.tz.guess();
  var task_deadline_time = moment(props.task.deadline).tz(timezone).format('MMM Do YYYY');
  if (props.task) {
    products = props.task.products
      ? Object.entries(props.task.products).map((e) => ({
          [e[0]]: e[1]
        }))
      : [];
  }

  const [error, setError] = useState({
    avatar: ''
  });
  const fieldErrors = {
    note: 'Please Enter Note'
  };
  const [errors, setErrors] = useState({});

  const image = useRef('');

  const userProposals = [];

  for (const proposalId in props.task.proposals) {
    const proposal = props.task.proposals[proposalId];
    if (proposal.creator_id === currentUser.uid) {
      for (const key in proposal.proposal_details) {
        userProposals.push(proposal.proposal_details[key]);
      }
    }
  }

  // 1 - set num_videos

  var num_videos = props.task.recipe_video + props.task.product_showcase;
  if (num_videos <= 0) {
    console.error('Error: num_videos must be a positive integer');
    return null;
  }

  // 2 - generate appropriate number of video inputs

  // 3 - handleChange that updates a useState element with true/false for upload

  // 4 - Iterate through each element in array of videos & upload to S3, set the result to another variable

  // 5 - place the object into the object for submit task

  const [files, setFiles] = useState([]);

  const handleChange = (event) => {
    console.log('File Array: ');
    console.log(Array.from(event.target.files));
    setFiles(Array.from(event.target.files));
  };

  const handleAddVideos = (e, i) => {
    if (e.target.files && e.target.files.length > 0) {
      setVideos({
        ...videos,
        [`video${i}`]: e.target.files[0]
      });
    }
  };

  //on Form Handle Submit
  const handleSubmit = async (event) => {
    
    event.preventDefault();
    setErrors({});
    const formValues = {
      image: image.current.value
    };

    const validationErrors = validateFormFields(formValues, fieldErrors);
    if (Object.keys(validationErrors).length) {
      return setErrors(validationErrors);
    }

    let url;
    if (image) {
      url = await S3FileUpload.uploadFile(image.current.files[0], s3).then((data) => data.location);
    }
    console.log("url: ---", url)
    const temp = {};
    temp.thumbnail = url;

    temp.uid = currentUser.uid;
    // if (currentUser.uid) RecipeService.AddRecipe(data);
    props.close();
  };
  async function sendRevisedVid(event) {
    setIsSubmitting(true);
    event.preventDefault();
    try {
      taskState.merge({ loading: true });
       for (let i = 0; i < files.length; i++) {
         const file = files[i];
         console.log('File #' + i);
         console.log(file);
         const data = await S3FileUpload.uploadFile(file, s3);
         console.log('File String #' + i);
         console.log(data.location);

         videoLinks[`video${i + 1}`] = data.location.toString();
       }

       const dt = new Date();
       let date = dt.toString();
       date.substring(4, 21);
       let vidname =
         props.task.creator_name + '_' + props.task.brand_name + '_' + date.substring(4, 21);
       let d = {
         drafts: videoLinks,
         creator_id: props.task.creator_id ? props.task.creator_id : props.task.brand_id,
         creator_name: vidname,
         brand_id: props.task.brand_id,
         status:"completed",
         task_id: props.id
       };

      await TaskService.SendDraft(props.id[0], d);
      taskState.merge({ loading: false });
   
      toast.success('Draft Sent', {duration: 1800});
      setTimeout(() => {
        props.close();
      } , 2000);
      // close modal   
    } catch (error) {
      //error
    }
  }

  return (
    <div>
      <Toaster/>
      <Modal
        open={props.open}
        onClose={props.close}
        center
        styles={bg}
        classNames={{ modal: 'customModal' }}
        closeOnOverlayClick={true}>
        <>
          <Collapsible
            trigger={[
              'Brief Details',
              <div className="px-3 absolute right-0">
                <BsChevronCompactDown />
              </div>
            ]}
            triggerWhenOpen={[
              'Brief Details',
              <div className="px-3 absolute right-0">
                <BsChevronCompactUp />
              </div>
            ]}
            open={false}
            contentOuterClassName="mb-6"
            triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
            <div className="m-1 bg-white rounded-lg text-3xl dark:bg-gray-700 p-5">
              <div className="flex ml-12 mb-5">
                <div className="flex mr-5 font-bold">Brand:</div>
                <a
                  className="text-blue underline"
                  target="_blank"
                  href={`http://${props.task && props.task.brand_website}`}
                  rel="noreferrer">
                  {props.task && props.task.brand_name}
                </a>
              </div>

              {/*<div className="flex ml-12 mb-5">
                <div className="flex mr-5 font-bold">Deadline:</div>
                <div>{props.task && task_deadline_time}</div>
              </div>*/}
              <div className="flex ml-12 mb-5">
                <div className="flex mr-5 font-bold">Brief:</div>
                <div className="flex flex-col space-y-2">
                  <div>
                    {' '}
                    {props.task &&
                      props.task.recipe_videos > 0 &&
                      `Create ${props.task.recipe_videos} short form recipe videos showcasing the brand as organically (not ad-style or salesy) as possible.`}
                  </div>
                  <div>
                    {' '}
                    {props.task &&
                      props.task.product_showcase > 0 &&
                      `Create ${props.task.product_showcase} short form product demo videos showcasing the brand as organically (not ad-style or salesy) as possible.`}
                  </div>
                  <div>
                    {' '}
                    Crafted and the brand will own these videos and will post them on their social
                    accounts, use them for paid social, website, etc. in perpetuity.
                  </div>
                </div>
              </div>
              <div className="ml-12 mb-5">
                <div>
                  <div className="mt-2 font-bold">
                    {props.task && props.task.brand_name} is prioritizing these products/flavors:
                  </div>
                  <div className="">{props.task && props.task.note1}</div>
                </div>
                <div>
                  <div className="mt-2 font-bold">
                    {props.task && props.task.brand_name}'s Target Audience:
                  </div>
                  <div className="">{props.task && props.task.note2}</div>
                </div>
                <div>
                  <div className="mt-2 font-bold">Key talking points for creators:</div>
                  <div className="">{props.task && props.task.note3}</div>
                </div>
              </div>
            </div>
          </Collapsible>
          <Collapsible
            trigger={[
              'Examples of Video Types',
              <div className="px-3 absolute right-0">
                <BsChevronCompactDown />
              </div>
            ]}
            triggerWhenOpen={[
              'Examples of Video Types',
              <div className="px-3 absolute right-0">
                <BsChevronCompactUp />
              </div>
            ]}
            open={false}
            contentOuterClassName="mb-6"
            triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
            <div className="m-3">
              <VideoCarousel />
            </div>
          </Collapsible>
          <Collapsible
            trigger={[
              'Draft Details',
              <div className="px-3 absolute right-0">
                <BsChevronCompactDown />
              </div>
            ]}
            triggerWhenOpen={[
              'Draft Details',
              <div className="px-3 absolute right-0">
                <BsChevronCompactUp />
              </div>
            ]} 
            open={true}
            contentOuterClassName="mb-6"
            triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
            <Carousel className="relative items-center" showIndicators={false} showArrows={true} showThumbs={true} useKeyboardArrows={true} swipeable={true}
                renderArrowPrev={(clickHandler, hasPrev) => {
                  return (
                    <div
                      className={`${
                        hasPrev ? "absolute" : "hidden"
                      } top-1/2 left-0 flex justify-center items-center p-3 opacity-100 hover:opacity-100 cursor-pointer z-20 transform -translate-y-1/2`}
                      onClick={() => {
                        if (currentVideo) {
                          currentVideo.pause(); // Pause the current video
                        }
                        clickHandler();
                        setSendNote(false);
                      }}
                    >
                      <IoMdArrowDropleft className="2xl:w-24 2xl:h-24 w-16 h-16 left-0 absolute text-red" />
                    </div>
                  );
                }}
                renderArrowNext={(clickHandler, hasNext) => {
                  return (
                    <div
                      className={`${
                        hasNext ? "absolute" : "hidden"
                      } top-1/2 right-0 flex justify-center items-center p-3 opacity-100 hover:opacity-100 cursor-pointer z-20 transform -translate-y-1/2`}
                      onClick={() => {
                        if (currentVideo) {
                          currentVideo.pause(); // Pause the current video
                        }
                        clickHandler();
                        setSendNote(false);
                      }}
                    >
                      <IoMdArrowDropright className="2xl:w-24 2xl:h-24 w-16 h-16 right-0 absolute text-red" />
                    </div>
                  );
                }}>
                {props.task.drafts &&
                  Object.values(props.task.drafts).some(draft => draft.status === "revisions") ? (
                    Object.values(props.task.drafts)
                    .filter(draft => draft.status === "revisions")
                    .sort((a, b) => new Date(b.created) - new Date(a.created))
                    .map((draft, i) => {
                      return (
                      <div className='mb-2 '>
                        <h3 className='font-bold text-lg w-full'>{draft.creator_name}</h3>
                        <div className="mx-auto w-full items-center justify-center text-center">
                        <video className="mx-auto w-1/2 h-full" 
                        controls src={draft.video} controlsList="fullscreen" style={{ objectFit: 'contain' }}
                        ref={(el) => {
                          if (el) {
                            el.onplay = () => setCurrentVideo(el); // Set the current video when it starts playing
                          }}}

                        /> {console.log(draft.task_id)}
                        
                        </div>
                        {draft.status === "pending" && (
                          <div className="my-2 mx-auto w-64 flex justify-center text-center">
                      
                          <button
                                onClick={() => {
                                  setSelectedDraft(draft); // Set the selected draft
                                setSendNote(!sendNote);
                                }}
                              className={`bg-${sendNote ? 'darkGray' : 'red'} px-1 text-xs font-bold py-1 rounded-lg text-white mt-41 w-auto text-center cursor-pointer`}>
                              Send Revisions
                          </button>
                      </div> 
                        )
                        
                        
                        }
                      
                        {draft.status === "revisions" && (
                          <div className="my-2 mx-auto w-1/2 b  setSelectedDraft(draft); // Set the selected draft
                                  setSendNote(!sendNote);reak-words flex flex-col items-center justify-center rounded-md text-center bg-white p-4">
                            <div className="mb-3 w-full h-fit break-words overflow-auto text-base shadow-inner font-medium text-gray-700">
                             {draft.revision_notes}
                            </div>
                            <button
                              onClick={() => {
                                setSelectedDraft(draft); // Set the selected draft
                                setSendNote(!sendNote);
                              }}
                              className={`px-4 py-2 text-xs font-bold rounded-lg text-white mt-2 w-auto cursor-pointer ${sendNote ? 'bg-darkGray' : 'bg-red'}`}
                            >
                              Edit revisions
                            </button>
                          </div>
                        )}
                        
                        {draft.status === "approved" && (
                        <div className="my-2 mx-auto w-64 flex justify-center text-center">
                            <div
                                className="bg-green px-2 mr-3 text-xs font-bold py-1 rounded-md text-white mt-41 w-auto text-center">
                               Draft Approved
                            </div>
                        </div>
                        )}
                        
                    </div>
                      )
                    })
                    ) : (
                      <p className='flex items-center justify-center mx-auto mt-24 text-center'>No drafts available.</p>
                    )}
                </Carousel>
          </Collapsible>
          {/*<Collapsible
            trigger={[
              'Send Revised Video',
              <div className="px-3 absolute right-0">
                <BsChevronCompactDown />
              </div>
            ]}
            contentOuterClassName="mb-6"
            triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            triggerWhenOpen={[
              'Send Revised Video',
              <div className="px-3 absolute right-0">
                <BsChevronCompactUp />
              </div>
            ]} open={true}>
              <form onSubmit={sendRevisedVid}> 
                <div className="flex flex-col my-5">
                  <div className="font-bold text mr-2">
                    <div>
                      <strong>Video 1 </strong>
                    </div>
                    <input
                      id="imageInput"
                      required
                      type="file"
                      accept="image/png, image/jpeg, video/mp4"
                      className="focus:outline-none w-full bg-lightGray p-2 mt-2 mr-2 rounded"
                      multiple
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
                <div className="items-center  my-5 justify-center text-center">
                <button
                className="bg-red text-white w-1/3 mt-8 py-4 rounded-lg outline-none text-base"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <span>
                    <Loader /> 
                    <span className="mr-2">Sending...</span>
                   
                  </span>
                ) : (
                  'Send Update'
                )}
              </button>
                 
                </div>
              </form>
                </Collapsible>*/}
        </>
      </Modal>
    </div>
  );
};
export default TaskDetails;
