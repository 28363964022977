import React, { useState, useContext } from 'react';
import { GenerativeBriefService, useGenerativeBriefState } from '../../Service/GenerativeBriefService';
import { Toaster, toast } from 'react-hot-toast';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';
import { Modal } from 'react-responsive-modal';
function BrandBriefTemplateView(props) {
  const [name, setName] = useState(props.task.name || "");
  const [brandDescription, setBrandDescription] = useState(props.task.brand_description || "");
  const [campaignDescription, setCampaignDescription] = useState(props.task.campaign_description || "");
  const [targetAudience, setTargetAudience] = useState(props.task.target_audience || "");
  const [usp, setUsp] = useState(props.task.usp || "");
  const [brandPositioning, setBrandPositioning] = useState(props.task.brand_positioning || "");
  const [videoConcept, setVideoConcept] = useState(props.task.video_concept || "");
  const [videoDos, setVideoDos] = useState(props.task.video_dos || "");
  const [videoDonts, setVideoDonts] = useState(props.task.video_donts || "");
  const [exampleVideos, setExampleVideos] = useState(props.task.example_videos || "");
  const [id, setId] = useState(props.task.id || "");
  const {brandId} = useContext(BrandContext);
  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      brand_id: brandId,
      brief_id: props.task.id,
      task_id: props.task.id,
      data : {
      name: name,
      brand_description: brandDescription,
      campaign_description: campaignDescription,
      target_audience: targetAudience,
      usp,
      brand_positioning: brandPositioning,
      video_concept: videoConcept,
      video_dos: videoDos,
      video_donts: videoDonts,
      example_videos: exampleVideos
      }
      
      
    }
    GenerativeBriefService.updateStudioTaskBrief(data )
    toast.success('Brief saved successfully!');

    setTimeout(() => {
      props.close();
      window.location.reload();
    }, 2000);
  };

  const handleDelete = () => {
    let data = {
      brand_id: brandId,
    }
    GenerativeBriefService.deleteBriefTemplate(props.task.id, data);
  }

  return (
    <Modal
    open={props.open}
    onClose={props.close}
    center
    classNames={{ modal: 'customWideModal' }}
    closeOnOverlayClick={false}
  >
      <Toaster/>
         <h1 className="2xl:text-2xl md:text-base xs:text-0.7 font-semibold text-center mt-2 text-gray-800 mb-4">View Creative Brief</h1>
        <hr/>
      <div className="p-3 md:mx-4 xs:mx-0 md:text-0.7 2xl:text-2xl" >
      <form onSubmit={handleSubmit} className="flex flex-col justify-between" style={{ height: 'calc(95vh - 10rem)' }}>
        <div className="overflow-auto">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Brief Name:
            </label>
            <input
              disabled
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline 2xl:text-base md:text-0.7 xs:text-sxs"
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="brandDescription">
              Brand Description:
            </label>
            <textarea
              disabled
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline 2xl:text-base md:text-0.7 xs:text-sxs"
              id="brandDescription"
              value={brandDescription}
              onChange={(e) => setBrandDescription(e.target.value)}
            ></textarea>
          </div>
          {/* Add the rest of the form fields here */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="campaignDescription">
              Campaign Description:
            </label>
            <textarea
              disabled
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline 2xl:text-base md:text-0.7 xs:text-sxs"
              id="campaignDescription"
              value={campaignDescription}
              onChange={(e) => setCampaignDescription(e.target.value)}
            ></textarea>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="targetAudience">
              Target Audience:
            </label>
            <input
              disabled
              className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline 2xl:text-base md:text-0.7 xs:text-sxs"
              id="targetAudience"
              type="text"
              value={targetAudience}
              onChange={(e) => setTargetAudience(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="usp">
              Unique Selling Point:
            </label>
            <input
              disabled
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline 2xl:text-base md:text-0.7 xs:text-sxs"
              id="usp"
              type="text"
              value={usp}
              onChange={(e) => setUsp(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="brandPositioning">
              Brand Positioning:
            </label>
            <textarea 
              disabled
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline 2xl:text-base md:text-0.7 xs:text-sxs"
              id="brandPositioning"
              type="text"
              value={brandPositioning}
              onChange={(e) => setBrandPositioning(e.target.value)}
            ></textarea>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="videoConcept">
              Video Concept:
            </label>
            <textarea
              disabled
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline 2xl:text-base md:text-0.7 xs:text-sxs"
              id="videoConcept"
              value={videoConcept}
              onChange={(e) => setVideoConcept(e.target.value)}
            ></textarea>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="videoDos">
              Video Dos:
            </label>
            <textarea
              disabled
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline 2xl:text-base md:text-0.7 xs:text-sxs"
              id="videoDos"
              value={videoDos}
              onChange={(e) => setVideoDos(e.target.value)}
            ></textarea>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="videoDonts">
              Video Donts:
            </label>
            <textarea
              disabled
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline 2xl:text-base md:text-0.7 xs:text-sxs"
              id="videoDonts"
              value={videoDonts}
              onChange={(e) => setVideoDonts(e.target.value)}
            ></textarea>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="exampleVideos">
              Example Videos:
            </label>
            <textarea
              disabled
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline 2xl:text-base md:text-0.7 xs:text-sxs"
              id="exampleVideos"
              value={exampleVideos}
              onChange={(e) => setExampleVideos(e.target.value)}
            ></textarea>
          </div> </div>
          {/*<div className='flex mt-5 flex-row gap-10 items-center justify-center'>
          <button
            type="submit"
            className="bg-Blue text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Save Changes
          </button>
          <button
            type="button"
            className="text-gray hover:underline font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            onClick={() => props.close()}
          >
            Discard Changes
          </button>
        </div>*/}
       
        </form>
    
      </div>
    </Modal>
    )

}

export default BrandBriefTemplateView