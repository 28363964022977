import React, { useEffect, useState } from 'react';
import { FaThumbsUp, FaThumbsDown, FaMinus } from 'react-icons/fa';

function CampaignCommentsAnalytics(props) {

    const [searchTerm, setSearchTerm] = useState();
    const [filteredComments, setFilteredComments] = useState([]);
    const [comments, setComments] = useState([]);
    // Get campaign comments
    const res = (props.comments); // remove the key portion
    console.log("unformatted comments: ", res);
    useEffect(() => {
        let commentsArray = [];
        Object.values(props.comments).forEach(obj => {
            Object.values(obj).forEach(comment => {
                commentsArray.push(comment);
            });
        });
        setComments(commentsArray);
    }, [props.comments]);
    console.log("comments: ", comments);

   /* const comments = [
        {
            id: "10000",
            text: "i am good",
            date: "2023-11-28T21:17:37+0000"
        }, 
        {
            id: "10001",
            text: "i am bad",
            date: "2023-11-28T21:17:37+0000"
        }, 
        {
            id: "10002",
            text: "it's a bad day",
            date: "2023-11-28T21:17:37+0000"
        }, 
        {
            id: "10003",
            text: "it's a good day",
            date: "2023-11-28T21:17:37+0000"
        }, 
        {
            id: "10004",
            text: "this looks bad",
            date: "2023-11-28T21:17:37+0000"
        }
    ];*/

    // Filter campaign comments upon search click
    const handleSearch = (e) => {
        e.preventDefault();
        const results = comments.filter(comment => {
            return comment.text.toLowerCase().includes(searchTerm.toLowerCase());
        });
        setFilteredComments(results);
    }
    useEffect(() => {
        console.log("filtered comments: ", filteredComments);
    })

    const handleReset = () => {
        setSearchTerm('');
        setFilteredComments([]);
    }
    return (
        <div>
            <form onSubmit={handleSearch} className="flex">
                <input
                type="text"
                placeholder="Search comments..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-96 py-2 px-3 border border-gray-300 rounded-md focus:outline-none focus:border-blue"
                />
                <button className="ml-2 bg-Blue text-white px-4 py-2 rounded-md rounded-l-none" type="submit">Search</button>
                {filteredComments.length > 0 && 
                 <button type="button" className="ml-2 text-Blue px-4 py-2 rounded-md rounded-l-none" onClick={handleReset}>Reset</button>
                }
            </form>
            <div className="overflow-y-auto max-h-80">
            {filteredComments ? (
                <>
                {filteredComments.length > 0 && 
                    <>
                        <h1>Search Results</h1>
                        <h2>{filteredComments.length} comments</h2>
                    </>
                }
                {filteredComments.map((comment) => (
                <div key={comment.id} className="mb-4">
                    <div className="border border-gray-300 rounded p-2 mt-2 flex justify-between items-center">
                    <div>
                        <p className="font-bold text-base">{comment.text}</p>
                        <p className="text-xs text-gray-600">{new Date(comment.timestamp).toLocaleString()}</p>
                    </div>
                    <div className="flex items-center">
                        {comment.sentiment === 'positive' && (
                        <FaThumbsUp
                            title="Positive comment"
                            className="text-green ml-1"
                        />
                        )}
                        {comment.sentiment === 'negative' && (
                        <FaThumbsDown
                            title="Negative comment"
                            className="text-red ml-1"
                        />
                        )}
                        {comment.sentiment === 'neutral' && (
                        <FaMinus
                            title="Neutral comment"
                            className="text-gray-500 ml-1 w-5 h-5"
                        />
                        )}
                    </div>
                    </div>
                </div>
                ))}
            </>
            ) : (
                <p className="text-gray-500">No comments available</p>
            )}
            </div>
    
        </div>
    );
}

export default CampaignCommentsAnalytics;