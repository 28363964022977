import React, { useEffect, useState, useRef } from 'react';
import { useDraftState, DraftService } from '../../Service/DraftService';
import { useTaskState, TaskService } from '../../Service/TaskServices';
import Loader2 from '../Loader2';
import { useAuth } from '../../Context/AuthContext';
import { Carousel } from 'react-responsive-carousel';// requires a loader
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { IoMdArrowDropright } from "react-icons/io";
import { IoMdArrowDropleft } from "react-icons/io";
import GlobalDialog from '../../utils/brandModal';
import { toast, Toaster } from 'react-hot-toast';
import { EmailService } from '../../Service/EmailService';
import CustomButton from '../../utils/customButton';

function UgcVideoDrafts() {
  const { id } = useParams();
  const [currentVideo, setCurrentVideo] = useState(null);
  const [error, setError] = useState({
    avatar: ''
  });
  const [deployAsset, setDeployAsset] = useState(false);
  const [sendNote, setSendNote] = useState(false);
  const [selectedDraft, setSelectedDraft] = useState(null);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const notes = useRef('');
  const [isSelected, setIsSelected] = useState("");
  const [isHover, setIsHover] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [videos, setVideos] = useState({})
  const [drafts, setDrafts] = useState([]);
  const [lastClickedVersion, setLastClickedVersion] = useState('Version 1');
  const [reviews, setReviews] = useState(false);
  const [recipes, setRecipes] = useState(false);
  const [photos, setPhotos] = useState(false);
  const [selectedTaskIndex, setSelectedTaskIndex] = useState(0);
  const [raw, setRaw] = useState(false);
  const [variation, setVariation] = useState(false);
  const fieldErrors = {
    note: 'Please Enter Note'
  };
  const [errors, setErrors] = useState({});
  const taskState = useTaskState();
  const [editTask, setEditTask] = useState(false);
  console.log("task id: ", id);
  useEffect(() => {
    if (id) {
      TaskService.FetchSingleTask(id);
    }
  }, [taskState.updateNeed.value]); // Make sure 'id' is in the dependency array // Make sur
  if (taskState.task.value) {
    console.log("VALUE: ", taskState.tasks.value)
    
  }
  const task = taskState.tasks.value;



  const { currentUser } = useAuth();

  const openRevision = (draft) => {
    setSelectedDraft(draft)
    setSendNote(!sendNote)
  }

  const handleSelectTask = (item, i) => { 
    setSelectedTaskIndex(i);
  };

  const approveDraft = async (draft) => {
    taskState.merge({updateNeed: true});
    let approvedData = {
      drafts: [{ video: draft.video }],
      photos: draft.photos,
      reviews: draft.reviews,
      recipes: draft.recipes,
      variations: draft.variations,
      creator_id: draft.creator_id,
      raw_footage: draft.raw_footage,
      creator_name: draft.creator_name,
      brand_id: draft.brand_id,
      task_id: draft.task_id
    };

    await TaskService.CompleteTask(draft.task_id, approvedData); 
    if (task && task.add_ons && task.add_ons.some((addOn) => 
        addOn.name === "Whitelist"  && addOn.platform.includes("tiktok")))
       {
        let emailData = {
          email: draft.creator_email,
          task_id: draft.task_id,
          creator_id: draft.creator_id,
          brand_id: draft.brand_id,
          creator_name: draft.creator_name.split("_")[1],
        }
        EmailService.sendTiktokCodeEmailCreator(emailData);
       }
    toast.success('Draft Approved Successfully! Please leave a review in the "Leave Reviews" tab.');
    taskState.merge({updateNeed: false});
  };

  const sendRevisionNote = async (draft) => {
    let revisionData = {
      task_id: draft.task_id,
      creator_id: draft.creator_id,
      brand_id: draft.brand_id,
      revision_notes: notes.current.value
    };
    await TaskService.AddRevisionNote(draft.task_id, revisionData);
    toast.success('Revision Notes Sent');  
    TaskService.FetchSingleTask(id);
  }

  const handleReview = (draft) => {
    setSelectedDraft(draft);
    setReviews(!reviews);
  }

  const handleRecipe = (draft) => {
    setSelectedDraft(draft);
    setRecipes(!recipes);
  }

  const handlePhotos = (draft) => {
    setSelectedDraft(draft);
    setPhotos(!photos);
  }

  const handleRaw = (draft) => {
    setSelectedDraft(draft);
    setRaw(!raw);
  }
  function downloadPhotoInNewTab(url) {
    window.open(url, '_blank');
  }
  const handleVariationClick = (fileUrl, variation) => {
    setSelectedVersion(fileUrl);
    setVariation(variation);
    setShowVideoModal(true);
    setCurrentVideo(null);
    console.log("variation: ", variation);
    console.log("fileUrl: ", fileUrl);
  };
  return (
    <div className=" bg-white w-full items-center justify-center p-4  h-30 rounded-lg z-100 flex flex-row">
       <Toaster />
      {/*<div>
          <div className={` mx-2 pb-5 flex flex-row h-full `} style={{ height: '100%' }}>
          <div className={`mb-5 w-3/12 h-23`}>
                  {taskState.loading.value ? (
                    <Loader2 />
                  ) : task.drafts ? ( 
                    <div
                      className="overflow-y-scroll video-revisions"
                      style={{ height: '100%', width: '100%' }}>
                      <div>
                        <div className="mt-2 mb-4 mr-2 cursor-pointer" >
                          <div className={`block px-4 py-2 mt-2 text-sm font-semibold rounded text-white bg-gray-600`}>
                            <p>
                              {task.note3 ? task.note3 : 'No Name'} 
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="h-full w-full flex items-center justify-center">
                      <p>No drafts available</p>
                    </div>
                  )}  
                </div>

                  <div className="z-100 bg-lightGray">
                    <div
                      // className="max-h-min z-0 p-3 rounded-lg my-2 bg-black text-white cursor-pointer"
                      // onClick={() => deployHandle(data, id, i)}
                    >
                      {
                        videos && Object.keys(videos).map((video,i)=>{
                          return(
                            <p className={` py-3 px-2 rounded  text-gray-500 mr-2 bg-${
                              i % 2 === 0 ? 'lightGray' : 'white'
                            } border-none`}>{video}</p>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
        
            </div>*/}
            <div className="text-center asbolute-right-0 h-1/2 w-1/2">
              <div>
              <Carousel className="relative items-center" showIndicators={false} showArrows={true} showThumbs={true} useKeyboardArrows={true} swipeable={true}
                renderArrowPrev={(clickHandler, hasPrev) => {
                  return (
                    <div
                      className={`${
                        hasPrev ? "absolute" : "hidden"
                      } top-1/2 left-3 lg:left-10 xl:left-16 2xl:left-32 flex justify-center items-center p-3 opacity-100 hover:opacity-100 cursor-pointer z-20 transform -translate-y-1/2`}
                      onClick={() => {
                        if (currentVideo) {
                          currentVideo.pause(); // Pause the current video
                        }
                        clickHandler();
                        setSendNote(false);
                        setShowVideoModal(false);
                      }}
                    >
                      <IoMdArrowDropleft className="2xl:w-24 2xl:h-24 w-16 h-16 text-Blue" />
                    </div>
                  );
                }}
                renderArrowNext={(clickHandler, hasNext) => {
                  return (
                    <div
                      className={`${
                        hasNext ? "absolute" : "hidden"
                      } top-1/2 right-3 lg:right-10 xl:right-16 2xl:right-32 flex justify-center items-center p-3 opacity-100 hover:opacity-100 cursor-pointer z-20 transform -translate-y-1/2`}
                      onClick={() => {
                        if (currentVideo) {
                          currentVideo.pause(); // Pause the current video
                        }
                        clickHandler();
                        setSendNote(false);
                        setShowVideoModal(false);
                      }}
                    >
                      <IoMdArrowDropright className="2xl:w-24 2xl:h-24 w-16 h-16 text-Blue" />
                    </div>
                  );
                }}>
                {task.drafts &&
                  Object.values(task.drafts).some(draft => draft.status == "pending" ||  draft.status === "completed" || draft.status === "revisions" || draft.status === "whitelisted" || draft.status === "completed" || draft.status === "approved") ? (
                    Object.values(task.drafts)
                    .filter(draft => draft.status === "pending" ||  draft.status === "completed" || draft.status === "approved" || draft.status === "revisions" || draft.status === "whitelisted")
                    .sort((a, b) => new Date(b.created) - new Date(a.created))
                    .map((draft, i) => {
                      return (
                      <div className='mb-2 '>
                        <h3 className='font-bold text-lg w-full'>{draft.creator_name}</h3>
                        <div className="mx-auto w-full items-center justify-center text-center">
                        <video className={`mx-auto w-fit 2xl:h-328 md:h-22 ${showVideoModal ? 'opacity-0' : ''}`} 
                        controls src={draft.video} controlsList="fullscreen nodownload" style={{ objectFit: 'contain' }}
                        ref={(el) => {
                          if (el) {
                            el.onplay = () => setCurrentVideo(el); // Set the current video when it starts playing
                          }}}
                          onContextMenu={(e) => e.preventDefault()}
                        />  <div className='my-3'>
                        {/*<a
                          href={selectedVersion || draft.video.file || draft.video}
                          download
                          target="_blank"
                          rel="noreferrer"
                          className="text-red hover:underline mb-4 px-4 text-0.7 font-bold py-3 rounded-lg mt-4 w-auto text-center cursor-pointer">
                          Having trouble viewing? Click here to review offline.
                        </a>*/}
                        </div>
                        {console.log(draft.task_id)}
                                                {showVideoModal && (
                          <div>
                            <video
                              className="mx-auto w-fit 2xl:h-328 md:h-22 absolute top-6 left-0 right-0 bottom-0"
                              controls
                              src={selectedVersion || draft.video.file}
                              controlsList="fullscreen" style={{ objectFit: 'contain', width: 'fit-content' }}
                            />
                        <div className='flex flex-row items-center justify-center mt-2 mx-auto w-1/2'>
                          {Object.entries(variation)
                            .filter(([key, value]) => value === true)
                            .map(([key], index, array) => (
                              <span className='font-bold ml-1' key={key}>
                                {key === "music" && "Music"}
                                {key === "onScreenText" && "On-screen text"}
                                {key === "voiceover" && "Voiceover"}
                                {index !== array.length - 1 && ", "}
                              </span>
                            ))}
                        </div>
                          </div>
                        )}
                     {draft && draft.variations && (
                      <div className='flex flex-row items-center mx-auto justify-between md:w-12rem 2xl:w-15rem'>
                      <div key={i} 
                          onClick={() => {
                            setShowVideoModal(false);
                            setLastClickedVersion('Version 1');
                            setSelectedVersion(null);
                          }} 
                         className={`focus-within:bg-gray-600 w-full mx-2 cursor-pointer text-white ${lastClickedVersion === 'Version 1' ? 'bg-red' : 'bg-gray-500'} rounded-lg border-2 md:text-base text-center my-1 p-1`}>
                         <p className='text-xs font-bold break-words'>
                            Version 1
                          </p>
                      </div>
                      {Object.keys(draft.variations).map((variationId, i) => (
                        <div key={i} 
                        className={`w-full mx-2 cursor-pointer text-white ${lastClickedVersion === `Version ${i + 2}` ? 'bg-red' : 'bg-gray-500'} rounded-lg border-2 md:text-base text-center my-1 p-1`} 
                         onClick={() => {
                          handleVariationClick(Array.isArray(draft.variations[variationId].video.files) ? draft.variations[variationId].video.files[0] : draft.variations[variationId].video.files, draft.variations[variationId].video.variation);                              
                          if (currentVideo) {
                            currentVideo.pause(); // Pause the current video
                          }
                          setLastClickedVersion(`Version ${i + 2}`);
                        }}>
                          <p className='text-xs font-bold break-words'>
                            Version {i + 2}
                          </p>
                        </div>
                      ))}
                    
                      </div>
                    )}
                       <div className='flex flex-col items-center mx-auto justify-center w-1/2 '>
                          {draft && draft.photos && (
                                
                          <button className='text-gray-400' onClick={() => handlePhotos(draft)}>View Product Photos</button>
                              )}
                              {draft && draft.recipes && (
                              <button className='text-gray-400' onClick={() => handleRecipe(draft)}>View Recipe</button>
                              )}
                              {draft && draft.reviews && (
                            <button className='text-gray-400' onClick={() => handleReview(draft)}>View Review</button>
                        )}
                        {draft && draft.raw_footage && (
                            <button className='text-gray-400' onClick={() => handleRaw(draft)}>View Raw Footage</button>
                        )}
                        </div>
                        </div>
                        {draft.status === "pending" && (
                          <div className="my-2 mx-auto w-64 flex justify-center text-center">
                          <CustomButton
                              className="px-1 mr-3 text-xs font-bold py-1 text-white mt-41 w-auto text-center cursor-pointer"
                              onClick={() => approveDraft(draft)}
                              >Approve Draft
                          </CustomButton>
                          <CustomButton
                                onClick={() => {
                                  setSelectedDraft(draft); // Set the selected draft
                                setSendNote(!sendNote);
                                }}
                              className={`bg-${sendNote ? 'darkGray' : 'red'} px-1 text-xs font-bold py-1 rounded-lg text-white mt-41 w-auto text-center cursor-pointer`}>
                              Send Revisions
                          </CustomButton>
                      </div> 
                        )
                        
                        
                        }
                      
                        {draft.status === "revisions" && (
                          <div className="my-2 mx-auto w-1/2 break-words flex flex-col items-center justify-center rounded-md text-center bg-white p-4">
                            <div className="mb-3 w-full h-fit break-words overflow-auto text-base shadow-inner font-medium text-gray-700">
                             {draft.revision_notes}
                            </div>
                            <CustomButton
                              onClick={() => {
                                setSelectedDraft(draft); // Set the selected draft
                                setSendNote(!sendNote);
                              }}
                              className={`px-4 py-2 text-xs font-bold text-white mt-2 w-auto cursor-pointer ${sendNote ? 'bg-darkGray' : 'bg-Blue'}`}
                            >
                              Edit revisions
                            </CustomButton>
                          </div>
                        )}
                        
                        {draft.status === "approved" && (
                        <div className="my-2 mx-auto w-64 flex justify-center text-center">
                            <div
                                className="bg-green px-2 mr-3 text-xs font-bold py-1 rounded-md text-white mt-41 w-auto text-center">
                               Draft Approved
                            </div>
                        </div>
                        )}
                        {draft && draft.whitelist_status ? 
                          draft.whitelist_status  === "pending" ? 
                            <div className="my-2 mx-auto w-48 flex justify-center text-center">
                              <div className="bg-green px-2 mr-3 text-xs font-bold py-1 rounded-md text-white mt-41  text-center">
                                TikTok Ad Code Pending
                              </div>
                            </div>
                          : 
                            <div className="my-2 mx-auto w-48 flex justify-center text-center">
                              <div className="bg-green px-2 mr-3 text-xs font-bold py-1 rounded-md text-white mt-41  text-center">
                                TikTok Ad Code Received
                              </div>
                            </div>
                        : null}
                      {task && task.add_ons && task.add_ons.some((addOn) => addOn.name == "Whitelist" && addOn.platform.includes("instagram")) && ( 
                        <div>The creator has indicated that they have approved your ad partner request, if that is not reflected in your Meta Business Suite DO NOT approve the draft. Instead, leave a revision that informs them that they have not approved your ad partner request.</div> )}
                    </div>
                      )
                    })
                    ) : (
                      <p className='flex items-center justify-center mx-auto mt-24 text-center'>No drafts available.</p>
                    )}
                </Carousel>
              </div>
            
              {/* {deployAsset ? (
              <DraftVideo
                open={deployAsset}
                draftData={draftData.draftData}
                approveDraft={() => approveDraft()}
                onClose={() => setDeployAsset(false)}
                id={draftData.id}
                count={count}
                assetLen={assetLen}
                dCount={() => setCount(count - 1)}
                eCount={() => setCount(count + 1)}
                setSendNote={() => setSendNote(true)}
                sendNote={sendNote}
              />
              ) : null} */}
            </div>
          {sendNote ? (
          <div className="h-full w-6/3 mb-5">
            <div className="w-full bg-gray-200 mb-5 rounded-lg" style={{ height: '45vh' }}>
              <textarea
                className="focus:outline-none w-full bg-lightGray p-2 rounded-lg"
                style={{ height: '45vh' }}
                placeholder="Please provide revision note to the creator here..."
                name="notes"
                required
                ref={notes}
              />
            </div>
            <div className="items-center">
              <CustomButton
                onClick={(e) => {
                  e.preventDefault(); // Prevent form submission
                  sendRevisionNote(selectedDraft); // Call the sendRevisionNote function
                  setSendNote(false); // Reset sendNote state
                }}
                className=" text-white font-bold px-4 py-2">
                Submit Notes
              </CustomButton>
            </div>
          </div>
        ) : null}
         {reviews ? (
          <div className="">
            <GlobalDialog open={reviews} onClose={() => setReviews(false)}>
              <div className=''>
              <h1 className='font-bold text-center'>{selectedDraft.creator_name}'s review</h1>
             <img className='w-1/2 h1/2 my-5 mx-auto flex justify-center object-cover rounded-md' onContextMenu={(e) => e.preventDefault()} src = {selectedDraft.reviews.review}/>
             {/*<CustomButton className='mx-auto flex items-center mb-4 text-white font-bold 2xl:px-10 2xl:py-3 md:px-5 md:py-2' onClick={() => downloadPhotoInNewTab(selectedDraft.reviews.review)}>
                    Download
               </CustomButton>*/}
               </div>
            </GlobalDialog>
          </div>
        ): null}
         {photos ? (
          
        <GlobalDialog open={photos} onClose={() => setPhotos(false)}>
          <div className="flex mx-auto bg-white w-full flex-col h-full items-center">
            <h1 className='font-bold text-center'>{selectedDraft.creator_name}'s photos</h1>
            <div className="flex gap-4 mx-10 mt-20 items-center">
            {Object.values(selectedDraft.photos).map((photo, index) => (
              <div key={index} className={`w-1/3 relative ${Object.values(selectedDraft.photos).length === 1 ? 'mx-auto' : ''}`} style={{ paddingBottom: 'calc(33.333% - 0.5rem)' }}>
                <img className='w-full h-full border-4 object-cover rounded-md' onContextMenu={(e) => e.preventDefault()} src={photo.photo} />
                {/*<CustomButton className='mx-auto flex items-center mt-2 text-white font-bold px-10 py-3' onClick={() => downloadPhotoInNewTab(photo.photo)}>
                  Download
                </CustomButton>*/}
              </div>
            ))}
            </div>
          </div>
        </GlobalDialog>
          
        ) : null}
        {raw ? (
          <GlobalDialog open={raw} onClose={() => setRaw(false)}>
            <div className="flex mx-auto bg-white rounded-md flex-col h-full items-center">
              <h1 className="font-bold text-center">{selectedDraft.creator_name}'s raw footage</h1>
              <div className="relative flex gap-4 mx-auto mt-10 justify-center items-center"> {/* Add relative positioning here */}
                <Carousel
                  className="relative items-center justify-center 2xl:w-40rem" 
                  showIndicators={false} 
                  showArrows={true} 
                  showThumbs={false} 
                  useKeyboardArrows={true} 
                  swipeable={true}
                  renderArrowPrev={(clickHandler, hasPrev) => {
                    return (
                      <div
                        className={`${
                          hasPrev ? "absolute" : "hidden"
                        } top-1/2 left-0 flex justify-center items-center p-3 opacity-100 hover:opacity-100 cursor-pointer z-20 transform -translate-y-1/2`}
                        onClick={() => {
                          if (currentVideo) {
                            currentVideo.pause();
                          }
                          clickHandler();
                          setSendNote(false);
                          setShowVideoModal(false);
                        }}
                      >
                        <IoMdArrowDropleft className="2xl:w-24 2xl:h-24 w-16 h-16 text-Blue" />
                      </div>
                    );
                  }}
                  renderArrowNext={(clickHandler, hasNext) => {
                    return (
                      <div
                        className={`${
                          hasNext ? "absolute" : "hidden"
                        } top-1/2 right-0 flex justify-center items-center p-3 opacity-100 hover:opacity-100 cursor-pointer z-20 transform -translate-y-1/2`}
                        onClick={() => {
                          if (currentVideo) {
                            currentVideo.pause();
                          }
                          clickHandler();
                          setSendNote(false);
                          setShowVideoModal(false);
                        }}
                      >
                        <IoMdArrowDropright className="2xl:w-24 2xl:h-24 w-16 h-16 text-Blue" />
                      </div>
                    );
                  }}
                >
                  {Object.values(selectedDraft.raw_footage).map((file, index) => (
                    <div key={index}>
                      {/*{file.file.replace("https://crafted-react-images.s3.amazonaws.com/", "")}*/}
                      <video className="w-fit mx-auto my-5 min-w-1/3 2xl:h-328 md:h-18 rounded-lg" src={file.file} controls />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </GlobalDialog>

        ) : null}
         {recipes ? (
               <GlobalDialog open={recipes} onClose={() => setRecipes(false)}>
               <div className="flex mx-auto bg-white w-full flex-col h-full items-center">
                 <h1 className='font-bold text-base text-center'>{selectedDraft.creator_name.split("_")[1]}'s recipe</h1>
                 <div className="flex items-center w-full md:w-11/12 md:max-w-6xl md:mx-auto mx-0 mb-20 md:mb-52">
        {selectedDraft.recipes && (
          <div id="main" className="w-full mx-6 md:mx-0">
            <div className="mb-6 bg-gray-100 px-5 py-4 rounded-2xl lg:flex hidden " id="top-banner">
              <img src={selectedDraft.recipes.thumbnail} alt={selectedDraft.recipes.name} onContextMenu={(e) => e.preventDefault()} className="w-80 h-80 object-cover" />
              <div className="ml-9">
                <div>
                  <p className="font-rubik font-bold text-2xl text-black mb-4">{selectedDraft.recipes.name}</p>
                </div>
                <div className="flex flex-col items-start">
                  {selectedDraft.recipes.metatags !== undefined ? (
                    <div className="flex mr-5  mb-4 " id="meta-asset.metatags">
                      {Object.values(selectedDraft.recipes.metatags).map((tag, i) => {
                        return (
                          <div
                            className="bg-lightBlue text-xs font-bold text-white rounded-xl py-1 px-3 mr-1"
                            key={i}>
                            {tag}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
                <div className="text-xs my-5 font-bold mb-3">{selectedDraft.recipes.description}</div>
              </div>
            </div>
            <div className="mb-6 rounded-2xl flex lg:hidden" id="top-banner">
              <div className="w-full bg-lightGray items-center rounded-xl p-3 flex flex-col">
                <img
                  src={selectedDraft.recipes.thumbnail}
                  className="w-full md:w-64 h-auto md:h-64 mb-3 rounded-2xl object-cover"
                  alt={selectedDraft.recipes.name}
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className="text-xl mb-1 font-bold"> {selectedDraft.recipes.name}</div>
                <div className="text-xs font-bold mb-3">{selectedDraft.recipes.description}</div>
                <div className="flex">
                  {selectedDraft.recipes.metatags !== undefined ? (
                    <div className="flex mr-5 " id="meta-asset.metatags">
                      {Object.values(selectedDraft.recipes.metatags).map((tag, i) => {
                        return (
                          <div
                            className="bg-lightBlue text-xs font-bold text-white rounded-xl py-1 px-3 mr-1"
                            key={i}>
                            {tag}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row">
              <div id="ingredient" className="bg-lightGray p-7 rounded-2xl">
                <div className="text-2xl text-center font-bold font-rubik">Ingredients</div>
                <hr className="w-full my-6 border-t-2 border-divider" />
                {selectedDraft.recipes.ingredients !== undefined
                  ? Object.values(selectedDraft.recipes.ingredients).map((ing, i) => {
                      return (
                        <li className="mb-3 font-rubik text-xl" key={i}>
                          {ing.measurement} {ing.unit} {ing.name}
                        </li>
                      );
                    })
                  : null}
              </div>
              <div id="directions" className="flex-1 py-7 pl-7 rounded-2xl">
                <div className="text-2xl text-center font-bold font-rubik">Directions</div>
                <hr className="w-full my-6 border-t-2 border-divider" />
                {selectedDraft.recipes.steps !== undefined
                  ? Object.values(selectedDraft.recipes.steps).map((d, i) => {
                      return (
                        <div className="mb-3 font-rubik text-xl" key={i}>
                          {i + 1}. {d.step}
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        )}

      </div>
               </div>
             </GlobalDialog>)
              : null}   
    </div>
  );
}

export default UgcVideoDrafts;
