import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import Collapsible from 'react-collapsible';
import { useFeatureRecipeState, featureRecipeService } from '../../Service/FeaturedRecipeService';
import { BsChevronCompactDown, BsChevronCompactUp } from 'react-icons/bs';

const bg = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.005)'
  }
};
const OrderDetails = (props) => {
  const [isOpen] = useState(true);
  const [featuredRecipe, setFeaturedRecipe] = useState(true);
  const featuredRecipeState = useFeatureRecipeState();

  useEffect(() => {
    if (props.host_id) {
      featureRecipeService.FetchSingleFeatureRecipe(props.host_id);
    }
    if (featuredRecipeState.featureRecipe.recipe) {
      setFeaturedRecipe(featuredRecipeState.featureRecipe.recipe.value);
    }
  }, []);

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={() => {
          props.closeAction();
        }}
        center
        styles={bg}
        classNames={{ modal: 'customModal' }}
        closeOnOverlayClick={true}>
        <div>
          {featuredRecipe ? (
            <>
              <h1 className="font-bold mb-3 font-black xs:text-lg md:text-4xl">Order Details</h1>
              <Collapsible
                trigger={[
                  'Customer number  ',
                  <div className="px-3 absolute right-0">
                    <BsChevronCompactDown />
                  </div>
                ]}
                triggerWhenOpen={[
                  'Customer number  ',
                  <div className="px-3 absolute right-0">
                    <BsChevronCompactUp />
                  </div>
                ]}
                contentOuterClassName="mb-6"
                triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-darkBlack bg-lightGray relative"
                triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
                <p className="mt-6">
                  <label className="text-black block md:text-lg mb-2 font-extrabold">
                    Customer number
                  </label>
                  <label className="block">{props.customer}</label>

                  <br></br>
                </p>
              </Collapsible>
              <Collapsible
                trigger={[
                  'Product SKU',
                  <div className="px-3 absolute right-0">
                    <BsChevronCompactDown />
                  </div>
                ]}
                triggerWhenOpen={[
                  'Product SKU',
                  <div className="px-3 absolute right-0">
                    <BsChevronCompactUp />
                  </div>
                ]}
                contentOuterClassName="mb-6"
                triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-darkBlack bg-lightGray relative"
                triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
                <div className="flex-col flex p-3">
                  <p className="mt-6">
                    <label className="text-black block md:text-lg font-extrabold">
                      Product SKU
                    </label>
                    <label className="text-black block md:text-xs font-extrabold">kk16Av 306</label>
                    <br />
                  </p>
                </div>
              </Collapsible>
              <Collapsible
                trigger={[
                  'Quantities',
                  <div className="px-3 absolute right-0">
                    <BsChevronCompactDown />
                  </div>
                ]}
                triggerWhenOpen={[
                  'Quantities',
                  <div className="px-3 absolute right-0">
                    <BsChevronCompactUp />
                  </div>
                ]}
                contentOuterClassName="mb-6"
                triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-darkBlack bg-lightGray relative"
                triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
                <div className="flex-col flex p-3">
                  <p className="mt-6">
                    <label className="text-black block md:text-lg font-extrabold">Quantities</label>
                    <label className="text-black block md:text-xs font-extrabold">kk16Av 306</label>
                  </p>
                </div>
              </Collapsible>

              <Collapsible
                trigger={[
                  'Dollar amounts',
                  <div className="px-3 absolute right-0">
                    <BsChevronCompactDown />
                  </div>
                ]}
                triggerWhenOpen={[
                  'Dollar amounts',
                  <div className="px-3 absolute right-0">
                    <BsChevronCompactUp />
                  </div>
                ]}
                contentOuterClassName="mb-6"
                triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-darkBlack bg-lightGray relative"
                triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
                <div className="flex-col flex p-3">
                  <p className="mt-6">
                    <label className="text-black block md:text-lg font-extrabold">
                      Dollar amounts
                    </label>
                    <label className="text-black block md:text-xs font-extrabold">
                      ${props.total}
                    </label>
                  </p>
                </div>
              </Collapsible>

              <Collapsible
                trigger={[
                  'Shipping details',
                  <div className="px-3 absolute right-0">
                    <BsChevronCompactDown />
                  </div>
                ]}
                triggerWhenOpen={[
                  'Shipping details',
                  <div className="px-3 absolute right-0">
                    <BsChevronCompactUp />
                  </div>
                ]}
                contentOuterClassName="mb-6"
                triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-darkBlack bg-lightGray relative"
                triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
                <div className="flex-col flex p-3">
                  <p className="mt-6">
                    <label className="text-black block md:text-lg font-extrabold">
                      Shipping details
                    </label>
                    <label className="text-black block md:text-xs font-extrabold">
                      {props.shipping_details}
                    </label>
                  </p>
                </div>
              </Collapsible>
            </>
          ) : null}
        </div>
      </Modal>
    </div>
  );
};
export default OrderDetails;
