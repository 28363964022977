import React, { useEffect, useState} from 'react'
import ViewCampaignTasks from './ViewCampaignTasks'
import GlobalDialog from '../../../utils/globalDialog';
import { BsExclamationCircle } from 'react-icons/bs';
import { TaskService } from '../../../Service/TaskServices';
import { CampaignService, useCampaignState } from '../../../Service/CampaignServices';
import { useAuth } from '../../../Context/AuthContext';
import Modal from 'react-responsive-modal';
import { useParams } from 'react-router-dom';
import AddTaskModal from './CampaignDetails/AddTaskModal';
function EditCampaignTasks(props) {
  const {id} = useParams();
  const [loading, setLoading] = useState(false);
  const [addTask, setAddTask] = useState(false);
  const addHandler = () => {
    setAddTask(true);
  }
  const fetchData = () => {
    if (id) {
      setLoading(true);
      CampaignService.FetchSingleCampaign(id);
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="relative">
      {/* Place the Add Task button in the top right corner */}


      {/* Add a top padding to the container to make space for the absolutely positioned button */}
      <div className="pt-10">
        {/* Tasks list component */}
        <ViewCampaignTasks fetchData = {fetchData} className='mt-3' />

        {/* Next button */}
        <div className="flex justify-center mt-4 md:mt-8 mb-2 md:mb-4">
          <button
            type="submit"
            onClick={() => props.goToNextTab()}
            className="py-2 bg-red w-1/2 md:w-1/3 px-4 mb-5 rounded-lg text-white mt-4 justify-center cursor-pointer"
          >
            Next
          </button>
        </div>
      </div>

      {/* Add Task Modal */}
      {addTask && (
        <AddTaskModal
         className='h-screen'
          open={addTask}
          onClose={() => setAddTask(false)}
          campaignId={id}
          fetchData = {fetchData}
        />
      )}
    </div>
  )
}

export default EditCampaignTasks