import React, {useState, useEffect} from 'react'
import { Modal } from 'react-responsive-modal';
import Collapsible from 'react-collapsible';
import {toast, Toaster} from 'react-hot-toast';
import Loader from '../Loader';
import { useAuth } from '../../Context/AuthContext';
import { BsChevronCompactDown, BsChevronCompactUp } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { TaskService } from '../../Service/TaskServices';
import CustomButton from '../../utils/customButton';
const bg = {
  overlay: {
    background: 'rgba(128, 128, 128, 0.099)'
  }
};
function EditTaskModal(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState('');
  const [isPhotosChecked, setIsPhotosChecked] = useState(false);
  const [isReviewChecked, setIsReviewChecked] = useState(false);
  const [isRecipesChecked, setIsRecipesChecked] = useState(false);
  const [note1, setNote1] = useState(props.task.note1);
  const [note2, setNote2] = useState(props.task.note2);
  const [note3, setNote3] = useState(props.task.note3);
  const [name, setName] = useState(props.task.name ? props.task.name : '');
  const {currentBrand} = useAuth();
  console.log(props.id[0]);
  console.log( props.task.creator_id)

  const handleNote1Change = (event) => {
    setNote1(event.target.value);
  };

  const handleNote2Change = (event) => {
    setNote2(event.target.value);
  };

  const handleNote3Change = (event) => {
    setNote3(event.target.value);
  };
  
  const editTask = (e) =>{
    setIsSubmitting(true);
    e.preventDefault();
    const data = {
     creator_id: props.task && props.task.creator_id,
      brand_id: props.task.brand_id,
      task_id: props.id[0],
      name: name,
      note1: note1,
      note2: note2,
      note3: note3,
      add_ons: []
    } 
    if (isRecipesChecked) {
      data.add_ons.push({
        name: "Recipes",
        price: 25
      });
    }
    if (isReviewChecked) {
      data.add_ons.push({
        name: "Reviews",
        price: 15
      });
    }
    if (isPhotosChecked) {
      data.add_ons.push({
        name: "Photos",
        price: 10
      });
    }
    TaskService.EditTask(props.id, data);
    toast.success('Task Edited Successfully', {duration: 1800});
      setTimeout(() => {
        props.close();
      } , 2000);
   ;
  }

  console.log(props.task.note1);
  return (
    <Modal
    open={props.open}
    onClose={props.close}
    center
    styles={bg}
    classNames={{ modal: 'customModal' }}
    closeOnOverlayClick={true}>
      <Toaster/>
      <div className='font-bold flex justify-center xs:xl md:text-4xl mb-1'> Edit Task</div>
      <hr />
                <h1 className="md:text-3xl mt-5 font-bold text-center">
                  Edit your current Studio task
                </h1>
                <p className='mt-1 italic text-xs text-center text-darkGray'><strong>NOTE:</strong> Each task can only be accepted by 1 creator. </p>
                <p className='mb-5 italic text-xs text-center text-darkGray'>If you want multiple creators, please create mulitple tasks.</p>
                <form onSubmit={editTask} className="px-4">
                <div className="my-2 w-full mr-2">
                  <label htmlFor="description" className="text-black block md:text-lg mb-2 font-bold">
                    What is the name of your Studio task?
                  </label>
                  <input
                    name="name"
                    className="outline-none bg-gray-100 p-3 rounded-lg w-full"
                    placeholder="Enter your studio task name."
                    required
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                  <div className="text-red px-3 font-medium">{errors.note2 || ''}</div>
                </div>
              <div className="my-2">
                <label htmlFor="note1" className="text-black block md:text-lg mb-2 font-bold">
                  What product do you want to showcase?
                </label>
                <input
                  name="note1"
                  className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                  placeholder="Enter the products you want to showcase."
                  required
                  value={note1}
                  onChange={handleNote1Change}
                />
               <div className="text-red px-3 font-medium">{errors.note1 || ''}</div>
              </div>
              <div className="my-2">
                <label htmlFor="description" className="text-black block md:text-lg mb-2 font-bold">
                  What is your target demographic?
                </label>
                <input
                  name="note2"
                  className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                  placeholder="Enter your target demographic."
                  required
                  value={note2}
                  onChange={handleNote2Change}
                />
                <div className="text-red px-3 font-medium">{errors.note2 || ''}</div>
              </div>
              <div className="my-2 mt-2">
                <label htmlFor="note3" className="text-black block md:text-lg mb-2 font-bold">
                  Are there any additional notes?
                </label>
                <input
                  name="note3"
                  className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                  placeholder="Enter any talking points you'd like the creator to include."
                  required
                  value={note3}
                  onChange={handleNote3Change}
                />
                
             
                      <div className="flex justify-center">
                        <CustomButton
                          type="submit"
                          disabled={isSubmitting}
                          className="py-2 w-fit px-4 text-white font-bold mt-4 items-center justify-center text-center cursor-pointer">
                          {isSubmitting  && <Loader />}
                          Submit
                        </CustomButton>
                      </div>    
                </div>
            
            </form>
  </Modal>
  )
}

export default EditTaskModal