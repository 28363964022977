import React, { useEffect, useState, useContext } from 'react';
import TaskCard from './TaskCard';
import MyInfluencerTasks from './MyInfluencerTasks';
import { useAuth } from '../../Context/AuthContext';
import { TaskService, useTaskState } from '../../Service/TaskServices';
import MyTaskCard from './MyTaskCard';
import Loader from '../Loader2';
import { useHistory } from 'react-router-dom';
import { useBrandState, BrandService } from '../../Service/BrandServices';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';
import { useAccountState, AccountService } from '../../Service/AccountServices';

function StudioBriefPreview(props) {
  const [loading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false);
  }, 1200);

  //console.log("props.tasks", props.tasks)
    return (
  <div className={`bg-white rounded-lg w-full h-full ${loading ? '' : 'overflow-y-scroll'}`} style={{height: '40vh'}}> 
   <div  className='w-full' style={{ position: 'sticky', top: 0, zIndex: 1 }}>
        <h3 className="font-semibold ml-4 bg-white w-full 2xl:text-base md:text-0.7 xs:text-sm">Ongoing UGC Briefs</h3>
      </div>
  {loading ? (
    <div className = "w-full"style={{maxHeight: '40vh'}}>
      <Loader/>
    </div>
  ) : (
    <div className="bg-white rounded-lg h-fit p-2 xs:p-0">
 <div className="h-21" style={{height: '40vh'}}>
    {(!props.tasks || props.tasks.length === 0 || 
      props.tasks.filter(item => ["approved", "drafted", "revisions", "submitted"].includes(item[Object.keys(item)[0]].status)).length === 0) && (
        <>
        <div className="md:text-base xs:text-0.7 flex mt-10 font-bold items-center justify-center">
          You have no ongoing UGC briefs.
        </div>
        <div className="md:text-base xs:text-0.7 text-center text-lg flex mt-10 font-bold items-center justify-center">
        Influencer briefs will appear here once you have applied and are accepted for one.
        </div>
      </>
    )}
<div className=" m-4 grid lg:grid-cols-2 xs:grid-cols-1 md:grid-cols-2 gap-2">
  {props.tasks &&
   props.tasks
      .filter(item => ["approved", "drafted", "revisions", "submitted"].includes(item[Object.keys(item)[0]].status))
      .slice(0)
      .reverse()
      .slice(0, 4)
      .map((item, i) => {
        const res = item[Object.keys(item)];
        const id = Object.keys(item);
        return (
          <div key={i}>
            <MyTaskCard task={res} id={id} />
          </div>
        );
      })}
    {props.tasks.filter((item) => ["approved", "drafted", "revisions", "submitted"].includes(item[Object.keys(item)[0]].status)).length > 4 && (
        <div className="bg-red text-white flex mx-auto rounded-md mt-2 items-center justify-center p-2 backdrop-blur">
          <a href="/creator/dashboard/mytasks" className="text-white">
            <span>View More</span>
          </a>
        </div>
    )}
</div>
      </div>
    </div>
  )}
</div>
  );
}

export default StudioBriefPreview;
