import React from "react";
import { toast, Toaster } from 'react-hot-toast';
import '../../../App.css';
import PlacesAutocomplete from 'react-places-autocomplete';
import { useEffect , useState} from "react";
import { UsersService, useUserState } from '../../../Service/userServices';

const UserAdressForm = (props) => {
  const { userInfo, userID } = props;
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [success, setSuccess] = useState(false);
  const [fullname, setFullname] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  function handleChange(address) {
    //HANDLE CHANGE
    setAddress1(address);
  }
  function handleSelect(address, placeId, suggestion) {
    //HANDLE SELECT
    setAddress1(suggestion.formattedSuggestion.mainText);
    setCity(suggestion.terms[suggestion.terms.length - 3].value);
    setState(suggestion.terms[suggestion.terms.length - 2].value);
  }
  const [user, setUser] = useState(userInfo);
  
  useEffect(() => {
    if( userInfo.shipping_details){
      setAddress1(userInfo.shipping_details.address1 ? userInfo.shipping_details.address1 : '');
      setAddress2(userInfo.shipping_details.address2 ? userInfo.shipping_details.address2 : '');
      setCity(userInfo.shipping_details.city ? userInfo.shipping_details.city : '');
      setState(userInfo.shipping_details.state ? userInfo.shipping_details.state : '');
      setFullname(userInfo.shipping_details.fullname ? userInfo.shipping_details.fullname : '');
      setZipcode(userInfo.shipping_details.zipcode ? userInfo.shipping_details.zipcode : '');
    }else{
      
    }  
  }, [userInfo]);


  // useEffect(() => {
  //   setUser({
  //     shipping_details:{
  //       address1: address1,
  //       address2: address2,
  //       city: city,
  //       state: state,
  //       zipcode: zipcode,
  //       fullname: fullname,
  //     }
  //   })
  // }, [])

  useEffect(() => {
    console.log(user)
  }, [user])
  
  

  function saveAddress(event) {
    event.preventDefault();
    let mailing_address = {
      fullname: fullname,
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      country: 'USA',
      zipcode: zipcode
    };

    UsersService.AddAddress(userID, mailing_address);

    setSuccess(true);

    setTimeout(() => {
      setSuccess(false);
    }, 10000);
  }

  return(
    <div>
    {/* EDIT ADDRESSS */}
      <div className="bg-white m-4 rounded-lg w-full  p- h-fit" >
        <h2 className="font-semibold py-2 text-2xl">Profile</h2>
        <Toaster />
        <>
          {
            <div>
              <>
                <div className="mb-3"></div>
              </>{' '}
              <div className="mb-3">
                <label className="text-darkBlack flex font-bold capitalize">Mailing Address</label>
                <div className="text-center">
                  <h2>Current Mailing Address</h2>
                  {user && user.shipping_details ? (
                    <p className="mt-6">
                      <label className="block font-extrabold">{user.shipping_details && user.shipping_details.fullname && (user.shipping_details.fullname)}</label>
                      <label className="block font-extrabold">{user.shipping_details && user.shipping_details.address1 && (user.shipping_details.address1)}</label>
                      <label className="block font-extrabold">{user.shipping_details && user.shipping_details.address2 && (user.shipping_details.address2)}</label>
                      <label className="block font-extrabold">
                        {user.shipping_details && user.shipping_details.city && (user.shipping_details.city)}, {user.shipping_details && user.shipping_details.state && (user.shipping_details.state)}, USA{' '}
                        {user.shipping_details && user.shipping_details.zipcode && (user.shipping_details.zipcode)}
                      </label>
                    </p>
                  ) : (
                    <div>
                      No address found.
                    </div>
                  )}
                </div>
              </div>
              <PlacesAutocomplete
                value={address1}
                onChange={handleChange}
                onSelect={handleSelect}
                className="flex focus:outline-none w-full bg-lightGray p-2 mt-2 rounded">
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div>
                    <input
                      {...getInputProps({
                        placeholder: 'Search Places ...',
                        className: 'location-search-input focus:outline-none w-full bg-lightGray p-2 mt-2 rounded'
                      })}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? 'suggestion-item--active'
                          : 'suggestion-item';
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style
                            })}>
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>

              <input
                className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
                type="text"
                name="name"
                placeholder="Full Name"
                value={fullname}
                onChange={(event) => setFullname(event.target.value)}
                required
              />
              <input
                className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
                type="text"
                name="address1"
                placeholder="Street Address"
                value={address1}
                onChange={(event) => setAddress1(event.target.value)}
                required
              />
              <input
                className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
                type="text"
                name="address2"
                placeholder="Apt Number, Suite, etc."
                value={address2}
                onChange={(event) => setAddress2(event.target.value)}
              />
              <div className=" grid grid-cols-3 gap-4">
                <input
                  className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded mr-2"
                  type="text"
                  name="city"
                  placeholder="City"
                  value={city}
                  onChange={(event) => setCity(event.target.value)}
                  required
                />
                <input
                  className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded mr-2"
                  type="text"
                  name="state"
                  placeholder="State"
                  value={state}
                  onChange={(event) => setState(event.target.value)}
                  required
                />
                <input
                  className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
                  type="text"
                  name="zipcode"
                  placeholder="Zipcode"
                  value={zipcode}
                  onChange={(event) => setZipcode(event.target.value)}
                  required
                />
              </div>
              {success ? (
                <>
                  <button
                    className="bg-green text-white px-4 py-2 rounded-lg mt-2"
                    onClick={(event) => saveAddress(event)}>
                    Updated!
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="bg-red text-white px-4 py-2 rounded-lg mt-2"
                    onClick={(event) => saveAddress(event)}>
                    Save
                  </button>
                </>
              )}
            </div>
          }
        </>
      </div>
    </div>
  )
}

export default UserAdressForm;