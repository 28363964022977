import React, { useState, useEffect } from 'react';
import { Modal } from 'react-responsive-modal';
import GenerateBriefModal from '../GenerativeBriefs/GenerateBriefModal';
import NewTaskModal from '../NewTaskModal';
import StudioOutReach from '../StudioOutreach';
import GenerateBriefOnboarding from './GenerativeBriefOnboarding';
import NewProductOnboarding from './NewProductModalOnboarding';
import { FaProductHunt, FaTemplate, FaTasks, FaBullhorn, FaBoxOpen} from 'react-icons/fa';
import { HiSparkles } from "react-icons/hi2";
import { IoMdMegaphone } from "react-icons/io";
import CustomButton from '../../../utils/customButton';
import NewTrialTaskModal from '../NewTrialTaskModal';
import giftBox from '../../../Assets/gift.png'
import ai from '../../../Assets/artificial-intelligence.png'
import logo from '../../../Assets/logo-red-ugc.png'
import list from '../../../Assets/list.png'
import megaphone from '../../../Assets/megaphone.png'
import TrialSelectPlanModal from './TrialSelectPlanModal';
import OnboardingSelectPlan from './OnboardingSelectPlan';
function TaskCreationModal(props) {
  const [activeTab, setActiveTab] = useState(0); // Initialize activeTab state to 0
  const [taskData, setTaskData] = useState(null);
  const [briefCreated, setBriefCreated] = useState(false);
  const [briefData, setBriefData] = useState(null);
  const [productName, setProductName] = useState('');
  useEffect(() => {
    if (activeTab === 5) {
      localStorage.setItem('disableModal', 'true');
    }
  }, [activeTab]);
  return (
    <Modal
      open={props.isOpen}
      onClose={() => {
        props.closeAction();
      }}
      center
      classNames={{ modal: 'customWideModal' }}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      >
      <ul style={{display: 'flex', borderBottom: '1px solid black'}}>
        {/* Add an additional tab for the explanation */}
        {/* Adjust the index for each tab accordingly */}
        <li className={`p-2.5 cursor-pointer flex-1 text-center 2xl:text-base md:text-0.7 ${activeTab === 0 ? 'border-b-2 border-Blue' : ''}`} >Overview</li>
        <li className={`p-2.5 cursor-pointer flex-1 text-center 2xl:text-base md:text-0.7 ${activeTab === 1 ? 'border-b-2 border-Blue' : ''}`} >Product Details</li>
        <li className={`p-2.5 cursor-pointer flex-1 text-center 2xl:text-base md:text-0.7 ${activeTab === 2 ? 'border-b-2 border-Blue' : ''}`} >Generate Creative Brief</li>
        <li className={`p-2.5 cursor-pointer flex-1 text-center 2xl:text-base md:text-0.7 ${activeTab === 3 ? 'border-b-2 border-Blue' : ''}`} >Create Studio Task</li>        
        <li className={`p-2.5 cursor-pointer flex-1 text-center 2xl:text-base md:text-0.7 ${activeTab === 5 ? 'border-b-2 border-Blue' : ''}`} >Creator Outreach</li>
      </ul>
      {activeTab === 0 && (
        <div className="mx-auto bg-white p-4">
        <h1 className="2xl:text-2xl md:text-base font-semibold text-center text-gray-800 mb-4">Get high converting UGC in less than one week!</h1>
        <hr />
        <p className='text-gray-500 2xl:text-base md:text-0.7 text-center my-4'>Follow these steps to get quality ad content from our vetted creators.</p>
          <div className="flex flex-wrap 2xl:mt-6rem md:mt-0 space-x-3 justify-center mt-5">
            <div className="bg-gradient-to-b from-white to-lightGray p-4 rounded-md border-2 shadow-lg 2xl:w-18rem 2xl:h-22rem md:w-12rem md:h-18rem flex flex-col justify-between">
              <h3 className="font-semibold text-gray-800 text-center 2xl:text-2xl md:text-0.7">1. Add a Product</h3>
              <div className="flex justify-center my-4">
              <img className='2xl:h-10rem 2xl:w-10rem md:h-8rem md:w-8rem' src = {giftBox} />
                {/*<a href="https://www.flaticon.com/free-stickers/document" title="document stickers">Document stickers created by Stickers - Flaticon</a>*/}
              </div>
              <p className="text-center 2xl:text-base md:text-0.5 font-bold">Begin by telling us which product you are looking to promote.</p>
            </div>
            <div className="bg-gradient-to-b from-white to-lightGray p-4 rounded-md border-2 shadow-lg 2xl:w-18rem 2xl:h-22rem md:w-12rem md:h-18rem flex flex-col justify-between">
              <h3 className="font-semibold text-gray-800 text-center 2xl:text-2xl md:text-0.7">2. Generate Brief</h3>
              <div className="flex justify-center my-4">
              <img className='2xl:h-10rem 2xl:w-10rem md:h-8rem md:w-8rem' src = {ai} />
                {/*<a href="https://www.flaticon.com/free-stickers/document" title="document stickers">Document stickers created by Stickers - Flaticon</a>*/}
              </div>
              <p className="text-center 2xl:text-base md:text-0.5 font-bold">Craft a creative brief that informs creators on how to best promote your product.</p>
            </div>
            <div className="bg-gradient-to-b from-white to-lightGray p-4 rounded-md border-2 shadow-lg 2xl:w-18rem 2xl:h-22rem md:w-12rem md:h-18rem flex flex-col justify-between">
              <h3 className="font-semibold text-gray-800 text-center 2xl:text-2xl md:text-0.7">3. Create Studio Task</h3>
              <div className="flex justify-center my-4">
              <img className='2xl:h-10rem 2xl:w-10rem md:h-8rem md:w-8rem' src = {list} />
                {/*<a href="https://www.flaticon.com/free-stickers/document" title="document stickers">Document stickers created by Stickers - Flaticon</a>*/}
              </div>
              <p className="text-center 2xl:text-base md:text-0.5 font-bold">Specify remaining details about your task.</p>
            </div>
            <div className="bg-gradient-to-b from-white to-lightGray p-4 rounded-md border-2 shadow-lg 2xl:w-18rem 2xl:h-22rem md:w-12rem md:h-18rem flex flex-col justify-between">
              <h3 className="font-semibold text-gray-800 text-center 2xl:text-2xl md:text-0.7">4. Creator Outreach</h3>
              <div className="flex justify-center my-4">
                <img className='2xl:h-10rem 2xl:w-10rem md:h-8rem md:w-8rem' src = {megaphone} />
                {/*<a href="https://www.flaticon.com/free-stickers/document" title="document stickers">Document stickers created by Stickers - Flaticon</a>*/}
              </div>
              <p className="text-center 2xl:text-base md:text-0.5 font-bold">Send an email to relevant Crafted creators inviting them to your new task.</p>
            </div>
          </div>
          <div className="mt-8 flex justify-center">
            <CustomButton
              className="mt-4 text-white font-bold 2xl:py-4 2xl:px-6 py-2 px-4 shadow-md focus outline-none  transform transition-transform duration-500 ease-in-out hover:scale-105 2xl:text-2xl md:text-base"
              onClick={() => setActiveTab(1)}
            >
              Get Started
            </CustomButton>
          </div>
        </div>
      )}
      {activeTab === 1 && <NewProductOnboarding taskData={taskData} setTaskData={setTaskData} setActiveTab={setActiveTab} isOpen={props.isOpen} closeAction={props.closeAction} setProductName={setProductName} />} 
      {activeTab === 2 && <GenerateBriefOnboarding taskData={taskData} setTaskData={setTaskData} setActiveTab={setActiveTab} briefCreated={briefCreated} briefData={briefData} setBriefData={setBriefData} setBriefCreated={setBriefCreated} isOpen={props.isOpen} closeAction={props.closeAction} productName = {productName} />}
      {activeTab === 3 && <NewTrialTaskModal briefData= {briefData} setBriefData={setBriefData} taskData={taskData} setTaskData={setTaskData} setActiveTab={setActiveTab} isOpen={props.isOpen} closeAction={props.closeAction } briefCreated={briefCreated}  setBriefCreated={setBriefCreated} />}
      {activeTab === 4 && <OnboardingSelectPlan briefData= {briefData} setBriefData={setBriefData} taskData={taskData} setTaskData={setTaskData} setActiveTab={setActiveTab} isOpen={props.isOpen} closeAction={props.closeAction } briefCreated={briefCreated}  setBriefCreated={setBriefCreated} />}
      {activeTab === 5 && <StudioOutReach taskData={taskData} isOpen={props.isOpen} closeAction={props.closeAction}/>}
    </Modal>
  );
}

export default TaskCreationModal;