import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';

//State
const state = createState({
  products: [],
  product: [],
  featuredProducts: [],
  featuredProduct: [],
  bookedProduct: [],
  bookedProducts: [],
  retrieving: false,
  fetched: false,
  loading: false,
  updateNeed: true
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'PRODUCTS_LOADED':
        return s.merge({
          products: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
          fetched: true
        });
      case 'CREATE_PRODUCT':
        return s.merge({
          product: action.data,
          loading: false,
          updateNeed: true
        });
      case 'MARK_BOOK_MARKED':
        return s.merge({
          bookedProduct: action.data,
          loading: false,
          updateNeed: true
        });
      case 'BOOK_MARKED_LOADED':
        return s.merge({
          bookedProducts: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
          fetched: true
        });
      case 'PRODUCT_UPDATE':
        return s.merge({
          updateNeed: true,
          loading: false
        });
      case 'PRODUCT_DELETE':
        return s.merge({
          loading: false,
          updateNeed: true
        });
      case 'PRODUCT_LOADED':
        return s.merge({
          product: action.data,
          updateNeed: false,
          loading: false,
          fetched: true
        });
      case 'CREATE_FEATURED_PRODUCT':
        return s.merge({
          featuredProduct: action.data,
          loading: false,
          updateNeed: true
        });
      case 'FEATURED_PRODUCTS_LOADED':
        return s.merge({
          featuredProducts: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
          fetched: true
        });
      default:
        return null;
    }
  }, action.type);
});

export const accessProductState = () => state;

export const useProductState = () => useState(state);
//Service
export const ProductService = {
  FetchProductForUser: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/brands/products/${id}`)
      .then((res) => {
        dispatch(ProductAction.loadUserProducts(res.data.result));
      })
      .catch((err) => {});
  },
  FetchProducts: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/products`)
      .then((res) => {
        dispatch(ProductAction.loadUserProducts(res.data.result));
      })
      .catch((err) => {});
  },
  AddProduct: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/products/createProduct`, data)
      .then((res) => {
        dispatch(ProductAction.createProduct(res));
      })
      .catch((err) => {});
  },
  MarkBookmarked: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/products/markBookmarked`, data)
      .then((res) => {
        dispatch(ProductAction.markBookmarked(res));
      })
      .catch((err) => {});
  },
  FetchBookedProducts: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/users/bookmarked_products/${id}`)
      .then((res) => {
        dispatch(ProductAction.loadUserBookedProducts(res.data.result));
      })
      .catch((err) => {});
  },
  UpdateProduct: (id, data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/products/updateProduct/${id}`, data)
      .then((res) => {
        dispatch(ProductAction.updateProduct(res));
      })
      .catch((err) => {});
  },
  DeleteProduct: (id, data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/products/deleteProduct/${id}`, data)
      .then((res) => {
        dispatch(ProductAction.deleteProduct(res));
      })
      .catch((err) => {});
  },
  FetchSingleProduct: (id) => {
    const dispatch = useDispatch();

    axios
      .get(`${baseUrl}api/products/${id}`)
      .then((res) => {
        dispatch(ProductAction.loadSingleProduct(res.data.result));
      })
      .catch((err) => {});
  },
  CreateFeaturedProducts: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/products/setFeaturedProducts`, data)
      .then((res) => {
        dispatch(ProductAction.createFeaturedProducts(res));
      })
      .catch((err) => {});
  },
  FetchFeaturedProductForUser: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/products/getFeaturedProducts/${id}`)
      .then((res) => {
        dispatch(ProductAction.loadUserFeaturedProducts(res.data.result));
      })
      .catch((err) => {});
  }
};

//Action
export const ProductAction = {
  loadUserProducts: (data) => {
    return {
      type: 'PRODUCTS_LOADED',
      data: data
    };
  },
  loadSingleProduct: (data) => {
    return {
      type: 'PRODUCT_LOADED',
      data: data
    };
  },
  markBookmarked: (data) => {
    return {
      type: 'MARK_BOOK_MARKED',
      data: data
    };
  },
  loadUserBookedProducts: (data) => {
    return {
      type: 'BOOK_MARKED_LOADED',
      data: data
    };
  },
  createProduct: (data) => {
    return {
      type: 'CREATE_PRODUCT',
      data: data
    };
  },
  createFeaturedProducts: (data) => {
    return {
      type: 'CREATE_FEATURED_PRODUCT',
      data: data
    };
  },
  updateProduct: (data) => {
    return {
      type: 'PRODUCT_UPDATE',
      data: data
    };
  },
  deleteProduct: (data) => {
    return {
      type: 'PRODUCT_DELETE',
      data: data
    };
  },
  loadUserFeaturedProducts: (data) => {
    return {
      type: 'FEATURED_PRODUCTS_LOADED',
      data: data
    };
  }
};
