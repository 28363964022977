import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';

//State
const state = createState({
  tasks: [],
  task: [],
  retrieving: false,
  fetched: false,
  loading: true,
  updateNeed: true
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'TASKS_LOADED':
        return s.merge({
          tasks: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true
        });
      case 'USER_TASKS_LOADED':
        return s.merge({
          userTasks: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true
        });
      case 'CREATE_TASK':
        return s.merge({
          task: action.data,
          loading: false,
          updateNeed: true
        });
      
      case 'CREATE_TRIAL_TASK':
        return s.merge({
          task: action.data,
          loading: false,
          updateNeed: true
        })
      case 'PROMO_FULFILLMENT':
        return s.merge({
          task: action.data,
          loading: false,
          updateNeed: true
        });

      case 'SHIP_FULFILLMENT':
        return s.merge({
          task: action.data,
          loading: false,
          updateNeed: true
        });
      
      case 'IN_STORE_FULFILLMENT':
        return s.merge({
          task: action.data,
          loading: false,
          updateNeed: true
        });  

      case 'PROPOSAL_DENIED':
        return s.merge({
          task: action.data,
          loading: false,
          updateNeed: true
        })
      
      case 'UNDO_FULFILLMENT':
        return s.merge({
          task: action.data,
          loading: false,
          updateNeed: true
        });

      case 'TASK_UPDATE':
        return s.merge({
          updateNeed: true,
          loading: false
        });
      case 'ADD_REVISION_NOTE':
        return s.merge({
          updateNeed: true,
          loading: false
        });
      case 'TASK_COMPLETE':
        return s.merge({
          updateNeed: true,
          loading: false
        });
      case 'TASK_DELETE':
        return s.merge({
          loading: false,
          updateNeed: true
        });
      case 'TASK_EDIT':
        return s.merge({
          loading: false,
          updateNeed: true
        })
      case 'TASK_LOADED':
        return s.merge({
          tasks: action.data,
          updateNeed: false,
          loading: false,
          fetched: true
        });
      case 'PROPOSAL_APPROVED':
        return s.merge({
          task: action.data,
          updateNeed: true,
          loading: false,
          fetched: true
        });
      case 'PRODUCT_REQ_TASK':
        return s.merge({
          updateNeed: true,
          loading: false,
          fetched: true
        });
      case 'TASK_EDITED':
        return s.merge({
          updateNeed: true,
          loading: false,
          fetched: true
        });

      case 'CREATOR_REMOVED':
        return s.merge({
          updateNeed: true,
          loading: false,
          fetched: true
        })

      case 'TASK_SEND_PROPOSAL':
        return s.merge({
          updateNeed: true,
          loading: false,
          fetched: true
        });
      case 'TASK_SEND_DRAFT':
          return s.merge({
            updateNeed: true,
            loading: false,
          });
      case 'TASK_SEND_TIKTOK_CODE':
        return s.merge({
          updateNeed: true,
          loading: false,
          fetched: true
        });
        case 'FIND_BRIEF_CREATORS':
          return s.merge({
            briefCreators: action.data,
            updateNeed: false,
            loading: false,
          });
      default:
        return null;
    }
  }, action.type);
});

export const accessTaskState = () => state;

export const useTaskState = () => useState(state);

//Service
export const TaskService = {
  FetchTasks: () => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/tasks`)
      .then((res) => {
        dispatch(TaskAction.loadTasks(res.data.result));
      })
      .catch((err) => {});
  },
  FetchTaskForUser: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/tasks/users/${id}`)
      .then((res) => {
        dispatch(TaskAction.loadUserTask(res.data.result));
      })
      .catch((err) => {});
  },
  AddTask: async (data) => {
    const dispatch = useDispatch();
    return await axios
      .post(`${baseUrl}api/tasks/createTask`, data)
      .then((res) => {
        dispatch(TaskAction.createTask(res));
        return res.data;
      })
      .catch((err) => {});
  },

  AddTrialTask: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/tasks/createTrialTask`, data)
      .then((res) => {
        dispatch(TaskAction.createTrialTask(res));
      }).catch((err) => {});
  },

  ApproveProposal: (id, data) => {
    const dispatch = useDispatch();
    return axios
      .post(`${baseUrl}api/tasks/approve/${id}`, data)
      .then((res) => {
        dispatch(TaskAction.approveProposal(res));
        return res
      })
  },

  DenyProposal: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/tasks/deny/${id}`, data)
      .then((res) => {
        dispatch(TaskAction.denyProposal(res));
      })
  },

  PromoFulfillment : (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/tasks/promoFulfillment/${id}`, data)
      .then((res) => {
        dispatch(TaskAction.promoFulfillment(res));
      })
      .catch((err) => {});
  },

  InStoreFulfillment : (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/tasks/inStoreFulfillment/${id}`, data)
      .then((res) => {
        dispatch(TaskAction.inStoreFulfillment(res));
      })
      .catch((err) => {});
  },
  ShipFulfillment : (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/tasks/shipFulfillment/${id}`, data)
      .then((res) => {
        dispatch(TaskAction.shipFulfillment(res));
      })
      .catch((err) => {});
  },

   UndoFulfillment : (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/tasks/undoFulfillment/${id}`, data)
      .then((res) => {
        dispatch(TaskAction.undoFulfillment(res));
      }).
      catch((err) => {});
  },


  SendProposalTask: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/tasks/sendProposal/${id}`, data)
      .then((res) => {
        dispatch(TaskAction.sendProposalTask(res));
      })
      .catch((err) => {});
  },
  ApproveProductRequestForTask: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/tasks/requests/send/${id}`, data)
      .then((res) => {
        dispatch(TaskAction.approveProductRequest(res));
      })
      .catch((err) => {});
  },
  AddRevisionNote: async (task_id, data) => {
    const dispatch = useDispatch();
    await axios
      .put(`${baseUrl}api/tasks/revision/${task_id}`, data)
      .then((res) => {
        dispatch(TaskAction.addRevisionNote(res));
      })
      .catch((err) => {});
  },
  UpdateTask: (id, data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/tasks/updateTask/${id}`, data)
      .then((res) => {
        dispatch(TaskAction.updateTask(res));
      })
      .catch((err) => {});
  },
  DeleteTask: (id, data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/tasks/cancelTask/${id}`, data)
      .then((res) => {
        dispatch(TaskAction.deleteTask(res));
      })
      .catch((err) => {});
  },
  FetchSingleTask: async (id) => {
    try {
      const dispatch = useDispatch();
      const res = await axios.get(`${baseUrl}api/tasks/${id}`);
      dispatch(TaskAction.loadSingleTask(res.data.result));
      return res.data.result; // Return the fetched task data
    } catch (err) {
      console.error(err);
      return null; // Return null or handle the error as needed
    }
  },
  EditTask: (id, data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/tasks/edit/${id}`, data)
      .then((res) => {
        dispatch(TaskAction.editTask(res));
      })
      .catch((err) => {});
  },
  CompleteTask: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/tasks/complete/${id}`, data)
      .then((res) => {
        dispatch(TaskAction.approveDraft(res));
      })
      .catch((err) => {});
  },


  SendDraft: async (id, data) => {
    const dispatch = useDispatch();
    try {
      const res = await axios.post(`${baseUrl}api/tasks/sendDraft/${id}`, data);
      dispatch(TaskAction.sendDraft(res));
      return res; // Return the response so it can be used in the calling function if needed
    } catch (err) {
      console.error("Error in SendDraft:", err);
      // Optionally, you can handle specific error statuses here, e.g., if (err.response.status === 400) { ... }
      throw err; // Rethrow the error to be caught in the calling function
    }
  },

  EditTask : (id, data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/tasks/edit/${id}`, data)
      .then((res) => {
        dispatch(TaskAction.editTask(res));
      })
    
  },

  RemoveCreator: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/tasks/remove/${id}`, data)
      .then((res) => {
        dispatch(TaskAction.removeCreator(res));
      })
      .catch((err) => {});
  },

  SendTiktokCode: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/tasks/sendTiktokCode/${id}`, data)
      .then((res) => {
        dispatch(TaskAction.sendTiktokCode(res));
      })
      .catch((err) => {});
  },
  FindCreatorsForBrief: (data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/studio/findCreators`, data)
      .then((res) => {
        dispatch(TaskAction.findCreatorsForBrief(res.data));
      });
  }
}

//Action
export const TaskAction = {
  loadUserTask: (data) => {
    return {
      type: 'USER_TASKS_LOADED',
      data: data
    };
  },
  loadTasks: (data) => {
    return {
      type: 'TASKS_LOADED',
      data: data
    };
  },
  editTask: (data) => {
    return {
      type: 'TASK_EDITED',
      data: data
    };
  },

  loadSingleTask: (data) => {
    return {
      type: 'TASK_LOADED',
      data: data
    };
  },
  createTask: (data) => {
    return {
      type: 'CREATE_TASK',
      data: data
    };
  },
  
  createTrialTask: (data) => {
    return {
      type: 'CREATE_TRIAL_TASK',
      data: data
    };
  },

  shipFulfillment: (data) => {
    return {
      type: 'SHIP_FULFILLMENT',
      data: data
    };
  },

  promoFulfillment: (data) => {
    return {
      type: 'PROMO_FULFILLMENT',
      data: data
    }
  },

  inStoreFulfillment: (data) => {
    return {
      type: 'IN_STORE_FULFILLMENT',
      data: data
    }
  },

  undoFulfillment: (data) => {
    return {
      type: 'UNDO_FULFILLMENT',
      data: data
    }
  },

  updateTask: (data) => {
    return {
      type: 'TASK_UPDATE',
      data: data
    };
  },

  completeTask: (data) => {
    return {
      type: 'TASK_COMPLETE',
      data: data
    };
  },
  deleteTask: (data) => {
    return {
      type: 'TASK_DELETE',
      data: data
    };
  },
  approveProposal: (data) => {
    return {
      type: 'PROPOSAL_APPROVED',
      data: data
    };
  },

  denyProposal: (data) => {
    return {
      type: 'PROPOSAL_DENIED',
    }
  },

  addRevisionNote: (data) => {
    return {
      type: 'CREATE_REVISION_NOTE',
      data: data
    };
  },
  sendProposalTask: (data) => {
    return {
      type: 'TASK_SEND_PROPOSAL',
      data: data
    };
  },

  sendDraft: (data) => {
    return {
      type: 'TASK_SEND_DRAFT',
      data: data
    }
  },
  
  approveProductRequest: (data) => {
    return {
      type: 'PRODUCT_REQ_TASK',
      data: data
    };
  },

  editTask: (data) => {
    return {
      type: 'TASK_EDIT',
      data: data
    };
  },
  
  removeCreator: (data) => {
    return {
      type: 'CREATOR_REMOVED',
      data: data
    };
  },

  sendTiktokCode: (data) => {
    return {
      type: 'TASK_SEND_TIKTOK_CODE',
      data: data
    };
  },
  findCreatorsForBrief: (data) => {
    return {
      type: 'FIND_BRIEF_CREATORS',
      data: data
    }
  }
};
