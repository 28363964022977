import React, {useEffect, useState, useRef} from 'react'
import { useHistory } from 'react-router-dom';
import axios from 'axios'
import { FaArrowDown, FaArrowUp, FaSearch, FaYoutube, FaInstagram, FaTiktok } from 'react-icons/fa';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Loader from '../Loader2';
import './CreatorDiscoveryTable.css'
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import Modal from 'react-responsive-modal';
import { Slider } from '@material-ui/core'; // Import Slider from Material UI
import {toast, Toaster} from 'react-hot-toast';
import { baseUrl } from '../../baseUrl';
import { Parser } from 'json2csv';
import { saveAs } from 'file-saver';
import CreatorDiscoverySearch from './CreatorDiscoverySearch';
import CreatorDiscoveryProfile from './CreatorDiscoveryProfile';
import { platform } from 'os';
import Toggle from '../Toggle';

function CreatorDiscoveryContent() {
  const [sortOrder, setSortOrder] = useState({ field: '', order: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [clickCount, setClickCount] = useState({}); 
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contactDetails, setContactDetails] = useState([]);
  const [selectAll, setSelectAll] = useState(false);  
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState('Instagram');
  const [selectedCode, setSelectedCode] = useState('9bb8913b-ddd9-430b-a66a-d74d846e6c66');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [ totalResults, setTotalResults] = useState(0);
  const [offset, setOffset] = useState(0);
  const [filterDropDown, setFilterDropDown] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const offsetRef = useRef(0);
  const [selectedState, setSelectedState] = useState('');
  const [audienceAgePercentage, setAudienceAgePercentage] = useState(0);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCreatorCities, setSelectedCreatorCities] = useState([]);
  const [selectedCreatorStates, setSelectedCreatorStates] = useState([]);
  const [selectedAudienceLocationId, setSelectedAudienceLocationId] = useState('');
  const [selectedCreatorLocationId, setSelectedCreatorLocationId] = useState('');
  const [searchLocationBy, setSearchLocationBy] = useState('state');
  const [cityQueryOptions, setCityQueryOptions] = useState([]);
  const [query, setQuery] = useState('');
  const [isClosing, setIsClosing] = useState(false);
  const [creatorData, setCreatorData] = useState();
  const [originalData, setOriginalData] = useState();
  const [searchMode, setSearchMode] = useState('filters');
  const [tags, setTags] = useState([]);
  const [selectedContactOptions, setSelectedContactOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [averageLikes, setAverageLikes] = useState([0, 0]);
  const [averageViews, setAverageViews] = useState([0, 0]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  // FILTER STATES
  const [followerCount, setFollowerCount] = useState([0, 0]);
  const [creatorLocation, setCreatorLocation] = useState('');
  const [keyword, setKeyword] = useState('');
  const [email, setEmail] = useState('');
  const [creatorGender, setCreatorGender] = useState('');
  const [creatorAge, setCreatorAge] = useState([0, 0]);
  const [isVerified, setIsVerified] = useState(false);
  const [mostRecentPost, setMostRecentPost] = useState('');
  const [bioPhrase, setBioPhrase] = useState('');
  const [audienceLocation, setAudienceLocation] = useState('');
  const [audienceAge, setAudienceAge] = useState([0, 0]);
  const [percentage, setPercentage] = useState(0);
  const [gender, setGender] = useState('');
  const [genderPercentage, setGenderPercentage] = useState(0);
  const [audienceAgeIsClicked, setAudienceAgeIsClicked] = useState(false);
  const [submittedKeyword, setSubmittedKeyword] = useState('');
  const [submittedBioPhrase, setSubmittedBioPhrase] = useState('');
  const [selectedCreatorInterests, setSelectedCreatorInterests] = useState([]);

const handleSubmit = (event) => {
  event.preventDefault();
  setSubmittedKeyword(keyword);
  setKeyword(keyword);
};

const handleBioSubmit = (event) => {
  event.preventDefault();
  setSubmittedBioPhrase(bioPhrase);
  setBioPhrase(bioPhrase);
};
  const handleAgeChange = (index) => (event) => {
    const newAudienceAge = [...audienceAge];
    newAudienceAge[index] = event.target.value;
    setAudienceAge(newAudienceAge);
  };

  const handleCreatorAgeChange = (index) => (selectedOption) => {
    setCreatorAge(prevState => {
      const newCreatorAge = [...prevState];
      newCreatorAge[index] = selectedOption.value;
      return newCreatorAge;
    });
  };


  //gender Options
  const genderOptions = [
    { value: 'MALE', label: 'Male' },
    { value: 'FEMALE', label: 'Female' },
  ];
  
  const followerOptions = [
    { value: 1000, label: '1k' },
    { value: 5000, label: '5k' },
    { value: 10000, label: '10k' },
    { value: 25000, label: '25k' },
    { value: 50000, label: '50k' },
    { value: 100000, label: '100k' },
    { value: 250000, label: '250k' },
    { value: 500000, label: '500k' },
    { value: 1000000, label: '1M' },
  ];

  const interestsOptions = [
    { value: 'Television & Film', label: 'Television & Film' },
    { value: 'Music', label: 'Music' },
    { value: 'Shopping & Retail', label: 'Shopping & Retail' },
    { value: 'Coffee, Tea & Beverages', label: 'Coffee, Tea & Beverages' },
    { value: 'Camera & Photography', label: 'Camera & Photography' },
    { value: 'Clothes, Shoes, Handbags & Accessories', label: 'Clothes, Shoes, Handbags & Accessories' },
    { value: 'Beer, Wine & Spirits', label: 'Beer, Wine & Spirits' },
    { value: 'Sports', label: 'Sports' },
    { value: 'Electronics & Computers', label: 'Electronics & Computers' },
    { value: 'Gaming', label: 'Gaming' },
    { value: 'Activewear', label: 'Activewear' },
    { value: 'Art & Design', label: 'Art & Design' },
    { value: 'Travel, Tourism & Aviation', label: 'Travel & Tourism' },
    { value: 'Business & Careers', label: 'Business & Careers' },
    { value: 'Beauty & Cosmetics', label: 'Beauty & Cosmetics' },
    { value: 'Healthcare & Medicine', label: 'Healthcare & Medicine' },
    { value: 'Jewellery & Watches', label: 'Jewellery & Watches' },
    { value: 'Restaurants, Food & Grocery', label: 'Food & Grocery' },
    { value: 'Toys, Children & Baby', label: 'Toys & Baby' },
    { value: 'Fitness & Yoga', label: 'Fitness & Yoga' },
    { value: 'Wedding', label: 'Wedding' },
    { value: 'Tobacco & Smoking', label: 'Tobacco & Smoking' },
    { value: 'Pets', label: 'Pets' },
    { value: 'Healthy Lifestyle', label: 'Healthy Lifestyle' },
    { value: 'Luxury Goods', label: 'Luxury Goods' },
    { value: 'Home Decor, Furniture & Garden', label: 'Home & Garden' },
    { value: 'Friends, Family & Relationships', label: 'Family & Relationships' },
    { value: 'Cars & Motorbikes', label: 'Cars & Motorbikes' }
  ];
  

  const ageOptions = [13, 18, 25, 35, 45, 65].map((age) => ({ value: age, label: age.toString() }));
  const contactOptions = ['EMAIL', 'PHONE', 'SNAPCHAT', 'FACEBOOK', 'INSTAGRAM', 'YOUTUBE', 'TIKTOK', 'TWITTER'];
  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);
  const dropdownContentRef = useRef(null);

  // CREDENTIALS
  const username = 'b0e95fb6-43c6-4c0f-a44b-6d1bc9ced026';
  const password = '1be6ac2c-e349-46c9-bce0-ebd607625390';
  const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');

  // CACHING THE TAGS
  const cache = new Map();
  

  const handleSelect = (type) => {
    setSelectedContactOptions(prev => {
      const updatedOptions = [...prev, { type, preference: 'SHOULD_HAVE' }];
      return updatedOptions;
    });
  }
  const handleClick = () => {
    setShowFilters(false);
    fetchData();
  };
  useEffect(() => {
    console.log(selectedRows);
  }, [selectedRows]);

  const handleDeselect = (type) => {
    setSelectedContactOptions(prev => {
      const updatedOptions = prev.filter(option => option.type !== type);
      return updatedOptions;
    });
  }

  const handleFollowerChange = (index) => (selectedOption) => {
    const newFollowerCount = [...followerCount];
    newFollowerCount[index] = selectedOption.value;
    setFollowerCount(newFollowerCount);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setAudienceAgeIsClicked(false);
      }
    }
  
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef2.current && !dropdownRef2.current.contains(event.target) &&
        dropdownContentRef.current && !dropdownContentRef.current.contains(event.target)
      ) {
        setAudienceAgeIsClicked(false);
      }
    }
  
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isClosing) {
      setTimeout(() => {
        setShowFilters(false);
        setIsClosing(false);
      }, 500); // Delay equal to animation duration
    }
  }, [isClosing]);


  useEffect(() => {
    const getStates = async () => {
      try {
        const response = await fetch(`${baseUrl}api/maintenance/getDiscoveryStates`);
        if (!response.ok) {
          throw new Error('HTTP error ' + response.status);
        }
        const data = await response.json();
        console.log('States:', data)
        setStates(data);
      } catch (error) {
        console.error('Failed to fetch states:', error);
      }
    };

    getStates();
  }, []);
  
useEffect(() => {
  if (states) {
    const newCities = Object.values(states).flatMap(state =>
      Object.values(state).map(city => ({
        value: city.location_id,
        label: city.display_name,
      }))
    );
    setCities(newCities);
  }
}, [states]);

  //console.log("cities", cities);
  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(
        cities.filter(city =>
          city.label.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
    }, 1000);
  };

  //ICONS FOR PLATFORMS
  const icons = {
    YouTube: {icon: <div className='youtube 2xl:w-6 2xl:h-6 w-4 h-4 ml-2 items-center'></div>, code: '14d9ddf5-51c6-415e-bde6-f8ed36ad7054'},
    Instagram: {icon: <div className='instagram 2xl:w-6 2xl:h-6 w-4 h-4 ml-2 items-center'></div>, code: '9bb8913b-ddd9-430b-a66a-d74d846e6c66'},
    TikTok: {icon: <div className='tiktok 2xl:w-6 2xl:h-6 w-4 h-4 ml-2 items-center'></div>, code: 'de55aeec-0dc8-4119-bf90-16b3d1f0c987'},
  };
  //SELECT ALL CREATORS IN PAGE
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // If the checkbox is checked, add all rows to the selected rows
      const newSelectedRows = sortedData.map((row, index) => ({...row, index}));
      setSelectedRows(newSelectedRows);
      setSelectAll(true);
    } else {
      // If the checkbox is unchecked, remove all rows from the selected rows
      setSelectedRows([]);
      setSelectAll(false);
    }
  };
  const handleSort = (field) => {
  let order = 'asc';
  let count = clickCount[field] || 0;
  count++;

  if (count === 3) {
    count = 0;
    order = null;
  } else if (count === 2) {
    order = 'desc';
  }

  setClickCount({ ...clickCount, [field]: count });
  setSortOrder({ field, order });

  if (order === null) {
    setSortedData(creatorData);
  } else {
    const sorted = [...creatorData].sort((a, b) => {
      // Use the original numerical values for sorting
      const aValue = a[field] || 0;
      const bValue = b[field] || 0;

      if (order === 'asc') {
        return aValue - bValue;
      } else if (order === 'desc') {
        return bValue - aValue;
      }
    });

    setSortedData(sorted);
  }
};

  useEffect(() => {
    console.log(selectedTags);
  }, [selectedTags]);

  //HANDLE CITY QUERY
  useEffect(() => {
    if (query) {
      const fetchCities = async (query) => {
        try {
          const response = await axios.get(
            `https://api.insightiq.ai/v1/social/creators/dictionary/locations?search_string=${query}`,
            {
              headers: {
                'Authorization': `Basic ${token}`,
              }
            }
          );
          const cityOptions = Array.isArray(response.data.data) ? response.data.data : [];
          setCityQueryOptions(cityOptions);
        } catch (error) {
          console.error("Error fetching city options:", error);
        };
      };

      fetchCities(query);

    } else {
      setCityQueryOptions([]);
    }
  }, [query]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const term = searchTerm.trim();

    if (term !== "" && !selectedTags.includes(term)) {
      const newTags = selectedTags.slice(); // This creates a copy of selectedTags
      newTags.push(term); // This adds the new term to the copied array
      setSelectedTags(newTags); // This sets the state to the new array
      setSearchTerm("");
    }
  };


  const handleInputChangeCity = (inputValue) => {
    setQuery(inputValue); // Update the query for API search
  };

  const handleRemoveTag = (event, tagToRemove) => {
    event.stopPropagation(); // This prevents the event from propagating up to the form's onSubmit event
    setSelectedTags(selectedTags.filter(tag => tag !== tagToRemove));
  };

  const handleRowSelect = (event, row, index) => {
    setSelectedRows(prevSelectedRows => {
      // Copy the array to avoid mutating state directly
      let newSelectedRows = [...prevSelectedRows];
      const rowIndex = newSelectedRows.findIndex(r => r.index === index);
  
      if (rowIndex !== -1) {
        // If the row is already selected, remove it from the array
        newSelectedRows.splice(rowIndex, 1);
      } else {
        // If the row is not selected, add it to the array
        newSelectedRows.push({...row, index});
      }
      return newSelectedRows;
    });
    event.stopPropagation();
  };
  

  useEffect(() => {
    console.log(emailList);
  }, [emailList]);

  const handleStateChange = (selectedOption) => {
    const selectedState = selectedOption ? { location_id: selectedOption.value, label: selectedOption.label, percentage: 0 } : '';
    setSelectedState(selectedState);
    console.log("selected state:", selectedState);
  };

const handleCityChange = (selectedOption) => {
  const selectedCityId = selectedOption ? selectedOption.value : null;
  const selectedCityName = selectedOption ? selectedOption.label : null;
  setSelectedCity({ cityId: selectedCityId, cityName: selectedCityName, percentage: 0 });
};

const handlePercentageChange = (value) => {
  setSelectedState(prevState => ({ ...prevState, percentage: parseInt(value, 10) }));
};

const handleCreatorStateChange = (selectedCreatorState) => {
  const selectedLocationIds = selectedCreatorState.map(option => option.value);
  setSelectedCreatorStates(selectedLocationIds);
  console.log("Selected state", selectedLocationIds);
  console.log("selected creator states", selectedCreatorStates)
};

    
const handleCreatorCityChange = (selectedOptions) => {
  const selectedCityIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
  setSelectedCreatorCities(selectedCityIds);
};

const handleCreatorInterestsChange = (selectedOptions) => {
  const selectedInterests = selectedOptions ? selectedOptions.map(option => option.value) : [];
  setSelectedCreatorInterests(selectedInterests);
};

const history = useHistory();

const handleRowClickProfile = (rowData) => {
  history.push(`/admin/discovery/profile/${rowData.platform_username}`, { username: rowData.platform_username });
};

const handleRowClick = (event, row) => {
  if (event.target.type !== 'checkbox') {
    setSelectedRow(row);
    setShowProfile(true);
  }
};

const handleSendEmails = async () => {
  const requestBody = {
    email_list: emailList,
    subject: emailSubject,
    body: emailBody,
  };

  try {
    const response = await fetch(`${baseUrl}api/email/sendMassEmail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data.message);

    // Show a toast notification
    toast.success('Emails were sent successfully!', {
      position: toast.POSITION.TOP_CENTER
    });
  } catch (error) {
    console.error('Failed to send email:', error);

    // Show a toast notification for the error
    toast.error('Failed to send emails!', {
      position: toast.POSITION.TOP_CENTER
    });
  }

  setShowModal(false);
};
  useEffect(() => {
    //console.log("Selected location id", selectedAudienceLocationId);
   // console.log("Selected city", selectedCities);
  }, [selectedAudienceLocationId, selectedCities]);
  useEffect(() => {
   // console.log("Selected location id", selectedCreatorLocationId);
  //  console.log("Selected city", selectedCreatorCities);
  }, [selectedCreatorLocationId, selectedCreatorCities]);
  const setters = {
    'Follower Count': setFollowerCount,
    'Creator Location': setCreatorLocation,
    'Keyword': setKeyword,
    'Contact options': setContactDetails,
    'Email': setEmail,
    'Creator Gender': setCreatorGender,
    'Creator Age': setCreatorAge,
    'is verified': setIsVerified,
    'Most Recent Post': setMostRecentPost,
    'Bio Phrase': setBioPhrase,
    'Audience Location': setAudienceLocation,
    'Audience Age': setAudienceAge,
  };

  function formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K';
    } else {
      return num;
    }
  }

  const downloadCSV = () => {
    // Add email to creatorData if platform_username is included in emailList
    const dataToIterate = (selectedRows && selectedRows.length > 0) ? selectedRows : creatorData;

    dataToIterate.forEach(data => {
      if (emailList && emailList.length > 0) {
        const emailData = emailList.find(email => email.platform_username === data.platform_username);
        if (emailData) {
          data.email = emailData.email;
        }
      }
    });
  
    const fields = ['full_name', 'follower_count', 'is_verified', 'engagement_rate', 'url'];
    // Include 'email' field only if emailList is not empty
    if (emailList && emailList.length > 0) {
      fields.push('email');
    }
  
    const parser = new Parser({ fields });
    // If selectedRows has a length greater than 0, parse selectedRows instead of creatorData
    const csv = parser.parse(dataToIterate);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'Discovery-Search.csv');
  };

  const incrementOffset = () => {
    offsetRef.current += 100; // Update the ref
    setOffset(offsetRef.current); // Update the state
  };
  
  const decrementOffset = () => {
    offsetRef.current = Math.max(offsetRef.current - 100, 0); // Update the ref
    setOffset(offsetRef.current); // Update the state
  };
  
  const reset = () => {
 
    setEmailList([]);
    setSelectedRows([]);
    setSelectAll(false);
    setFollowerCount([0, 0]);
    setCreatorAge([0, 0]);
    setAudienceAge([0, 0]);
    setPercentage(0);
    setGender('');
    setGenderPercentage(0);
    setSelectedCreatorCities([]);
    setSelectedCities([]);
    setSelectedCity(null);
    setSelectedCreatorStates([]);
    setSelectedState('');
    setSelectedContactOptions([]);
    setSelectedIcon('Instagram');
    setSelectedCode('9bb8913b-ddd9-430b-a66a-d74d846e6c66');
    // Add the reset for other state variables here   
    setShowFilters(false);
    setKeyword('');
    setCreatorLocation('');
    setCreatorGender('');
    setBioPhrase('');
    setSubmittedKeyword('');
    setSelectedTags([]);
  };

  useEffect(() => {
    if (selectedIcon && selectedCode) {
      console.log("Selected icon", selectedIcon, "Selected code", selectedCode);
      fetchData();
    }
  }, [selectedIcon, selectedCode]);

  const fieldMapping = {
    'full_name': 'Creator',
    'follower_count': 'Followers',
    'average_likes': 'Average Likes',
    'engagement_rate': 'Engagement Rate'
  };

  const data = [
    {
      id: 1,
      creator: 'Creator 1',
      handle: '@creator1',
      followers: 1000,
      averageLikes: 500,
      engagementRate: '50%'
    },
    {
      id: 2,
      creator: 'Creator 2',
      handle: '@creator2',
      followers: 2000,
      averageLikes: 1000,
      engagementRate: '50%'
    },
    {
      id: 3,
      creator: 'Creator 3',
      handle: '@creator3',
      followers: 3000,
      averageLikes: 1500,
      engagementRate: '50%'
    },
    {
      id: 4,
      creator: 'Creator 4',
      handle: '@creator4',
      followers: 4000,
      averageLikes: 2000,
      engagementRate: '50%'
    },
    {
      id: 5,
      creator: 'Creator 5',
      handle: '@creator5',
      followers: 5000,
      averageLikes: 2500,
      engagementRate: '50%'
    },
    // Add more data as needed
  ];

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input type="checkbox" checked={props.isSelected} onChange={() => null} /> {props.label}
        </components.Option>
      </div>
    );
  };

const fetchData = async () => {
      setLoading(true);

      console.log("selectedContactOptions: ", selectedContactOptions)

      try {      
        setSelectedRows([]);
        setEmailList([]);
        setSelectAll(false); // Add this line
        const response = await axios.post(
          'https://api.insightiq.ai/v1/social/creators/profiles/quick-search ',
          {
            "work_platform_id": selectedCode,
            ...(followerCount[0] > 0 && followerCount[1] > 0 && {
              "follower_count": {
                "min": followerCount[0],
                "max": followerCount[1]
              }
            }),
            ...(gender && genderPercentage > 0 && {
              "audience_gender": {
                "type": gender,
                "operator": "GT",
                "percentage_value": genderPercentage
              }
            }),
            //"creator_gender": "ANY",
            ...(audienceAge[1] !== 0 && {
              "audience_age": {
                "min": audienceAge[0],
                "max": audienceAge[1],
                "percentage":Number(percentage)
              }
            }),
            ...(creatorAge[1] !== 0 && {
              "creator_age": {
                "min": creatorAge[0],
                "max": creatorAge[1]
              }
            }),
            ...(keyword && keyword !== '' && {
              "description_keywords": keyword,
            }),
            /*"is_verified": true,
            "has_contact_details": true,
            //"last_post_timestamp": "2019-08-24T14:15:22",
            "creator_language": {
              "code": "en"
            },*/
            ...(averageLikes[1] !== 0 && {
              "average_likes": {
                "min": averageLikes[0],
                "max": averageLikes[1]
              }
            }),
            ...(averageViews[1] !== 0 && {
              "average_views": {
                "min": averageViews[0],
                "max": averageViews[1]
              }
            }),
            ...(selectedContactOptions.length > 0 && {
              "specific_contact_details": selectedContactOptions
            }),
            /* "has_sponsored_posts": true,
            "instagram_options": {
              "reel_views": {
                "min": 0,
                "max": 0
              }
            },*/
            ...(selectedState && selectedState !== "" && {
              "audience_locations": [{
                "location_id": selectedState.location_id,
                "percentage_value": selectedState.percentage,
                "operator": "GT"
              }]
            }),
            ...(searchLocationBy === "state" && selectedCreatorStates && selectedCreatorStates.length > 0 && {
              "creator_locations": selectedCreatorStates
            }),
            ...(searchLocationBy === "city" && selectedCreatorCities && selectedCreatorCities.length > 0 && {
              "creator_locations": selectedCreatorCities
            }),
         ...(creatorGender && creatorGender !== '' && {
            creator_gender: creatorGender
          }),
          ...(bioPhrase && bioPhrase !== '' && {
            bio_phrase: bioPhrase
          }),

          ...(selectedCreatorInterests && selectedCreatorInterests.length > 0 && {
            "creator_interests": selectedCreatorInterests
          }),
          
          /*...(selectedCode === '9bb8913b-ddd9-430b-a66a-d74d846e6c66' && {
            "creator_account_type": ["CREATOR"]
          }),*/
           "sort_by": {
            "field": selectedTags.length > 0 ? "TOPIC_RELEVANCE" : "AVERAGE_LIKES",
            "order": "DESCENDING"
          },
            "limit": 100,
            "offset": offsetRef.current,
            /*"total_engagements": {
              "min": 0,
              "max": 0
            },*/
            ...(selectedTags.length > 0 && {
              "topic_relevance": {
                "name": selectedTags.map(tag => `#${tag}`)
              },
              "filter_tags": {
                "topic_relevance": `Topics: ${selectedTags.join(', ')}`
              }
            }),
            "exclude_private_profiles": false
          },
          {
            headers: {
              'Authorization': `Basic ${token}`,
            },
          }
        );
        //console.log("Response", response.data.data)
        setCreatorData(response.data.data);
        setOriginalData(response.data.data);
        setSortedData(response.data.data);
        setTotalResults(response.data.metadata.total_results); 
        console.log("total results", response.data.metadata);
      } catch (error) {
        console.error('Error fetching data', error);
      }
      finally {
        setLoading(false); // set loading back to false in the finally block
      }
    };
    useEffect(() => {
      console.log('fetchData is being called');
      fetchData();
    }, [offset]);

      useEffect(() => {
        console.log("sorted Data", sortedData);
      }, [sortedData]);

  const fetchEmails = async () => {
    const delay = (interval, call) => new Promise(resolve =>
      setTimeout(() => resolve(call()), interval)
    );
    const username = 'b0e95fb6-43c6-4c0f-a44b-6d1bc9ced026';
    const password = '1be6ac2c-e349-46c9-bce0-ebd607625390';
    setIsLoading(true);
    try {      
      setEmailList([]);
      setSelectAll(false); // Add this line

      console.log("selectedContactOptions in emails: ", selectedContactOptions);
      console.log("Selected filters: ", selectedCode, followerCount, gender, keyword); 
        const response = await axios.post('https://api.insightiq.ai/v1/social/creators/profiles/search', {
            "work_platform_id": selectedCode,
            ...(followerCount[0] > 0 && followerCount[1] > 0 && {
              "follower_count": {
                "min": followerCount[0],
                "max": followerCount[1]
              }
            }),
            ...(gender && genderPercentage > 0 && {
              "audience_gender": {
                "type": gender,
                "operator": "GT",
                "percentage_value": genderPercentage
              }
            }),
            //"creator_gender": "ANY",
            ...(audienceAge[1] !== 0 && {
              "audience_age": {
                "min": audienceAge[0],
                "max": audienceAge[1],
                "percentage": Number(percentage)
              }
            }),
            ...(creatorAge[1] !== 0 && {
              "creator_age": {
                "min": creatorAge[0],
                "max": creatorAge[1]
              }
            }),
            ...(keyword && keyword !== '' && {
              "description_keywords": keyword,
            }),
            /*"is_verified": true,
            "has_contact_details": true,
            "creator_language": {
              "code": "en"
            },*/
            ...(averageLikes[1] !== 0 && {
              "average_likes": {
                "min": averageLikes[0],
                "max": averageLikes[1]
              }
            }),
            ...(averageViews[1] !== 0 && {
              "average_views": {
                "min": averageViews[0],
                "max": averageViews[1]
              }
            }),
            ...(selectedContactOptions.length > 0 && {
              "specific_contact_details": selectedContactOptions
            }),
            ...(selectedState && selectedState !== "" && {
              "audience_locations": [{
                "location_id": selectedState.location_id,
                "percentage_value": selectedState.percentage,
                "operator": "GT"
              }]
            }),
            ...(searchLocationBy === "state" && selectedCreatorStates && selectedCreatorStates.length > 0 && {
              "creator_locations": selectedCreatorStates
            }),
            ...(searchLocationBy === "city" && selectedCreatorCities && selectedCreatorCities.length > 0 && {
              "creator_locations": selectedCreatorCities
            }),
            ...(selectedCities && selectedCities.length > 0 && {
              "audience_locations": selectedCities.map(({ cityId, percentage }) => ({
                "location_id": cityId,
                "percentage_value": percentage,
                "operator": "GT"
              }))
            }),
            ...(creatorGender && creatorGender !== '' && {
              creator_gender: creatorGender
            }),
            ...(bioPhrase && bioPhrase !== '' && {
              bio_phrase: bioPhrase
            }),
            ...(selectedCreatorInterests && selectedCreatorInterests.length > 0 && {
              "creator_interests": selectedCreatorInterests
            }),
            /*...(selectedCode === '9bb8913b-ddd9-430b-a66a-d74d846e6c66' && {
              "creator_account_type": ["CREATOR"]
            }),*/
            "sort_by": {
              "field": selectedTags.length > 0 ? "TOPIC_RELEVANCE" : "AVERAGE_LIKES",
              "order": "DESCENDING"
            },
            "limit": 100,
            "offset": offset,
            ...(selectedTags.length > 0 && {
              "topic_relevance": {
                "name": selectedTags.map(tag => `#${tag}`)
              },
              "filter_tags": {
                "topic_relevance": `Topics: ${selectedTags.join(', ')}`
              }
            }),
            "exclude_private_profiles": false
          }, {
            headers: {
              'Authorization': `Basic ${token}`,
            },
          })
      
      
      //const responses = (await Promise.all(promises)).filter(response => response !== null); // filter out failed requests
      console.log("Response", response);
      const emails = 
        response.data.data.flatMap(item =>
        item.contact_details
          .filter(detail => detail.type === 'email')
          .map(detail => ({
            email: detail.value,
            full_name: item.full_name,
            follower_count: item.follower_count,
            platform_username: item.platform_username,
            is_verified: item.is_verified,
            engagement_rate: item.engagement_rate,
            url: item.url
          }))
        );
  
  
      // Update the email list state
      setEmailList(prevEmails => [...prevEmails, ...emails]);
      console.log("Emails", emails);
    } catch (error) {
      console.error('Error fetching data', error);
    }
    finally {
      setIsLoading(false); // set loading back to false in the finally block
    }
  };
  
  return (
    <div className='w-full' style={{ position: 'relative' }}>
      <Toaster/>
      {/**SEARCH BAR, TOGGLE FOR PLATFORM, AND TOGGLER FOR SEARCH METHOD */}
      <div className="p-3 md:p-2 sm:p-1 my-2 flex flex-row items-center justify-center ">
        <div className='mx-3' style={{ position: 'relative', zIndex: 10 }}>
          <button 
            className="bg-white shadow-lg rounded-md border-2 hover:bg-gray-100 text-white focus:outline-none 2xl:py-2 md:py-1 sm:px-5 sm:py-2 flex items-center justify-center transform transition-transform duration-500 ease-in-out space-x-2"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            {selectedIcon && icons[selectedIcon].icon}
            {dropdownOpen ? <IoIosArrowUp className='text-gray-600'/> : <IoIosArrowDown className='text-gray-600'/>}
          </button>
          {dropdownOpen && (
            <div className="absolute bg-white left-0 mt-2 w-48 rounded-md shadow-lg" style={{ zIndex: 50 }}>
              <div className="rounded-md ring-1 ring-black ring-opacity-5">
              {Object.keys(icons).map((icon) => (
                <div
                  key={icon}
                  className="flex items-center px-4 py-2 font-bold text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => {
                    setSelectedIcon(icon);
                    setSelectedCode(icons[icon].code);
                    setDropdownOpen(false);
                  }}
                >
                  {icons[icon].icon} <span className="ml-2">{icon}</span>
                </div>
              ))}
              </div>
            </div>
          )}
        </div>
          <form onSubmit={handleBioSubmit} className="flex flex-col w-1/2 relative">
          <div className="bg-white flex items-center shadow-md border-2 2xl:py-2 md:py-1 text-gray-700 leading-tight focus:border-red rounded-md">
            <div className="flex flex-row flex-nowrap overflow-x-auto">
              {submittedBioPhrase && (
                <div className="flex items-center m-1 h-6 bg-gray-200 rounded-full">
                 <>
                      <button 
                        type="button"
                        onClick={() => setSubmittedBioPhrase('')}
                        className="p-1 ml-2 font-bold rounded-full"
                      >
                        x
                      </button>
                      <span className="px-2 py-1 text-sm">{submittedBioPhrase}</span>
                </>
                </div>
              )}
            </div>
            <input 
              className="rounded-md flex-grow mx-auto 2xl:text-base md:text-0.7 p-5 md:p-2 sm:p-2 text-gray-700 leading-tight border-none focus:ring-0 focus:ring-offset-0 focus:outline-none 2xl:h-8 md:h-1rem" 
              id="search" 
              type="text" 
              placeholder="Add bio phrase" 
              onChange={(event) => {
                setBioPhrase(event.target.value);
                console.log(event.target.value); // Log the new value
              }}
              value={bioPhrase}
            />
          </div>
          </form>
        <div className="shadow-lg ml-2">
        <button onClick={fetchData} className="bg-Blue shadow-lg text-base text-white rounded-md focus:outline-none 2xl:px-4 2xl:py-3 md:px-4 md:py-2 sm:px-5 sm:py-2 flex items-center justify-center transform transition-transform duration-500 ease-in-out hover:scale-105 space-x-2">
          <FaSearch /> 
          <span>Search</span>
        </button>
        </div>
      </div>
      {/**END SEARCH BAR */}
      {/**FILTERS */}
      <div className="my-3 mx-10 font-bold 2xl:text-base md:text-0.7 rounded-lg border divide-y divide-gray-200 bg-white">
        <div className="grid grid-cols-6 sm:grid-cols-6 md:grid-cols-6 lg:grid-cols-6 xl:grid-cols-6 divide-x divide-gray-200 py-2">
        <div className="p-2 flex flex-col hover:bg-gray-100 transition-colors duration-200">
          <label>Audience Location</label>
          <Select
            className="w-full hover:bg-gray-100 mt-2"
              options={Object.keys(states).map((state) => {
                const location_id = Object.keys(states[state])[0];
                return { value: location_id, label: state };
              })}
              onChange={handleStateChange}
              isSearchable
            />
          {selectedState && selectedState !== "" && (
            <div className="text-center">
              <h3 className='2xl:text-0.7 md:text-0.6'>Percentage of audience in {selectedState.label}</h3> <p>{selectedState.percentage}%</p>
              <input type="range" min="0" max="100" value={selectedState.percentage} onChange={(event) => handlePercentageChange(event.target.value)} />            </div>
          )}
        </div>
        <div 
          className="p-2 flex flex-col hover:bg-gray-100 transition-colors duration-200 cursor-pointer relative"
          onClick={() => setAudienceAgeIsClicked(true)}
          ref={dropdownRef}
        >
          <label>Audience Age</label>
            <div className="flex items-center mt-2 text-0.7">
              <Select
                options={ageOptions}
                onChange={(option) => setAudienceAge([Number(option.value), audienceAge[1]])}
                isSearchable={false}
                defaultValue={ageOptions.find(option => option.value === audienceAge[0])}
              />
              <span className="mx-2">to</span>
              <Select
                options={ageOptions}
                onChange={(option) => setAudienceAge([audienceAge[0], Number(option.value)])}
                isSearchable={false}
                defaultValue={ageOptions.find(option => option.value === audienceAge[1])}
              />
            </div>
            <p className='text-0.7 mt-1 font-thin'>{percentage > 0 && `Selected Percentage of audience from ${audienceAge[0]} to ${audienceAge[1]}: ${percentage}%`}</p>
            <input className='w-full mt-2' type="range" min="0" max="100" value={percentage} onChange={(e) => setPercentage(e.target.value)} onBlur={(e) => e.stopPropagation()} />
            {(percentage > 0 || (audienceAge[0] > 0 && audienceAge[1] > 0)) && (
              <button 
                className="bg-gray-200 font-bold hover:text-white text-black px-2 py-1 rounded mt-2"
                onClick={() => {
                  setPercentage(0);
                  setAudienceAge([0, 0]);
                }}
              >
                Reset
              </button>
            )}
        </div>
        <div className="p-2 flex flex-col hover:bg-gray-100 transition-colors duration-200 cursor-pointer">
            <label>Audience Gender</label>
          <Select
              className="w-full hover:bg-gray-100 mt-2"
              options={genderOptions}
              onChange={(selectedOption) => setGender(selectedOption ? selectedOption.value : '')}
              isClearable
            />
            {gender && (
              <>
                <input className='ml-5 mt-2' type="range" min="0" max="100" value={genderPercentage} onChange={(e) => setGenderPercentage(e.target.value)} />
                <div className="flex flex-col 2xl:text-xs ml-5 md:text-0.65">
                  <p>{gender && `Selected Gender: ${gender}`}</p>
                  <p className=' mt-0.5 text-0.7'>{genderPercentage > 0 && `Selected Percentage: ${genderPercentage}%`}</p>
                  {(gender && genderPercentage > 0) && (
                    <button 
                      className="bg-gray-200 font-bold hover:text-white text-black px-2 py-1 rounded mt-2"
                      onClick={() => {
                        setGender('');
                        setGenderPercentage(0);
                      }}
                    >
                      Reset
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="p-2 flex flex-col hover:bg-gray-100 transition-colors duration-200">
            <label>Creator Location</label>
            {/* Search by State or City Toggle */}
            <div className="flex items-center justify-center 2xl:text-sm md:text-xs border-2 mx-auto w-fit mt-3 space-x-2 rounded-3xl relative">
              <div 
                className={`absolute left-0 top-0 h-full bg-Blue text-white rounded-3xl transition-transform duration-500 ease-in-out ${searchLocationBy === 'state' ? 'transform translate-x-0' : 'transform translate-x-full'}`}
                style={{ width: '50%', background: '#648de5', zIndex: 1 }}
              />
              <div 
                className={`cursor-pointer hover:bg-Blue hover:text-white px-2 py-1 z-10 ${searchLocationBy === 'state' ? ' text-white rounded-3xl' : 'bg-white text-gray-700 rounded-2xl'}`} 
                onClick={() => setSearchLocationBy('state')}
              >
                State
              </div>
              <div 
                className={`cursor-pointer hover:bg-Blue hover:text-white px-2 py-1 z-10 ${searchLocationBy === 'city' ? ' text-white rounded-3xl' : 'bg-white text-gray-700 rounded-2xl'}`} 
                onClick={() => setSearchLocationBy('city')}
              >
                City
              </div>
            </div>
            {searchLocationBy === 'state' && (
              <Select
              className="w-full hover:bg-gray-100 mt-2"
                options={Object.keys(states).map((state) => {
                  const location_id = Object.keys(states[state])[0];
                  return { value: location_id, label: state };
                })}
                onChange={handleCreatorStateChange}
                isSearchable
                isMulti
              />
            )}
            {searchLocationBy === 'city' && (
              <>
                <Select 
                  type='text'
                  className='w-full mt-2 p-2 border-2 rounded-md focus:outline-none focus:ring-0 focus:ring-offset-0'
                  options={cityQueryOptions.map(city => ({ value: city.id, label: city.display_name }))}
                  onInputChange={handleInputChangeCity}
                  onChange={handleCreatorCityChange}
                  noOptionsMessage={()=> 'No cities found'}
                  isMulti
                  isClearable
                />
              </>
            )}
          </div>
           <div className="p-2 flex flex-col h-5rem hover:bg-gray-100 transition-colors duration-200 cursor-pointer whitespace-nowrap" onClick={() => setIsModalOpen(true)}>
                <label>Contact Options</label>
                <div className="overflow-auto grid grid-cols-2 gap-2">
                  {selectedContactOptions.length > 0 && selectedContactOptions.map((option, index) => (
                    <div key={index} className="rounded-2xl bg-gray-200 p-1 m-1 inline-block flex items-center">
                      {option.type}
                      <button 
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering the onClick event of the parent div
                          handleDeselect(option.type);
                        }}
                        className="ml-2 bg-gray-200 text-black rounded-full w-4 h-4 flex items-center justify-center"
                      >
                        x
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            <Modal   open={isModalOpen} onClose={() => setIsModalOpen(false)} center>
              <div className="p-2 m-4">
              <h2 className="text-center text-lg font-bold my-4">Which contact options should creators have?</h2>
              {contactOptions.map(option => (
                <div key={option} className="flex items-center space-x-3 mb-2">
                  <input 
                    type="checkbox" 
                    id={option} 
                    name={option} 
                    onChange={(e) => e.target.checked ? handleSelect(option) : handleDeselect(option)}
                    checked={selectedContactOptions.some(contactOption => contactOption.type === option)} // Corrected this line
                  />
                  <label htmlFor={option} className="text-sm">{option}</label>
                </div>
              ))}
              </div>
            </Modal>
          <div 
          className=" flex flex-col items-start hover:bg-gray-100 cursor-pointer"
          onClick={() => setShowFilters(!showFilters)}>
                <label className="ml-3 font-bold py-2 px-4 rounded">
                  ... More filters
                </label>
          </div>
        </div>
      </div>
      {creatorData &&  
      <div className = "ml-10 my-2 md:text-0.7 font-bold 2xl:text-base ">
      {creatorData.length} results out of {totalResults}
      </div>}
  <div className="flex justify-between">
        <div className='ml-10 space-x-2'>
        {offset >= 100 && (
          <button 
            onClick={() => {decrementOffset(); fetchData();}}
            className="bg-Blue shadow-lg text-base text-white rounded-md 2xl:py-2 2xl:px-4 md:py-1 md:px-2 2xl:text-base  md:text-0.6 mb-2  transform transition-transform duration-500 ease-in-out hover:scale-105"
          >
            Back
          </button>
        )}
        {creatorData && creatorData.length === 100 && (
          <button onClick={() => {incrementOffset(); fetchData();}}
          className='bg-Blue shadow-lg text-base text-white rounded-md 2xl:py-2 2xl:px-4 md:py-1 md:px-2  2xl:text-base md:text-0.6 mb-2  transform transition-transform duration-500 ease-in-out hover:scale-105'>Next</button>
        )}
      </div>
    { creatorData && (
  <div>
    { emailList && emailList.length > 0 ? (
      <button className='bg-Blue text-white 2xl:py-2 2xl:px-4 md:py-1 md:px-2  2xl:text-base md:text-0.6 rounded mb-2  transform transition-transform duration-500 ease-in-out hover:scale-105' onClick={downloadCSV}>Export CSV with Emails</button>
    ) : (
      <button className='bg-Blue text-white 2xl:py-2 2xl:px-4 md:py-1 md:px-2  2xl:text-base md:text-0.6 rounded mb-2  transform transition-transform duration-500 ease-in-out hover:scale-105' onClick={downloadCSV}>Export CSV</button>
    )}
  </div>
)}
    <div>
  {isLoading ? (
    <p className='font-nunito mr-5 text-base md:text-0.7 font-bold'>Fetching emails. This may take a while...</p>
  ) : (
    emailList && (
      <>
          {emailList.length <= 0 && selectedRows.length > 0 ? (
            <button 
              className="bg-Blue hover:bg-blue-700 mr-10 mb-2 text-white 2xl:py-2 2xl:px-4 md:py-1 md:px-2 2xl:text-base md:text-0.6 rounded transform transition-transform duration-500 ease-in-out hover:scale-105" 
              onClick={fetchEmails}
            >
              Fetch {selectedRows.length} Emails
            </button>
          ) : emailList.length > 0 && selectedRows.length > 0  ? (
            <button 
              className="bg-Blue hover:bg-blue-700 mr-10 mb-2 text-white 2xl:py-2 2xl:px-4 md:py-1 md:px-2 2xl:text-base md:text-0.6  rounded transform transition-transform duration-500 ease-in-out hover:scale-105"
              onClick={() => setShowModal(true)}
            >
              Send {emailList.length} Emails
            </button>
          ) : null}

    {showModal && (
      <div className="fixed z-10 inset-0  overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

          <div className="inline-block align-bottom w-full h-full bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  Send {emailList.length} recipients an email
                  </h3>
                  <div className="mt-2">
                    <label htmlFor="subject" className="block text-sm font-medium text-gray-700">Subject</label>
                    <input 
                      type="text" 
                      id="subject" 
                      className="mt-1 block w-28rem rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
                      value={emailSubject} 
                      onChange={e => setEmailSubject(e.target.value)} 
                    />

                    <label htmlFor="body" className="block text-sm font-medium text-gray-700">Body</label>
                    <textarea 
                      id="body" 
                      className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
                      value={emailBody} 
                      onChange={e => setEmailBody(e.target.value)} 
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button 
                type="button" 
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-Blue   text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={handleSendEmails}
              >
                Send
              </button>
              <button 
                type="button" 
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    )}
  </>
   )
  )}
    </div>
</div>

      {/************** END FILTERS ***********************/}
  
      {/**TABLE */}
      { loading ? 
      (<div className='flex mx-auto items-center flex-col justify-center'>
      <Loader/>
      <h1 className='mt-10'>Loading Results...</h1>
      </div>):
      (<>
      <div className='overflow-auto' style={{ maxHeight: '50px' }}>
      <table className="w-full divide-y divide-gray-200">
        <thead className="bg-gray-200">
          <tr className="grid grid-cols-4 font-bold">
          {Object.keys(fieldMapping).map((field, index) => (
              <th
                scope="col"
                className={`px-6 py-2 text-left 2xl:text-base font-bold md:text-xs border-gray-200 hover:bg-gray-300 font-medium text-gray-500 uppercase tracking-wider transition-colors duration-500 ease-in-out hover:text-red-500 cursor-pointer select-none`}
                onClick={() => handleSort(field)}
              >
                <div className="flex items-center justify-start">
                  {index === 0 && <input type="checkbox" className="w-4 h-4 mr-2" checked={selectAll} onChange={handleSelectAll} onClick={(event) => event.stopPropagation()} />}
                  <span className={index === 0 ? 'ml-5' : ''}>{fieldMapping[field]}</span>
                  {sortOrder.field === field && sortOrder.order && (sortOrder.order === 'asc' ? <FaArrowUp className='ml-3' style={{ display: 'inline-block' }} /> : <FaArrowDown className='ml-3' style={{ display: 'inline-block' }} />)}
                </div>
              </th>
            ))}
          </tr>
        </thead>
      </table>
    </div>
    <div className='overflow-auto' style={{ maxHeight: '60vh' }}>
      <table className="w-full divide-y divide-gray-200">
        <tbody className="bg-white divide-y divide-gray-200">
        {sortedData && sortedData.map((row, index) => {
            return (
              <tr 
                key={index} 
                className="grid grid-cols-4 font hover:bg-gray-100 transition-colors duration-500 ease-in-out cursor-pointer"
                onMouseDown={(event) => handleRowClick(event, row)}
              >
                <td className="px-6 py-2 text-left flex items-center 2xl:text-base md:text-0.7 whitespace-nowrap text-sm border-b text-gray-800">
                  {/* <input 
                    type="checkbox" 
                    className="w-4 h-4 mr-2" 
                    checked={selectedRows.some(selectedRow => selectedRow.index === index)} 
                    onChange={(event) => { 
                      handleRowSelect(event, row, index);
                      event.stopPropagation(); 
                    }}
                  /> */}
                  <img src={row.image_url} alt={row.full_name} className="h-3rem w-3rem bg-red border-2 rounded-full ml-5" />
                  <div className='ml-5 flex flex-col'>
                    <span className='font-bold w-5rem overflow-auto truncate'>{row.full_name}</span>
                    <span className='text-500 text-xs hover:underline'>@<a href={row.url} target='_blank' className='hover:underline' onClick={(event) => {
                      event.preventDefault(); // Prevent the onMouseDown event on the tr element from being triggered
                      window.open(row.url, '_blank'); // Open the link in a new tab
                    }}>{row.platform_username}</a></span>
                  </div>
                </td>
                <td className="px-6 ml-5 py-2 text-left flex items-center 2xl:text-base md:text-xs whitespace-nowrap font-roboto text-sm border-b text-gray-800">{formatNumber(row.follower_count || row.subscriber_count)}</td>
                <td className="px-6 py-2 ml-5 text-left flex items-center 2xl:text-base md:text-xs whitespace-nowrap font-roboto text-sm border-b text-gray-800">{formatNumber(row.average_likes)}</td> 
                <td className="px-6 py-2 ml-5 text-left flex items-center 2xl:text-base md:text-xs whitespace-nowrap font-roboto text-sm border-b text-gray-800">{Number((Number(row.engagement_rate) * 100).toFixed(2))}%</td>    
              </tr>
              )
            })}
        </tbody>
      </table>
    </div>
    </>)
    }
      {/**END TABLE */}

      {showFilters && (
        <div className='h-screen'>
          <div className="fixed h-screen inset-0 z-50 bg-black opacity-70 backdrop-blur-md"></div>
          <div className={`fixed right-0 top-0 bottom-0 w-2/3 z-50 bg-white overflow-auto ${isClosing ? 'slide-out' : 'slide-in'}`}>
            <div className="flex justify-between items-center m-4">
              <h1 className="font-bold font-roboto text-2xl">All Filters</h1>
              <button 
                className="text-gray-500 font-bold" 
                onClick={() => setIsClosing(true)}
              >
                Close
              </button>
            </div>
            <ul className="p-4 grid grid-cols-2 gap-4">
              <div className="relative">
                    <li className="relative flex flex-col items-start py-4 border shadow-md border-gray-300 rounded cursor-pointer hover:bg-gray-100 transition-colors duration-200 ease-in-out">
                      
                      <div className='flex'><span className="font-bold font-nunito text-2xl mx-2">+</span>
                      <span className='font-bold font-nunito'>Follower Count</span>
                       </div>
                       <div className="flex items-center mt-2 ml-2">
                          <Select
                            options={followerOptions}
                            onChange={handleFollowerChange(0)}
                            isSearchable={false}
                            defaultValue={followerOptions.find(option => option.value === followerCount[0])}
                          />
                          <span className="mx-2">to</span>
                          <Select
                            options={followerOptions}
                            onChange={handleFollowerChange(1)}
                            isSearchable={false}
                            defaultValue={followerOptions.find(option => option.value === followerCount[1])}
                          />
                        </div>
                    </li>
                  </div>
              <div className="relative">
                <div className="relative flex flex-col items-start py-4 border shadow-md border-gray-300 rounded cursor-pointer hover:bg-gray-100 transition-colors duration-200 ease-in-out">
                  <div className="flex">
                    <span className="font-bold font-nunito text-2xl mx-2">+</span>
                    <span className='font-bold font-nunito'>Audience Location</span>
                  </div>
                  <div className="p-2 flex flex-col hover:bg-gray-100 transition-colors duration-200">
                    <Select
                      className="w-full hover:bg-gray-100 mt-2"
                        options={Object.keys(states).map((state) => {
                          const location_id = Object.keys(states[state])[0];
                          return { value: location_id, label: state };
                        })}
                        onChange={handleStateChange}
                        isSearchable
                      />
                    {selectedState && selectedState !== "" && (
                      <div className="text-center mt-2">
                        <h3 className='2xl:text-0.7 md:text-0.6'>Percentage of audience in {selectedState.label}</h3> <p>{selectedState.percentage}%</p>
                        <input type="range" min="0" max="100" value={selectedState.percentage} onChange={(event) => handlePercentageChange(event.target.value)} />            </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="relative">
                <li className="relative flex items-center py-4 border shadow-md border-gray-300 rounded cursor-pointer hover:bg-gray-100 transition-colors duration-200 ease-in-out">
                  <div className='flex-col'>
                    <span className="font-bold font-nunito text-2xl mx-2">+</span>
                    <span className='font-bold font-nunito'>Audience Gender</span>
                  
                    <Select
                      className="w-full hover:bg-gray-100 mt-2 ml-2"
                      options={genderOptions}
                      onChange={(selectedOption) => setGender(selectedOption ? selectedOption.value : '')}
                      isClearable
                    />
                    {gender && (
                      <div className='ml-2 mt-2'>
                        <label className="pt-2 mt-2">Percentage</label>
                        <input type="range" min="0" max="100" value={genderPercentage} onChange={(e) => setGenderPercentage(e.target.value)} />
                        <div className="flex flex-col 2xl:text-xs md:text-0.65 pt-2">
                          <p>{gender && `Selected Gender: ${gender}`}</p>
                          <p className='text-0.7 ml-2'>{genderPercentage > 0 && `Selected Percentage: ${genderPercentage}%`}</p>
                          {(gender && genderPercentage > 0) && (
                            <button 
                              className="bg-gray-200 font-bold hover:text-white text-black px-2 py-1 rounded mt-2"
                              onClick={() => {
                                setGender('');
                                setGenderPercentage(0);
                              }}
                            >
                              Reset
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </li>
              </div>
              <div className="relative">
                  <li className="relative flex-col items-center py-4 border shadow-md border-gray-300 rounded cursor-pointer hover:bg-gray-100 transition-colors duration-200 ease-in-out"
                    >
                    <div className='flex'>
                      <span className="font-bold font-nunito text-2xl mx-2">+</span>
                      <span className='font-bold font-nunito'>Creator Location</span>
                    </div>
                    {/* Search by State or City Toggle */}
                    <div className="flex items-center justify-center 2xl:text-sm md:text-xs border-2 mx-auto w-fit mt-3 space-x-2 rounded-3xl relative">
                      <div 
                        className={`absolute left-0 top-0 h-full bg-Blue text-white rounded-3xl transition-transform duration-500 ease-in-out ${searchLocationBy === 'state' ? 'transform translate-x-0' : 'transform translate-x-full'}`}
                        style={{ width: '50%', background: '#648de5', zIndex: 1 }}
                      />
                      <div 
                        className={`cursor-pointer hover:bg-Blue hover:text-white px-2 py-1 z-10 ${searchLocationBy === 'state' ? ' text-white rounded-3xl' : 'bg-white text-gray-700 rounded-2xl'}`} 
                        onClick={() => setSearchLocationBy('state')}
                      >
                        State
                      </div>
                      <div 
                        className={`cursor-pointer hover:bg-Blue hover:text-white px-2 py-1 z-10 ${searchLocationBy === 'city' ? ' text-white rounded-3xl' : 'bg-white text-gray-700 rounded-2xl'}`} 
                        onClick={() => setSearchLocationBy('city')}
                      >
                        City
                      </div>
                    </div>
                    {searchLocationBy === 'state' && (
                      <Select
                        className="w-2/3 hover:bg-gray-100 mt-2 mx-6"
                          options={Object.keys(states).map((state) => {
                            const location_id = Object.keys(states[state])[0];
                            return { value: location_id, label: state };
                          })}
                          onChange={handleCreatorStateChange}
                          isSearchable
                          isMulti
                        />
                    )}
                    {searchLocationBy === 'city' && (
                      <>
                        <Select 
                          type='text'
                          className="w-2/3 hover:bg-gray-100 mt-2 mx-6"
                          options={cityQueryOptions.map(city => ({ value: city.id, label: city.display_name }))}
                          onInputChange={handleInputChangeCity}
                          onChange={handleCreatorCityChange}
                          noOptionsMessage={()=> 'No cities found'}
                          isMulti
                          isClearable
                        />
                      </>
                    )}

                  </li>
              </div>
              <div className="relative">
                  <li className="relative flex flex-col items-start py-4 border shadow-md border-gray-300 rounded cursor-pointer hover:bg-gray-100 transition-colors duration-200 ease-in-out">
                    <div className='flex'>
                      <span className="font-bold font-nunito text-2xl mx-2">+</span>
                      <span className='font-bold font-nunito'>Creator Age</span>
                    </div>
                    <div className="flex items-center mt-2 ml-2">
                      <Select
                        options={ageOptions}
                        onChange={(option) => setCreatorAge([Number(option.value), creatorAge[1]])}
                        isSearchable={false}
                        defaultValue={ageOptions.find(option => option.value === creatorAge[0])}
                      />
                      <span className="mx-2">to</span>
                      <Select
                        options={ageOptions}
                        onChange={(option) => setCreatorAge([creatorAge[0], Number(option.value)])}
                        isSearchable={false}
                        defaultValue={ageOptions.find(option => option.value === creatorAge[1])}
                      />
                    </div>
                  </li>
                </div>
                <div className="relative">
                    <li className="relative flex flex-col items-start py-4 border shadow-md border-gray-300 rounded cursor-pointer hover:bg-gray-100 transition-colors duration-200 ease-in-out">
                      <div className='flex'>
                        <span className="font-bold font-nunito text-2xl mx-2">+</span>
                        <span className='font-bold font-nunito'>Creator Gender</span>
                      </div>
                      <div className="flex items-center mt-2 ml-2">
                        <Select
                          options={genderOptions}
                          onChange={(option) => setCreatorGender(option.value)}
                          isSearchable={false}
                          defaultValue={genderOptions.find(option => option.value === creatorGender)}
                        />
                      </div>
                    </li>
                  </div>
                <div className="relative">
                    <li className="relative flex flex-col items-start py-4 border shadow-md border-gray-300 rounded cursor-pointer hover:bg-gray-100 transition-colors duration-200 ease-in-out">
                      <div className='flex'>
                        <span className="font-bold font-nunito text-2xl mx-2">+</span>
                        <span className='font-bold font-nunito'>Audience Age</span>
                      </div>
                      <div className="flex items-center mt-2 text-0.7 ml-2">
                        <Select
                          options={ageOptions}
                          onChange={(option) => setAudienceAge([Number(option.value), audienceAge[1]])}
                          isSearchable={false}
                          defaultValue={ageOptions.find(option => option.value === audienceAge[0])}
                        />
                        <span className="mx-2">to</span>
                        <Select
                          options={ageOptions}
                          onChange={(option) => setAudienceAge([audienceAge[0], Number(option.value)])}
                          isSearchable={false}
                          defaultValue={ageOptions.find(option => option.value === audienceAge[1])}
                        />
                      </div>
                      <p className='ml-5 mt-2 text-0.7'>{percentage > 0 && `Selected Percentage: ${percentage}%`}</p>
                      <input className='ml-5' type="range" min="0" max="100" value={percentage} onChange={(e) => setPercentage(e.target.value)} onBlur={(e) => e.stopPropagation()} />
                      {(percentage > 0 || (audienceAge[0] > 0 && audienceAge[1] > 0)) && (
                        <button 
                          className="bg-gray-200 ml-5 font-bold hover:text-white text-black px-2 py-1 rounded mt-2"
                          onClick={() => {
                            setPercentage(0);
                            setAudienceAge([0, 0]);
                          }}
                        >
                          Reset
                        </button>
                      )}
                    </li>
                  </div>
                  <div className="relative">
                <li className="relative flex items-center py-4 border shadow-md border-gray-300 rounded cursor-pointer hover:bg-gray-100 transition-colors duration-200 ease-in-out">
                  <div className='flex-col'>
                    <span className="font-bold font-nunito text-2xl mx-2">+</span>
                    <span className='font-bold font-nunito'>Bio Phrase</span>
                      <form className="flex flex-col w-full relative" onSubmit={handleBioSubmit}>
                        <div className="bg-white flex items-center ml-4 w-full shadow-md border-2 py-2 text-gray-700 leading-tight focus:border-red rounded-md">
                          <div className="flex flex-row flex-nowrap w-full">
                            {submittedBioPhrase && (
                              <div className="flex items-center m-1 h-6 bg-gray-200 rounded-full">
                                <button 
                                  type="button"
                                  onClick={() => setSubmittedBioPhrase('')}
                                  className="p-1 ml-2 font-bold rounded-full"
                                >
                                  x
                                </button>
                                <span className="px-2 py-1 text-sm">{submittedBioPhrase}</span>
                              </div>
                            )}
                            <input 
                              className="rounded-md w-full flex-grow 2xl:text-base md:text-0.7 p-2 md:p-2 sm:p-2 text-gray-700 leading-tight border-none focus:ring-0 focus:ring-offset-0 focus:outline-none h-1rem ml-4" 
                              id="search" 
                              type="text" 
                              placeholder="Add bio phrase" 
                              onChange={(event) => {
                                setBioPhrase(event.target.value);
                                console.log(event.target.value); // Log the new value
                              }}
                              value={bioPhrase}
                            />
                          </div>
                          <button type="submit" style={{ display: 'none' }}>Submit</button>
                        </div>
                      </form>
                  </div>
                </li>
              </div>
              <div className="relative" onClick={() => setIsModalOpen(true)}>
                  <li className="relative flex items-center py-4 border shadow-md border-gray-300 rounded cursor-pointer hover:bg-gray-100 transition-colors duration-200 ease-in-out"
                    >
                      <div className='flex'>
                    <span className="font-bold font-nunito text-2xl mx-2">+</span>
                    <span className='font-bold font-nunito'>Contact Options</span>
                    </div>
                <div className="overflow-auto grid grid-cols-5 w-1/2 flex flex-col h-3rem gap-2">
                  {selectedContactOptions.length > 0 && selectedContactOptions.map((option, index) => (
                    <div key={index} className="rounded-2xl bg-gray-200 p-1 m-1 inline-block flex items-center">
                      {option.type}
                      <button 
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering the onClick event of the parent div
                          handleDeselect(option.type);
                        }}
                        className="ml-2 bg-gray-200 text-black rounded-full w-4 h-4 flex items-center justify-center"
                      >
                        x
                      </button>
                    </div>
                  ))}
                </div>
                  </li>
              </div>
              <div className="relative">
                  <li className="relative flex items-center py-4 border shadow-md border-gray-300 rounded cursor-pointer hover:bg-gray-100 transition-colors duration-200 ease-in-out"
                    >
                    <div className='flex-col'>
                        <span className="font-bold font-nunito text-2xl mx-2">+</span>
                        <span className='font-bold font-nunito'>Topics</span>
                    
                    <form onSubmit={handleSearch} className="flex flex-col w-full relative">
                      <div className="bg-white flex items-center ml-4 w-full shadow-md border-2 py-2 text-gray-700 leading-tight focus:border-red rounded-md">
                        <div className="flex flex-row flex-nowrap w-full overflow-x-auto">
                          {selectedTags.map((tag) => (
                            <div key={tag} className="flex items-center m-1 h-6 bg-gray-200 rounded-full">
                              <button 
                                type="button"
                                onClick={(event) => handleRemoveTag(event, tag)}
                                className="p-1 ml-2 font-bold rounded-full"
                              >
                                x
                              </button>
                              <span className="px-2 py-1 text-sm">{tag}</span>
                            </div>
                          ))}
                        </div>
                        <input 
                          className="rounded-md w-full flex-grow 2xl:text-base md:text-0.7 p-2 md:p-2 sm:p-2 text-gray-700 leading-tight border-none focus:ring-0 focus:ring-offset-0 focus:outline-none h-1rem ml-4" 
                          id="search" 
                          type="text" 
                          placeholder="Add topics..." 
                          onChange={handleInputChange}
                          value={searchTerm}
                        />
                      </div>
                      </form>
                      </div>
                  </li>
              </div>
              <div className="relative">
                <li className="relative flex items-center py-4 border shadow-md border-gray-300 rounded cursor-pointer hover:bg-gray-100 transition-colors duration-200 ease-in-out">
                  <div className='flex-col'>
                    <span className="font-bold font-nunito text-2xl mx-2">+</span>
                    <span className='font-bold font-nunito'>Keyword</span>
                  
                    <form className="flex flex-col w-full relative" onSubmit={handleSubmit}>
                      <div className="bg-white flex items-center ml-4 w-full shadow-md border-2 py-2 text-gray-700 leading-tight focus:border-red rounded-md">
                        <div className="flex flex-row flex-nowrap w-full overflow-x-auto">
                          <div className="flex items-center m-1 h-6 bg-gray-200 rounded-full">
                            {submittedKeyword && (
                              <>
                                <button 
                                  type="button"
                                  onClick={() => setSubmittedKeyword('')}
                                  className="p-1 ml-2 font-bold rounded-full"
                                >
                                  x
                                </button>
                                <span className="px-2 py-1 text-sm">{submittedKeyword}</span>
                              </>
                            )}
                          </div>
                        </div>
                        <input 
                          className="rounded-md w-full flex-grow 2xl:text-base md:text-0.7 p-2 md:p-2 sm:p-2 text-gray-700 leading-tight border-none focus:ring-0 focus:ring-offset-0 focus:outline-none h-1rem ml-4" 
                          id="search" 
                          type="text" 
                          placeholder="Add keywords" 
                          onChange={(event) => {
                            setKeyword(event.target.value);
                            console.log(event.target.value); // Log the new value
                          }}
                          value={keyword}
                        />
                        <button type="submit" style={{ display: 'none' }}>Submit</button>
                      </div>
                    </form>
                  </div>
                </li>
              </div>
              <div className="relative">
                <div className="relative flex flex-col items-start py-4 border shadow-md border-gray-300 rounded cursor-pointer hover:bg-gray-100 transition-colors duration-200 ease-in-out">
                  <div className="flex">
                    <span className="font-bold font-nunito text-2xl mx-2">+</span>
                    <span className='font-bold font-nunito'>Creator Interests</span>
                  </div>
                  <div className="p-2 flex flex-col hover:bg-gray-100 transition-colors duration-200">
                    <Select
                      className="w-full hover:bg-gray-100 mt-2"
                        options={interestsOptions}
                        onChange={handleCreatorInterestsChange}
                        isSearchable
                        isMulti
                      />
                  </div>
                </div>
              </div>
            </ul>
            <div className="flex justify-between m-4 relative bottom-2 space-x-10">
              <div className="relative ">
                <button onClick={handleClick} className="bg-Blue shadow-lg text-base text-white rounded-md focus:outline-none px-4 py-3 md:px-4 md:py-2 sm:px-5 sm:py-2 flex items-center justify-center transform transition-transform duration-500 ease-in-out hover:scale-105 space-x-2">
                  <span>Get Results</span>
                </button>
              </div>
              <div>
                <button onClick={reset} className="">
                  <span className="tracking-widest text-gray-500 underline font-bold focus:outline-none px-4 py-3 md:px-6 md:py-3 sm:px-5 sm:py-2 flex items-center justify-center transform transition-transform duration-500 ease-in-out hover:scale-105 space-x-2">Remove Filters</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        )}
        {showProfile && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6">
              <button onClick={() => setShowProfile(false)}>Back</button>
              <CreatorDiscoveryProfile selectedRow={selectedRow} />
            </div>
          </div>
        )}
    </div>
  )
}

export default CreatorDiscoveryContent