import React from 'react';
const Checkout = () => {
  const retrievedObject = [];
  if (!sessionStorage.getItem('cart')) {
  } else {
    var jsonString = sessionStorage.getItem('cart');
    retrievedObject.push(JSON.parse(jsonString));

    const priceTotal = retrievedObject[0].map((prod, i) => {
      if (Number.isInteger(+prod.price)) {
        let price = +prod.price;
        let qty = 1;
        if (prod.qty) {
          qty = +prod.qty;
        } else {
          qty = 1;
        }
        let total = price * qty;
        return total;
      } else {
        return 1;
      }
    });
    var sum = priceTotal.reduce(function (a, b) {
      return a + b;
    }, 0);
  }
  return (
    <div className="md:w-1/4.7 rounded-lg mt-13 pt-5 z-100 overflow-x-hidden overflow-y-scroll h-150 p-5 bg-white">
      <div className="md:mx-12 xs:m-5  mt-9 text-red font-semibold md:text-5xl xs:text-sm">
        Order information
      </div>
      <div className="m-5 bg-gray-100 rounded-lg p-5 md:mx-12 z-0 mt-12">
        {retrievedObject[0] &&
          retrievedObject[0].map((prod, i) => (
            <div className="grid grid-rows-1 grid-flow-col gap-4 mb-3 w-full">
              <div className="w-48">{prod.name}</div>
              {prod.qty ? <div className="w-5">{prod.qty}</div> : <div className="w-5">1</div>}
              {prod.qty ? (
                <div className="text-red">${prod.price * prod.qty}</div>
              ) : (
                <div className="w-5">${prod.price}</div>
              )}
            </div>
          ))}
        <div className="flex -ml-9 -mb-2px">
          <div className="border-8 p-2 border-white bg-white rounded-full mr-24 mt-2 w-5 h-5"></div>
          <div className="border-8 p-2 border-white bg-white rounded-full ml-5px mt-2 w-5 h-5"></div>
        </div>
        <div
          style={{ borderTop: '2px gray dashed' }}
          className="grid grid-rows-1 grid-flow-col gap-4 mt-9 w-full">
          <div className="w-48 text-4xl pt-5">TOTAL</div>
          <div className="text-red text-4xl pt-5">${sum}</div>
        </div>
      </div>
      <p className="ml-12 mt-5 font-semibold md:text-3xl ">You have $5 bonuses</p>
      <p className="ml-12 mt-2 mx-12">
        You can use your available bonuses to pay for the products.
      </p>

      <p className="ml-12 mt-9 mx-12 text-xs text-Lgray">Enter bonuse amount</p>
      <p className="m-3 ml-12 mt-2 mb-12 bg-gray-200 p-3  w-48 rounded-xl">5</p>
      <div className="bg-red  w-32 mx-12 py-3 font-bold rounded-xl text-white mt-4 items-center justify-center text-center cursor-pointer">
        <button className="w-full font-semibold">Apply Bonus</button>
      </div>
    </div>
  );
};
export default Checkout;
