import React, { useEffect, useRef, useState } from 'react'

export default function useFileUploader() {
    const [files, setFiles] = useState({})
    const [progress, setProgress] = useState(0);
    const [uploadError, setUploadError] = useState("");
  
    const inputRef = useRef(null)

    useEffect(() => {
        if(progress === 100) {
          setTimeout(() => {
            setProgress(0);
          }, 5000)
        }
      }, [
        progress
      ]);

    const handleRetryUpload = () => {
        inputRef.current.click();
        setUploadError("")
    }

    const uploadHanlder = (event) => {
        setProgress(1);
        const maxFileSize = 2 * 1024 * 1024 * 1024; // 2GB in bytes
    
          const files = Array.from(event.target.files);
          let  totalSizeInBytes = 0;
    
          // Calculate total size of uploaded files
          for (let i = 0; i < files.length; i++) {
            totalSizeInBytes += files[i].size;
          }
    
          if(totalSizeInBytes > maxFileSize) {
            setUploadError("Total file size exceeds 2GB limit")
            inputRef.current.value = null;
            return;
          }
    
          for (let i=0; i < files.length; i++ ){
            const file = files[i];
          
            if (file) {
              const reader = new FileReader();
              
              reader.onprogress = (event) => {
                if (event.lengthComputable) {
                  const percentLoaded = Math.round((event.loaded / event.total) * 100);
                  setProgress(percentLoaded);
                }
              };
              reader.onerror = (event) => {
    
                let errorMessage = 'An error occurred while reading the file.';
          
                  // Get more specific error messages if available
                  switch(event.target.error.code) {
                    case event.target.error.NOT_FOUND_ERR:
                      errorMessage = 'File not found.';
                      break;
                    case event.target.error.NOT_READABLE_ERR:
                      errorMessage = 'File is not readable.';
                      break;
                    case event.target.error.ABORT_ERR:
                      errorMessage = 'File reading was aborted.';
                      break;
                    case event.target.error.SECURITY_ERR:
                      errorMessage = 'File reading was blocked due to security restrictions.';
                      break;
                    default:
                      errorMessage = `Error reading file: ${event.target.error}`;
                  }

                  setUploadError(errorMessage);
              }
              if(i === (files.length - 1)) {
                setFiles(files);
              }
              reader.readAsArrayBuffer(file);
            }
          } 
      }
    
  return {
    files,
    inputRef,
    progress,
    uploadError,
    uploadHanlder,
    handleRetryUpload
  }
}
