import React, {useState, useEffect, useRef} from 'react'
import { Modal } from 'react-responsive-modal';
import NewTaskModal from './NewTaskModal';
import StudioOutReach from './StudioOutreach';

function NewTaskTabs(props) {
  const [activeTab, setActiveTab] = useState(0); // Initialize activeTab state to 0
  const [taskData, setTaskData] = useState(null);
  const newTaskModalRef = useRef(null);
  useEffect(() => {
    console.log(taskData); // this will log the updated taskData whenever it changes
  }, [taskData]);
  const handleClose = () => {
    setActiveTab(0); // Reset activeTab to 0
    if (newTaskModalRef.current) {
      newTaskModalRef.current.resetForm();
    }
    props.closeAction(); // Call the original close action passed through props
  };
  return (
    <Modal
    open={props.isOpen}
    onClose={handleClose} // Use the modified handleClose function
    center
    classNames={{ modal: 'customWideModal' }}
    closeOnOverlayClick={false}>
      <ul style={{display: 'flex', borderBottom: '1px solid black'}}>
          <li
            style={{
              borderBottom: activeTab === 0 ? '2px solid blue' : 'none',
              padding: '10px',
              cursor: 'pointer'
            }}
          >
            Create Studio Brief
          </li>
          <li
            style={{
              borderBottom: activeTab === 1 ? '2px solid blue' : 'none',
              padding: '10px',
              cursor: 'pointer'
            }}
          >
            Studio Outreach
          </li>
      </ul>
      {activeTab === 0 && <NewTaskModal taskData={taskData} setTaskData={setTaskData} setActiveTab={setActiveTab} isOpen={props.isOpen} ref={newTaskModalRef} closeAction={handleClose} />} {/* Pass setActiveTab, isOpen, and modified closeAction to NewTaskModal */}
      {activeTab === 1 && <StudioOutReach taskData={taskData} isOpen={props.isOpen} closeAction={handleClose}/>} {/* Render StudioOutreach when activeTab is 1 with modified closeAction */}
  </Modal>
  );
}

export default NewTaskTabs