import React, {useEffect, useState, useContext} from 'react'
import BrandCard from './BrandCard'
import { OrganizationService, useOrganizationState } from '../../Service/OrganizationService';
import { useAuth } from '../../Context/AuthContext';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';
import { useParams } from 'react-router-dom';
import NewBrandModal from './NewBrandModal';
import { OrgContext } from '../../routes/PrivateRoute/OrganizationDashboard';
function OrganizationBrands() {
  const orgState = useOrganizationState();
  const { id } = useParams();
  const {orgId} = useContext(OrgContext);
  const [modal, setModal] = useState(false);
  const {brandId, setBrandId} = useContext(BrandContext);
 console.log("org id: ", id);
  
  useEffect (() => {
    if (id){
      OrganizationService.GetBrands(id);
    }
  }, [id])

  let brands =  orgState.brands.value;
  console.log("brands: ", brands);

  const dummyData = [
    {
      brandPhoto: "https://crafted-react-images.s3.amazonaws.com/html_logo.png",
      brandName: "Dummy Brand Name 1",
      credits: 1000
    },
    {
      brandPhoto: "https://crafted-react-images.s3.amazonaws.com/html_logo.png",
      brandName: "Dummy Brand Name 2",
      credits: 5000
    },
    {
      brandPhoto: "https://crafted-react-images.s3.amazonaws.com/html_logo.png",
      brandName: "Dummy Brand Name 1",
      credits: 1000
    },
    {
      brandPhoto: "https://crafted-react-images.s3.amazonaws.com/html_logo.png",
      brandName: "Dummy Brand Name 1",
      credits: 1000
    },
    {
      brandPhoto: "https://crafted-react-images.s3.amazonaws.com/html_logo.png",
      brandName: "Dummy Brand Name 1",
      credits: 1000
    },
    // Add more dummy objects as needed
  ];
 
  return (
    <div className="bg-white m-4 rounded-md lg:w-full xs:h-fit h-148">
      <div className='flex flex-col p-4  w-full bg-white rounded-md'>
      <div className='flex justify-between mb-4 border-b'>
        <h1 className='font-bold m-4 text-3xl'>Brands </h1>
        <button
         onClick={() => setModal(!modal)}
          className="bg-red text-white text-base mb-2 font-bold md:px-4 w-32 md:py-2 rounded-lg mt-2 mr-3"
        >
          Add Brand
        </button>
      </div>  
      </div>
      <h3 className=' text-gray-500 text-base ml-4 mb-2 '>Click on the brand you want to view</h3>
      <div className="grid lg:grid-cols-5 m-4 grid-cols-1 overflow-auto h-41 md:grid-cols-2 gap-2 mx-2">
      {brands && Object.entries(brands).map(([id, el]) => (
            <BrandCard // use the key as the id
              brandPhoto={el.brand_avatar}
              brandName={el.brand_name}
              paidUsage={el && el.billing && el.billing.paidUsage || 0}
              currentUsage= {el && el.billing && el.billing.currentUsage || 0}
              id={el.id} // pass the key as the id
            />
        ))}
      </div>
      {modal ?
      <NewBrandModal open={modal} onClose={() => setModal(false)} /> 
      : null
      }
      </div>
  )
}

export default OrganizationBrands