import React, { useState, useEffect } from 'react';
import '../../../App.css';
import Loader from '../../Loader';
import { UsersService, useUserState } from '../../../Service/userServices';
import axios from 'axios';
import { baseUrl } from '../../../baseUrl';





const userInfoForm = (props) => {
  const { userInfo, userID } = props;
  console.log("THIS IS THE USERID",userID)

  const userState = useUserState();
  const [user, setUser] = useState({
    name: '',
    username: '',
    email: '',
    bio: '',
    avatar: '',
    creator_socials: {
        instagram: {},
        tiktok: {},
        youtube: {},
    },
    shipping_details: {
      address1: '',
      address2: '',
      city: '',
      country: '',
      fullname: '',
      state:'',
      zipcode:'',
    }
  });
  const [error, setError] = useState({
    name: '',
    bio: ''
  });

  const handleUpdateUser = () => {
    if (user.name === undefined) return setError({ ...error, name: 'name is required' });
    if (user.bio === undefined) return setError({ ...error, bio: 'bio is required' });
    if (user.email === undefined) return setError({ ...error, bio: 'email is required' });
    axios.put(`${baseUrl}api/admin/user/${userID}`, {
      name: user.name,
      email: user.email,
      bio: user.bio,
    }
    )
      .then((res) => {
        console.log('SUCCESS', res);
        console.log(userID)
      })
      .catch((err) => {
        console.log('ERROR!', err.response);
      })
  };
  useEffect(() => {
    setUser({
      name: userInfo.name || userInfo.profile_name,
      username: userInfo.username,
      email: userInfo.email,
      bio: userInfo.bio,
      avatar: userInfo.avatar,
      creator_socials: {
          instagram:{ 
            handle: userInfo && userInfo.creator_socials &&  userInfo.creator_socials.instagram && userInfo.creator_socials.instagram.handle
          },
          tiktok: {
            handle:userInfo && userInfo.creator_socials &&  userInfo.creator_socials.tiktok && userInfo.creator_socials.tiktok.handle
          },
          youtube: {
            handle:userInfo && userInfo.creator_socials &&  userInfo.creator_socials.youtube && userInfo.creator_socials.youtube.handle
          }
      },
      shipping_details:{
        address1: userInfo && userInfo.shipping_details && userInfo.shipping_details.address1,
        address2: userInfo && userInfo.shipping_details && userInfo.shipping_details.address2,
        city: userInfo && userInfo.shipping_details && userInfo.shipping_details.city,
        country: userInfo && userInfo.shipping_details && userInfo.shipping_details.country,
        fullname: userInfo && userInfo.shipping_details && userInfo.shipping_details.fullname,
        state: userInfo && userInfo.shipping_details && userInfo.shipping_details.state,
        zipcode: userInfo && userInfo.shipping_details && userInfo.shipping_details.zipcode
      }
    });
  }, [userInfo]);

  


  return(
    <div>
      <div className="mb-3 flex-col items-center">
        <label className="text-darkBlack font-bold capitalize">Name</label>
        <input
          className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
          type="text"
          value={user.name}
          placeholder={user.name}
          onChange={(e) => setUser({ ...user, name: e.target.value })}
        />
        {error && error.name && <span className="text-sm text-Red">{error.name}</span>}
      </div>
      <div className="mb-3">
        <label className="text-darkBlack font-bold capitalize">UserName</label>
        <input
          className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
          type="text"
          value={user.username}
          placeholder={user.username}
          onChange={(e) => setUser({ ...user, username: e.target.value })}
        />
      </div>
      <div className="mb-3">
        <label className="text-darkBlack font-bold capitalize">Email</label>
        <input
          className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
          type="email"
          value={user.email}
          placeholder={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
        />
      </div>
      <div className="mb-3">
        <label className="text-darkBlack font-bold capitalize">Phone Number</label>
        <input
          className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
          type="number"
          disabled
        />
      </div>
      <div className="mb-3">
        <label className="text-darkBlack font-bold capitalize">Bio</label>
        <textarea
          className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
          value={user.bio}
          onChange={(e) => setUser({ ...user, bio: e.target.value })}
        />
        {error && error.bio && <span className="text-sm text-Red">{error.bio}</span>}
      </div>

      <button
        className=" mt-2 mx-auto bg-Red rounded text-white py-2 px-14 block"
        type="button"
        onClick={() => handleUpdateUser()}
      >
        {userState.loading.value && <Loader />}
        Update User
      </button>
    </div>
  )
}


export default userInfoForm;