import React, { useState, useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import Modal from '../../../utils/brandModal';
import { IoMdClose } from "react-icons/io";
import CreditPackStripeContainer from '../CreditPackStripeContainer';
import { BsArrowLeft } from 'react-icons/bs';
import CustomButton from '../../../utils/customButton';
const modalStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999,
};


function SelectPlanModal(props) {
    const [tab, setTab] = useState(0);
    const [secondaryTab, setSecondaryTab] = useState(0);
    const [subscribeData, setSubscribeData] = useState({
      type: '',
      quantity: '',
      credits: 0,
      price: '',
      promo: '',
      discount: 0,
      videos: 0,
      quote: '',
    });

    /*
    
    //LIVE PRODUCT PRICES & PROMOS

    const STUDIO_ADS_PACK_PRICE = 'price_1OX6FHJZT64pbPqUBGP7aXRr';
    const STUDIO_GROWTH_PACK_PRICE = 'price_1OX6LrJZT64pbPqUy05K0dwL';
    const STUDIO_SCALE_PACK_PRICE = 'price_1OX95oJZT64pbPqUFAl42sYd';

    

    const STUDIO_GROWTH_PROMO = 'promo_1OX9AmJZT64pbPqUxdtGnnVn';
    const STUDIO_SCALE_PROMO = 'promo_1OX9BYJZT64pbPqUucgnnEtR';*/


    // TEST PRODUCT PRICES & PROMOS 

    const STUDIO_ADS_PACK_PRICE = 'price_1OgUQYJZT64pbPqURIXCBAba';
    const STUDIO_GROWTH_PACK_PRICE = 'price_1OgUQzJZT64pbPqUG1P6bz7K';
    const STUDIO_SCALE_PACK_PRICE = 'price_1OgURNJZT64pbPqUlN6fxugo';

    

    const STUDIO_GROWTH_PROMO = 'promo_1OgUSxJZT64pbPqUZenJCYgX';
    const STUDIO_SCALE_PROMO = 'promo_1OgUTMJZT64pbPqU2NuR0cdE';

    const handleAdsPack = () => {
      setSubscribeData({
        ...subscribeData,
        type: 'ads',
        quantity: 1,
        credits: 1500, 
        price: STUDIO_ADS_PACK_PRICE,
        price_string: '1,500.00',
        discount: 0,
        videos: 5,
        quote: 'For beginner level paid social ad managers',
      });

      setSecondaryTab(1);
    }

    useEffect(() => {
      if (props.open) {
        setTab(props.tab); // Set the tab based on the prop passed from the parent component
      }
    }, [props.open, props.tab]);
  

    const handleGrowthPack = () => {
      setSubscribeData({
        ...subscribeData,
        type: 'growth',
        quantity: 1,
        credits: 3000,
        price: STUDIO_GROWTH_PACK_PRICE,
        price_string: '2,400.00',
        promo: STUDIO_GROWTH_PROMO,
        discount: 20,
        videos: 10,
        quote: 'For advanced level paid social ad managers',
      });

      setSecondaryTab(1);
    }

    const handleScalePack = () => {
      setSubscribeData({
        ...subscribeData,
        type: 'scale',
        quantity: 1,
        credits: 9000,
        price: STUDIO_SCALE_PACK_PRICE,
        price_string: '4,500.00',
        promo: STUDIO_SCALE_PROMO,
        discount: 30,
        videos: 30,
        quote: 'For expert level paid social ad managers',
      });

      setSecondaryTab(1);
    }

    return (
      <>
      <div
        // classNames={{ modal: 'customModal' }}
        style={modalStyles}
        closeOnOverlayClick={false}>
        <div className="bg-white p-10 w-4/5 rounded-md h-40 fixed">
        
          <>
          <div className="flex mb-5 ml-5 gap-4">
            <div className={`w-auto font-bold px-5 py-1 cursor-pointer ${tab === 0 ? 'text-white bg-red rounded-xl px-5 py-1' : ''}`} onClick={() => setTab(0)}>
              Crafted Studio
            </div>
            <div className={`w-auto font-bold px-5 py-1 cursor-pointer ${tab === 1 ? 'text-white bg-red rounded-xl px-5 py-1' : ''}`} onClick={() => setTab(1)}>
              Crafted Amplify
            </div>
            <button className="absolute top-2 right-2 " onClick={props.close}><IoMdClose className='2xl:w-10 2xl:h-10 w-5 h-5'/></button>
          </div>
            { tab === 0 && (
              <>
                { secondaryTab === 0 && (
                <>
                <h1 className="text-center text-4xl font-bold">Select the plan that fits your needs</h1>

                <div className="md:h-128 xs:h-auto 2xl:text-base text-xs  md:flex md:w-6px xs:w-full mx-auto">
                  <div className="h-28 mt-10 px-10 bg-lightGray shadow-md md:w-3px xs:w-4px md:mx-8 xs:mx-auto rounded-xl">
                    <h1 className="text-xl bg-black text-white m-8 w-1/3 items-center mx-auto font-bold mb-3 font-black xs:xl md:text-4xl text-center rounded-xl">
                      ADS
                    </h1>
                    <h1 className="2xl:text-6xl md:text-5xl text-xs  m-8 font-bold mb-3 font-black text-center">
                      $1,500 <span className='line-through text-sm text-darkGray'></span>
                    </h1>

                    <div className='flex flex-col items-center'>
                      <span className='py-2 font-bold 2xl:text-2xl'>1500 CREDITS</span>
                      <span className='py-2 font-bold'>UP TO 5 VIDEOS</span>
                      <span className='py-2'>$2 for each additional credit</span>
                    </div>

                    <div className='py-3   text-center'>
                      For brands looking for ad content.
                    </div>

                    <button
                      className="mt-4 mx-auto hover:bg-blue-darkest shadow-sm bg-Blue rounded text-white py-2 px-14 block"
                      type="button"
                      onClick={handleAdsPack}>
                      Select Plan
                    </button>
                  </div>
                  <div className="h-28 mt-10 px-10 bg-lightGray shadow-md md:w-3px xs:w-4px md:mx-8 xs:mx-auto rounded-xl">
                    <h1 className="text-xl bg-black text-white m-8 2xl:w-1/2 w-2/3 mx-auto items-center font-bold mb-3 font-black xs:xl md:text-4xl text-center rounded-xl">
                      GROWTH
                    </h1>
                    <div className="flex flex-col items-center">
                      <div className="flex items-center">
                        <span className='text-xs 2xl:text-base bg-red rounded-md text-white px-3'>20% off</span>
                        <span className="line-through text-base 2xl:text-3xl md:text-base text-darkGray ml-2">$3,000</span>
                      </div>
                      <div className="2xl:text-6xl md:text-5xl text-xs font-bold mt-1">$2,400</div>
                    </div>

                    <div className='flex flex-col items-center'>
                      <span className='py-2 font-bold 2xl:text-2xl'>3000 CREDITS</span>
                      <span className='py-2 font-bold'>UP TO 10 VIDEOS</span>
                      <span className='py-2'>$2 for each additional credit</span>
                    </div>

                    <div className='py-2 text-center'>
                    For brands looking to kickstart their social media accounts.
                    </div>

                    <button
                      className="md:mt-4 2xl:mt-0 mx-auto 2xl:mb-0.5 hover:bg-blue-darkest shadow-sm bg-Blue rounded text-white py-2 px-14 block"
                      type="button"
                      onClick={handleGrowthPack}>
                      Select Plan
                    </button>
                  </div>
                  <div className="h-28 mt-10 px-10 bg-lightGray shadow-md md:w-3px xs:w-4px md:mx-8 xs:mx-auto rounded-xl">
                    <h1 className="text-xl bg-black text-white m-8 2xl:w-1/2 w-2/3 px-4 items-center  mx-auto font-bold mb-3 font-black xs:xl md:text-4xl text-center rounded-xl">
                      SCALE
                    </h1>
                    <div className="flex flex-col items-center">
                      <div className="flex items-center">
                        <span className='text-xs 2xl:text-base bg-red rounded-md text-white px-3'>30% off</span>
                        <span className="line-through text-base 2xl:text-3xl md:text-base text-darkGray ml-2">$9,000</span>
                      </div>
                      <div className="2xl:text-6xl md:text-5xl text-xs font-bold mt-1">$6,300</div>
                    </div>

                    <div className='flex flex-col items-center'>
                      <span className='py-2 font-bold 2xl:text-2xl'>9000 CREDITS</span>
                      <span className='py-2 font-bold'>UP TO 30 VIDEOS</span>
                      <span className='py-2'>$2 for each additional credit</span>
                    </div>

                    <div className='py-2 text-center'>
                    For brands looking to scale their social media accounts.
                    </div>

                    <button
                      className="md:mt-4 2xl:mt-0 mx-auto 2xl:mb-0.5 hover:bg-blue-darkest shadow-sm bg-Blue rounded text-white py-2 px-14 block"
                      type="button"
                      onClick={handleScalePack}>
                      Select Plan
                    </button>
                    
                  </div>
                </div>
                </>
                )}

                { secondaryTab === 1 && (

                        <div className='flex flex-col items-start'>
                        <div className='flex flex-row w-full items-center'>
                          <button className='flex items-center font-bold 2xl:text-base md:text-0.7 transform transition-transform duration-500 ease-in-out hover:scale-105' onClick={() => setSecondaryTab(0)}>
                            <BsArrowLeft className='mr-2' /> {/* Adjust margin-right as needed */}
                            <span>Back to plans</span>
                          </button>
                        </div>
                        <div className='flex flex-row w-full'>
                          <div className='w-1/2 px-2'> {/* Adjusted for equal width and added padding for spacing */}
                            <CreditPackStripeContainer
                              plan={subscribeData.type}
                              price={subscribeData.price}
                              qty={subscribeData.quantity}
                              promo={subscribeData.promo}
                              handleSubscribe={() => setSecondaryTab(2)}
                            />
                          </div>
                          <div className="w-1/2 h-28 mt-10 px-2 bg-lightGray shadow-md rounded-xl flex flex-col justify-between"> {/* Adjusted for equal width and added padding for spacing */}
                            <h1 className="text-xl bg-black text-white m-8 2xl:w-full w-full mx-auto items-center font-bold mb-3 font-black text-center rounded-xl">
                              {(subscribeData.type).toUpperCase()}
                            </h1>
                            <div className="flex flex-col items-center">
                              <div className="flex items-center">
                                {subscribeData && subscribeData.discount !== 0 && 
                                <>
                                  <span className='text-xs 2xl:text-base bg-red rounded-md text-white px-3'>{subscribeData.discount}% off</span>
                                  <span className="line-through text-base 2xl:text-3xl md:text-base text-darkGray ml-2">{subscribeData.price_string}</span>
                                </>}
                              </div>
                              <div className="2xl:text-6xl md:text-4xl text-xs font-bold mt-1">{subscribeData.price_string}</div>
                            </div>

                            <div className='flex flex-col items-center'>
                              <span className='py-2 font-bold 2xl:text-2xl'>{subscribeData.credits} CREDITS</span>
                              <span className='py-2 font-bold'>UP TO {subscribeData.videos} VIDEOS</span>
                              <span className='py-2'>$2 for each additional credit</span>
                            </div>

                            <div className='py-2 text-center'>
                              {subscribeData.quote}  
                            </div>
                          </div>
                        </div>
                        </div>
                )}

                { secondaryTab === 2 && (
                  <>
                  <p className='font-bold 2xl:text-base md:text-0.7 text-center flex mt-20 items-center justify-center'>
                    Congratulations! You've just purchased the {subscribeData.type} credit pack.
                  </p>
                  <CustomButton
                    className="bg-Blue  mt-8 rounded-md w-8rem py-2 text-center mx-auto font-bold flex 2xl:text-base md:text-0.7 items-center justify-center flex-row text-white"
                    onClick = {() => window.location.reload()}>
                    Continue
                  </CustomButton>
                  </>
                )}
              </>
            )}
            
          {tab === 1 && (
            <>
              <div className='flex flex-col mt-36 justify-center items-center'>
                <h1 className="text-center text-4xl font-bold">
                  To contact us for our rates, click the button below.
                </h1>
                 
                <button  className="md:mt-20 2xl:mt-10 mx-auto 2xl:mb-0.5 text-3xl hover:bg-blue-darkest shadow-sm bg-Blue rounded text-white py-8 px-14 block" onClick={() => window.location = 'mailto:sarah@usecrafted.com'}>Contact Us</button>
              </div>
            </>
          )}
          </>
        </div>
      </div>
    </>
  );
}

export default SelectPlanModal;