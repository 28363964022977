import { createState, useState } from '@hookstate/core';
const state = createState({
  currentTab: 0,
  currentCampTab: 0,
  currentCampModalTab: 0,
  currentSubCampTab: 9,
  currentUgcTab: 0,
  data:'',
  prevTab: false,
  nextTab: false
});

export const accessTabState = () => state;

export const useTabState = () => useState(state);
