import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Layout from '../../Layout/Dashboard/Organization';
import { useAuth } from '../../Context/AuthContext';  
import { AccountService, useAccountState } from '../../Service/AccountServices';
export const OrgContext = React.createContext({
    orgId: "",
    setOrgId: () => {}
  });
function OrganizationDashboardRoute({ component: Component, ...rest }) {
  const accountState = useAccountState();
  const [loading, setLoading] = useState(true);
  const [orgId, setOrgId] = useState(() => localStorage.getItem('orgId') || null); // Initialize with the value from localStorage
  const value = { orgId, setOrgId };
  const { currentUser } = useAuth();
  useEffect(() => {
    if (currentUser) {
      setLoading(true);
      AccountService.FetchAccountInfo(currentUser.uid);
      console.log("Fetching orgs for user: " + currentUser.uid);
      setLoading(false);
    }
  }, [currentUser]);
  const account = accountState.account.value;
  useEffect(() => {
    if (!loading && account && account.organizations && !orgId) {
      const orgs = account.organizations;
      console.log("Setting selected brand again:");
      console.log(orgs);
      const defaultOrgId = orgs && Object.keys(orgs)[0];
      setOrgId(defaultOrgId);
      console.log("org id: ", defaultOrgId);
      localStorage.setItem('orgId', defaultOrgId); // Store the brandId in localStorage
    }
  }, [accountState.updateNeed.value]);

  console.log(" AACCOUNT :", account);
  console.log("ORG ID: ", orgId);
  return (
    <Route
      {...rest}
      
      render={(props) => {
        return currentUser ? (
          <OrgContext.Provider value={value}>
          <Layout>
            <Component {...props} />
          </Layout>
          </OrgContext.Provider>
        ) : (
          <Redirect to="/login"></Redirect>
        );
      }}
    />
  );
}

export default OrganizationDashboardRoute;
