import React from 'react';
import { Modal } from 'react-responsive-modal';
import Collapsible from 'react-collapsible';
import { BsChevronCompactDown, BsChevronCompactUp } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const bg = {
  overlay: {
    background: 'rgba(128, 128, 128, 0.099)'
  }
};
const TaskDetails = (props) => {
  let products = [];
  if (props.task) {
    products = props.task.products
      ? Object.entries(props.task.products).map((e) => ({
          [e[0]]: e[1]
        }))
      : [];
  }

  let proposals = [];
  if (props.task) {
    proposals = props.task.accepted_proposals
      ? Object.entries(props.task.accepted_proposals).map((e) => ({
          [e[0]]: e[1]
        }))
      : [];
  }

  
  const StringChecker = ( text ) => {
    let formattedText = text;
    if (formattedText[0].length > 50) {
      formattedText[0] = formattedText[0].substring(0, 50) + '...';
    }
    return(formattedText[0]);
  };

  function extractBeforeAndAfterURL(str) {
    const regex = /(.*?)(https?:\/\/[^\s]+)/;
    const match = str.match(regex);
  
    if (match && match.length >= 3) {
      const beforeURL = match[1];
      const afterURL = str.substring(match[1].length + match[2].length);
      return { beforeURL, afterURL };
    }
  
    return { beforeURL: str, afterURL: '' };
  }
  const covertingString = () => {
    const regex = /(https?|ftp):\/\/[^\s/$.?#].[^\s]*/i;
    if (props.task && props.task.note3){
      if (regex.test(props.task.note3)){
        let extractedUrl = props.task.note3.match(/\bhttps?:\/\/\S+/gi);
        let visualLink = StringChecker(extractedUrl);
        const { beforeURL, afterURL } = extractBeforeAndAfterURL(props.task.note3);
        return(
          <div className='break-words max-w-full'>
            {beforeURL}
            <p className= "max-w-full text-blue-500" >
              <a className='text-blue-500 hover:underline cursor-pointer' href={props.task.note3.match(/\bhttps?:\/\/\S+/gi)[0]} target="_blank" style={{color: 'blue'}}>{visualLink}</a>
            </p>
            {afterURL}
          </div>
        )
      }
      else{
        return(
          <div>
            {props.task.note3}
          </div>
        )
      }
    }
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.close}
        center
        styles={bg}
        classNames={{ modal: 'customModal' }}
        closeOnOverlayClick={true}>
        <div className="mt-5">
          {/*<h1 className="font-bold mb-3 font-black xs:text-lg md:text-4xl">Brief Details</h1>*/}
          <Collapsible
          trigger={[
            'Brief Details',
            <div className="px-3 absolute right-0">
              <BsChevronCompactDown />
            </div>
          ]}
          triggerWhenOpen={[
            'Brief Details',
            <div className="px-3 absolute right-0">
              <BsChevronCompactUp />
            </div>
          ]}
          contentOuterClassName="mb-6"
          triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-darkBlack bg-lightGray relative"
          triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
            <div className="m-3 bg-white rounded-lg text-3xl dark:bg-gray-700">
              <div className="flex mb-5">
                <div className="flex mr-5 font-bold">Featured Products:</div>
                <div>{props.task && props.task.note1}</div>
              </div>
              <div className="flex mb-5">
                <div className="flex mr-5 font-bold">Target Demo:</div>
                <div>{props.task && props.task.note2}</div>
              </div>
              <div className="flex mb-5">
                <div className="flex mr-5 font-bold">Talking Points:</div>
                <div>
                  {covertingString()}
                </div>
              </div>
              <div className="flex mb-5">
                <div className="flex mr-2 font-bold">Task Credits:</div>
                <div>{props.task && props.task.task_credits}</div>
              </div>
              <div className="flex mb-5">
                <div className="flex mr-2 font-bold">Recipe Videos:</div>
                <div>{props.task && props.task.recipe_videos} | </div>
                <div className="flex mr-2 font-bold">Product Demos:</div>
                <div>{props.task && props.task.product_showcase}</div>
              </div>

              {/*<div className="flex mb-5">
                <div className="flex mr-5 font-bold">Estimated Deadline:</div>
                <div>{props.task && props.task_deadline_time}</div>
              </div>*/}
            </div>
          </Collapsible>
          <Collapsible
            trigger={[
              'Creator Details',
              <div className="px-3 absolute right-0">
                <BsChevronCompactDown />
              </div>
            ]}
            triggerWhenOpen={[
              'Creator Details',
              <div className="px-3 absolute right-0">
                <BsChevronCompactUp />
              </div>
            ]}
            contentOuterClassName="mb-6"
            triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-darkBlack bg-lightGray relative"
            triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
            <div className="m-3 bg-white rounded-lg text-3xl dark:bg-gray-700">
              <div className="flex mb-5">
                <div className="flex mr-5 font-bold">Creator:</div>
                <div>{props.task && props.task.creator_name}</div>
                {props.task && props.task.creator_id && (
                  <Link
                  className="bg-darkGray text-white px-2 py-1 m-1 ml-10 rounded-lg outline-none text-xs"
                  to={`/profile/${props.task.creator_id}`}
                  target="_blank">
                  View Creator
                </Link>
                )}
              </div>
              <div className="flex mb-5">
              <div className='flex flex-col mb-5'>
                <div className='pb-1 font-bold'>Selected Proposal: </div>
                <div className='p-3'>
                {Object.keys(props.task.accepted_proposals).map((prop, i) => (
                  <>
                    <div className="m-1">Video {i+1}: {props.task.accepted_proposals[prop]}</div>
                  </>
                ))}
                </div> 
              </div>
              </div>
              <div className="flex mb-5">
                <div className="flex mr-5 font-bold">Products:</div>
                <div>
                  {products.length > 0 &&
                    products
                      .slice(0)
                      .reverse()
                      .map((item, i) => {
                        const res = item[Object.keys(item)];
                        return (
                          <>
                            <div key={i}>{res.name}</div>
                          </>
                        );
                      })}
                </div>
              </div>
            </div>
          </Collapsible>
          <div className="font-bold text-red px-2 py-2 rounded-lg outline-none text-base items-center  my-5 justify-center text-center">
            Awaiting Deliverables from Creator
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default TaskDetails;
