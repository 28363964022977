import React, { useContext, useEffect, useState } from 'react';
import { useAuth } from '../../Context/AuthContext';
import NewTaskModal from './NewTaskModal';
import { TaskService, useTaskState } from '../../Service/TaskServices';
import { useBrandState, BrandService } from '../../Service/BrandServices';
import {useHistory} from 'react-router-dom'
import MyTaskCard from '../Brand_Dashboard/MyTaskCard';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';
import Loader from '../Loader2';
import * as Sentry from "@sentry/react";
import NewTaskTabs from './NewTaskTabs';
import CustomButton from '../../utils/customButton';
import { useAccountState, AccountService } from '../../Service/AccountServices';
function Tasks() {
  const taskState = useTaskState();
  const accountState = useAccountState();
  const [addTask, setAddTask] = useState();
  const { currentUser, currentBrand } = useAuth();
  const brandState = useBrandState();
  const { brandId } = useContext(BrandContext);
  const history = useHistory();
  const handleError = () => {
    try {
      throw new Error('Triggered a test error');
    } catch (error) {
      Sentry.captureException(error);
    }
  };
  
  //console.log("brandId in Tasks: " +  brandId);

  useEffect(() => {
    TaskService.FetchTasks();
  }, [taskState.updateNeed.value]);

  useEffect(() => {
    if (!currentUser || currentUser.uid === null) {
      window.location.href = '/brand/login';
    } else if (currentUser.uid) {
      AccountService.FetchAccountInfo(currentUser.uid);
    }
  }, [currentUser, history]);

  const account = accountState.account.value;
  const res = taskState.tasks.value;
  const task = res
    ? Object.entries(res).map((e) => ({
        [e[0]]: e[1]
      }))
    : [];
    //console.log("currentUser", currentUser)
  return (
    <div className="bg-white m-4 rounded-lg lg:w-full xs:h-fit"  style={{ height: 'calc(97vh - 5rem)'}}>
       <div className="flex items-center h-16 justify-between ">
        <h3 className="font-semibold p-8 pl-2 ml-5 2xl:text-2xl md:text-base xs:text-base">Crafted Studio</h3>
        {account && account.trial ?  
        <CustomButton
          disabled
          className=" text-white opacity-40 2xl:text-base md:text-base 2xl:px-4 2xl:py-2 md:px-2 md:py-1 rounded-lg mr-8"
          >
          + Add Task
        </CustomButton> : 
        <CustomButton
          className=" text-white 2xl:text-base md:text-base 2xl:px-4 2xl:py-2 md:px-2 md:py-1 rounded-lg mr-8"
          onClick={() => setAddTask(true)}>
          + Add Task
        </CustomButton>
      }
      </div>
        <p className='text-gray-500 2xl:text-base md:text-0.7 ml-8'>Crafted Studio delivers custom User-Generated Content (UGC) to engage your audience and enhance your online presence. Get started by creating a task where you can  request personalized short-form videos, photos, and reviews that resonate with your customers and drive your marketing objectives forward.</p>
    {taskState.loading.value ? ( <div className="bg-white m-4  rounded-lg lg:w-full h-40 "><Loader /></div>) :
   (
      <div className="bg-white m-4  rounded-lg lg:w-full 2xl:h-45rem md:h-27rem overflow-auto xs:h-fit">
        <div className="grid lg:grid-cols-5 grid-cols-1 overflow-y-scroll h-fit overflow-auto md:grid-cols-2 gap-2 mx-2">
        {taskState.loading.value ? (
  // Optionally, you can add a loader here
  <div className='flex items-center justify-center'>Loading...</div>
) : task.length === 0 ? (
  <div className='w-full'>
    <div className="text-center flex items-center justify-center font-bold mx-auto mt-6 mb-4 text-2xl">
      You currently have no UGC tasks ongoing, when you do they will appear here. To create another UGC task, click below.
    </div>
    <div className='mt-6 text-center'>
      <button
          className="bg-Blue font-bold px-2 py-2 rounded-md my-1 text-white text-lg w-32 items-left justify-left text-center cursor-pointer"
          onClick={() => setAddTask(true)}>
          Create UGC task
      </button>
    </div>
  </div>
) : (
  task
    .slice(0)
    .reverse()
    .map((item, i) => {
      const res = item[Object.keys(item)];
      const id = Object.keys(item);
      return (
        <>
          {brandId === res.brand_id ? (
            <div key={i}>
              <MyTaskCard  task={res} id={id} />
            </div>
          ) : null}
        </>
      );
    })
)}
        </div>
        <NewTaskTabs isOpen={addTask} closeAction={() => setAddTask(false)} />
      </div>
    )}
  </div>
  );
}

export default Tasks;
