import React, { useEffect, useState } from 'react';
import { useTabState } from '../../../utils/TabState';
import { useAuth } from '../../../Context/AuthContext';
import { CampaignService } from '../../../Service/CampaignServices';
import { CampaignState } from '../../../utils/campaignState';
import Loader from '../../Loader';
import toast from 'react-hot-toast';
import { Mixpanel } from '../../../mixpanel';

export default function CampaignConfirmation(props) {
  const tabState = useTabState();
  const { currentUser } = useAuth();
  const campaignState = CampaignState();
  const [numberOfVideos, setNumberOfVideos] = useState({});

  const handleContinue = async () => {
    tabState.merge({ currentCampModalTab: 3 });
  };


  const task = JSON.stringify(campaignState.tasks.value);
  const tempTask = JSON.parse(task);
  const influencerBudget = campaignState.influencer_reach_budget.value 

  useEffect(() => {
    if (tempTask.length) {
      const data = {};
      tempTask.forEach(el => {
        data[el.name] = el.number_of_videos;
      });
      setNumberOfVideos(data)
    }
  }, [])


  const calcualtedTotal = () => {
    //console.log("tempTask:");
    //console.log(tempTask);
    const data = tempTask.map(e => {
      const sum = e.add_ons.map(obj => ({ ...obj, price: Number(numberOfVideos[e.name]) * Number(obj.price) })).reduce((acc, ob) => acc + ob.price, 0)
      return sum + (Number(numberOfVideos[e.name]) * 200);
    });
    //const total = (tempTask.number_of_videos * 20000)+(100*(data.reduce((acc, curr) => (!isNaN(curr) ? acc + curr : acc), 0)))
    return data.reduce((acc, curr) => (!isNaN(curr) ? acc + curr : acc), 0);
  }

  const calcualtedVal = (el) => {
    const sum = el.add_ons.map(obj => ({ ...obj, price: Number(numberOfVideos[el.name]) * (Number(obj.price)) })).reduce((acc, ob) => acc + ob.price, 0);
    return sum + (Number(numberOfVideos[el.name]) * 200);
  }

  const TOTAL_BUDGET = eval(calcualtedTotal() + (Number(influencerBudget) / 100));


  return (
    <div className='p-4'>
      
      {
        tempTask.map((el, i) => {
          return (
            <div className={` ${(i + 1 % 2 !== 0) ? 'bg-lightGray' : ''} m-2 p-5 rounded-lg `}>
              <div className="flex rounded-lg mt-4 mx-2 ">
                <div className="w-1/5 p-2">
                  <input type="number" id="number" name={el.name} value={numberOfVideos[el.name]} className='p-3 w-2/3' onChange={(e) => setNumberOfVideos({ ...numberOfVideos, [el.name]: e.target.value })} />
                </div>

                <div className="w-4/5 p-2">
                  <p className="mx-2 font-bold">
                    {tempTask.name} Budget
                  </p>
                  {numberOfVideos[el.name]} Videos x 200 credits
                  {
                    el.add_ons.map(addOn => {
                      return (<p className="mx-2">
                        {numberOfVideos[el.name]} {addOn.name} x ${addOn.price}
                      </p>)
                    })
                  }

                  <p className="mx-2 mt-5 font-bold">
                    {calcualtedVal(el)} credits
                  </p>
                </div>
              </div>
            </div>
          )
        })
      }
      <div className="w-full mt-4 ">
        <div className="flex rounded-lg">
          <div className="w-1/4 p-2 font-bold border border-gray-300 rounded-lg items-center justify-center text-center ">
            <span>{influencerBudget / 100} Credits</span>
          </div>

          <div className="w-3/4 pl-4 font-bold">
            <span>Influencer Reach Budget</span>
          </div>

        </div>
      </div>
      <div className='justify-center text-center text-red'>
        <h1>Total Budget = {campaignState.credits.value} credits</h1>
        
      </div>

      <div className="flex justify-center">
        <button
          type="submit"
          className="py-2 w-fit bg-red font-bold w-1/3 px-4 mb-5 rounded-lg text-white mt-4 items-center justify-center text-center cursor-pointer"
          onClick={handleContinue}
        >
          {campaignState.loading.value && <Loader />}
          Confirm
        </button>
      </div>
    </div>
  );
}
