import React, { useEffect, useState, useContext } from "react";
import {
  useCampaignState,
  CampaignService,
} from "../../../Service/CampaignServices";
import { useParams } from "react-router-dom";
import { BsQuestionCircle } from "react-icons/bs";
import { FaEdit, FaCheck } from "react-icons/fa";
import { FaRegCheckCircle } from "react-icons/fa";
import { BsExclamationCircle } from "react-icons/bs";
import EditCampaign from "./EditCampaign";
import CustomGlobalDialog from "../CustomGlobalDialog";
import toast, { Toaster } from "react-hot-toast";
import { BrandContext } from "../../../routes/PrivateRoute/BrandDashboardRoute";
import CustomButton from "../../../utils/customButton";
import { useAuth } from "../../../Context/AuthContext";

function CampaignOverview() {
  const { id } = useParams();
  const campaignState = useCampaignState();
  const [proposalCount, setProposalCount] = useState(0);
  const [approvedProposalCount, setApprovedProposalCount] = useState(0);
  const [totalDrafts, setTotalDrafts] = useState(0);
  const [totalFinalVids, settotalFinalVids] = useState(0);
  const [totalPost, settotalFinalPost] = useState(0);
  const [numTask, setNumTask] = useState(0);
  const [influencerBudget, setInfluencerBudget] = useState(0);
  const [utilized_budget, setUtilized_budget] = useState(0);
  const [totalBudget, setTotalBudget] = useState(0);
  const [editModal, setEditModal] = useState(false);
  const [status, setStatus] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { brandId } = useContext(BrandContext);
  const { currentUser } = useAuth();

  const handleEditModal = () => {
    setEditModal(!editModal);
  };
  useEffect(() => {
    if (id) {
      CampaignService.FetchSingleCampaign(id);
    }
  }, [id]);

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const completeCampaign = () => {
    let data = {
      brand_id: brandId,
    };
    try {
      CampaignService.CompleteCampaign(id, data);
      setTimeout(() => {
        toast.success("Campaign completed successfully");
        setIsModalOpen(false);
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error(error);
      toast.error("Error completing campaign");
    }
  };

  useEffect(() => {
    const res = campaignState.campaign.value;
    let totalProposals = 0;
    let approvedProposals = 0;
    let drafts = 0;
    let finalVids = 0;
    let post = 0;
    let tasks = 0;
    let influencer_reach_budget = 0;
    for (const key in res && res.tasks) {
      totalProposals += Object.keys(res.tasks[key].proposals || {}).length;
      approvedProposals += Object.keys(
        res.tasks[key].approved_proposals || {},
      ).length;
      drafts += Object.keys(res.tasks[key].drafts || {}).length;
      post += Object.keys(res.tasks[key].posts || {}).length;
      for (const draft in res.tasks[key].drafts) {
        if (res.tasks[key].drafts[draft].status === "approved") {
          finalVids += 1;
        }
      }
      tasks++;
    }
    setInfluencerBudget(res.influencer_reach_budget);
    setTotalBudget(res.total_budget);
    setStatus(res && res.status);
    settotalFinalPost(post);
    settotalFinalVids(finalVids);
    setProposalCount(totalProposals - approvedProposals);
    setApprovedProposalCount(approvedProposals);
    setTotalDrafts(drafts - finalVids);
    setNumTask(tasks);
    setInfluencerBudget(res.influencer_reach_budget);
    setUtilized_budget(res.utilized_budget);
  }, [campaignState]);

  let campaign = campaignState.campaign.value;
  return (
    <div className=" bg-white w-full p-4 rounded-lg z-100">
      <Toaster />
      {status !== "completed" && (
        <div className="flex flex-row">
          {/*<button onClick={handleEditModal}
        className=' ml-4 text-white bg-red hover:bg-red-800 rounded-lg text-xs inline-flex items-center px-3 py-2.5 text-center mr-2'>
          <FaEdit className='h-4 w-4 mr-2 font-bold'/> Edit Campaign
      </button>*/}
          <CustomButton
            onClick={handleButtonClick}
            className="ml-10 mb-5 text-white hover:bg-red-800 text-xs inline-flex items-center px-3 py-2.5 text-center mr-2"
          >
            <FaRegCheckCircle classname="h-4 w-4 mr-2 font-bold" />
            <h1 className="ml-2">Complete Campaign</h1>
          </CustomButton>
          {/*<button className=' ml-4 text-white bg-red hover:bg-red-800  font-bold rounded-lg text-xs inline-flex items-center px-3 py-2.5 text-center mr-2'>
          Delete Campaign
        </button>*/}
        </div>
      )}
      {status === "completed" && (
        <div className="ml-10 mb-5 text-white bg-Blue hover:bg-red-800 rounded-md text-xs inline-flex items-center px-3 py-2.5 text-center mr-2">
          <FaCheck className="h-4 w-4 mr-2 font-bold" /> Campaign Completed
        </div>
      )}
      <div className="mx-auto mb-2">
        <div className="flex flex-row items-center justify-center">
          <h1 className="font-black 2xl:text-3xl md:text-base mb-4 text-center">
            Total Budget
          </h1>
          <div className="relative group ml-4 mb-4">
            <span className=" hover:text-black">
              <BsQuestionCircle className="" />
              <div
                className="absolute flex border border-gray-400 top-0 right-0 transform translate-x-full -translate-y-full w-48 h-fit bg-gray-200 text-gray-800 py-2 px-2 rounded opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto"
                style={{ lineHeight: "1.5", letterSpacing: "0.1em" }}
              >
                <span className="font-bold inline-flex text-xs">
                  This feature allows you to track your spending progress to
                  ensure you stay within your allocated budget for the campign.
                </span>
              </div>
            </span>
          </div>
        </div>
        <div className="w-full bg-gray-400 2xl:h-2rem md:h-1rem rounded-full relative">
          <div
            className="absolute top-0 left-0 2xl:h-2rem md:h-1rem bg-red-dark rounded-full"
            style={{
              width: `${Math.min(
                100,
                influencerBudget !== totalBudget
                  ? ((utilized_budget + totalBudget - influencerBudget) /
                      totalBudget) *
                      100
                  : (utilized_budget / totalBudget) * 100,
              )}%`,
              zIndex: 1,
            }}
          ></div>
          <div
            className="flex justify-center items-center h-full md:text-0.7 2xl:text-2xl text-white"
            style={{ position: "relative", zIndex: 2 }}
          >
            <span className="font-bold tracking-widest">
              {influencerBudget !== totalBudget
                ? `${parseInt(
                    (utilized_budget + totalBudget - influencerBudget) / 100,
                  ).toLocaleString()} / ${parseInt(
                    totalBudget / 100,
                  ).toLocaleString()} credits used`
                : `${parseInt(
                    utilized_budget / 100,
                  ).toLocaleString()} / ${parseInt(
                    totalBudget / 100,
                  ).toLocaleString()} credits used`}
            </span>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 ">
        <div className="shadow-md 2xl:p-2 md:p-1 bg-gray-100 rounded-lg flex items-center justify-center flex-col w-full mx-auto">
          <p className="font-black text-4xl mb-2 md:text-2xl">
            {proposalCount}
          </p>
          <h3 className="font-black text-xl md:text-base mb-1">
            Total Proposals
          </h3>
        </div>
        <div className="shadow-md 2xl:p-2 md:p-1 bg-gray-100 rounded-lg flex items-center justify-center flex-col w-full mx-auto">
          <p className="font-black text-4xl mb-2 md:text-2xl">
            {approvedProposalCount}
          </p>
          <h3 className="font-black text-xl md:text-base mb-1">
            Total Approved Proposals
          </h3>
        </div>
        <div className="shadow-md 2xl:p-2 md:p-1 bg-gray-100 rounded-lg flex items-center justify-center flex-col w-full mx-auto">
          <p className="font-black text-4xl mb-2 md:text-2xl">{totalDrafts}</p>
          <h3 className="font-black text-xl md:text-base mb-1">Total Drafts</h3>
        </div>
        <div className="shadow-md 2xl:p-2 md:p-1 bg-gray-100 rounded-lg flex items-center justify-center flex-col w-full mx-auto">
          <p className="font-black text-4xl mb-2 md:text-2xl">
            {totalFinalVids}
          </p>
          <h3 className="font-black text-xl md:text-base mb-1">
            Approved Video Drafts
          </h3>
        </div>
        <div className="shadow-md 2xl:p-2 md:p-1 bg-gray-100 rounded-lg flex items-center justify-center flex-col w-full mx-auto">
          <p className="font-black text-4xl mb-2 md:text-2xl">{totalPost}</p>
          <h3 className="font-black text-xl md:text-base mb-1">Posts</h3>
        </div>
        <div className="shadow-md 2xl:p-2 md:p-1 bg-gray-100 rounded-lg flex items-center justify-center flex-col w-full mx-auto">
          <p className="font-black text-4xl mb-2 md:text-2xl">{numTask}</p>
          <h3 className="font-black text-xl md:text-base mb-1">
            Number Of Tasks
          </h3>
        </div>
        {campaign.campaign_objective && (
          <div className="shadow-md 2xl:p-2 md:p-1 bg-gray-100 rounded-lg flex items-center justify-center flex-col w-full mx-auto">
            <p className="font-black text-4xl mb-2 md:text-2xl">
              {campaign.campaign_objective === "phone_number_emails"
                ? "Collect phone numbers/emails"
                : campaign.campaign_objective === "survey_data"
                ? "Collect survey data"
                : campaign.campaign_objective === "in_store_trial"
                ? "Driving in-store trial"
                : campaign.campaign_objective === "in_store_purchases"
                ? "Driving in-store purchases"
                : campaign.campaign_objective === "online_purchases"
                ? "Driving online purchases"
                : ""}
            </p>
            <h3 className="font-black text-xl md:text-base mb-1">
              Campaign Objective
            </h3>
          </div>
        )}
        {campaign.traffic_destination && (
          <div className="shadow-md 2xl:p-2 md:p-1 bg-gray-100 rounded-lg flex items-center justify-center flex-col w-full mx-auto">
            <p className="font-black text-4xl mb-2 md:text-2xl">
              {campaign.traffic_destination === "amazon"
                ? "Amazon Product Detail Page"
                : campaign.traffic_destination === "brand_website"
                ? "Brand Website"
                : campaign.traffic_destination === "store_locator"
                ? "Store Locator"
                : campaign.traffic_destination === "retail_website"
                ? "Retail Website"
                : campaign.traffic_destination === "sms_rebate"
                ? "SMS Rebate Landing Page"
                : campaign.traffic_destination === "other"
                ? "Other"
                : ""}
            </p>
            <h3 className="font-black text-xl md:text-base mb-1">
              Traffic Destination
            </h3>
          </div>
        )}
        {campaign.invites &&
          currentUser &&
          currentUser.uid === "aOLwKHwgMAbQfJv3QIPXk616Nwp1" && (
            <>
              <div className="shadow-md 2xl:p-2 md:p-1 bg-gray-100 rounded-lg flex items-center justify-center flex-col w-full mx-auto">
                <p className="font-black text-4xl mb-2 md:text-2xl">
                  {campaign.invites.sent + campaign.invites.failed}
                </p>
                <h3 className="font-black text-xl md:text-base mb-1">
                  Total Invites
                </h3>
              </div>
              <div className="shadow-md 2xl:p-2 md:p-1 bg-gray-100 rounded-lg flex items-center justify-center flex-col w-full mx-auto">
                <p className="font-black text-4xl mb-2 md:text-2xl">
                  {campaign.invites.sent}
                </p>
                <h3 className="font-black text-xl md:text-base mb-1">
                  Successful Invites
                </h3>
              </div>
              <div className="shadow-md 2xl:p-2 md:p-1 bg-gray-100 rounded-lg flex items-center justify-center flex-col w-full mx-auto">
                <p className="font-black text-4xl mb-2 md:text-2xl">
                  {campaign.invites.failed}
                </p>
                <h3 className="font-black text-xl md:text-base mb-1">
                  Failed Invites
                </h3>
              </div>
            </>
          )}
      </div>

      {editModal ? (
        <EditCampaign
          open={editModal}
          onClose={() => setEditModal(false)}
          id={id}
          campaign={campaignState.campaign.value}
        />
      ) : null}

      {isModalOpen ? (
        <CustomGlobalDialog
          className="2xl:w-1/2 h-fit xl:w-1/2 w"
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          id={id}
        >
          <Toaster />
          <div className="bg-white rounded-md px-10 break-words 2xl:text-2xl text-xs h-1/2  md:h-fit flex flex-col items-center justify-center">
            <div className="bg-white rounded-md break-words 2xl:text-2xl text-xs h-fit w-full my-10">
              <div className="p-6 pt-0 text-center">
                <h1 className="2xl:text-3xl text-base mb-2 font-bold">
                  Complete Campaign
                </h1>
                <BsExclamationCircle className="mx-auto mb-4 w-32 h-14 text-gray-400 dark:text-gray-200" />
                <h3 className="mb-5 w-full 2xl:text-2xl text-base font-normal text-gray-700 dark:text-gray-400">
                  Are you sure you want to complete your campaign? This action
                  cannot be undone, and your analytics will be finalized.
                </h3>

                <input
                  type="text"
                  value={inputValue}
                  className="outline-none my-3 text-base placeholder-gray-900 bg-gray-100 2xl:p-3 md:p-1 rounded-lg w-full"
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="Type 'complete campaign' to confirm"
                />

                <div className="flex flex-row items-center justify-center gap-10">
                  <CustomButton
                    type="button"
                    className={`text-white hover:bg-red-800 font-medium text-sm inline-flex items-center px-5 py-2.5 text-center mr-2 ${
                      inputValue.toLowerCase() !== "complete campaign"
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                    onClick={completeCampaign}
                    disabled={inputValue.toLowerCase() !== "complete campaign"}
                  >
                    Complete
                  </CustomButton>
                  <button
                    type="button"
                    className="text-gray-500 bg-white hover:bg-gray-100 rounded-md border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600"
                    onClick={() => {
                      setIsModalOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </CustomGlobalDialog>
      ) : null}
    </div>
  );
}

export default CampaignOverview;
