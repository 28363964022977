import React, { useState } from "react";
import CustomButton from "../../utils/customButton";
import { Toaster, toast } from 'react-hot-toast';
import { IoIosCheckmarkCircle, IoIosCloseCircle, IoIosInformationCircleOutline } from "react-icons/io";


function ReceiptCapture(props) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsValid(false); // Reset validation on new file selection
    setIsInvalid(false); // Reset invalid state on new file selection
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      alert("Please select a file first.");
      return;
    }
  
    setSubmitted(true);
  
    const formData = new FormData();
    formData.append('license_key', '4a92dac3ba2749608812b1ba22bc5c45');
    formData.append('image', selectedFile, selectedFile.name);
    formData.append('frame_idx', '1');
  
    try {
      const response = await fetch('https://scan.blinkreceipt.com/api_scan/v14', {
        method: 'POST',
        headers: {
          'accept': 'application/json',
        },
        body: formData,
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log("API response data: ", data);
        if (data.is_receipt === true && data.merchant_name && data.products) {
          setIsValid(true);
          setIsInvalid(false);
          props.setReceiptData(data);
          setTimeout(() => {
            props.setCurrentTab(1);
          }, 1000)
        } else {
          setIsInvalid(true);
          //toast.error("The uploaded image is not recognized as a receipt. Please try again.");
        }
      } else {
        setIsInvalid(true);
        //toast.error("Image was too blurry, try uploading a new file");
      }
    } catch (error) {
      console.error('Error:', error);
      setIsInvalid(true);
      //toast.error("Image was too blurry, try uploading a new file");
    } finally {
      setSubmitted(false);
    }
  };

  return (
    <div className="flex flex-col items-center p-10 bg-white rounded-md w-full max-w-lg mx-auto">
      <Toaster />
      <h1 className="text-3xl md:text-2xl font-extrabold uppercase text-center mb-5">UPLOAD RECEIPT</h1>
      <p className="text-center text-gray-600 mb-5">Please upload a photo(s) of your receipt as proof of purchase.</p>
      
      <div className="relative bg-gray-100 p-4 rounded-lg mb-6 shadow-md">
        <IoIosInformationCircleOutline className="absolute top-4 left-2" size={15} />
        <p className="ml-4 text-gray-800 leading-relaxed md:text-0.75 xs:text-0.7">
          Place your receipt on a <strong>solid, dark, non-reflective background</strong> in a well-lit space. Ensure that the <strong>text on the receipt is clear and easy to read</strong> and that the picture is not blurry.
        </p>
      </div>

    <div className="border-2 border-dashed md:text-base xs:text-0.7 border-gray-300 rounded-md p-6 mb-5 w-full flex flex-col items-center">
    <label htmlFor="imageInput" className="cursor-pointer bg-Blue text-white py-2 px-4 overflow-hidden mx-3 rounded-md hover:bg-indigo-600 flex items-center justify-center text-center space-x-2 md:w-22rem xs:w-10rem">
        {!selectedFile && <span>Choose File(s)</span>}
        <span>{selectedFile ? selectedFile.name : ""}</span>
      </label>
      <input
        id="imageInput"
        type="file"
        accept="image/jpeg, image/jpg, image/png"
        className="hidden"
        onChange={handleFileChange}
      />
      {selectedFile && (
        <div className="mt-4 relative">
          <img
            src={URL.createObjectURL(selectedFile)}
            alt="Receipt Preview"
            className="w-24 h-24 object-cover rounded-md"
          />
          <button
            onClick={() => setSelectedFile(null)}
            className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center"
          >
           <IoIosCloseCircle className="mr-2 text-2xl w-3rem h-3rem rounded" color="red" />
          </button>
        </div>
      )}
    </div>
    {isValid && (
      <div className="flex items-start text-center md:text-base xs:text-0.7 font-bold mt-4 mb-5">
        <IoIosCheckmarkCircle className="mr-2 text-2xl w-1rem h-1rem rounded" color="green" />
        <span className="flex-1">Receipt validated. Proceed to the next screen to confirm payout details.</span>
      </div>
    )}

    {isInvalid && (
      <div className="flex items-start text-center md:text-base xs:text-0.7 font-bold mt-4 mb-5">
        <IoIosCloseCircle className="mr-2 text-2xl w-1rem h-1rem rounded" color="red" />
        <span className="flex-1">This receipt is invalid, or the products on your receipt are not listed for this promo. Please try uploading again.</span>
      </div>
    )}

      <CustomButton 
        onClick={handleSubmit}
        className="hover:bg-indigo-600 text-base text-white font-bold py-2 px-8 rounded-md disabled:opacity-50"
        disabled={submitted}
      >
        {isValid ? "Confirm Payout Details" : isInvalid ? "Reupload Receipt" : "Validate Receipt"}
      </CustomButton>
    </div>
  );
}

export default ReceiptCapture;