import React, { useState } from 'react';
import GlobalDialog from '../../utils/globalDialog';
import { RequestService } from '../../Service/RequestServices';
import { useAuth } from '../../Context/AuthContext';
import { TaskService } from '../../Service/TaskServices';

function ApproveRequestModal({ open, onClose, id, reqData }) {
  const { currentUser } = useAuth();
  const [tab, setTab] = useState(0);
  const [markAsShippedData, setMarkAsShippedData] = useState({
    trackingNumber: ''
  });
  const [promoCodeData, setPromoCodeData] = useState({
    promoCode: '',
    promoCodeLink: ''
  });
  const [error, setError] = useState({
    trackingNumber: ''
  });

  const tabHandle = () => {
    let tb = tab;
    tb === 1 ? setTab(0) : setTab(1);
  };
  const handleMark = () => {
    if (markAsShippedData.trackingNumber === '' && promoCodeData.promoCode === '') {
      return setError({
        error,
        trackingNumber: 'Tracking number or Promo code field is required'
      });
    } else {
      if (currentUser.uid) {
        if (reqData.type === 'event') {
          let temp = {};
          temp['event_id'] = reqData.event_id;
          temp['creator_id'] = reqData.creator_id;
          temp['brand_id'] = reqData.brand_id;
          temp['product_id'] = reqData.product_id;
          temp['seeding_type'] = tab ? 'code' : 'ship';
          tab
            ? (temp['sample_product_code'] = promoCodeData.promoCode) &&
              (temp['product_link'] = promoCodeData.promoCodeLink)
            : (temp['tracking_number'] = markAsShippedData.trackingNumber);
          RequestService.ApproveRequest(id, temp);
          onClose();
        } else {
          let temp = {};
          temp['task_id'] = reqData.task_id;
          temp['creator_id'] = reqData.creator_id;
          temp['brand_id'] = reqData.brand_id;
          temp['product_id'] = reqData.product_id;
          temp['seeding_type'] = tab ? 'code' : 'ship';
          tab
            ? (temp['sample_product_code'] = promoCodeData.promoCode) &&
              (temp['product_link'] = promoCodeData.promoCodeLink)
            : (temp['tracking_number'] = markAsShippedData.trackingNumber);
          TaskService.ApproveProductRequestForTask(id, temp);
          onClose();
        }
      }
    }
  };
  return (
    <GlobalDialog open={open} onClose={onClose}>
      <div className=" bg-white rounded-lg dark:bg-gray-700">
        <div className="p-6 pt-0 text-center">
          <h3 className="mb-5font-normal text-darkBlack font-semibold text-4xl">Product Seeding</h3>
          <p className="mt-2">
            Please choose whether you'd like to fulfill the request directly or provide a promo code
            for the creator to use.
          </p>
          <div className="flex mb-5">
            <div className="w-auto border-b-4 border-red font-bold cursor-pointer mr-3 text-red my-2">
              {tab ? 'Promo Code' : 'Ship product'}
            </div>
            <div className="w-auto my-2 font-bold cursor-pointer mr-3" onClick={() => tabHandle()}>
              {!tab ? 'Promo Code' : 'Ship product'}
            </div>
          </div>
          <>
            {tab === 0 ? (
              <div>
                {reqData.creator_address ? (
                  <div>
                    <p className="mt-6">
                      <label className="block font-extrabold">
                        {reqData.creator_address.fullname}
                      </label>
                      <label className="block font-extrabold">
                        {reqData.creator_address.address1}
                      </label>
                      <label className="block font-extrabold">
                        {reqData.creator_address.address2}
                      </label>
                      <label className="block font-extrabold">
                        {reqData.creator_address.city}, {reqData.creator_address.state},{' '}
                        {reqData.creator_address.country} {reqData.creator_address.zipcode}
                      </label>

                      <br></br>
                    </p>
                  </div>
                ) : (
                  <div className="text-red mt-2">
                    Creator has not provided an address, this option is unavailable.
                  </div>
                )}

                <div className="mt-2">
                  After the order has been filled, please provide a tracking number here.
                </div>
                <label
                  htmlFor="productName"
                  className="text-black text-left  block md:text-lg font-semibold mb-2 ">
                  Tracking number
                </label>
                <input
                  name="trackingNumber"
                  className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                  placeholder="Enter Tracking Number"
                  value={markAsShippedData.trackingNumber}
                  onChange={(e) =>
                    setMarkAsShippedData({
                      ...markAsShippedData,
                      trackingNumber: e.target.value
                    })
                  }
                />
              </div>
            ) : (
              <div>
                <label
                  htmlFor="productName"
                  className="text-black text-left  block md:text-lg font-semibold mb-2 ">
                  Promo Code
                </label>
                <input
                  name="promocode"
                  className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                  placeholder="Enter Promo Code"
                  value={promoCodeData.promoCode}
                  onChange={(e) =>
                    setPromoCodeData({
                      ...promoCodeData,
                      promoCode: e.target.value
                    })
                  }
                />
                <label
                  htmlFor="productName"
                  className="text-black mt-3 text-left block md:text-lg mb-2 font-bold">
                  Link to product page for this promo code (ex:
                  https://shop.itsskinny.com/angel-hair/)
                </label>
                <input
                  name="ProductLink"
                  className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                  placeholder="Enter Product link"
                  value={promoCodeData.promoCodeLink}
                  onChange={(e) =>
                    setPromoCodeData({
                      ...promoCodeData,
                      promoCodeLink: e.target.value
                    })
                  }
                />
              </div>
            )}
          </>

          <br />
          {error && error.trackingNumber && (
            <span className="text-sm text-Red">{error.trackingNumber}</span>
          )}

          <div className="bg-red w-full px-4 text-lg font-bold py-3 rounded-lg text-white mt-4 w-1/4 items-center justify-center text-center cursor-pointer">
            <button
              type="submit"
              className="bg-red w-full px-4 text-lg font-bold"
              onClick={handleMark}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </GlobalDialog>
  );
}

export default ApproveRequestModal;
