import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';

//State
const state = createState({
  featureRecipes: [],
  featureRecipe: [],
  retrieving: false,
  fetched: false,
  loading: false,
  updateNeed: true
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'FEATURERECIPES_LOADED':
        return s.merge({
          featureRecipes: action.data,
          loading: false,
          updateNeed: false
        });
      case 'FEATURERECIPE_LOAD_SINGLE':
        return s.merge({
          featureRecipe: action.data,
          loading: false,
          updateNeed: false,
          fetched: true
        });
      case 'CREATE_FEATURERECIPE':
        return s.merge({
          // featureRecipes: action.data,
          loading: false,
          updateNeed: true
        });
      case 'FEATURERECIPE_UPDATE':
        return s.merge({
          updateNeed: true,
          loading: false
        });
      case 'FEATURERECIPE_DELETE':
        return s.merge({
          updateNeed: true
        });

      case 'FEATURERECIPE_LOADED':
        return s.merge({
          featureRecipe: action.data,
          retrieving: true,
          fetched: true,
          updateNeed: true
        });
      default:
        return null;
    }
  }, action.type);
});

export const accessFeatureRecipeState = () => state;

export const useFeatureRecipeState = () => useState(state);

//Service
export const featureRecipeService = {
  FetchFeatureRecipe: () => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/featureRecipes`)
      .then((res) => {
        dispatch(FeatureRecipeAction.loadFeatureRecipe(res.data.result));
      })
      .catch((err) => { });
  },
  AddFeatureRecipe: async (data) => {
    const dispatch = useDispatch();

    await axios
      .post(`${baseUrl}api/recipes/setFeaturedRecipe`, data)
      .then((res) => {
        dispatch(FeatureRecipeAction.createFeatureRecipe(res));
      })
      .catch((err) => { });
  },
  FetchSingleFeatureRecipe: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/recipes/getFeaturedRecipe/${id}`)
      .then((res) => {
        dispatch(FeatureRecipeAction.singleFeatureRecipe(res.data.result));
      })
      .catch((err) => { });
  },
  DeleteFeatureRecipe: (id) => {
    const dispatch = useDispatch();
    axios
      .delete(`${baseUrl}api/recipes/deleteFeaturedRecipe/${id}`)
      .then((res) => {
        dispatch(FeatureRecipeAction.deleteFeatureRecipe(res));
      })
      .catch((err) => { });
  },
  UpdateFeatureRecipe: (id, data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/recipes/setFeauturedRecipe/${id}`, data)
      .then((res) => {
        dispatch(FeatureRecipeAction.updateFeatureRecipe(res));
      })
      .catch((err) => { });
  }
};

//Action
export const FeatureRecipeAction = {
  loadFeatureRecipe: (data) => {
    return {
      type: 'FEATURERECIPE_LOADED',
      data: data
    };
  },
  createFeatureRecipe: (data) => {
    return {
      type: 'CREATE_FEATURERECIPE',
      data: data
    };
  },
  singleFeatureRecipe: (data) => {
    return {
      type: 'FEATURERECIPE_LOADED',
      data: data
    };
  },
  deleteFeatureRecipe: (data) => {
    return {
      type: 'FEATURERECIPE_DELETE',
      data: data
    };
  },
  updateFeatureRecipe: (data) => {
    return {
      type: 'FEATURERECIPE_UPDATE',
      data: data
    };
  }
};
