/* eslint-disable no-useless-escape */
import React, { useState, useRef } from 'react';
import { useAuth } from '../../Context/AuthContext';
import { Link } from 'react-router-dom';
import '../../App.css';
import axios from 'axios';
import 'react-phone-input-2/lib/style.css';
import AccountCreation from './AccountCreation';
import success from '../../Assets/GirlWithEggs.png';
import { BrandService } from '../../Service/BrandServices';
import toast, { Toaster } from 'react-hot-toast';
import { Mixpanel } from '../../mixpanel';

import Loader from '../Loader';
import CustomButton from '../../utils/customButton';
const re =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const usernameRe = /^[a-z0-9_\.]+$/;
function SignUp() {
  const userEmail = useRef('');
  const userPassword = useRef('');
  const userPasswordConfirm = useRef('');
  const brandName = useRef('');
  const firstName = useRef('');
  const lastName = useRef('');

  const { signup } = useAuth();
  const [error, setError] = useState({
    email: '',
    password: '',
    confirmPass: '',
    firstname: '',
    lastname: '',
    brandname: '',
    phoneNumber: '',
    common: '',
    terms: ''
  });
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [isTermsChecked,  setIsTermsChecked] = useState(false);
  var new_user = {};

  const onChange = (e) => {
    setError({ ...error, [e.target.name]: '' });
  };

  

  async function handleSubmit() {

    let err = error;
    // return;
    if (userPassword.current.value !== userPasswordConfirm.current.value) {
      err.password = 'Passwords do not match.';
      return setError({ ...error, err });
    } else if (userPassword.current.value.length < 7) {
      err.email = 'Password must be at least 6 characters long.';
      return setError({ ...error, err });
    } else if (!re.test(userEmail.current.value)) {
      err.email = 'Email is not valid.';
      return setError({ ...error, err });
    } 
    if (!userPassword.current.value) {
      return (err.password = 'Password is required');
    }
    if (brandName.current.value === '') {
      return (err.brandname = 'Brand name is required');
    }
    if (firstName.current.value === '') {
      return (err.firstname = 'First name is required');
    }
    if (lastName.current.value === '') {
      return (err.lastname = 'Last name is required');
    }
    if (!userPasswordConfirm.current.value) {
      err.confirmPass = 'Confirm Password is required';
    } else if (userPassword.current.value.length < 6) {
      err.confirmPass = 'Password should be at least 6 characters';
    } else if (userPassword.current.value !== userPasswordConfirm.current.value) {
      err.confirmPass = 'Confirm Password and Password is not match';
    }

    if (!isTermsChecked) {
      err.terms = 'You must agree to the terms of service';
      return setError({ ...error, err });
    }

    

    try {
      setError({
        email: '',
        password: '',
        confirmPass: '',
        brandname: '',
        firstname: '',
        lastname: '',
        phoneNumber: '',
        common: '',
        terms: ''
      });
      setLoading(true);

      const currEmail = userEmail.current.value;
      const currUser = brandName.current.value.toLowerCase();
      await signup(userEmail.current.value, userPassword.current.value.trim())
        .then((userCreds) => {
          new_user = {
            uid: userCreds.user.uid,
            avatar: 'https://crafted-react-images.s3.amazonaws.com/default_pfp_1384719047375875089754832059.png',
            brand_name: currUser,
            first_name: firstName.current.value,
            last_name: lastName.current.value,
            email: currEmail
          };
          BrandService.AddBrand(new_user);
          Mixpanel.signup(new_user);
          setCurrentTab(1);
        })
        .catch((error) => {
          toast.error('email is already taken!!');
          setLoading(false);
        });
    } catch (__error) {
      err.common = __error;
      setError({ ...error, err });
    }
  }

  return (
    <div className=''>
      {currentTab === 0 ? (
        <div>
          <Toaster />
          <div className="">
            <label className="text-darkBlack 2xl:text-base md:text-0.7 font-bold capitalize">Brand Name</label>
            <input
              className="focus:outline-none w-full bg-lightGray 2xl:p-2 2xl:mt-2 md:p-1 md:mt-1 rounded"
              name="userName"
              onChange=''
              type="text"
              ref={brandName}
              required
            />
            {error && error.brandname && <span className="text-sm text-Red">{error.brandname}</span>}
          </div>
          <div className='flex gap-3'>
              <div className="mb-3">
              <label className="text-darkBlack 2xl:text-base md:text-0.7 font-bold capitalize">First Name</label>
              <input
                className="focus:outline-none w-full bg-lightGray 2xl:p-2 2xl:mt-2 md:p-1 md:mt-1 rounded"
                name="userName"
                onChange=''
                type="text"
                ref={firstName}
                required
              />
              {error && error.firstname && <span className="text-sm text-Red">{error.firstname}</span>}
            </div>
            <div className="mb-3">
              <label className="text-darkBlack 2xl:text-base md:text-0.7 font-bold capitalize">Last Name</label>
              <input
                className="focus:outline-none w-full bg-lightGray 2xl:p-2 2xl:mt-2 md:p-1 md:mt-1 rounded"
                name="userName"
                onChange=''
                type="text"
                ref={lastName}
                required
              />
              {error && error.lastname && <span className="text-sm text-Red">{error.lastname}</span>}
            </div>
          </div>
          <div className="mb-3">
            <label className="text-darkBlack 2xl:text-base md:text-0.7 font-bold capitalize">Email</label>
            <input
              className="focus:outline-none w-full bg-lightGray 2xl:p-2 2xl:mt-2 md:p-1 md:mt-1 rounded"
              name="email"
              onChange={onChange}
              type="email"
              ref={userEmail}
              required
            />
            {error && error.email && <span className="text-sm text-Red">{error.email}</span>}
          </div>
          <div className="mb-3">
            <label className="text-darkBlack 2xl:text-base md:text-0.7 font-bold capitalize">Password</label>
            <input
              className="focus:outline-none w-full bg-lightGray 2xl:p-2 2xl:mt-2 md:p-1 md:mt-1 rounded"
              name="password"
              onChange={onChange}
              type="password"
              ref={userPassword}
              required
            />
            {error && error.password && <span className="text-sm text-Red">{error.password}</span>}
          </div>
          <div className="mb-10">
            <label className="text-darkBlack 2xl:text-base md:text-0.7 font-bold capitalize">Confirm Password</label>
            <input
              className="focus:outline-none w-full bg-lightGray 2xl:p-2 2xl:mt-2 md:p-1 md:mt-1 rounded"
              name="confirmPass"
              onChange={onChange}
              type="password"
              ref={userPasswordConfirm}
              required
            />
            {error && error.confirmPass && (
              <span className="text-sm text-Red">{error.confirmPass}</span>
            )}
          </div>
          <div className=" ml-1">
            <input type="checkbox" id="isTermsChecked" name="isTermsChecked" value={isTermsChecked} onChange={(e) => setIsTermsChecked(e.target.checked)} />
            <span className="mx-2 hover:text-black relative">
             I acknowledge that I have read and accepted the <a className='text-red underline' target = "_blank" href = 'https://crafted-react-images.s3.amazonaws.com/Client Terms of Services.docx.pdf'>Terms of Service</a>
            </span> 
          <p className='mt-1'>{error && error.terms && <span className="text-sm text-Red">{error.terms}</span>}</p>
          </div>
     
          <p>{error && error.common && error.common}</p>
          <CustomButton
            className="text-xl font-bold my-3 w-full mx-auto text-white py-2 px-14 block"
            type="button"
            disabled={loading}
            onClick={()=>handleSubmit()}>
            {loading && <Loader />}
            Join as Brand
          </CustomButton>
        </div>
      ) : currentTab === 1 ? (
        <div>
          <AccountCreation
            handleComplete={() => {
              setCurrentTab(2);
            }}
          />
        </div>
      ) : (
        currentTab === 2 && (
          <div>
            <img src={success} alt="logo" className="mx-auto h-48" />
            <div>
              <h2 className="text-4xl text-center font-bold mb-4">Congrats! You're in.</h2>
              {/*<p className="text-lg text-center font-bold text-black mb-2">
                Here are our community guidelines, to make sure
                <br /> this is a fun and safe environment to celebrate food.
              </p>
              <p className="text-lg text-Red font-bold text-center mb-2">Be respectful.</p>
              <p className="text-lg text-Red font-bold text-center mb-2">Be encouraging.</p>
              <p className="text-lg text-Red font-bold text-center mb-2">Be curious.</p>*/}
              <Link
                className="text-xl text-center font-bold my-3 w-full mx-auto bg-Blue rounded-md text-white py-2 px-14 block"
                to="/brand/dashboard">
                Let's Go!
              </Link>
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default SignUp;
