import React, { useContext, useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import Modal from '../../../utils/ugcModal';
import { useAuth } from '../../../Context/AuthContext';
import { AuthService, userAuthstate } from '../../../Service/authenticationService';
import { useTabState } from '../../../utils/TabState';
import UgcTab from '../../../common/steps/campaign/ugcTab';
import { SubscriptionService, useSubscriptionState } from '../../../Service/SubscriptionServices';
import BasicCampaignInfo from './BasicCampaignInfo';
import CampaignConfirmation from './CampaignConfirmation';
import CampaignTasks from './CampaignTasks'
import CampaignOutReach from './CampaignOutReach';
import CampaignManualOutReach from './CampaignManualOutReach';
import CampaignLaunchSucess from './CampaignLaunchSucess';
import { BrandContext } from '../../../routes/PrivateRoute/BrandDashboardRoute';
import CreatorTable from '../CreatorTable';


export default function FirstProduct(props) {
  const { currentUser } = useAuth();
  const subsState = useSubscriptionState();
  const tabState = useTabState();
  const { brandId } = useContext(BrandContext);

  const [message, setMessage] = useState('');
 
  const authState = userAuthstate();
  const handleOutreachMessageChange = (newMessage) => {
    setMessage(newMessage);
  };

  useEffect(() => {
    const user = brandId ? brandId: null;
    if (user) {
      AuthService.FetchAuthUser(brandId);
      // SubscriptionService.FetchSubscriptionForUser(currentUser.uid);
    }
  }, [authState.updateNeed.value]);

  const user = authState.authUser.value;
  const subs = subsState.subscription.value;
  let availUsage = 0;
  {/*if (subs) {
    availUsage = subs.paidUsage - subs.currentUsage;
  }
  useEffect(() => {
    if (user && subs) {
      availUsage = subs.paidUsage - subs.currentUsage;
    }
  }, [subs, subsState.updateNeed.value]);*/}


  useEffect(() => {
    tabState.merge({ currentTab: 0 });
  }, []);

  return (
    <Modal
      open={props.isOpen}
      onClose={() => {
        props.closeAction();
      }}
      center
      closeOnOverlayClick={false}>
      <>
        <>
          <div>
            <h1 className="text-xl font-bold mb-3 mx-5 my-5 font-black xs:xl md:text-4xl">
              Launch Influencer Campaign
            </h1>
            <hr className="my-5" />
            <UgcTab currentCampModalTab={tabState.currentCampModalTab.value} />

            {tabState.currentCampModalTab.value === 0 && <BasicCampaignInfo />}
            {tabState.currentCampModalTab.value === 1 && <CampaignTasks />}
            {tabState.currentCampModalTab.value === 2 && (
              <CampaignConfirmation
                onClose={() => {
                  props.closeAction();
                }}
              />
            )}
            {tabState.currentCampModalTab.value === 3 && (
              <CampaignOutReach
              message={message}
              onMessageChange={handleOutreachMessageChange}
                onClose={() => {
                  props.closeAction();
                }}
              />
            )}
            {tabState.currentCampModalTab.value === 4 && (
              <CampaignLaunchSucess
              message={message}
                onClose={() => {
                  props.closeAction();
                }}
              />
            )}
            {tabState.currentCampModalTab.value === 5 && (
              <CreatorTable
              message={message} 
                onClose={() => {
                  props.closeAction();
                }}
              />
            )}
          </div>
        </>
      </>
    </Modal>
  );
}
