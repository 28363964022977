import React, { useState, useEffect, useContext } from 'react';
import { useProductState, ProductService } from '../../../Service/ProductServices';
import { BrandContext } from '../../../routes/PrivateRoute/BrandDashboardRoute';
import { useGenerativeBriefState, GenerativeBriefService } from '../../../Service/GenerativeBriefService';
import { toast, Toaster } from 'react-hot-toast';
import Loader from '../../Loader2';
import CustomButton from '../../../utils/customButton';


const GenerateBriefFormOnboarding = ({ step, setBriefCreated, setStep, setBriefData,  setBriefId, setActiveTab, productName }) => {
  const [productDescription, setProductDescription] = useState('');
  const [uniqueSellingPoints, setUniqueSellingPoints] = useState('');
  const [targetAudience, setTargetAudience] = useState('');
  const [videoConcept, setVideoConcept] = useState('');
  const productState = useProductState();
  const {brandId} = useContext(BrandContext);
  const genBriefState = useGenerativeBriefState();
  const [isLoading, setIsLoading] = useState(true);

  console.log("product name: ", productName);
  // loading the form
    // fetching products
    useEffect(() => {
      // tabState.merge({ currentTab: 1 });
      if (productState.updateNeed.value) {
          ProductService.FetchProductForUser(brandId);
      }
    }, [productState.updateNeed.value]);

    const res = JSON.stringify(productState.products.value);

    const productTemp = [];
    if (productState.products.value){
        Object.keys(JSON.parse(res)).forEach(el => {
        productTemp.push({
            id: el,
            ...JSON.parse(res)[el]
        })
    })
    }

    // Handle form submission here
    const handleSubmit = async (e) => {
      e.preventDefault();
      setStep(1); 
      // You can access the form values using the state variables
      console.log({
        productDescription,
        uniqueSellingPoints,
        targetAudience,
        videoConcept
      });

      const completeDescription = `Product name: ${productName}, Product Description: ${productDescription}`;
    
      const requestBody = {
        featured_product: completeDescription,
        usp: uniqueSellingPoints,
        target_audience: targetAudience,
        video_concept: videoConcept,
        brand_id: brandId,
      };
    
      try {
        // Retrieve the brief ID and brief data from the response
        const { briefId, briefData } = await GenerativeBriefService.createBriefTemplate(requestBody);
        setBriefData(briefData);
        setBriefCreated(true);
        setStep(2);
        console.log("Brief ID:", briefId);
        console.log("Brief data:", briefData);
        toast.success("Brief created successfully");
      } catch (error) {
        console.error("An error occurred while creating the brief", error);
        setStep(0);
        toast.error("An error occurred while creating the brief");
      }
    };
   

  return (
      <div className='overflow-hidden 2xl:text-2xl mt-4'>
         <h1 className="2xl:text-2xl md:text-base font-semibold text-center text-gray-800 mb-4">Crafted AI Creative Brief Builder</h1>
        <hr />
        <p className='text-gray-500 2xl:text-base md:text-0.7 text-center my-4'>Tell us a little more about the product, target audience and campaign - we'll take it from here.</p>
        <form onSubmit={handleSubmit} className="p-3 mx-auto space-y-6">
          <label className="block">
            <span className="text-black mb-2 font-bold 2xl:text-2xl md:text-base">Describe your product.</span>
            <div className="my-2">
            <input
              type="text"
              required placeholder ="ex: Jif Peanut Butter Spread is a creamy peanut butter with no added sugars or oils"
              value={productDescription}
              onChange={(e) => setProductDescription(e.target.value)}
              className="mr-2 outline-none text-base bg-gray-100 2xl:p-3 md:p-2  rounded-lg w-full"
            />
            </div>
          </label>
          <label className="block">
            <span className="text-black mb-2 font-bold 2xl:text-2xl md:text-base">Describe unique selling points of the product.</span>
            <input
              type="text"
              required placeholder ="ex: Creamy Texture, Spreadable, No added sugars or oils, Plant-based...."
              value={uniqueSellingPoints}
              onChange={(e) => setUniqueSellingPoints(e.target.value)}
              className="mr-2 outline-none text-base bg-gray-100 2xl:p-3 md:p-2  rounded-lg w-full"
            />
          </label>
          <label className="block">
            <span className="text-black mb-2 font-bold 2xl:text-2xl md:text-base">Describe your target audience.</span>
            <input
              type="text"
              required placeholder ="ex: Busy on-the-go moms looking for a fun snack for their kids"
              value={targetAudience}
              onChange={(e) => setTargetAudience(e.target.value)}
              className="mr-2 outline-none text-base bg-gray-100 2xl:p-3 md:p-2  rounded-lg w-full"
            />
          </label>
          <label className="block">
            <span className="text-black mb-2 font-bold 2xl:text-2xl md:text-base">Describe the purpose of the content.</span>
            <input
              type="text"
              value={videoConcept}
              required placeholder ="ex: This video will be used to promote our upcoming launch into Walmart"
              onChange={(e) => setVideoConcept(e.target.value)}
              className="mr-2 outline-none text-base bg-gray-100 2xl:p-3 md:p-2  rounded-lg w-full"
            />
          </label>
          <CustomButton type="submit" className="transform transition-transform duration-500 font-bold ease-in-out hover:scale-105 flex mx-auto items-center justify-center text-white py-2 px-4">
            Start generating brief
          </CustomButton>
          <button onClick = {() => {setActiveTab(3); setBriefCreated(false);}} className="text-gray-500 flex mx-auto items-center justify-center hover:underline  rounded-md">
            I already have my own brief 
          </button>
        </form>
      </div>
  );
};

export default GenerateBriefFormOnboarding;