import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import SignIn from '../SignIn';
import BrandOnboardingSignUp from './BrandOnboardingSignUp';
import logo from '../../Assets/logo-red.png';
import brand_icon from '../../Assets/Sales Photo.png'
import PasswordReset from '../PasswordReset';
import { useAuth } from '../../Context/AuthContext';
import { Link } from 'react-router-dom';
function BrandOnboardingSetup() {
  const [currentTab, setCurrentTab] = useState(0);
  const { currentUser } = useAuth();
  useEffect(() => {
    if (currentUser) {
    }
  }, [currentUser]);

  return (
    <div className="min-h-screen flex w-screen items-center justify-center">
  
  <div className="flex flex-col items-center justify-center w-1/3 bg-Blue min-h-screen">
    <div className="bg-Blue rounded-md px-10"> 
      <img className="mt-2 2xl:h-30rem md:h-17rem h-15rem rounded-xl mx-auto" src={brand_icon}/>
    </div>
    <div className="text-white text-center font-black 2xl:text-5xl md:text-3xl">Get high converting video ads!</div>
    <ol className="list-decimal list-inside mx-auto 2xl:mt-4 md:mt-8 text-white 2xl:text-base  md:text-0.7">
      <li>Generate a creative brief using our AI Brief Builder.</li>
      <li>Blast the brief to relevant creators in the Crafted Network.</li>
      <li>Approve the creators you like.</li>
      <li>Get high converting video ads!</li>
    </ol>
  </div>

  <div className="flex flex-col items-center justify-center w-2/3 bg-gray-100  min-h-screen">
    <div className="bg-white p-4 rounded-2xl shadow-lg md:w-4/6 xs:w-full place-self-center xs:mt-10">
   
      <img src={logo} alt="logo" className="mx-auto" />
    
      <ul id="tabs" className="flex mb-3">
        <li className='w-full px-7 font-semibold py-2 border-solid border-b-4 border-Red text-center text-Red'>
          <p className="cursor-pointer 2xl:text-base md:text-0.7">Sign Up</p>
        </li>
      </ul>
      <BrandOnboardingSignUp/>
    </div>
  </div>
</div>
  );
}

export default BrandOnboardingSetup;
