import React from 'react';
import './GenerativeBriefs.css';
import Loader from '../../Loader2';

function GenerateBriefLoading({ step, setStep }) {
  return (
    <div>
      <div className='border-b w-full text-center 2xl:text-3xl md:text-2xl font-bold'>
          Brief Loading
        </div>
    <div className="generateBriefLoading component-loaded slide-in-right">
      <div className="flex flex-col justify-center items-center h-96">
        <Loader/>
        <p className="text-lg font-bold mt-4 text-center">Generating Brief...</p>
      </div>
    </div>
    </div>
  );
}

export default GenerateBriefLoading;