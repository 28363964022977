import React, { useRef, useState, useEffect, useContext } from 'react';
import { useAuth } from './../Context/AuthContext';
import { ImCross } from 'react-icons/im';
import S3FileUpload from 'react-s3';
import s3 from './../aws-s3';
import '../App.css';
import Loader2 from './Loader2';
import Loader from './Loader';
import { AuthService, userAuthstate } from '../Service/authenticationService';
import { useBrandState, BrandService } from '../Service/BrandServices';
import { BrandContext } from '../routes/PrivateRoute/BrandDashboardRoute';
import CustomButton from '../utils/customButton';


function AccountUpdate(props) {
  const name = useRef('');
  const bio = useRef('');
  const website = useRef('');
  const email = useRef('');
  const fileRef = useRef(null);
  // const [setImgLocation] = useState('');
  const { currentUser} = useAuth();
  const {brandId} = useContext(BrandContext);
  const [loader] = useState(false);
  const [imgUploaded, setImgUpload] = useState(false);
  const brandState = useBrandState();
  const [brandData, setBrandData] = useState({
    name: '',
    avatar: '',
    website: '',
    email: '',
    bio: ''
  })
  const [userData, setUserData] = useState({
    name: '',
    avatar: '',
    bio: ''
  });
  const [error, setError] = useState({
    avatar: '',
    name: '',
    bio: ''
  });

  const authState = userAuthstate();
  let update = {
    avatar: '',
    name: '',
    bio: ''
  };
  const onChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: '' });
  };
  const onChangeBrand = (e) => {
    setBrandData({ ...brandData, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: '' });
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImgUpload(true);
    } else {
      setImgUpload(false);
    }
  };

  useEffect(() => {
    if (currentUser.uid !== null) {
      authState.merge({ loading: true });
      AuthService.FetchAuthUser(currentUser.uid);
    }
  }, [authState.updateNeed.value]);

  useEffect(() => {
    if (brandId !== null) {
      BrandService.FetchSingleBrand(brandId && brandId);
    }
    
  }, [brandState.updateNeed.value]);

  const user = authState.authUser.value;
  //console.log("USER", user);
  const brand = brandState.brand.value;
  console.log("BRAND", brand);

  useEffect(() => {
    if (user !== null) {
      setUserData({
        name: user.name !== undefined ? user.name : '',
        bio: user.bio !== undefined ? user.bio : '',
        avatar: user.avatar !== undefined ? user.avatar : ''
      });
    }
  }, []);
  
  useEffect(() => {
    if (brand !== null) {
      console.log('Brand object:', brand); 
      console.log('Brand email:', brand.email);
      setBrandData({
        name: brand.brand_name !== undefined ? brand.brand_name : '',
        bio: brand.brand_line !== undefined ? brand.brand_line : '',
        website: brand.brand_website !== undefined ? brand.brand_website : '',
        email: brand.email !== undefined ? brand.email : '',
        avatar: brand.brand_avatar !== undefined ? brand.brand_avatar : ''
      });
    }
  }, []);
  


  async function handleSubmit() {

    if (user) {
    const userdata = userData;
    const avatarInput = user.avatar ? user.avatar : fileRef.current.files[0];

    if (!avatarInput) {
      return setError({ ...error, avatar: 'avatar is required' });
    }

    try {
      setError({ avatar: '', name: '', bio: '' });
      if (imgUploaded) {
        const file = fileRef.current.files[0];
        const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
      
        if (!allowedFileTypes.includes(file.type)) {
          console.log('Please select a JPEG, JPG, or PNG file.');
          return; // Abort the upload if the selected file type is not allowed
        }
      
        console.log('img uploaded:', imgUploaded);
      
        const uniqueFilename = Date.now() + '_' + file.name; // Generate a unique filename using the current timestamp
      
        await S3FileUpload.uploadFile(file, s3, {
          keyPrefix: '', // Remove the uniquePrefix option
          customPrefix: {
            public: 'uploads/',
          },
          // Use the unique filename as the key
          key: uniqueFilename,
        }).then((data) => {
          console.log('data:', data);
          // setImgLocation(data.location.toString());
          update = {
            bio: bio.current.value,
            name: name.current.value,
            avatar: data.location,
          };
        });
      } else {
        update = {
          bio: bio.current.value,
          name: name.current.value,
          avatar: ''
        };
      }
      userdata.name = update.name !== '' ? update.name : userdata.name;
      userdata.bio = update.bio !== '' ? update.bio : userdata.bio;
      userdata.avatar = update.avatar !== '' ? update.avatar : userdata.avatar;

      if (userdata.name === '') return setError({ ...error, name: 'name is required' });
      if (userdata.bio === '') return setError({ ...error, bio: 'bio is required' });
      AuthService.UpdateAuthUser(currentUser.uid, userdata);
      window.location.reload(false);
    } catch (error) {
      setError('Failed to Update Profile');
    }
  }

    else {
      const branddata = brandData;
    const avatarInput = brandData.avatar ? brandData.avatar : fileRef.current.files[0];

    if (!avatarInput) {
      return setError({ ...error, avatar: 'avatar is required' });
    }

    try {
      setError({ avatar: '', name: '', bio: '' });
      if (imgUploaded) {
        await S3FileUpload.uploadFile(fileRef.current.files[0], s3).then((data) => {
          // setImgLocation(data.location.toString());
          update = {
            bio: bio.current.value,
            name: name.current.value,
            avatar: data.location
          };
        });
      } else {
        update = {
          bio: bio.current.value,
          name: name.current.value,
          avatar: ''
        };
      }
      branddata.name = update.name !== '' ? update.name : branddata.name;
      branddata.bio = update.bio !== '' ? update.bio : branddata.bio;
      branddata.avatar = update.avatar !== '' ? update.avatar : branddata.avatar;

      if (branddata.name === '') return setError({ ...error, name: 'name is required' });
      if (branddata.bio === '') return setError({ ...error, bio: 'bio is required' });
      AuthService.UpdateAuthBrand(brandId, branddata);
      window.location.reload(false);
    } catch (error) {
      setError('Failed to Update Profile');
    }
    }
  }

  return (
    <>
  {authState.loading.value ? (
    <Loader2 />
  ) : (
    <div>
      {user && user.avatar != '' ? (
        <>
          <div className="mb-3">
            <label className="text-darkBlack font-bold capitalize">Profile Picture</label>
            <br />
            {userData.avatar === '' && (
              <input
                id="imageInput"
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                ref={fileRef}
                className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
                onChange={handleChange}
              />
            )}
            {userData.avatar !== '' && (
              <div className="w-32 relative mt-4">
                <img src={userData.avatar} alt="" className="object-cover " />
                <div className="absolute -top-2 -right-2 cursor-pointer" onClick={() => setUserData({ ...userData, avatar: '' })}>
                  <ImCross className="text-red" />
                </div>
              </div>
            )}
            {error && error.avatar && <span className="text-sm text-Red">{error.avatar}</span>}
          </div>
          <div className="mb-3">
            <label className="text-darkBlack font-bold capitalize">Name</label>
            <input
              className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
              type="text"
              name="name"
              value={userData.name}
              onChange={onChange}
              ref={name}
            />
            {error && error.name && <span className="text-sm text-Red">{error.name}</span>}
          </div>
          <div className="mb-3">
            <label className="text-darkBlack font-bold capitalize">Bio</label>
            <textarea
              className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
              value={userData.bio}
              name="bio"
              onChange={onChange}
              ref={bio}
              maxLength={300}
            />

            {/* CRAFTEDENG-27 Shows current character count out of 300 */}
            <div className="flex justify-end">
              <span className="text-sm text-darkGray">
                {userData.bio.length}/{300} characters
              </span>
            </div>
            {error && error.bio && <span className="text-sm text-Red">{error.bio}</span>}
          </div>
          <CustomButton
            className="mt-2 mx-auto rounded text-white py-2 px-14 block"
            type="button"
            disabled={loader}
            onClick={() => handleSubmit()}
          >
            Update Profile
          </CustomButton>
        </>
      ) : (
        <>
        <div className="mb-3">
          <label className="text-darkBlack font-bold capitalize">Profile Picture</label>
          <br />
          {brandData && brandData.avatar === '' && (
            <input
              id="imageInput"
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              ref={fileRef}
              className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
              onChange={handleChange}
            />
          )}
          {brandData.avatar !== '' && (
            <div className="w-32 relative mt-4">
              <img src={brandData.avatar} alt="" className="object-cover " />
              <div className="absolute -top-2 -right-2 cursor-pointer" onClick={() => setBrandData({ ...brandData, avatar: '' })}>
                <ImCross className="text-red" />
              </div>
            </div>
          )}
          {error && error.avatar && <span className="text-sm text-Red">{error.avatar}</span>}
        </div>
        <div className="mb-3">
          <label className="text-darkBlack font-bold capitalize">Name</label>
          <input
            className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
            type="text"
            name="name"
            value={brandData.name}
            onChange={onChangeBrand}
            ref={name}
          />
          {error && error.name && <span className="text-sm text-Red">{error.name}</span>}
        </div>
        <div className="mb-3">
          <label className="text-darkBlack font-bold capitalize">Bio</label>
          <textarea
            className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
            value={brandData.bio}
            name="bio"
            onChange={onChangeBrand}
            ref={bio}
            maxLength={300}
          />
          <div className="flex justify-end">
            <span className="text-sm text-darkGray">
              {brandData.bio.length}/{300} characters
            </span>
          </div>
          {error && error.bio && <span className="text-sm text-Red">{error.bio}</span>}
        </div>
        <div className="mb-3">
          <label className="text-darkBlack font-bold capitalize">Website</label>
          <input
            className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
            type="text"
            name="website"
            value={brandData.website || ''}
            onChange={onChangeBrand}
            ref={website}
          />
          {error && error.website && <span className="text-sm text-Red">{error.website}</span>}
        </div>
        <div className="mb-3">
          <label className="text-darkBlack font-bold capitalize">Email</label>
          <input
            className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
            type="email"
            name="email"
            value={brandData.email || ''}
            onChange={onChangeBrand}
            ref={email}
          />
          {error && error.email && <span className="text-sm text-Red">{error.email}</span>}
        </div>
        <CustomButton
          className="mt-2 mx-auto rounded text-white py-2 px-14 block"
          type="button"
          disabled={loader}
          onClick={handleSubmit}
        >
          Update Profile
        </CustomButton>
      </>
      )}
    </div>
  )}
</>
  );
}
export default AccountUpdate;
