import React, { useRef, useState, useEffect } from 'react';
import { Modal } from 'react-responsive-modal';
import Collapsible from 'react-collapsible';
import { BsChevronCompactDown, BsChevronCompactUp } from 'react-icons/bs';
import { useTaskState, TaskService } from '../../Service/TaskServices';
import s3 from '../../aws-s3';
import S3FileUpload from 'react-s3';
import moment from 'moment';
import Loader from '../Loader';
import { useRecipeState, RecipeService } from '../../Service/RecipeServices';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import VideoCarousel from './VideoCarousel';
import { UsersService, useUserState } from '../../Service/userServices';
import ProposalVideo from './ProposalVideo';
import { useAuth } from '../../Context/AuthContext';
import { validateFormFields } from '../../utils/functions';
import { Toaster, toast } from 'react-hot-toast';
import AWS from 'aws-sdk'
import S3 from 'react-aws-s3';
import CustomButton from '../../utils/customButton';
import useFileUploader from '../../hooks/useFileUploader';
import useSubmitStatus from '../../hooks/useSubmitStatus';
import FileProgressBar from '../FileProgressBar';
AWS.config.update({
  region: 'us-east-1',
  accessKeyId: 'AKIAUFGKHL2BO2FGMSFY',
  secretAccessKey: '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'
});

const config = {
  region: 'us-east-1',
  bucketName: 'crafted-react-images',
  accessKeyId: 'AKIAUFGKHL2BO2FGMSFY',
  secretAccessKey: '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',
  s3Url: 'https://crafted-react-images.s3.amazonaws.com',
}
const ReactS3Client = new S3(config);

const elastictranscoder = new AWS.ElasticTranscoder();
const createJob = async (file) => {
  console.log("file name: ", file)
  const fileKey = file.replace("https://crafted-react-images.s3.amazonaws.com/", "").replace(/\s/g, '_')
  const params = {
    "Input": {
      "Key": fileKey,
      "FrameRate": "auto",
      "Resolution": "auto",
      "AspectRatio": "auto",
      "Interlaced": "auto",
      "Container": "mp4"
    },
    "Outputs": [
      {
        "Key": `${fileKey.replace(/\.mov$/i, '.mp4').replace(/\s/g, '_')}`,
        "Rotate": "0",
        "PresetId": "1351620000001-000001"
      }
    ],
    "PipelineId": "1712849456709-w50a8j"
  };
  //console.log("params: ", params)

  return new Promise((resolve, reject) => {
    elastictranscoder.createJob(params, function(err, data) {
      if (err) {
        console.log(err, err.stack); // an error occurred
        reject(err);
      } else {
        console.log(data);           // successful response
        resolve(data);
      }
    });
  });
}
const bg = {
  overlay: {
    background: 'rgba(128, 128, 128, 0.099)'
  }
};

const Variation = ({ variation, handleVariationChange }) => {
  const {
    files,
    inputRef,
    progress,
    uploadError,
    uploadHanlder,
    handleRetryUpload
   } = useFileUploader();

   useEffect(() => {
    if(files) {
      const updatedVariation = { ...variation, files };
      handleVariationChange(updatedVariation)
    }
   }, [files])

  return (
    <div className='mb-2' key={variation.id}>
      <h3 className='font-bold'>
        Version {variation.id + 1}: <FileProgressBar progress={progress} />
          {uploadError ? 
          
          <div className="flex gap-3 items-center">
            <span className="text-xs text-danger">{uploadError}</span>
            <span className="text-white px-2 mr-3 rounded-lg outline-none text-xs bg-Blue cursor-pointer" onClick={handleRetryUpload}
            >Retry
            </span>
          </div>
          :null}
      </h3>
      <div className='flex w-full 2xl:text-base md:text-base xs:text-0.7'>
        {Object.entries(variation)
          .filter(([key, value]) => value === true || value === false)
          .map(([key, value], index, array) => (
            <>
              {index > 0 && ", "}
          <span key={key} className="ml-1">
            {key === "music" && (variation[key] === true ? "Music" : "No Music")}
            {key === "onScreenText" && (variation[key] === true ? "On-screen text" : "No On-screen text")}
            {key === "separateClips" && (variation[key] === true ? "Unedited clips" : "No Unedited clips")}
            {key === "voiceover" && (variation[key] === true ? "Voiceover" : "No Voiceover")}
          </span>
            </>
          ))}
          
        </div>
        {variation && variation.separateClips === true && (
            <div className='2xl:text-base md:text-base xs:text-0.7 ml-1 text-gray-500'>
              <span className='font-bold'>NOTE:</span>For unedited clips, you must include the unedited clips that make up your video. You can select multiple files.
            </div>
          )}
      <input
        id="videoInput"
        type="file"
        required
        accept="video/mov, video/mp4, "
        className="focus:outline-none w-full mb-2 bg-lightGray p-2 mt-2 mr-2 rounded"
        {...(variation.separateClips ? { multiple: true, max: 10 } : {})}
        onChange={(e) => uploadHanlder(e)}
        ref={inputRef}
      />
    </div>
  )
}
const TaskDetails = (props) => {
  const fileRefs = useRef({});
  const taskState = useTaskState();
  const userState = useUserState();
  const [videos, setVideos] = useState({});
  const [metaRequested, setMetaRequested] = useState(false);
  const [variations, setVariations] = useState([]);
  const [tiktokCode, setTiktokCode] = useState('');
  const [recipeId, setRecipeId] = useState(null);
  const [final, setFinal] = useState(false);
  const { currentUser } = useAuth();
  let videoLinks = {};
  let s3PhotoLink = {};
  let s3ReviewLink = {};
  let s3Variations = [];
  let s3RawFootage = [];
  //const [videosUploaded, setVideoUploads] = useState(false);
  var uploadedVideos = {};
  let video1 = '';
  let video2 = '';
  let products = [];
  const timezone = moment.tz.guess();
  var task_deadline_time = moment(props.task.deadline).tz(timezone).format('MMM Do YYYY');
  if (props.task) {
    products = props.task.products
      ? Object.entries(props.task.products).map((e) => ({
          [e[0]]: e[1]
        }))
      : [];
  }

  const [error, setError] = useState({
    avatar: ''
  });
  const fieldErrors = {
    note: 'Please Enter Note'
  };
  const [errors, setErrors] = useState({});

  const image = useRef('');

  const userProposals = [];

  for (const proposalId in props.task.proposals) {
    const proposal = props.task.proposals[proposalId];
    if (proposal.creator_id === currentUser.uid) {
      for (const key in proposal.proposal_details) {
        userProposals.push(proposal.proposal_details[key]);
      }
    }
  }

  // 1 - set num_videos

  var num_videos = props.task.recipe_video + props.task.product_showcase;
  if (num_videos <= 0) {
    console.error('Error: num_videos must be a positive integer');
    return null;
  }

  // 2 - generate appropriate number of video inputs

  // 3 - handleChange that updates a useState element with true/false for upload

  // 4 - Iterate through each element in array of videos & upload to S3, set the result to another variable

  // 5 - place the object into the object for submit task

  const {
    retrySubmit,
    setRetrySubmit,
    isSubmitting,
    setIsSubmitting
  } = useSubmitStatus();

  const {
    files: files,
    inputRef: videoInputRef,
    progress: videoUploadProgress,
    uploadError: videoUploadError,
    uploadHanlder: uploadVideoHandler,
    handleRetryUpload: handleRetryVideoUpload
   } = useFileUploader();

   const {
    files: photo,
    inputRef: photoInputRef,
    progress: photoUploadProgress,
    uploadError: photoUploadError,
    uploadHanlder: uploadPhotoHandler,
    handleRetryUpload: handleRetryPhotoUpload
   } = useFileUploader();

   const {
    files: review,
    inputRef: reviewInputRef,
    progress: reviewUploadProgress,
    uploadError: reviewUploadError,
    uploadHanlder: uploadReviewHandler,
    handleRetryUpload: handleRetryReviewUpload
   } = useFileUploader();

   const {
    files: raw,
    inputRef: rawInputRef,
    progress: rawUploadProgress,
    uploadError: rawUploadError,
    uploadHanlder: uploadRawHandler,
    handleRetryUpload: handleRetryRawUpload
   } = useFileUploader();


  const handlePhotoChange = (event) => {
    if (event.target.files.length > 3) {
      // Notify the user that only 3 files can be selected
      alert('You can only select up to 3 files');
      // Clear the selected files
      event.target.value = '';
    } else {
      uploadPhotoHandler(event)
    }
  };
  const handleVariationChange = (files) => {
    setVariations(files);
  };

  const handleAddVideos = (e, i) => {
    if (e.target.files && e.target.files.length > 0) {
      setVideos({
        ...videos,
        [`video${i}`]: e.target.files[0]
      });
    }
  };
  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setRecipeId(selectedValue);
    console.log(selectedValue);
  };
  // loading user's information
  useEffect(() => {
   if (userState.updateNeed.value) {
    UsersService.FetchUserInfo(currentUser.uid);
   }
  }, [userState.updateNeed.value]);

  let user = userState.user.value;

  const recipeState = useRecipeState();
  useEffect(() => {
    if (recipeState.updateNeed.value) {
      RecipeService.FetchRecipeForUser(currentUser.uid);

    }
  }, [recipeState.updateNeed.value]);
  const res = recipeState.recipes.value;
  const recipe = res
    ? Object.entries(res).map((e) => ({
      [e[0]]: e[1]
    }))
    : [];

  //on Form Handle Submit
  const handleSubmit = async (event) => {
    
    event.preventDefault();
    setErrors({});
    const formValues = {
      image: image.current.value
    };

    const validationErrors = validateFormFields(formValues, fieldErrors);
    if (Object.keys(validationErrors).length) {
      return setErrors(validationErrors);
    }

    let url;
    if (image) {
      url = await S3FileUpload.uploadFile(image.current.files[0], s3).then((data) => data.location);
    }
    console.log("url: ---", url)
    const temp = {};
    temp.thumbnail = url;

    temp.uid = currentUser.uid;
    // if (currentUser.uid) RecipeService.AddRecipe(data);
    props.close();
  };
  async function sendRevisedVid(event) {
    setIsSubmitting(true);
    event.preventDefault();
    try {
      taskState.merge({ loading: true });
      /*if (vid1Uploaded) {
        await S3FileUpload.uploadFile(fileRef1.current.files[0], s3).then((data) => {
          video1 = data.location.toString();
        });
      }
      if (vid2Uploaded) {
        await S3FileUpload.uploadFile(fileRef2.current.files[0], s3).then((data) => {
          video2 = data.location.toString();
        });
      }*/

      /*for (const key in videos) {
        await S3FileUpload.uploadFile(videos[key], s3).then((data) => {
          setVideoLinks({
            ...videoLinks,
            [`${key}`]: data.location.toString()
          });
        });
      }*/
      let dated = moment().format('MMM_DD_YYYY_HHmmss');
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        console.log('File #' + i);
        console.log(file);

        const userName = user.name || (user && user.shipping_details && user.shipping_details.fullname) || user.username;
        const brandName = props.task.brand_name; // Replace with your actual brand name
        const newName = `${userName}_${brandName}_${dated}${file.name.substr(file.name.lastIndexOf('.'))}`.replace(/ /g, '_');

        // Create a new File object with the new name
        file = new File([file], newName, { type: file.type });
        try {
          const data = await ReactS3Client.uploadFile(file, newName);
          console.log('File String #' + i);
          console.log(data.location);

          if (file.name.toLowerCase().endsWith('.mov')) {
            // Transcode the file
            await createJob(data.location);
        
            // Replace the .mov file with the .mp4 file in the videoLinks array
            videoLinks[`video${i + 1}`] = data.location.replace(/\.mov$/i, '.mp4').replace(/\s/g, '_');
          } else {
            videoLinks[`video${i + 1}`] = data.location.replace(/\s/g, '_');
          }
        } catch (error) {
          console.error(`Failed to upload file: ${file.name}`, error);
        }
      }
  
      if (review.length > 0) {    
      for (let i = 0; i < review.length; i++) {
        const file = review[i]; // Use `file` instead of `review_file`
        console.log('File #' + i);
        console.log(file);
        const data = await S3FileUpload.uploadFile(file, s3);
        console.log('File String #' + i);
        console.log(data.location);
      
        s3ReviewLink[`review${i + 1}`] = data.location.toString();
      }
        }

        if (photo.length > 0 ) {
          
       
      for (let i = 0; i < photo.length; i++) {
        const file = photo[i];
        const data = await S3FileUpload.uploadFile(file, s3);
        s3PhotoLink[`photo${i + 1}`] = data.location.toString();
      }
      
      // After uploading all files, check if the number of files exceeds three
      if (photo.length !== 3) {
        // Notify the user that only 3 files can be uploaded
        alert('You can only upload up to 3 files');
        setIsSubmitting(false);
        return; // Exit the function or handle the error accordingly
      }
    }
    if (variations.length > 0) {
      for (let i = 0; i < variations.length; i++) {
        const variation = variations[i];
        
        if (variation.separateClips === true) {
          const filesToUpload = variation.files;
          const uploadedFiles = await Promise.all(filesToUpload.map(file => {
            file = new Blob([file], { type: file.type });
            file.name = file.name.replace(/\s/g, '_');
            return S3FileUpload.uploadFile(file, s3);
          }));
          
          const variationData = {
            files: uploadedFiles.map(d => d.location), // Store all file locations in an array
            id: variation.id,
            music: variation.music,
            onScreenText: variation.onScreenText,
            separateClips: variation.separateClips,
            voiceover: variation.voiceover
          };
        
          s3Variations.push(variationData); // Push the variationData directly
        } else {
          console.log('variation with one file: ', variation);
          let fileToUpload = Array.isArray(variation.files) ? variation.files[0] : variation.files;
          console.log('variation with one file: ', fileToUpload);
  
          // Create a new name
          const userName = user.name || (user.shipping_details && user.shipping_details.fullname) || user.username;
          const brandName = props.task.brand_name; // Replace with your actual brand name
          let dateTime = moment().format('MMM_DD_YYYY_HHmmss');
          const newName = `${userName}_${brandName}_version${i+2}_${dateTime}${fileToUpload.name.substr(fileToUpload.name.lastIndexOf('.'))}`.replace(/ /g, '_');
  
          // Create a new File object with the new name
          fileToUpload = new File([fileToUpload], newName, { type: fileToUpload.type });
  
          const uploadedFile = await S3FileUpload.uploadFile(fileToUpload, s3);
          const variationData = {
            files: uploadedFile.location, // Store the uploaded file location
            id: variation.id,
            music: variation.music,
            onScreenText: variation.onScreenText,
            separateClips: variation.separateClips,
            voiceover: variation.voiceover
          };
  
          s3Variations.push(variationData);
      }
    }
  }
      const dt = new Date();
      let date = dt.toString();
      date.substring(4, 21);
      let vidname =
      props.task.brand_name + '_' +  
      ((user && user.name) || (user && user.shipping_details && user.shipping_details.fullname) || (user && user.username)) + 
      '_' + date.substring(4, 21);
      let d = {
        recipe: recipeId !== null ? recipeId : {},
        photoLinks: s3PhotoLink ,
        reviewLinks: s3ReviewLink,
        drafts: videoLinks,
        creator_id: props.task.creator_id ? props.task.creator_id : props.task.brand_id,
        task_id: props.task.id,
        creator_name: vidname,
        creator_id: currentUser.uid,
        brand_id: props.task.brand_id,
        variations: s3Variations,
      };
      await TaskService.SendDraft(props.id[0], d);
      taskState.merge({ loading: false });
      toast.success('Deliverables Sent', {duration: 1800});
      setTimeout(() => {
        setRetrySubmit(false)
        props.close();
      } , 2000);
      // close modal
    } catch (error) {
      setRetrySubmit(true)
      toast.error(error.message || "Something went wrong", { duration: 3000 });
      // Handle errors, e.g., show an error message or log the error
    } finally {
      // Reset the submitting state if needed
      setIsSubmitting(false);
    }
  }

  return (
    <div>
      <Toaster/>
      <Modal
        open={props.open}
        onClose={props.close}
        center
        styles={bg}
        classNames={{ modal: 'customModal' }}
        closeOnOverlayClick={true}>
        <>
          <Collapsible
            trigger={[
              'Brief Details',
              <div className="px-3 absolute right-0">
                <BsChevronCompactDown />
              </div>
            ]}
            triggerWhenOpen={[
              'Brief Details',
              <div className="px-3 absolute right-0">
                <BsChevronCompactUp />
              </div>
            ]}
            open={false}
            contentOuterClassName="mb-6"
            triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
            <div className="m-1 bg-white rounded-lg text-3xl dark:bg-gray-700 p-5">
              <div className="flex ml-12 mb-5">
                <div className="flex mr-5 font-bold">Brand:</div>
                <a
                  className="text-blue underline"
                  target="_blank"
                  href={`http://${props.task && props.task.brand_website}`}
                  rel="noreferrer">
                  {props.task && props.task.brand_name}
                </a>
              </div>

              {/*<div className="flex ml-12 mb-5">
                <div className="flex mr-5 font-bold">Deadline:</div>
                <div>{props.task && task_deadline_time}</div>
              </div>*/}
              <div className="flex ml-12 mb-5">
                <div className="flex mr-5 font-bold">Brief:</div>
                <div className="flex flex-col space-y-2">
                  <div>
                    {' '}
                    {props.task &&
                      props.task.recipe_videos > 0 &&
                      `Create ${props.task.recipe_videos} short form recipe videos showcasing the brand as organically (not ad-style or salesy) as possible.`}
                  </div>
                  <div>
                    {' '}
                    {props.task &&
                      props.task.product_showcase > 0 &&
                      `Create ${props.task.product_showcase} short form product demo videos showcasing the brand as organically (not ad-style or salesy) as possible.`}
                  </div>
                  <div>
                    {' '}
                    Crafted and the brand will own these videos and will post them on their social
                    accounts, use them for paid social, website, etc. in perpetuity.
                  </div>
                </div>
              </div>
              <div className="ml-12 mb-5">
                <div>
                  <div className="mt-2 font-bold">
                    {props.task && props.task.brand_name} is prioritizing these products/flavors:
                  </div>
                  <div className="">{props.task && props.task.note1}</div>
                </div>
                <div>
                  <div className="mt-2 font-bold">
                    {props.task && props.task.brand_name}'s Target Audience:
                  </div>
                  <div className="">{props.task && props.task.note2}</div>
                </div>
                <div>
                  <div className="mt-2 font-bold">Key talking points for creators:</div>
                  <div className="">{props.task && props.task.note3}</div>
                </div>
              </div>
            </div>
          </Collapsible>
          <Collapsible
            trigger={[
              'Examples of Video Types',
              <div className="px-3 absolute right-0">
                <BsChevronCompactDown />
              </div>
            ]}
            triggerWhenOpen={[
              'Examples of Video Types',
              <div className="px-3 absolute right-0">
                <BsChevronCompactUp />
              </div>
            ]}
            open={false}
            contentOuterClassName="mb-6"
            triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
            <div className="m-3">
              <VideoCarousel />
            </div>
          </Collapsible>
          <Collapsible
            trigger={[
              'Draft Details',
              <div className="px-3 absolute right-0">
                <BsChevronCompactDown />
              </div>
            ]}
            triggerWhenOpen={[
              'Draft Details',
              <div className="px-3 absolute right-0">
                <BsChevronCompactUp />
              </div>
            ]} 
            open={true}
            contentOuterClassName="mb-6"
            triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
            <ProposalVideo task={props.task} />
          </Collapsible>
          <Collapsible
            trigger={[
              'Send Revised Video',
              <div className="px-3 absolute right-0">
                <BsChevronCompactDown />
              </div>
            ]}
            contentOuterClassName="mb-6"
            triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            triggerWhenOpen={[
              'Send Revised Video',
              <div className="px-3 absolute right-0">
                <BsChevronCompactUp />
              </div>
            ]} open={true}>
              <form onSubmit={sendRevisedVid}> 
                <div className="flex flex-col my-5">
                {props.task && props.task.add_ons && props.task.add_ons.some(addon => addon.name === "Recipes") && (
                <div>
                  <div className="mt-2 flex w-fit items-center relative group"><p className='font-bold'>Add Recipe:</p><AiOutlineQuestionCircle className='ml-1'/>
                    <div className="absolute border font-thin border-gray-400 top-0 right-0 transform translate-x-full -translate-y-full w-56 h-fit bg-gray-200 text-gray-800 py-2 px-4 rounded opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto">
                        Recipes must be added in the "Recipes" page before submitting a recipe.
                    </div>
                  
                  
                  </div>
                  {recipe && recipe.length > 0 ? (
                    <select 
                      required 
                      className="mt-4 mb-2 mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                      onChange={handleSelectChange}
                    >
                      <option value="" className="text-gray-400">
                        Select a recipe
                      </option>
                      {recipe.map((item) => {
                        const res = item[Object.keys(item)[0]];
                        const id = Object.keys(item)[0];
                        return (
                          <option key={id} value={id}>
                            {res.name}
                          </option>
                        );
                      })}
                    </select> 
                  ) : (
                    <h1 className="font-thin my-3">
                      You currently have no recipes. Please add a recipe{" "}
                      <Link to="/creator/dashboard/recipes" className="underline text-red font-bold">here</Link>
                    </h1>
                  )}

                </div>
              )}

              {props.task && props.task.add_ons && props.task.add_ons.some(addon => addon.name === "Reviews") && (
                <div>
                <h3 className="font-bold flex items-center gap-3">Screenshot of Product Review: <FileProgressBar progress={reviewUploadProgress} />
                            {reviewUploadError ? 
                            
                            <div className="flex gap-3 items-center">
                              <span className="text-xs text-danger">{reviewUploadError}</span>
                              <span className="text-white px-2 mr-3 rounded-lg outline-none text-xs bg-Blue cursor-pointer" onClick={handleRetryReviewUpload}
                              >Retry
                              </span>
                              </div>
                            :null}
                          </h3>
                <input
                id="screenshotInput"
                type="file"
                required
                accept="image/png, image/jpeg"
                className="focus:outline-none w-full mb-2 bg-lightGray p-2 mt-2 mr-2 rounded"
                onChange={(e) => uploadReviewHandler(e)}
                ref={reviewInputRef}
              />
                </div>
              )}

                  {props.task && props.task.add_ons && props.task.add_ons.some(addon => addon.name === "Photos") && (
                    <div>     
                      <h3 className="flex items-center gap-3">Add three Product Photos: <FileProgressBar progress={photoUploadProgress} />
                            {photoUploadError ? 
                            
                            <div className="flex gap-3 items-center">
                              <span className="text-xs text-danger">{photoUploadError}</span>
                              <span className="text-white px-2 mr-3 rounded-lg outline-none text-xs bg-Blue cursor-pointer" onClick={handleRetryPhotoUpload}
                              >Retry
                              </span>
                              </div>
                            :null}
                          </h3>
                      <input
                        id="imageInput"
                        type="file"
                        required
                        accept="image/png, image/jpeg, image/jpg"
                        className="focus:outline-none w-full mb-2 bg-lightGray p-2 mt-2 mr-2 rounded"
                        multiple
                        max="3"
                        ref={photoInputRef}
                        onChange={(e) => handlePhotoChange(e)}
                      />
                    </div>
                  )}
                  <div className="font-bold text mr-2">
                  <h3 className="flex items-center gap-3">Video: <FileProgressBar progress={videoUploadProgress} />
                    {videoUploadError ? 
                    
                    <div className="flex gap-3 items-center">
                      <span className="text-xs text-danger">{videoUploadError}</span>
                      <span className="text-white px-2 mr-3 rounded-lg outline-none text-xs bg-Blue cursor-pointer" onClick={handleRetryVideoUpload}
                      >Retry
                      </span>
                      </div>
                     :null}
                  </h3>
                    <input
                      id="imageInput"
                      required
                      type="file"
                      accept="video/mov, video/mp4, video/avi"
                      className="focus:outline-none w-full bg-lightGray p-2 mt-2 mr-2 rounded"
                      multiple
                      onChange={(e) => uploadVideoHandler(e)}
                      ref={videoInputRef}
                    />
                  </div>
                  {props.task && props.task.variations && (
                  <div className='mt-2'>     
                    {props.task.variations.map((variation, index) => (
                      <Variation variation={variation} key={index} handleVariationChange={handleVariationChange}/>
                    ))}
                  </div>
                )}
                  {props.task && props.task.add_ons && props.task.add_ons.some(addon => addon.name === "Raw Footage") && (
                <div>       
                <h3 className='font-bold flex items-center gap-3'>Raw Footage Clips <FileProgressBar progress={rawUploadProgress} />
                    {rawUploadError ? 
                    
                    <div className="flex gap-3 items-center">
                      <span className="text-xs text-danger">{rawUploadError}</span>
                      <span className="text-white px-2 mr-3 rounded-lg outline-none text-xs bg-Blue cursor-pointer" onClick={handleRetryRawUpload}
                      >Retry
                      </span>
                      </div>
                     :null}</h3>  
                <span className='font-bold'>NOTE:</span>For unedited clips, you must include the unedited clips that make up your video. You can select multiple files.
                <input
                id="screenshotInput"
                type="file"
                multiple
                required
                accept="video/mov, video/mp4"
                className="focus:outline-none w-full mb-2 bg-lightGray p-2 mt-2 mr-2 rounded"
                onChange={(e) => uploadRawHandler(e)}
                ref={rawInputRef}
              />
                </div>
              )}
                {props.task && props.task.add_ons && props.task.add_ons.some((addOn) => 
                    addOn.name === "Whitelist" && addOn.platform.includes("instagram")) &&
                    <div className='my-2'>
                      <label className="inline-flex items-center mr-4">
                        <input
                          type="checkbox"
                          className="form-checkbox h-5 w-5 text-gray-600"
                          checked={metaRequested}
                          required
                          onChange={() => setMetaRequested(true)}
                        />
                        <span className="ml-2 text-base xs:text-0.7 w-full text-gray-700">I have accepted {props.task.brand_name} as an Ad Partner through Meta Business Suite:  <a href="https://help.instagram.com/966886840676703" target ="_blank" className="text-Blue underline">More Info</a></span>
                      </label>
                    
                    </div>
                  }
                  <div className='my-2'>
                      <label className="inline-flex items-center mr-4">
                        <input
                          type="checkbox"
                          className="form-checkbox h-5 w-5 text-gray-600"
                          checked={final}
                          required
                          onChange={() => setFinal(!final)}
                        />
                        <span className="ml-2 text-base xs:text-0.7 w-full text-gray-700"> I am submitting a video file that can be used as a final deliverable - not a screen recording of the video from editing app or a video watermarked with #draft, etc. </span>
                      </label>
                    
                  </div>
                </div>
                <div className="items-center  my-5 justify-center text-center">
                <CustomButton
                className=" text-white w-1/3 mt-8 py-4 outline-none text-base"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <span>
                    <Loader /> 
                    <span className="mr-2">{retrySubmit ? "Retrying" : "Sending"}...</span>
                   
                  </span>
                ) : (
                  retrySubmit ? "Retry Send Update" : "Send Update"
                 )}
              </CustomButton>
                 
                </div>
              </form>
          </Collapsible>
        </>
      </Modal>
    </div>
  );
};
export default TaskDetails;