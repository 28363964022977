import React from 'react';
import { useTabState } from '../../../utils/TabState';
import { CampaignState } from '../../../utils/campaignState';

export default function Subscribe({ currentSubCampTab }) {
  const tabState = useTabState();
  const campaignState = CampaignState();
  const tabhandle = (tab) => {
    tabState.merge({ currentSubCampTab: tab });
  };
  let cmp = campaignState.campaignData.value;
  let cmpName = cmp ? cmp.name : 'Campaign name';
  return (
    <div className="flex mx-5 font-bold border-gray-300 mb-2 rounded-lg overflow-hidden">
      {[
        { label: 'Overview', id: 9 },
        { label: 'Review Proposals', id: 2 },
        { label: 'View Briefs', id: 3 },
        { label: 'Fulfill Products', id: 4 },
        { label: 'Review Video Drafts', id: 5 },
        { label: 'Final Deliverables', id: 6 },
        { label: 'Post Analytics', id: 7 },
        { label: 'Campaign Analytics', id: 8 },
      ].map(({ label, id }) => (  
        <h1
          key={id}
          className={`2xl:text-base md:text-0.62 mx-5 cursor-pointer font-bold mb-2 font-black xs:xl text-center transition-all duration-200 ease-in-out transform hover:scale-110 ${
            currentSubCampTab === id ? 'text-Blue border-red-500' : 'text-black border-transparent'
          } border-b-4 hover:text-Blue`}
          onClick={() => tabhandle(id)}
        >
          {label}
        </h1>
      ))}
    </div>
  );
}