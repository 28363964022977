import React, { useState } from 'react';
import GlobalDialog from '../../utils/globalDialog';
import ConfirmModalTask from './SendTaskProposal';
import { BsChevronCompactDown, BsChevronCompactUp } from 'react-icons/bs';
import Collapsible from 'react-collapsible';
import moment from 'moment';
import { useAuth } from '../../Context/AuthContext';
import BriefTemplateView from './BriefTemplateView';
import CustomButton from '../../utils/customButton';
function ProposalModal({ open, onClose, id, task }) {
  const { currentUser } = useAuth();
  const [tab, setTab] = useState(0);
  const [briefView, setBriefView] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const timezone = moment.tz.guess();
  var task_deadline_time = moment(task.deadline).tz(timezone).format('MMM. D, YYYY');
  const proposalHandle = () => {
    tab ? setTab(2) : setTab(1);
    confirm ? setConfirm(false) : setConfirm(true);
  };

const userProposals = [];

const viewBriefHandle = () => {
  briefView ? setBriefView(false) : setBriefView(true);
}

for (const proposalId in task.proposals) {
  const proposal = task.proposals[proposalId];
  if (proposal.creator_id === currentUser.uid) {
    for (const key in proposal.proposal_details) {
      userProposals.push(proposal.proposal_details[key]);
    }
  }
}
  // look under tasks -> proposlas
  return (
    <>
      {tab === 0 ? (
        <GlobalDialog className="px-3" open={open} onClose={onClose}>
          <Collapsible
            trigger={[
              'Brief Details',
              <div className="px-3 absolute right-0">
                <BsChevronCompactDown />
              </div>
            ]}
            triggerWhenOpen={[
              'Brief Details',
              <div className="px-3 absolute right-0">
                <BsChevronCompactUp />
              </div>
            ]}
            open={false}
            contentOuterClassName="mb-6"
            triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
          <div className="m-3 bg-white rounded-lg text-3xl dark:bg-gray-700">
            <div className="flex ml-12 mb-5">
              <div className="flex mr-5 font-bold">Brand:</div>
              <a className= "text-blue underline" target="_blank" href={`http://${task && task.brand_website}`} rel="noreferrer" >{task && task.brand_name}</a>
            </div>

            {/*<div className="flex ml-12 mb-5">
              <div className="flex mr-5 font-bold">Deadline:</div>
              <div>{task && task_deadline_time}</div>
            </div>*/}
            <div className="flex ml-12 mb-5">
              <div className="flex mr-5 font-bold">Brief:</div>
              <div className="flex flex-col">
                    <div className='flex-wrap'>
                    {task && task.brief_link && task.brief_link.trim() !== "" ? (
                      <a href={task.brief_link} target="_blank" rel="noopener noreferrer" className="text-Blue hover:underline">
                        View Brief Details
                      </a>
                    ) : (
                      <div className="flex flex-col space-y-2 md:text-base xs:text-0.7 sm:text-xs">
                        {task && task.recipe_videos > 0 && (
                          <div>
                            Create {task.recipe_videos} short form recipe videos showcasing the brand as organically (not ad-style or salesy) as possible.
                          </div>
                        )}
                        {task && task.product_showcase > 0 && (
                          <div>
                            Create a short form product demo video showcasing the brand as organically (not ad-style or salesy) as possible.
                          </div>
                        )}
                        <div>
                          Crafted and the brand will own these videos and will post them on their social accounts, use them for paid social, website, etc. in perpetuity.
                        </div>
                      </div>
                    )}
              </div>
              </div>
            </div>
            <div className="ml-12 mb-5">
              <div>
                <div className="mt-2 font-bold">
                  {task && task.brand_name} is prioritizing these products/flavors:
                </div>
                <div className="">
                  {task && task.note1}
                </div>
              </div>
              <div>
                <div className="mt-2 font-bold">
                  {task && task.brand_name}'s Target Audience:
                </div>
                <div className="">
                  {task && task.note2}
                </div>
              </div>
              <div>
                <div className="mt-2 font-bold">
                  Key talking points for creators:
                </div>
                <div className="">
                  {task && task.note3}
                </div>
              </div>
            </div>
          </div>
          </Collapsible>
          <Collapsible
            trigger={[
              'My Proposal',
              <div className="px-3 absolute right-0">
                <BsChevronCompactDown />
              </div>
            ]}
            triggerWhenOpen={[
              'My Proposal',
              <div className="px-3 absolute right-0">
                <BsChevronCompactUp />
              </div>
            ]}
            open={false}
            contentOuterClassName="mb-6"
            triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
              <div className='p-3'>
                {userProposals.map((proposalDetail, index) => (
                  <p key={index}>Proposal {index + 1}: {proposalDetail}</p>
                ))}
              </div>  
            </Collapsible>

          <div className='text-center p-3'> You will be notified here once a brand has accepted your proposal. If you do not hear from us about this task, please continue monitoring this page for future opportunities! </div>
          <div className=" pb-4 font-bold text-red items-center justify-center text-center">
                Waiting for {task.brand_name} to approve your proposal.
          </div>
          {task.brief_template && (
            <div className='mx-auto items-center flex'>
                <CustomButton
                  className=" text-white mx-auto mb-2 px-2 py-4 outline-none 2xl:text-base md:text-0.7 xs:text-sxs"
                  onClick={() => viewBriefHandle()}>
                  View Brief
                </CustomButton>
            </div>
              )}
        </GlobalDialog>
      ) : null}
      {tab === 1 ? <ConfirmModalTask open={confirm} onClose={() => proposalHandle()} id={id} task={task} deadline={task_deadline_time} /> : null}
      {briefView ? (
        <BriefTemplateView open={briefView} close={() => viewBriefHandle()} id={task.brief_template.id} task={task} />
      ) : null}
    </>
  );
}

export default ProposalModal;
