import React, { useState, useEffect } from 'react';
import { BiLogOut } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { useAuth } from '../../Context/AuthContext';
import { FiBookmark, FiInbox, FiSettings, FiUser, FiZap, FiStar, FiHome } from 'react-icons/fi';
import { HiOutlineCash } from 'react-icons/hi';
import { baseUrl } from '../../baseUrl';
import { MdOutlineRateReview } from "react-icons/md";
import './Sidebar.css';
import MobileSidebar from './MobileSidebar';
import DesktopSidebar from './DesktopSidebar';
function Sidebar() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className='mb-5'>
      {isMobile ? (
        <MobileSidebar />
      ) : (
        <DesktopSidebar />
      )}
    </div>
  );
}

export default Sidebar;
