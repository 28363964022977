import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from '../../Context/AuthContext';
import { UsersService, useUserState } from '../../Service/userServices';
import NewTemplateModal from './NewTemplateModal';
import { useEmailState, EmailService } from '../../Service/EmailService';
import { validateFormFields } from '../../utils/functions';
import Multiselect from 'multiselect-react-dropdown';
import { AiFillMinusCircle } from 'react-icons/ai';
import toast, { Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { useBrandState, BrandService } from '../../Service/BrandServices';
import { Checkbox } from '@material-ui/core';
import CustomButton from '../../utils/customButton';

function AdminEmail() {
  const message = useRef('');
  const subjectLine = useRef('');
  const fieldErrors = {
    message: 'Please Enter Note'
  };
  const [setOpenTemplateModal] = useState(false);
  const [addtemplate, setAddTemplate] = useState(false);
  const [selectAllUsers, setSelectAllUsers] = useState(false);
  const [errors, setErrors] = useState({});
  const { currentUser,currentBrand } = useAuth();
  const [selectedValue] = useState([]);
  const [selectedRec, setSelectedRec] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const userState = useUserState(false);
  const templatesState = useEmailState();
  const [tempInfo, setTempInfo] = useState(null);
  const history = useHistory();

  const brandState = useBrandState();
  console.log(currentUser.uid)
  useEffect(() => {
    if (currentUser.uid !== null) {
      BrandService.FetchSingleBrand(currentUser.uid);
    }
  }, [brandState.updateNeed.value]);

  const brand = brandState.brand.value;

  {/*IuseEffect(() => {
    if (brand === null){
      history.push('/');
    }
    if (brand && Object.keys(brand).length > 0) {
      console.log("Brand object populated:", brand);
      if (brand.admin === true) {
        console.log("Brand admin is true");
      }
      else {
      history.push('/');
    } 
    }
  }, [brand]); */}
  
  // Display user
  useEffect(() => {
    if (userState.updateNeed.value) {
      UsersService.FetchUsers();
    }
  }, [userState.updateNeed.value]);

  // Function to insert dynamic variables into the textarea
  const insertDynamicVariable = (variable) => {
    if (tempInfo && tempInfo.email_body && message.current) {
      const cursorPos = message.current.selectionStart;
      const textBefore = tempInfo.email_body.substring(0, cursorPos);
      const textAfter = tempInfo.email_body.substring(cursorPos);
      const newText = textBefore + "{{" + variable + "}}" + textAfter;
      setTempInfo({ ...tempInfo, email_body: newText });
    }
  };

  /// Display all templete
  const temp = userState.user.value;
  useEffect(() => {
    EmailService.getAllTemplate();
  }, [templatesState.updateNeed.value]);

  //Changing proxxy to array
  let templeteData = [];

  useEffect(() => {
    if (templatesState.template.value) {
      toast.success(templatesState.template.value.data, {
        duration: 7000,
        position: 'top-center',

        // Styling
        style: {},
        className: '',

        // Aria
        ariaProps: {
          role: 'status',
          'aria-live': 'polite'
        }
      });
    }
  }, [templatesState.template.value]);

  const resTemp = JSON.stringify(templatesState.templates.value);
  Object.keys(JSON.parse(resTemp)).forEach((el) => {
    templeteData.push({
      templeteId: el,
      ...JSON.parse(resTemp)[el]
    });
  });

  const Userdata = temp
    ? Object.entries(temp).map((e) => ({
      [e[0]]: e[1]
    }))
    : [];

  const handleSeletedTemplate = (id) => {
    if (id) {
      setSelectedTemplate(id);
      const res = templeteData.filter((item) => item.templeteId === id);
      setTempInfo(res[0]);
    }
  };

  const styles = {
    option: {
      // To change css for dropdown options
    },
    optionContainer: {
      // To change css for option container
      overflowY: 'scroll',
      maxHeight: '120px',
      overflowX: 'hidden',
      position: 'relative'
    },
    multiselectContainer: {
      // To change css for multiselect (Width,height,etc..)
      height: '120px',
      marginTop: '10px',
      maxHeight: '200px',
      marginBottom: '65px',
      position: 'relative',
      outerWidth: '95%'
    },
    searchWrapper : {
      maxHeight: '100px', 
      overflowY: 'scroll',
    },

    optionContainer:{
    overflowY: 'scroll', /* Allows vertical scrollbar */
    overflowX: 'hidden', /* Hides horizontal scrollbar */
    maxHeight: '200px', /* Adjusted to 200px */
    position: 'relative'
   },

    chips: {
      background: '#A9A9A9',
      maxHeight: '120px', // Set a maximum height
    },
    searchBox: {
      // To change search box element look
      border: 'none',
      maxHeight: '100px',
      padding: '7px 10px',
      fontSize: '15px',
      overflowY: 'scroll',
      backgroundColor: '#F3F4F6',
      backgroundImage: 'url(../../Assets/drop_down.png)',
      borderRadius: '50px'
    }
  };

  const handleSelectAllUsers = (e) => {
    setSelectAllUsers(e.target.checked);
  
    if (e.target.checked) {
      setSelectedRec(usersData.map(user => ({ ...user, uid: user.id })));
    } else {
      setSelectedRec([]);
    }
  };
  
  const onSelect = (selectedList, selectedItem) => {
    setSelectedRec(selectedList);
  };

  const handleSubjectLineChange = (e) => {
    setTempInfo({ ...tempInfo, subjectLine: e.target.value });
  };
  
  const handleMessageChange = (e) => {
    setTempInfo({ ...tempInfo, email_body: e.target.value });
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedRec(selectedList);
  };

  const usersData = [];


  if (Userdata.length > 0) {
    Userdata.map((com, i) => {
      let obj = {};
      const el = com[Object.keys(com)];
      const id = Object.keys(com);
      if (el.username) {
        obj['name'] = el.username;
        obj['id'] = id[0];
        obj['email'] = el.email;
        usersData.push(obj);
      }
    });
  }

  ///Create templete
 const handleSubmit = async (event) => {
  event.preventDefault();
  setErrors({});
  const formValues = {
    message: message.current.value
  };

  const validationErrors = validateFormFields(formValues, fieldErrors);
  if (Object.keys(validationErrors).length) {
    return setErrors(validationErrors);
  }
  const temp = {
    email_body: message.current.value,
    subjectLine: subjectLine.current.value,
    recipients: selectedRec,
    dynamic_variables: Object.values(tempInfo.dynamic_variables)
  };
  if (currentUser.uid) EmailService.SendEmail(temp);
};

  const handleDeleteTemp = (id) => {
    if (id) {
      console.log(id);
    }
  };
  return (
    <div className="bg-white m-4 rounded-lg w-full p-6">
      <h1 className="font-semibold py-2 text-2xl"> Select Recipients{selectedRec.length > 0 && (
            <>: {selectedRec.length} recipient{selectedRec.length > 1 ? 's' : ''} selected</>
          )}</h1>
      <form>
      <div>
        <label>
          <input
            type="checkbox"
            checked={selectAllUsers}
            onChange={handleSelectAllUsers}
           className='font-bold mr-5'/>
          Select All Users 
        </label>
      </div>
      <Multiselect
        options={usersData}
        selectedValues={selectedRec}
        showCheckbox={true}
        closeOnSelect={true}
        placeholder="Select Recipients"
        style={styles}
        onSelect={onSelect}
        onRemove={onRemove}
        displayValue="name"
      />
    </form>
      <div className="flex justify-between items-center -mt-8 w-full mb-6">
        <div className='mr-6 templates hide-scroll-bar'>
          <div className="flex mt-2" style={{ width: "min-content" }}>
            {templeteData.map((el) => (
              <div className='relative'>
                <p
                  key={el.templeteId}
                  onClick={() => handleSeletedTemplate(el.templeteId)}
                  className={` mr-5 text-center  px-1 py-1 cursor-pointer w-40 bg-${selectedTemplate === el.templeteId ? 'red' : 'gray-400'
                    } text-white rounded-full`}>
                  {el.template_name}
                </p>
                <span onClick={() => handleDeleteTemp(el.templeteId)} className='absolute -top-2 right-4 cursor-pointer'><AiFillMinusCircle className='text-red' /></span>
              </div>
            ))}
          </div>
        </div>
        <div className='	'>
          <button
            onClick={() => setAddTemplate(true)}
            className="bg-Blue text-white font-bold py-2 px-4 rounded-full">
            Create Template
          </button>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <textarea
  ref={subjectLine}
  className="w-full h-10 mb-5 bg-gray-300  p-2 rounded-lg  text-black focus:outline-none "
  placeholder="Enter your subject here"
  value={tempInfo ? tempInfo.subjectLine : ''}
  onChange={handleSubjectLineChange}></textarea>
        <div className="w-full h-48 relative bg-gray-300 rounded-lg mb-4">
       
        <textarea
  ref={message}
  className="w-full h-10 bg-gray-300  p-2 rounded-lg  text-black focus:outline-none "
  placeholder="Enter your message here"
  value={tempInfo ? tempInfo.email_body : ''}
  onChange={handleMessageChange}></textarea>
                    
          <div className="flex flex-row items-center absolute bottom-4 left-4">
            {tempInfo &&
              tempInfo.dynamic_variables &&
              Object.keys(tempInfo.dynamic_variables)
                .map((key) => tempInfo.dynamic_variables[key])
                .map((val) => (
                  <p
                    key={val}
                    className={`bg-${val ? 'gray-700' : 'gray-400'
                      } px-3  mr-3 cursor-pointer text-white rounded-full`}
                    onClick={() => insertDynamicVariable(val)}>
                    {val}{' '}
                  </p>
                ))}
          </div>
        </div>
        <div className="flex flex-row justify-end">
          <CustomButton type="submit" className="  text-white font-bold py-2 px-8">
            Send
          </CustomButton>
        </div>
      </form>
      <Toaster />
      <NewTemplateModal
        open={addtemplate}
        onClose={() => setAddTemplate(false)}
        closeAction={() => {
          setOpenTemplateModal(false);
        }}
      />
    </div>
  );
}

export default AdminEmail;
