import React, { useContext, useEffect, useState } from "react";
import { useAuth } from "../../../../Context/AuthContext";
import instagram from "../../../../Assets/instagram (1).png";
import viewIcon from "../../../../Assets/view_icon.png";
import rectangleIcon from "../../../../Assets/rectangle_icon.png";
import announce from "../../../../Assets/announce_icon.png";
import bucketIcon from "../../../../Assets/buy_cart_icon.png";
import { SlUserUnfollow } from "react-icons/sl";
import {
  BsEyeFill,
  BsFillPeopleFill,
  BsFillPersonFill,
  BsQuestionCircle,
} from "react-icons/bs";
import { useParams } from "react-router-dom";
import tiktok from "../../../../Assets/tiktok-square.png";
import { toast, Toaster } from "react-hot-toast";
import {
  useCampaignState,
  CampaignService,
} from "../../../../Service/CampaignServices";
import Loader from "../../../LoaderRed";
import ViewDemographicsModal from "../../ViewDemographicsModal";
import { IoMdMale, IoMdFemale } from "react-icons/io";
import { Link } from "react-router-dom";
import youtube from "../../../../Assets/youtube-shorts.png";
import CampaignNavTabs from "../CampaignNavTabs";
import logo from "../../../../Assets/logo.png";
import CampaignSubNav from "../CampaignSubNav";
import { BiDollar, BiDollarCircle } from "react-icons/bi";
import { CampaignEmailService } from "../../../../Service/CampaignEmailService";
import { BsArrowLeft } from "react-icons/bs";
import InfluencerRemovalModal from "./InfluencerRemovalModal";
import ApproveInfluencerModal from "./ApproveInfluencerModal";
import DenyInfluencerModal from "./DenyInfluencerModal";
import { BrandContext } from "../../../../routes/PrivateRoute/BrandDashboardRoute";
import ReactStars from "react-rating-stars-component";

import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import CustomButton from "../../../../utils/customButton";
import {
  SubscriptionService,
  useSubscriptionState,
} from "../../../../Service/SubscriptionServices";

function CreatorProposalsForTask(props) {
  const { currentUser } = useAuth();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [filteredProposals, setFilteredProposals] = useState({});

  const [proposalRemove, setProposalRemove] = useState();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [modal, setModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [trafficLink, setTrafficLink] = useState("");
  const [denyModal, setDenyModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [influencerRate, setInfluencerRate] = useState(0);
  const [taskData, setTaskData] = useState(task);
  const [influencerBudget, setInfluencerBudget] = useState(0);
  const [utilized_budget, setUtilized_budget] = useState(0);
  const [totalBudget, setTotalBudget] = useState(0);
  const [demoModal, setDemoModal] = useState(false);
  const [proposalId, setProposalId] = useState(null);
  const { brandId } = useContext(BrandContext);
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);
  const [toggle4, setToggle4] = useState(false);

  const campaignState = useCampaignState();
  const subscriptionState = useSubscriptionState();
  const handleSortByStatus = () => {
    const sortedProposals = Object.values(task.proposals).sort((a, b) => {
      if (a.status === "approved") {
        return -1; // a should come before b
      } else if (b.status === "approved") {
        return 1; // b should come before a
      } else {
        return 0; // a and b have the same status, maintain their original order
      }
    });

    setSortBy(sortedProposals);
  };
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const handleToggle1 = () => {
    setToggle1((prevToggle) => !prevToggle);
  };

  useEffect(() => {
    // Convert the filteredProposals object into an array of [key, value] pairs
    const entries = Object.entries(filteredProposals);

    // Reverse the array of [key, value] pairs
    const reversedEntries = entries.reverse();

    // Convert the reversed entries back into an object
    const reversedProposals = Object.fromEntries(reversedEntries);

    // Update the state with the reversed object
    setFilteredProposals(reversedProposals);

    console.log("Reversed/Reset Proposals:", reversedProposals);
  }, [toggle1]);

  useEffect(() => {
    // Convert object to array of [key, value] pairs
    const filteredEntries = Object.entries(filteredProposals).filter(
      ([id, proposal]) => proposal.status !== "denied",
    );

    const sortedEntries = filteredEntries.sort(([idA, a], [idB, b]) => {
      const followerCountSumA =
        ((a.creator_socials &&
          a.creator_socials.instagram &&
          a.creator_socials.instagram.follower_count) ||
          0) +
        ((a.creator_socials &&
          a.creator_socials.tiktok &&
          a.creator_socials.tiktok.performance &&
          a.creator_socials.tiktok.performance.followerCount) ||
          0);

      const followerCountSumB =
        ((b.creator_socials &&
          b.creator_socials.instagram &&
          b.creator_socials.instagram.follower_count) ||
          0) +
        ((b.creator_socials &&
          b.creator_socials.tiktok &&
          b.creator_socials.tiktok.performance &&
          b.creator_socials.tiktok.performance.followerCount) ||
          0);

      return toggle2
        ? followerCountSumB - followerCountSumA
        : followerCountSumA - followerCountSumB;
    });

    // Convert back to object
    const sortedProposals = Object.fromEntries(sortedEntries);

    // Update the state with the sorted object
    setFilteredProposals(sortedProposals);

    console.log("Sorted Proposals:", sortedProposals);
  }, [toggle2]);

  useEffect(() => {
    // Convert object to array of [key, value] pairs
    const filteredEntries = Object.entries(filteredProposals).filter(
      ([id, proposal]) => proposal.status !== "denied",
    );

    const sortedEntries = filteredEntries.sort(([idA, a], [idB, b]) => {
      const instagramValueA = Math.floor(
        (a.creator_socials &&
          a.creator_socials.instagram &&
          a.creator_socials.instagram.median_plays) ||
          Math.floor(
            ((a.creator_socials &&
              a.creator_socials.instagram &&
              a.creator_socials.instagram.suggested_rate) ||
              0) / 2500,
          ) * 1000 ||
          0,
      );

      const tiktokValueA =
        (a.creator_socials &&
          a.creator_socials.tiktok &&
          a.creator_socials.tiktok.performance &&
          a.creator_socials.tiktok.performance.median_views) ||
        Math.round(
          (a.creator_socials &&
            a.creator_socials.tiktok &&
            a.creator_socials.tiktok.performance &&
            a.creator_socials.tiktok.performance.avg10_views) ||
            0,
        ) ||
        0;

      const instagramValueB = Math.floor(
        (b.creator_socials &&
          b.creator_socials.instagram &&
          b.creator_socials.instagram.median_plays) ||
          Math.floor(
            ((b.creator_socials &&
              b.creator_socials.instagram &&
              b.creator_socials.instagram.suggested_rate) ||
              0) / 2500,
          ) * 1000 ||
          0,
      );

      const tiktokValueB =
        (b.creator_socials &&
          b.creator_socials.tiktok &&
          b.creator_socials.tiktok.performance &&
          b.creator_socials.tiktok.performance.median_views) ||
        Math.round(
          (b.creator_socials &&
            b.creator_socials.tiktok &&
            b.creator_socials.tiktok.performance &&
            b.creator_socials.tiktok.performance.avg10_views) ||
            0,
        ) ||
        0;

      const valueA = instagramValueA + tiktokValueA;
      const valueB = instagramValueB + tiktokValueB;

      return toggle3 ? valueB - valueA : valueA - valueB;
    });

    // Convert back to object
    const sortedProposals = Object.fromEntries(sortedEntries);

    // Update the state with the sorted object
    setFilteredProposals(sortedProposals);

    console.log("Sorted Proposals:", sortedProposals);
  }, [toggle3]);

  useEffect(() => {
    // Convert object to array of [key, value] pairs
    const filteredEntries = Object.entries(filteredProposals).filter(
      ([id, proposal]) => proposal.status !== "denied",
    );

    const sortedEntries = filteredEntries.sort(([idA, a], [idB, b]) => {
      const ratingA =
        typeof a.average_rating === "number" ? a.average_rating : 0;
      const ratingB =
        typeof b.average_rating === "number" ? b.average_rating : 0;

      return toggle4 ? ratingB - ratingA : ratingA - ratingB;
    });

    // Convert back to object
    const sortedProposals = Object.fromEntries(sortedEntries);

    // Update the state with the sorted object
    setFilteredProposals(sortedProposals);
  }, [toggle4]);

  const handleToggle2 = () => {
    setToggle2((prevToggle) => !prevToggle);
  };

  const handleToggle3 = () => {
    setToggle3((prevToggle) => !prevToggle);
  };

  const handleToggle4 = () => {
    setToggle4((prevToggle) => !prevToggle);
  };

  const fetchData = async () => {
    if (id) {
      setLoading(true);
      try {
        const campaignData = await CampaignService.FetchSingleCampaign(id);
        setLoading(false);
        if (campaignData) {
          if (campaignData.utilized_budget) {
            setUtilized_budget(campaignData.utilized_budget);
          }
          setTotalBudget(campaignData.total_budget);
          setInfluencerBudget(campaignData.influencer_reach_budget);

          // After setting the budget states, update the filtered proposals
          const tasks = campaignData.tasks
            ? Object.entries(campaignData.tasks).map(([key, value]) => ({
                key,
                ...value,
              }))
            : [];
          const selectedTask = tasks.find(
            (task) => task.key === props.task.key,
          );
          if (
            selectedTask &&
            typeof selectedTask === "object" &&
            selectedTask.proposals &&
            Object.keys(selectedTask.proposals).length > 0
          ) {
            setFilteredProposals(selectedTask.proposals);
          } else {
            setFilteredProposals({});
            // Handle the case when selectedTask.proposals is empty
          }
        } else {
          // Handle the case when campaignData is not truthy
        }
      } catch (error) {
        console.log("Error loading campaign data:", error);
        // Handle any errors from the data retrieval
      }
    }
  };

  useEffect(() => {
    const fetchSubscription = async () => {
      await SubscriptionService.FetchSubscriptionForUser(brandId);
      console.log("subscription state: ", subscriptionState);
    };

    fetchSubscription();
  }, []);

  let subs = subscriptionState.subscription.value;
  console.log("subscription: ", subs);
  let availUsage = subs.currentUsage - subs.paidUsage;
  console.log("availUsage: ", availUsage);
  console.log("filteredProposals: ", filteredProposals);
  useEffect(() => {
    fetchData();
  }, [campaignState.updateNeed.value]);

  const handleButtonClick = (proposal, proposalKey, influencerRate) => {
    setModal(true);
    setProposalRemove(proposal);
    setProposalId(proposalKey);
    setInfluencerRate(influencerRate);
  };

  const handleSelection = (proposal) => {
    setDemoModal(true);
    setProposalRemove(proposal);
    console.log("proposal: ", proposal);
    console.log("demo modal", demoModal);
  };

  const approvedModal = (proposal, proposalKey, influencerRate) => {
    // Convert influencerRate to a number to ensure proper calculations

    // Calculate the total value based on the suggested rates from Instagram and TikTok
    const instagramRate =
      proposal.creator_socials &&
      proposal.creator_socials.instagram &&
      proposal.creator_socials.instagram.suggested_rate
        ? parseFloat(proposal.creator_socials.instagram.suggested_rate)
        : 0;

    const tiktokRate =
      proposal.creator_socials &&
      proposal.creator_socials.tiktok &&
      proposal.creator_socials.tiktok.performance &&
      proposal.creator_socials.tiktok.performance.suggestedRate
        ? parseFloat(proposal.creator_socials.tiktok.performance.suggestedRate)
        : 0;

    const totalCalculatedValue =
      parseInt(Math.floor(((instagramRate + tiktokRate) / 2500) * 3300)) +
      20000;

    console.log("totalCalculatedValue", totalCalculatedValue);
    console.log("utilized_budget", utilized_budget);
    console.log("totalBudget", totalBudget);

    // Calculate available usage based on the subscription data
    let availUsage = subs.paidUsage - subs.currentUsage;

    // Calculate the new budget after adding the influencer rate
    const newBudget = utilized_budget + influencerRate;

    // Check if availUsage is less than the influencer rate
    if (availUsage < influencerRate) {
      toast.error("Insufficient usage credits to approve this creator.");
      console.log("Insufficient usage credits to approve this creator.");
      return; // Exit the function early to prevent further processing
    }

    // Check if new budget exceeds the total budget or if the influencer rate exceeds the available usage

    // TODO: Commented out to make sure campaigns does not get halted by insufficient credits.
    // if (newBudget > totalBudget || influencerRate > availUsage) {
    if (influencerRate > availUsage) {
      toast.error(
        "The budget exceeds your available credits or allocated budget.",
      );
      console.log(
        "The budget exceeds your available credits or allocated budget.",
      );
      return; // Exit the function early to prevent further processing
    }

    // If all checks pass, proceed to open the approval modal
    setApproveModal(true);
    setProposalRemove(proposal);
    setProposalId(proposalKey);
    setInfluencerRate(influencerRate); // Set the number value
    if (selectedTask.traffic_link) {
      setTrafficLink(selectedTask.traffic_link);
    }
  };

  const deniedModal = (proposal, proposalKey, influencerRate) => {
    setDenyModal(true);
    setProposalRemove(proposal);
    setProposalId(proposalKey);
    setInfluencerRate(influencerRate);
  };

  const res = JSON.stringify(campaignState.campaign.value);
  const campaign = JSON.parse(res);
  const tasks =
    campaign && campaign.tasks
      ? Object.entries(campaign.tasks).map(([key, value]) => ({
          key,
          ...value,
        }))
      : [];
  const selectedTask = tasks.find((task) => task.key === props.task.key);

  console.log("filtered proposals:", filteredProposals);

  function formatNumber(number) {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    } else if (number >= 1000) {
      return (number / 1000).toFixed(1).replace(/\.0$/, "") + "k";
    } else {
      return number;
    }
  }

  const approvedProp = async (proposal, proposalKey) => {
    let new_proposal = {
      proposal_id: proposalKey,
      proposal: proposal,
      campaign_id: id,
      task_name: props.task.name,
      task_brief: props.task.talking_points,
    };
    const emailData = {
      brand_id: brandId,
      creator_id: proposal.creator_id,
      task_id: props.task.id,
      proposal: proposal,
      campaign_id: id,
      task_name: props.task.name,
      task_brief: props.task.talking_points,
    };

    try {
      if (proposal) {
        console.log("task id: " + proposal.task_id);
        await CampaignService.ApproveProposal(proposal.task_id, new_proposal);
        await CampaignEmailService.SendApprovalEmail(emailData);
        fetchData(); // Reloading the proposals
        const res = JSON.stringify(campaignState.campaign.value);
        const campaign = JSON.parse(res);
        setUtilized_budget(campaign.utilized_budget);
        toast.success("Proposal Approved Successfully");
      }
    } catch (error) {
      toast.error("An error occurred while approving the proposal.");
    }
  };

  const task = props.task;

  const deniedProp = async (proposal, proposalKey) => {
    console.log("task id: " + proposal.task_id);
    console.log("key: " + proposalKey);
    const data = {
      campaign_id: id,
      creator_id: proposal.creator_id,
      task_id: proposal.task_id,
      proposal_id: proposalKey,
    };

    try {
      await CampaignService.DenyProposal(proposal.task_id, proposalKey, data);
      fetchData();
      await CampaignService.FetchSingleCampaign(id);
      toast.error("Proposal Denied");
    } catch (error) {}
  };

  const removeInfluencer = async (proposal, proposalKey, influencerRate) => {
    const data = {
      campaign_id: id,
      creator_id: proposal.creator_id,
      task_id: proposal.task_id,
      brand_id: brandId,
      proposal_id: proposalKey,
      influencer_rate: influencerRate,
    };

    try {
      await CampaignService.RemoveInfluencer(proposalKey, data);
      fetchData();
      await CampaignService.FetchSingleCampaign(id);
      toast.success("Influencer Removed");
    } catch (error) {
      toast.error("An error occurred while removing the influencer.");
    }
  };

  if (
    selectedTask &&
    selectedTask.approved_proposals &&
    typeof selectedTask.approved_proposals === "object"
  ) {
    const approvedProposalsCount = Object.keys(
      selectedTask.approved_proposals,
    ).length;
    console.log(approvedProposalsCount);
  } else {
    console.log("Approved proposals not found or not an object.");
  }

  const approvedProposalsCount =
    selectedTask && selectedTask.approved_proposals
      ? Object.keys(selectedTask.approved_proposals).length
      : 0;

  let instagramTotalReach = 0;
  let tiktokTotalReach = 0;

  if (
    selectedTask &&
    selectedTask.approved_proposals &&
    typeof selectedTask.approved_proposals === "object"
  ) {
    const approvedProposals = selectedTask.approved_proposals;

    for (const creatorId in approvedProposals) {
      if (approvedProposals.hasOwnProperty(creatorId)) {
        const proposals = approvedProposals[creatorId];

        for (const proposalKey in proposals) {
          if (proposals.hasOwnProperty(proposalKey)) {
            const proposal = proposals[proposalKey];

            if (proposal && proposal.creator_socials) {
              const { instagram, tiktok } = proposal.creator_socials;

              if (instagram && instagram.suggested_rate) {
                let ig_views = parseInt(
                  (instagram.suggested_rate / 2500) * 1000,
                );
                instagramTotalReach += ig_views;
              }

              if (
                tiktok &&
                tiktok.performance &&
                tiktok.performance.suggestedRate
              ) {
                let tt_views = parseInt(
                  (tiktok.performance.suggestedRate / 2500) * 1000,
                );
                tiktokTotalReach += tt_views;
              }
            }
          }
        }
      }
    }
  } else {
    console.log("Approved proposals not found or not an object.");
  }

  let totalSuggestedRate = 0;

  if (
    selectedTask &&
    selectedTask.approved_proposals &&
    typeof selectedTask.approved_proposals === "object"
  ) {
    const approvedProposals = task.approved_proposals;

    for (const creatorId in approvedProposals) {
      if (approvedProposals.hasOwnProperty(creatorId)) {
        const proposals = approvedProposals[creatorId];

        for (const proposalKey in proposals) {
          if (proposals.hasOwnProperty(proposalKey)) {
            const proposal = proposals[proposalKey];

            if (proposal && proposal.creator_socials) {
              const { instagram, tiktok } = proposal.creator_socials;

              if (instagram && instagram.follower_count) {
                if (instagram.suggested_rate) {
                  totalSuggestedRate += instagram.suggested_rate;
                }
              }

              if (
                tiktok &&
                tiktok.performance &&
                tiktok.performance.followerCount
              ) {
                if (tiktok.performance.suggestedRate) {
                  totalSuggestedRate += tiktok.performance.suggestedRate;
                }
              }
            }
          }
        }
      }
    }

    console.log(totalSuggestedRate.toFixed(2) / 100);
  } else {
    console.log("Approved proposals not found or not an object.");
  }

  const formattedBudget = (
    Math.floor((((totalSuggestedRate / 2500) * 3300) / 100) * 100) / 100
  ).toFixed(2);

  //FILTERING FUNCTIONS

  const handleFilterByFollowerCount = () => {
    // Toggle the filter criteria
    setToggle1(!toggle1);

    // Filter the proposals based on the chosen criteria and toggle
    const filteredByFollowerCount = selectedTask.proposals.filter(
      (proposal) => {
        // Add your logic to filter by highest follower count
        // Return true for proposals that meet the filter criteria
      },
    );

    // Apply ascending or descending order based on the toggle state
    const sortedProposals = toggle1
      ? filteredByFollowerCount.sort(/* ascending order logic */)
      : filteredByFollowerCount.sort(/* descending order logic */);

    // Update the filteredProposals state
    setFilteredProposals(sortedProposals);
  };

  return (
    <div className="m-4 bg-white h-full  w-full p-4 rounded-lg z-100">
      <button
        className="flex flex-row right-0 top-0 font-bold text-base"
        onClick={props.onClose}
      >
        <BsArrowLeft className="mx-3 mt-0.5" />
        Back{" "}
      </button>
      <Toaster />
      {loading ? (
        <div className="flex items-center h-full justify-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="mt-3 w-full mx-auto mb-2 text-base">
            <div className="flex shadow bg-gray-700 rounded-t items-center">
              <h3 className="font-bold  mt-2 ml-6 text-center text-white h-7 2xl:text-base md:text-sxs">
                Task: {selectedTask.name}
              </h3>
            </div>

            <div className="grid grid-cols-3">
              <div className="bg-gray-100 rounded-l-md shadow py-1 h-auto 2xl:h-7rem md:h-2rem flex items-center justify-center">
                <div className="text-center font-bold">
                  <span className="text-red 2xl:text-base md:text-sxs">
                    {approvedProposalsCount}/
                    {selectedTask.number_of_videos || campaign.number_of_videos}
                  </span>
                  <h1 className="2xl:text-base md:text-sxs ">
                    Current Influencers Selected for Campaign
                  </h1>
                </div>
              </div>

              <div className="bg-gray-100 shadow py-1 h-auto 2xl:h-7rem md:h-2rem flex items-center justify-center">
                <div className="text-center font-bold">
                  <div className="flex items-center justify-center">
                    <span className="text-red-dark 2xl:text-base md:text-sxs flex items-center">
                      {instagramTotalReach > 0 && (
                        <img
                          className="2xl:w-5 2xl:h-5 md:w-3 md:h-3 mx-1"
                          src={instagram}
                          alt="instagram"
                        />
                      )}
                      {tiktokTotalReach > 0 && (
                        <img
                          className="2xl:w-5 2xl:h-5 md:w-3 md:h-3 mx-1"
                          src={tiktok}
                          alt="tiktok"
                        />
                      )}
                      {formatNumber(tiktokTotalReach + instagramTotalReach)} /{" "}
                      {formatNumber(campaign.target_viewership) ||
                        formatNumber((influencerBudget / 3300) * 1000)}
                    </span>
                  </div>
                  <h1 className="2xl:text-base md:text-sxs mt-1">
                    Current Expected Viewership
                  </h1>
                </div>
              </div>

              <div className="bg-gray-100 rounded-r-md shadow py-1 h-auto 2xl:h-7rem md:h-2rem flex items-center justify-center">
                <div className="text-center font-bold">
                  <span className="text-red-dark 2xl:text-base md:text-sxs">
                    {parseInt((utilized_budget / influencerBudget) * 100) ||
                      parseInt((utilized_budget / campaign.total_budget) * 100)}
                    %
                  </span>
                  <h1 className="2xl:text-base md:text-sxs ">
                    Current Budget Utilization
                  </h1>
                </div>
              </div>
            </div>

            <div className="mx-auto flex flex-col items-center mb-2 w-full">
              <div className="flex flex-row justify-center w-full">
                <div className="flex flex-row items-center ml-auto">
                  <h1 className="font-black 2xl:text-2xl md:text-0.7 my-4 text-center ml-24">
                    Total Budget
                  </h1>
                  <div className="relative items-center group ml-4 my-4">
                    <span className=" hover:text-black">
                      <BsQuestionCircle className="" />
                      <div
                        className="absolute flex border border-gray-400 top-0 right-0 transform translate-x-full -translate-y-full w-20rem h-fit bg-gray-200 text-gray-800 py-2 px-2 rounded opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto"
                        style={{ lineHeight: "1.5", letterSpacing: "0.1em" }}
                      >
                        <span className="font-bold inline-flex text-xs">
                          This feature allows you to track your spending
                          progress to ensure you stay within your allocated
                          budget for the campign.
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
                <div className="ml-auto mt-2">
                  <button
                    className="border-2 2xl:px-9 2xl:py-2 md:px-5 md:py-1 rounded-t-md 2xl:text-base md:text-0.7 bg-gray-100 text-black hover:bg-gray-200"
                    onClick={toggleDropdown}
                  >
                    Sort
                  </button>
                  {showDropdown && (
                    <div className="absolute right-8 z-10 bg-white border border-gray-300 rounded-md shadow-lg">
                      {/* Dropdown content goes here */}
                      <ul className="p-0">
                        <li
                          className="border-b border-gray-300 flex items-center justify-between 2xl:px-4 2xl:py-2 md:px-2 md:py-1 hover:bg-gray-100 cursor-pointer w-full"
                          onClick={handleToggle1}
                        >
                          <span className="text-base">Date</span>
                          <span>
                            {toggle1 ? <BsChevronDown /> : <BsChevronUp />}
                          </span>
                        </li>
                        <li
                          className="border-b border-gray-300 flex items-center justify-between 2xl:px-4 2xl:py-2 md:px-2 md:py-1 hover:bg-gray-100 cursor-pointer w-full"
                          onClick={handleToggle2}
                        >
                          <span className="text-base">Followers</span>
                          <span>
                            {toggle2 ? <BsChevronDown /> : <BsChevronUp />}
                          </span>
                        </li>
                        <li
                          className="border-b border-gray-300 flex items-center justify-between 2xl:px-4 2xl:py-2 md:px-2 md:py-1 hover:bg-gray-100 cursor-pointer w-full"
                          onClick={handleToggle3}
                        >
                          <span className="text-base">Rate</span>
                          <span>
                            {toggle3 ? <BsChevronDown /> : <BsChevronUp />}
                          </span>
                        </li>
                        <li
                          className="border-b border-gray-300 flex items-center justify-between 2xl:px-4 2xl:py-2 md:px-2 md:py-1 hover:bg-gray-100 cursor-pointer w-full"
                          onClick={handleToggle4}
                        >
                          <span className="text-base">Creator Rating</span>
                          <span className="ml-2">
                            {toggle4 ? <BsChevronDown /> : <BsChevronUp />}
                          </span>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full bg-gray-400 rounded-full relative">
                <div
                  className="absolute top-0 left-0 h-full bg-red-dark rounded-full"
                  style={{
                    width: `${Math.min(
                      100,
                      influencerBudget !== totalBudget
                        ? ((utilized_budget / 100 +
                            (totalBudget - influencerBudget) / 100) /
                            parseFloat(totalBudget / 100).toFixed(2)) *
                            100
                        : (utilized_budget / totalBudget) * 100,
                    )}%`,
                    zIndex: 1,
                  }}
                ></div>
                <div
                  className="flex justify-center items-center h-full text-white"
                  style={{ position: "relative", zIndex: 2 }}
                >
                  <span className="font-bold tracking-widest 2xl:text-base md:text-sxs">
                    {influencerBudget !== totalBudget
                      ? `${parseInt(
                          utilized_budget / 100,
                        ).toLocaleString()} / ${parseInt(
                          totalBudget / 100,
                        ).toLocaleString()} credits used`
                      : `${parseInt(
                          utilized_budget / 100,
                        ).toLocaleString()} / ${parseInt(
                          totalBudget / 100,
                        ).toLocaleString()} credits used`}
                  </span>
                </div>
              </div>
            </div>
            {/** <div className='flex px-5 justify-end top-0 right-0 mr-6 mt-6'>
                      <button onClick = {toggleDropdown} className='px-5 rounded-md border-2 inline-block'>
                        <span className=''>Filter</span>
                      </button>
                      {dropdownVisible && (
                        <div className='absolute bg-white border rounded-b-md mt-6'>
                        <ul className='px-3'>
                          <li className='border-b'><button className='px-4 mb-2 mt-1 w-full' onClick={handleSortByStatus}>Approved</button></li>
                          <li className=''><button className='px-4 mb-2 mt-1 w-full' onClick={handleSortByStatus}>Pending</button></li>
                        </ul>
                      </div>)}

                    </div> */}
            <div
              className="overflow-y-auto mt-5 2xl:text-base md:text-0.7 rounded-md"
              style={{ height: "70vh" }}
            >
              {Object.entries(filteredProposals).map(
                ([proposalKey, proposal]) => {
                  proposal = filteredProposals[proposalKey];
                  console.log("Proposal: ", proposal)
                  let influencerRate =
                    parseInt(
                      Math.floor(
                        ((proposal.creator_socials &&
                        proposal.creator_socials.instagram &&
                        task.platform.includes("Instagram") &&
                        proposal.creator_socials.instagram.suggested_rate !==
                          undefined
                          ? parseFloat(
                              proposal.creator_socials.instagram.suggested_rate,
                            )
                          : 0) /
                          2500) *
                          3300 +
                          ((proposal.creator_socials &&
                          proposal.creator_socials.tiktok &&
                          task.platform.includes("TikTok") &&
                          proposal.creator_socials.tiktok.performance &&
                          proposal.creator_socials.tiktok.performance
                            .suggestedRate !== undefined
                            ? parseFloat(
                                proposal.creator_socials.tiktok.performance
                                  .suggestedRate,
                              )
                            : 0) /
                            2500) *
                            3300,
                      ) / 100,
                    ) + 200;
                  return (
                    <div>
                      {proposal.status !== "denied" && (
                        <div className="mr-4 grid grid-cols-2 border-b-2 my-3">
                          <div className="flex flex-col flex-wrap items-start mt-2 2xl:h-fit md:h-fit ">
                            <div className="flex flex-row my-0.5 ">
                              <div className="mr-4">
                                <div className="flex flex-row flex-wrap items-start mt-2 2xl:h-fit md:h-fit">
                                  <img
                                    className="2xl:w-20 2xl:h-20 md:w-3em md:h-3rem ml-2 border-2 rounded object-cover"
                                    src={proposal.creator_photo || logo}
                                    alt="avatar"
                                  />
                                  <div className="ml-6">
                                    <p className="2xl:text-base md:text-0.65">
                                      <span className="font-bold">
                                        {proposal.creator_address &&
                                          proposal.creator_address.fullname}
                                      </span>{" "}
                                      &nbsp;
                                      <span className="italic text-gray-500">
                                        {proposal.creator_address &&
                                          proposal.creator_address.city}
                                        ,{" "}
                                        {proposal.creator_address &&
                                          proposal.creator_address.state}
                                        ,{" "}
                                        {proposal.creator_address &&
                                          proposal.creator_address.country}
                                      </span>
                                      <div>
                                        {typeof proposal.average_rating ===
                                        "number" ? (
                                          <div className="flex flex-row items-center">
                                            <span className="mr-2 font-bold">
                                              {proposal.average_rating.toFixed(
                                                1,
                                              )}
                                            </span>
                                            <ReactStars
                                              count={5}
                                              isHalf={true}
                                              value={proposal.average_rating.toFixed(
                                                1,
                                              )}
                                              size={24}
                                              edit={false}
                                              activeColor="#FFD700"
                                            />
                                          </div>
                                        ) : (
                                          <p className="2xl:text-0.8 md:text-sxs text-white px-2 rounded-md my-2 w-fit  py-1 bg-red">
                                            {" "}
                                            NEW{" "}
                                          </p>
                                        )}
                                      </div>
                                    </p>
                                    <div className="flex flex-row items-center mt-4 space-x-4">
                                      <BsFillPeopleFill
                                        title="Followers"
                                        className="2xl:w-5 2xl:h-5 md:w-3 md:h-3"
                                      />

                                      {task.platform.includes("Instagram") &&
                                        proposal.creator_socials &&
                                        proposal.creator_socials.instagram &&
                                        proposal.creator_socials.instagram
                                          .handle && (
                                          <div className="flex items-center space-x-2">
                                            <a
                                              href={`https://instagram.com/${proposal.creator_socials.instagram.handle}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <img
                                                className="2xl:w-5 2xl:h-5 md:w-3 md:h-3"
                                                src={instagram}
                                                alt="instagram"
                                              />
                                            </a>
                                            {proposal.creator_socials.instagram
                                              .follower_count && (
                                              <span
                                                title="Follower count (Instagram)"
                                                className="2xl:text-base md:text-0.65 font-bold"
                                              >
                                                {formatNumber(
                                                  proposal.creator_socials
                                                    .instagram.follower_count,
                                                )}
                                              </span>
                                            )}
                                          </div>
                                        )}

                                      {task.platform.includes("TikTok") &&
                                        proposal.creator_socials &&
                                        proposal.creator_socials.tiktok &&
                                        proposal.creator_socials.tiktok
                                          .performance &&
                                        proposal.creator_socials.tiktok
                                          .performance.followerCount && (
                                          <div className="flex items-center space-x-2">
                                            <a
                                              href={`https://tiktok.com/@${proposal.creator_socials.tiktok.handle}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <img
                                                className="2xl:w-5 2xl:h-5 md:w-3 md:h-3"
                                                src={tiktok}
                                                alt="tiktok"
                                              />
                                            </a>
                                            <span
                                              title="Follower count (TikTok)"
                                              className="2xl:text-base md:text-0.65 font-bold cursor-pointer"
                                            >
                                              {formatNumber(
                                                proposal.creator_socials.tiktok
                                                  .performance.followerCount,
                                              )}
                                            </span>
                                          </div>
                                        )}

                                      <div className="flex items-center space-x-2">
                                        <span className="flex items-center">
                                          <BsEyeFill
                                            title="Expected Views"
                                            className="2xl:w-5 2xl:h-5 md:w-3 md:h-3"
                                          />
                                        </span>

                                        {task.platform.includes("Instagram") &&
                                          proposal.creator_socials &&
                                          proposal.creator_socials.instagram &&
                                          proposal.creator_socials.instagram
                                            .handle && (
                                            <div className="flex items-center space-x-2">
                                              <a
                                                href={`https://instagram.com/${proposal.creator_socials.instagram.handle}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                <img
                                                  className="2xl:w-5 2xl:h-5 md:w-3 md:h-3"
                                                  src={instagram}
                                                  alt="instagram"
                                                />
                                              </a>
                                              {proposal.creator_socials
                                                .instagram.suggested_rate && (
                                                <span
                                                  title="Expected Views (Instagram)"
                                                  className="2xl:text-base md:text-0.65 font-bold"
                                                >
                                                  {formatNumber(
                                                    Math.floor(
                                                      proposal.creator_socials
                                                        .instagram.median_plays,
                                                    ) ||
                                                      formatNumber(
                                                        Math.floor(
                                                          (proposal
                                                            .creator_socials
                                                            .instagram
                                                            .suggested_rate /
                                                            2500) *
                                                            1000,
                                                        ),
                                                      ),
                                                  )}
                                                </span>
                                              )}
                                            </div>
                                          )}

                                        {task.platform.includes("TikTok") &&
                                          proposal.creator_socials &&
                                          proposal.creator_socials.tiktok &&
                                          proposal.creator_socials.tiktok
                                            .handle &&
                                          proposal.creator_socials.tiktok
                                            .performance &&
                                          proposal.creator_socials.tiktok
                                            .performance.followerCount && (
                                            <div className="flex items-center space-x-2">
                                              <a
                                                href={`https://tiktok.com/@${proposal.creator_socials.tiktok.handle}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                <img
                                                  className="2xl:w-5 2xl:h-5 md:w-3 md:h-3"
                                                  src={tiktok}
                                                  alt="tiktok"
                                                />
                                              </a>
                                              <span
                                                title="Expected Views (TikTok)"
                                                className="ml-2 font-bold 2xl:text-base md:text-0.65 cursor-pointer"
                                              >
                                                {formatNumber(
                                                  proposal.creator_socials
                                                    .tiktok.performance
                                                    .median_views ||
                                                    Math.round(
                                                      proposal.creator_socials
                                                        .tiktok.performance
                                                        .avg10_views,
                                                    ),
                                                )}
                                              </span>
                                            </div>
                                          )}
                                      </div>

                                      <div className="ml-8">
                                        <Link
                                          to={`/profile/${proposal.creator_id}`}
                                          target="_blank"
                                          className="bg-Blue text-white px-2 py-1 rounded-lg outline-none 2xl:text-xs md:text-sxs"
                                        >
                                          <span className="text-white">
                                            View Portfolio + Reviews
                                          </span>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-row items-center space-x-4 ml-2 2xl:mt-2 md:mt-0">
                              <p className="2xl:text-base md:text-0.65">
                                Influencer Rate:
                              </p>
                              <div
                                className="text-white ml-1 bg-gray-700 px-4 rounded-xl font-bold 2xl:text-base md:text-sxs"
                                title="Influencer Rate (Instagram)"
                              >
                                {influencerRate.toLocaleString()} credits
                              </div>
                              <div className="relative items-center group ml-4 my-4">
                                <span className=" hover:text-black">
                                  <BsQuestionCircle className="" />
                                  <div
                                    className="absolute border border-gray-400 top-0 right-0 transform translate-x-full -translate-y-full w-20rem h-fit bg-gray-200 text-gray-800 py-2 px-2 rounded opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto"
                                    style={{
                                      lineHeight: "1.5",
                                      letterSpacing: "0.1em",
                                    }}
                                  >
                                    <div className="text-xs">
                                      Reach/views ($33 CPM):
                                      <span className="font-bold inline-flex ml-2 text-xs">
                                        {" "}
                                        {parseInt(
                                          Math.floor(
                                            ((proposal.creator_socials &&
                                            proposal.creator_socials
                                              .instagram &&
                                            task.platform.includes(
                                              "Instagram",
                                            ) &&
                                            proposal.creator_socials.instagram
                                              .suggested_rate !== undefined
                                              ? parseFloat(
                                                  proposal.creator_socials
                                                    .instagram.suggested_rate,
                                                )
                                              : 0) /
                                              2500) *
                                              3300 +
                                              ((proposal.creator_socials &&
                                              proposal.creator_socials.tiktok &&
                                              task.platform.includes(
                                                "TikTok",
                                              ) &&
                                              proposal.creator_socials.tiktok
                                                .performance &&
                                              proposal.creator_socials.tiktok
                                                .performance.suggestedRate !==
                                                undefined
                                                ? parseFloat(
                                                    proposal.creator_socials
                                                      .tiktok.performance
                                                      .suggestedRate,
                                                  )
                                                : 0) /
                                                2500) *
                                                3300,
                                          ) / 100,
                                        ).toLocaleString()}{" "}
                                        credits
                                      </span>
                                    </div>
                                    <div className="font-thin inline-flex text-xs">
                                      Video Assets + Usage Rights:{" "}
                                      <span className="font-bold text-0.7 ml-2">
                                        200 credits
                                      </span>
                                    </div>
                                  </div>
                                </span>
                              </div>
                            </div>

                            <div className="2xl:mt-4 md:mt-0 ml-2">
                              <p className="2xl:text-base md:text-0.65">
                                {proposal.proposal}
                              </p>
                            </div>

                            <div className="mt-2.5 ml-2 mb-2">
                              {proposal.status === "approved" ? (
                                <div className="flex flex-row">
                                  <button className="bg-green cursor-default text-white 2xl:px-4 2xl:py-2 md:px-2 md:py-1 rounded-md flex items-center justify-center mr-5 2xl:text-base md:text-0.65">
                                    Approved
                                  </button>
                                  <button
                                    onClick={() =>
                                      handleButtonClick(
                                        proposal,
                                        proposalKey,
                                        influencerRate,
                                      )
                                    }
                                    title="remove influencer from campaign"
                                    className="bg-red text-white 2xl:px-4 2xl:py-2 md:px-2 md:py-1 rounded-lg flex items-center justify-center"
                                  >
                                    <SlUserUnfollow className="font-bold 2xl:w-5 2xl:h-5 md:w-3 md:h-3" />
                                  </button>
                                </div>
                              ) : (
                                <>
                                  <CustomButton
                                    onClick={() =>
                                      approvedModal(
                                        proposal,
                                        proposalKey,
                                        influencerRate,
                                      )
                                    }
                                    className=" text-white 2xl:px-4 2xl:py-2 md:px-2 md:py-1 mr-8 2xl:text-base md:text-sxs"
                                  >
                                    Approve
                                  </CustomButton>
                                  <CustomButton
                                    onClick={() =>
                                      deniedModal(
                                        proposal,
                                        proposalKey,
                                        influencerRate,
                                      )
                                    }
                                    className=" text-white 2xl:px-4 2xl:py-2 md:px-2 md:py-1 mr-8 2xl:text-base md:text-sxs"
                                  >
                                    Deny
                                  </CustomButton>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="flex flex-col my-2">
                            {proposal &&
                            proposal.creator_socials &&
                            proposal.creator_socials.instagram &&
                            proposal.creator_socials.instagram.demographics ? (
                              <div className="justify-end w-fit rounded-md h-fit flex 2xl:text-base md:text-xs">
                                <div className="w-fit flex flex-col mt-2">
                                  <h1 className="font-bold text-gray-600 text-base text-center ml-2 mb-2">
                                    Audience Data
                                  </h1>
                                  <span className="text-center text-gray-400 italic">
                                    Top 5 entries are shown, click{" "}
                                    <button
                                      onClick={() => handleSelection(proposal)}
                                      className="font-bold underline"
                                    >
                                      here
                                    </button>{" "}
                                    to view more
                                  </span>
                                  <div className="flex mx-auto">
                                    <div className="w-full mx-3 md:w-1/3">
                                      <h1 className="font-bold text-center 2xl:text-base md:text-xs">
                                        Gender{" "}
                                      </h1>
                                      <div className=" flex flex-col ">
                                        <div className="flex flex-row items-center">
                                          <IoMdMale
                                            title="Male"
                                            className="mr-2 w-5 h-4 text-blue"
                                          />{" "}
                                          <span className="font-bold mr-2">
                                            M
                                          </span>
                                          <div className="w-full bg-gray-300 rounded-lg my-2">
                                            <div
                                              className="bg-Blue rounded-lg"
                                              style={{
                                                width: `${parseInt(
                                                  (proposal.creator_socials
                                                    .instagram.demographics
                                                    .gender.M /
                                                    (proposal.creator_socials
                                                      .instagram.demographics
                                                      .gender.F +
                                                      proposal.creator_socials
                                                        .instagram.demographics
                                                        .gender.M)) *
                                                    100,
                                                )}%`,
                                                height: "5px",
                                              }}
                                            />
                                          </div>
                                          <div
                                            className="ml-2"
                                            style={{ width: "50px" }}
                                          >
                                            {parseInt(
                                              (proposal.creator_socials
                                                .instagram.demographics.gender
                                                .M /
                                                (proposal.creator_socials
                                                  .instagram.demographics.gender
                                                  .F +
                                                  proposal.creator_socials
                                                    .instagram.demographics
                                                    .gender.M)) *
                                                100,
                                            )}
                                            %
                                          </div>
                                        </div>
                                        <div className="flex flex-row items-center">
                                          <IoMdFemale
                                            title="Female"
                                            className="mr-2 w-5 h-4 text-red-coin"
                                          />{" "}
                                          <span className="font-bold mr-3">
                                            F
                                          </span>
                                          <div className="w-full bg-gray-300 rounded-lg my-2">
                                            <div
                                              className="bg-Blue rounded-lg"
                                              style={{
                                                width: `${parseInt(
                                                  (proposal.creator_socials
                                                    .instagram.demographics
                                                    .gender.F /
                                                    (proposal.creator_socials
                                                      .instagram.demographics
                                                      .gender.F +
                                                      proposal.creator_socials
                                                        .instagram.demographics
                                                        .gender.M)) *
                                                    100,
                                                )}%`,
                                                height: "5px",
                                              }}
                                            />
                                          </div>
                                          <div
                                            className="ml-2"
                                            style={{ width: "50px" }}
                                          >
                                            {parseInt(
                                              (proposal.creator_socials
                                                .instagram.demographics.gender
                                                .F /
                                                (proposal.creator_socials
                                                  .instagram.demographics.gender
                                                  .F +
                                                  proposal.creator_socials
                                                    .instagram.demographics
                                                    .gender.M)) *
                                                100,
                                            )}
                                            %
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex-none w-full mx-3 md:w-1/3">
                                      <h1 className="font-bold text-center ml-10 2xl:text-base md:text-xs">
                                        Age{" "}
                                      </h1>
                                      <div className="flex flex-wrap mt-0.5">
                                        {Object.entries(
                                          proposal.creator_socials.instagram
                                            .demographics.age,
                                        )
                                          .sort((a, b) => b[1] - a[1]) // Sort by value in descending order
                                          .slice(0, 5) // Take the first 5 entries
                                          .map(([ageRange, value]) => (
                                            <div
                                              key={ageRange}
                                              className="flex items-center w-full mb-2"
                                            >
                                              <div className="inline w-24 font-bold">
                                                {ageRange}:
                                              </div>
                                              <div className="flex items-center w-full">
                                                <div className="w-full bg-gray-300 rounded-lg">
                                                  <div
                                                    className="bg-Blue rounded-lg"
                                                    style={{
                                                      width: `${(
                                                        (value /
                                                          Object.values(
                                                            proposal
                                                              .creator_socials
                                                              .instagram
                                                              .demographics.age,
                                                          ).reduce(
                                                            (a, b) => a + b,
                                                            0,
                                                          )) *
                                                        100
                                                      ).toFixed(2)}%`,
                                                      height: "5px",
                                                    }}
                                                  />
                                                </div>
                                                <div
                                                  className="ml-2"
                                                  style={{ width: "50px" }}
                                                >
                                                  {(
                                                    (value /
                                                      Object.values(
                                                        proposal.creator_socials
                                                          .instagram
                                                          .demographics.age,
                                                      ).reduce(
                                                        (a, b) => a + b,
                                                        0,
                                                      )) *
                                                    100
                                                  ).toFixed(2)}
                                                  %
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                      </div>
                                    </div>
                                    <div className="flex-none w-full  mx-3 md:w-1/3">
                                      <h1 className="font-bold text-center 2xl:text-base md:text-xs">
                                        State{" "}
                                      </h1>
                                      <div className="flex flex-wrap">
                                        {proposal.creator_socials &&
                                        proposal.creator_socials.instagram &&
                                        proposal.creator_socials.instagram
                                          .demographics &&
                                        proposal.creator_socials.instagram
                                          .demographics.states
                                          ? Object.entries(
                                              proposal.creator_socials.instagram
                                                .demographics.states,
                                            )
                                              .sort(
                                                (a, b) =>
                                                  b[1].total - a[1].total,
                                              ) // Sorting by total in descending order
                                              .slice(0, 5) // Getting the top 5 entries
                                              .map(([state, value]) => (
                                                <div
                                                  key={state}
                                                  className="flex items-center w-full mb-2"
                                                >
                                                  <div className="inline  font-bold 2xl:w-1/2 md:w-2/3 2xl:text-base md:text-xs">
                                                    {state.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase())}:
                                                  </div>
                                                  <div className="w-full bg-gray-300 rounded-lg">
                                                    <div
                                                      className="bg-Blue rounded-lg"
                                                      style={{
                                                        width: `${(
                                                          (value.total /
                                                            Object.values(
                                                              proposal
                                                                .creator_socials
                                                                .instagram
                                                                .demographics
                                                                .states,
                                                            ).reduce(
                                                              (a, b) =>
                                                                a + b.total,
                                                              0,
                                                            )) *
                                                          100
                                                        ).toFixed(2)}%`,
                                                        height: "5px",
                                                      }}
                                                    />
                                                  </div>
                                                  <div
                                                    className="ml-2"
                                                    style={{ width: "50px" }}
                                                  >
                                                    {(
                                                      (value.total /
                                                        Object.values(
                                                          proposal
                                                            .creator_socials
                                                            .instagram
                                                            .demographics
                                                            .states,
                                                        ).reduce(
                                                          (a, b) => a + b.total,
                                                          0,
                                                        )) *
                                                      100
                                                    ).toFixed(2)}
                                                    %
                                                  </div>
                                                </div>
                                              ))
                                          : "State Data Not Available"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="text-center flex items-center justify-center">
                                Demographics data unavailable
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                },
              )}
            </div>
          </div>
        </>
      )}

      {modal ? (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center h-full w-full items-center z-50 bg-black bg-opacity-10">
          <InfluencerRemovalModal
            proposal={proposalRemove}
            proposal_id={proposalId}
            campaign_id={id}
            influencerRate={influencerRate}
            task_id={proposalRemove.task_id}
            brand_id={brandId}
            close={() => setModal(false)}
            handleRemove={removeInfluencer}
            fetchData={fetchData}
          />
        </div>
      ) : null}

      {approveModal ? (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center h-full w-full items-center z-50 bg-black bg-opacity-10">
          <ApproveInfluencerModal
            proposal={proposalRemove}
            proposal_id={proposalId}
            campaign_id={id}
            campaign={campaign}
            influencerRate={influencerRate}
            task_id={proposalRemove.task_id}
            task={task}
            name={task.name}
            trafficLink={trafficLink}
            brand_id={brandId}
            close={() => setApproveModal(false)}
            handleRemove={removeInfluencer}
            fetchData={fetchData}
          />
        </div>
      ) : null}

      {denyModal ? (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center h-full w-full items-center z-50 bg-black bg-opacity-10">
          <DenyInfluencerModal
            proposal={proposalRemove}
            proposal_id={proposalId}
            campaign_id={id}
            influencerRate={influencerRate}
            campaign={campaign}
            task_id={proposalRemove.task_id}
            name={task.name}
            task={task}
            brand_id={brandId}
            close={() => setDenyModal(false)}
            handleRemove={removeInfluencer}
            fetchData={fetchData}
          />
        </div>
      ) : null}

      {demoModal ? (
        <ViewDemographicsModal
          proposal={proposalRemove}
          open={demoModal}
          onClose={() => setDemoModal(false)}
        />
      ) : null}
    </div>
  );
}

export default CreatorProposalsForTask;
