import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Route from './routes';

function App() {
  return (
    <div>
      <Router>
        <Route />
      </Router>
    </div>
  );
}

export default App;
