import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';
import { getRandomValues } from 'crypto';

//State
const state = createState({
  retailCampaign: [],
  retrieving: false,
  fetched: false,
  loading: true,
  updateNeed: true
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'RETAIL_CAMPAIGN_LOADED':
        return s.merge({
          retailCampaign: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true
        });

      case 'SEND_RETAIL_ENROLLMENT_EMAIL': 
        return s.merge({
          retailCampaign: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true
        })
      
      case 'RETAIL_CAMPAIGN_AMPLIFY':
        return s.merge({
          retailCampaign: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true
        })
      
      case 'ENROLL_CUSTOMER_INTO_CAMPAIGN':
        return s.merge({
         // retailCampaign: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true
        })

      case 'REGISTER_CUSTOMER':
        return s.merge({
          //retailCampaign: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true
        })
      default:
        return null;
    }
  }, action.type);
});

export const accessRetailCampaignState = () => state;

export const useRetailCampaignState = () => useState(state);

//Service
export const RetailCampaignService = {

  registerCustomer: (data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/retail/registerCustomer`, data)
      .then((res) => {
        const result = res.data;
        dispatch(RetailCampaignAction.registerCustomer(result));
      })
      .catch((err) => {
        // handle the error
      });
    },


  enrollCustomerIntoCampaign: (data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/retail/enrollCustomerIntoCampaign`, data)
      .then((res) => {
        const result = res.data;
        dispatch(RetailCampaignAction.enrollCustomerIntoCampaign(result));
      })
      .catch((err) => {
        // handle the error
      });
    },

   getRetailCampaign : (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/retail/getRetailCampaign/${id}`)
      .then((res) => {
        const result = res.data.result;
        dispatch(RetailCampaignAction.getRetailCampaign(result));
      })
      .catch((err) => {
        // handle the error
      });
  },

  sendRetailEnrollmentEmail : (data) => {
      const dispatch = useDispatch();
      axios
        .post(`${baseUrl}api/email/sendRetailEnrollmentEmail`, data)
        .then((res) => {
          const result = res.data;
          dispatch(RetailCampaignAction.sendRetailEnrollmentEmail(result));
        })
        .catch((err) => {
          // handle the error
        });
    },
    
  getRetailCampaignForAmplify : (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/retail/getAmplifyCampaign/${id}`)
      .then((res) => {
        dispatch(RetailCampaignAction.getRetailCampaignForAmplify(res.data.result));
      })
      .catch((err) => {
        // handle the error
      });
  }
}


//Action
export const RetailCampaignAction = {
  getRetailCampaign: (data) => {
    return {
      type: 'RETAIL_CAMPAIGN_LOADED',
      data: data
    };
  },

  sendRetailEnrollmentEmail: (data) => {
    return {
      type: 'SEND_RETAIL_ENROLLMENT_EMAIL',
      data: data
    };
  },

  getRetailCampaignForAmplify: (data) => {
    return {
      type: 'RETAIL_CAMPAIGN_AMPLIFY',
      data: data
    };
  },

  enrollCustomerIntoCampaign: (data) => {
    return {
      type: 'ENROLL_CUSTOMER_INTO_CAMPAIGN',
      data: data
    };
  },

  registerCustomer: (data) => {
    return {
      type: 'REGISTER_CUSTOMER',
      data: data
    };
  }
};