import React, { useEffect, useState } from 'react';
import NavBar from '../../Components/Navbar';
import { useAuth } from '../../Context/AuthContext';
import { UsersService, useUserState } from '../../Service/userServices';
import CreatorOnboarding from '../Onboarding/Creator';
import Sidebar from '../../Components/Creator_Dashboard/Sidebar';
import Modal from '../../utils/brandModal';
import GeneralOnboarding from '../../Components/Creator_Dashboard/Onboarding/GeneralOnboarding';

const styles = {
  fontFamily: 'sans-serif',
  textAlign: 'center',
  color: 'red',
  width: '80%'
};

export default function Creator({ user, children }) {
  const { currentUser } = useAuth();
  const [onboardNeeded, setOnboardNeeded] = useState(false);
  const userState = useUserState();
  const [isOpen, setIsOpen] = useState(true);

  /*useEffect(() => {
    if (currentUser.uid) {
      UsersService.FetchUserInfo(currentUser.uid);
    }
  }, [userState.updateNeed.value, curUser]);

const curUser = userState.user.value; */
  useEffect(() => {
    if (user) {
      if (!user.creator_socials) {
        setOnboardNeeded(true);
      } else {
        setOnboardNeeded(false);
      }
    }

  }, [currentUser]);


  return (
    <div className="bg-gray-100 z-100 min-h-screen h-max">
      <div>
      { onboardNeeded && <CreatorOnboarding />}
      </div>

      <NavBar />
      <div className="flex lg:flex-row  xs:flex-col lg:w-full lg:h-5/6  ">
        <Sidebar user={user} />
        {children}
      </div>
    </div>
  );
}
