import React, { useState, useEffect, useContext } from 'react';
import { validateFormFields } from '../../../utils/functions';
import Loader from '../../Loader';

import ReviewCard from '../Reviews/ReviewCard';
import { BrandContext } from '../../../routes/PrivateRoute/BrandDashboardRoute';
import { ReviewService, useReviewState } from '../../../Service/ReviewService';
import ReviewHelpModal from './ReviewHelpModal';
import CustomButton from '../../../utils/customButton';
const Reviews = (props) => {
  const [productDescription, setProductDescription] = useState('');
  const [uniqueSellingPoints, setUniqueSellingPoints] = useState('');
  const [targetAudience, setTargetAudience] = useState('');
  const [campaignPurpose, setCampaignPurpose] = useState('');
  const [videoConcept, setVideoConcept] = useState('');
  const [generate, setGenerate] = useState(false);
  const reviewState = useReviewState();
  const [tab, setTab] = useState('Pending'); 
  const {brandId} = useContext(BrandContext);
  const [loading, setLoading] = useState(true); // Add this line
  const [help, setHelp] = useState(false); // Add this line
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    // You can access the form values using the state variables
    console.log({
      productDescription,
      uniqueSellingPoints,
      targetAudience,
      campaignPurpose,
      videoConcept
    });
  };
  
  useEffect(() => {
    ReviewService.getBrandReviews(brandId);
    setLoading(false); // Add this line
  }, [reviewState.updateNeed.value]); // Add a comma here
  const res = reviewState.reviews.value;
  const reviews = res
  console.log("res", res)

  const pendingReviewsCount = reviews 
    ? Object.values(reviews).filter(review => !review.completed).length
    : 0;

  return (
    <div className="m-4 xs:m-0 bg-white w-full rounded-lg z-100" style={{ height: 'calc(95vh - 5rem)'}}>
      <div className="flex items-center h-16 justify-between ">
        <h3 className="font-semibold p-8 pl-2 ml-5 2xl:text-2xl md:text-base xs:text-base">Reviews</h3>
        <CustomButton onClick={()=> setHelp(true)} 
          className="text-white 2xl:text-base md:text-base 2xl:px-4 2xl:py-2 md:px-2 md:py-1 mr-8">Help</CustomButton>
      </div>
      <div className='flex flex-col ml-2 overflow-y-auto'>
        <div className="flex mb-2 border-b w-full "> 
          <button 
            className={`2xl:text-base md:text-xs mx-5 cursor-pointer font-bold mb-2 font-black xs:xl text-center transition-all duration-200 ease-in-out transform hover:scale-110 ${tab === 'Pending' ? 'text-Blue border-red-500' : 'text-black border-transparent'} border-b-4 hover:text-Blue transform transition duration-500 ease-in-out hover:scale-110 hover:bg-blue-200`}
            onClick={() => setTab('Pending')}>
          Review Needed {pendingReviewsCount > 0 ? `(${pendingReviewsCount})` : ''}
          </button>  
          <button 
            className={`2xl:text-base md:text-xs mx-5 cursor-pointer font-bold mb-2 font-black xs:xl text-center transition-all duration-200 ease-in-out transform hover:scale-110 ${tab === 'Completed' ? 'text-Blue border-red-500' : 'text-black border-transparent'} border-b-4 hover:text-Blue transform transition duration-500 ease-in-out hover:scale-110 hover:bg-blue-200`}
            onClick={() => setTab('Completed')}>
            Completed Reviews
          </button>
        </div>
        <div style={{ height: 'calc(75vh - 5rem)', overflow: 'auto' }}>
          {loading ? <Loader /> : (
            reviews ? (
              Object.values(reviews)
                .filter(review => (tab === 'Pending' ? !review.completed : review.completed))
                .map((review) => (
                  <ReviewCard review={review} key={review.id} />
                ))
            ) : (
              <p className='flex items-center mt-10 text-base justify-center font-bold'>{tab === 'Pending' ? 'No reviews pending. Reviews that have not been completed will show up here.' : 'No completed reviews yet.'}</p>
            )
          )}
        </div>
      </div>
      {help ? (
          <ReviewHelpModal
            open={help}
            close={() => setHelp(false)}
          />
           ): null}
    </div>
  );
};

export default Reviews;