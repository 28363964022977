import React, { useEffect, useState, useContext } from 'react';
import TaskCard from './TaskCard';
import MyInfluencerTasks from './MyInfluencerTasks';
import { useAuth } from '../../Context/AuthContext';
import { TaskService, useTaskState } from '../../Service/TaskServices';
import MyTaskCard from './MyTaskCard';
import Loader from '../Loader2';
import { useHistory } from 'react-router-dom';
import { useBrandState, BrandService } from '../../Service/BrandServices';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';
import { useAccountState, AccountService } from '../../Service/AccountServices';
import { baseUrl } from '../../baseUrl';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import moment from 'moment';
import DeleteModalTask from './TaskDeleteModal';
import ApprovalModalTask from './ApprovalModalTask';
import TaskDetailsModal from './SendTaskDeliverables';
import TaskDeleteModal from './TaskDeleteModal';
import UpdateTask from './UpdateTask';
import SendVideoRevision from './SendVideoRevision';
import SubmitTiktokCode from './SubmitTiktokCode';
function Calendar(props) {
  const taskState = useTaskState();
  const { currentUser } = useAuth();
  const history = useHistory();
  const brandState = useBrandState();
  const accountState = useAccountState();
  const [loading,setLoading] = useState(true);
  const [delTask, setDelTask] = useState(false);
  const [taskDetails, setTaskDetails] = useState(false);
  const [videoRevision, setVideoRevision] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [deleteTask, setDeleteTask] = useState(false);
  const [proposalTask, setproposalTask] = useState(false);
  const [update, setUpdate] = useState(false);
  const [submission, setSubmission] = useState(false);
  
const detailsHander = (task) => {
  setSelectedTask(task);
  setTaskDetails(!taskDetails);
};

const revisionHandle = (task) => {
  setSelectedTask(task);
  setVideoRevision(!videoRevision);
};

const proposalHandle = (task) => {
  setSelectedTask(task);
  setproposalTask(true);
};

const deleteHandle = (task) => {
  setSelectedTask(task);
  setDeleteTask(true);
};

const updateHandle = (task) => {
  setSelectedTask(task);
  let updt = update;
  setUpdate(!updt);
};

const submissionHandle = (task) => {
  setSelectedTask(task);
  setSubmission(!submission);
};
  useEffect(() => {
    if (accountState.account.value && accountState.account.value.brands) {
      console.log("IS BRAND");
      history.push("/brand/dashboard");
    }
  }, [accountState.account.value]);
  const checkUserFulfillment = (approvedProposals, currentUserId) => {
    // First, check if approvedProposals is an object and not null
    if (typeof approvedProposals === 'object' && approvedProposals !== null) {
      // Then, check if the currentUserId key exists in the approvedProposals
      const userProposals = approvedProposals[currentUserId];
      // Finally, check if 'fulfillment' key exists under the user's proposals
      return userProposals && 'fulfillment' in userProposals;
    }
    return false; // Return false if approvedProposals is null or not an object
  };

  // Using the function to determine if the current user has fulfillment
  const currentUserHasFulfillment = checkUserFulfillment(props.tasks.accepted_proposals, currentUser.uid);
  const currentUserHasInfFulfillment = checkUserFulfillment(props.tasks.accepted_proposals, currentUser.uid);
  const task_user = props.tasks;
  const influencer_tasks =  props.influencerTasks;
  //console.log("Example task:", task_user[0]);
  //console.log("tasks in calendar", task_user)
  // console.log("influencer tasks in calendar", influencer_tasks)
    return (
      <div className="bg-white m-4 rounded-lg w-full xs:h-fit h-full" style={{height: '40vh'}}>
      <div style={{ position: 'sticky', top: 0 }}>
      <h3 className='font-bold md:text-0.6'>Calendar</h3>
      <div className='text-sxs font-bold'>
      <FullCalendar
        plugins={[ dayGridPlugin ]}
        initialView="dayGridMonth"
        events={(task_user && influencer_tasks) ? [...task_user.filter(taskObject => {
          const task = Object.values(taskObject)[0]; // Get the task object from the wrapper object
          return ["approved", "revisions", "revision", "drafted", "submitted"].includes(task.status);
        }).map(taskObject => {
          const task = Object.values(taskObject)[0]; // Get the task object from the wrapper object
          return {
            title: task.name || task.note3,
            start: task.deadline || task.date_issued, // Use deadline if it exists, otherwise use date_issued
            task: task, // Add the task to the event object
          };
        }), ...Object.values(influencer_tasks).filter(task => {
          return ["approved", "revisions", "revision", "drafted", "submitted"].includes(task.status);
        }).map(task => {
          return {
            title: task.name || task.note3,
            start: task.deadline || task.date_issued, // Use deadline if it exists, otherwise use date_issued
            task: task, // Add the task to the event object
          };
        })] : []}
        eventColor="#FF2642"
        height="35vh" // Set the height of the entire calendar
        eventClick={(info) => {
          const task = info.event.extendedProps.task;
          setSelectedTask(task);
          switch (task.status) {
            case "approved":
              detailsHander(task);
              break;
            case "revisions":
              revisionHandle(task);
              break;
            case "pending":
              proposalHandle(task);
              break;
            case "submitted":
              submissionHandle(task);
              break;
            case "completed":
              break;
            case "drafted":
              break;
            default:
              detailsHander(task);
              break;
          }
        }}
      />
      </div>
      </div>
      {delTask ? (
        <DeleteModalTask
          open={delTask}
          onClose={() => setDelTask(false)}
          task={selectedTask}
          id={props.id}
        />
      ) : null}
      {proposalTask ? (
        <ApprovalModalTask
          open={proposalTask}
          task={selectedTask}
          onClose={() => setproposalTask(false)}
          id={props.id}
        />
      ) : null}
      {update ? <UpdateTask open={update} onClose={updateHandle} id={props.id} /> : null}
      {taskDetails ? (
        <TaskDetailsModal
          open={taskDetails}
          close={() => setTaskDetails(false)}
          currentUserHasFulfillment={currentUserHasFulfillment}
          task={selectedTask}
          id={props.id}
        />
      ) : null}
      {videoRevision ? (
        <SendVideoRevision
          open={videoRevision}
          close={() => setVideoRevision(false)}
          task={selectedTask}
          id={props.id}
        />
      ) : null}
      {submission ? (
        <SubmitTiktokCode
          open={submission}
          close={() => setSubmission(false)}
          task={selectedTask}
          id={props.id}/>
      ) : null}
      {deleteTask ? (
        <TaskDeleteModal
          open={deleteTask}
          task={selectedTask}
          close={() => setDeleteTask(false)}
          id={props.id}
        />
      ) : null}
    </div>
  );
}

export default Calendar;
