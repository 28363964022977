import React, { useEffect, useState } from 'react';
import ApproveModal from './ApproveModal';
import { OfferService, useOfferState } from '../../Service/OfferService';
import { GrFormPrevious } from 'react-icons/gr';
import { MdOutlineNavigateNext } from 'react-icons/md';
import OfferDetails from './OfferDetails';

function Offers() {
  const [delRec, setDelRec] = useState(false);
  const [details, setDetails] = useState('');
  const [offerApproveData, setOfferApproveData] = useState({
    creator_uid: '',
    brand_uid: '',
    offer_id: ''
  });

  const offerState = useOfferState();
  useEffect(() => {
    if (offerState.updateNeed.value) {
      OfferService.FetchOffers();
    }
  }, [offerState.updateNeed.value]);
  let offer = [];
  if (offerState.offers.value) {
    offer = Object.entries(offerState.offers.value).map((e) => ({
      [e[0]]: e[1]
    }));
  }

  const handleShowDetails = () => {
    setDetails(true);
  };
  const handleApprove = (id, data) => {
    setOfferApproveData({
      creator_uid: data.creator_uid,
      brand_uid: data.brand_uid,
      offer_id: id
    });
    setDelRec(true);
  };
  return (
    <div className="bg-white m-4 p-12 rounded-lg w-full p-6" style={{ height: '78vh' }}>
      <h3 className="font-semibold py-2 text-left text-2xl border-solid border-b-2  border-black ">
        Offers
      </h3>
      {delRec ? (
        <ApproveModal
          open = {delRec}
          offerApproveData = {offerApproveData}
          onClose = {() => setDelRec(false)}
        />
      ) : null}
      {details && <OfferDetails open={details} onClose={() => setDetails(false)} />}

      <div className="flex flex-col">
        <div className="sm:-mx-6 lg:-mx-8">
          <div className="py-2  overflow-y-scroll z-100  h-41 inline-block min-w-full sm: lg:px-8">
            <div>
              <table className="w-full z-0">
                <thead className="bg-white border-b">
                  <tr>
                    <th
                      scope="col"
                      className="text-xl font-medium   font-bold text-gray-900 py-4 text-left">
                      Name
                    </th>
                    <th
                      scope="col"
                      className="text-xl font-medium font-bold text-gray-900  py-4 text-left">
                      Creator
                    </th>
                    <th
                      scope="col"
                      className="text-xl font-medium font-bold text-gray-900  py-4 text-left">
                      Offering Price
                    </th>
                    <th
                      scope="col"
                      className="text-xl font-medium font-bold text-gray-900  py-4 text-left">
                      Status
                    </th>
                    <th
                      scope="col"
                      className="text-xl font-medium font-bold text-gray-900  py-4 text-left"></th>
                    <th
                      scope="col"
                      className="text-xl font-medium font-bold text-gray-900  py-4 text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  {offer.length > 0 &&
                    offer.map((item, i) => {
                      const data = item[Object.keys(item)];
                      const id = Object.keys(item);
                      return (
                        <tr className={i % 2 ? 'bg-white' : 'bg-gray-100'} key={i}>
                          <td className="pl-3 py-4 whitespace-nowrap text-lg font-medium text-gray-900 text-left">
                            {data.asset_name}
                          </td>

                          <td className="text-lg text-gray-900 font-light py-4 whitespace-nowrap text-left">
                            @{data.creator_name}
                          </td>

                          <td className="text-lg text-red font-light py-2 whitespace-nowrap text-left">
                            ${(data.offer_price / 100).toFixed(2).toString()}
                          </td>
                          <td className="text-lg text-gray-900 font-light py-2 whitespace-nowrap text-left">
                            {data.status}
                          </td>
                          <td className="text-lg text-gray-900 font-light py-2 whitespace-nowrap text-left">
                            {' '}
                            {data.status === 'Accepted' ? (
                              <button
                                className="bg-red font-bold px-4 py-2 rounded-lg text-white text-3xl mt-1 w-48 items-center justify-center text-left cursor-pointer"
                                onClick={() => handleShowDetails()}>
                                See Details
                              </button>
                            ) : (
                              <button
                                className="bg-red font-bold px-4 py-2 rounded-lg text-white text-3xl mt-1 w-48 items-center justify-center text-left cursor-pointer"
                                onClick={() => handleApprove(id, data)}>
                                Approve Offer
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>

              <div className="flex ml-2.5px mt-4">
                <button
                  // onClick={() => goToPreviousPage()}
                  className="mr-8 cursor-pointer bg-red text-white py-2 px-2 rounded-lg">
                  <GrFormPrevious className="inline text-white fill-current" />
                  Previous
                </button>
                <div className="mr-4 mt-4 font-bold">{/* {currentPage} - {pages} */}</div>
                <button
                  // onClick={() => goToNextPage()}
                  className="cursor-pointer bg-red text-white py-2 px-2 rounded-lg">
                  Next
                  <MdOutlineNavigateNext className="inline text-white fill-current" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Offers;
