import React from 'react'
import { TaskService } from '../../Service/TaskServices';
import { toast, Toaster } from 'react-hot-toast';
import CustomButton from '../../utils/customButton';
function UgcUndoFulfillmentModal(props) {
  console.log(props);
  const handleUndo = async (proposal) => {
    console.log("task id: " + proposal.task_id);
    const data = {
      campaign_id: props.id,
      creator_id: props.creator_id,
      task_id: props.task_id,
      brand_id: props.brand_id,
      
    };
    
    try {
      await TaskService.UndoFulfillment(proposal.creator_id, data);
      toast.success(`Fulfillment for ${fullname} has been undone.`, {duration: 1000});
      props.close();
      props.fetchData();
    }
    catch (error) {
      toast.error('An error occurred while undoing the fulfillment.');
    }

  } 

  const proposal = props.proposal[props.proposalId];
const creatorAddress = proposal && proposal.creator_address;
const fullname = creatorAddress && creatorAddress.fullname;
  console.log("proposal id: ", props.proposal[props.proposalId]);
  return (
<div className='bg-white rounded-md break-all font-thin text-base sm:h-full sm:w-full md:h-2/3 md:w-2/3 lg:h-1/2 lg:w-1/2 xl:h-1/3 xl:w-1/3'>
  <h1 className='flex mx-auto items-center mt-5 justify-center text-center px-4'>
    Are you sure you want to undo&nbsp;
    <span className='font-extrabold'>{fullname}'s</span>&nbsp;product fulfillment?
  </h1>
  <div className='mx-4 sm:mx-6 md:mx-10 flex flex-col mt-5 break-words'>
    <div className='justify-center'>
      <div className="flex items-center">
        <strong className='font-bold'>Fulfillment Type:</strong>
      </div>
      <div className="flex items-center">
        {props.fulfillment.type}
      </div>
      {props.fulfillment.tracking_number && (
        <>
          <div className="flex items-center">
            <strong className='font-bold'>Tracking Number:</strong>
          </div>
          <div className="flex items-center">
            {props.fulfillment.tracking_number}
          </div>
        </>
      )}
      {props.fulfillment.product_link && (
        <>
          <div className="flex items-center">
            <strong className='font-bold'>Product Link:</strong>
          </div>
          <div className="flex items-center">
            {props.fulfillment.product_link}
          </div>
        </>
      )}
      {props.fulfillment.store_link && (
        <>
          <div className="flex items-center">
            <strong className='font-bold'>Store Link:</strong>
          </div>
          <div className="flex items-center">
            {props.fulfillment.store_link}
          </div>
        </>
      )}
    </div>
    <div className='flex flex-col sm:flex-row mt-5 items-center justify-center gap-x-4 md:gap-x-20'>
      <CustomButton onClick={() => handleUndo(props.proposal)} className='bg-red font-bold text-white rounded-md py-1 px-3 md:py-3 md:px-10'>
        Yes
      </CustomButton>
      <button onClick={() => props.close()} className='border-2 font-bold hover:bg-gray-100 text-gray-500 rounded-md py-1 px-3 md:py-3 md:px-10'>
        No
      </button>
    </div>
  </div>
</div>
    
  )
}

export default UgcUndoFulfillmentModal