import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import DeleteUser from './DeleteUserPop';

function UpdateModal(props) {
  const [isOpen] = useState(true);
  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => {
          props.closeAction();
        }}
        center
        classNames={{ modal: 'customModal' }}>
        <div>
          <DeleteUser
            handleComplete={() => {
              props.closeAction();
            }}
            userID={props.userId}
          />
        </div>
      </Modal>
    </>
  );
}

export default UpdateModal;
