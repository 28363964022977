import { createState, useState } from '@hookstate/core';
const state = createState({
  campaign_id: '',
  campaign_name: '',
  campaign_photo: '',
  date_created: '',
  number_of_videos: 0,
  credits: 0,
  email_body: '',
  influencer_reach_budget: 0,
  tasks: [],
  target_viewership: 0,
  loading: false,
  editTask: '',
  deleteTask:'',
  campaign_objective: '',
  traffic_destination: '',
});

export const accessTabState = () => state;

export const CampaignState = () => useState(state);
