import React, { useEffect, useState, useContext } from 'react';
import TaskCard from './TaskCard';
import MyInfluencerTasks from './MyInfluencerTasks';
import { useAuth } from '../../Context/AuthContext';
import { TaskService, useTaskState } from '../../Service/TaskServices';
import MyTaskCard from './MyTaskCard';
import Loader from '../Loader2';
import { useHistory } from 'react-router-dom';
import { useBrandState, BrandService } from '../../Service/BrandServices';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';
import { useAccountState, AccountService } from '../../Service/AccountServices';

function MyTasks() {
  const taskState = useTaskState();
  const { currentUser } = useAuth();
  const history = useHistory();
  const brandState = useBrandState();
  const accountState = useAccountState();
  const [loading,setLoading] = useState(true);

  useEffect(() => {
    TaskService.FetchTaskForUser(currentUser.uid);
    setLoading(false);
  }, [taskState.updateNeed.value]);
  const { brandId } = useContext(BrandContext);
  useEffect(() => {
    const fetchAccountInfo = async () => {
      if (currentUser.uid !== null) {
        await AccountService.FetchAccountInfo(currentUser.uid);
      }
    };
  
    fetchAccountInfo();
  
    return () => {
      // Cleanup, if needed
    };
  }, [currentUser.uid]);
  
  useEffect(() => {
    if (accountState.account.value && accountState.account.value.brands) {
      console.log("IS BRAND");
      history.push("/brand/dashboard");
    }
  }, [accountState.account.value]);
  const res = taskState.tasks.value;
  const resUser = taskState.userTasks.value;
  const task = res
    ? Object.entries(res).map((e) => ({
        [e[0]]: e[1]
      }))
    : [];

  const task_user = resUser
    ? Object.entries(resUser).map((e) => ({
        [e[0]]: e[1]
      }))
    : [];

    return (
    <div className="bg-white m-4 rounded-lg lg:w-full xs:h-fit overflow-y-scroll"
    style={{ height: 'calc(100vh - 5rem)' }}>
      <div className="bg-white m-4 rounded-lg lg:w-fit xs:h-fit">
          <div >
          <h3 className="font-semibold p-4 md:mb-1 bg-white w-full h-10 2xl:text-2xl md:text-base xs:text-sm">My UGC Briefs</h3>
          <h3 className=' text-gray-500 2xl:text-base md:text-0.7 xs:text-xs ml-4 mb-2 '>For these briefs you will only be asked to generate the video assets, there will be no need to post.</h3>
        </div>
      </div>
      {task_user.length === 0 ? (
         <div className="md:h-42" style={{maxHeight: '40vh'}}>
          <div className="2xl:text-4xl md:text-base flex mt-10 font-bold items-center justify-center">
            You have no current UGC briefs.
          </div>
          <div className="2xl:text-4xl md:text-base mb-2 text-center text-lg flex mt-10 font-bold items-center justify-center">
            UGC briefs will appear here once you have applied for one.
          </div>
        </div>
      ) : (
        <>
    {loading ? (
      <Loader />
    ) : (
          <div className="overflow-y-scroll w-full" style={{maxHeight: '40vh'}}>
           <div className="overflow-y-scroll grid lg:grid-cols-5 grid-cols-1 md:grid-cols-2 gap-2 mx-2">
            {task_user
              .filter((item) => item[Object.keys(item)].status !== 'available')
              .sort((a, b) => {
                const statusWeights = {
                  'revisions': 5,
                  'approved': 4,
                  'drafted': 3,
                  'pending': 2,
                  'completed': 1
                };
                const aStatus = a[Object.keys(a)].status;
                const bStatus = b[Object.keys(b)].status;
                return statusWeights[bStatus] - statusWeights[aStatus];
              })
              .map((item, i) => {
                const res = item[Object.keys(item)];
                const id = Object.keys(item);
                return (
                  <div key={i}>
                    <MyTaskCard task={res} id={id} />
                  </div>
                );
              })}
            </div>
          </div>
         )}
        </>
      )}
      <div className='border-b border-2 border-gray-300'/>
          <MyInfluencerTasks />
    </div>
  );
}

export default MyTasks;
