import React from 'react'
import { toast, Toaster } from 'react-hot-toast';
import { BsExclamationCircle } from 'react-icons/bs';
import { TaskService, useTaskState } from '../../Service/TaskServices';
import CustomButton from '../../utils/customButton';
function UgcCreatorRemovalModal(props) {
  console.log(props); 
  const removeCreator = async (proposal, proposalKey) => {

    const data = {
      creator_id: proposal.creator_id,
      task_id: props.task_id,
      bid: props.brand_id,
      proposal_id: proposalKey
    };
    
    try {
      console.log("data: ", data);
      await TaskService.RemoveCreator(props.task_id, data);
      toast.success('Influencer Removed');
      props.close();
      props.fetchData();
    }
    catch (error) {
      toast.error('An error occurred while removing the influencer.');
    }
  }

  return (
    <div className='bg-white rounded-md px-10 break-words 2xl:text-2xl text-xs h-1/2  md:h-fit flex flex-col items-center justify-center'>
       <div className='bg-white rounded-md break-words 2xl:text-2xl text-xs h-fit w-full my-10'>
        <div className="p-6 pt-0 text-center">
          <h1 className='2xl:text-3xl text-base mb-2 font-bold'>Remove Creator</h1>
          <BsExclamationCircle className="mx-auto mb-4 w-32 h-14 text-gray-400 dark:text-gray-200" />
          <h1 className='mb-5 w-full 2xl:text-2xl text-base font-normal text-gray-500 dark:text-gray-600'>
          Are you sure you want to remove &nbsp;<span className='font-bold'>{props.proposal.creator_address.fullname}</span>&nbsp; from this brief?
          </h1>
          <div className='flex flex-row items-center justify-center gap-10'>
            <CustomButton onClick={() => removeCreator(props.proposal, props.proposalKey)} className="text-white hover:bg-red-800  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">Remove
            </CustomButton>
            <button  onClick={() => props.close()} className="text-gray-500 bg-white hover:bg-gray-100  rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600">Cancel
            </button>
          </div>
          </div>
        </div>
    </div>
  )
}

export default UgcCreatorRemovalModal