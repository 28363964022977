import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';

//State
const state = createState({
  requests: [],
  request: [],
  requestsBrand: [],
  retrieving: false,
  fetched: false,
  inserted: false,
  message: '',
  loading: true,
  updateNeed: true
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'CREATE_REQUEST':
        return s.merge({
          request: action.data,
          loading: false,
          updateNeed: true
        });
      case 'REQUESTS_SINGLE_LOADED':
        return s.merge({
          request: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true
        });
      case 'REQUESTS_BRAND_LOADED':
        return s.merge({
          requestsBrand: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true
        });
      case 'REQUEST_APPROVE':
        return s.merge({
          loading: false,
          updateNeed: true
        });
      case 'REQUEST_DENY':
        return s.merge({
          loading: false,
          updateNeed: true
        });
      case 'REQUEST_DELETE':
        return s.merge({
          loading: false,
          updateNeed: true
        });

      case 'REQUESTS_LOADED':
        return s.merge({
          requests: action.data,
          updateNeed: false,
          loading: false,
          fetched: true
        });
      default:
        return null;
    }
  }, action.type);
});

export const accessRequestState = () => state;

export const useRequestState = () => useState(state);
//Service
export const RequestService = {
  AddRequest: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/requests/createRequests`, data)
      .then((res) => {
        dispatch(RequestAction.createRequest(res));
        if (res.data.statuscode) {
          dispatch(RequestAction.createRequest(res.data.message));
        }
      })
      .catch((err) => {});
  },
  FetchSingleRequest: (id) => {
    const dispatch = useDispatch();

    axios
      .get(`${baseUrl}api/requests/${id}`)
      .then((res) => {
        dispatch(RequestAction.loadSingleRequest(res.data.result));
      })
      .catch((err) => {});
  },
  FetchRequests: () => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/requests`)
      .then((res) => {
        dispatch(RequestAction.loadRequests(res.data.result));
      })
      .catch((err) => {});
  },
  FetchRequestForBrand: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/requests/brand/${id}`)
      .then((res) => {
        dispatch(RequestAction.loadBrandRequests(res.data.result));
      })
      .catch((err) => {});
  },
  FetchRequestForUser: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/requests/getBrandRequests/${id}`)
      .then((res) => {
        dispatch(RequestAction.loadUserRequests(res.data.result));
      })
      .catch((err) => {});
  },
  ApproveRequest: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/requests/approve/${id}`, data)
      .then((res) => {
        dispatch(RequestAction.approveRequest(res));
      })
      .catch((err) => {});
  },
  DenyRequest: (id, data) => {
    const dispatch = useDispatch();
    axios
      .post(`${baseUrl}api/requests/deny/${id}`, data)
      .then((res) => {
        dispatch(RequestAction.denyRequest(res));
      })
      .catch((err) => {});
  },
  DeleteRequest: (id, data) => {
    const dispatch = useDispatch();
    axios
      .delete(`${baseUrl}api/requests/cancelRequest/${id}`, data)
      .then((res) => {
        dispatch(RequestAction.deleteRequest(res));
      })
      .catch((err) => {});
  }
};

//Action
export const RequestAction = {
  createRequest: (data) => {
    return {
      type: 'CREATE_REQUEST',
      data: data
    };
  },
  loadUserRequests: (data) => {
    return {
      type: 'REQUESTS_LOADED',
      data: data
    };
  },
  loadRequests: (data) => {
    return {
      type: 'REQUESTS_LOADED',
      data: data
    };
  },
  loadBrandRequests: (data) => {
    return {
      type: 'REQUESTS_BRAND_LOADED',
      data: data
    };
  },
  loadSingleRequest: (data) => {
    return {
      type: 'REQUEST_SINGLE_LOADED',
      data: data
    };
  },
  approveRequest: (data) => {
    return {
      type: 'REQUEST_APPROVE',
      data: data
    };
  },
  denyRequest: (data) => {
    return {
      type: 'REQUEST_DENY',
      data: data
    };
  },
  deleteRequest: (data) => {
    return {
      type: 'REQUEST_DELETE',
      data: data
    };
  }
};
