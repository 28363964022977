import React from 'react';
import axios from 'axios'
import GlobalDialog from '../../utils/globalDialog';
import logo from '../../Assets/logo-red.png';
import { Link } from 'react-router-dom';

function EditModal({ open, name, asset, onClose, thumbnail }) {
  const downloadVideo = (url) => {
    axios({
      url,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      const urlObject = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlObject;
      link.setAttribute('download', 'recording.mp4');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const formatDate = (date) => {
    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    const formattedDate = new Date(date).toLocaleDateString(undefined, options);

    const optionsTime = { hour: 'numeric', minute: 'numeric' };
    const formattedTime = new Date(date).toLocaleTimeString(undefined, optionsTime);

    return `${formattedDate} ${formattedTime}`;
  };

  const formattedDate = formatDate(asset.created);

  return (
    <GlobalDialog open={open} onClose={onClose}>
      <div className=" bg-white rounded-lg dark:bg-gray-700">
        <div>
          <img src={logo} className="mx-auto" alt="log1" />
        </div>
       
        <div className="rounded-lg w-auto p-3 ">
          {asset.type === 'video' ? (
            <div className=" w-2/3 bg-green mx-auto items-center justify-center text-center">
              <video className="w-full min-w-1/3 h-auto rounded-lg" controls src={asset.source || asset.video} />
            </div>
          ) : (
            <div className=" w-2/3 bg-green mx-auto items-center justify-center text-center">
              <img src={thumbnail} alt={name} className="w-full min-w-1/3 h-auto rounded-lg" />
            </div>
          )} 
          <div className='flex justify-center mt-3 font-semibold'>{asset.creator_name} - {formattedDate}</div>
          <div className="my-10 w-full items-center justify-center text-center">
            <a
              href={asset.source}
              download
              target="_blank"
              rel="noreferrer"
              className="bg-Blue m-auto px-4 text-3xl font-bold py-3 rounded-md text-white mt-4 w-auto text-center cursor-pointer">
              Download
            </a>
          </div>
        </div>
      </div>
    </GlobalDialog>
  );
}

export default EditModal;
