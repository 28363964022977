import React, { useState, useEffect } from 'react';
import GlobalDialog from '../../utils/globalDialog';
import ConfirmModalTask from './InfluencerSendTaskProposal';
import moment from 'moment-timezone';
import { AuthService, userAuthstate } from '../../Service/authenticationService';
import { UsersService, useUserState } from '../../Service/userServices';
import { BsChevronCompactDown, BsChevronCompactUp } from 'react-icons/bs';
import { useAuth } from '../../Context/AuthContext';

import Collapsible from 'react-collapsible';
import { Link } from 'react-router-dom';
import CustomButton from '../../utils/customButton';

function ProposalModal({ open, onClose, id, task, fetchInfluencerTasks }) {
  const { currentUser } = useAuth();
  const userState = useUserState();
  const authState = userAuthstate();
  const [tab, setTab] = useState(0);
  const timezone = moment.tz.guess();
  var task_deadline_time = moment(task.deadline).tz(timezone).format('MMM. D, YYYY');
  const [confirm, setConfirm] = useState(false);
  const [url, setUrl] = useState('');



  useEffect(() => {
    if (currentUser.uid !== null) {
      AuthService.FetchAuthUser(currentUser.uid);
    }
  }, []);

  const user = authState.authUser.value;

 

  useEffect(() => {
    if (currentUser.uid !== null) {
      UsersService.FetchUserInfo(currentUser.uid);
    }
  }, []);
  const userInfoData = userState.user.value;
  
  // Check if userInfoData contains creator_socials.tiktok
  if (userInfoData && userInfoData.creator_socials && userInfoData.creator_socials.tiktok) {
    const tiktokData = userInfoData.creator_socials.tiktok;
    // Now you can access tiktokData safely
  } else {
    console.log('TikTok data not available yet.');
  }



  const proposalHandle = () => {
    tab ? setTab(2) : setTab(1);
    confirm ? setConfirm(false) : setConfirm(true);
  };

  const StringChecker = (text) => {
    let formattedText = text;
    if (formattedText[0].length > 50) {
      formattedText[0] = formattedText[0].substring(0, 50) + '...';
    }
    return (formattedText[0]);
  };



  function extractBeforeAndAfterURL(str) {
    const regex = /(.*?)(https?:\/\/[^\s]+)/;
    const match = str.match(regex);

    if (match && match.length >= 3) {
      const beforeURL = match[1];
      const afterURL = str.substring(match[1].length + match[2].length);
      return { beforeURL, afterURL };
    }

    return { beforeURL: str, afterURL: '' };
  }
  const covertingString = () => {
    const regex = /(https?|ftp):\/\/[^\s/$.?#].[^\s]*/i;
    if (task && task.talking_points) {
      if (regex.test(task.talking_points)) {
        let extractedUrl = task.talking_points.match(/\bhttps?:\/\/\S+/gi);
        let visualLink = StringChecker(extractedUrl);
        const { beforeURL, afterURL } = extractBeforeAndAfterURL(task.talking_points);
        return (
          <div className='break-words max-w-full'>
            {beforeURL}
            <p className="max-w-full text-blue" >
              <a className='text-blue hover:underline cursor-pointer' href={task.talking_points.match(/\bhttps?:\/\/\S+/gi)[0]} target="_blank" >{visualLink}</a>
            </p>
            {afterURL}
          </div>
        )
      }
      else {
        return (
          <div>
            {task.talking_points}
          </div>
        )
      }
    }
  }

  return (
    <div>
      {tab === 0 ? (
        <div>
          <GlobalDialog className='p-2 w-2/3' open={open} onClose={onClose}>
            <Collapsible
              trigger={[
                'Brief Details',
                <div className="px-3 absolute right-0">
                  <BsChevronCompactDown />
                </div>
              ]}
              triggerWhenOpen={[
                'Brief Details',
                <div className="px-3 absolute right-0">
                  <BsChevronCompactUp />
                </div>
              ]}
              contentOuterClassName="mb-6"
              triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
              triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
            </Collapsible>
            <div className="m-1 bg-white rounded-lg text-3xl dark:bg-gray-700 p-5">
              <div className="flex ml-7 mb-5 xs:text-0.7">
                <div className="flex mr-5 font-bold">Brand:</div>
                <a className="text-blue underline" target="_blank" href={`${task && task.brand_website}`} rel="noreferrer" >{task && task.brand_name}</a>
              </div>
                    <div className="flex ml-7 mb-5 xs:text-0.7">
                      <div className="flex mr-5 font-bold">Platform(s):</div>
                      <div>
                        {task && task.platform.map((platform, index) => (
                          <span key={index} className="mr-2">
                            {platform}
                            {index < task.platform.length - 1 ? ',' : ''}
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="flex ml-7 mb-5 xs:text-0.7">
                <div className="flex mr-5 font-bold">Task Name: <p className='ml-2 font-thin'>{task && task.name}</p></div>
               
              </div>
              <div className="flex ml-7 mb-5 xs:text-0.7">
                <div className="flex flex-col">
                    <div className="font-bold">Brief:</div>
                    <div className='flex-wrap'>
                        <span>We invite you to craft a unique video that highlights the brand in a non-salesy way and share it on the platforms specified below (ie, TikTok & Instagram Reels). Submit the link to us afterwards, so we can track and analyze the engagement.</span>
                    </div>
                    <div className='flex-wrap'>
                        <span>Crafted and the brand will have the rights to the video assets in perpetuity.</span>
                    </div>
                </div>
            </div>  
              <div className="ml-7 mb-5 xs:text-0.7">
                <div>
                  <div className="mt-2 font-bold">
                    {task && task.brand_name} is prioritizing these products/flavors:
                  </div>
                  <div className="">
                    {task && task.product_name}
                  </div>
                </div>
                <div>
                  <div className="mt-2 font-bold xs:text-0.7">
                    {task && task.brand_name}'s Target Audience:
                  </div>
                  <div className="">
                    {task && task.target_audience}
                  </div>
                </div>
                <div>
                  <div className="mt-2 font-bold xs:text-0.7">
                    Key talking points for creators:
                  </div>
                  <div className="text-justify break-all flex-wrap">
                    {task && covertingString(task.talking_points)}
                  </div>
                  <div className="mt-2 font-bold xs:text-0.7">
                    Retailer:
                  </div>
                  <div className="text-justify ">
                    {task && task.retailer}
                  </div>
                  <div className="mt-2 font-bold xs:text-0.7">
                    Caption Instructions:
                  </div>
                  <div className="text-justify">
                    {task && task.caption_instructions}
                  </div>
                  {task && task.link_required && (
                    <>
                      <div className="mt-2 font-bold">
                        <strong className='font-extrabold'> NOTE:</strong> Brand requires creator to include a link in their social media bio.
                      </div>
                      <span className='text-base text-gray-400 font-thin italic '>
                        This will help the brand convert audience into customers. You may also include it as apart of your Beacon, Linktree, etc page. Link must be present for at least 1 month after posting.
                      </span>
                      {task && task.traffic_destination && (
                        <>
                            <div className='mt-2 font-bold'>Link will lead to brand's:</div>
                            <div className="text-justify">
                            <div>
                            {task.traffic_destination === 'amazon' ? 'Amazon Product Page' 
                                    : task.traffic_destination === 'brand_website' ? 'Brand Website' 
                                    : task.traffic_destination === 'store_locator' ? 'Store Locator' 
                                    : task.traffic_destination === 'retail_website' ? 'Retail Website' 
                                    : task.traffic_destination === 'sms_rebate' ? 'SMS Rebate Landing Page' 
                                    : 'Other'}
                            </div>
                            </div>
                        </>
                      )}

                    </>
                  )}

                  <div className="mt-2 font-bold">
                    Store locator:
                  </div>

                  <div className="text-left ">
                    {task && task.store_list && task.store_list.includes("https://") || task.store_list.includes(".com") ? (
                      <a
                        className="text-blue hover:underline break-all cursor-pointer"
                        href={task.store_list.startsWith("http") ? task.store_list : `https://${task.store_list}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {task.store_list}
                      </a>
                    ) : (
                      task.store_list
                    )}
                  </div>
                  <div className="mt-2 font-bold">
                    Regions:
                  </div>
                  <div className="text-justify 2xl:text-2xl text-base">
                    {task && task.regions && 
                      ((Array.isArray(task.regions) && task.regions.length > 0) ? (
                        <p>{task.regions.map(region => region.value || region).join(', ')}</p>
                      ) : (
                        <p>{Object.values(task.regions).map(region => region.value).join(', ')}</p>
                      ))
                    }
                  </div>
                </div>
              </div>
              <div className="items-center justify-center text-center">
  {(() => {
    const tiktokAccountMissing = task.platform.includes('TikTok') && (!userInfoData || !userInfoData.creator_socials || !userInfoData.creator_socials.tiktok || !userInfoData.creator_socials.tiktok.performance || !userInfoData.creator_socials.tiktok.performance.suggestedRate);
    const instagramAccountMissing = task.platform.includes('Instagram') && (!userInfoData || !userInfoData.creator_socials || !userInfoData.creator_socials.instagram || !userInfoData.creator_socials.instagram.suggested_rate);
    const bothAccountsMissing = tiktokAccountMissing && instagramAccountMissing;

    if (instagramAccountMissing && task.platform.length === 1 && task.platform.includes('Instagram')) {
      return (
        <>
          <div className='text-red font-bold mb-5'>
            This brief requires an Instagram account. Please add your Instagram account to your profile before sending a proposal.
          </div>
          <Link to="/creator/dashboard/settings" className="bg-red text-white px-2 py-4 rounded-lg outline-none text-base">
            Go to Profile
          </Link>
        </>
      );
    }

    if (tiktokAccountMissing && task.platform.length === 1 && task.platform.includes('TikTok')) {
      return (
        <>
          <div className='text-red font-bold mb-5'>
            This brief requires a TikTok account. Please add your TikTok account to your profile before sending a proposal.
          </div>
          <Link to="/creator/dashboard/settings" className="bg-red text-white px-2 py-4 rounded-lg outline-none text-base">
            Go to Settings
          </Link>
        </>
      );
    }

    if (bothAccountsMissing){
      return (
        <>
          <div className='text-red font-bold mb-5'>
            This brief requires an Instagram or TikTok account. Please add your Instagram or TikTok account to your profile before sending a proposal.
          </div>
          <Link to="/creator/dashboard/settings" className="bg-red text-white px-2 py-4 rounded-lg outline-none text-base">
            Go to Settings
          </Link>
        </>
      );
    }

    if (userInfoData && !userInfoData.shipping_details) {
      return (
        <>
          <div className='text-red font-bold mb-5'>
           You are required to have an address on file to send a proposal. Please add your address to your profile before sending a proposal.
          </div>
          <Link to="/creator/dashboard/settings" className="bg-red text-white px-2 py-4 rounded-lg outline-none text-base">
            Go to Settings
          </Link>
        </>
      );
    }
    
    if (
      task && task.regions && 
      !(userInfoData && userInfoData.shipping_details && 
        // Check if user's state is in the task regions
        task.regions.some(region => 
          (typeof region === 'string' && region.toLowerCase() === userInfoData.shipping_details.state.toLowerCase()) || 
          (region.value && region.value.toLowerCase() === userInfoData.shipping_details.state.toLowerCase())
        )
      ) &&
      // Check if user's country matches task region (USA or CAN)
      !(
        (task.regions.some(region => (typeof region === 'string' && region.toLowerCase() === 'usa') || (region.value && region.value.toLowerCase() === 'usa')) &&
        userInfoData.shipping_details.country.toLowerCase() === 'usa') ||
        (task.regions.some(region => (typeof region === 'string' && region.toLowerCase() === 'can') || (region.value && region.value.toLowerCase() === 'can')) &&
        userInfoData.shipping_details.country.toLowerCase() === 'can')
      )
    ) {
      return (
        <div className='text-red font-bold flex flex-col'>
          <p className='2xl:text-2xl text-base xs:text-sxs '>Unfortunately, this brief is not available in your state or province.</p>
          <p className='2xl:text-2xl text-base xs:text-sxs'> Please check for other briefs that are available in your region.</p>
        </div>
      );
    }


    if (
      (task.platform.includes('TikTok') && userInfoData && userInfoData.creator_socials && userInfoData.creator_socials.tiktok) ||
      (task.platform.includes('Instagram') && userInfoData && userInfoData.creator_socials && userInfoData.creator_socials.instagram)
    ) {
      return (
        <CustomButton
          className=" text-white px-2 py-4 outline-none text-base"
          onClick={() => proposalHandle()}>
          Send Proposal
        </CustomButton>
      );
    }
    

    return null;
  })()}
</div>


            </div>
          </GlobalDialog>
        </div>
      ) : null}
      {tab === 1 ? (
        <ConfirmModalTask fetchInfluencerTasks={fetchInfluencerTasks} open={confirm} onClose={() => proposalHandle()} id={id} task={task} />
      ) : null}
    </div>
  );
}

export default ProposalModal;
