import React from 'react'
import GlobalDialog from '../../utils/globalDialog'
function SettingsHelpModal(props) {
  return (
<GlobalDialog open={props.open} onClose={props.close} className="md:h-148 h-screen md:w-6/12 w-screen">
  <div className="mx-auto w-full md:w-80.6% flex flex-col items-center justify-center h-full">
    <h1 className="md:text-4xl font-bold mb-6">Help</h1>
    <h1 className="text-2xl font-bold mb-4">Welcome to the Settings page!</h1>
    <p className="text-sm mb-4">
      In this page, you can configure important settings for your account. Here's a brief explanation of the settings:
    </p>
    <ol className="list-decimal pl-6 mb-6">
      {/*<li className="md:mb-10">
       *  <span className="font-bold">Stripe:</span> This platform is used for creator payouts. By connecting your Stripe account, you can receive payments from the platform.
      </li>*/}
      <li className="md:mb-10">
        <span className="font-bold">Influencer Rates:</span> Influencer rates are based on the performance of your last 25 videos for Instagram and 20 videos for TikTok. To view your influencer rates, make sure to connect your TikTok and Instagram accounts to our platform. Every first day of the month, your influencer rate will be updated according your most recent account data.
      </li>
      <li className="md:mb-10">
        <span className="font-bold">Mailing Address:</span> Providing your mailing address is important for brands who want to send products to creators. Make sure to enter your complete and accurate address.
      </li>
      <li className="md:mb-10">
        <span className="font-bold">PayPal Email Address:</span> Your PayPal email address is essential for your payouts to be sent. Your default address will be your Crafted email, and it can be updated anytime.
      </li>
    </ol>
    <p className="text-sm">
      Feel free to explore these settings and make any necessary adjustments. If you have any further questions, please don't hesitate to ask!
    </p>
    <div className="flex-grow"></div>
  </div>
</GlobalDialog>
  )
}

export default SettingsHelpModal