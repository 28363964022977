import React, { useState, useEffect } from 'react';
import avatar from './../../Assets/profilePicture.png';
import live from './../../Assets/live.png';
import bgAvatar from './../../Assets/circle.gif';
import { AuthService, userAuthstate } from '../../Service/authenticationService';
import { FiEye, FiInstagram, FiYoutube } from 'react-icons/fi';
import { useAuth } from '../../Context/AuthContext';
import { Link } from 'react-router-dom';
import { IoMdMale, IoMdFemale } from 'react-icons/io';
import { FaTiktok } from 'react-icons/fa';
import ReactStars from 'react-rating-stars-component';
function ProfileHeader(props) {
  const { currentUser } = useAuth();
  let creator = {};
  const data = {
    uid: currentUser.uid,
    follower: '',
    follower_username: '',
    follower_name: 'noName',
    follower_avatar: '',
    username: '',
    name: '',
    avatar: ''
  };

  creator = props.user;
  //console.log('CREATOR');
  //console.log(props.user.creator_socials);
  console.log("PROS---", props);
  return (
    <div className=" bg-lightGray rounded-xl items-center justify-center mt-4 mx-auto border-b-5 flex flex-col">
      <div className="flex flex-col md:flex-row w-full items-center">
        <div className="relative md:mr-16">
          <div className="relative flex items-center justify-center ml-4 w-28 h-28 md:w-60 md:h-60">
            <img
              src={props.user.avatar ? props.user.avatar : avatar}
              className=" w-8rem h-8rem rounded-full object-cover bg-white"
              alt="avatar"
            />
          </div>
        </div>
  <div className="flex flex-col w-12rem items-baseline mt-6 md:mt-0 md:mr-28">
    <div className="2xl:text-base md:text-0.7 font-medium mb-2 ">{props.user.name && props.user.name}</div>

    {/*CRAFTEDENG-18: Removed "!== null" from the social tags*/}
  <div className="flex flex-col w-full gap-y-2">
      <div className="text-darkGray text-left text-0.7">@{props.user.username && props.user.username}</div>
        {props.user.average_rating ? (
          <div className="flex items-center  gap-x-2"> 
           <div className='font-bold text-0.8'>{props.user.average_rating.toFixed(1)}</div>
            <ReactStars
              count={5}
              value={props.user.average_rating}
              size={15}
              isHalf={true}
              activeColor="#FFD700"
              edit={false}
            />
          
          </div>
        ) : (
          <p className=' bg-red 2xl:px-2 2xl:py-1 2xl:text-base md:px-1 md:py-1 md:text-0.65 text-center text-white rounded-lg font-extrabold'>NEW</p>        )}
    </div>

  <div className='flex space-x-3 mt-4'>
    {props.user && props.user.creator_socials && props.user.creator_socials.instagram && props.user.creator_socials.instagram.handle && (
      <a href={`https://instagram.com/${props.user.creator_socials.instagram.handle}`} target="_blank">
        <FiInstagram />
      </a>)}
    {props.user && props.user.creator_socials && props.user.creator_socials && props.user.creator_socials.tiktok && (
      <a href={`https://tiktok.com/@${props.user.creator_socials.tiktok.handle}`} target="_blank">
        <FaTiktok />
        </a>)}
    </div>
  </div>

        {/*CRAFTEDENG-17: Added width and overflow wrap/overflow scroll option*/}
        <div className="mt-10 md:text-0.7 text-darkBlack 2xl:text-base mx-5 break-words sm:w-fit md:w-fit overflow-y-auto md:h-36">
          {creator.bio ? creator.bio : "No bio."}
        </div>

       
      </div> {props.user && props.user.creator_socials && props.user.creator_socials.instagram && props.user.creator_socials.instagram.demographics && (
        <div className="justify-end w-fit mx-2 mb-2 bg-gray-100 rounded-md h-fit flex 2xl:text-base md:text-0.7 ">
        <div className='w-fit flex flex-col mt-2'>   
          <h1 className='font-bold text-gray-600 text-center 2xl:text-3xl md:text-base mr-10 mb-2'>Audience Data</h1>
          <div className='flex mx-auto '>
          
          <div className="w-full mx-3 md:w-1/3">
          <h1 className='font-bold text-center'>Gender </h1>
                <div className=" flex flex-col ">
                <div className="flex flex-row items-center">
                  <IoMdMale title="Male" className="mr-2 w-5 h-4 text-blue" /> <span className='font-bold mr-2'>M</span>
                  <div className="w-full bg-gray-300 rounded-t rounded-b rounded-r my-2">
                    <div className="bg-Blue rounded-lg" style={{ width: `${parseInt((props.user.creator_socials.instagram.demographics.gender.M) / (props.user.creator_socials.instagram.demographics.gender.F + props.user.creator_socials.instagram.demographics.gender.M) * 100)}%`, height: '6px' }}>
                    </div>
                  </div>
                  <span className="ml-2 text-0.7 text-gray-800">
                    {parseInt((props.user.creator_socials.instagram.demographics.gender.M) / (props.user.creator_socials.instagram.demographics.gender.F + props.user.creator_socials.instagram.demographics.gender.M) * 100)}%
                  </span>
                </div>
                <div className="flex flex-row items-center">
                  <IoMdFemale title="Female" className="mr-2 w-5 h-4 text-red-coin" /> <span className='font-bold mr-3'>F</span>
                  <div className="w-full bg-gray-300 rounded-t rounded-b rounded-r my-2">
                      <div className="bg-Blue rounded-lg" style={{ width: `${parseInt((props.user.creator_socials.instagram.demographics.gender.F) / (props.user.creator_socials.instagram.demographics.gender.F + props.user.creator_socials.instagram.demographics.gender.M) * 100)}%`, height: '6px' }}>
                      </div>
                  </div>
                    <span className="ml-2 text-0.7 text-gray-800">
                      {parseInt((props.user.creator_socials.instagram.demographics.gender.F) / (props.user.creator_socials.instagram.demographics.gender.F + props.user.creator_socials.instagram.demographics.gender.M) * 100)}%
                    </span>
                </div>
              </div>
              </div>
      <div className="flex-none w-full mx-3 md:w-1/3">
        <h1 className="font-bold text-center">Age </h1>
        <div className="flex flex-wrap mt-0.5">
        {
          props && props.user &&
          props.user.creator_socials &&
          props.user.creator_socials.instagram &&
          props.user.creator_socials.instagram.demographics &&
          Object.entries(props.user.creator_socials.instagram.demographics.age)
          .sort((a, b) => b[1] - a[1]) // Sort by value in descending order
          .slice(0, 5) // Take the first 5 entries
          .map(([ageRange, value]) => (
            <div key={ageRange} className="flex items-center w-full mb-2">
              <div className="inline w-1/5 font-bold">{ageRange}:</div>
              <div className="w-full bg-gray-300 rounded-lg">
                <div className="bg-Blue rounded-lg" style={{ width: `${((value / Object.values(props.user.creator_socials.instagram.demographics.age).reduce((a, b) => a + b, 0)) * 100).toFixed(2)}%`, height: '6px' }}>
                </div>
              </div>
              <span className="ml-2 text-0.7 text-gray-800">
                {((value / Object.values(props.user.creator_socials.instagram.demographics.age).reduce((a, b) => a + b, 0)) * 100).toFixed(2)}%
              </span>
            </div>
        ))}
        </div>
      </div>
     <div className="flex-none w-full mx-3 md:w-1/3">
        <h1 className='font-bold text-center mb-2'>State </h1>
        <div className="flex flex-wrap">
          { props && props.user &&
            props.user.creator_socials &&
            props.user.creator_socials.instagram &&
            props.user.creator_socials.instagram.demographics &&
            props.user.creator_socials.instagram.demographics.states
            ? Object.entries(props.user.creator_socials.instagram.demographics.states)
              .sort((a, b) => b[1].total - a[1].total) // Sorting by total in descending order
              .slice(0, 5) // Getting the top 5 entries
              .map(([state, value]) => (
                <div key={state} className="flex items-center w-full mb-2">
                  <div className='inline 2xl:w-1/4 md:w-1/2 text-0.7 font-bold'>{state}:</div>
                  <div className="w-full bg-gray-300 rounded-t rounded-b rounded-r">
                    <div className="bg-Blue rounded-lg" style={{ width: `${((value.total / Object.values(props.user.creator_socials.instagram.demographics.states).reduce((a, b) => a + b.total, 0)) * 100).toFixed(2)}%`, height: '6px' }}>
                    </div>
                  </div>
                  <span className="ml-2 text-0.7 text-gray-800">
                    {((value.total / Object.values(props.user.creator_socials.instagram.demographics.states).reduce((a, b) => a + b.total, 0)) * 100).toFixed(2)}%
                  </span>
                </div>
              ))
            : <div className='flex items-center justify-center ml-3 text-center'>State data unavailable</div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
)}
    </div>
  );
}

export default ProfileHeader;
