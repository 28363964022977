import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { ProductService, useProductState } from '../../../Service/ProductServices';
import moment from 'moment-timezone';
import { useEventState, EventService } from '../../../Service/EventService';
import { useRecipeState, RecipeService } from '../../../Service/RecipeServices';
import { AuthService } from '../../../Service/authenticationService';
import { useUserState, UsersService } from '../../../Service/userServices';
import { useAuth } from '../../../Context/AuthContext';
import Multiselect from 'multiselect-react-dropdown';
import { ImCross } from 'react-icons/im';
import S3FileUpload from 'react-s3';
import s3 from '../../../aws-s3';
import { Mixpanel } from '../../../mixpanel';
import Loader from '../../Loader';
import DatePicker from 'react-date-picker';

function LiveStream(props) {
  const recipeState = useRecipeState();
  const { id } = props;
  const image = useRef('');
  const eventState = useEventState();
  const userState = useUserState();
  const history = useHistory();
  const { currentUser } = useAuth();
  const [currentTab] = useState(0);
  const recipe = recipeState.recipes.value;
  const [isOpen, setOpen] = useState(true);
  const [timezone, setTimezone] = useState('');
  const [selectedRec, setSelectedRec] = useState('');
  const [error] = useState('');
  const [disable] = useState(false);
  const [setSelectedProd] = useState('');
  const [selectedValue] = useState([]);
  const productState = useProductState();
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    startDate: '',
    startTime: '',
    endTime: '',
    image: {},
    thumbnail: '',
    eventType: ''
  });
  let formatted_tz = '';
  let tz = '';
  const [setImgUpload] = useState(false);
  useEffect(() => {
    EventService.FetchSingleEvent(id[0]);
  }, []);
  const event = eventState.event.value;

  useEffect(() => {
    if (eventState.fetched.value) {
      let date = event.date_time;

      let start_time;
      let end_time;
      let event_date;
      if (date) {
        event_date = date.substring(0, 10);
        start_time = date.substring(56, 61);
        end_time = date.substring(62, 67);
      }

      setFormData({
        ...formData,
        title: event.title,
        thumbnail: event.thumbnail,
        description: event.description,
        startDate: new Date(event_date),
        startTime: start_time,
        endTime: end_time
      });
    }
  }, [eventState.fetched.value]);

  //handling input Value

{/**   useEffect(() => {
    if (currentUser !== null) {
      UsersService.FetchUserInfo(currentUser.uid);
    }
  }, []);*/}
  const user = userState.user.value;

  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImgUpload(true);
    } else {
      setImgUpload(false);
    }
  };
  useEffect(() => {
    if (currentUser !== null) {
      AuthService.FetchAuthUser(currentUser.uid);
      RecipeService.FetchRecipeForUser(currentUser.uid);
      ProductService.FetchProducts();
    } else if (currentUser === null) {
      history.push('/');
    }
  }, [history, currentUser]);
  const prod = productState.products.value;
  //map products data into single array
  const productdata = prod
    ? Object.entries(prod).map((e) => ({
        [e[0]]: e[1]
      }))
    : [];
  //map recipe data into single array
  const recipedata = recipe
    ? Object.entries(recipe).map((e) => ({
        [e[0]]: e[1]
      }))
    : [];

  const opti = [];
  if (productdata) {
    if (productdata.length) {
      productdata.map((rec, i) => {
        let obj = {};
        const el = rec[Object.keys(rec)];
        const id = Object.keys(rec);
        obj['name'] = el.name;
        obj['id'] = id[0];
        opti.push(obj);
        return null;
      });
    }
  }

  useEffect(() => {
    tz = moment.tz.guess(); // "America/Chicago"
    formatted_tz = moment.tz(tz).format('z');
    setTimezone(formatted_tz);
  }, [timezone]);



  const handleNext = async (event) => {
    event.preventDefault();
    eventState.merge({ loading: true });
    let url;
    if (image.current) {
      url = await S3FileUpload.uploadFile(image.current.files[0], s3).then((data) => data.location);
    }
    const temp = formData;
    const data = {
      title: temp.title,
      description: temp.description,
      thumbnail: url ? url : temp.thumbnail,
      date_time: `${temp.startDate}-${temp.startTime}-${temp.endTime}-${timezone}`,
      uid: currentUser.uid,
      recipe: selectedRec,
      type: temp.eventType,
      community_id: '',
      creator_avatar: user.avatar,
      creator_name: user.name,
      creator_username: user.username
    };
    await EventService.UpdateEvent(id, data);
    eventState.merge({ loading: false });
    // window.location.reload();
    props.onClose();
  };

  const styles = {
    option: {
      // To change css for dropdown options
    },
    optionContainer: {
      // To change css for option container
      overflowY: 'scroll',
      height: '120px',
      overflowX: 'hidden',
      position: 'relative'
    },
    multiselectContainer: {
      // To change css for multiselect (Width,height,etc..)
      height: '120px',
      marginTop: '10px',
      marginBottom: '65px',
      position: 'relative'
    },
    chips: {
      // To change css chips(Selected options)
      // display:"none",
      background: '#A9A9A9'
    },
    searchBox: {
      // To change search box element look
      border: 'none',
      padding: '7px 10px',
      fontSize: '15px',
      backgroundColor: '#F3F4F6',
      backgroundImage: 'url(../../Assets/drop_down.png)'
    }
  };
  const onSelect = (selectedList, selectedItem) => {
    setSelectedProd(selectedList);
  };
  const onRemove = (selectedList, removedItem) => {};

  return (
    <div className="h-47">
      <Modal
        open={isOpen}
        onClose={() => {
          setOpen(false);
        }}
        center
        classNames={{ modal: 'customModal' }}
        closeOnOverlayClick={false}>
        <div>
          <ul id="tabs" className=" mb-14">
            {currentTab === 0 ? (
              <li>
                <h2 className="text-2xl font-bold mb-3">Update details for your event</h2>
                <hr />
                <form className="mt-2" onSubmit={handleNext}>
                  <div className="m-2 grid gap-2 lg:grid-cols-2 xs:grid-cols-1">
                    <div className="m-2">
                      <label htmlFor="startDate" className="text-black block text-base mb-2">
                        Event Start Date
                      </label>
                      <DatePicker
                        onChange={(date) => {
                          setFormData({
                            ...formData,
                            startDate: date
                          });
                        }}
                        selected={formData.startDate}
                        value={formData.startDate}
                        minDate={new Date()}
                        format="y-MM-dd"
                        isOpen={false}
                        required={true}
                      />
                      <div className="text-red px-3 font-medium"></div>
                    </div>
                    <div className="m-2">
                      <label htmlFor="startTime" className="text-black block text-base mb-2">
                        Event Start Time
                      </label>
                      <div className="flex items-center">
                        <input
                          name="startTime"
                          className="mt-1  w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          placeholder="Enter Event Start Time"
                          type="time"
                          value={formData.startTime}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              startTime: e.target.value
                            })
                          }
                        />
                        <div className="ml-2">{timezone}</div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <label className="font-bold text-black block text-xl mb-2">Event Title</label>
                    <input
                      type="text"
                      className="bg-gray-100 w-96 h-10 rounded w-full focus:outline-none p-2"
                      placeholder="Enter event title"
                      required
                      value={formData.title}
                      onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="text-black block text-2xl mb-2">Event Description</label>
                    <input
                      type="text"
                      className="bg-gray-100 w-96 h-10 rounded w-full focus:outline-none p-2"
                      placeholder="enter event title"
                      required
                      value={formData.description}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          description: e.target.value
                        })
                      }
                    />
                  </div>
                  <div className="m-2">
                    {!formData.thumbnail && (
                      <input
                        id="imageInput"
                        type="file"
                        name="thumbnail"
                        required
                        ref={image}
                        onChange={handleChange}
                        className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
                      />
                    )}
                    {formData.thumbnail && (
                      <div className="w-32 relative mt-4">
                        <img src={formData.thumbnail} alt="" className=" object-cover " />
                        <div
                          className="absolute -top-2 -right-2 cursor-pointer"
                          onClick={() => setFormData({ ...formData, thumbnail: '' })}>
                          <ImCross className="text-red" />
                        </div>
                      </div>
                    )}
                  </div>
                  <p className="mb-4">Add recipe below, so your viewers can follow along!</p>
                  {currentUser === null ? (
                    <p className="text-red">No recipe found!</p>
                  ) : (
                    <select
                      className="w-full mb-24 outline-none p-1.5 bg-gray-100"
                      value={selectedRec}
                      onChange={(e) => setSelectedRec(e.target.value)}>
                      <option value="N/A">Select Recipe</option>
                      {recipedata.length > 0 &&
                        recipedata.map((rec, i) => {
                          const el = rec[Object.keys(rec)];
                          const id = Object.keys(rec);
                          return (
                            <option key={i} value={id}>
                              {el.name}
                            </option>
                          );
                        })}
                    </select>
                  )}
                  {currentUser === null ? (
                    <p className="text-red">No product found!</p>
                  ) : (
                    <div>
                      <p className="mb-2">Are you going to be sharing a Product?</p>
                      <p className="mb-4"></p>
                      <p className="mb-4">Add Product below, so your viewers can follow along!</p>
                      <Multiselect
                        options={opti} // Options to display in the dropdown
                        selectedValues={selectedValue} // Preselected value to persist in dropdown
                        showCheckbox={true}
                        closeOnSelect={true}
                        placeholder="Select Product"
                        style={styles}
                        onSelect={onSelect} // Function will trigger on select event
                        onRemove={onRemove} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                      />
                    </div>
                  )}

                  {error && <p className="text-red">{error}</p>}
                  <div className="bg-red  w-full px-4 py-2 rounded-lg text-white mt-4 w-1/4 items-center justify-center text-center cursor-pointer">
                    <button type="submit" className="w-full" disable={disable}>
                      {eventState.loading.value && <Loader />}
                      Update Event
                    </button>
                  </div>
                </form>
              </li>
            ) : null}
          </ul>
        </div>
      </Modal>
    </div>
  );
}

export default LiveStream;
