import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';


const state = createState({
  analytics : [],
  data: [],
  retrieving: false,
  fetched: false,
  loading: true,
  updateNeed: false
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'INSTAGRAM_ANALYTICS_LOADED':
        return s.merge({
          analytics: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
          fetched: true
        });
      case 'INSTAGRAM_DEMOGRAPHICS_LOADED':
        return s.merge({
          data: action.data,
          loading: false,
          retrieving: true,
          fetched: true
        });
        default:
        return null;
      }}, action.type)
    } );



export const instagramState = () => state;

export const useInstagramState = () => useState(state);

export const InstagramService = {
  GetNewInstagramData: (userId, username) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${baseUrl}api/social/instagram/${userId}/${username}`)
        .then((res) => {
          console.log(res.data); // Log the response data
          resolve(res.data);
        })
        .catch((err) => {
          console.error('Error:', err);
          reject(err);
        });
    });
  },

  GetInstagramDemographics: (data) => {
    const dispatch = useDispatch();
   return axios
      .post(`${baseUrl}api/influencers/instagram/demographics`, data)
      .then((res) => {
        dispatch(InstagramAction.getInstagramDemographics(res)  );
      })
      .catch((err) => { });
  },
  }




export const InstagramAction = {
  getNewInstagramData: (data) => {
    return {
      type: 'INSTAGRAM_ANALYTICS_LOADED',
      data: data
    };
  },
  getInstagramDemographics: (data) => {
    return {
      type: 'INSTAGRAM_DEMOGRAPHICS_LOADED',
      data: data
    };
  }
}