import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';

//State
const state = createState({
  events: [],
  event: [],
  retrieving: false,
  fetched: false,
  loading: false,
  updateNeed: true
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'EVENT_LOADED':
        return s.merge({
          events: action.data,
          loading: false,
          updateNeed: false,
          retrieving: true
        });
      case 'CREATE_EVENT':
        return s.merge({
          event: action.data,
          loading: false,
          updateNeed: false
        });
      case 'UPDATE_EVENT':
        return s.merge({
          loading: false,
          updateNeed: true
        });
      case 'SINGLE_EVENT':
        return s.merge({
          event: action.data,
          loading: false,
          fetched: true,
          retrieving: true,
          updateNeed: false
        });
      case 'COMMUNITY_EVENTS_LOADED':
        return s.merge({
          events: action.data,
          loading: false,
          updateNeed: false,
          retrieving: true
        });
      case 'DELETE_EVENT':
        return s.merge({
          loading: false,
          updateNeed: true
        });
      case 'USER_EVENT':
        return s.merge({
          events: action.data,
          loading: false,
          updateNeed: false
        });
      default:
        return null;
    }
  }, action.type);
});

export const accessEventState = () => state;

export const useEventState = () => useState(state);

//Service
export const EventService = {
  FetchEvents: () => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/events`)
      .then((res) => {
        dispatch(EventAction.loadEvents(res.data.result));
      })
      .catch((err) => {});
  },
  AddEvent: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/createEvent`, data)
      .then((res) => {
        dispatch(EventAction.createEvent(res.data));
      })
      .catch((err) => {});
  },
  UpdateEvent: async (id, data) => {
    const dispatch = useDispatch();
    await axios
      .put(`${baseUrl}api/updateEvent/${id}`, data)
      .then((res) => {
        dispatch(EventAction.updateEvent(res.data));
      })
      .catch((err) => {});
  },
  FetchSingleEvent: async (id) => {
    const dispatch = useDispatch();
    await axios
      .get(`${baseUrl}api/events/${id}`)
      .then((res) => {
        dispatch(EventAction.singleEvent(res.data.result));
      })
      .catch((err) => {});
  },
  FetchCommunityEvents: async (id) => {
    const dispatch = useDispatch();
    await axios
      .get(`${baseUrl}api/communities/events/${id}`)
      .then((res) => {
        dispatch(EventAction.communityEvents(res.data.result));
      })
      .catch((err) => {});
  },
  DeleteSingleEvent: async (id, data) => {
    const dispatch = useDispatch();
    await axios
      .delete(`${baseUrl}api/deleteEvent/${id}`, { data: data })
      .then((res) => {
        dispatch(EventAction.deleteEvent(res));
      })
      .catch((err) => {});
  },
  FetchUserEvent: async (id) => {
    const dispatch = useDispatch();
    await axios
      .get(`${baseUrl}api/users/events/${id}`)
      .then((res) => {
        dispatch(EventAction.loadUserEvent(res.data.result));
      })
      .catch((err) => {});
  }
};

//Action
export const EventAction = {
  loadEvents: (data) => {
    return {
      type: 'EVENT_LOADED',
      data: data
    };
  },
  createEvent: (data) => {
    return {
      type: 'CREATE_EVENT',
      data: data
    };
  },
  updateEvent: (data) => {
    return {
      type: 'UPDATE_EVENT',
      data: data
    };
  },
  singleEvent: (data) => {
    return {
      type: 'SINGLE_EVENT',
      data: data
    };
  },
  communityEvents: (data) => {
    return {
      type: 'COMMUNITY_EVENTS_LOADED',
      data: data
    };
  },
  deleteEvent: (data) => {
    return {
      type: 'DELETE_EVENT',
      data: data
    };
  },
  loadUserEvent: (data) => {
    return {
      type: 'USER_EVENT',
      data: data
    };
  }
};
