import React, { useEffect, useState, useRef, useContext } from 'react';
import { useAssetState, AssetService } from '../../Service/AssetService';
import { useDraftState, DraftService } from '../../Service/DraftService';
import { useTaskState, TaskService } from '../../Service/TaskServices';
import AssetDeploy from './AssetDeploy';
import Loader2 from '../Loader2';
import { useAuth } from '../../Context/AuthContext';
import { Link } from 'react-router-dom';
import { validateFormFields } from '../../utils/functions';
import { toast, Toaster } from 'react-hot-toast';
import moment from 'moment';

function Drafts() {
  const draftState = useDraftState(); 
  const { brandId } = useContext(brandId);

  const [error, setError] = useState({
    avatar: ''
  });
  const fieldErrors = {
    note: 'Please Enter Note'
  };
  const [errors, setErrors] = useState({});

  const notes = useRef('');
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };
  const [deployAsset, setDeployAsset] = useState(false);
  const [sendNote, setSendNote] = useState(false);
  const [count, setCount] = useState(0);
  const [draftData, setDraftData] = useState({
    id: '',
    draftData: ''
  });
  const approveDraft = async () => {
    let approvedData = {
      drafts: draftData.draftData.video, 
      creator_id: draftData.draftData.creator_id,
      creator_name: draftData.draftData.creator_name,
      brand_id: brandId,
      task_id: draftData.draftData.task_id,
      status: 'completed'
    };
    await TaskService.CompleteTask(draftData.draftData.task_id, approvedData);
    toast.success('Draft Approved Successfully!');
  };
  
  const deployHandle = (data, id, i) => {
    setDraftData({
      ...draftData,
      id: id,
      draftData: data
    });
    setDeployAsset(true);
    setCount(i);
  };

  const { currentUser } = useAuth();
  useEffect(() => {
    if (draftState.updateNeed.value) {
      draftState.merge({ loading: true });
      DraftService.FetchDraftsForBrand(brandId);
    }
  }, [draftState.updateNeed.value]);
  
  let draftdata = [];
if (draftState.drafts.value) {
  draftdata = Object.entries(draftState.drafts.value)
    .filter(([key, value]) => {
      // Replace 'status' with the actual property name in your data structure
      return value.campaign !== true && value.status !== 'approved' && value.status !== 'completed';
    })
    .map(([key, value]) => ({
      [key]: value
    }));
}
  let assetLen = draftdata.length;
  
  {/*useEffect(() => {
    draftdata.map((item, i) => {
      let draftObj = {};
      const data = item[Object.keys(item)];
      draftObj.data = data;
      const id = Object.keys(item);
      draftObj.id = id;
  
      // Check if the draft has an "approved" status
      const hasApprovedStatus = Object.values(data).some(
        (value) => value.status === "approved"
      );
  
      if (!hasApprovedStatus) {
        if (i === count) {
          setDraftData({
            ...draftData,
            id: id,
            draftData: data
          });
          setDeployAsset(true);
        }
      }
    });
  }, [draftdata, count]); */}

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});
    const formValues = {
      notes: notes.current.value
    };

    const validationErrors = validateFormFields(formValues, fieldErrors);
    if (Object.keys(validationErrors).length) {
      return setErrors(validationErrors);
    }

    const temp = {
      creator_id: draftData.draftData.creator_id,
      brand_id: brandId,
      notes: notes.current.value
    };
    console.log("creator_id in handleSubmit:", draftData);
    if (brandId) TaskService.AddRevisionNote(draftData.draftData.task_id, temp);
    setSendNote(false);
    toast.success('Revision Notes Sent');
    DraftService.FetchDraftsForBrand(brandId);
  };

  return (
    <div className="m-4 bg-white w-full p-4 rounded-lg z-100">
       <Toaster />
      <div className="flex items-center justify-between">
        <h3 className="font-semibold p-8 md:text-2xl xs:text-sm">User-Generated Videos</h3>
      </div>
      <div>
        {!draftState.loading.value && draftdata.length === 0 ? (
          <div className="w-full">
            <div className="text-center font-bold mx-auto mt-6 mb-4 text-2xl">
              You currently have no UGC assets, when you do they will appear here. To request UGC
              videos, click below
            </div>
            <div className="mt-6 text-center">
              <Link
                className="bg-Blue font-bold px-2 py-2 rounded-md my-1 text-white text-lg w-32 items-left justify-left text-center cursor-pointer"
                to="/brand/dashboard/tasks">
                Create UGC Task
              </Link>
            </div>
          </div>
        ) : (
          <div
            className={`grid md:grid-cols-3 grid-cols-1 md:grid-cols-2 gap-2 mx-2 pb-5`}
            style={{ height: '65vh' }}>
            <div className={`mb-5`} style={{ height: '65vh' }}>
              {draftState.loading.value ? (
                <Loader2 />
              ) : (
                <>
                  <>
                    {draftdata.length > 0 && (
                      <div className="overflow-y-scroll w1/3 pb-5" style={{ height: '65vh' }}>
                        {draftdata.length > 0 &&
                          draftdata.reverse().map((item, i) => {
                            const data = item[Object.keys(item)];
                            const id = Object.keys(item);
                            if (draftData.id === '' && i === count) {
                              deployHandle(data, id, i);
                            }
                            return (
                              <div className={`z-100 bg-${i % 2 === 0 ? 'lightGray' : 'white'} `}>
                                <div
                                  className={`max-h-min z-0 p-3 rounded-lg my-2 bg-${
                                    i === count && 'black text-white'
                                  } cursor-pointer`}
                                  onClick={() => deployHandle(data, id, i)}>
                                  <h3 className={`text-3xl font-semibold truncate `}>
                                    {data.creator_name}
                                  </h3>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </>
                </>
              )}
            </div>
            <div className="text-center bg-lightGray rounded-lg" style={{ height: '65vh' }}>
              {deployAsset ? (
                <AssetDeploy
                  open={deployAsset}
                  draftData={draftData.draftData}
                  approveDraft={() => approveDraft()}
                  onClose={() => setDeployAsset(false)}
                  id={draftData.id}
                  count={count}
                  assetLen={assetLen}
                  dCount={() => setCount(count - 1)}
                  eCount={() => setCount(count + 1)}
                  setSendNote={() => setSendNote(true)}
                  sendNote={sendNote}
                />
              ) : null}
            </div>
            {sendNote ? (
              <form onSubmit={handleSubmit} className="mb-5" style={{ height: '65vh' }}>
                <div className="w-full bg-gray-200 mb-5 rounded-lg" style={{ height: '45vh' }}>
                  <textarea
                    className="focus:outline-none w-full bg-lightGray p-2 rounded-lg"
                    style={{ height: '45vh' }}
                    placeholder="Please provide revision note to the creator here..."
                    name="notes"
                    ref={notes}
                  />
                </div>
                <div className="submit-note items-center">
                  <button type="submit" className="bg-red text-white px-4 py-2 rounded-lg">
                    Submit Notes
                  </button>
                </div>
              </form>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}

export default Drafts;
