import React from 'react';
import GlobalDialog from '../../utils/globalDialog';
import { BsExclamationCircle } from 'react-icons/bs';
import { OfferService } from '../../Service/OfferService';

function ApproveModal(props) {
  const handleApproveRec = () => {
    const temp = {};
    temp.creatoruid = props.offerApproveData.creator_uid;
    temp.branduid = props.offerApproveData.brand_uid;
    temp.offer_id = props.offerApproveData.offer_id;
    OfferService.ApproveOffer(props.offerApproveData.offer_id, temp);
    props.onClose();
  };
  return (
    <GlobalDialog open={props.open} onClose={props.onClose}>
      <div className=" bg-white rounded-lg dark:bg-gray-700">
        <div className="p-6 pt-0 text-center">
          <BsExclamationCircle className="mx-auto mb-4 w-32 h-14 text-gray-400 dark:text-gray-200" />
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            Are you sure you want to approve this offer?
          </h3>
          <button
            type="button"
            className="text-white bg-red hover:bg-red-800  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
            onClick={() => handleApproveRec()}>
            Yes, I'm sure
          </button>
          <button
            type="button"
            className="text-gray-500 bg-white hover:bg-gray-100  rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600"
            onClick={() => props.onClose()}>
            No, cancel
          </button>
        </div>
      </div>
    </GlobalDialog>
  );
}

export default ApproveModal;
