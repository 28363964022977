import React, { useState } from 'react';
import GlobalDialog from '../../utils/globalDialog';
import ConfirmModalTask from './SendTaskProposal';
import { BsChevronCompactDown, BsChevronCompactUp } from 'react-icons/bs';
import Collapsible from 'react-collapsible';
import moment from 'moment';
import { useAuth } from '../../Context/AuthContext';

function ProposalModal({ open, onClose, id, task }) {
  const { currentUser } = useAuth();
  const [tab, setTab] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const timezone = moment.tz.guess();
  var task_deadline_time = moment(task.deadline).tz(timezone).format('MMM. D, YYYY');
  const proposalHandle = () => {
    tab ? setTab(2) : setTab(1);
    confirm ? setConfirm(false) : setConfirm(true);
  };

const userProposals = [];

for (const proposalId in task.proposals) {
  const proposal = task.proposals[proposalId];
  if (proposal.creator_id === currentUser.uid) {
    userProposals.push(proposal.proposal);
  }
}
  // look under tasks -> proposlas
  return (
    <>
      {tab === 0 ? (
        <GlobalDialog className="px-3" open={open} onClose={onClose}>
          <Collapsible
            trigger={[
              'Brief Details',
              <div className="px-3 absolute right-0">
                <BsChevronCompactDown />
              </div>
            ]}
            triggerWhenOpen={[
              'Brief Details',
              <div className="px-3 absolute right-0">
                <BsChevronCompactUp />
              </div>
            ]}
            open={false}
            contentOuterClassName="mb-6"
            triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
          <div className="m-3 bg-white rounded-lg text-3xl dark:bg-gray-700">
            <div className="flex ml-12 mb-5">
              <div className="flex mr-5 font-bold">Brand:</div>
              <a className= "text-blue underline" target="_blank" href={`http://${task && task.brand_website}`} rel="noreferrer" >{task && task.brand_name}</a>
            </div>

            <div className="flex ml-12 mb-5">
              <div className="flex mr-5 font-bold">Deadline:</div>
              <div>{task && task_deadline_time}</div>
            </div>
            <div className="flex ml-12 mb-5">
              <div className="flex mr-5 font-bold">Brief:</div>
              <div className="flex flex-col space-y-2">
                <div> {task && task.recipe_videos > 0 && (`Create ${task.recipe_videos} short form recipe videos showcasing the brand as organically (not ad-style or salesy) as possible.`)}</div>
                <div> {task && task.product_showcase > 0 && (`Create a short form product demo video showcasing the brand as organically (not ad-style or salesy) as possible.`)}</div>
                <div> Crafted and the brand will own these videos and will post them on their social accounts, use them for paid social, website, etc. in perpetuity.</div>
              </div>
            </div>
            <div className="flex ml-12 mb-5">
              <div className="flex mr-5 font-bold">Task Name: <p className='font-thin ml-2'>{task && task.name}</p></div>
            </div>
            <div className="ml-12 mb-5">
              <div>
                <div className="mt-2 font-bold">
                  {task && task.brand_name} is prioritizing these products/flavors:
                </div>
                <div className="">
                  {task && task.product_name}
                </div>
              </div>
              <div>
                <div className="mt-2 font-bold">
                  {task && task.brand_name}'s Target Audience:
                </div>
                <div className="">
                  {task && task.target_audience}
                </div>
              </div>
              <div>
                <div className="mt-2 font-bold">
                  Key talking points for creators:
                </div>
                <div className="">
                  {task && task.talking_points}
                </div>
              </div>
            </div>
          </div>
          </Collapsible>
          <Collapsible
            trigger={[
              'My Proposal',
              <div className="px-3 absolute right-0">
                <BsChevronCompactDown />
              </div>
            ]}
            triggerWhenOpen={[
              'My Proposal',
              <div className="px-3 absolute right-0">
                <BsChevronCompactUp />
              </div>
            ]}
            open={false}
            contentOuterClassName="mb-6"
            triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
            triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
              <div className='p-3'>
              {userProposals.length > 0 && (
            <p>Proposal: {userProposals[userProposals.length - 1]}</p>
          )}
              </div>  
            </Collapsible>

          <div className='text-center p-3'> You will be notified here once a brand has accepted your proposal. If you do not hear from us about this task, please continue monitoring this page for future opportunities! </div>
          <div className=" pb-4 font-bold text-red items-center justify-center text-center">
                Waiting for {task.brand_name} to approve your proposal.
          </div>
        </GlobalDialog>
      ) : null}
      {tab === 1 ? <ConfirmModalTask open={confirm} onClose={() => proposalHandle()} id={id} task={task} deadline={task_deadline_time} /> : null}
    </>
  );
}

export default ProposalModal;
