import React, { useEffect, useState } from 'react';
import { CampaignState } from '../../../../utils/campaignState';
import { useCampaignState, CampaignService } from '../../../../Service/CampaignServices';
import { useTabState } from '../../../../utils/TabState';
import twitter from '../../../../Assets/twitter.png';
import tiktok from '../../../../Assets/tiktok-square.png';
import instagram from '../../../../Assets/instagram (1).png';
import 'react-responsive-modal/styles.css';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../Context/AuthContext';
import SendProductModal from './SendProduct';
import UndoFulfillmentModal from '../UndoFulfillmentModal';
import CustomButton from '../../../../utils/customButton';

export default function   CompaignDetails(props) {
  const campaignState = useCampaignState();
  const [open, setOpen] = useState();
  const [proposal, setProposal] = useState({});
  const [task, setTask] = useState({})
  const [modal, setModal] = useState(false);
  const [fulfillmentUndo, setFulfillmentUndo] = useState();
  const [taskId, setTaskId] = useState({});
  const [brandId, setBrandId] = useState({}); 
  const [creatorId, setCreatorId] = useState({});
  const [loading, setLoading] = useState(false);
  const [proposalId, setProposalId] = useState();
  const [fulfillment, setFulfillment] = useState();
  const { id } = useParams();
  const {currentUser} = useAuth();

  const fetchData = async () => {
    if (id) {
      setLoading(true); 
      campaignState.merge({loading: true});
      await CampaignService.FetchSingleCampaign(id);
     
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, campaignState.updateNeed.value]);
  
  const res = JSON.stringify(campaignState.campaign.value);
  const campaign = JSON.parse(res);
  
  const tasks = campaign.tasks || {};
  //console.log("tasks", tasks);
  const handleButtonClick = (proposal) => {
    setModal(true);
    setFulfillmentUndo(proposal);
  };
  

  const fullfill = [
    { product1: 'Sweet Potato Brownies...', product2: 'White Chocolate Browni...' },
    { product1: 'Sweet Potato Brownies...', product2: 'White Chocolate Browni...' },
    { product1: 'Sweet Potato Brownies...', product2: 'White Chocolate Browni...' }
  ];

  const handleSendProduct = (proposal, creator_id, task_id, brand_id, brand_name, product_name, product_id, proposalId) => {
    let selected_task = {
      taskId: task_id,
      creatorId: creator_id,
      brandId: brand_id,
      brand_name: brand_name,
      product_name: product_name,
      product_id: product_id,
      proposal_id: proposalId
    };
    //console.log("selected_task", selected_task);
    setTask(selected_task);
    setCreatorId(creator_id);
    setTaskId(task_id); 
    setBrandId(brand_id);
    setProposal(proposal);
    setOpen(true);
  }

  const handleUndoFulfillment = (proposal, creator_id, task_id, brand_id, brand_name, proposalId, fulfillment) => {
    let selected_task = {
      taskId: task_id,
      creatorId: creator_id,
      brandId: brand_id,
      brand_name: brand_name,
      proposal_id:proposalId
    }
    setTask(selected_task);
    setCreatorId(creator_id);
    setTaskId(task_id); 
    setBrandId(brand_id);
    setProposal(proposal);
    setProposalId(proposalId);
    setFulfillment(fulfillment);
    setModal(true);
  }


  return (
<div
    className="px-3 md:px-10 md:mt-3 font-bold  overflow-x-auto h-40">
{tasks && Object.values(tasks).some(task => task.approved_proposals) ? (
  <>
    {Object.entries(tasks).map(([task_id, item]) => {
        //console.log("task_id: ", task_id); 
        const brand_id = item.brand_id; 
        const product_name = item.product_name;
        const product_id = item.product_id;
        const brand_name = item.brand_name;
              return (
                <div key = {task_id} className='mb-10 overflow-x-auto shadow-md rounded-md'>          
                   <div className="w-full shadow-md rounded-md">
                      <h1 className="2xl:text-2xl md:text-base  bg-gray-700 w-full py-3 text-white rounded font-bold">
                      <p className='ml-5'>
                      {
                      item &&
                      `${item.name} (Fulfillment completed: ${
                        Object.values(item && item.approved_proposals || {}).filter(proposal => proposal.fulfillment).length
                      } / ${Object.entries(item && item.approved_proposals || {}).length} )`
                    }
                      </p>
                      </h1>
                    
                    <table className=" 2xl:text-2xl md:text-0.7  font-thin rounded-lg table-auto">
                      <thead className="w-full gap-x-8">
                          <tr className={`h-auto mx-2 bg-lightGray border-none `}>
                        <th className=" px-6 py-3 text-left leading-4 md:w-1/6 lg:w-1/6 tracking-wider">
                              Product
                            </th>
                            <th className=" px-6 py-3 text-left text-sm leading-4 md:w-1/6 lg:w-1/6 tracking-wider ">
                              Ship to Name
                            </th>
                            <th className=" px-6 py-3 text-left text-sm leading-4 md:w-1/6 lg:w-1/6 tracking-wider">
                              Address 1
                            </th>
                            <th className=" px-6 py-3 text-left text-sm leading-4 md:w-1/6 lg:w-1/6 tracking-wider">
                              Address 2
                            </th>
                            <th className=" px-6 py-3 text-left text-sm leading-4 md:w-1/6 lg:w-1/6 tracking-wider">
                              City
                            </th>
                            <th className=" px-6 py-3 text-left text-sm leading-4 md:w-1/6 lg:w-1/6 tracking-wider">
                              State
                            </th>
                            <th className=" px-6 py-3 text-left text-sm leading-4 md:w-1/6 lg:w-1/6 tracking-wider">
                              Zipcode
                            </th>
                            <th
                              className=" px-6 py-3 text-left text-sm leading-4 md:w-1/6 lg:w-1/6 tracking-wider">
                              Send Product
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white">
                        {item.approved_proposals && 
                          Object.entries(item.approved_proposals)
                            .map(([creator_id, proposal], i) => {
                              const proposalId = Object.keys(proposal)[0];
                              const hasFulfillment = proposal.fulfillment;
                              return (
                                <tr
                                  key={creator_id}
                                  className={`h-auto mx-2 bg-white border-b-2 border-gray-200`}
                                >
                                  <td className="px-6 py-4 whitespace-no-wrap">
                                    <div className="items-center">{product_name}</div>
                                  </td>
                                  <td className="px-6 py-4 whitespace-no-wrap">
                                    <div className="flex items-center">
                                      <div className="text-sm leading-5 text-gray-800">
                                        {proposal[proposalId].creator_address &&
                                          proposal[proposalId].creator_address.fullname}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="px-6 py-4 whitespace-no-wrap">
                                    <div className="flex items-center">
                                      <div className="text-sm leading-5 text-gray-800">
                                        {proposal[proposalId].creator_address &&
                                          proposal[proposalId].creator_address.address1}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="px-6 py-4 whitespace-no-wrap">
                                    <div className="flex items-center">
                                      <div className="text-sm leading-5 text-gray-800">
                                        {proposal[proposalId].creator_address &&
                                          proposal[proposalId].creator_address.address2}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="px-6 py-4 whitespace-no-wrap">
                                    <div className="flex items-center">
                                      <div className="text-sm leading-5 text-gray-800">
                                        {proposal[proposalId].creator_address &&
                                          proposal[proposalId].creator_address.city}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="px-6 py-4 whitespace-no-wrap">
                                    <div className="flex items-center">
                                      <div className="text-sm leading-5 text-gray-800">
                                        {proposal[proposalId].creator_address &&
                                          proposal[proposalId].creator_address.state}
                                      </div>
                                    </div>
                                  </td>
                                  <td className=" px-8 py-4 whitespace-no-wrap">
                                    <div className="flex items-center">
                                      <div className="text-sm leading-5 text-gray-800">
                                        {proposal[proposalId].creator_address &&
                                          proposal[proposalId].creator_address.zipcode}
                                      </div>
                                    </div>
                                  </td>
                                  <td className=" px-6 py-4 whitespace-no-wrap">
                                    <div className="flex items-center">
                                    {hasFulfillment ? 
                                     (
                                        <CustomButton
                                        onClick={() =>
                                          handleUndoFulfillment(
                                            proposal,
                                            creator_id,
                                            task_id,
                                            brand_id, 
                                            brand_name,
                                            proposalId,
                                            proposal.fulfillment
                                          )
                                        }
                                        className="2xl:px-5 2xl:py-2 md:px-3  text-xs text-white rounded focus:outline-none">
                                          Undo Fulfillment ({proposal.fulfillment.type})
                                        </CustomButton>
                                      ) : (
                                      <CustomButton
                                        className="2xl:px-5 2xl:py-2 md:px-3  text-white rounded focus:outline-none"
                                        onClick={() =>
                                          handleSendProduct(
                                            proposal,
                                            creator_id,
                                            task_id,
                                            brand_id, 
                                            brand_name,
                                            product_name,
                                            product_id,
                                            proposalId
                                          )
                                        }
                                      >
                                        Send Product
                                      </CustomButton>)
                                         }
                                    </div>
                                  </td>
                                         
                                </tr>
                              );
                            })}
                        </tbody>
                  </table>
                  </div>
                </div>
              );
            }
          )}
        </>
      ) : (
        <div className="h-full w-full flex items-center justify-center">
          <p>No Products available</p>
        </div>
      )}
{open ?
  <SendProductModal
    open={open}
    proposal={proposal}
    creator_id={creatorId}
    task_id={taskId}
    brand_id={brandId}
    task={task}
    id={id}
    fetchData={fetchData}
    name = {campaign.campaign_name}
    onClose={() => setOpen(false)}
  />
  : null }   
    {modal ? (
     <div className="fixed top-0 left-0 right-0 bottom-0 flex  break-words justify-center h-full w-full items-center z-50 bg-black bg-opacity-10">
    <UndoFulfillmentModal
      proposal={proposal}
      creator_id={creatorId}
      proposalId={proposalId}
      task_id={taskId}
      fulfillment={fulfillment}
      brand_id={brandId}
      task={task}
      id={id}
      fetchData={fetchData}
      close = {() => setModal(false)}
    />
      </div>
    ): null}
  </div>
  );
}
