import React, { useState, useEffect, useContext } from 'react';
import Loader from '../../Loader2';
import GenerateBriefModal from './GenerateBriefModal';
import moment from 'moment';
import { useGenerativeBriefState, GenerativeBriefService } from '../../../Service/GenerativeBriefService';
import { BrandContext } from '../../../routes/PrivateRoute/BrandDashboardRoute';

const GenerateBriefs = (props) => {
  const [productDescription, setProductDescription] = useState('');
  const [uniqueSellingPoints, setUniqueSellingPoints] = useState('');
  const [targetAudience, setTargetAudience] = useState('');
  const [campaignPurpose, setCampaignPurpose] = useState('');
  const [videoConcept, setVideoConcept] = useState('');
  const [generate, setGenerate] = useState(false);
  const {brandId} = useContext(BrandContext);
  const [loading, setLoading] = useState(true);
  const briefState = useGenerativeBriefState();
  // fetching brief templates
  useEffect(() => {
    if (briefState.updateNeed.value) {
      GenerativeBriefService.getBrandGenBriefs(brandId);
    }
  }, [briefState.updateNeed.value]);

  const briefs = briefState.briefs.value.data;
  console.log("briefs: ", briefs)
  return (
    <div className="m-4 bg-white w-full rounded-lg z-100" style={{ height: 'calc(100vh - 7rem)' }}> 
     <div className="flex items-center h-16 justify-between ">
        <h3 className="font-semibold p-8 pl-2 ml-5 2xl:text-2xl md:text-0.7 xs:text-0.7">Brief Builder</h3>
        <button
          className="bg-red text-white 2xl:text-base md:text-base 2xl:px-4 2xl:py-2 md:px-2 md:py-1 rounded-lg mr-8"
          onClick={() => setGenerate(true)}>
          + Generate Brief
        </button>
      </div>
      <p className='text-gray-500 2xl:text-base md:text-0.6 ml-8'>Stuck on any creative ideas for a brief? Here you can generate a brief, and use the brief in Crafted Studio. </p>
      <div className='2xl:h-full h-35rem mx-auto flex flex-col items-center overflow-auto'>
        
        {briefs === null || Object.keys(briefState.briefs.value).length === 0 ? (
          <p className='flex items-center mt-10 2xl:text-base md:text-0.7 justify-center font-bold'>No generated briefs available</p>
          ) : (
          <div className='mt-4 w-11/12 flex flex-col'>
            <div className='rounded-full p-2 px-3 font-bold w-fit bg-gray-200 2xl:text-base md:text-sxs items-start'>Briefs</div>
           <table className='w-11/12 mt-5 p-2 overflow-auto border-2 border-gray-200 '>
  <thead className='bg-gray-200 mx-10 rounded-md 2xl:text-2xl md:text-sxs'>
    <tr>
      <th style={{ width: '25%', marginLeft: '5px', marginRight: '10px' }} className='py-1 '>BRIEF NAME</th>
      <th style={{ width: '25%', marginLeft: '10px', marginRight: '10px' }} className='py-1 text-center'>CREATED</th>
      <th style={{ width: '25%', marginLeft: '10px', marginRight: '10px' }} className='py-1 text-center'>LAST EDITED</th>
      <th style={{ width: '25%', marginLeft: '10px', marginRight: '10px' }} className='py-1 text-center'>ACTIONS</th>
    </tr>
  </thead>
  <tbody>
    {Object.values(briefs).map((brief) => (
      <tr className='hover:bg-gray-100'>
        <td style={{ width: '25%', marginLeft: '5px', marginRight: '10px' }} className='2xl:py-4 md:py-2 border-b text-black font-bold 2xl:text-base text-center md:text-sxs overflow-hidden'>{brief.name || ""}</td>
        <td style={{ width: '25%', marginLeft: '10px', marginRight: '10px' }} className='2xl:py-4 md:py-2 border-b text-black 2xl:text-base text-center md:text-sxs'>{moment(brief.date_created).format('MM/DD/YYYY, hh:mm A') || ""}</td>
        <td style={{ width: '25%', marginLeft: '10px', marginRight: '10px' }} className='2xl:py-4 md:py-2 border-b text-black  2xl:text-base text-center md:text-sxs'>{(brief.updated) || moment(brief.date_created).format('MM/DD/YYYY, hh:mm A')}</td>
        <td style={{ width: '25%', marginLeft: '10px', marginRight: '10px' }} className='2xl:py-4 md:py-2 border-b w-1/4'>
          {/* Add actions here */}
        </td>
      </tr>
    ))}
  </tbody>
</table>
          </div>
        )}
        </div>
      
      
      
      {generate ? (
      <GenerateBriefModal
        open={generate}
        onClose={() => setGenerate(false)}
      />) : null
      }
    </div>
  
  );
};

export default GenerateBriefs;