import React, { useState, useEffect } from "react";
import { useAuth } from "../../Context/AuthContext";
import toast, { Toaster } from "react-hot-toast";
import Loader2 from "../Loader2";
import AddSocialModal from "./AddSocialModal";
import Modal from "../../utils/brandModal";
import MailingAddressModal from "./MailingAddressModal";
import { BsCheckCircle } from "react-icons/bs";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  AuthService,
  userAuthstate,
} from "../../Service/authenticationService";
import { UsersService } from "../../Service/userServices";
import Portfolio from "./Onboarding/Portfolio";
import { baseUrl } from "../../baseUrl";
import Loader from "../Loader";
import InstagramHandleModal from "./InstagramHandleModal";
import SettingsHelpModal from "./SettingsHelpModal";
import InstagramOnboardingInfoModal from "./InstagramOnboardingInfoModal";
import CustomButton from "../../utils/customButton";

const { REACT_APP_TIKTOK_REDIRECT_URI_PROD } = process.env;

function Profile() {
  const { currentUser } = useAuth();
  const [editingEmail, setEditingEmail] = useState(false);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [instagramInfo, setInstagramInfo] = useState(null);
  const [zipcode, setZipcode] = useState("");
  const [success, setSuccess] = useState(false);
  const [igModal, setIgModal] = useState(false);
  const [fullname, setFullname] = useState("");
  const [mailingAddress, setmailingAddress] = useState(false);
  const [instagramHandle, setInstagramHandle] = useState(false);
  const [settingsHandle, setSettingsHandle] = useState(false);
  const [address1, setAddress1] = useState("");
  const [email, setEmail] = useState();
  const [address2, setAddress2] = useState("");
  const [currentAddress, setCurrentAddress] = useState({
    currentFullName: "",
    currentAddress1: "",
    currentAddress2: "",
    currentCity: "",
    currentState: "",
    currentZip: "",
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulating an API call or any asynchronous task
    setTimeout(() => {
      setIsLoading(false);
    }, 580);
  }, []);

  const authState = userAuthstate();

  const disconnectTikTok = async () => {
    const creator_uid = currentUser.uid;
    const accessTokenResponse = await fetch(
      `${baseUrl}api/influencers/tiktok/${creator_uid}/disconnect`,
      {
        method: "POST",
      },
    );
    window.location.reload();
  };

  const disconnectInstagram = async () => {
    const creator_uid = currentUser.uid;
    const accessTokenResponse = await fetch(
      `${baseUrl}api/influencers/instagram/disconnect/${creator_uid}`,
      {
        method: "POST",
      },
    );
    window.location.reload();
  };

  const getUserInfo = async () => {
    try {
      const creator_uid = currentUser.uid;

      // Retrieving the TikTok access token from backend API
      const accessTokenResponse = await fetch(
        `${baseUrl}api/influencers/tiktok/access_token/${creator_uid}`,
      );

      if (!accessTokenResponse.ok) {
        throw new Error("Failed to retrieve access token");
      }

      const accessTokenData = await accessTokenResponse.json();

      const accessToken = accessTokenData.result;
      const response = await fetch(
        "https://open.tiktokapis.com/v2/user/info/?fields=open_id,union_id,avatar_url,follower_count,likes_count,view_count,video_count,is_verified,profile_deep_link,display_name,likes_count",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if (response.ok) {
        const data = await response.json();
        setUserInfo(data);
      } else {
        throw new Error("Request failed");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error retrieving user info:", error);
    }
  };

  // Calling the function to retrieve user info
  useEffect(() => {
    getUserInfo();
  }, []);

  const getInstagramInfo = async () => {
    const creator_uid = currentUser.uid;
    try {
      // Retrieving the TikTok access token from backend API
      const instagramResponse = await fetch(
        `https://graph.facebook.com/v18.0/${
          user &&
          user.creator_socials &&
          user.creator_socials.instagram &&
          user.creator_socials.instagram.instagram_business_account_id
        }?fields=username,profile_picture_url,followers_count&access_token=${
          user &&
          user.creator_socials &&
          user.creator_socials.instagram &&
          user.creator_socials.instagram.access_token
        }`,
      );

      if (instagramResponse.ok) {
        const data = await instagramResponse.json();
        setInstagramInfo(data);
        console.log(instagramInfo);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInstagramInfo();
  }, []);

  useEffect(() => {
    if (currentUser.uid !== null) {
      AuthService.FetchAuthUser(currentUser.uid);
    }
  }, []);

  let user = authState.authUser.value;

  const handleUpdateEmail = () => {
    const data = {
      email: email,
    };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address.");
      return;
    } else {
      UsersService.UpdatePayPalEmail(currentUser.uid, data);
      setEditingEmail(false);
      toast.success("Email updated successfully!");
      window.location.reload();
    }
  };

  const negotiated = user && user.negotiated;

  const tiktokRate =
    user &&
    user.creator_socials &&
    user.creator_socials.tiktok &&
    user.creator_socials.tiktok.performance &&
    user.creator_socials.tiktok.performance.suggestedRate;

  const instagramRate =
    user &&
    user.creator_socials &&
    user.creator_socials.instagram &&
    user.creator_socials.instagram.suggested_rate;

  useEffect(() => {
    if (user) {
      setCurrentAddress({
        currentFullName: user.shipping_details
          ? user.shipping_details.fullname
          : null,
        currentAddress1: user.shipping_details
          ? user.shipping_details.address1
          : null,
        currentAddress2: user.shipping_details
          ? user.shipping_details.address2
          : null,
        currentCity: user.shipping_details ? user.shipping_details.city : null,
        currentState: user.shipping_details
          ? user.shipping_details.state
          : null,
        currentZip: user.shipping_details
          ? user.shipping_details.zipcode
          : null,
      });
    }
  }, [authState.updateNeed.value]);

  function handleChange(address) {
    //HANDLE CHANGE
    setAddress1(address);
  }

  function handlePortfolio() {
    // DO NOTHING
    alert("Creator Portfolio Updated!");
  }

  function handleSelect(address, placeId, suggestion) {
    //HANDLE SELECT
    setAddress1(suggestion.formattedSuggestion.mainText);
    setCity(suggestion.terms[suggestion.terms.length - 3].value);
    setState(suggestion.terms[suggestion.terms.length - 2].value);
  }

  const mailingAddressHandle = () => {
    setmailingAddress(!mailingAddress);
  };

  const instagramHandleModal = () => {
    setInstagramHandle(!instagramHandle);
  };
  const settingsHelpModal = () => {
    setSettingsHandle(!settingsHandle);
  };
  function saveAddress() {
    let mailing_address = {
      fullname: fullname,
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      country: "USA",
      zipcode: zipcode,
    };

    UsersService.AddAddress(currentUser.uid, mailing_address);

    setSuccess(true);

    setTimeout(() => {
      setSuccess(false);
    }, 10000);
  }

  const csrfState = Math.random().toString(36).substring(2);

  const startTikTokLoginFlow = () => {
    // TODO: Fix hardcoded API urls and redirect_uri. redirect_uri must be environment based.
    const tikTokAuthURL = `https://www.tiktok.com/v2/auth/authorize/?client_key=${process.env.REACT_APP_TIKTOK_CLIENT_KEY}&response_type=code&scope=user.info.stats,user.info.basic,user.info.profile,video.list&redirect_uri=${REACT_APP_TIKTOK_REDIRECT_URI_PROD}&state=${csrfState}`;

    // Store the CSRF token in a cookie or in a secure place on the client-side
    document.cookie = `csrfState=${csrfState}; path=/`;

    // Open the TikTok authorization URL in the same tab/window
    window.location.href = tikTokAuthURL;
  };

  const startInstagramLoginFlow = () => {
    const clientId = process.env.REACT_APP_FACEBOOK_APP_CLIENT_ID;
    const redirectUri =
      "https://www.app.cookwithcrafted.com/creator/dashboard/instagram-success";
    const scope =
      "instagram_basic,instagram_manage_insights,pages_show_list,pages_read_engagement,business_management";
    const display = "page";
    const extras = { setup: { channel: "IG_API_ ONBOARDING" } };
    const responseType = "token";
    const authUrl = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${clientId}&display=${display}&extras=${extras}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}`;

    window.location.href = authUrl;
  };

  return (
    <div
      className="bg-white m-4 xs:m-0 rounded-lg w-full overflow-y-scroll p-6 "
      style={{ height: "85vh" }}
    >
      <h2 className="font-semibold py-2 ml-2 text-2xl">Settings</h2>
      <Toaster />
      <>
        {isLoading ? (
          <Loader2 />
        ) : (
          <div>
            {" "}
            <div className="flex justify-end 2xl:mr-12rem md:mr-5rem">
              <CustomButton
                onClick={settingsHelpModal}
                className="flex  mt-4 font-bold text-white 2xl:py-2 2xl:px-4 md:py-1 md:px-2 2xl:text-base md:text-0.7 xs:text-sxs items-center justify-center"
              >
                <AiOutlineQuestionCircle className="mr-2" />
                Help
              </CustomButton>
            </div>
            <div className="h-fit flex flex-col items-center justify-center mx-auto">
              <h2 className="font-semibold py-2 text-2xl xs:text-xs my-8 ml-10">
                Connected Accounts
              </h2>
              <div className="gap-y-5 flex flex-col w-5/6 mx-10">
                <div className="flex flex-col">
                  <div className="w-full rounded-md flex md:flex-row flex-col items-center md:justify-between shadow-md border-t-2 xs:text-sxs border-gray-200 py-5">
                    {userInfo ? (
                      <>
                        <a
                          className="tiktok md:ml-5 md:mb-2 w-8 h-8 md:w-14 mx-2 md:h-14 rounded-lg xs:mb-2"
                          href={userInfo.data.user.profile_deep_link}
                          target="_blank"
                        ></a>
                        <div className="flex items-center mb-3 mt-0.5">
                          <img
                            className="md:w-10 md:h-10 w-4 h-4 border-2 rounded-full ml-3"
                            src={userInfo.data.user.avatar_url}
                            alt="TikTok Profile Picture"
                          />
                          <span className="ml-2 font-bold px-1">
                            {userInfo.data.user.display_name}
                          </span>
                          <BsCheckCircle
                            title="Connected"
                            className="bg-Green rounded-full w-4 h-4 ml-1"
                          />
                          <span className="ml-2  xs:text-sxs font-bold">
                            {negotiated
                              ? `Negotiated rate: $${(tiktokRate / 100).toFixed(
                                  2,
                                )}`
                              : `Influencer rate: $${(tiktokRate / 100).toFixed(
                                  2,
                                )}`}
                          </span>{" "}
                           
                        </div>
                        <div className="flex-grow"></div>
                        <CustomButton
                          className="font-bold text-white text-base rounded-md py-1 px-3 md:py-3 md:px-7 md:mr-5"
                          onClick={disconnectTikTok}
                        >
                          Disconnect
                        </CustomButton>
                      </>
                    ) : (
                      <>
                        <div className="tiktok md:ml-5 md:mb-2 w-8 h-8 md:w-14 xs:w-1rem xs:h-1rem mx-2 md:h-14 rounded-lg xs:mb-2"></div>
                        <div className="flex items-center">
                          <div className="flex-grow"></div>
                          <CustomButton
                            className="font-bold text-white text-base rounded-md py-1 px-3 md:py-3 md:px-7 md:mr-5"
                            onClick={startTikTokLoginFlow}
                          >
                            Connect
                          </CustomButton>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex flex-col xs:text-sxs">
                  <div className="w-full rounded-md flex md:flex-row flex-col items-center md:justify-between shadow-md border-t-2 border-gray-200 py-5">
                    {user &&
                    user.creator_socials &&
                    user.creator_socials.instagram &&
                    user.creator_socials.instagram.access_token &&
                    user.creator_socials.instagram.access_token !== "null" ? (
                      <>
                        <a
                          className="instagram md:ml-5 md:mb-2 w-8 h-8 md:w-14 md:h-14 rounded-lg xs:mb-2"
                          href={`https://www.instagram.com/${user.creator_socials.instagram.handle}`}
                          target="_blank"
                        ></a>
                        <div className="flex items-center mb-3 mt-0.5">
                          <img
                            className="md:w-10 md:h-10 w-4 h-4 border-2 rounded-full ml-3"
                            src={
                              instagramInfo &&
                              instagramInfo &&
                              instagramInfo.profile_picture_url
                            }
                            alt="Instagram Profile Picture"
                          />
                          <span className="ml-2 font-bold  px-1">
                            @{user.creator_socials.instagram.handle}
                          </span>
                          <BsCheckCircle
                            title="Connected"
                            className="bg-Green rounded-full w-4 h-4 ml-1 mr-2"
                          />
                          <span className="ml-2 font-bold">
                            {negotiated
                              ? `Negotiated rate: $${(
                                  instagramRate / 100
                                ).toFixed(2)}`
                              : `Influencer rate: $${(
                                  instagramRate / 100
                                ).toFixed(2)}`}
                          </span>{" "}
                                       
                        </div>
                        <div className="flex-grow"></div>
                        <CustomButton
                          className="bg-red font-bold text-white text-base rounded-md py-1 px-3 md:py-3 md:px-7 md:mr-5"
                          onClick={disconnectInstagram}
                        >
                          Disconnect
                        </CustomButton>
                      </>
                    ) : (
                      <>
                        <div className="instagram md:ml-5 md:mb-2 w-8 h-8 md:w-14 md:h-14 rounded-lg xs:mb-2"></div>
                        <CustomButton
                          className="bg-red font-bold text-white text-base rounded-md py-1 px-3 md:py-3 md:px-7 md:mr-5"
                          onClick={() => setIgModal(true)}
                        >
                          Connect
                        </CustomButton>
                      </>
                    )}
                  </div>
                </div>
                {/*  <div className='flex flex-col'> 
              <div className='w-full rounded-md flex md:flex-row flex-col items-center md:justify-between shadow-md border-t-2 border-gray-200 py-5'>
                  <h1 className='md:ml-20 flex grow-0 text-center text-purple mt-3 font-bold font-rubik text-4xl mb-2'>Stripe</h1>
                  <button className ='bg-red font-bold text-white rounded-md py-1 px-5 md:py-3 md:px-11 md:mr-20' onClick={fbLogout}>Login</button> <button className ='bg-red font-bold text-white rounded-md py-1 px-5 md:py-3 md:px-11 md:mr-20' onClick={login}>Login</button>
                  </div>
                </div>*/}
              </div>

              <h2 className="font-semibold py-2 text-2xl my-8 ml-10">
                Mailing Address
              </h2>
              <div className="gap-y-5 flex flex-col w-5/6 mx-10 xs:text-xs">
                <div className="w-full rounded-md flex md:flex-row flex-col text-center items-center md:justify-between shadow-md border-t-2 border-gray-200 py-5">
                  {user && user.shipping_details ? (
                    <p className="mt-3 flex flex-row gap-5 ml-10">
                      <label className="block font-extrabold">
                        {user.shipping_details &&
                          user.shipping_details.fullname &&
                          user.shipping_details.fullname}
                      </label>
                      <label className="block font-extrabold">
                        {user.shipping_details &&
                          user.shipping_details.address1 &&
                          user.shipping_details.address1}
                      </label>
                      <label className="block font-extrabold">
                        {user.shipping_details &&
                          user.shipping_details.address2 &&
                          user.shipping_details.address2}
                      </label>
                      <label className="block font-extrabold">
                        {user.shipping_details &&
                          user.shipping_details.city &&
                          user.shipping_details.city}
                        ,{" "}
                        {user.shipping_details &&
                          user.shipping_details.state &&
                          user.shipping_details.state}
                        , USA{" "}
                        {user.shipping_details &&
                          user.shipping_details.zipcode &&
                          user.shipping_details.zipcode}
                      </label>
                    </p>
                  ) : (
                    <div className="ml-20 text-center">No address found.</div>
                  )}
                  <CustomButton
                    className=" font-bold text-white rounded-md py-1 px-3 md:py-3 md:px-7 md:mr-5"
                    onClick={() => mailingAddressHandle()}
                  >
                    Update
                  </CustomButton>
                </div>
              </div>
              {mailingAddress ? (
                <MailingAddressModal
                  open={mailingAddress}
                  close={() => setmailingAddress(false)}
                />
              ) : null}
              {/*<div className="mb-4">
              <label className="text-black block md:text-lg mb-2 font-extrabold">
                Payment Options
              </label>
              <label className="block">
                <input
                  type="text"
                  name="company profile"
                  value={companyProfileData.companyName}
                  onChange={(e) =>
                    setCompanyProfileData({
                      ...companyProfileData,
                      companyName: e.target.value
                    })
                  }
                  className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                />
              </label>
            
              <br></br>
              <label className="text-black block md:text-lg mb-2 font-extrabold">
                Payment Details
              </label>
              <label className="block">
                <input
                  type="text"
                  name="company 1-liner"
                  value={companyProfileData.companyOneLine}
                  onChange={(e) =>
                    setCompanyProfileData({
                      ...companyProfileData,
                      companyOneLine: e.target.value
                    })
                  }
                  className="mr-2 outline-none bg-gray-100 p-3 rounded-lg w-full"
                />
              </label>
              
              <br />
              <>
                <label className="text-black block md:text-lg mb-2 font-bold">Social Media</label>
              </>
              <button className="addSocial" onClick={handleAddSocial}></button>
            </div>*/}
              {/**  <Portfolio handlePortfolio={() => handlePortfolio()} />*/}
              <h2 className="font-semibold py-2 text-2xl my-8 ml-10">
                PayPal Email Address
              </h2>
              <div className="gap-y-5 flex flex-col w-5/6 mx-10">
                <div className="w-full rounded-md flex md:flex-row flex-col items-center md:justify-between shadow-md border-t-2 border-gray-200 py-5">
                  {editingEmail ? (
                    <input
                      type="text"
                      value={email}
                      placeholder={
                        (user && user.paypal_email) || (user && user.email)
                      }
                      onChange={(e) => setEmail(e.target.value)}
                      className="font-bold ml-8 2xl:text-base md:text-0.7 py-3 2xl:px-16 px-6 w-fit shadow-inner bg-gray-200 rounded focus:outline-none"
                    />
                  ) : (
                    <div className="font-bold 2xl:text-base md:text-0.7 md:ml-10 xs:mx-auto xs:mb-4">
                      {(user && user.paypal_email) || (user && user.email)}
                    </div>
                  )}
                  <div className="flex-grow"></div>
                  {editingEmail ? (
                    <>
                      <CustomButton
                        className="font-bold text-white rounded-md py-1 px-3 md:py-3 md:px-7 md:mr-5"
                        onClick={handleUpdateEmail}
                      >
                        Save
                      </CustomButton>
                      <button
                        className="text-black font-bold rounded-md py-1 px-3 md:py-3 md:px-7 md:mr-5"
                        onClick={() => setEditingEmail(false)}
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <CustomButton
                      className="font-bold text-white text-base rounded-md py-1 px-3 md:py-3 md:px-7 md:mr-5"
                      onClick={() => setEditingEmail(true)}
                    >
                      Update
                    </CustomButton>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>

      {igModal ? (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center item-center z-50 bg-black bg-opacity-50">
          <InstagramOnboardingInfoModal
            open={igModal}
            onClose={() => setIgModal(false)}
          />
        </div>
      ) : null}

      {settingsHandle ? (
        <SettingsHelpModal
          open={settingsHandle}
          close={() => setSettingsHandle(false)}
        />
      ) : null}
    </div>
  );
}

export default Profile;