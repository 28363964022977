import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';
import { CampaignAction } from './CampaignServices';
import { CampaignEmailAction } from './CampaignEmailService';

const state = createState({
  tasks: [],
  task: [],
  retrieving: false,
  fetched: false,
  loading: true,
  updateNeed: true
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'TASKS_LOADED':
        return s.merge({
          tasks: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true
        });
      case 'USER_TASKS_LOADED':
        return s.merge({
          userTasks: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true
        });

      case 'TASK_UPDATE':
        return s.merge({
          updateNeed: true,
          loading: false
        });
      case 'ADD_REVISION_NOTE':
        return s.merge({
          updateNeed: true,
          loading: false
        });
      case 'TASK_COMPLETE':
        return s.merge({
          updateNeed: true,
          loading: false
        });
      case 'TASK_DELETE':
        return s.merge({
          loading: false,
          updateNeed: true
        });
      case 'TASK_LOADED':
        return s.merge({
          tasks: action.data,
          updateNeed: false,
          loading: false,
          fetched: true
        });
    }
  });
});

export const accessInfluencerTaskState = () => state;

export const useInfluencerTaskState = () => useState(state);

export const InfluencerTaskService = {
  SendRevisions: (id, data) => {
    const dispatch = useDispatch();
    axios.post(`${baseUrl}api/campaigns/influencer/${id}/sendRevisions`, data).then((res) => {
      dispatch(InfluencerTaskAction.sendRevisions(res.data));
    });
  },
  SendDeliverables: async (id, data) => {
    console.log('From Client', data);
    const dispatch = useDispatch();
    return axios
      .post(`${baseUrl}api/campaigns/influencer/${id}/sendDeliverables`, data)
      .then((res) => {
        dispatch(CampaignAction.sendDeliverables(res.data));
        dispatch(CampaignEmailAction.sendDeliverablesEmail(res.data));
        dispatch(CampaignEmailAction.receiveDeliverablesEmail(res.data));
        return res;
      });
  },
  FetchInfluencerTasks: async () => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/influencers/tasks`)
      .then((res) => {
        dispatch(InfluencerTaskAction.tasksLoaded(res.data));
      })
      .catch((error) => {
        console.error('Error fetching Influencer Tasks:', error);
      });
  }
};

//Action
export const InfluencerTaskAction = {
  sendRevisions: (data) => {
    return {
      type: 'ADD_REVISION_NOTE',
      data: data
    };
  },
  fetchTasks: (data) => {
    return {
      type: 'TASKS_LOADED',
      data: data
    };
  }
};
