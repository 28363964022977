import React from 'react';
import { useTabState } from '../../utils/TabState';

export default function UgcTab({ currentUgcTab, task }) {
  const tabState = useTabState();
  const tabhandle = (tab) => {
    tabState.merge({ currentUgcTab: tab });
  };

  // Define all tabs
  const tabs = [
    { label: 'Overview', id: 0 },
    { label: 'Review Proposals', id: 1 },
    { label: 'Fulfill Products', id: 2 },
    { label: 'Review Video Drafts', id: 3 },
    { label: 'Final Deliverables', id: 4 },
  ];

  // Filter tabs if task.trial exists
  const availableTabs = task.trial ? tabs.filter(tab => tab.id < 2) : tabs;

  return (
    <div className="z-10 flex mx-5 font-bold border-gray-300 border-b w-full mb-2 h-fit overflow-hidden">
      {availableTabs.map(({ label, id }) => (
        <h1
          key={id}
          className={`2xl:text-base md:text-0.7 mx-5 cursor-pointer font-bold mb-2 font-black xs:xl text-center transition-all duration-200 ease-in-out transform hover:scale-110 ${
            currentUgcTab === id ? 'text-Blue border-red-500' : 'text-black border-transparent'
          } border-b-4 hover:text-Blue`}
          onClick={() => tabhandle(id)}
        >
          {label}
        </h1>
      ))}
    </div>
  );
}