import React, { useState } from 'react';
import UserInfoForm from './userInfoForm';
import UserSocialForm from './userSocialForm';
import UserAddressForm from './userAddressForm';
import NegotiatedRateForm from './NegotiatedRateForm';
function UserProfile({ userID, userInfo }) {
  const [activeComponent, setActiveComponent] = useState('');
  const handleComponentChange = (componentName) => {
    setActiveComponent(componentName);
  };

  

  const renderComponent = () => {
    switch (activeComponent) {
      case 'info':
        return <UserInfoForm userID={userID} userInfo={userInfo} />;
      case 'social':
        return <UserSocialForm userID={userID} userInfo={userInfo} />;
      case 'address':
        return <UserAddressForm userID={userID} userInfo={userInfo} />;
      case 'negotiate':
        return <NegotiatedRateForm userID={userID} userInfo={userInfo} />;
      default:
        return null;
    }
  };

  return (
    <div className="bg-white p-4 rounded-lg h-41">
      <h1 className="text-2xl font-bold mb-4">User Profile</h1>
      <div className="flex space-x-4 mb-4">
        <button
          className={`${
            activeComponent === 'info' ? 'bg-red text-white' : 'bg-gray-200 text-gray-700'
          } px-4 py-2 rounded-lg`}
          onClick={() => handleComponentChange('info')}
        >
          User Info
        </button>
        <button
          className={`${
            activeComponent === 'social' ? 'bg-red text-white' : 'bg-gray-200 text-gray-700'
          } px-4 py-2 rounded-lg`}
          onClick={() => handleComponentChange('social')}
        >
          Social Media
        </button>
        <button
          className={`${
            activeComponent === 'address' ? 'bg-red text-white' : 'bg-gray-200 text-gray-700'
          } px-4 py-2 rounded-lg`}
          onClick={() => handleComponentChange('address')}
        >
          Address
        </button>
        <button
          className={`${
            activeComponent === 'negotiate' ? 'bg-red text-white' : 'bg-gray-200 text-gray-700'
          } px-4 py-2 rounded-lg`}
          onClick={() => handleComponentChange('negotiate')}
        >
          Negotiated Rate
        </button>
      </div>
      <div>{renderComponent()}</div>
    </div>
  );
}

export default UserProfile;
