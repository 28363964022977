import React, { useState, useEffect } from 'react';
import UploadModal from './UploadModal';
import { useAssetState, AssetService } from '../../Service/AssetService';
import Loader2 from '../Loader2';
import AssetCard from './AssetCard';
import { useAuth } from '../../Context/AuthContext';

function ProfileAssets(props) {
  const assetState = useAssetState();
  useEffect(() => {
    if (assetState.updateNeed.value) {
      assetState.merge({ loading: true });
      AssetService.FetchAssetsForCreator(props.uid);
    }
  }, [assetState.updateNeed.value]);

  const Assetdata = assetState.assets.value || {};

  return (
    <div className="bg-white rounded-lg h-full w-full z-100">
      <div className="w-full h-35rem z-100">
        {assetState.loading.value ? (
          <Loader2 />
        ) : (
          <div className="grid xl:grid-cols-5 grid-cols-2 overflow-x-hidden h-1/2 md:grid-cols-2 gap-2 mx-1 w-full">
            {Object.entries(Assetdata).length === 0 ? (
              <h1 className="table mx-auto text-red text-2xl">No Assets found</h1>
            ) : (
              Object.entries(Assetdata).reverse().map(([id, data], i) => (
                <AssetCard
                  key={i}
                  asset={data}
                  date={data.created.toString()} // Pass the date as a string
                  name={data.creator_name}
                  id={id}
                  thumbnail={data.source}
                />
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
}



export default ProfileAssets;
