import React, { useState, useEffect, useContext } from 'react';
import Loader from '../Loader';
import { useAuth } from '../../Context/AuthContext';
import CreatorReviewCard from './CreatorReviewCard';
import { ReviewService, useReviewState } from '../../Service/ReviewService';
import ReactStars from 'react-rating-stars-component';
import { BsQuestionCircle } from 'react-icons/bs';
import { UsersService, useUserState } from '../../Service/userServices';
import CreatorReviewsGuide from './CreatorReviewsGuide';
import CustomButton from '../../utils/customButton';
const CreatorReviews = (props) => {
  const [productDescription, setProductDescription] = useState('');
  const [uniqueSellingPoints, setUniqueSellingPoints] = useState('');
  const [targetAudience, setTargetAudience] = useState('');
  const [campaignPurpose, setCampaignPurpose] = useState('');
  const [videoConcept, setVideoConcept] = useState('');
  const [generate, setGenerate] = useState(false);
  const reviewState = useReviewState();
  const [tab, setTab] = useState('Pending'); 
  const {currentUser} = useAuth();
  const [loading, setLoading] = useState(true); 
  const userState = useUserState();
  const [help, setHelp] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    // You can access the form values using the state variables
    console.log({
      productDescription,
      uniqueSellingPoints,
      targetAudience,
      campaignPurpose,
      videoConcept
    });
  };
  
  useEffect(() => {
    UsersService.FetchUserInfo(currentUser.uid);  
        setLoading(false);
  }, [userState.updateNeed.value]);
  
  

  useEffect(() => {
    ReviewService.getUserReviews(currentUser.uid);
    setLoading(false); 
  }, [reviewState.updateNeed.value]); 
  const res = reviewState.reviews.value;
  const reviews = res
  console.log("res", res)

  let user = userState.user.value;

  return (
    <div className="m-4 bg-white w-full p-4 rounded-lg z-100" style={{ height: 'calc(100vh - 5rem)' }}>
      <div className="flex items-center h-16 justify-between ">
      <h3 className="font-semibold p-4 bg-white w-full h-10 2xl:text-2xl md:text-base xs:text-sm">Reviews</h3>
      <CustomButton onClick={()=> setHelp(true)} className='ml-3  text-white bg-red hover:bg-red-800 rounded-lg text-xs inline-flex items-center 2xl:px-3 2xl:py-2.5 md:px-2 md:py-1 lg:px-2 lg:py-1 px-2 py-1   text-center mr-2'>Help</CustomButton>
      </div>
      <div className='flex flex-col justify-center items-center border-b w-full'>
        <div className='w-full p-4 rounded-lg '>
            <h2 className='text-center text-3xl font-bold mb-2'>Creator Rating</h2>
            <p className='text-gray-600 font-thin text-center text-sm mb-2'>Cumulative rating across all briefs.</p>
          {!loading && user && user.average_rating ? (
            <div className='text-center'>
              <p className='text-3xl font-semibold'>{user.average_rating.toFixed(1)}</p>
              <div className='flex items-center justify-center'>
              <ReactStars 
                count={5}
                value={user.average_rating}
                size={24}
                edit={false}
                isHalf={true}
                activeColor="#FFD700"
              />
              </div>
            </div>
          ) : (
            <p className='text-center text-lg'>Not rated</p>
          )}
        </div>
      </div>
      <div className="m-4 bg-white w-full p-4 rounded-lg z-100 flex flex-col overflow-y-auto" style={{ maxHeight: 'calc(100vh - 5rem - 16rem)' }}>
        {loading ? <Loader /> : (
          reviews ? (
            Object.values(reviews)
              .map((review) => (
                <CreatorReviewCard review={review} key={review.id} />
              ))
          ) : (
            <p className='flex items-center mt-10 text-base justify-center font-bold' >You currently have no reviews</p>
          )
        )}
      </div>
      {help ? (
          <CreatorReviewsGuide
            open={help}
            close={() => setHelp(false)}
          />
           ): null}
    </div>
  );
};

export default CreatorReviews;