import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import Collapsible from 'react-collapsible';
import { BsChevronCompactDown, BsChevronCompactUp } from 'react-icons/bs';

const bg = {
  overlay: {
    background: 'rgba(128, 128, 128, 0.099)'
  }
};
const OrderDetails = (props) => {
  const [isOpen] = useState(true);

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={() => props.onClose()}
        center
        styles={bg}
        classNames={{ modal: 'customModal' }}
        closeOnOverlayClick={true}>
        <div>
          <>
            <h1 className="font-bold mb-3 font-black xs:text-lg md:text-4xl">Offer Details</h1>
            <Collapsible
              trigger={[
                'Customer Address  ',
                <div className="px-3 absolute right-0">
                  <BsChevronCompactDown />
                </div>
              ]}
              triggerWhenOpen={[
                'Customer Address  ',
                <div className="px-3 absolute right-0">
                  <BsChevronCompactUp />
                </div>
              ]}
              contentOuterClassName="mb-6"
              triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-darkBlack bg-lightGray relative"
              triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
              <p className="mt-6"></p>
            </Collapsible>
            <Collapsible
              trigger={[
                'Order Items',
                <div className="px-3 absolute right-0">
                  <BsChevronCompactDown />
                </div>
              ]}
              triggerWhenOpen={[
                'Order Items',
                <div className="px-3 absolute right-0">
                  <BsChevronCompactUp />
                </div>
              ]}
              contentOuterClassName="mb-6"
              triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-darkBlack bg-lightGray relative"
              triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
              <div className="w-full">
                <table className="w-full z-0">
                  <thead className="bg-white">
                    <tr>
                      <th
                        scope="col"
                        className="text-xl font-medium  font-bold text-gray-900  py-4">
                        Product Name
                      </th>
                      <th scope="col" className="text-xl font-medium font-bold text-gray-900  py-4">
                        QTY
                      </th>
                      <th scope="col" className="text-xl font-medium font-bold text-gray-900  py-4">
                        PRICE
                      </th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
            </Collapsible>
            <Collapsible
              trigger={[
                ' Creator Referrer',
                <div className="px-3 absolute right-0">
                  <BsChevronCompactDown />
                </div>
              ]}
              triggerWhenOpen={[
                ' Creator Referrer',
                <div className="px-3 absolute right-0">
                  <BsChevronCompactUp />
                </div>
              ]}
              contentOuterClassName="mb-6"
              triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-darkBlack bg-lightGray relative"
              triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
              <div className="flex-col flex p-3">
                <p className="mt-6">
                  {props.referrer ? (
                    <label className="text-black block md:text-lg font-extrabold">
                      {props.referrer}
                    </label>
                  ) : (
                    <label className="text-black block md:text-lg font-extrabold">
                      The Failed Foodie (@thefailedfoodie)
                    </label>
                  )}
                </p>
              </div>
            </Collapsible>
          </>
        </div>
      </Modal>
    </div>
  );
};
export default OrderDetails;
