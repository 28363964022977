import React, { useState, useEffect } from 'react';
import { BsSearch } from 'react-icons/bs';
import Switch from '../Switch';
import { useOrderState, OrderService } from '../../Service/OrderServices';
import { GrFormPrevious } from 'react-icons/gr';
import { MdOutlineNavigateNext } from 'react-icons/md';
import OrderDetails from './OrderDetails';
function UsersTable() {
  const [showDetails, setShowDetails] = useState(false);
  const orderState = useOrderState();
  const [currentPage, setcurrentPage] = useState(1);
  const [setDelRec] = useState(false);
  const [setOrderId] = useState();
  const [itemsPerPage] = useState(8);
  useEffect(() => {
    if (orderState.updateNeed.value) {
      OrderService.FetchOrders();
    }
  }, [orderState.updateNeed.value]);
  const res = orderState.orders.value;

  const ord = res
    ? Object.entries(res).map((e) => ({
        [e[0]]: e[1]
      }))
    : [];
  const pages = Math.ceil(ord.length / itemsPerPage);

  const getPaginatedData = () => {
    const startIndex = currentPage * itemsPerPage - itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return ord.slice(startIndex, endIndex);
  };
  const data = getPaginatedData();

  function goToNextPage() {
    if (data.length < itemsPerPage) {
      return;
    } else {
      setcurrentPage((page) => page + 1);
    }
  }
  function goToPreviousPage() {
    if (currentPage === 1) {
      return;
    } else {
      setcurrentPage((page) => page - 1);
    }
  }

  const closeAction = () => {
    setShowDetails(false);
  };
  const handleShowDetails = () => {
    setShowDetails(true);
  };
  const handleDelete = (id) => {
    setOrderId(id);
    setDelRec(true);
  };

  return (
    <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 pr-10 lg:px-8">
      <div className="align-middle rounded-tl-lg rounded-tr-lg inline-block w-full py-4 overflow-hidden bg-white shadow-lg px-12">
        <div className="flex justify-between">
          <div className="inline-flex border rounded w-7/12 px-2 lg:px-6 h-12 bg-transparent">
            <div className="flex justify-between items-center w-full h-full mb-6 relative">
              <div className="flex">
                <span className="flex items-center leading-normal bg-transparent rounded rounded-r-none border border-r-0 border-none lg:px-3 py-2 whitespace-no-wrap text-grey-dark text-sm">
                  <BsSearch />
                </span>
              </div>
              <input
                type="text"
                className="flex-shrink flex-grow flex-auto leading-normal tracking-wide w-px flex-1 border border-none border-l-0 rounded rounded-l-none px-3 relative focus:outline-none text-xxs lg:text-xs lg:text-base text-gray-500 font-thin"
                placeholder="Search"
              />
            </div>
          </div>
          <div className="py-3 px-4 flex items-center text-sm font-medium leading-none text-gray-600 bg-gray-200 hover:bg-gray-300 cursor-pointer rounded">
            <p>Sort By:</p>
            <select className="focus:outline-none bg-transparent ml-1">
              <option className="text-sm text-indigo-800">Name</option>
              <option className="text-sm text-indigo-800">ProductID</option>
              <option className="text-sm text-indigo-800">Brand</option>
              <option className="text-sm text-indigo-800">UserID</option>
            </select>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden ml-32">
              <table className="w-5/6">
                <thead className="bg-white border-b">
                  <tr>
                    <th
                      scope="col"
                      className="text-xl font-medium  font-bold text-gray-900 px-6 py-4 text-center">
                      Order No
                    </th>
                    <th
                      scope="col"
                      className="text-xl font-medium font-bold text-gray-900 px-6 py-4 text-center">
                      Mark as Shippedy
                    </th>
                    <th
                      scope="col"
                      className="text-xl font-medium font-bold text-gray-900 px-6 py-4 text-center">
                      More details
                    </th>
                    <th
                      scope="col"
                      className="text-xl font-medium font-bold text-gray-900 px-6 py-4 text-center">
                      Option
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 &&
                    data.map((item, i) => {
                      const res = item[Object.keys(item)];
                      const id = Object.keys(item);
                      if (typeof res.brand === 'string') {
                        return (
                          <tr
                            className={i % 2 ? 'bg-white border-b' : 'bg-gray-100 border-b'}
                            key={i}>
                            <td className="px-6 py-4 whitespace-nowrap text-lg font-medium text-gray-900 text-center">
                              {id}
                            </td>
                            <td className="text-lg text-gray-900 font-light pl-2px  px-6 py-4 whitespace-nowrap text-left">
                              <Switch
                                id={id}
                                customeruid={res.brand}
                                branduid={res.brand}
                                order_id={id}
                                trackingNumber={id}
                              />
                            </td>
                            <td className="text-lg text-gray-900 font-light px-6 py-4 mb-2 whitespace-nowrap text-center">
                              <button
                                className="bg-red font-bold px-4 py-2 rounded-lg  mb-2 text-white text-3xl mt-1 w-48 items-center justify-center text-center cursor-pointer"
                                onClick={handleShowDetails}>
                                View More Details
                              </button>
                              {showDetails && (
                                <OrderDetails
                                  customeruid={res.brand}
                                  customer={res.customer}
                                  items={res.items}
                                  total={res.total}
                                  shipping_details={res.shipping_details}
                                  tax={res.tax}
                                  closeAction={closeAction}
                                />
                              )}
                            </td>
                            <td className="text-lg text-gray-900 font-light px-6 py-2 whitespace-nowrap text-center">
                              <button
                                className="bg-red font-bold px-4 py-2 rounded-lg text-white text-3xl mt-1 w-48 items-center justify-center text-center cursor-pointer"
                                onClick={() => handleDelete(id)}>
                                Delete
                              </button>
                              {showDetails && (
                                <OrderDetails
                                  customeruid={res.brand}
                                  customer={res.customer}
                                  items={res.items}
                                  total={res.total}
                                  shipping_details={res.shipping_details}
                                  tax={res.tax}
                                  closeAction={closeAction}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      } else return null;
                    })}
                </tbody>
              </table>

              <div className="flex ml-2.5px mt-4">
                <button
                  onClick={() => goToPreviousPage()}
                  className="mr-8 cursor-pointer bg-red text-white py-2 px-2 rounded-lg">
                  <GrFormPrevious className="inline text-white fill-current" />
                  Previous
                </button>
                <div className="mr-4 mt-4 font-bold">
                  {currentPage} - {pages}
                </div>
                <button
                  onClick={() => goToNextPage()}
                  className="cursor-pointer bg-red text-white py-2 px-2 rounded-lg">
                  Next
                  <MdOutlineNavigateNext className="inline text-white fill-current" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UsersTable;
