import React, { useState, useEffect } from 'react';
import { BiLogOut } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { useAuth } from '../../Context/AuthContext';
import { FiBookmark, FiInbox, FiSettings, FiUser, FiZap, FiStar, FiHome } from 'react-icons/fi';
import { HiOutlineCash } from 'react-icons/hi';
import { baseUrl } from '../../baseUrl';
import { MdOutlineRateReview } from "react-icons/md";
import './Sidebar.css';
import CustomButton from '../../utils/customButton';

function DesktopSidebar() {
  const { logout } = useAuth();
  const [balance, setBalance] = useState('');
  const { currentUser } = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  useEffect(() => {
    // Make API request to retrieve balance
    fetch(`${baseUrl}api/user/balance/${currentUser.uid}`)
      .then(response => response.json())
      .then(data => {
        const { result } = data;
        setBalance(result);
      })
      .catch(error => {
        console.error('Error retrieving balance:', error);
      });
  }, []);


  const handleLogOut = () => {
    logout();
  };
  const [pathname, setPathname] = useState('dashboard');
  const path = window.location.pathname;
  useEffect(() => {
    switch (path) {
      case '/creator/dashboard':
        setPathname('dashboard');
        break;
        case '/creator/dashboard/home':
          setPathname('home');
          break;
      case '/creator/dashboard/tasks':
        setPathname('tasks');
        break;
      case '/creator/dashboard/mytasks':
        setPathname('my-tasks');
        break;
      case '/creator/dashboard/settings':
        setPathname('settings');
        break;
      case '/creator/dashboard/products':
        setPathname('products');
        break;
      case '/creator/dashboard/recipes':
        setPathname('recipes');
        break;
       case '/creator/dashboard/cashout':
          setPathname('cash-out');
          break;
      case '/creator/dashboard/reviews':
          setPathname('reviews');
      default:
        break;
    }
  }, [path]);
  return (
    <div className="flex flex-col 2xl:w-64 md:w-48 m-4 sticky text-gray-700 xs:h-fit bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800 rounded-md shadow dark-mode:bg-gray-800 flex-shrink-0"
        style={{
            height: window.innerWidth <= 900 ? '25rem': 'calc(100vh - 6rem)' 
        }}>
    <nav className="flex-grow md:block md:text-0.7 px-4 pb-4 md:pb-0 md:overflow-y-auto">
        {/*<Link
          className="block px-4 py-2 mt-2 text-sm font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline"
          to="/creator/dashboard/events">
          <FiCalendar className="mr-2 mb-1.5 inline w-8 h-6" />
          My Events 
        </Link>
        <Link
          className="block px-4 py-2 mt-2 text-sm font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline"
          to="/creator/dashboard/golive">
          <FiRadio className="mr-2 mb-1.5 inline w-8 h-6" />
          Go Live
        </Link>*/}
        <div className=' border-b-2 border-gray-300'> 
          <div
              className={
                pathname === 'cash-out'
                  ? 'block px-4 py-2 mt-2 text-sm font-semibold  rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
                  : 'block px-4 py-2 mt-2 text-sm font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 focus:text-white focus:bg-gray-900 focus:text-white focus:shadow-outline'
              } 
              >
              <h3 className='text-center md:text-center 2xl:text-4xl md:text-4xl font-bold'>{"$" + (Number(balance) / 100).toFixed(2)}</h3>
                <div className='my-4 text-center items-center justify-center   h-6'>
                <Link className='2xl:py-2 md:py-2 md:px-7 w-full 2xl:px-10 2xl:text-3xl tracking-tightest font-extrabold rounded-lg bg-gray-200 hover:bg-gray-400 hover:text-white'
                to = '/creator/dashboard/cash-out'>History</Link>
                </div>
            </div>

          </div>
          {/*<Link
          className={
            pathname === 'home'
              ? 'block px-4 py-2 mt-2 2xl:text-2xl text-base md:text-0.7  font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 2xl:text-2xl text-base md:text-0.7  font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/creator/dashboard/home">
          <FiHome className="mr-2 mb-1.5 inline 2xl:w-8 2xl:h-6" />
          Home
        </Link>*/}
        <Link
          className={
            pathname === 'tasks'
              ? 'block px-4 py-2 mt-2 2xl:text-2xl text-base md:text-0.7  font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 2xl:text-2xl text-base md:text-0.7  font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/creator/dashboard/tasks">
          <FiZap className="mr-2 mb-1.5 inline 2xl:w-8 2xl:h-6" />
          Apply to Briefs
        </Link>
        <Link
          className={
            pathname === 'my tasks'
              ? 'block px-4 py-2 mt-2 2xl:text-2xl text-base md:text-0.7  font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 2xl:text-2xl text-base md:text-0.7  font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/creator/dashboard/mytasks">
          <FiInbox className="mr-2 mb-1.5 inline 2xl:w-8 2xl:h-6" />
          Manage Briefs
        </Link>
        <Link
          className={
            pathname === 'recipes'
              ? 'block px-4 py-2 mt-2 2xl:text-2xl text-base md:text-0.7 font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 2xl:text-2xl text-base md:text-0.7 font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/creator/dashboard/recipes">
          <FiBookmark className="mr-2 mb-1.5 inline 2xl:w-8 2xl:h-6" />
          Recipes
        </Link>
        <Link
          className={
            pathname === 'reviews'
              ? 'block px-4 py-2 mt-2 2xl:text-2xl text-base md:text-0.7 font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 2xl:text-2xl text-base md:text-0.7 font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/creator/dashboard/reviews">
          <FiStar className="mr-2 mb-1.5 inline 2xl:w-8 2xl:h-6" />
          View Reviews
        </Link>
        <Link
          className={
            pathname === 'settings'
              ? 'block px-4 py-2 mt-2 2xl:text-2xl text-base md:text-0.7 font-semibold rounded-lg  dark-mode:bg-gray-600 dark-mode:bg-gray-600 dark-mode:text-white dark-mode:text-white dark-mode:text-gray-200 text-white text-white bg-gray-900 bg-gray-900 text-white shadow-outline'
              : 'block px-4 py-2 mt-2 2xl:text-2xl text-base md:text-0.7 font-semibold text-gray-900  rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-white focus:text-white hover:bg-gray-900 focus:bg-gray-900 focus:text-white focus:shadow-outline'
          }
          to="/creator/dashboard/settings">
          <FiSettings className="mr-2 mb-1.5 inline 2xl:w-8 2xl:h-6" />
          Settings
        </Link>
      </nav>

      <CustomButton
        className=" text-white font-bold py-2 px-1 text-lg m-2 rounded-md"
        onClick={() => handleLogOut()}>
        <BiLogOut className="mr-2 mb-1.5 inline w-8 h-6" />
        Logout
      </CustomButton>
    </div>
  );
}

export default DesktopSidebar;
