import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import CustomButton from "../../utils/customButton";
import ReceiptCapture from "./ReceiptCapture";
import PayoutConfirmation from "./PayoutConfirmation";
import ReceiptConfirmation from "./ReceiptConfirmation";
import Payout from "./Payout";
import { RetailCampaignService, useRetailCampaignState } from "../../Service/RetailCampaignService";
import Loader from "../Loader2";

function CustomerRetailCampaign() {
  const retailCampaignState = useRetailCampaignState();
  const [currentTab, setCurrentTab] = useState(0);
  const [receiptData, setReceiptData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [inputValue, setInputValue] = useState(""); 
  const {retailId} = useParams();
  const {uid} = useParams();
  console.log("campaignId: ", retailId);
  console.log("influencerId:", uid);
  useEffect(() => {
    const fetchData = async () => {
        try {  
             await RetailCampaignService.getRetailCampaign('-O7eDT8g0B_-IzJFRGvy');
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    fetchData();
}, []);



    let retailCampaign = retailCampaignState.retailCampaign.value;
    console.log("retail campaign: ", retailCampaign);
  return (
    <>
    {loading ? (
    <div className="flex items-center justify-center flex-col">
        <h2 className="my-5">Loading</h2>
        <Loader/>
    </div>
         
    ) : (<><div className="flex justify-center" style={{ backgroundColor: `#${retailCampaign.theme_color}` }}>
            <img className="py-5 w-8rem h-8rem rounded" src={retailCampaign.brand_photo}></img>
        </div>
        {currentTab === 0 && (
            <ReceiptCapture retailCampaign={retailCampaign} receiptData={receiptData} setReceiptData = {setReceiptData} setCurrentTab={setCurrentTab} /> 
        )}
        {currentTab === 1 && (
            <Payout retailCampaign={retailCampaign} setCurrentTab={setCurrentTab} selectedOption={selectedOption} setSelectedOption={setSelectedOption} inputValue={inputValue} setInputValue={setInputValue} /> 
        )}
        {currentTab === 2 && (
            <PayoutConfirmation retailCampaign={retailCampaign} setCurrentTab={setCurrentTab} selectedOption={selectedOption} setSelectedOption={setSelectedOption} inputValue={inputValue} setInputValue={setInputValue} /> 
        )}
        </>
            )}
        

    </>
  );
}

export default CustomerRetailCampaign;