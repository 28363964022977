import React, { useState, useEffect } from 'react';
import { UsersService, useUserState } from '../../../Service/userServices';
import '../../../App.css';
import Loader from '../../Loader';
import logo from '../../../Assets/logo.png'
import instagramLogo from '../../../Assets/instagram.png'
import youtubeLogo from '../../../Assets/youtube.png'
import tiktokLogo from '../../../Assets/tiktok.png'
import { useRef } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import PlacesAutocomplete from 'react-places-autocomplete';
import UserInfoForm from './userInfoForm';
import UserSocialForm from './userSocialForm';
import UserAdressForm from './userAddressForm';
import UserProfile from './UserProfile';



function UpdateUserForm(props) {
  const { userInfo, userID } = props;
  const socialLink = useRef('');
  const socialName = useRef('');
  const userState = useUserState();
  const [addSoc, setAddSoc] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [socialArr, setSocialArr] = useState([]);
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [success, setSuccess] = useState(false);
  const [fullname, setFullname] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');

  function handleChange(address) {
    //HANDLE CHANGE
    setAddress1(address);
  }

  function saveAddress() {
    let mailing_address = {
      fullname: fullname,
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      country: 'USA',
      zipcode: zipcode
    };

    UsersService.AddAddress(userID, mailing_address);

    setSuccess(true);

    setTimeout(() => {
      setSuccess(false);
    }, 10000);
  }

  function handleSelect(address, placeId, suggestion) {
    //HANDLE SELECT
    setAddress1(suggestion.formattedSuggestion.mainText);
    setCity(suggestion.terms[suggestion.terms.length - 3].value);
    setState(suggestion.terms[suggestion.terms.length - 2].value);
  }

  const handleAddSocial = () => {
    let add = addSoc;
    setAddSoc(!add);
  };

  const handleSocialSubmit = (e) => {
    e.preventDefault();
    const socialLinkValue = socialLink.current.value.trim();
    const socialNameValue = socialName.current.value.trim();
  
    // Regular expression pattern to validate social media handles
    const handlePattern = /^(?!www\.[a-zA-Z0-9_-]+\.(?:com|edu|net)$)[a-zA-Z0-9_.]+$/
  
    // Tests for the input being a valid social media handle
    if (handlePattern.test(socialLinkValue)) {
      const formValues = {
        socialLink: socialLinkValue,
        socialName: socialNameValue
      };
      setSocialArr((prevArr) => [...prevArr, formValues]);
      setAddSoc(false);
      setErrorMessage('');
    } else {
      setErrorMessage('Please enter a valid social media handle.');
    }
  };
  console.log("props.userInfo", userInfo);
  console.log("props.selectedId", props);
  const handlePortfolio = () =>{
    console.log("nothing")
  }

  const handlePortfolioSubmit = () => {
    userState.merge({ loading: true });
    let socialObj = socialArr.reduce((acc, curr)=>{
      acc[curr.socialName] = curr.socialLink;
      return acc;
    },{})
    console.log("SocialObj")
    console.log(socialObj)

    let data = {
      socials: socialObj
    };
    UsersService.AddCreatorPortfolio(userID, data);
    
  };

  const extractHandle = (profileLink) => {
    if(typeof(profileLink) !== "string"){
      return ""
    }
    const handle = profileLink.replace(/^\/+|\/+$/g, '');
    return handle;
  };

  const [user, setUser] = useState({
    name: '',
    username: '',
    email: '',
    bio: '',
    avatar: '',
    negotiated: '',
    creator_socials: {
        instagram: {
          handle: '',
        },
        tiktok: {
          handle: '',
        },
        youtube: {
          handle: '',
        },
    },
    shipping_details: {
      address1: '',
      address2: '',
      city: '',
      country: '',
      fullname: '',
      state:'',
      zipcode:'',
    }
  });
  const [error, setError] = useState({
    name: '',
    bio: ''
  });
  useEffect(() => {
    setUser({
      name: userInfo.name || userInfo.profile_name || userInfo.shipping_details.fullname,
      username: userInfo.username,
      email: userInfo.email,
      bio: userInfo.bio,
      avatar: userInfo.avatar,
      negotiated: userInfo.negotiated,
      creator_socials: {
          instagram: userInfo && userInfo.creator_socials && userInfo.creator_socials.instagram,
          tiktok: userInfo && userInfo.creator_socials && userInfo.creator_socials.tiktok,
          youtube: userInfo && userInfo.creator_socials && userInfo.creator_socials.youtube, 
      },
      shipping_details:{
        address1: userInfo && userInfo.shipping_details && userInfo.shipping_details.address1,
        address2: userInfo && userInfo.shipping_details && userInfo.shipping_details.address2,
        city: userInfo && userInfo.shipping_details && userInfo.shipping_details.city,
        country: userInfo && userInfo.shipping_details && userInfo.shipping_details.country,
        fullname: userInfo && userInfo.shipping_details && userInfo.shipping_details.fullname,
        state: userInfo && userInfo.shipping_details && userInfo.shipping_details.state,
        zipcode: userInfo && userInfo.shipping_details && userInfo.shipping_details.zipcode
      }
      
    });
  }, [userInfo]);
  const handleUpdateUser = () => {
    const data = {
      name: user.name,
      bio: user.bio,
      avatar: ''
    };
    if (user.name === '') return setError({ ...error, name: 'name is required' });
    if (user.bio === '') return setError({ ...error, bio: 'bio is required' });
    userState.merge({ loading: true });
    UsersService.UpdateUser(userID, data);
    userState.merge({ loading: false });
  };


  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };
  
  return (
    <div className=''>
    <div className='border border-gray-300 h-65 rounded-lg p-4'>
      <div>
        <button
          className={`px-4 py-2 rounded-full ${
            isToggled
              ? 'bg-red text-white'
              : 'bg-white border border-gray-300 text-gray-700'
          }`}
          onClick={handleToggle}
        >
          {isToggled ? 'Details' : 'Edit'}
        </button>
      </div>
      <>
      { !isToggled ?
        <div className=" flex-col items-center h-fit"  >
          <div className="text-center">
            <img src={user.avatar || logo} alt="User" className="rounded-full w-1/5 h-1/5 mx-auto mb-3 object-cover"/>
            <div className="mx-auto">
            <p>
              {user.name || user.profile_name}
            </p>
            <p>
              Instagram: @{user.creator_socials.instagram && user.creator_socials.instagram.handle ? user.creator_socials.instagram.handle : 'N/A'}
              <br/>
              TikTok: @{user.creator_socials.tiktok && user.creator_socials.tiktok.handle ? user.creator_socials.tiktok.handle : 'N/A'}
              <br/>
              Youtube Channel: Youtube.com/{user.creator_socials.youtube && user.creator_socials.youtube.handle ? user.creator_socials.youtube.handle : 'N/A'}
              <br/>
              {user.email}
            </p>
            </div>
          </div>
          <div className='mt-4'>
            <label className="text-left">
              <span className='font-bold'>Negotiated Rate: {user.negotiated ? 'Yes' : 'No'}</span>
            </label>
            
            {user.negotiated ? (
              <>
                <div>
                  Negotiated rate for Instagram: ${userInfo && userInfo.creator_socials && userInfo.creator_socials.instagram && userInfo.creator_socials.instagram.suggested_rate.toFixed(2) / 100}
                </div>
                
                <div>
                  Negotiated rate for TikTok: ${userInfo && userInfo.creator_socials && userInfo.creator_socials.tiktok && userInfo.creator_socials.tiktok.performance && (userInfo.creator_socials.tiktok.performance.suggestedRate.toFixed(2) / 100)}
                </div>
              </>
            ) : (
              <>
                <div>
                  Suggested rate for Instagram: {userInfo && userInfo.creator_socials && userInfo.creator_socials.instagram && userInfo.creator_socials.instagram.suggested_rate ? 
                    `$${(userInfo.creator_socials.instagram.suggested_rate.toFixed(2) / 100)}` : 
                    'Not Available'}
                </div>
                
                <div>
                  Suggested rate for TikTok: {userInfo && userInfo.creator_socials && userInfo.creator_socials.tiktok && userInfo.creator_socials.tiktok.performance && userInfo.creator_socials.tiktok.performance.suggestedRate ? 
                    `$${(userInfo.creator_socials.tiktok.performance.suggestedRate.toFixed(2) / 100)}` : 
                    'Not Available'}
                </div>
              </>
            )}
          </div>
          <div className='mt-4'>
            <label className="text-left font-bold whitespace-normal break-words">
           Bio:
            </label>
          <div> {user.bio} </div>
          </div>
          <div className='mt-4'>
            <label className="text-left">
            <span className='font-bold'>Socials:</span>
            
            <div className="max-w-md flex space-x-4">
                {user.creator_socials.instagram && user.creator_socials.instagram.handle && (
                  <a href={`https://www.instagram.com/${user.creator_socials.instagram.handle}`} target="_blank" rel="noopener noreferrer">
                    <img src={instagramLogo} className="h-6 w-6" alt="Instagram" />
                  </a>
                )}
                {user.creator_socials.tiktok && user.creator_socials.tiktok.handle && (
                  <a href={`https://www.tiktok.com/${user.creator_socials.tiktok.handle}`} target="_blank" rel="noopener noreferrer">
                    <img src={tiktokLogo} className="h-6 w-6" alt="TikTok" />
                  </a>
                )}
                {user.creator_socials.youtube && user.creator_socials.youtube.handle && (
                  <a href={`https://www.youtube.com/${user.creator_socials.youtube.handle}`} target="_blank" rel="noopener noreferrer">
                    <img src={youtubeLogo} className="h-6 w-6" alt="YouTube" />
                  </a>
                )}
              </div>
          </label>
                  {
          user.shipping_details ? (
            <div className="w-fit mt-4">
              <label className="text-left ">
                <span className='font-bold'>Address:</span>
              </label>
              <address className="text-gray-600">
                {user.shipping_details.fullname},&nbsp;
                {user.shipping_details.address1},&nbsp;
                {user.shipping_details.address2},&nbsp;
                {user.shipping_details.city},&nbsp; {user.shipping_details.state}, &nbsp;{user.shipping_details.zipcode},&nbsp;
                {user.shipping_details.country}
              </address>
            </div>
          ):
          null
        }
          </div>
        </div>:
          <UserProfile userID={userID} userInfo={userInfo}/>
        }
      </>
    </div>
    </div>
  );
}

export default UpdateUserForm;
