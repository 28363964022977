import React, { useContext, useEffect, useState } from 'react';
import { CampaignState } from '../../../../utils/campaignState';
import Proposals from './Proposals';
import {AiFillDollarCircle} from 'react-icons/ai';
import { useCampaignState, CampaignService } from '../../../../Service/CampaignServices';
import 'react-responsive-modal/styles.css';
import tiktok from '../../../../Assets/tiktok-square.png';
import youtube from '../../../../Assets/youtube-shorts.png';
import { useAuth } from '../../../../Context/AuthContext';
import instagram from '../../../../Assets/instagram (1).png';
import viewIcon from '../../../../Assets/view_icon.png';
import rectangleIcon from '../../../../Assets/rectangle_icon.png';
import announce from '../../../../Assets/announce_icon.png';
import bucketIcon from '../../../../Assets/buy_cart_icon.png';
import { useParams } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import Loader from '../../../LoaderRed';
import {Link} from 'react-router-dom';
import CampaignNavTabs from '../CampaignNavTabs';
import logo from '../../../../Assets/logo.png';
import CampaignSubNav from '../CampaignSubNav';
import { BiDollar, BiDollarCircle } from 'react-icons/bi';
import { CampaignEmailService } from '../../../../Service/CampaignEmailService';
import CreatorProposalsForTask from './CreatorProposalsForTask';
import { BrandContext } from '../../../../routes/PrivateRoute/BrandDashboardRoute';

export default function CampaignDetails(props) {
  const campaignState = useCampaignState();
  const [loading, setLoading] = useState(false);
  const [proposalModal, setProposalModal] = useState(false);
  const { id } = useParams();
  let numberOfProposals = 0;
  const {currentUser} = useAuth();
  const [selectedTask, setSelectedTask] = useState(null);
  const { brandId } = useContext(BrandContext);

  const fetchData = async () => {
    if (id) {
      setLoading(true);
      await CampaignService.FetchSingleCampaign(id);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const res = JSON.stringify(campaignState.campaign.value);
  const campaign = JSON.parse(res);
  const tasks = campaign && campaign.tasks ? Object.entries(campaign.tasks).map(([key, value]) => ({key, ...value})) : [];

  const approvedProp = async (proposal, proposalKey) => {
    let new_proposal = {
      proposal_id: proposalKey,
      proposal: proposal,
    };
    const emailData = {
      brand_id: brandId,
      creator_id: proposal.creator_id,  
      task_id: proposal.task_id,
      proposal: proposal
    }
    if (proposal) {
      console.log("task id: " + proposal.task_id);
      await CampaignService.ApproveProposal(proposal.task_id, new_proposal);
      await CampaignEmailService.SendApprovalEmail(emailData);
      fetchData(); // Reloading the proposals
    }

    toast.success('Proposal Approved Successfully');
  };
 
  const deniedProp = async (proposal, proposalKey) => {
    console.log("task id: " + proposal.task_id);
    console.log("key: " + proposalKey);
    const data = {
      campaign_id: id,
      creator_id: proposal.creator_id,
      task_id: proposal.task_id,
      proposal_id: proposalKey
    };
  

    try {
      await CampaignService.DenyProposal(proposal.task_id, proposalKey, data);
      fetchData();
      toast.error('Proposal Denied');
    } 
    catch (error) {
      
    }
  };

  const handleTaskClick = (item) => {
    setSelectedTask(item);
    setProposalModal(true);
  };
  

  return (
    <div className='m ml-8 bg-white p-4 rounded-lg z-100'>
  <h1 className='font-bold text-base mb-2'>Click on a task to view its proposals</h1>
  <div className='grid grid-cols-4 gap-2'>
    {tasks && tasks.map((item, i) => {
      const taskId = item.key;
      return (
        <div key={taskId} onClick={() => handleTaskClick(item)} className="bg-lightGray items-center rounded-xl p-1 flex flex-col cursor-pointer">
      <div className="relative w-full h-full">
    {item && (
      <img
        src={item.brand_photo}
        style={{ height: '150px' }}
        className="rounded-lg w-full h-full bg-white object-contain"
        alt="img"
      />
    )}
      {item && item.platform && item.platform.includes('Instagram') &&
        <div className="absolute bottom-0 left-2">
          <div className="instagram w-4 h-4"></div>
        </div>
      }
      {item && item.platform && item.platform.includes('TikTok') &&
        <div className={`absolute bottom-0 ${item.platform.length === 1 && item.platform.includes('TikTok') ? 'left-2' : 'left-7'}`}>
          <div className="tiktok w-4 h-4"></div>
        </div>
      }
    </div>
    <div className='lg:text-3xl md:text-base text-center truncate overflow-hidden leading-tight mt-0.5 md:w-36'>
      {item.name}
    </div>
    <h2 className='font-bold'>{item.brand_name}</h2>
      
    <div className="text-base mb-1 tracking-tighter font-bold text-center">
      {item.add_ons && item.add_ons.map((el, i) => (
        <span key={i}>{i !== 0 && '+'} {el.name}</span>
      ))}
    </div>
        </div>
        
      );
    })}
  </div>
  {proposalModal ? (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center item-center z-50 bg-black bg-opacity-50">
      <CreatorProposalsForTask
        key={selectedTask.key}
        open={proposalModal}
        task={selectedTask}
        onClose={() => {
          setSelectedTask(null);
          setProposalModal(false);
        }}
        id={props.id}
      />
    </div>
  ) : null}
</div>
    )
   

    
}