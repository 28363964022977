import React from "react";

function CustomButton({
  className,
  children,
  onClick,
  type = "button",
  ...props
}) {
  const combinedClassName = `bg-Blue text-center rounded-md ${className}`;

  return (
    <button
      type={type}
      className={combinedClassName}
      onClick={onClick}
      {...props}
    >
      {children || "button"}
    </button>
  );
}

export default CustomButton;
