import React, { useEffect, useState } from 'react'
import { useCampaignState, CampaignService } from '../../../Service/CampaignServices';
import { useParams } from 'react-router-dom';
import InfluencerTaskCard from './InfluencerTaskCard';
import AddTaskModal from './CampaignDetails/AddTaskModal';
import CustomButton from '../../../utils/customButton';
function ViewCampaignTasks() {
  const { id } = useParams();
  const campaignState = useCampaignState();
  const [proposalCount, setProposalCount] = useState(0);
  const [approvedProposalCount, setApprovedProposalCount] = useState(0);
  const [totalDrafts, setTotalDrafts] = useState(0);
  const [totalFinalVids, settotalFinalVids] = useState(0);
  const [totalPost, settotalFinalPost] = useState(0);
  const [numTask, setNumTask] = useState(0);
  const [taskCards, setTaskCards] = useState([]);
  const [addTask, setAddTask] = useState(false);
  const [reload, setReload] = useState(false);

  const [loading, setLoading] = useState(false);
  const addHandler = () => {
    setAddTask(true);
  }
  const fetchData = async () => {
    if (id) {
      setLoading(true);
      await CampaignService.FetchSingleCampaign(id);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [reload]);

  // Function to call when you want to reload data
  const handleReload = () => {
    setReload(prev => !prev); // This toggles the state, which will trigger the useEffect
  };
  
  const res = JSON.stringify(campaignState.campaign.value);
  const campaign = JSON.parse(res);
  const tasks = campaign && campaign.tasks ? Object.entries(campaign.tasks).map(([key, value]) => ({key, ...value})) : [];    

  
  
  return (
    <div className='relative ml-8 bg-white p-4 rounded-lg z-100 h-40'>
      <h1 className='font-bold text-base mb-3'>Click on a brief to view its details</h1>
      <CustomButton
        className='absolute top-0 right-5 rounded-lg mr-5 text-white lg:px-5 lg:py-2 px-3 py-1'
        onClick={addHandler}
      >
        + Add Brief
      </CustomButton>
      <div className='grid grid-cols-4 gap-2'>
        {tasks && tasks.map((item, i) => (
          <div key={i}>
            <InfluencerTaskCard task={item}  reload={handleReload} id={id} />
          </div>
        ))}
      </div>
      {addTask && (
        <AddTaskModal
         className='h-screen'
          open={addTask}
          onClose={() => setAddTask(false)}
          campaignId={id}
          fetchData = {fetchData}
        />
      )}
    </div>
  );
}

export default ViewCampaignTasks;