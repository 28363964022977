import React, { useEffect, useState } from 'react';
import AssetCard from './AssetCard';
import { useAssetState, AssetService } from '../../Service/AssetService';
import Loader2 from '../Loader2';
import { useAuth } from '../../Context/AuthContext';
import CustomButton from '../../utils/customButton';

function Products() {
  const assetState = useAssetState();
  const { currentUser } = useAuth();
  const [selectedBrand, setSelectedBrand] = useState("All");

  useEffect(() => {
    if (assetState.updateNeed.value) {
      assetState.merge({ loading: true });
      AssetService.FetchAssets();
    }
  }, [assetState.updateNeed.value]);

  const extractName = (str) => {
    const parts = str.split('_');
    return parts[1];
  };

  let Assetdata = [];
  let brandArr = ["All"];
  if (assetState.assets.value) {
    Assetdata = Object.entries(assetState.assets.value).map((e) => ({
      [e[0]]: e[1]
    }));
  }
  

  Assetdata.forEach((data) => {
    const id = Object.keys(data);
    console.log(data[id])
    console.log("RIGHT HERE")
    const creatorName = data[id].creator_name || '';
    const [_, stringWithinUnderscores, __] = creatorName.split('_');
    if (!brandArr.includes(stringWithinUnderscores)) {
      brandArr.push(stringWithinUnderscores);
    }
  });

  const filteredAssets = Assetdata.filter((item) => {
    const data = item[Object.keys(item)];
    const creatorName = data.creator_name || '';
    const [_, stringWithinUnderscores, __] = creatorName.split('_');
    return selectedBrand === "All" || selectedBrand === stringWithinUnderscores;
  });

  return (
    <div className="m-4 bg-white w-full p-4 rounded-lg z-100">
      <div className="flex items-center justify-between">
        <h3 className="font-semibold p-8 md:text-2xl xs:text-sm">User-Generated Videos</h3>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4" style={{ marginBottom: "40px" }}>
        {brandArr.map((data, index) => (
          <CustomButton
            key={index}
            className={`bg-gray-200 text-gray-800 px-4 py-2 rounded-md shadow-md hover:bg-gray-300 focus:bg-red transition-colors duration-200 ease-in-out ${selectedBrand === data ? 'bg-gray-300' : ''}`}
            onClick={() => setSelectedBrand(data)}
          >
            {data}
          </CustomButton>
        ))}
      </div>
      <div className="w-full z-100 mb-5" style={{ height: '85vh' }}>
        {assetState.loading.value ? (
          <Loader2 />
        ) : (
          <>
            {!assetState.loading.value && Assetdata.length === 0 && (
              <div className="w-full">
                {/* ... */}
              </div>
            )}
            <div className="grid lg:grid-cols-4 grid-cols-1 overflow-y-scroll md:grid-cols-2 gap-2 mx-2 pb-5 h-41">
              {/* {filteredAssets.length > 0 &&
                filteredAssets.reverse().map((item, i) => {
                  const data = item[Object.keys(item)];
                  const id = Object.keys(item);
                  return (
                    <AssetCard
                      key={id} // Use a unique key prop based on the asset id
                      asset={data}
                      name={data.creator_name}
                      date={data.created}
                      id={id}
                      thumbnail={data.source}
                    />
                  );
                })} */}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Products;
