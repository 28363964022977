import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import moment from 'moment';
import { useCampaignState, CampaignService } from '../../../../Service/CampaignServices'
import { useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { baseUrl } from '../../../../baseUrl';
import { useAuth } from '../../../../Context/AuthContext';
import { BrandContext } from '../../../../routes/PrivateRoute/BrandDashboardRoute';


function AddInstagramAnalytics(props) {
  const campaignState = useCampaignState();                    
  const [views, setViews] = useState('');
  const [comments, setComments] = useState('');
  const [likes, setLikes] = useState('');
  const [shares, setShares] = useState('');
  const [analytics, setAnalytics] = useState({});
  const {id} = useParams();
  const currentUser = useAuth();
  const { brandId } = useContext(BrandContext);

 useEffect(() => {
  if (id) {
    CampaignService.FetchSingleCampaign(id);
  }
}, [id]);
const res = JSON.stringify(campaignState.campaign.value);
const campaign = JSON.parse(res);


const refreshAnalytics = async () => {
  try {
    let req_body = {
      brand_id: brandId,
    };
    const response = await fetch(`${baseUrl}api/campaigns/influencer/${id}/refreshAnalytics`, {
      method: 'POST',
      body: JSON.stringify(req_body),
    });
    const data = await response.json();
    setAnalytics(data); // Assuming 'data' contains the new analytics
    console.log('Fetched data:', data);
  } catch (error) {
    console.error('Error refreshing analytics:', error);
  }

};

  const handleSubmit = async (event) => {
                    event.preventDefault();
                    const post_id = props.id;
                    const campaign_id = id;
                    const task_id = props.taskId;
                    const updated = moment().format();
                
                    const postUpdate = {
                      comments,
                      likes,
                      shares,
                      updated,
                      views
                    };
                
                    const response = await fetch(`${baseUrl}api/campaigns/influencer/${post_id}/addInstagramAnalytics`, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({
                        campaign_id,
                        task_id,
                        ...postUpdate
                      })
                    });
                
                    if (response.ok) {
                      console.log('Analytics added successfully');
                      toast.success('Analytics added successfully', { duration: 2000 });
                      setTimeout(() => {
                        props.close();
                        props.setReloadAnalytics(true); // Set shouldReloadAnalytics to true in the parent component
                      }, 1500);
                    } else {
                      console.log('Failed to add analytics');
                    }
                    refreshAnalytics();
                  };

  const bg = {
    overlay: {
      background: 'rgba(128, 128, 128, 0.099)'
    }
  };

  const inputStyles = {
    backgroundColor: 'gray-200',
    padding: '0.5rem',
    borderRadius: '0.25rem',
    border: 'none',
    marginBottom: '1rem',
    width: '100%',
    boxSizing: 'border-box',
    fontSize: '1rem',
  };

  const buttonStyles = {
    backgroundColor: 'blue-500',
    color: 'white',
    padding: '0.5rem 1rem',
    borderRadius: '0.25rem',
    border: 'none',
    cursor: 'pointer',
    fontSize: '1rem',
  };

  return (
    <div className=''>
                  
      <Modal
        open={props.open}
        onClose={props.close}
        center
        styles={bg}
        classNames={{ modal: 'customModal' }}
        closeOnOverlayClick={true}
      >  <Toaster />
        <h2 className='mx-auto text-4xl font-bold flex items-center justify-center'>Add Instagram Analytics</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Views:
            <input type="number" className='bg-gray-200' placeholder= 'Views' value={views} onChange={(event) => setViews(event.target.value)} style={inputStyles} />
          </label>
          <br />
          <label>
            Comments:
            <input type="number" className='bg-gray-200' placeholder= 'Comments' value={comments} onChange={(event) => setComments(event.target.value)} style={inputStyles} />
          </label>
          <br />
          <label>
            Likes:
            <input type="number" className='bg-gray-200' placeholder= 'Likes' value={likes} onChange={(event) => setLikes(event.target.value)} style={inputStyles} />
          </label>
          <br />
          <label>
            Shares:
            <input type="number" className='bg-gray-200' placeholder= 'Shares' value={shares} onChange={(event) => setShares(event.target.value)} style={inputStyles} />
          </label>
          <br />
          <button type="submit" className='px-10 flex justify-center items-center mx-auto py-5 bg-red text-white' style={buttonStyles}>Update Analytics</button>
        </form>
      </Modal>
    </div>
  );
}

export default AddInstagramAnalytics;