import React, { useRef, useState, useEffect } from 'react';
import GlobalDialog from '../../utils/globalDialog';
import { ImCross } from 'react-icons/im';
import S3FileUpload from 'react-s3';
import s3 from '../../aws-s3';
import ReactTagInput from '@pathofdev/react-tag-input';
import '@pathofdev/react-tag-input/build/index.css';
import { BsTrash } from 'react-icons/bs';
import Loader from '../Loader';
import { useRecipeState, RecipeService } from '../../Service/RecipeServices';
import Loader2 from '../Loader2';
import CustomButton from '../../utils/customButton';

function UpdateRecipe(props) {
  const { open, onClose, id } = props;
  const recipeState = useRecipeState();
  const [thumbnail, setThumbnail] = useState(false);
  //for Input Handling
  const name = useRef('');
  const description = useRef('');
  const image = useRef('');
  const [errors, setErrors] = useState('');
  const [tags, setTags] = useState(['easy']);
  const [resData, setResData] = useState({
    name: '',
    uid: '',
    description: '',
    thumbnail: '',
    recipeSteps: [],
    recipeIngred: [],
    recipeMetatags: []
  });
  const data = {
    name: '',
    uid: '',
    description: '',
    thumbnail: '',
    recipeSteps: [],
    recipeIngred: [],
    recipeMetatags: []
  };
  const [stepsDesc, setStepsDesc] = useState([
    {
      step: ''
    }
  ]);
  const [ingredients, setIngredients] = useState([
    {
      name: '',
      measurement: '',
      unit: ''
    }
  ]);

  useEffect(() => {
    RecipeService.FetchSingleRecipe(id);
  }, []);

  const recipe = recipeState.recipe.value;

  useEffect(() => {
    if (recipeState.fetched.value) {
      setResData({
        name: recipe.name || '',
        uid: recipe.creator,
        description: recipe.description,
        thumbnail: recipe.thumbnail,
        recipeSteps: recipe.steps,
        recipeIngred: recipe.ingredients,
        recipeMetatags: recipe.metatags
      });
      setTags(recipe.metatags);
      setIngredients(recipe.ingredients);
      setStepsDesc(recipe.steps);
    }
  }, [recipeState.fetched.value, id]);

  //add ingredients
  const handleRemoveIng = (index) => {
    const listing = [...ingredients];
    listing.splice(index, 1);
    setIngredients(listing);
  };

  const handleInputChangeIng = (e, index) => {
    const { name, value } = e.target;
    const listIng = [...ingredients];
    const data = [];
    listIng.forEach((el) => {
      data.push({
        measurement: el.measurement,
        name: el.name,
        unit: el.unit
      });
    });
    data[index][name] = value;
    setIngredients(data);
  };

  //add steps functionality

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...stepsDesc];
    const data = [];
    list.forEach((el) => {
      data.push({
        step: el.step
      });
    });
    data[index][name] = value;
    setStepsDesc(data);
  };

  const handleRemoveClick = (index) => {
    const list = [...stepsDesc];
    list.splice(index, 1);
    setStepsDesc(list);
  };
  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setThumbnail(true);
    } else {
      setThumbnail(false);
    }
  };

  const onChange = (e) => {
    setResData({ ...resData, [e.target.name]: e.target.value });
  };

  //on Form Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (tags.length < 1) {
      return setErrors('Tags Are Required');
    }
    recipeState.merge({ loading: true });

    let url;
    if (thumbnail) {
      url = await S3FileUpload.uploadFile(image.current.files[0], s3).then((data) => data.location);
    }

    const temp = data;
    temp.name = name.current.value;
    temp.description = description.current.value;
    temp.thumbnail = resData.thumbnail ? resData.thumbnail : url;
    temp.recipeSteps = stepsDesc;
    temp.recipeIngred = ingredients;
    temp.recipeMetatags = tags;
    temp.uid = resData.uid;

    if (id) RecipeService.UpdateRecipe(id, temp);
    onClose();
  };

  return (
    <GlobalDialog open={open} onClose={onClose}>
      <h1 className="font-bold  text-2xl mt-2 ml-4 text-gray-600">Update Recipe</h1>
      {!recipeState.fetched.value || !recipe ? (
        <div className="mb-4">
          <Loader2 />
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="p-4">
          <div className="m-2">
            <label htmlFor="profileName" className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              name="name"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter Name"
              value={resData.name}
              onChange={onChange}
              ref={name}
              required
            />
            <div className="text-red px-3 font-medium">{errors.name || ''}</div>
          </div>
          <div className="m-2">
            <label htmlFor="Description" className="block text-sm font-medium text-gray-700">
              Description
            </label>
            <input
              name="description"
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter Description"
              value={resData.description}
              ref={description}
              onChange={onChange}
              required
            />
            <div className="text-red px-3 font-medium">{errors.description || ''}</div>
          </div>
          <div className="m-2">
            <label htmlFor="tags" className="block text-sm font-medium text-gray-700">
              Tags
            </label>

            <ReactTagInput tags={tags} maxTags={6} onChange={(newTags) => setTags(newTags)} />
            {errors && <p className="text-red">{errors}</p>}
          </div>
          <div className="m-2">
            {!resData.thumbnail && (
              <input
                id="imageInput"
                type="file"
                name="thumbnail"
                required
                ref={image}
                onChange={handleChange}
                className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
              />
            )}
            {resData.thumbnail && (
              <div className="w-32 relative mt-4">
                <img src={resData.thumbnail} alt="" className=" object-cover " />
                <div
                  className="absolute -top-2 -right-2 cursor-pointer"
                  onClick={() => setResData({ ...resData, thumbnail: '' })}>
                  <ImCross className="text-red" />
                </div>
              </div>
            )}
          </div>
          <table className="w-full">
            {ingredients.length > 0 &&
              ingredients.map((ingredient, i) => {
                return (
                  <tbody key={i}>
                    {i === 0 && (
                      <tr>
                        <th>
                          <label
                            htmlFor="ingredientMeasurement"
                            className="block text-sm mx-2 text-left font-medium text-gray-700">
                            Ingredient Measurement
                          </label>
                        </th>
                        <th>
                          <label
                            htmlFor="ingredientUnit"
                            className="block text-sm mx-2 text-left font-medium text-gray-700">
                            Ingredient Unit
                          </label>
                        </th>
                        <th>
                          <label
                            htmlFor="ingredientName"
                            className="block text-sm mx-2 text-left font-medium text-gray-700">
                            Ingredient Name
                          </label>
                        </th>
                        <th />
                      </tr>
                    )}
                    <tr>
                      <td>
                        <div className="mx-2 mb-2">
                          <input
                            name="measurement"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Enter Ingredient Measurement"
                            value={ingredient && ingredient.measurement}
                            onChange={(e) => {
                              handleInputChangeIng(e, i);
                            }}
                            required
                          />
                          <div className="text-red px-3 font-medium">
                            {errors.ingredientMeasurement || ''}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="mx-2 mb-2">
                          <select
                            name="unit"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Enter Ingredient Unit"
                            value={ingredient && ingredient.unit}
                            onChange={(e) => {
                              handleInputChangeIng(e, i);
                            }}
                            required>
                            <option>cups</option>
                            <option>ounces</option>
                            <option>lbs</option>
                            <option>cups</option>
                            <option>kg</option>
                            <option>g</option>
                            <option>tsp</option>
                            <option>tbsp</option>
                            <option>L</option>
                            <option>mL</option>
                          </select>
                          <div className="text-red px-3 font-medium">
                            {errors.ingredientUnit || ''}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="mb-2 mx-2">
                          <input
                            name="name"
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            placeholder="Enter Ingredient Name"
                            value={ingredient && ingredient.name}
                            onChange={(e) => {
                              handleInputChangeIng(e, i);
                            }}
                            required
                          />
                          <div className="text-red px-3 font-medium">
                            {errors.ingredientName || ''}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div
                          className="mx-2 text-gray-400 cursor-pointer"
                          onClick={() => handleRemoveIng(i)}>
                          <BsTrash className="inline text-center" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
          </table>
          <div
            className="border-dashed border-2 text-center text-sm p-2 m-2 cursor-pointer"
            onClick={() => {
              setIngredients([...ingredients, { name: '', measurement: '', unit: '' }]);
            }}>
            Add more Ingredients
          </div>
          <div className="mt-4">
            {stepsDesc.length > 0 &&
              stepsDesc.map((x, i) => {
                return (
                  <>
                    {i === 0 && (
                      <label
                        htmlFor="ingredientMeasurement"
                        className="block text-sm font-medium text-gray-700 mt-2">
                        Steps Description
                      </label>
                    )}
                    <div className="flex w-full items-center mt-2">
                      <div className="mr-1">Step</div>
                      <div className="mr-2">{i + 1}:</div>
                      <input
                        type="text"
                        name="step"
                        value={x && x.step}
                        placeholder="Enter Steps Description"
                        onChange={(e) => {
                          handleInputChange(e, i);
                        }}
                        className="bg-gray-100 py-2 px-4 w-full outline-none"
                        required
                      />
                      <div
                        className="text-gray-400 cursor-pointer place-items-center"
                        onClick={() => handleRemoveClick(i)}>
                        <BsTrash className="inline text-center" />
                      </div>
                    </div>
                  </>
                );
              })}
            <div className="text-red px-3 font-medium">{errors.steps || ''}</div>
            <div
              className="border-dashed border-2 text-center text-sm p-2 mt-2 cursor-pointer"
              onClick={() => {
                setStepsDesc([...stepsDesc, { step: '' }]);
              }}>
              Add more Steps
            </div>
          </div>

          <div className="pt-3 mb-2 flex justify-center">
            <CustomButton
              type="submit"
              className="items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              {recipeState.loading.value && <Loader />}
              Update Recipe
            </CustomButton>
          </div>
        </form>
      )}
    </GlobalDialog>
  );
}

export default UpdateRecipe;
