import React, { useContext, useEffect } from 'react';
import AssetCard from './AssetCard';
import { useAssetState, AssetService } from '../../Service/AssetService';
import Loader2 from '../Loader2';
import { useAuth } from '../../Context/AuthContext';
import { Link } from 'react-router-dom';
import { useBrandState } from '../../Service/BrandServices';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';

function Products() {
  const assetState = useAssetState();
  const { currentUser } = useAuth();
  const brandState = useBrandState()
  const { brandId } = useContext(BrandContext);

  useEffect(() => {
    if (brandId) {
      assetState.merge({ loading: true });
      AssetService.FetchAssetsForBrand(brandId);
    }
  }, [assetState.updateNeed.value, brandId] );

  let Assetdata = [];
  if (assetState.assets.value) {
    Assetdata = Object.entries(assetState.assets.value).map((e) => ({
      [e[0]]: e[1]
    }));
  }

  return (
    <div className="m-4 bg-white w-full rounded-lg z-100 overflow-auto"  style={{ height: 'calc(95vh - 5rem)'}}>
      <div className="flex items-center h-16 justify-between ">
        <h3 className="font-semibold p-8 pl-2 ml-5 2xl:text-2xl md:text-base xs:text-base">User-Generated Assets</h3>
      </div>
      <div className="w-full z-100 mb-5 overflow-auto" style={{ height: '79vh' }}>
        {assetState.loading.value ? (
          <Loader2 />
        ) : (
          <>
            {!assetState.loading.value && Assetdata.length === 0 && (
              <div className="w-full">
                <div className="text-center font-bold mx-auto mt-6 mb-4 text-2xl">
                  You currently have no UGC assets, when you do they will appear here. To request
                  UGC videos, click below.
                </div>
                <div className="mt-6 text-center">
                  <Link
                    className="bg-Blue font-bold px-2 py-2 rounded-md my-1 text-white text-lg w-32 items-left justify-left text-center cursor-pointer"
                    to="/brand/dashboard/tasks">
                    Create UGC Task
                  </Link>
                </div>
              </div>
            )}
        
            <div
              className="grid lg:grid-cols-4 p-4 grid-cols-1 h-20 md:grid-cols-2 gap-2 mx-2 pb-5"
            >
              {Assetdata.length > 0 &&
                Assetdata.map((item, i) => {
                  const data = item[Object.keys(item)];
                  const id = Object.keys(item);
                  return (
                    <AssetCard
                      key={i}
                      asset={data}
                      name={data.creator_name}
                      date = {data.created}
                      id={id}
                      thumbnail={data.source}
                    />
                  );
                })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Products;
