import mixpanel from 'mixpanel-browser';

const MIXPANEL_APP_ID = 'ded901f7301815341e3dec27831638e9';
mixpanel.init(MIXPANEL_APP_ID);

let actions = {
  signup: (user) => {
    mixpanel.alias(user.uid);
    mixpanel.track('Sign Up');
    mixpanel.people.set({
      $email: user.email,
      $name: user.username,
      'Registration Date': new Date().toISOString()
    });
  },
  login: (id) => {
    mixpanel.identify(id);
  },
  completeRegistration: (user) => {
    mixpanel.track('Complete Registration', {
      'Profile Name': user.name,
      $avatar: user.avatar,
      Bio: user.bio
    });
  },
  viewLivestream: (view) => {
    mixpanel.track('View Livestream', {
      'Streamer ID': view.streamer_id,
      'Stream Type': view.stream_type
    });

    mixpanel.people.set({
      'Last Livestream View': new Date().toISOString()
    });

    mixpanel.people.set_once({
      'First Livestream View': new Date().toISOString()
    });

    mixpanel.people.increment('Total Livestream Views');
  },
  exitLivestream: (exit) => {
    mixpanel.track('Exit Livestream', {
      'Streamer ID': exit.streamer_id,
      'Stream Type': exit.stream_type
    });
  },
  viewCommunity: (community) => {
    mixpanel.track('View Community', {
      'Community ID': community.community_id,
      'Community Name': community.community_name
    });

    mixpanel.people.increment('Total Community Views');
  },
  initiateScheduleStream: (community) => {
    mixpanel.track('Initiate Schedule Event Stream', {
      'Community ID': community.community_id
    });
  },
  initiateStream: (community) => {
    mixpanel.track('Initiate Start Stream', {
      'Community ID': community.community_id
    });
  },
  scheduleStream: (event) => {
    mixpanel.track('Schedule Stream Event', {
      'Stream Name': event.stream_name,
      'Stream Type': event.stream_type,
      'Stream Event Date Time': event.stream_time,
      'Recipe?': event.stream_recipe,
      'Community ID': event.community_id
    });

    mixpanel.people.set({
      'Last Stream Event': new Date().toISOString()
    });

    mixpanel.people.set_once({
      'First Stream Event': new Date().toISOString()
    });

    mixpanel.people.increment('Total Stream Events');
  },
  startStream: (stream) => {
    mixpanel.track('Start Stream', {
      'Stream Name': stream.stream_name,
      'Stream Type': stream.stream_type,
      'Recipe?': stream.stream_recipe,
      'Community ID': stream.community_id
    });

    mixpanel.people.set({
      'Last Stream': new Date().toISOString()
    });

    mixpanel.people.set_once({
      'First Stream': new Date().toISOString()
    });

    mixpanel.people.increment('Total Streams');
  },
  socialShare: (share) => {
    mixpanel.track('Social Media Share', {
      'Social Media Type': share.share_type,
      'Scheduled Event?': share.scheduled,
      'Stream Type': share.stream_type
    });

    mixpanel.people.increment('Total Shares');
  },

  selectFeaturedProducts: (feature) => {
    mixpanel.track('Featured Products Selected', {
      Products: feature.products,
      'Stream Type': feature.stream_type
    });

    mixpanel.people.set({
      'Last Product Feature': new Date().toISOString()
    });

    mixpanel.people.set_once({
      'First Product Feature': new Date().toISOString()
    });

    mixpanel.people.increment('Total Product Features');
  },

  initiateLivePurchase: (purchase) => {
    mixpanel.track('Initiate Livestream Purchase', {
      'Streamer ID': purchase.streamer_id,
      'Stream Type': purchase.stream_type,
      'Brand ID': purchase.brand_id,
      'Brand Name': purchase.brand_name,
      'Product ID': purchase.product_id,
      'Product Name': purchase.product_name,
      'Product Price': purchase.product_price
    });
  },

  saveProduct: (save) => {
    mixpanel.track('Livestream Product Bookmarked', {
      'Streamer ID': save.streamer_id,
      'Stream Type': save.stream_type,
      'Brand ID': save.brand_id,
      'Brand Name': save.brand_name,
      'Product ID': save.product_id,
      'Product Name': save.product_name,
      'Product Price': save.product_price,
      'Product Save Type': save.type
    });

    mixpanel.people.set({
      'Last Product Save': new Date().toISOString()
    });

    mixpanel.people.set_once({
      'First Product Save': new Date().toISOString()
    });

    mixpanel.people.increment('Total Product Saves');
  },

  livePurchase: (purchase) => {
    mixpanel.track('Livestream Product Purchase', {
      'Streamer ID': purchase.streamer_id,
      'Stream Type': purchase.stream_type,
      'Brand ID': purchase.brand_id,
      'Brand Name': purchase.brand_name,
      'Product ID': purchase.product_id,
      'Product Name': purchase.product_name,
      'Product Price': purchase.product_price,
      'Product Quantity': purchase.product_quantity
    });

    mixpanel.people.set({
      'Last Product Purchase': new Date().toISOString()
    });

    mixpanel.people.set_once({
      'First Product Purchase': new Date().toISOString()
    });

    mixpanel.people.increment('Total Product Purchases');
  },


/************************************* CREATOR IMPLEMENTATION *********************************************************/
  creatorSignUp: (user) => {
    mixpanel.alias(user.uid);
    mixpanel.track('Creator Starts Sign Up');
    mixpanel.people.set({
      $email: user.email,
      $name: user.username,
      $phone: user.phone,
      'Account Type': 'Creator',
      'Registration Date': new Date().toISOString()
    });
    
  },

  creatorAccountSetup: (user) => {
    mixpanel.track('Account Setup Completed', {
      'Profile Name': user.name,
      $avatar: user.avatar,
      Bio: user.bio
    });
  },
  creatorAddressAdded: (user) => {
    mixpanel.track('Physical Address Added');
    mixpanel.people.set({
      $city: user.city,
      $region: user.state,
      'Zip code': user.zipcode
    });
    
  },
  creatorTikTokConnect: (user) => {
    mixpanel.track('TikTok Account Connected');
      mixpanel.people.set({
      $tiktok_handle: user.tiktok_handle,
      $tiktok_median_views: user.tiktok_median_views,
      $tiktok_median_engagements: user.tiktok_median_comments,
      $tiktok_median_likes: user.tiktok_median_likes,
      $tiktok_follower_count: user.tiktok_follower_count
      })
  },

  creatorTikTokConnected: (user) => {
    mixpanel.track('Instagram Account Connected', {
      $instagram_handle: user.instagram_handle,
      $instagram_median_views: user.instagram_median_views,
      $instagram_median_engagements: user.instagram_median_comments,
      $instagram_median_likes: user.instagram_median_likes,
      $instagram_follower_count: user.instagram_follower_count
    });
  },

  influencerTaskView: (task) => {
    console.log('Mixpanel Task Object:', task);
    mixpanel.track('Influencer Task Details Viewed', {
      'Brand ID': task.brand_id,
      'Brand Name': task.brand_name,
      'Campaign ID': task.campaign_id,
      //'Campaign Name': task.campaign_name,
      'Task ID': task.id,
      'Task Name': task.name
    });
  },

  proposalSubmitted: (task) => {
    mixpanel.track('Proposal Application Submitted', {
      'Brand ID': task.brand_id,
      'Brand Name': task.brand_name,
      'Campaign ID': task.campaign_id,
      'Campaign Name': task.campaign_name,
      'Task ID': task.id,
      'Task Name': task.name
    });

    // Set the user profile properties
    mixpanel.people.set_once({
      'First Influencer Proposal Submitted': new Date().toISOString(),
    });
    mixpanel.people.set({ 'Last Influencer Proposal Submitted': new Date().toISOString});

    mixpanel.people.increment('Influencer Proposals Submitted');
  },

  deliverablesSubmitted: (task) => {
    const recipeAvailable = task.addons.includes('recipe') ? true : false;
    const reviewAvailable = task.addons.includes('reviews') ? true : false;
    const photoAvailable = task.addons.includes('photos') ? true : false;
    mixpanel.track('Proposal Application Submitted', {
      'Brand ID': task.brand_id,
      'Brand Name': task.brand_name,
      'Campaign ID': task.campaign_id,
      'Campaign Name': task.campaign_name,
      'Task ID': task.id,
      'Task Name': task.name,
      'Recipe': recipeAvailable,
      'Reviews': reviewAvailable,
      'Product Photos': photoAvailable
    });

    // Set the user profile properties
    mixpanel.people.set_once({
      'First Influencer Deliverable Submitted': new Date().toISOString(),
    });
    mixpanel.people.set({ 'Last Influencer Deliverable Submitted': new Date().toISOString});

    mixpanel.people.increment('Influencer Deliverables Submitted');
  },

  revisedDeliverables : (task) => {
    const recipeAvailable = task && task.add_ons && task.add_ons.some(addon => addon.name === "Recipes");
  const reviewAvailable = task && task.add_ons && task.add_ons.some(addon => addon.name === "Reviews");
  const photoAvailable = task && task.add_ons && task.add_ons.some(addon => addon.name === "Photos");
    mixpanel.track('Proposal Application Submitted', {
      'Brand ID': task.brand_id,
      'Brand Name': task.brand_name,
      'Campaign ID': task.campaign_id,
      'Campaign Name': task.campaign_name,
      'Task ID': task.id,
      'Task Name': task.name,
      'Recipe': recipeAvailable,
      'Reviews': reviewAvailable,
      'Product Photos': photoAvailable
    });

    mixpanel.people.set_once({
      'First Influencer Revision Submitted': new Date().toISOString(),
    });
    mixpanel.people.set({ 'Last Influencer Revision Submitted': new Date().toISOString});

    mixpanel.people.increment('Influencer Revisions Submitted');
  },

  influencerPostAdded : (task) =>{
    mixpanel.track('Post Added', {
      'Brand ID': task.brand_id,
      'Brand Name': task.brand_name,
      'Campaign ID': task.campaign_id,
      'Campaign Name': task.campaign_name,
      'Task ID': task.id,
      'Task Name': task.name,
      'Platform': task.platform
    });

    mixpanel.people.set_once({
      'First Influencer Post Submitted': new Date().toISOString(),
    });
    mixpanel.people.set({ 'Last Influencer Post Submitted': new Date().toISOString});

    mixpanel.people.increment('Influencer Posts Submitted');
  },

  /********************************************** END OF CREATOR IMPLEMENTATION  *********************************************************/


  /********************************************** BEGINNING OF BRAND IMPLEMENTATION  *********************************************************/
  brandSignUp: (user) => {
    mixpanel.alias(user.uid);
    mixpanel.track('Brand Starts Sign Up');
    mixpanel.people.set({
      $email: user.email,
      $name: user.username,
      $phone: user.phone,
      'Account Type': 'Brand',
      'Registration Date': new Date().toISOString()
    });
    
  },

  brandAccountSetup: (user) => {
    mixpanel.track('Account Setup Completed', {
      'Brand Name': user.name,
      $avatar: user.avatar,
      Bio: user.bio
    });
  },

  addProduct: (product) => {
      mixpanel.track('Brand Product Added', {
        'Brand Name': product.brand_name,
        'Product ID': product.product_id,
        'Product Name': product.product_name,
        'Product Price': product.product_price,
      });
  },

  launchCampaign: (campaign) => {
    const recipeAvailable = campaign.recipeAvailable;
    const reviewAvailable = campaign.reviewAvailable;
    const photoAvailable = campaign.photoAvailablec;
    mixpanel.track('Campaign Launched', {
      
      'Brand Name': campaign.brand_name,
      'Campaign ID': campaign.campaign_id,
      'Campaign Name': campaign.campaign_name,
      'Creators': campaign.creators,
      'Recipe': recipeAvailable,
      'Reviews': reviewAvailable,
      'Product Photos': photoAvailable,
      'Viewership Target': campaign.viewership_target,

    });

    mixpanel.people.set_once ({
      'First Campaign Launch': new Date().toISOString()
    })
     mixpanel.people.set({
      'Last Campaign Launch': new Date().toISOString()
     })

     mixpanel.people.increment('Campaigns Launched');
  },

  approveProposal : (proposal) => {
    mixpanel.track('Proposal Approved', {
      'Creator ID': proposal.creator_id,
      'Brand Name': proposal.creator_name,
      'Campaign ID': proposal.campaign_id,
      'Campaign Name': proposal.campaign_name,
      'Task ID': proposal.id,
      'Task Name': proposal.name
    });
  },

  productFulfilled : (fulfillment) => {
    mixpanel.track('Product Fulfilled', {
      'Creator ID': fulfillment.creator_id,
      'Brand Name': fulfillment.brand_name,
      'Campaign ID': fulfillment.campaign_id,
      'Campaign Name': fulfillment.campaign_name,
      'Task ID': fulfillment.id,
      'Task Name': fulfillment.name,
      'Fulfillment Type': fulfillment.fulfillment_type,
      'City': fulfillment.city,
      'State': fulfillment.state,
      'Product ID': fulfillment.product_id,
      'Prodcut Name': fulfillment.product_name,
      'Product Price': fulfillment.product_price,
    });
  },

  approveDraft : (task) => {
    mixpanel.track('Draft Approved', {
      'Creator ID': task.creator_id,
      'Brand Name': task.creator_name,
      'Campaign ID': task.campaign_id,
      'Campaign Name': task.campaign_name,
      'Task ID': task.id,
      'Task Name': task.name
    });
  },

  sendRevisions : (task) => {
    mixpanel.track('Revision Notes Sent', {
      'Creator ID': task.creator_id,
      'Brand Name': task.creator_name,
      'Campaign ID': task.campaign_id,
      'Campaign Name': task.campaign_name,
      'Task ID': task.id,
      'Task Name': task.name
    });

    mixpanel.people.increment('Total Video Revisions Sent');
  },

  viewerShipReached : (campaign) => {
    mixpanel.track('Viewership Reached', {
      'Campaign ID': campaign.campaign_id,
      'Campaign Name': campaign.campaign_name,
    });
    mixpanel.people.set_once({
      'First Successful Campaign': new Date().toISOString()
    })

    mixpanel.people.set({
      'Last Successful Campaign': new Date().toISOString()
    })

    mixpanel.people.increment('Total Successful Campaigns');
  },

  // These last two functions aren't implemented yet
  highEngagementPost : (post) => {
    mixpanel.track('Post Engaged', {
      'Creator ID': post.creator_id,
      'Brand Name': post.creator_name,
      'Campaign ID': post.campaign_id,
      'Campaign Name': post.campaign_name,
      'Task ID': post.id,
      'Task Name': post.name,
      'Platform': post.platform
    });
    
    mixpanel.people.set_once({
      'First High Engagement Post': new Date().toISOString()
    });

    mixpanel.people.set({
      'Last High Engagement Post': new Date().toISOString()
    })

    mixpanel.people.increment('Total High Engagement Posts');
  },

  viralPost : (post) => {
    mixpanel.track('Viral Post Achieved', {
      'Creator ID': post.creator_id,
      'Brand Name': post.creator_name,
      'Campaign ID': post.campaign_id,
      'Campaign Name': post.campaign_name,
      'Task ID': post.id,
      'Task Name': post.name,
      'Platform': post.platform
    });

    mixpanel.people.set_once({
      'First Viral Post': new Date().toISOString()
    })

    mixpanel.people.set({
      'Last Viral Post': new Date().toISOString()
    })

    mixpanel.people.increment('Total Viral Posts');
  }




  /********************************************** END OF BRAND IMPLEMENTATION  *********************************************************/

}

  
export let Mixpanel = actions;
