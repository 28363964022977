import React, { useState, useEffect } from 'react';
import StripeSignupModal from './Onboarding/StripeOnboardingModal';
import { Link, useLocation } from 'react-router-dom';
import { HiOutlineCash } from 'react-icons/hi';
import { baseUrl } from '../../baseUrl';
import { useAuth } from '../../Context/AuthContext';
import dotenv from 'dotenv';
import PayoutsCard from './PayoutsCard';
dotenv.config();


function CreatorPayout() {
  const [balance, setBalance] = useState('');
  const [stripeId, setStripeId] = useState('');
  const { currentUser } = useAuth();
  const stripeConnectLink = 'https://dashboard.stripe.com/register';
  const [isModalOpen, setModalOpen] = useState(!stripeId);
  const location = useLocation();

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  
  const csrfState = Math.random().toString(36).substring(2);
  const SERVER_ENDPOINT_OAUTH = 'https://www.app.cookwithcrafted.com/';

  useEffect(() => {
    // Make API request to retrieve balance
    fetch(`${baseUrl}api/user/balance/${currentUser.uid}`)
      .then(response => response.json())
      .then(data => {
        const { result } = data;
        setBalance(result);
      })
      .catch(error => {
        console.error('Error retrieving balance:', error);
      });
  }, []);
  
  useEffect(() => {
    // Make API request to retrieve stripeId
    fetch(`${baseUrl}api/user/${currentUser.uid}/stripe_id`)
      .then(response => response.json())
      .then(data => {
        const { result } = data;
        setStripeId(result);
      })
      .catch(error => {
        console.error('Error retrieving stripeId:', error);
      });
  }, []);


  return (
    <div className="bg-white m-4 rounded-lg lg:w-full xs:h-fit overflow-hidden" style={{ height: '85vh' }}>
      <div className='flex mt-2  text-3xl md:text-4xl font-thin items-center justify-center'>
        Current Balance:
      </div>
      <div className='text-5xl font-extrabold md:text-7xl flex items-center justify-center'>
        {"$" + (Number(balance) / 100).toFixed(2)}
      </div>
     {/** <div className='text-center items-center mt-5 md:mt-0 justify-center'>
        {balance === 0 ? (
          <h3>You don't have any funds to cash out</h3>
        ) : (
          <div>
            {stripeId ? (
              <button
                onClick=''
                className='px-10  md:px-16 w-fit py-3 md:text-4xl shadow-sm tracking-tighter rounded-lg bg-gray-200 hover:text-white hover:bg-gray-300'
              >
                Cash Out with <span className='text-purple hover:text-purple-light font-bold font-rubik'>Stripe</span>
              </button>
            ) : (
              <a
                href={process.env.REACT_APP_STRIPE_OAUTH_URI}
                target='_blank'
                className='px-10 md:px-16 w-fit py-3 md:text-4xl shadow-sm tracking-tighter rounded-lg bg-gray-200 hover:text-white hover:bg-gray-300'
              >
                Connect your <span className='text-purple hover:text-purple-dark font-bold font-rubik'>Stripe</span> account
              </a>
            )}
          </div>
        )}
      </div> */}
      <div className='border-b-2 mt-10 md:mt-5'></div>

      <PayoutsCard/>
      
    {/** {stripeId === null && isModalOpen ? (
    // Display StripeSignupModal when stripeId is null and isModalOpen is true
    <StripeSignupModal isOpen={isModalOpen} onClose={closeModal} />
  ) : (
    // StripeId is available or isModalOpen is false, no need to show the modal
    <div></div>
  )}*/}
    </div>
  );
}

export default CreatorPayout;
