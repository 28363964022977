import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './CreditPackPaymentForm';
const PUBLIC_LIVE_KEY =
  'pk_live_51HC8pNJZT64pbPqUFQSQTqX6ujIBbTo38MnO6LecTQOiUBFq8eqlHIovYsff3MYsSkFlsA4NqeSfOPCrQ6hufdmX00kRWutCak';

const PUBLIC_TEST_KEY =
  'pk_test_51HC8pNJZT64pbPqUHC849SzcIGxDGSg7wvN5xS9y99JNEZ1MuUDsh83h9JWGbFwwyI1qeomy0RwRnIuVBd3NEsGC00m5TqLuOd';

const stripePromise = loadStripe(PUBLIC_TEST_KEY);

const CLIENT_SECRET = "sk_test_51HC8pNJZT64pbPqUjHmv1U3sRm2sCc8JRnZhLIDBTiH4nbTEfpLWulrcu5jlbAL2BObhnLif0JkIrgbtuPZ3rOjV00hBfmRIbB";

const options = {
  // passing the client secret obtained in step 3
  clientSecret: CLIENT_SECRET,
  // Fully customizable with appearance API.
  appearance: {theme: 'stripe'}
};

function CreditPackStripeContainer(props) {
  console.log("prps:", props)

  return (
    <Elements stripe={stripePromise}>
      <PaymentForm
        plan={props.plan}
        price={props.price}
        qty={props.qty}
        promo={props.promo}
        handleSubscribe={props.handleSubscribe}
      />
    </Elements>
  );
}

export default CreditPackStripeContainer;
