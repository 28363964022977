import React, {useState} from 'react'
import { FaTiktok } from 'react-icons/fa';
function TiktokOnboarding({goToNextTab} ) {
	const [loading, setLoading] = useState(false);
	const csrfState = Math.random().toString(36).substring(2)
 
	const startTikTokLoginFlow = () => {
    const SERVER_ENDPOINT_OAUTH = 'https://www.app.cookwithcrafted.com/creator/dashboard/tik-tok-success/'; // Redirect URI, for dev: 'https://d464-144-121-204-234.ngrok-free.app/'

    const tikTokAuthURL = `https://www.tiktok.com/v2/auth/authorize/?client_key=${process.env.REACT_APP_TIKTOK_CLIENT_KEY}&response_type=code&scope=user.info.stats,user.info.basic,user.info.profile,video.list&redirect_uri=${SERVER_ENDPOINT_OAUTH}&state=${csrfState}`;
                      
    // Store the CSRF token in a cookie or in a secure place on the client-side
    document.cookie = `csrfState=${csrfState}; path=/`;

    // Open the TikTok authorization URL in the same tab/window
    window.open(tikTokAuthURL, '_blank');
		goToNextTab();
};

  return (
		<div className='flex flex-col mt-10 p-4 pl-8 items-center mx-auto'>
		<h2 className='text-4xl text-left mb-4 '>2. Connect your TikTok Account.</h2>
		<p className='font-thin italic xl:text-3xl text-xs text-gray-600 tracking-tighter'>
		In order to be eligible for influencer briefs that require a TikTok Account, you must first connect your TikTok Account to Crafted.
		</p>
		<div className='flex items-left'>
			<div className='mt-3 mb-4'>
				<a
					className='bg-black text-white cursor-pointer px-20 py-4 rounded-lg flex md:text-4xl mt-10 items-center'
					onClick={startTikTokLoginFlow}
				>
					Connect with TikTok <FaTiktok className='ml-2' />
				</a>
			</div>
		</div>
	</div>
  )
}

export default TiktokOnboarding
