import React, { useState, useEffect } from 'react';
import { BsSearch } from 'react-icons/bs';
import { BsFillPersonPlusFill } from 'react-icons/bs';
import UserFormModal from './UserFormModal';
import UpdateModal from './UserUpdateModal';
import { UsersService, useUserState } from '../../../Service/userServices';
import { GrFormPrevious } from 'react-icons/gr';
import { MdOutlineNavigateNext } from 'react-icons/md';
import UsersDeleteModal from './UsersDeleteModal';
import { useRef } from 'react';
import NewUpdate from './newUpdate';
import UpdateUserForm from './UserUpdateModal';
import Loader from '../../Loader2';

function UsersTable() {
  const [OpenProfileModal, setOpenProfileModal] = useState(false);
  const [OpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [OpenAddUserModal, setOpenAddUserModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const userState = useUserState();
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);
  const [userInfo, setUserInfo] = useState();
  const [previousCursor, setPreviousCursor] = useState(null);
  const [userData, setUserData] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [display, setDisplay] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const modalRef = useRef();




  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  const [nextCursor, setNextCursor] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [history, setHistory] = useState([]);
  
  const handleSearch = (e) => {
    e.preventDefault(); // Prevent the form from being submitted
    const value = e.target.value;
    setSearchTerm(value);
    console.log("search term", value);
    setLoading(true);
    fetchData();
  };
  
  const fetchData = async () => {
    try {
      let response;
      if (searchTerm !== '') {
        response = await getUsers(searchTerm);
      }
      const { result, length } = response;
  
      const resultArray = Array.isArray(result) ? result : [result];
      console.log("array length", length);
      setUserData(resultArray);
    } catch (error) {
      console.error('Error loading users:', error);
    } finally {
      setLoading(false); // Set loading to false after data has been fetched
    }
  };
  
  async function getUsers(searchTerm) {
    try {
      const response = await fetch(`https://us-central1-crafted-dev-v1.cloudfunctions.net/loadusers-prod?query=${searchTerm}`);
      const data = await response.json();
      console.log(data);
      return data; // Return the fetched data
    } catch (error) {
      console.error('Error:', error);
    }
  }

    /*const handleSearch = (e) => {
      const value = e.target.value;
      /*const lowerCaseValue = value.toLowerCase();

      const queryResults = [];

     UsersService.QueryUsers(lowerCaseValue)
      .then((user) => {
          // load results into an array to be displayed
          let users = user.result.data;
          users.forEach((user) => {
            queryResults.push(user);
          }
          )
      })

      UsersService.LoadUsersInBatches()

      setSearchTerm(value);
    
      const results = arr.filter((user) => {
        const { bio, email, profile_name, username, name, shipping_details, fullname, creator_socials, negotiated }= user;
        const { address1, address2, city, country, state, zipcode } =
          shipping_details || {};
    
        const lowerCaseValue = value.toLowerCase(); // Convert search value to lowercase
    
        // Search by bio, email, profile name, username, name, and shipping details (case-insensitive)
        return (
          (bio && bio.toLowerCase().includes(lowerCaseValue)) ||
          (email && email.toLowerCase().includes(lowerCaseValue)) ||
          (profile_name && profile_name.toLowerCase().includes(lowerCaseValue)) ||
          (username && username.toLowerCase().includes(lowerCaseValue)) ||
          (name && name.toLowerCase().includes(lowerCaseValue)) ||
          (fullname && fullname.toLowerCase().includes(lowerCaseValue)) ||
          (address1 && address1.toLowerCase().includes(lowerCaseValue)) ||
          (address2 && address2.toLowerCase().includes(lowerCaseValue)) ||
          (city && city.toLowerCase().includes(lowerCaseValue)) ||
          (country && country.toLowerCase().includes(lowerCaseValue)) ||
          (state && state.toLowerCase().includes(lowerCaseValue)) ||
          (creator_socials && (
            (creator_socials.tiktok && Object.values(creator_socials.tiktok).some((value) => typeof value === 'string' && value.toLowerCase().includes(lowerCaseValue))) ||
            (creator_socials.instagram && Object.values(creator_socials.instagram).some((value) => typeof value === 'string' && value.toLowerCase().includes(lowerCaseValue))) ||
            (creator_socials.youtube && Object.values(creator_socials.youtube).some((value) => typeof value === 'string' && value.toLowerCase().includes(lowerCaseValue)))
          )) ||
          (zipcode && zipcode.toLowerCase().includes(lowerCaseValue))
        );
      });
    
      setSearchResults(results);
      setcurrentPage(1);
    }; */
  
  const pages = Math.ceil(userData.length / itemsPerPage);

  const handleUpdate = async (el, id) => {
    setUserData(el);
    setSelectedId(id);
    setOpenProfileModal(true);
  };

  const handleDelete = (id) => {
    setOpenDeleteModal(true);
    setSelectedId(id);
  };

  const openModal = (data, key) => {
    console.log("data", data);
    console.log("key", key);
    setUserInfo(data);
    setSelectedId(key);
    setIsOpen(true);
  };
  
  useEffect(() => {
    console.log("user info now", userInfo);
  }, [userInfo]);

  const closeModal = () => {
    setIsOpen(false);
  };

  

  return (
    <>
      <div className="-my-2 py-2  sm:-mx-6 sm:px-6 lg:-mx-8 pr-10 lg:px-8 h-fit overflow-y-auto" style={{ height: 'calc(95vh - 5rem)'}}>
        <div className="align-middle rounded-tl-lg rounded-tr-lg inline-block w-full py-4 overflow-hidden bg-white px-12">
          <div className="flex justify-between">
            <div className="inline-flex border rounded w-7/12 px-2 lg:px-6 h-12 bg-transparent">
              <div className="flex justify-between items-center w-full h-full mb-6 relative">
                <input
                  type="text"
                  className="flex-shrink flex-grow flex-auto leading-normal tracking-wide w-px  border border-none border-l-0 rounded rounded-l-none px-3 relative focus:outline-none text-xxs lg:text-xs lg:text-base text-gray-500 font-thin"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
                <button onClick={handleSearch} className='border px-10 py-2 absolute rounded hover:bg-gray-200 right-0 text-blue'>Search</button>
              </div>
            </div>
            <button
              className="px-5 py-2 border-blue border text-blue rounded transition duration-300 hover:bg-blue hover:text-white focus:outline-none"
              onClick={() => {
                setOpenAddUserModal(true);
              }}>
              <BsFillPersonPlusFill className="mr-2 mb-1.5 inline w-8 h-6" />
              Add User
            </button>
          </div>
        </div>
        <div className="align-middle inline-block min-w-full overflow-hidden overflow-y-scroll h-fit bg-white shadow-dashboard px-8 pt-3 rounded-bl-lg rounded-br-lg">
          <table className="min-w-full h-fit overflow-y-scroll">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-blue-500 tracking-wider">
                  Fullname
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-blue-500 tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-blue-500 tracking-wider">
                  Socials
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-blue-500 tracking-wider">
                  City/State
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-blue-500 tracking-wider">
                  Action
                </th>
                <th className="px-6 py-3 border-b-2 border-gray-300"></th>
              </tr>
            </thead>
            <div>
            {isOpen && (
              <div className="fixed z-10 inset-0 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
              onClick={handleClickOutside}
              >
                <div className="relative w-1/2 bg-white rounded-lg" ref={modalRef}>
                <UpdateUserForm
                  closeAction={() => {
                    setOpenProfileModal(false);
                  }}
                  userID={selectedId}
                  userInfo={userInfo}
                />
                </div>
              </div>
            )}
          
            </div>  
            {loading && 
            <div className='absolute top-3rem right-0 left-20rem bottom-0 flex items-center mx auto flex-col justify-center'>
              <Loader/>
             <p className='mt-5 text-base'>Loading users. This might take a while, as it is a large database.</p> 
            </div>}
            {!loading && userData === '' &&
            <div className='absolute top-35rem right-0 left-20rem bottom-0 flex items-center mx auto h-10 flex-col justify-center'>
              Search for any creators using the search bar above
            </div>}
            {
            <tbody className="bg-white scrolling-touch overflow-y-scroll h-fit scrollbar">
            {loading === false && userData.length > 0 && userData.map((user, i) => {
              if (display === false) {
                setDisplay(true);
              }
              
              // Destructure the key and data properties from the user object
              const { key, data: userInfo } = user;
              
              return (
                <tr key={i}>
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                    <div className="text-sm leading-5 text-blue-900">{userInfo && userInfo.name  || userInfo.shipping_details && userInfo.shipping_details.fullname }</div>
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap border-b text-blue-900 border-gray-500 text-sm leading-5">
                    {userInfo.email || userInfo.paypal_email}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                    <div className="flex gap-3">
                      {userInfo.creator_socials && userInfo.creator_socials.instagram && userInfo.creator_socials.instagram.handle && (
                        <div className="text-sm leading-5 instagram w-5 h-5 text-blue-900">
                          <a href={`https://www.instagram.com/${userInfo.creator_socials.instagram.handle}`} target="_blank" className="h-5 w-5 opacity-0">Ins</a>
                        </div>
                      )}
                      {userInfo.creator_socials && userInfo.creator_socials.tiktok && (
                        <div className="text-sm leading-5 tiktok w-5 h-5 text-blue-900">
                          <a href={`https://www.tiktok.com/@${userInfo.creator_socials.tiktok.performance && userInfo.creator_socials.tiktok.performance.handle || userInfo.creator_socials.tiktok.handle}`} target="_blank" className="h-5 w-5 opacity-0">In</a>
                        </div>
                      )}
                      {userInfo.creator_socials && userInfo.creator_socials.youtube && (
                        <div className="text-sm leading-5 youtube w-5 h-5 text-blue-900">
                          <a href={`https://www.youtube.com/${userInfo.creator_socials.youtube.handle}`} target="_blank" className="h-5 w-5 opacity-0">Instagram</a>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap border-b text-blue-900 border-gray-500 text-sm leading-5">
                    {userInfo.shipping_details ? <p>{userInfo.shipping_details.city}/{userInfo.shipping_details.city || 'N/A'}</p> : ''}
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500 text-blue-900 text-sm leading-5">
                    <button className="px-5 py-2 bg-Red text-white rounded focus:outline-none" onClick={() => handleDelete(key)}>Delete</button>
                  </td>
                  <td className="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-500 text-sm leading-5">
                    <button className="px-5 py-2 border-blue border text-blue-500 rounded transition duration-300 hover:bg-blue hover:text-white focus:outline-none" onClick={() => openModal(userInfo, key)}>View Details</button>
                  </td>
                </tr>
              );
            })}
          </tbody>         
          }
          </table>
        </div>
        {OpenProfileModal ? (
          <UpdateUserForm
          closeAction={() => {
            setOpenProfileModal(false);
          }}
          userID={selectedId}
          userInfo={userInfo}
        />
        ) : null}
        {OpenAddUserModal ? (
          <UserFormModal
            closeAction={() => {
              setOpenAddUserModal(false);
            }}
          />
        ) : null}
        {OpenDeleteModal ? (
          <UsersDeleteModal
            closeAction={() => {
              setOpenDeleteModal(false);
            }}
            userId={selectedId}
          />
        ) : null}
      </div>
      {display && 
        <>
        {/*  <div className="flex items-center justify-center mt-4" style={{marginTop:"100px"}}>
        <button
          onClick={() => goToPreviousPage()}
          className="mr-8 cursor-pointer bg-red text-white py-1 px-2 rounded-lg">
          <GrFormPrevious className="inline text-white fill-current" />
          Previous
        </button>
        <div className="mr-4 font-bold">
          {currentPage} - {pages}
        </div>
        <button
          onClick={() => handleNextPage()}
          className="cursor-pointer bg-red text-white py-1 px-2 rounded-lg">
          Next
          <MdOutlineNavigateNext className="inline text-white fill-current" />
        </button>
        </div>*/} 
      
      </>}
    </>
  );
}

export default UsersTable;
