import React, { useState, useEffect } from 'react';
import {AiFillInstagram} from'react-icons/ai';
import {FaTiktok} from 'react-icons/fa';
import InfluencerTaskDetails from './InfluencerTaskDetails';
import EditInfluencerTaskModal from './EditInfluencerTaskModal'
import GlobalDialog from '../../../utils/globalDialog';
import EditTaskModal from '../EditTaskModal';
import { useParams } from 'react-router-dom';
import EditCurrentTaskModal from './EditCurrentTaskModal';
import DeleteInfluencerTaskModal from './DeleteInfluencerTaskModal';
import { CampaignService } from '../../../Service/CampaignServices';
import CustomButton from '../../../utils/customButton';
function InfluencerTaskCard(props) {
  const { id } = useParams();
  const [delTask, setDelTask] = useState(false);
  const [taskDetails, setTaskDetails] = useState(false);
  const [proposalTask, setproposalTask] = useState(false);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editTask, setEditTask] = useState(false);

  useEffect(() => {
    props.reload();
  }, []);

  const detailsHander = () => {
    setTaskDetails(!taskDetails);
  };

  const proposalHandle = () => {
    setproposalTask(!proposalTask);
  };

  const editTaskHandle = () => {
    setEditTask(!editTask);
  }
  const updateHandle = () => {
    let updt = update;
    setUpdate(!updt);
  };

  const deleteHandle = () => {
    setDelTask(!delTask);
    console.log("delTask state after clicking Delete Task:", !delTask);
  };

  return (
    <>
<div onClick={() => proposalHandle()}>
<div className="bg-lightGray items-center rounded-xl p-1 flex flex-col cursor-pointer">
      <div className="relative w-full h-full">
    {props.task && (
      <img
        src={props.task.brand_photo}
        style={{ height: '150px' }}
        className="rounded-lg w-full h-full bg-white object-contain"
        alt="img"
      />
    )}
      {props.task && props.task.platform && props.task.platform.includes('Instagram') &&
        <div className="absolute bottom-0 left-2">
          <div className="instagram w-4 h-4"></div>
        </div>
      }
      {props.task && props.task.platform && props.task.platform.includes('TikTok') &&
        <div className={`absolute bottom-0 ${props.task.platform.length === 1 && props.task.platform.includes('TikTok') ? 'left-2' : 'left-7'}`}>
          <div className="tiktok w-4 h-4"></div>
        </div>
      }
    </div>
    <div className='lg:text-3xl md:text-xs text-center truncate overflow-hidden leading-tight mt-0.5 md:w-36'>
      {props.task.name}
    </div>
    <h2 className='font-bold'>{props.task.brand_name}</h2>

    <div className="text-base mb-1 tracking-tighter font-bold text-center">
      {props.task && props.task.add_ons && props.task.add_ons.map((el, i) => (
        <span key={i}>{i !== 0 && '+'} {el.name}</span>
      ))}
    </div>
  </div>
        
  </div>
  <div className='flex flex-row items-center justify-center gap-4'>
  <CustomButton
    className="bg-red text-white mt-2 px-2 items-center justify-center rounded-lg outline-none text-xs"
    onClick={() => editTaskHandle()}
  >
            Edit Brief
  </CustomButton> 
  <CustomButton
    className=" text-white mt-2 px-2 items-center justify-center rounded-lg outline-none text-xs"
    onClick={() => deleteHandle()}>
    Delete Brief
  </CustomButton>
  </div>
  {proposalTask ? (
        <InfluencerTaskDetails
          open={proposalTask}
          task={props.task}
          onClose={() => setproposalTask(false)}
          id={props.id}
        />
      ) : null}

  {editTask ? (
        <EditCurrentTaskModal
          open={editTask}
          reload = {props.reload}
          campaign = {props.campaign}
          task={props.task}
          onClose={() => setEditTask(false)}
          id={props.id}
        />
      ) : null}

  {delTask ? (
    <DeleteInfluencerTaskModal
      open={delTask}
      task={props.task}
      campaign = {props.campaign}
      onClose={() => setDelTask(false)}
      reload = {props.reload}
      id={props.id}
    />
  ) : null}
    

  </>
  );
}

export default InfluencerTaskCard;
