import React, {useState, useEffect} from 'react'
import {toast, Toaster} from 'react-hot-toast'
import { UsersService } from '../../../Service/userServices';
import CustomButton from '../../../utils/customButton';
function NegotiatedRateForm(props) {
  const [negotiatedRate, setNegotiatedRate] = useState();
  const [instagramDollars, setInstagramDollars] = useState();
  const [instagramCents, setInstagramCents] = useState();
  const [tiktokDollars, setTiktokDollars] = useState();
  const [tiktokCents, setTiktokCents] = useState();

  useEffect(() => {
    if (
      props.userInfo &&
      props.userInfo.creator_socials &&
      props.userInfo.creator_socials.instagram &&
      props.userInfo.creator_socials.instagram.suggested_rate
    ) {
      const suggestedRate = props.userInfo.creator_socials.instagram.suggested_rate;
      const dollars = Math.floor(suggestedRate / 100);
      const cents = suggestedRate % 100;
      setInstagramDollars(dollars.toString());
      setInstagramCents(cents.toString());
    }
  }, [props.userInfo]);
  
  useEffect(() => {
    if (
      props.userInfo &&
      props.userInfo.creator_socials &&
      props.userInfo.creator_socials.tiktok &&
      props.userInfo.creator_socials.tiktok.performance &&
      props.userInfo.creator_socials.tiktok.performance.suggestedRate
    ) {
      const suggestedRate = props.userInfo.creator_socials.tiktok.performance.suggestedRate;
      const dollars = Math.floor(suggestedRate / 100);
      const cents = suggestedRate % 100;
      setTiktokDollars(dollars.toString());
      setTiktokCents(cents.toString());
    }
  }, [props.userInfo]);
  

  const handleInstagramDollarsChange = (e) => {
    let input = e.target.value;
    setInstagramDollars(input);
  };

  const handleTiktokDollarsChange = (e) => {
    let input = e.target.value;
    setTiktokDollars(input);
  };

  const handleInstagramCentsChange = (e) => {
    let input = e.target.value;
    if (input.length > 2) {
      input = input.slice(0, 2);
    }
    setInstagramCents(input);
  };

  const handleTiktokCentsChange = (e) => {
    let input = e.target.value;
    if (input.length > 2) {
      input = input.slice(0, 2);
    }
    setTiktokCents(input);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const combinedInstagramRate = parseFloat(instagramDollars + '.' + instagramCents);
    const combinedTiktokRate = parseFloat(tiktokDollars + '.' + tiktokCents);
    const data = {
      tik_tok_rate: combinedTiktokRate,
      instagram_rate: combinedInstagramRate
    };
    UsersService.AddNegotiatedRate(props.userID, data);
    toast.success('Negotiated Rate Added', {duration: 1800});
  }
  return (
    <div className='h-1/2 mt-20'>
      <Toaster/>
      <form onSubmit={handleSubmit}>
      <h2 className='font-bold my-2'>Enter the Instagram negotiated rate for this user:</h2>
      <div className="flex gap-2 items-baseline">
        $
        <input
          value={instagramDollars}
          onChange={handleInstagramDollarsChange}
          className="focus:outline-none w-1/3 bg-lightGray p-2 mt-2 rounded"
          type="number"
          placeholder="Dollars"
        />
       <p className='font-bold'>.</p> 
        <input
          value={instagramCents}
          onChange={handleInstagramCentsChange}
          className="focus:outline-none w-1/3 bg-lightGray p-2 mt-2 rounded"
          type="number"
          placeholder="Cents"
        />
      </div>
      
      <h2 className='font-bold mt-7'>Enter the TikTok negotiated rate for this user:</h2>
      <div className="flex gap-2 items-baseline">
        $
        <input
          value={tiktokDollars}
          onChange={handleTiktokDollarsChange}
          className="focus:outline-none w-1/3 bg-lightGray p-2 mt-2 rounded"
          type="number"
          placeholder="Dollars"
        />
       <p className='font-bold'>.</p> 
        <input
          value={tiktokCents}
          onChange={handleTiktokCentsChange}
          className="focus:outline-none w-1/3 bg-lightGray p-2 mt-2 rounded"
          type="number"
          placeholder="Cents"
        />
      </div>
      <CustomButton type="submit" className="mt-6 ml-4 text-white px-4 py-2 ">Submit</CustomButton>

      </form>
    </div>
  )
}

export default NegotiatedRateForm