import React, { useState, useEffect } from 'react';
import { RecipeService, useRecipeState } from '../../Service/RecipeServices';
import { useAuth } from '../../Context/AuthContext';
import AddRecipe from '../AddRecipe';
import Loader from '../Loader2';
import ProfileRecipeCard from './ProfileRecipeCard';

function ProfileRecipes(props) {
  const recipeState = useRecipeState();
  const [addrecipe, setAddRecipe] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (recipeState.updateNeed.value) {
      RecipeService.FetchRecipeForUser(props.uid);
    }
  }, [recipeState.updateNeed.value]);

  const res = recipeState.recipes.value;
  const recipe = res
    ? Object.entries(res).map((e) => ({
        [e[0]]: e[1]
      }))
    : [];
  return (
    <div className="bg-white m-4 rounded-lg lg:w-full xs:h-fit" style={{ height: '78vh' }}>
      {recipeState.loading.value && <Loader />}
      {!recipeState.loading.value && recipe.length === 0 && (
        <h1 className="table mx-auto text-red text-2xl">No Recipes found</h1>
      )}
      <div className="grid lg:grid-cols-5 grid-cols-1 overflow-y-scroll h-41 md:grid-cols-2 gap-2 mx-2">
        {recipe.length > 0 &&
          recipe.map((item, i) => {
            const res = item[Object.keys(item)];
            const id = Object.keys(item);
            return (
              <div key={i}>
                <ProfileRecipeCard recipe={res} id={id} />
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default ProfileRecipes;
