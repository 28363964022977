/* eslint-disable no-useless-escape */
import React, { useState, useRef } from 'react';
import { useAuth } from '../../Context/AuthContext';
import { Link, useParams } from 'react-router-dom';
import '../../App.css';
import axios from 'axios';
import 'react-phone-input-2/lib/style.css';
import Navbar from '../Navbar';
import success from '../../Assets/GirlWithEggs.png';
import { BrandService } from '../../Service/BrandServices';
import toast, { Toaster } from 'react-hot-toast';
import { Mixpanel } from '../../mixpanel';
import Loader from '../Loader';
import logo from '../../Assets/logo-red.png';
import brand_icon from '../../Assets/brand-login.jpg'
import { AccountService } from '../../Service/AccountServices';
import BrandInviteSignUp from './BrandInviteSignUp';
const re =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const usernameRe = /^[a-z0-9_\.]+$/;
function BrandInviteOnboarding() {
  const userEmail = useRef('');
  const userPassword = useRef('');
  const userPasswordConfirm = useRef('');
  const brandName = useRef('');
  const firstName = useRef('');
  const lastName = useRef('');
  const { id } = useParams();
  const { signup } = useAuth();
  const [error, setError] = useState({
    email: '',
    password: '',
    confirmPass: '',
    firstname: '',
    lastname: '',
    brandname: '',
    phoneNumber: '',
    common: '',
    terms: ''
  });
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [isTermsChecked,  setIsTermsChecked] = useState(false);
  var new_user = {};

  const onChange = (e) => {
    setError({ ...error, [e.target.name]: '' });
  };

  

  async function handleSubmit() {

    let err = error;
    // return;
    if (userPassword.current.value !== userPasswordConfirm.current.value) {
      err.password = 'Passwords do not match.';
      return setError({ ...error, err });
    } else if (userPassword.current.value.length < 7) {
      err.email = 'Password must be at least 6 characters long.';
      return setError({ ...error, err });
    } else if (!re.test(userEmail.current.value)) {
      err.email = 'Email is not valid.';
      return setError({ ...error, err });
    } 
    if (!userPassword.current.value) {
      return (err.password = 'Password is required');
    }
    if (firstName.current.value === '') {
      return (err.firstname = 'First name is required');
    }
    if (lastName.current.value === '') {
      return (err.lastname = 'Last name is required');
    }
    if (!userPasswordConfirm.current.value) {
      err.confirmPass = 'Confirm Password is required';
    } else if (userPassword.current.value.length < 6) {
      err.confirmPass = 'Password should be at least 6 characters';
    } else if (userPassword.current.value !== userPasswordConfirm.current.value) {
      err.confirmPass = 'Confirm Password and Password is not match';
    }

    if (!isTermsChecked) {
      err.terms = 'You must agree to the terms of service';
      return setError({ ...error, err });
    }

    

    try {
      setError({
        email: '',
        password: '',
        confirmPass: '',
        brandname: '',
        firstname: '',
        lastname: '',
        phoneNumber: '',
        common: '',
        terms: ''
      });
      setLoading(true);

      const currEmail = userEmail.current.value;
      await signup(userEmail.current.value, userPassword.current.value.trim())
        .then((userCreds) => {
          new_user = {
            uid: userCreds.user.uid,
            avatar: 'https://crafted-react-images.s3.amazonaws.com/default_pfp_1384719047375875089754832059.png',
            first_name: firstName.current.value,
            last_name: lastName.current.value,
            email: currEmail,
            brand_id: id
          };
          console.log("new user:", new_user);
          AccountService.CreateInvitedBrandAccount(new_user);
          Mixpanel.signup(new_user);
          setCurrentTab(1);
        })
        .catch((error) => {
          toast.error('email is already taken!!');
          setLoading(false);
        });
    } catch (__error) {
      err.common = __error;
      setError({ ...error, err });
      console.error("error! ", __error);
    }
  }

  return (
    <div className="bg-gray-600 min-h-screen h-max w-screen pb-10">
      <Navbar />
      <div className="grid md:grid-cols-2 xs:grid-row-2 gap-4 xs:grid-row-2 mx-auto lg:mt-32">
        <div className="flex flex-col justify-center align-center">
          <div className='flex justify-left'>
            <h2 className="justify-center rounded-lg text-center ml-16 mb-4 px-4 bg-red font-black text-2xl lg:text-5xl text-white">FOR BRANDS</h2>
          </div>
          <img className="mt-10 h-72 rounded-xl mx-auto" src={brand_icon}/>
          <div className="text-white text-center font-black lg:text-5xl mx-10 mt-8 mb-8">Get affordable, authentic, effective UGC for your brand.</div>
        </div>
        <div className="bg-white p-4 rounded-2xl md:w-4/6 xs:w-full place-self-center xs:mt-10">
          <img src={logo} alt="logo" className="mx-auto" />
          <ul id="tabs" className="flex mb-14">
          
            <li
              className={
                currentTab === 0
                  ? `w-full px-7 font-semibold py-2 border-solid border-b-4 border-Red text-center text-Red`
                  : `w-full px-7 font-semibold py-2 border-solid border-b-4 border-transparent text-center`
              }
              onClick={() => {
                setCurrentTab(0);
              }}>
              <p className="cursor-pointer">Sign Up</p>
            </li>
          </ul>
          <div>
            
            {currentTab === 0 ? (
              <>
                <BrandInviteSignUp />
                <p
                  className="text-black font-bold text-center cursor-pointer"
                  onClick={() => setCurrentTab(0)}>
                  Have an account already? Log in.
                </p>
              </>
            ) : null}

          </div>
        </div>
      </div>
    </div>
  );
}

export default BrandInviteOnboarding;
