import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import GlobalDialog from '../../utils/brandModal';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';
import { useHistory } from 'react-router-dom';

function BrandCard({ brandPhoto, brandName, paidUsage, currentUsage, id }) {
  const {brandId, setBrandId} = useContext(BrandContext);
  const [modal, setModal] = useState(false);
  const handleAllocation = () => {
    setModal(!modal);
  }

  const history = useHistory();

  const handleBrandChange = (selectedBrandId) => {
    localStorage.setItem('brandId', selectedBrandId); // Update localStorage with the selected brandId
    setBrandId(selectedBrandId);
    history.push(`/brand/dashboard`); // Redirect to the dashboard
  }
  //console.log("id in BrandCard: ", id)
  return (
    <div> 
      <div className="bg-lightGray items-center border-2 rounded-xl p-1 flex flex-col cursor-pointer">
      <div 
          className="relative w-full"
          onClick={() => handleBrandChange(id)}
        >
          <img
            src={brandPhoto}
            style={{ height: '150px' }}
            className="rounded-lg w-full bg-white object-contain"
            alt="img"
          />

        </div>
        <h2 className='font-extrabold text-center mt-2'>{brandName}</h2>
        <div className='text-center'>
          {parseInt(paidUsage - currentUsage)} credits
        </div>
          {/*<button onClick={handleAllocation}
            className="bg-red flex mx-auto text-white px-2 rounded-lg outline-none text-xs"
          >
            Allocate Credits
          </button>*/}
      </div>
      {modal ? 
        <GlobalDialog open={modal} onClose={() => setModal(false)} style={{ maxWidth: '100px' }}>
          <div className='flex flex-col items-center p-8'>
            <img
              src={brandPhoto}
              style={{ height: '150px' }}
              className="rounded-lg w-full bg-white object-contain mb-4"
              alt="img"
            />
            <div className='font-bold text-center mb-4'>
              How many credits do you want to send to {brandName}?
            </div>
            <input className='border-2 rounded-md mb-4 p-2 w-1/2' type="text" />
            <button className='bg-red px-4 py-2 rounded-lg text-white'>
              Allocate Credits
            </button>
          </div>
        </GlobalDialog>
        :
        null
      }

    </div>
  );
}

export default BrandCard;