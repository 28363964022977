import React, { useContext, useState } from 'react'
import GlobalDialog from '../../utils/globalDialog'
import {toast, Toaster} from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { BsExclamationCircle } from 'react-icons/bs';
import { TaskService, useTaskState } from '../../Service/TaskServices';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';
import CustomButton from '../../utils/customButton';
function UgcDenialModal(props) {

  const { id } = useParams();
  console.log("props", props);
  const taskState = useTaskState();
  const { brandId } = useContext(BrandContext);

  const deniedProp = async (proposal, proposalKey) => { 
    taskState.merge({loading: true});
    console.log("proposal: ", props.proposal);
    console.log("proposalKey: ",  props.proposalKey);
   try{
    taskState.merge({updateNeed: true});
      let data = {
        proposal:  props.proposal,
        proposal_id:  props.proposalKey,
        creator_id:  props.proposal.creator_id,
        bid: brandId,
        task_id:  props.task.id
      }
      console.log("data: ", data);

      TaskService.DenyProposal( props.task.id, data)
     
      toast.success('Creator Denied!');
      TaskService.FetchSingleTask(id); 
      taskState.merge({updateNeed: false});
      props.fetchData();
      props.close();
    }
    catch(error){
    }
    
  }

  return (
    <div className='bg-white rounded-md px-10 break-words 2xl:text-2xl text-xs h-1/2  md:h-fit flex flex-col items-center justify-center'>
       <div className='bg-white rounded-md break-words 2xl:text-2xl text-xs h-fit w-full my-10'>
        <div className="p-6 pt-0 text-center">
          <h1 className='2xl:text-3xl text-base mb-2 font-bold'>Deny Creator</h1>
          <BsExclamationCircle className="mx-auto mb-4 w-32 h-14 text-gray-400 dark:text-gray-200" />
          <h3 className="mb-5 w-full 2xl:text-2xl text-base font-normal text-gray-700 dark:text-gray-400">
            Are you sure you want to deny {props.proposal.creator_address.fullname}?
          </h3>
          <div className='flex flex-row items-center justify-center gap-10'>
          <CustomButton
            type="button"
            className="text-white hover:bg-red-800  font-mediumtext-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
            onClick={() => deniedProp()}>
            Deny
          </CustomButton>
          <button
            type="button"
            className="text-gray-500 bg-white hover:bg-gray-100  rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600"
            onClick={() => props.close()}>
            Cancel
          </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UgcDenialModal