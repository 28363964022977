import React, { useState, useEffect, useContext } from 'react';
import { useProductState, ProductService } from '../../../Service/ProductServices';
import { BrandContext } from '../../../routes/PrivateRoute/BrandDashboardRoute';
import GenerateBriefLoading from './GenerateBriefLoading';
import GenerateBriefResult from './GenerateBriefResult';
import CustomGlobalDialog from '../CustomGlobalDialog';
import { useGenerativeBriefState, GenerativeBriefService } from '../../../Service/GenerativeBriefService';
import { toast, Toaster } from 'react-hot-toast';
import Loader from '../../Loader2';
import GenerateBriefForm from './GenerateBriefForm';
import './GenerativeBriefs.css';

const GenerateBriefModal = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [step, setStep] = useState(0); // 0: form, 1: loading, 2: generated
  const [briefData, setBriefData] = useState();
  const [briefId, setBriefId] = useState();
  // loading the form
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 650); // 1000ms = 1 second
  
    // Cleanup function to clear the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  let content;
  if (step === 1) {
    content = <GenerateBriefLoading step={step} setStep={setStep} />;
  } else if (step === 2) {
    content = <GenerateBriefResult step={step} setStep={setStep} briefId={briefId} briefData={briefData} />;
  } else {
    content = isLoading ? <div className="flex justify-center items-center h-96"><Loader/></div> :
    <GenerateBriefForm step={step} setStep={setStep} setBriefId={setBriefId} setBriefData={setBriefData} />; // Pass step and setStep as props
  }

  return (
    <CustomGlobalDialog className="2xl:w-2/3 md:w-2/3 2xl:h-80rem overflow-hidden md:h-screen" open={props.open} onClose={props.onClose}>
      <Toaster/>
      <div className={`component-loaded`}>
        {content}
      </div>
    </CustomGlobalDialog>
  );
};

export default GenerateBriefModal;