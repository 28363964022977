import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';

//State
const state = createState({
  orders: [],
  order: [],
  retrieving: false,
  fetched: false,
  inserted: false,
  message: '',
  loading: true,
  updateNeed: true
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'ORDERS_LOADED':
        return s.merge({
          orders: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true
          // fetched: true,
        });
      case 'CREATE_ORDER':
        return s.merge({
          order: action.data,
          loading: false,
          updateNeed: true
        });
      case 'ORDER_UPDATE':
        return s.merge({
          updateNeed: true,
          loading: false
        });
      case 'ORDER_DELETE':
        return s.merge({
          loading: false,
          updateNeed: true
        });
      case 'ORDER_CLEAR':
        return s.merge({
          inserted: false
        });
      case 'ORDER_LOADED':
        return s.merge({
          order: action.data,
          updateNeed: false,
          loading: false,
          fetched: true
        });

      case 'ORDER_INSERTED':
        return s.merge({
          message: action.data,
          updateNeed: false,
          loading: false,
          fetched: true,
          inserted: true
        });

      default:
        return null;
    }
  }, action.type);
});

export const accessOrderState = () => state;

export const useOrderState = () => useState(state);
//Service
export const OrderService = {
  FetchOrderForUser: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/orders/getBrandOrders/${id}`)
      .then((res) => {
        dispatch(OrderAction.loadUserOrders(res.data.result));
      })
      .catch((err) => {});
  },
  FetchOrders: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/orders`)
      .then((res) => {
        dispatch(OrderAction.loadUserOrders(res.data.result));
      })
      .catch((err) => {});
  },
  AddOrder: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/orders/createOrder`, data)
      .then((res) => {
        dispatch(OrderAction.createOrder(res));
        if (res.data.statuscode) {
          dispatch(OrderAction.insertOrder(res.data.message));
        }
      })
      .catch((err) => {});
  },
  ClearOrder: async () => {
    const dispatch = useDispatch();
    dispatch(OrderAction.clearOrder(false));
  },

  UpdateOrder: (id, data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/orders/markShipped/${id}`, data)
      .then((res) => {
        dispatch(OrderAction.updateOrder(res));
      })
      .catch((err) => {});
  },
  DeleteOrder: (id, data) => {
    const dispatch = useDispatch();
    axios
      .delete(`${baseUrl}api/orders/deleteOrder/${id}`, { data: data })
      .then((res) => {
        dispatch(OrderAction.deleteOrder(res));
      })
      .catch((err) => {});
  },
  FetchSingleOrder: (id) => {
    const dispatch = useDispatch();

    axios
      .get(`${baseUrl}api/orders/${id}`)
      .then((res) => {
        dispatch(OrderAction.loadSingleOrder(res.data.result));
      })
      .catch((err) => {});
  }
};

//Action
export const OrderAction = {
  loadUserOrders: (data) => {
    return {
      type: 'ORDERS_LOADED',
      data: data
    };
  },
  loadSingleOrder: (data) => {
    return {
      type: 'ORDER_LOADED',
      data: data
    };
  },
  clearOrder: (data) => {
    return {
      type: 'ORDER_CLEAR',
      fetched: data
    };
  },
  insertOrder: (data) => {
    return {
      type: 'ORDER_INSERTED',
      data: data
    };
  },
  createOrder: (data) => {
    return {
      type: 'CREATE_ORDER',
      data: data
    };
  },
  updateOrder: (data) => {
    return {
      type: 'ORDER_UPDATE',
      data: data
    };
  },
  deleteOrder: (data) => {
    return {
      type: 'ORDER_DELETE',
      data: data
    };
  }
};
