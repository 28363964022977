import React, { useState, useEffect } from 'react';
import { useAuth } from '../../Context/AuthContext';
import { toast, Toaster } from 'react-hot-toast';
import Loader from '../Loader';
import { baseUrl } from '../../baseUrl';
import GlobalDialog from '../../utils/globalDialog';
import axios from 'axios';
import Loader2 from '../Loader2';
import AddSocialModal from './AddSocialModal';
import PlacesAutocomplete from 'react-places-autocomplete';
import { FaTiktok } from 'react-icons/fa';
import { AuthService, userAuthstate } from '../../Service/authenticationService';
import { SelectComponentsConfig } from 'react-select';
import { useUserState, UsersService } from '../../Service/userServices';
import { InstagramService } from '../../Service/InstagramService';
import CustomButton from '../../utils/customButton';

function MailingAddressModal(props) {
  const { currentUser } = useAuth();
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [country, setCountry] = useState('USA'); // Add a country state
  const [success, setSuccess] = useState(false);
  const [fullname, setFullname] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [currentAddress, setCurrentAddress] = useState({
    currentFullName: '',
    currentAddress1: '',
    currentAddress2: '',
    currentCity: '',
    currentState: '',
    currentZip: ''
  });
  const authState = userAuthstate();

  useEffect(() => {
    if (currentUser.uid !== null) {
      AuthService.FetchAuthUser(currentUser.uid);
    }
  }, [authState.updateNeed.value]);

  const user = authState.authUser.value;
  
  useEffect(() => {
    if (user) {
      setCurrentAddress({
        currentFullName: user.shipping_details ? user.shipping_details.fullname : null,
        currentAddress1: user.shipping_details ? user.shipping_details.address1 : null,
        currentAddress2: user.shipping_details ? user.shipping_details.address2 : null,
        currentCity: user.shipping_details ? user.shipping_details.city : null,
        currentState: user.shipping_details ? user.shipping_details.state : null,
        currentZip: user.shipping_details ? user.shipping_details.zipcode : null
      });
    }
  }, [authState.updateNeed.value]);

  function handleChange(address) {
    // HANDLE CHANGE
    setAddress1(address);
  }

  function handlePortfolio() {
    // DO NOTHING
    alert("Creator Portfolio Updated!")
  }

  function handleSelect(address, placeId, suggestion) {
    // HANDLE SELECT
    setAddress1(suggestion.formattedSuggestion.mainText);
    setCity(suggestion.terms[suggestion.terms.length - 3].value);
    setState(suggestion.terms[suggestion.terms.length - 2].value);
  }

  function saveAddress(e) {
    e.preventDefault();
    let mailing_address = {
      fullname: fullname,
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      country: country,
      zipcode: zipcode
    };

    UsersService.AddAddress(currentUser.uid, mailing_address);
    
    setCurrentAddress({
      currentFullName: mailing_address.fullname,
      currentAddress1: mailing_address.address1,
      currentAddress2: mailing_address.address2,
      currentCity: mailing_address.city,
      currentState: mailing_address.state,
      currentZip: mailing_address.zipcode
    });  

    setSuccess(true);

    setTimeout(() => {
      setSuccess(false);
    }, 5000);
  }
  
 const US_STATES = {
  "Alabama": "AL",
  "Alaska": "AK",
  "Arizona": "AZ",
  "Arkansas": "AR",
  "California": "CA",
  "Colorado": "CO",
  "Connecticut": "CT",
  "Delaware": "DE",
  "Florida": "FL",
  "Georgia": "GA",
  "Hawaii": "HI",
  "Idaho": "ID",
  "Illinois": "IL",
  "Indiana": "IN",
  "Iowa": "IA",
  "Kansas": "KS",
  "Kentucky": "KY",
  "Louisiana": "LA",
  "Maine": "ME",
  "Maryland": "MD",
  "Massachusetts": "MA",
  "Michigan": "MI",
  "Minnesota": "MN",
  "Mississippi": "MS",
  "Missouri": "MO",
  "Montana": "MT",
  "Nebraska": "NE",
  "Nevada": "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  "Ohio": "OH",
  "Oklahoma": "OK",
  "Oregon": "OR",
  "Pennsylvania": "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  "Tennessee": "TN",
  "Texas": "TX",
  "Utah": "UT",
  "Vermont": "VT",
  "Virginia": "VA",
  "Washington": "WA",
  "West Virginia": "WV",
  "Wisconsin": "WI",
  "Wyoming": "WY"
};

const CANADIAN_PROVINCES = {
  "Alberta": "AB",
  "British Columbia": "BC",
  "Manitoba": "MB",
  "New Brunswick": "NB",
  "Newfoundland and Labrador": "NL",
  "Nova Scotia": "NS",
  "Ontario": "ON",
  "Prince Edward Island": "PE",
  "Quebec": "QC",
  "Saskatchewan": "SK",
  "Northwest Territories": "NT",
  "Nunavut": "NU",
  "Yukon": "YT"
};

  return (
    <div className=" px-20 py-5">
      <h2 className='text-4xl text-center mb-4'>Update Mailing Address</h2>
      <div className="text-center">
        <h2>Current Mailing Address</h2>
        {user && user.shipping_details ? (
          <p className="mt-6">
            <label className="block font-extrabold">{user.shipping_details.fullname}</label>
            <label className="block font-extrabold">{user.shipping_details.address1}</label>
            <label className="block font-extrabold">{user.shipping_details.address2}</label>
            <label className="block font-extrabold">
              {user.shipping_details.city}, {user.shipping_details.state}, {user.shipping_details.country} {' '}
              {user.shipping_details.zipcode}
            </label>
          </p>
        ) : (
          <div>No address found.</div>
        )}
      </div>
      <form onSubmit={saveAddress}>
        <PlacesAutocomplete
          value={address1}
          onChange={handleChange}
          onSelect={handleSelect}
          className="flex focus:outline-none w-full bg-lightGray p-2 mt-2 rounded">
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: 'Search Places ...',
                  className:
                    'location-search-input focus:outline-none w-full bg-lightGray p-2 mt-2 rounded'
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style
                      })}>
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        
        <input
          className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
          type="text"
          name="name"
          placeholder="Full Name"
          value={fullname}
          onChange={(event) => setFullname(event.target.value)}
          required
        />
        <input
          className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
          type="text"
          name="address1"
          placeholder="Street Address"
          value={address1}
          onChange={(event) => setAddress1(event.target.value)}
          required
        />
        <input
          className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
          type="text"
          name="address2"
          placeholder="Apt Number, Suite, etc."
          value={address2}
          onChange={(event) => setAddress2(event.target.value)}
        />
        <div className="grid grid-cols-3 gap-4">
          <input
            className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded mr-2"
            type="text"
            name="city"
            placeholder="City"
            value={city}
            onChange={(event) => setCity(event.target.value)}
            required
          /> 
          <select
            className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded mr-2"
            name="state"
            value={state}
            onChange={(event) => setState(event.target.value)}
            required
          >
            <option value="">Select a state/province</option>
            {country === 'USA' &&
              Object.entries(US_STATES).map(([stateName, stateCode]) => (
                <option key={stateCode} value={stateCode}>{stateName}</option>
              ))}
            {country === 'CAN' &&
              Object.entries(CANADIAN_PROVINCES).map(([provinceName, provinceCode]) => (
                <option key={provinceCode} value={provinceCode}>{provinceName}</option>
              ))}
          </select>
          <select
            className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded mr-2"
            name="country"
            value={country}
            onChange={(event) => setCountry(event.target.value)}
            required
          >
            <option value="USA">United States</option>
            <option value="CAN">Canada</option>
          </select>
         
          <input
            className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
            type="text"
            name="zipcode"
            placeholder="Zip/postal code"
            value={zipcode}
            onChange={(event) => setZipcode(event.target.value)}
            required
          />
        </div>
        {success ? (
          <button
            className="bg-green text-white px-4 py-2 rounded-md mt-2">
            Updated!
          </button>
        ) : (
          <CustomButton
            className="text-white px-4 py-2 mt-2"
            type='submit'>
            Save
          </CustomButton>
        )}
      </form>
    </div>
  )
}

export default MailingAddressModal;
