import React, { useState } from 'react';
import Modal from 'react-responsive-modal';
import AccountUpdate from './AccountUpdate';
import logo from '../Assets/logo-red.png';

function UpdateProfileModal(props) {
  const [isOpen] = useState(true);
  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => {
          props.closeAction();
        }}
        center
        classNames={{ modal: 'customModal' }}
        closeOnOverlayClick={false}>
        <div>
          <img src={logo} alt="logo" className="mx-auto" />
          <AccountUpdate
            handleComplete={() => {
              props.closeAction();
            }}
          />
        </div>
      </Modal>
    </>
  );
}

export default UpdateProfileModal;
