import NavBar from '../../Components/Navbar';
import Sidebar from '../../Components/Organization_Dashboard/Sidebar';
import React,{useEffect} from 'react';
import { useAuth } from '../../Context/AuthContext';
import { UsersService } from '../../Service/userServices';
import OrganizationNavbar from '../../Components/Organization_Dashboard/OrganizationNavbar';
export default function OrganizationDashboard({ children }) {
  const { currentUser } = useAuth();

return (
  <div>
    <OrganizationNavbar/>
    <div className="md:flex flex-col z-100 md:flex-row  h-47 w-full bg-gray-100">
      <Sidebar />
      {children}
    </div>
  </div>
  );
}
