import React, { useContext, useState } from "react";
import GlobalDialog from "../../utils/globalDialog";
import { toast, Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { BsExclamationCircle } from "react-icons/bs";
import { TaskService, useTaskState } from "../../Service/TaskServices";
import { BrandContext } from "../../routes/PrivateRoute/BrandDashboardRoute";
import CustomButton from "../../utils/customButton";
import {
  useCreditPackState,
  CreditPackService,
} from "../../Service/CreditPackServices";
import Loader from "../Loader";

function UgcApprovalModal(props) {
  const [metaRequested, setMetaRequested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const creditPackState = useCreditPackState();
  const { id } = useParams();
  const taskState = useTaskState();
  const { brandId } = useContext(BrandContext);

  const approveCreator = async () => {
    setIsLoading(true);

    try {
      taskState.merge({ updateNeed: true });
      creditPackState.merge({ updateNeed: true });
      let data = {
        proposal: props.proposal,
        proposal_id: props.proposalKey,
        creator_id: props.proposal.creator_id,
        brand_id: brandId,
        task_id: props.task.id,
      };
      console.log("data: ", data);

      await TaskService.ApproveProposal(props.task.id, data);

      toast.success("Creator Approved Successfully!");
      await TaskService.FetchSingleTask(id);
      props.fetchData();
      taskState.merge({ updateNeed: false });
      CreditPackService.FetchSubscriptionForUser(brandId);
      creditPackState.merge({ updateNeed: false });
      props.close();
    } catch (error) {
      // Handle error
      toast.error(error.response.data.message);
    }

    setIsLoading(false);
  };

  return (
    <div className="bg-white rounded-md px-10 break-words 2xl:text-2xl text-xs h-1/2  md:h-fit flex flex-col items-center justify-center">
      <div className="bg-white rounded-md break-words 2xl:text-2xl text-xs h-fit w-full my-10">
        <div className="p-6 pt-0 text-center">
          <h1 className="2xl:text-3xl text-base mb-2 font-bold">
            Approve Creator
          </h1>
          <BsExclamationCircle className="mx-auto mb-4 w-32 h-14 text-gray-400 dark:text-gray-200" />
          <h3 className="mb-5 w-full 2xl:text-2xl text-base font-normal text-gray-700 dark:text-gray-400">
            Are you sure you want to approve{" "}
            {props.proposal.creator_address.fullname}?
          </h3>
          {props.task &&
            (!props.task.accepted_proposals ||
              new Date(props.task.date_issued) >= new Date("2024-08-20")) && (
              <p className="text-red-500 font-bold text-base mb-10">
                {props.task.task_credits / props.task.product_showcase} credits
                will be deducted once the creator is approved
              </p>
            )}
          {props.task &&
            props.task.add_ons &&
            props.task.add_ons.some(
              (addOn) =>
                addOn.name === "Whitelist" &&
                addOn.platform.includes("instagram"),
            ) && (
              <div>
                <div className="text-base">
                  To add this creator, search for their Instagram handle in Meta
                  Business Suite and send a partnership request:
                  <a
                    href={`https://instagram.com/${props.proposal.creator_socials.instagram.handle}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-Blue ml-1"
                  >
                    @{props.proposal.creator_socials.instagram.handle}
                  </a>
                </div>
                <label className="inline-flex items-center mr-4">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 mb-4 text-gray-600"
                    checked={metaRequested}
                    required
                    onChange={() => setMetaRequested(true)}
                  />
                  <span className="ml-2 mb-4 text-base text-gray-700 mt-2">
                    I have requested this influencer as an Ad Partner through
                    Meta Business Suite:{" "}
                    <a
                      href="https://help.instagram.com/966886840676703"
                      target="_blank"
                      className="text-Blue underline"
                    >
                      More Info
                    </a>
                  </span>
                </label>
              </div>
            )}
          <div className="flex flex-row items-center justify-center gap-10">
            <CustomButton
              type="button"
              className="text-white hover:bg-red-800  font-medium text-sm inline-flex items-center px-5 py-2.5 text-center mr-2 "
              onClick={() => approveCreator()}
              disabled={isLoading}
            >
              {isLoading ? <Loader /> : <span>Approve</span>}
            </CustomButton>
            <button
              type="button"
              className="text-gray-500 bg-white hover:bg-gray-100 border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600"
              onClick={() => props.close()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UgcApprovalModal;
