import React, {useState, useEffect} from 'react';
import 'react-responsive-modal/styles.css';
import { useParams } from 'react-router-dom';
import { TaskService, useTaskState } from '../Service/TaskServices';
import { UsersService, useUserState } from '../Service/userServices';

export default function TaskPageInfo(props) {
 const taskState = useTaskState();
  const {id} = useParams();
  useEffect(() => {
    if (id) {
      TaskService.FetchSingleTask(id);
    }
  }, [taskState.updateNeed.value]); // Make sure 'id' is in the dependency array // Make sur

  const task = taskState.tasks.value;
 console.log("task", task);
  return (  
    <div className="bg-white w-11/12 p-4 rounded-lg z-100 overflow-auto" style={{ height: '90%' }}>
   <div className="flex justify-between items-center mb-2">
    <h2 className="2xl:text-3xl md:2xl:text-base font-semibold ml-10">Brief Details</h2>
  </div>

  <img className='ml-10 w-4rem h-4rem rounded border-2 my-2' src={task.brand_photo} />
   <h1 className='mr-auto ml-10 font-bold 2xl:text-base md:text-0.7 my-2'>{task.name || task.note1}</h1>
  <div className="grid grid-cols-2 gap-4 mb-4 mx-10 h-fit">
    <div className="bg-gray-100 shadow 2xl:p-3 md:p-1 rounded">
      <h3 className="2xl:text-base md:text-0.7 font-semibold mb-1">Compensation</h3>
      <p className='ml-2'>${(task.price / 100).toFixed(2)}</p>
    </div>
    <div className="bg-gray-100 shadow 2xl:p-3 md:p-1 rounded">
      <h3 className="2xl:text-base md:text-0.7 font-semibold mb-2">Add-ons</h3>
      {task && task.add_ons && task.add_ons.length > 0 ? (
        task.add_ons.map((el, i) => (
          <span className='2xl:text-base md:text-0.7' key={i}>
            {i !== 0 && ', '}
            {el.name}
          </span>
        ))
      ) : (
        <span>none</span>
      )}
    </div>
    {task && task.add_ons && task.add_ons.some((addOn) => addOn.name === "Whitelist") && (
      <div className="bg-gray-100 shadow 2xl:p-3 md:p-1 rounded">
        <h3 className="2xl:text-base md:text-0.7 font-semibold mb-2">Whitelisting Platforms</h3>
        {task.add_ons
          .filter((el) => el.name === "Whitelist")
          .map((el, i) => (
            <span key={i}>
              {i !== 0 && ', '}
              {el.platform.map(platform => platform.charAt(0).toUpperCase() + platform.slice(1)).join(', ')}
            </span>
          ))}
      </div>
    )}
    <div className="2xl:text-base md:text-0.7 bg-gray-100 shadow 2xl:p-3 md:p-1 rounded">
      <h3 className="font-semibold mb-2">Products</h3>{task.note1}
    </div>
    <div className="2xl:text-base md:text-0.7 bg-gray-100 shadow 2xl:p-3 md:p-1 rounded">
      <h3 className="font-semibold mb-2">Task Audience</h3>{task.note2}
    </div>
    {task && task.variations && task.variations.length > 0 && (
      <div className="2xl:text-base md:text-0.7 bg-gray-100 shadow 2xl:p-3 md:p-1 rounded">
        <h3 className="font-semibold mb-2">Versions</h3>
        <div className='flex flex-col'>
          {task.variations.map((variation, index) => (
            <div key={variation.id}>
              <p>Version {variation.id + 1}:</p>
              <div className='flex w-full 2xl:text-base md:text-0.7'>
                {variation.voiceover && <div>&nbsp;Voiceover{variation.music || variation.onScreenText || variation.subtitles ? ', ' : ''}</div>}
                {variation.music && <div>&nbsp;Music{variation.onScreenText || variation.subtitles ? ', ' : ''}</div>}
                {variation.onScreenText && <div>&nbsp;On-screen text{variation.subtitles ? ', ' : ''}</div>}
                {variation.subtitles && <div>&nbsp;Subtitles</div>}
              </div>
            </div>
          ))}
        </div>
      </div>
    )}
    {task && task.video_requirements && (
      <div className="2xl:text-base md:text-0.7 bg-gray-100 shadow 2xl:p-3 md:p-1 rounded">
        <h3 className="font-semibold mb-2">Video Requirements</h3>
        <div className="flex flex-row">
        {Object.values(task.video_requirements).every(val => !val) ? (
          <div className="flex flex-row ml-1">
            None
          </div>
        ) : (
          Object.entries(task.video_requirements).map(([key, value], index, array) => (
            value && 
            <div className="flex flex-row ml-1" key={key}>
              {`${key === 'voiceover' ? 'Voiceover' : key === 'music' ? 'Music' : key === 'onScreenText' ? 'On-screen Text' : key === 'subtitles' ? 'Subtitles': null}${index < array.length - 1 ? ', ' : ''}`}
            </div>
          ))
        )}
        </div>
      </div>
    )}
    <div className="2xl:text-base md:text-0.7 bg-gray-100 shadow 2xl:p-3 md:p-1 rounded">
      <h3 className="font-semibold mb-2">Regions</h3>
      <div className='flex flex-row 2xl:text-base md:text-0.'>
     {task && task.regions && task.regions.length > 0 ? (
        <span>{task.regions.map(region => region.label).join(', ')}</span>
      ) : (
        <span>none</span>
      )}
      </div>
    </div>
  </div>
  <div className="2xl:text-base md:text-0.7 bg-gray-100 shadow 2xl:p-3 md:p-1 rounded mx-10">
    <h3 className="font-semibold mb-2">Talking Points</h3>
    <p style={{ overflowWrap: 'break-word', overflowX: 'hidden' }}>{task.note3}</p>
  </div>
  
</div>
  );
}
