import React, { useEffect, useState } from 'react';
import InfluencerTaskCard from './InfluencerTaskCard';
import { baseUrl } from '../../baseUrl';
import { useAuth } from '../../Context/AuthContext';
import { InfluencerTaskService, useInfluencerTaskState } from '../../Service/InfluencerTaskService';
function InfluencerTasks() {
  const [influencerTasks, setInfluencerTasks] = useState([]);
  const { currentUser } = useAuth();
  
  //console.log(currentUser.uid);
  const fetchInfluencerTasks = async () => {
    try {
      const response = await fetch(`${baseUrl}api/influencers/tasks`);
      const data = await response.json(); // Parse the response data using .json()
      setInfluencerTasks(data.result);
    } catch (error) {
      console.error('Error fetching Influencer Tasks:', error);
    }
  };

  useEffect(() => {
    fetchInfluencerTasks();
  }, []);
  
  if (influencerTasks === null || influencerTasks === undefined) {
    return  <div className="bg-white m-4 rounded-lg lg:w-fit xs:h-fit">
    <div style={{ position: 'sticky', top: 0, zIndex: 1 }}>
      <h3 className="font-semibold p-4 md:mb-1 bg-white w-full h-10 2xl:text-2xl md:text-base xs:text-sm">Influencer Tasks</h3>
      <h3 className=' text-gray-500 text-base ml-4 mb-2 '>For these briefs you will be asked not only to generate the video assets, but also post to each of the platforms you've connected in the 'Settings' tab.</h3>
    </div>
    <div className=" md:h-21" style={{ maxHeight: '400px' }}>
      <div className='font-bold text-4xl flex items-center justify-center mt-10'>No influencer briefs available </div>
      </div>
    </div>
      
      
      ;

  }
  // Remove the direct assignment to influencerTasks here
  const influencerTasksArray = Object.entries(influencerTasks).map((e) => ({ id: e[0], ...e[1] }));

  return (
    <div className="overflow-y-scroll bg-white m-4 rounded-lg lg:w-full xs:h-fit ">
    <div className='sticky' >
      <h3 className="font-semibold p-4 md:mb-1 bg-white w-full h-10 md:text-3xl xs:text-sm">Influencer Briefs</h3>
      <h3 className=' text-gray-500 2xl:text-base text-xs ml-4 mb-2 '>For these briefs you will be asked not only to generate the video assets, but also post to each of the platforms you've connected in the 'Settings' tab.</h3>
    </div>
    <div className="overflow-y-scroll md:h-21" style={{ maxHeight: '400px' }}>
      {influencerTasksArray.length > 0 && (
        <div className="grid lg:grid-cols-5 grid-cols-1 md:grid-cols-2 gap-2 mx-2">
          {influencerTasksArray
          .slice(0)
          .reverse()
          .filter(item => {
            const hasNoProposalFromCurrentUser = !item.proposals || !Object.values(item.proposals).some(proposal => proposal.creator_id === currentUser.uid);

            return (
              item.status &&
              item.status !== 'approved' &&
              item.status !== 'accepted' &&
              item.status !== 'revisions' &&
              item.status !== 'drafted' &&
              item.status !== 'completed' &&
              hasNoProposalFromCurrentUser
            );
          })
          .map((item, i) => {
            const id = item.id; // Extract id from the key
            return (
              <div key={i}>
                <InfluencerTaskCard task={item} id={id} fetchInfluencerTasks={fetchInfluencerTasks}/>
              </div>
            );
          })}
        </div>
      )}
    </div>
    </div>
  );
}

export default InfluencerTasks;