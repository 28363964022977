import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import '../../../App.css';
import Loader from '../../Loader';
import { UsersService, useUserState } from '../../../Service/userServices';
import CustomButton from "../../../utils/customButton";



const UserSocialForm = (props) => {
  const { userInfo, userID } = props;
  const [socialArr, setSocialArr] = useState([]);
  const [addSoc, setAddSoc] = useState(false);
  const socialLink = useRef('');
  const socialName = useRef('');
  const [errorMessage, setErrorMessage] = useState('');
  const userState = useUserState();


  const handlePortfolioSubmit = () => {
    userState.merge({ loading: true });
    let socialObj = socialArr.reduce((acc, curr)=>{
      acc[curr.socialName] = curr.socialLink;
      return acc;
    },{})
    console.log("SocialObj")
    console.log(socialObj)

    let data = {
      socials: socialObj
    };
    UsersService.AddCreatorPortfolio(userID, data);
    
  };

  const handleSocialSubmit = (e) => {
    e.preventDefault();
    const socialLinkValue = socialLink.current.value.trim();
    const socialNameValue = socialName.current.value.trim();
  
    // Regular expression pattern to validate social media handles
    const handlePattern = /^(?!www\.[a-zA-Z0-9_-]+\.(?:com|edu|net)$)[a-zA-Z0-9_.]+$/
  
    // Tests for the input being a valid social media handle
    if (handlePattern.test(socialLinkValue)) {
      const formValues = {
        socialLink: socialLinkValue,
        socialName: socialNameValue
      };
      setSocialArr((prevArr) => [...prevArr, formValues]);
      setAddSoc(false);
      setErrorMessage('');
    } else {
      setErrorMessage('Please enter a valid social media handle.');
    }
  };

  const [user, setUser] = useState({
    name: '',
    username: '',
    email: '',
    bio: '',
    avatar: '',
    creator_socials: {
        instagram: {
          handle: '',
        },
        tiktok: {
          handle: '',
        },
        youtube: {
          handle: '',
        },
    },
    shipping_details: {
      address1: '',
      address2: '',
      city: '',
      country: '',
      fullname: '',
      state:'',
      zipcode:'',
    }
  });
  
  useEffect(() => {
    setUser({
      name: userInfo.name || userInfo.profile_name,
      username: userInfo.username,
      email: userInfo.email,
      bio: userInfo.bio,
      avatar: userInfo.avatar,
      creator_socials: {
          instagram: {
            handle: userInfo && userInfo.creator_socials && userInfo.creator_socials && userInfo.creator_socials.instagram
          },
          tiktok: {
            handle:userInfo && userInfo.creator_socials && userInfo.creator_socials && userInfo.creator_socials.tiktok
          },
        },
      shipping_details:{
        address1: userInfo && userInfo.shipping_details && userInfo.shipping_details.address1,
        address2: userInfo && userInfo.shipping_details && userInfo.shipping_details.address2,
        city: userInfo && userInfo.shipping_details && userInfo.shipping_details.city,
        country: userInfo && userInfo.shipping_details && userInfo.shipping_details.country,
        fullname: userInfo && userInfo.shipping_details && userInfo.shipping_details.fullname,
        state: userInfo && userInfo.shipping_details && userInfo.shipping_details.state,
        zipcode: userInfo && userInfo.shipping_details && userInfo.shipping_details.zipcode
      }
    });
  }, [userInfo]);

  const handleAddSocial = () => {
    let add = addSoc;
    setAddSoc(!add);
  };

  return(
    <div>
      {/* EDIT USER SOCIALS */}
      <div className="bg-white my-2 rounded-lg ">
        <div className="mb-2 text-3xl">
          <div className="mb-2 text-center text-3xl ">
            <p>Edit User's social handles here</p>
          </div>

          <div className='text-center my-2 font-bold'> Current Socials </div>
          <div className='flex justify-center'>
            {user && user.creator_socials && (
              <>
                {user.creator_socials.instagram && (
                  <a href={`https://www.instagram.com/${user.creator_socials.instagram.handle}`} target="_blank" rel="noopener noreferrer">
                    <div className='instagram w-12 mx-2 h-12 rounded-lg'></div>
                  </a>
                )}
                {user.creator_socials.tiktok && (
                  <a href={`https://www.tiktok.com/@${user.creator_socials.tiktok.handle}`} target="_blank" rel="noopener noreferrer">
                    <div className='tiktok w-12 mx-2 h-12 rounded-lg'></div>
                  </a>
                )}
                {user.creator_socials.youtube && (
                  <a href={`https://www.youtube.com/${user.creator_socials.youtube.handle}`} target="_blank" rel="noopener noreferrer">
                    <div className='youtube w-12 mx-2 h-12 rounded-lg'></div>
                  </a>
                )}
              </>
            )}
          </div>

          <div className="w-3px h-auto mx-auto mt-8">
            <h1 className="my-2 text-left">Social Media</h1>
            <p className='mb-2 italic text-xs text-left text-darkGray'><strong>NOTE:</strong> Please only enter social media handles, NOT the copy & pasted link. </p>
            <div className="flex">
              {socialArr.length > 0 &&
                socialArr.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className={`${item.socialName}  w-12 mx-2 h-12 rounded-lg`}></div>
                  );
                })}

              <div
                className="addSocial cursor-pointer  w-12 mx-2 h-12 rounded-lg"
                onClick={() => handleAddSocial()}></div>
            </div>
          </div>
          {addSoc && (
            <form onSubmit={handleSocialSubmit} className="p-4 pl-8">
              <div className="w-3px mx-auto">
                <select
                  name="socialName"
                  ref={socialName}
                  required
                  className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded">
                  <option className="text-sm text-indigo-800" defaultValue disabled>
                    Select Social Media
                  </option>
                  <option className="text-sm text-indigo-800" value="instagram">Instagram</option>
                  <option className="text-sm text-indigo-800" value="tiktok">TikTok</option>
                  <option className="text-sm text-indigo-800" value="youtube">YouTube</option>
                </select>
              </div>

              <div className="w-3px mx-auto mt-8">
                <label className="text-darkBlack flex font-bold capitalize">Username</label>
                <input
                  className="focus:outline-none w-full bg-lightGray p-2 mt-2 rounded"
                  type="text"
                  name="socialLink"
                  placeholder="Enter profile handle"
                  ref={socialLink}
                  required
                />
              </div>
              {/* Error message */}
              {errorMessage && (
                <p className="text-red text-sm my-6 md:ml-24">{errorMessage}</p>
              )}

              <div className="w-3px mx-auto mt-2">
                <button type="submit" className="bg-darkGray rounded-md px-4 text-white">
                  Add Platform
                </button>
              </div>
            </form>
          )}
            <div className="w-1/7 h-auto mx-auto mt-8">
              <CustomButton
                className=" text-white text w-1/7 px-12 mx-auto py-2 "
                onClick={() => handlePortfolioSubmit()}>
                {userState.loading.value && <Loader />}
                Build Profile
              </CustomButton>
            </div>
        </div>
      </div>
    </div>
  )
}


export default UserSocialForm;