import React from 'react';
import { BiBlock } from 'react-icons/bi';
import { Link } from 'react-router-dom';
function NotFound() {
  return (
    <div className="h-screen flex  justify-center items-center">
      <div>
        <BiBlock className="w-full block text-5xl" />
        <h1 className="text-xl font-extrabold text-center">Oops! Looks like there has been a mistake</h1>
        <p className="text-center">
          <Link to="/" className="bg-Blue  w-fit px-4 rounded-lg text-white mt-4 items-center justify-center text-center cursor-pointer">
            Back
          </Link>
        </p>
        <div className='mt-4'>
          If you are having trouble with our app, please contact us at team@usecrafted.com
        </div>
      </div>
    </div>
  );
}

export default NotFound;
