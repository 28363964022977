import React, {useState, useEffect, useContext} from 'react'
import {toast, Toaster} from 'react-hot-toast';
import { BrandContext } from '../../routes/PrivateRoute/BrandDashboardRoute';
import { UsersService } from '../../Service/userServices';
import { TaskService } from '../../Service/TaskServices';
import CheckmarkIcon from '../../Assets/Eo_circle_green_checkmark.png'
import CustomButton from '../../utils/customButton';

function StudioOutReach( props) {
  const [loading, setLoading] = useState(false);
  const [numberOfVideos, setNumberOfVideos] = useState({});
  const { brandId } = useContext(BrandContext);
  const [message, onMessageChange] = useState('');
  const [influencerSize, setInfluencerSize] = useState({
    nano: false,
    micro: false,
    macro: false
  }); 

  const [campaign, setCampaign] = useState({ email_body: '' });


  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setInfluencerSize(prevState => ({
      ...prevState,
      [name]: checked
    }));
    console.log('Checkbox clicked:', name, checked); // Add this line for debugging
  }
  
  
  const calculateRange = (influencerSize) => {
    if (influencerSize.nano && influencerSize.micro && influencerSize.macro) {
      return [100, 1000000];
    } else if (influencerSize.micro && influencerSize.macro) {
      return [10000, 1000000];
    } else if (influencerSize.nano && influencerSize.micro) {
      return [100, 50000];
    } else if (influencerSize.nano && influencerSize.macro) {
      return [100, 1000000];
    } else if (influencerSize.nano) {
      return [100, 10000];
    } else if (influencerSize.micro) {
      return [10000, 50000];
    } else if (influencerSize.macro) {
      return [20000000, 20000000];
    } else {
      return "No range selected";
    }
  }

  const followerCountRange = calculateRange(influencerSize);


  const handleContinue = (e) => {
    e.preventDefault();
    const data = {  
      follower_count: followerCountRange,
      platform: ["tiktok", "instagram"],
      states: props.taskData.regions.map(region => region.value),
      brand_id: brandId,
      email_body: message,
      message: message,
      task: props.taskData
    };
  
    // Show the toast message immediately
    toast.success("Email is being sent to creators");

    // Fire the POST request without waiting for the response
    fetch('https://us-central1-crafted-dev-v1.cloudfunctions.net/studioOutreach', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).catch(error => {
      // Handle any errors here, perhaps with a toast.error
      toast.error("An error occurred while sending the blast");
    });

    // Close the modal after 2 seconds
    setTimeout(() => {
      props.closeAction();
    }, 2000);
};


  function validateCheckboxes(event) {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    const checked = Array.prototype.slice.call(checkboxes).some(checkbox => checkbox.checked);
    if (!checked) {
      event.preventDefault(); // Prevent form submission
      alert('At least one influencer size needs to be selected to proceed.'); // Show error message
    }
  }
  
  return (
      <div className="p-4 max-w-full h-full md:max-w-2xl mx-auto text-center"> 
        <Toaster />
        <h1 className="2xl:text-2xl md:text-base font-semibold text-center text-gray-800 mb-4">Creator Outreach</h1>
        <hr />
        <form onSubmit={handleContinue}>
          <img src={CheckmarkIcon} className='2xl:w-16rem 2xl:h-16rem md:w-10rem md:h-10rem my-10 mx-auto' alt="Checkmark Icon" />
          <h1 className="2xl:text-2xl md:text-base leading-6 font-semibold text-gray-900">
          Your task has been created! Would you like to send a blast to relevant Crafted creators inviting them to apply to your task?
          </h1>  
          <div className="flex justify-center mt-4 space-x-4"> 
          
            <CustomButton 
              type="submit"
              className=" text-white 2xl:text-base md:text-0.7 font-bold text-xl py-3 px-4 outline-none focus:outline-none"
            >
              Send blast
            </CustomButton>  
            <button
              type="button" 
              className=" text-gray-500 2xl:text-base md:text-0.7 hover:underline font-bold text-xl py-3 px-4 outline-none focus:outline-none"
              onClick={props.closeAction} 
            >
              No, thanks
            </button>
          </div>
        </form>
      </div>
  )
}

export default StudioOutReach