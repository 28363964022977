import React, { useEffect, useState } from 'react';
import { AuthService, userAuthstate } from '../../../Service/authenticationService';
import { useAuth } from '../../../Context/AuthContext';
import { CampaignState } from '../../../utils/campaignState';
import CustomButton from '../../../utils/customButton';

function CampaignTaskCard(props) {
const { currentUser } = useAuth();
const authState = userAuthstate();
const [currentCampaign, setCurrentCampaign] = useState('');
const campaignState = CampaignState();



console.log(currentCampaign);

const editTask = () => {
props.setAddCampaign(true);
campaignState.merge({ editTask: props.campaign });
}

const deleteTask = () => {
campaignState.merge({ editTask: null });
}

return (
<>
	<div
			className="bg-lightGray items-center rounded-xl p-1 flex flex-col cursor-pointer"
	>
			<div>
					<img
							src={props.campaign.photo}
							style={{ height: '200px' }}
							className="rounded-lg w-full h-full object-cover"
							alt="img"
					/>
					<div className="text-base my-2  text-red flex items-center mx-auto justify-center font-extrabold md:w-10rem overflow-hidden text-center ">
							{props.campaign.name}
							{/* {props.campaign.campaign_credits && props.campaign.campaign_credits + ' credits'} */}
					</div>
					<div className="text-base mb-1 font-bold text-center"></div>
			</div>

			<p className="text-black w-full  font-extrabold text-center">
					{props.campaign.number_of_videos}   {props.campaign.add_ons.map((el, i) => (<span> {i !== 0 && '+'} {el.name}</span>))}
			</p>
			<div className="items-center justify-center text-center">
					<CustomButton
								onClick={() => editTask()}
							className="bg-red text-white px-2 mr-3 rounded-lg outline-none text-xs">
							Edit Brief
					</CustomButton>
					{/*<button
								onClick={() => deleteTask()}
							className="bg-red text-white px-2 mr-3 rounded-lg outline-none text-xs">
							Delete Task
					</button>*/}
			</div>
	</div >
</>
);
}

export default CampaignTaskCard;
