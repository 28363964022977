import React, { useEffect, useState } from 'react';
import { TaskService, useTaskState } from '../../Service/TaskServices';
import Calendar from './Calendar';
import AmplifyBriefPreview from './AmplifyBriefPreview';
import StudioBriefPreview from './StudioBriefPreview';
import { useAuth } from '../../Context/AuthContext';
import { baseUrl } from '../../baseUrl';
import RecommendedTasks from './RecommendedTasks';
import Loader from '../Loader2';
import Tour from 'reactour';
import { UsersService, useUserState } from '../../Service/userServices';

function CreatorHome() {
  const taskState = useTaskState();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [influencerTasks, setInfluencerTasks] = useState([]);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const userState = useUserState();

  useEffect(() => {
    UsersService.FetchBasicInfo(currentUser.uid);
  }, [currentUser.uid]);

  const steps = [
    {
      selector: '.shadow-md',
      content: 'This is your first step.',
    },
    // Add as many steps as you want
  ];

  const fetchInfluencerTasks = async () => {
    try {
      const response = await fetch(`${baseUrl}api/influencers/${currentUser.uid}/tasks`);
      const data = await response.json();
      setInfluencerTasks(data.result);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching Influencer Tasks:', error);
    }
  };

  useEffect(() => {
    const fetchTasks = async () => {
      await fetchInfluencerTasks();
      await TaskService.FetchTaskForUser(currentUser.uid);
      setLoading(false);
    };

    fetchTasks();
  }, [taskState.updateNeed.value]);

  // Check if the tour has already been completed
  useEffect(() => {
    const tourCompleted = localStorage.getItem('tourCompleted');
    if (!tourCompleted) {
      setIsTourOpen(true);
    }
  }, []);

  const handleTourClose = () => {
    setIsTourOpen(false);
    localStorage.setItem('tourCompleted', 'true'); // Mark the tour as completed
  };

  const resetTour = () => {
    localStorage.setItem('tourCompleted', 'false');
  };
  const resUser = taskState.userTasks.value;
  const task_user = resUser
    ? Object.entries(resUser).map((e) => ({
        [e[0]]: e[1]
      }))
    : [];

  return (
    loading ? (
      <div className='bg-white md:m-4 xs:m-0 rounded-lg w-full lg:w-full xs:h-fit overflow-y-scroll flex items-center justify-center'><Loader/></div>
    ) : (
      <div className="bg-white md:m-4 xs:m-0 rounded-lg w-full lg:w-full xs:h-fit overflow-y-scroll"
        style={{ height: 'calc(100vh - 5rem)' }}>
        <div className='bg-white h-full'>
          <div className="grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 h-full">
            <div id="calendar" className="shadow-md flex items-center border-2 rounded-md w-full justify-center" style={{height: '42vh'}}>
              <Calendar influencerTasks={influencerTasks} tasks={task_user} />
            </div>
            <div id="amplify" className="shadow-md flex items-center border-2 rounded-md w-full justify-center" style={{height: '42vh'}}>
              <AmplifyBriefPreview influencerTasks={influencerTasks} />
            </div>
            <div id="studio" className="shadow-md flex items-center border-2 rounded-md w-full justify-center" style={{height: '42vh'}}>
              <StudioBriefPreview tasks={task_user} />
            </div>
            <div id="recommended" className="shadow-md flex items-center border-2 rounded-md w-full justify-center" style={{height: '42vh'}}>
              <RecommendedTasks user={userState.user.value} />
            </div>
          </div>
        </div>
        <Tour
            isOpen={isTourOpen}
            steps={[
              {
                selector: '',
                content: 'Welcome to your personalized dashboard! This is your central hub for managing and viewing all your briefs.',
              },
              {
                selector: '#calendar',
                content: 'Meet your calendar! It provides a comprehensive view of your briefs along with their respective due dates.',
              },
              {
                selector: '#amplify',
                content: 'Here you can see a snapshot of your ongoing influencer and User-Generated Content (UGC) briefs. Click "View More" to explore all your influencer tasks in the "Manage Briefs" section.',
              },
              {
                selector: '#studio',
                content: 'This is another glimpse of your ongoing influencer and UGC briefs. Remember, you can always click "View More" to see all your briefs in the "Manage Briefs" section.',
              },
              {
                selector: '#recommended',
                content: 'This section showcases briefs that are highly suited to your profile and you are most likely to be approved for.',
              },
              {
                selector: '',
                content: 'That concludes our tour! Feel free to explore and get familiar with the platform. If you have any questions or need further assistance, don’t hesitate to reach out. Happy browsing!',
              },
            ]}
            onRequestClose={handleTourClose}
          />
      </div>
    )
  );
}

export default CreatorHome;
