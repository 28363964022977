import React from 'react';

function CustomPagination({ currentPage, totalPages, onPageChange }) {
  const handlePageChange = (newPage) => {
    onPageChange(newPage);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, startPage + 4);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <div className=" bottom-0 left-0 right-0 -mb-4 flex justify-center items-center py-4">
      <nav className="relative z-0 inline-flex rounded-md shadow-sm">
        {currentPage !== 1 && (
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-red"
          >
            Previous
          </button>
        )}

        <div className="flex">
          {renderPageNumbers().map((page) => (
            <button
              key={page}
              onClick={() => handlePageChange(page)}
              className={`-ml-px relative inline-flex items-center px-4 py-2 border ${
                currentPage === page
                  ? 'border-black bg-red text-white'
                  : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50'
              } text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-black`}
            >
              {page}
            </button>
          ))}
        </div>

        {currentPage !== totalPages && (
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-red"
          >
            Next
          </button>
        )}
      </nav>
      <div className="ml-3 text-gray-700 text-sm font-medium">
        Page {currentPage} of {totalPages}
      </div>
    </div>
  );
}

export default CustomPagination;
