import React from 'react';
import { CampaignState } from '../../../../utils/campaignState';
import CampaignSubTab from '../../../../common/steps/campaign/campaignSubTab';
import { useTabState } from '../../../../utils/TabState';
import 'react-responsive-modal/styles.css';
import { Link } from 'react-router-dom';
export default function CompaignDetails(props) {
  const campaignState = CampaignState();
  const tabState = useTabState();

  const res = campaignState.campaignData.value;
  console.log(res);
  const campaign = res
    ? Object.entries(res).map((e) => ({
        [e[0]]: e[1]
      }))
    : [];
  const tasks = campaign[1]
    ? Object.entries(res).map((e) => ({
        [e[0]]: e[1]
      }))
    : [];
  console.log('cmpaign:', campaign);
  return (
    <div>
      <h1 className="text-4xl text-center font-bold">Campign Brief content</h1>
      <hr className="mb-4" />
    </div>
  );
}
