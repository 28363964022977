import React from 'react';
import Modal from '../../utils/brandModal';
import { BsChevronCompactDown, BsChevronCompactUp } from 'react-icons/bs';
import Collapsible from 'react-collapsible';
import { TaskService } from '../../Service/TaskServices';
import moment from 'moment';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

function ProposalModal({ open, onClose, id, task }) {
  const timezone = moment.tz.guess();
  var task_deadline_time = moment(task.deadline).tz(timezone).format('MMM Do YYYY');
  let proposals = [];
  if (task) {
    proposals = task.proposals
      ? Object.entries(task.proposals).map((e) => ({
        [e[0]]: e[1]
      }))
      : [];
  }

  const covertingString = () => {
    const regex = /(https?|ftp):\/\/[^\s/$.?#].[^\s]*/i;
    if (task && task.note3) {
      if (regex.test(task.note3)) {
        let extractedUrl = task.note3.match(/\bhttps?:\/\/\S+/gi);
        let visualLink = StringChecker(extractedUrl);
        const { beforeURL, afterURL } = extractBeforeAndAfterURL(task.note3);
        return (
          <div className='break-words max-w-full'>
            {beforeURL}
            <p className="max-w-full text-blue-500" >
              <a className='text-blue-500 hover:underline cursor-pointer' href={task.note3.match(/\bhttps?:\/\/\S+/gi)[0]} target="_blank" style={{ color: 'blue' }}>{visualLink}</a>
            </p>
            {afterURL}
          </div>
        )
      }
      else {
        return (
          <div>
            {task.note3}
          </div>
        )
      }
    }
  }



  function extractBeforeAndAfterURL(str) {
    const regex = /(.*?)(https?:\/\/[^\s]+)/;
    const match = str.match(regex);

    if (match && match.length >= 3) {
      const beforeURL = match[1];
      const afterURL = str.substring(match[1].length + match[2].length);
      return { beforeURL, afterURL };
    }

    return { beforeURL: str, afterURL: '' };
  }

  const StringChecker = (text) => {
    let formattedText = text;
    if (formattedText[0].length > 50) {
      formattedText[0] = formattedText[0].substring(0, 50) + '...';
    }
    return (formattedText[0]);
  };

  // w-full min-w-1/3 h-1/2
  const approveProposal = (res) => {
    let approval = {
      creator_id: res.creator_id,
      creator_name: res.creator_name,
      creator_username: res.creator_username,
      accepted_proposals: res.proposal_details,
      products: res.products ? res.products : '',
      brand_id: task.brand_id,
      shippingDetails: res.creator_address,
      task_id: id[0],
      task_type: 'video'
    };

    TaskService.ApproveProposal(id[0], approval);
    toast.success("Creator Approved! Click 'Send Samples' to gift product to the creator.");
    onClose();
  };

  return (
    <div>
      <Modal className="px-3 w-max" open={open} onClose={onClose}>
        <Toaster />
        <Collapsible
          trigger={[
            'Brief Details',
            <div className="px-3 absolute right-0">
              <BsChevronCompactDown />
            </div>
          ]}
          triggerWhenOpen={[
            'Brief Details',
            <div className="px-3 absolute right-0">
              <BsChevronCompactUp />
            </div>
          ]}
          contentOuterClassName="mb-6"
          triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
          triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
          <div className="m-3 bg-white rounded-lg text-3xl dark:bg-gray-700">
            <div className="flex mb-5">
              <div className="flex mr-5 font-bold">Featured Products:</div>
              <div>{task && task.note1}</div>
            </div>
            <div className="flex mb-5">
              <div className="flex mr-5 font-bold">Target Demo:</div>
              <div>{task && task.note2}</div>
            </div>
            <div className="flex mb-5">
              <div className="flex mr-5 font-bold">Talking Points:</div>
              {covertingString()}
            </div>
            <div className="flex mb-5">
              <div className="flex mr-2 font-bold">Task Credits:</div>
              <div>{task && task.task_credits}</div>
            </div>
            <div className="flex mb-5">
              <div className="flex mr-2 font-bold">Recipe Videos:</div>
              <div>{task && task.recipe_videos} | </div>
              <div className="flex mr-2 font-bold">Videos:</div>
              <div>{task && task.product_showcase}</div>
            </div>

            {/*<div className="flex mb-5">
              <div className="flex mr-5 font-bold">Estimated Deadline:</div>
              <div>{task && task_deadline_time}</div>
        </div>*/}
          </div>
        </Collapsible>
        <Collapsible
          open
          trigger={[
            'Creator Proposals',
            <div className="px-3 absolute right-0">
              <BsChevronCompactDown />
            </div>
          ]}
          triggerWhenOpen={[
            'Creator Proposals',
            <div className="px-3 absolute right-0">
              <BsChevronCompactUp />
            </div>
          ]}
          contentOuterClassName="mb-6"
          triggerClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative"
          triggerOpenedClassName="flex items-center justify-center rounded flex-1 p-3 text-white bg-darkBlack relative">
          <div>
            <div className="flex mb-5">
              <div>
                {proposals.length > 0 ? (
                  <>
                    {proposals
                      .slice(0)
                      .reverse()
                      .map((item, i) => {
                        const res = item[Object.keys(item)];
                        return (
                          <>
                            <div className="max-w-full" key={i}>
                              <div className="flex justify-between">
                                <div className="font-bold">{res.creator_name}'s Proposal:</div>
                                <Link
                                  className="bg-darkGray text-white px-2 py-1 m-1 rounded-lg outline-none text-xs"
                                  to={`/profile/${res.creator_id}`}
                                  target="_blank">
                                  View Creator
                                </Link>
                              </div>
                              {res.proposal_details &&
                                Object.keys(res.proposal_details).map((prop, i) => {
                                  return (
                                    <>
                                      <div className="m-1">
                                        Video {i + 1}: {res.proposal_details[prop]}
                                      </div>
                                      <button
                                        className="bg-red text-white px-1 py-4 m-2 rounded-lg outline-none text-base"
                                        onClick={() => approveProposal(res)}>
                                        Approve
                                      </button>
                                    </>
                                  );
                                })}
                              {/*<div className="m-1">Video 1: {res.proposal_details.proposal1}</div>
                                <div className="m-1">Video 2: {res.proposal_details.proposal2}</div>*/}

                              <hr className="mb-4" />
                            </div>
                          </>
                        );
                      })}
                  </>
                ) : (
                  <div className="w-full flex justify-center">
                    <div className="my-5 text-center font-bold text-sm text-red">
                      Once creators have applied to this brief, their proposals can be reviewed and
                      approved here.
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Collapsible>
      </Modal>
    </div>
  );
}

export default ProposalModal;
