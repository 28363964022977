import React from 'react';
import ReactPlayer from 'react-player';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import asmr from '../../Assets/ASMR.mp4';
import story from '../../Assets/Storytelling.mp4';
import review from '../../Assets/Review.mp4';
import cooking_show from '../../Assets/CookingShow.mp4';
import aesthetic from '../../Assets/HighlyAesthetic.mp4';
import aspirational from '../../Assets/Aspirational.mp4';
import overhead_recipe from '../../Assets/OverheadRecipe.mp4';
function VideoCarousel(props) {

    let examples = {
        asmr: asmr,
        story: story,
        review: review,
        cooking_show: cooking_show,
        aspirational: aspirational,
        overhead_recipe: overhead_recipe,
        aesthetic: aesthetic
    }

    let examples_captions = {
        asmr: "No face, overhead angle, no voiceover, sound super prominent audio, aesthetic",
        story: "Face, 'that time I [hosted a dinner party]...', cuts with multiple settings (kitchen, eating), best if you show the context ",
        review: "Face, often involves trying multiple flavors, providing honest feedback on flavors",
        cooking_show: "Face, commentary, along with recipe & aesthetic top down shots",
        aspirational: "Like story, but really puts the product in context (think a nice bottle of wine at a candle lit dinner)",
        overhead_recipe: "No face, overhead angle, cutting board, often a pretty involved recipe, instructional voiceover",
        aesthetic: "No face, same instrutional voiceover, front on angle, modern & clean aesthetic"
    }


    return (
        <div>
            <Carousel
                className='p-3'
                showThumbs={true}
                autoPlay={true}
                showStatus={false}
                swipeable={true}
                stopOnHover={true}
                interval={3000}
                showArrows={true}
                infiniteLoop={true}

            >
                <div>
                    <h3 className='font-bold text-lg'>ASMR</h3>
                    <p className='mb-3'>{examples_captions.asmr}</p>
                    <div className="flex mb-2 mx-auto items-center justify-center text-center">
                        <video className="w-auto h-32 rounded-lg" controls src={examples.asmr} />
                    </div>
                </div>
                <div>
                    <h3 className='font-bold text-lg'>Story</h3>
                    <p className='mb-3'>{examples_captions.story}</p>
                    <div className="flex mx-auto items-center justify-center text-center">
                        <video className="w-auto h-32 rounded-lg" controls src={examples.story} />
                    </div>
                </div>
                <div>
                    <h3 className='font-bold text-lg'>Review</h3>
                    <p className='mb-3'>{examples_captions.review}</p>
                    <div className="flex  mx-auto items-center justify-center text-center">
                        <video className="w-auto h-32 rounded-lg" controls src={examples.review} />
                    </div>
                </div>
                <div>
                    <h3 className='font-bold text-lg'>Cooking Show</h3>
                    <p className='mb-3'>{examples_captions.cooking_show}</p>
                    <div className="flex  mx-auto items-center justify-center text-center">
                        <video className="w-auto h-32 rounded-lg" controls src={examples.cooking_show} />
                    </div>
                </div>
                <div>
                    <h3 className='font-bold text-lg'>Experience</h3>
                    <p className='mb-3'> {examples_captions.aspirational}</p>
                    <div className=" flex mx-auto items-center justify-center text-center">
                    <video className="w-auto h-32 rounded-lg" controls src={examples.aspirational} />
                    </div>
                </div>
                <div>
                    <h3 className='font-bold text-lg'>How-To</h3>
                    <p className='mb-3'>{examples_captions.overhead_recipe}</p>
                    <div className=" flex mx-auto items-center justify-center text-center">
                    <video className="w-auto h-32  rounded-lg" controls src={examples.overhead_recipe} />
                    </div>
                </div>
                <div>
                    <h3 className='font-bold text-lg'>Aesthetic</h3>
                    <p className='mb-3'>{examples_captions.aesthetic}</p>
                    <div className="flex  mx-auto items-center justify-center text-center">
                        <video className="w-auto h-32 rounded-lg" controls src={examples.aesthetic} />
                    </div>
                </div>
            </Carousel>
        </div>
    );
}

export default VideoCarousel;