import React, { useState, useEffect, useContext } from 'react';
import { BsSearch } from 'react-icons/bs';
import { BsFillPersonPlusFill } from 'react-icons/bs';
import UserFormModal from '../Admin/Users/UserFormModal';
import UpdateModal from '../Admin/Users/UserUpdateModal';
import { UsersService, useUserState } from '../../Service/userServices';
import { GrFormPrevious } from 'react-icons/gr';
import { MdOutlineNavigateNext } from 'react-icons/md';
import UsersDeleteModal from '../Admin/Users/UsersDeleteModal';
import { useRef } from 'react';
import UpdateUserForm from '../Admin/Users/UserUpdateModal';
import { OrganizationService, useOrganizationState } from '../../Service/OrganizationService';
import { useParams } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import InviteOrganizationModal from './InviteOrganizationModal';
import EditOrgUserModal from './EditOrgUserModal';
import { OrgContext } from '../../routes/PrivateRoute/OrganizationDashboard';
import { useAccountState, AccountService } from '../../Service/AccountServices';
import { useAuth } from '../../Context/AuthContext';
import CustomButton from '../../utils/customButton';

function OrganizationUsers() {
  const [OpenProfileModal, setOpenProfileModal] = useState(false);
  const [OpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [OpenAddUserModal, setOpenAddUserModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const userState = useUserState();
  const [editModal, setEditModal] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const { currentUser } = useAuth();
  const accountState = useAccountState();
  const organizationState = useOrganizationState();
  const [itemsPerPage] = useState(8);
  const [userData, setUserData] = useState('');
  const [orgName, setOrgName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const {id} = useParams();
  const {orgId} = useContext(OrgContext);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [display, setDisplay] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef();
  const [openInviteModal, setOpenInviteModal] = useState(false);

  useEffect(() => {
    console.log(currentUser.uid);
    if (currentUser && currentUser.uid) {
      AccountService.FetchAccountInfo(currentUser.uid);
    }
  }, [currentUser]);
  const dummyUserData = [
    { email: 'user1@example.com', role: 'Admin' },
    { email: 'user2@example.com', role: 'User' },
    { email: 'user3@example.com', role: 'User' },
    { email: 'user4@example.com', role: 'Admin' },
    { email: 'user5@example.com', role: 'User' },
    { email: 'user6@example.com', role: 'User' },
    { email: 'user7@example.com', role: 'Admin' },
    // Add more dummy user data as needed
  ];
  console.log("ORG ID", id);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          
       
        const orgUsersData = await OrganizationService.GetOrgUsers(id);
        const organizationData = await OrganizationService.GetOrganization(id);
        if (Object.keys(orgUsersData).length > 0) {
          setUserData(orgUsersData);
        }
        if (Object.keys(organizationData).length > 0) {
          setOrgName(organizationState.organization.name.value);
        }
        console.log("ORG STATE", organizationState);
    }  
  } catch (err) {
        console.error('Error fetching organization users:', err);
      }
    } 
    fetchData();
  }, [id, organizationState.updateNeed.value]);


  const organization = organizationState.organization.value;


  let account  = accountState.account.value
  //console.log("role", account && account.organizations && account.organizations[orgId][currentUser.uid].role );
  let role;
  if (account && account.organizations && account.organizations[id] && account.organizations[id].users && account.organizations[id].users[currentUser.uid]) {
    role = account.organizations[id].users[currentUser.uid].role;
  }
  console.log("role:", account && account.organizations && account.organizations[id]);

   {/** const handleSearch = (e) => {
      const value = e.target.value;
      setSearchTerm(value);
    
      const results = arr.filter((user) => {
        const { bio, email, profile_name, username, name, shipping_details, fullname, creator_socials, negotiated }= user;
        const { address1, address2, city, country, state, zipcode } =
          shipping_details || {};
    
        const lowerCaseValue = value.toLowerCase(); // Convert search value to lowercase
    
        // Search by bio, email, profile name, username, name, and shipping details (case-insensitive)
        return (
          (bio && bio.toLowerCase().includes(lowerCaseValue)) ||
          (email && email.toLowerCase().includes(lowerCaseValue)) ||
          (profile_name && profile_name.toLowerCase().includes(lowerCaseValue)) ||
          (username && username.toLowerCase().includes(lowerCaseValue)) ||
          (name && name.toLowerCase().includes(lowerCaseValue)) ||
          (fullname && fullname.toLowerCase().includes(lowerCaseValue)) ||
          (address1 && address1.toLowerCase().includes(lowerCaseValue)) ||
          (address2 && address2.toLowerCase().includes(lowerCaseValue)) ||
          (city && city.toLowerCase().includes(lowerCaseValue)) ||
          (country && country.toLowerCase().includes(lowerCaseValue)) ||
          (state && state.toLowerCase().includes(lowerCaseValue)) ||
          (creator_socials && (
            (creator_socials.tiktok && Object.values(creator_socials.tiktok).some((value) => typeof value === 'string' && value.toLowerCase().includes(lowerCaseValue))) ||
            (creator_socials.instagram && Object.values(creator_socials.instagram).some((value) => typeof value === 'string' && value.toLowerCase().includes(lowerCaseValue))) ||
            (creator_socials.youtube && Object.values(creator_socials.youtube).some((value) => typeof value === 'string' && value.toLowerCase().includes(lowerCaseValue)))
          )) ||
          (zipcode && zipcode.toLowerCase().includes(lowerCaseValue))
        );
      });
    
      setSearchResults(results);
      setcurrentPage(1);
    };
  
  
  const pages = Math.ceil(Userdata.length / itemsPerPage);

  const getPaginatedData = () => {
    const startIndex = currentPage * itemsPerPage - itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return Userdata.slice(startIndex, endIndex);
  };
  
  const data = getPaginatedData();
  
  function goToNextPage() {
    if (data.length < itemsPerPage) {
      return;
    } else {
      setcurrentPage((page) => page + 1);
    }
  }
  
  function goToPreviousPage() {
    if (currentPage === 1) {
      return;
    } else {
      setcurrentPage((page) => page - 1);
    }
  }
 */}

 const handleSearch = (e) => {
  const value = e.target.value;
  setSearchTerm(value);

  const results = userData && userData.filter((user) => {
    return (
      user.email.toLowerCase().includes(value.toLowerCase()) ||
      user.role.toLowerCase().includes(value.toLowerCase())
    );
  });

  setSearchResults(results);
};

  const handleUpdate = async (el, id) => {
    setUserData(el);
    setSelectedId(id);
    setOpenProfileModal(true);
  };

  const handleDelete = (id) => {
    setOpenDeleteModal(true);
    setSelectedId(id);
  };

  const openModal = (data, id) => {
    setUserData(data)
    setSelectedId(id)
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const inviteHandler = () => {
    setOpenInviteModal(true)
  }
  
  const editModalHandler = (user, id) => {
    setEditModal(true)
    setSelectedId(id)
    setSelectedUser(user)
  }

  return (
    <div className='flex flex-col p-4 m-4 w-full bg-white rounded-md'>
      <div className='flex justify-between mb-4 border-b'>
        <h1 className='font-bold m-4 text-3xl'>Team </h1>
        {
          <CustomButton
          onClick = {() => inviteHandler()}
          className="bg-red text-white text-base mb-2 font-bold md:px-4 w-32 md:py-2 rounded-lg mt-2 mr-3"
        >
          Invite
        </CustomButton>
        
        }

      </div>
      <input
        type="text"
        placeholder="Search by email or role"
        onChange={handleSearch}
        className="p-2 mb-4 border border-gray-200 rounded-md"
      />
      <div className="overflow-auto h-full">
        <table className="w-full table-auto">
          <thead className='border-b-2 border-gray-500'>
            <tr className='border-b-2 border-gray-500'>
              <th className="px-10 py-4 text-left text-xs font-bold text-gray-500 uppercase sticky fixed top-0 w-1/2 bg-white">Email</th>
              <th className="px-10 py-4 text-left text-xs font-bold text-gray-500 uppercase sticky fixed top-0 w-1/2 bg-white">Role</th>
            </tr>
          </thead>
          <tbody>
            {searchResults.length > 0 ? (
              searchResults.map((user, index) => (
                <tr key={index} className={index % 2 === 0 ? "bg-gray-200 group hover:bg-gray-300" : "bg-white group hover:bg-gray-100"}>
                <td className="px-10 py-4 whitespace-nowrap">{user.email}</td>
                <td className="px-10 py-4 whitespace-nowrap relative">{user.role}
                  <button className="absolute top-0 right-12 p-4  text-black rounded-full opacity-0 group-hover:opacity-100"><FaEdit className='w-10 h-6'/></button>
                </td>
              </tr>
              ))
            ) : searchTerm !== "" ? (
              <tr>
                <td colSpan="2" className="px-6 py-4 text-center">
                  <div className='h-full mx-auto my-20 flex items-center justify-center'>
                    No members fit your search criteria
                  </div>
                </td>
              </tr>
            ) : (
              userData && Object.keys(userData).map((userId, index) => (
                <tr key={index} className={index % 2 === 0 ? "bg-gray-200 group hover:bg-gray-300" : "bg-white group hover:bg-gray-100"}>
                  <td className="px-10 py-4 whitespace-nowrap">{userData[userId].email}</td>
                  <td className="px-10 py-4 whitespace-nowrap relative">{userData[userId].role}
                   { role === "admin" && <button onClick={() => editModalHandler(userData[userId], userId)} className="absolute top-0 right-12 p-4  text-black rounded-full opacity-0 group-hover:opacity-100">
                      <FaEdit className='w-10 h-6'/>
                    </button>}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
        {
          openInviteModal ?
           <InviteOrganizationModal
            open = {openInviteModal}
            org_name = {organization && organization.name }
            onClose={() => setOpenInviteModal(false)}
           /> : null
        }
        {editModal ?
            <EditOrgUserModal
            open = {editModal}
            onClose = {() => setEditModal(false)}
            user = {selectedUser}
            id = {selectedId}
            /> : null
          }
    </div>
  );
}

export default OrganizationUsers  ;
