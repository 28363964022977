import React, { useState, useRef, useEffect } from 'react';

import 'react-responsive-modal/styles.css';
import { validateFormFields } from '../../../utils/functions';
import Loader from '../../Loader';
import { Modal } from 'react-responsive-modal';
import { useAuth } from '../../../Context/AuthContext';
import { AuthService, userAuthstate } from '../../../Service/authenticationService';
import { useTaskState, TaskService } from '../../../Service/TaskServices';
import { BrandService } from '../../../Service/BrandServices';
import { SubscriptionService, useSubscriptionState } from '../../../Service/SubscriptionServices';
import { Link } from 'react-router-dom';

export default function FirstProduct(props) {
  const { currentUser } = useAuth();
  const taskState = useTaskState();
  const subsState = useSubscriptionState();

  const [recipe, setRecipe] = useState(0);
  const [product, setProduct] = useState(0);
  const [taskCredits, setTaskCredits] = useState(0);

  const fieldErrors = {
    note1: 'Please enter the products do they want to showcase ',
    note2: 'Please enter their target demographic',
    note3: 'Please enter other talking points'
  };

  const handleAddRecipe = () => {
    let rec = recipe;
    let cred = taskCredits;
    setTaskCredits(cred + 2);
    setRecipe(rec + 1);
  };
  const handleAddProd = () => {
    let prod = product;
    let cred = taskCredits;
    setTaskCredits(cred + 1);
    setProduct(prod + 1);
  };
  const handleDRecipe = () => {
    if (taskCredits >= 2 && recipe > 0) {
      let rec = recipe;
      let cred = taskCredits;
      setTaskCredits(cred - 2);
      setRecipe(rec - 1);
    }
  };
  const handleDProd = () => {
    if (taskCredits >= 1 && product > 0) {
      let prod = product;
      let cred = taskCredits;
      setTaskCredits(cred - 1);
      setProduct(prod - 1);
    }
  };

  const note1 = useRef('');
  const note2 = useRef('');
  const note3 = useRef('');
  const authState = userAuthstate();

  useEffect(() => {
    const user = currentUser ? currentUser.uid : null;
    if (user) {
      AuthService.FetchAuthUser(currentUser.uid);
      SubscriptionService.FetchSubscriptionForUser(currentUser.uid);
    }
  }, [authState.updateNeed.value]);
  const user = authState.authUser.value;
  const subs = subsState.subscription.value;
  let availUsage = 0;
  if (subs) {
    availUsage = subs.paidUsage - subs.currentUsage;
  }
  useEffect(() => {
    if (user && subs) {
      availUsage = subs.paidUsage - subs.currentUsage;
    }
  }, [subs, subsState.updateNeed.value]);

  const [errors, setErrors] = useState({});

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formValues = {
      note1: note1.current.value,
      note2: note2.current.value,
      note3: note3.current.value
    };

    const validationErrors = validateFormFields(formValues, fieldErrors);
    if (Object.keys(validationErrors).length) {
      return setErrors(validationErrors);
    }
    taskState.merge({ loading: true });
    const temp = {};
    temp.uid = currentUser.uid;
    temp.brand_name = user.brand_info.brand_name;
    temp.brand_photo = user.avatar;
    temp.brand_website = user.brand_info ? user.brand_info.website : 'www';
    temp.note1 = note1.current.value;
    temp.note2 = note2.current.value;
    temp.note3 = note3.current.value;
    temp.credits = taskCredits;
    temp.recipe_videos = recipe;
    temp.product_showcase = product;
    temp.task_type = 'SERVICE';
    if (currentUser.uid) TaskService.AddTask(temp);
    taskState.merge({ loading: false });
    props.onClose();
  };

  return (
    <Modal
      open={props.isOpen}
      onClose={() => {
        props.closeAction();
      }}
      center
      classNames={{ modal: 'customModal' }}
      closeOnOverlayClick={false}>
      <>
        <>
          <div>
            <h1 className="text-xl font-bold mb-3 mx-5 font-black xs:xl md:text-4xl">
              Request a UGC Video
            </h1>
            <hr />
            <h1 className="md:text-3xl my-5 font-bold text-center">
              Describe Your Ideal UGC Video
            </h1>

            <form onSubmit={handleSubmit} className="px-4">
              <div className="my-2">
                <label htmlFor="note1" className="text-black block md:text-lg mb-2 font-bold">
                  What products do they want to showcase?
                </label>
                <input
                  name="note1"
                  className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                  placeholder="Enter the products you want to showcase."
                  required
                  ref={note1}
                />
                <div className="text-red px-3 font-medium">{errors.note1 || ''}</div>
              </div>
              <div className="my-2">
                <label htmlFor="description" className="text-black block md:text-lg mb-2 font-bold">
                  What is their target demographic?
                </label>
                <input
                  name="note2"
                  className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                  placeholder="Enter your target demographic."
                  required
                  ref={note2}
                />
                <div className="text-red px-3 font-medium">{errors.note2 || ''}</div>
              </div>
              <div className="my-2">
                <label htmlFor="note3" className="text-black block md:text-lg mb-2 font-bold">
                  Are there any other talking points?
                </label>
                <input
                  name="note3"
                  className="mr-2 outline-none bg-gray-100 p-3  rounded-lg w-full"
                  placeholder="Enter any talking points you'd like the creator to include."
                  required
                  ref={note3}
                />
                Which type of videos would you like?
                <div className="text-red px-3 font-medium">{errors.note3 || ''}</div>
              </div>
              <div className="w-full">
                <div className="flex bg-lightGray rounded-lg">
                  <div className="w-2/5 p-2 font-bold text-xs">
                    <span className="mx-2">{recipe}</span> x
                    <span className="mx-2">Recipe Video</span>
                  </div>
                  <div className="flex p-2 w-1/5 font-bold text-xs">
                    <span className="mx-2">{recipe * 2}</span> credits
                  </div>
                  <div className="flex w-2/5 font-bold">
                    <div
                      className="ml-5px text-4xl font-bold cursor-pointer"
                      onClick={handleAddRecipe}>
                      +
                    </div>
                    <div className="ml-8 text-4xl font-bold cursor-pointer" onClick={handleDRecipe}>
                      -
                    </div>
                  </div>
                </div>
                <div className="flex ">
                  <div className="w-2/5 p-2 font-bold text-xs">
                    <span className="mx-2">{product}</span> X{' '}
                    <span className="mx-2">Product Demo</span>
                  </div>
                  <div className="flex w-1/5 p-2 font-bold text-xs">
                    <span className="mx-2">{product}</span> credits
                  </div>
                  <div className="flex w-2/5 font-bold">
                    <div
                      className="ml-5px text-4xl font-bold cursor-pointer"
                      onClick={handleAddProd}>
                      +
                    </div>
                    <div className="ml-8 text-4xl font-bold cursor-pointer" onClick={handleDProd}>
                      -
                    </div>
                  </div>
                </div>
              </div>
              {subs && subs.status === 'active' ? (
                <div>
                  {taskCredits > availUsage ? (
                    <>
                      <p className="text-center text-3xl font-bold text-red">
                        {' '}
                        {taskCredits} credits ({subs && availUsage} credits available)
                      </p>
                      <div className="flex justify-center">
                        <Link
                          className="py-2 w-fit bg-darkGray w-fit px-4 rounded-lg text-white mt-4 items-center justify-center text-center cursor-pointer"
                          to="/brand/dashboard/billing">
                          {taskState.loading.value && <Loader />}
                          Add Credits
                        </Link>
                      </div>
                    </>
                  ) : (
                    <>
                      <p className="text-center text-3xl font-bold">
                        {' '}
                        {taskCredits} credits ({subs && availUsage} credits available)
                      </p>
                      <div className="flex justify-center">
                        <button
                          type="submit"
                          className="py-2 w-fit bg-red  w-fit px-4 rounded-lg text-white mt-4 items-center justify-center text-center cursor-pointer">
                          {taskState.loading.value && <Loader />}
                          Create a task
                        </button>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className="justify-center">
                  <p className="text-center text-xl text-red font-bold mt-2">
                    {' '}
                    Your subscription is inactive, check the billing tab.{' '}
                  </p>
                  <div className="flex justify-center">
                    <Link
                      className="py-2 w-fit bg-darkGray w-fit px-4 rounded-lg text-white mt-4 items-center justify-center text-center cursor-pointer"
                      to="/brand/dashboard/billing">
                      Go to Billing
                    </Link>
                  </div>
                </div>
              )}
            </form>
          </div>
        </>
      </>
    </Modal>
  );
}
