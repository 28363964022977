import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FiUsers, FiHeart, FiMessageCircle, FiMail, FiEye } from "react-icons/fi";
import {toast, Toaster} from 'react-hot-toast';
function CreatorDiscoveryProfile({ selectedRow, setShowProfile }) {
  const { handle } = useParams();
  const [dropDown, setDropDown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contactDetails, setContactDetails] = useState([]);
  const icons = {
    youtube: {icon: <div className='youtube 2xl:w-6 2xl:h-6 w-4 h-4 ml-2 items-center'></div>, code: '14d9ddf5-51c6-415e-bde6-f8ed36ad7054'},
    facebook: {icon: <div className='Facebook 2xl:w-6 2xl:h-6 w-4 h-4 ml-2 items-center'></div>, code: 'f4b3b3b4-1b3b-4b3b-8b3b-3b3b3b3b3b3b'},
    twitter: {icon: <div className='Twitter 2xl:w-6 2xl:h-6 w-4 h-4 ml-2 items-center'></div>, code: '3b3b3b3b-3b3b-3b3b-3b3b-3b3b3b3b3b3b'},
    instagram: {icon: <div className='Instagram 2xl:w-6 2xl:h-6 w-4 h-4 ml-2 items-center'></div>, code: '9bb8913b-ddd9-430b-a66a-d74d846e6c66'},
    tiktok: {icon: <div className='tiktok 2xl:w-6 2xl:h-6 w-4 h-4 ml-2 items-center'></div>, code: 'de55aeec-0dc8-4119-bf90-16b3d1f0c987'},
    email: {icon: <div className='email flex 2xl:w-6 2xl:h-6 w-4 h-4 ml-2 items-center'><FiMail/></div>, code: '3b3b3b3b-3b3b-3b3b-3b3b-3b3b3b3b3b3b'},
  };
  console.log("Selected Row", selectedRow);
  {/*const fetchData = async () => {
    const username = '79e726a1-b9e9-462a-9db6-29dcb3d761bb';
    const password = '436d043a-79a3-423e-a353-e0e279f9d09d';
    const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');

    try {
      const response = await axios.post(
        'https://api.staging.insightiq.ai/v1/internal/social/creators/profiles/analytics',
        {
          identifier: handle,
          work_platform_id: '9bb8913b-ddd9-430b-a66a-d74d846e6c66'
        },
        {
          headers: {
            Authorization: `Basic ${token}`,
          },
        }
      );
      setCreatorData(response.data.profile); // Update the state with fetched data
      console.log("Response Data", response.data.profile)
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };*/}
  const ageRanges = ["13-17", "18-24", "25-34", "35-44", "45-64", "65+"];
  {/*useEffect(() => {
    fetchData();
  }, [handle]); // Fetch data when handle changes*/}

  function formatNumber(num) {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K';
    } else {
      return num;
    }
  }

  const unlockContactDetails = async () => {
    setIsLoading(true);
    const username = 'b0e95fb6-43c6-4c0f-a44b-6d1bc9ced026';
    const password = '1be6ac2c-e349-46c9-bce0-ebd607625390';
    const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
  
    try {
      const response = await axios.post(
        'https://api.insightiq.ai/v1/social/creators/profiles/search',
        {
          "work_platform_id":  selectedRow.work_platform.id,
          platform_username: {
            value: selectedRow.platform_username,
            operator: 'PREFIX'
          },
          
          "sort_by": {
            "field": "AVERAGE_LIKES",
            "order": "DESCENDING"
          },
          "limit": 100,
        },
        {
          headers: {
            'Authorization': `Basic ${token}`,
          },
        }
      );
      setContactDetails(response.data.data[0].contact_details);
      setDropDown(true); // Set dropDown to true when the contact details are loaded
      console.log("Contact Details", response.data.data[0].contact_details);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getFormattedLocation = (location) => {
    if (location) {
      const { city, state, country } = location;
      return `${city}, ${state}, ${country}`;
    }
    return "N/A";
  };

  const formattedLocation = selectedRow ? getFormattedLocation(selectedRow.location) : "N/A";
  
  
  return (
<div className="flex w-full items-center justify-center 2xl:text-base md:text-0.7 border-2 mx-auto mt-3 space-x-2 rounded-3xl relative">
  <Toaster/>
  <div className="flex flex-col items-center lg:flex-row lg:items-start lg:space-x-8 p-6 w-full border-b border-gray-200 pb-4 mb-4">  <div className="space-y-2 w-full">
    <div className='w-full'> 
     <div className="flex justify-between items-center space-x-4 mb-4">
        <div className="flex items-center space-x-4">
          <img
            className="2xl:w-8rem 2xl:h-8rem md:w-4rem md:h-4rem rounded-full transition-transform duration-300 hover:scale-105"
            src={selectedRow.image_url}
            alt={selectedRow.platform_username}
          />
          <div>
            <h1 className="2xl:text-3xl md:text-base font-bold mb-2 text-gray-800">{selectedRow.full_name}</h1>
            <div className="flex items-center space-x-2">
              <span className="text-gray-600 2xl:text-sm md:text-0.7">@{selectedRow.platform_username}</span>
              <span className={`text-xs rounded-full px-2 py-1 ${selectedRow.is_verified ? 'bg-blue text-white' : 'bg-gray-300 text-white'}`}>
                  {selectedRow.is_verified ? 'Verified' : 'Not Verified'}
                </span>
            </div>
          </div>
        </div>
      <div className="relative mr-4 transition-colors rounded-md p-2 bg-gray-100 hover:bg-gray-200">
        <button 
          className="flex flex-row items-center transition-colors gap-3 2xl:text-2xl" 
          onClick={unlockContactDetails}
          disabled={isLoading}
        >
          <FiMail/> 
          {isLoading ? 'Loading...' : (contactDetails.length > 0 ? 'Show Contact Details' : 'Unlock Contact Details')}
        </button>
        {!isLoading && contactDetails && (
          <div className="absolute bg-white left-0 mt-2 w-fit rounded-md shadow-lg" style={{ zIndex: 50 }}>
            <div className="">
            {dropDown && contactDetails.map((contact, index) => (
              <div
                key={index}
                className="flex items-center px-4 py-2 mr-2 font-bold text-sm text-gray-700 border-b hover:bg-gray-100"
              >
                <a 
                  href="#" 
                  onClick={(e) => {
                    e.preventDefault();
                    if (contact.type === 'email') {
                      navigator.clipboard.writeText(contact.value);
                      toast.success('Email copied to clipboard');
                    } else {
                      window.open(contact.value, '_blank');
                    }
                  }} 
                  className="flex items-center"
                >
                  {icons[contact.type] && icons[contact.type].icon}
                  <span className="ml-2">{contact.type}</span>
                </a>
              </div>
            ))}
            </div>
          </div>
        )}
      </div>
      </div>
        {/*<div className="w-full">
          <p className="text-base text-gray-600 mb-2">
            Bio: <span className="text-gray-800">{selectedRow.introduction}</span>
          </p>
          <div className="flex justify-start 2xl:text-2xl md:text-0.7 gap-4 text-gray-600 mb-4">
            <p>
              Location: <span className="text-gray-800 font-semibold">{selectedRow.location.city}, {selectedRow.location.state}, {selectedRow.location.country}</span>
            </p>
            <p>
              Language Spoken: <span className="font-semibold text-gray-800">{selectedRow.language}</span>
            </p>
            <p>
              Account type: <span className="text-gray-800 font-semibold">{selectedRow.platform_account_type}</span>
            </p>
          </div>
        </div>*/}
    </div>
    <div className=" w-full border-b">
  <div className=" p-4 mb-4 w-full bg-white">
    <h2 className="2xl:text-4xl font-nunito md:text-2xl font-bold mb-5 text-gray-700">Performance</h2>
    <div className="flex space-x-8 text-lg">
      <p className="text-gray-600 2xl:text-2xl md:text-0.7">
        <div className="flex items-center">
          <FiUsers  className='text-gray-600 w-3 h-3' />
          <span className="font-semibold  ml-1">{formatNumber(selectedRow.follower_count)}</span>
        </div>
        <span>Followers</span>
      </p>
      <p className="text-gray-600 2xl:text-2xl md:text-0.7">
        <div className="flex items-center">
          <span className="font-semibold  ">{(selectedRow.engagement_rate * 100).toFixed(2)}%</span>
        </div>
        <span>Engagement rate</span>
      </p>
      <div>
      <div className="flex items-center">
        <FiHeart className='text-gray-600'/>
        <p className="font-semibold text-purple-500 2xl:text-2xl md:text-0.7 ml-2">{formatNumber(selectedRow.average_likes)}</p>
      </div>
      <p className="text-gray-600 2xl:text-2xl md:text-0.7">Average likes per post</p>
    </div>
    </div>
  </div>

</div>
</div>
</div>
</div>
  );
}

export default CreatorDiscoveryProfile;
