import React, { useState, useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import Modal from '../../utils/brandModal';
import { useAuth } from '../../Context/AuthContext';
import { useHistory } from 'react-router-dom';
import { useUserState, UsersService } from '../../Service/userServices';
import { useTabState } from '../../utils/TabState';
import { useLocation } from 'react-router-dom';
import Mailing from '../../Components/Creator_Dashboard/Onboarding/Mailing';
import Payment from '../../Components/Creator_Dashboard/Onboarding/Payment';
import 'react-select-search/style.css';
import Proposal from '../../Components/Creator_Dashboard/Onboarding/Proposal';
import CreatorTab from '../../common/steps/Onboarding/CreatoTab';
import Portfolio from '../../Components/Creator_Dashboard/Onboarding/Portfolio';
import GeneralOnboarding from '../../Components/Creator_Dashboard/Onboarding/GeneralOnboarding';

const styles = {
  fontFamily: 'sans-serif',
  textAlign: 'center',
  color: 'red',
  width: '50%'
};

function CreatorOnboarding() {
  const history = useHistory();
  const { currentUser } = useAuth();
  const userState = useUserState();
  const tabState = useTabState();
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add a loading state
  const [shouldRenderModal, setShouldRenderModal] = useState(false); // State variable to control rendering
  const location = useLocation();
  const closeAction = () => {
    setShouldRenderModal(false);
  };

  const handlePayment = () => {
    tabState.merge({ currentTab: 2 });
  };

  const handlePortfolio = () => {
    tabState.merge({ currentTab: 3 });
  };

  const handleContinue = () => {
    tabState.merge({ currentTab: 1 });
  };

  useEffect(() => {
    if (currentUser !== null) {
      UsersService.FetchUserInfo(currentUser.uid);
    }
  }, [history, currentUser]);

  let user = userState.user.value;

  useEffect(() => {
    if (userState.user.value) {
      function subsTimeout() {}
      setTimeout(subsTimeout, 0);
    }
    setIsLoading(false); // Set isLoading to false once the necessary data is fetched
  }, []);

  // Delay before checking conditions and rendering the modal
  useEffect(() => {
    const delay = setTimeout(() => {
      if (location.pathname.includes('/creator/dashboard/tik-tok-success') || location.pathname === '/creator/dashboard/mytasks' || location.pathname === '/creator/dashboard/settings' || location.pathname === '/creator/dashboard/instagram-success' || location.pathname.includes('/creator/dashboard/reviews')) {
        setShouldRenderModal(false);
      } else if (
        user &&
        ((!user.creator_socials || !user.shipping_details) ||
          (user.creator_socials &&
            (!user.creator_socials.tiktok ||
              !user.creator_socials.tiktok.performance ||
              !user.creator_socials.instagram ||
              !user.creator_socials.instagram.suggested_rate)))
      ) {
        setShouldRenderModal(true);
      } else {
        setShouldRenderModal(false); // Hide the modal if conditions are not satisfied
      }
    }, 3000); // Change the delay time as per your requirement
  
    return () => clearTimeout(delay);
  }, [(
    user &&
      ((!user.creator_socials || !user.shipping_details) ||
        (user.creator_socials &&
          (!user.creator_socials.tiktok ||
            !user.creator_socials.tiktok.performance ||
            !user.creator_socials.instagram ||
            !user.creator_socials.instagram.suggested_rate)))
  )]);
  // Render loading state while fetching data
  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Render the modal if shouldRenderModal is true
  if (shouldRenderModal) {
    return (
      <div>
        <Modal
          open={true}
          onClose={() => setShouldRenderModal(false)}
          center
          style={styles}
          closeOnOverlayClick={false}
        >
          <div>
            <div className="flex w-full items-center justify-center mx-auto">
              <GeneralOnboarding
                open={true}
                onClose={() => setShouldRenderModal(false)}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  // Render null if shouldRenderModal is false
  return null;
}

export default CreatorOnboarding