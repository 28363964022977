import React from 'react';
import { useTabState } from '../../../utils/TabState';

export default function Subscribe(props) {
  const tabState = useTabState();
  const handleTabClick = (tabIndex) => {
    tabState.currentCampModalTab.set(tabIndex); // Setting the currentCampModalTab to the index of the clicked tab
  };

  return (
    <div className="flex mx-5 font-bold">
      <h1
        className={`text-base mr-5 font-bold mb-3 font-black xs:xl cursor-pointer ${
          tabState.currentCampModalTab.value === 0 ? 'text-Blue' : 'text-darkGray'
        } text-center`}
       >
        1) Basic Campaign Info
      </h1>
      <h1
        className={`text-base mx-5 font-bold mb-3 font-black xs:xl cursor-pointer ${
          tabState.currentCampModalTab.value === 1 ? 'text-Blue' : 'text-darkGray'
        } text-center`}
        >
        2) Campaign Briefs
      </h1>
      {/*<h1
        className={`text-base mx-5 font-bold mb-3 font-black xs:xl cursor-pointer ${
          tabState.currentCampModalTab.value === 2 ? 'text-Blue' : 'text-darkGray'
        } text-center`}
        onClick={() => handleTabClick(2)}>
        3) Confirmation
      </h1>*/}
      <h1
        className={`text-base mx-5 font-bold mb-3 font-black xs:xl cursor-pointer ${
          tabState.currentCampModalTab.value === 2 ? 'text-Blue' : 'text-darkGray'
        } text-center`}
        >
        3) Outreach
      </h1>
     {/**  <h1
        className={`text-base mx-5 font-bold mb-3 font-black xs:xl cursor-pointer ${
          tabState.currentCampModalTab.value === 4 ? 'text-Blue' : 'text-darkGray'
        } text-center`}
        onClick={() => handleTabClick(4)}>
        4) Manual Outreach
      </h1>*/}
    </div>
  );
}