import { createState, useState } from '@hookstate/core';
import axios from 'axios';
import { baseUrl } from '../baseUrl';
import { store, useDispatch } from '../Store';

//State
const state = createState({
  assets: [],
  asset: [],
  retrieving: false,
  fetched: false,
  loading: false,
  updateNeed: true
});

store.receptors.push((action) => {
  state.batch((s) => {
    switch (action.type) {
      case 'BRAND_ASSETS_LOADED':
        return s.merge({
          assets: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
          fetched: true
        });
      case 'CREATOR_ASSETS_LOADED':
        return s.merge({
          assets: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
          fetched: true
        });
      case 'ALL_ASSETS_LOADED':
        return s.merge({
          assets: action.data,
          updateNeed: false,
          loading: false,
          retrieving: true,
          fetched: true
        });
      case 'CREATE_ASSET':
        return s.merge({
          asset: action.data,
          loading: false,
          updateNeed: true
        });
      case 'ASSET_UPDATE':
        return s.merge({
          updateNeed: true,
          loading: false
        });
      case 'ASSET_DELETE':
        return s.merge({
          loading: false,
          updateNeed: true
        });
      case 'ASSET_LOADED':
        return s.merge({
          asset: action.data,
          updateNeed: false,
          loading: false,
          fetched: true
        });
      default:
        return null;
    }
  }, action.type);
});

export const accessAssetState = () => state;

export const useAssetState = () => useState(state);
//Service
export const AssetService = {
  FetchAssetsForBrand: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/assets/brands/${id}`)
      .then((res) => {
        dispatch(AssetAction.loadBrandAssets(res.data.result));
      })
      .catch((err) => {});
  },
  FetchAssetsForCreator: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/assets/creators/${id}`)
      .then((res) => {
        dispatch(AssetAction.loadCreatorAssets(res.data.result));
      })
      .catch((err) => {});
  },
  FetchAssets: (id) => {
    const dispatch = useDispatch();
    axios
      .get(`${baseUrl}api/assets`)
      .then((res) => {
        dispatch(AssetAction.loadAssets(res.data.result));
      })
      .catch((err) => {});
  },
  AddAsset: async (data) => {
    const dispatch = useDispatch();
    await axios
      .post(`${baseUrl}api/assets/createAsset`, data)
      .then((res) => {
        dispatch(AssetAction.createAsset(res));
      })
      .catch((err) => {});
  },

  UpdateAsset: (id, data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/assets/updateAsset/${id}`, data)
      .then((res) => {
        dispatch(AssetAction.updateAsset(res));
      })
      .catch((err) => {});
  },
  DeleteAsset: (id, data) => {
    const dispatch = useDispatch();
    axios
      .put(`${baseUrl}api/assets/deleteAsset/${id}`, data)
      .then((res) => {
        dispatch(AssetAction.deleteAsset(res));
      })
      .catch((err) => {});
  },
  FetchSingleAsset: (id) => {
    const dispatch = useDispatch();

    axios
      .get(`${baseUrl}api/assets/${id}`)
      .then((res) => {
        dispatch(AssetAction.loadSingleAsset(res.data.result));
      })
      .catch((err) => {});
  }
};

//Action
export const AssetAction = {
  loadBrandAssets: (data) => {
    return {
      type: 'BRAND_ASSETS_LOADED',
      data: data
    };
  },
  loadCreatorAssets: (data) => {
    return {
      type: 'CREATOR_ASSETS_LOADED',
      data: data
    };
  },
  loadAssets: (data) => {
    return {
      type: 'ALL_ASSETS_LOADED',
      data: data
    };
  },
  loadSingleAsset: (data) => {
    return {
      type: 'ASSET_LOADED',
      data: data
    };
  },
  createAsset: (data) => {
    return {
      type: 'CREATE_ASSET',
      data: data
    };
  },
  updateAsset: (data) => {
    return {
      type: 'ASSET_UPDATE',
      data: data
    };
  },
  deleteAsset: (data) => {
    return {
      type: 'ASSET_DELETE',
      data: data
    };
  }
};
